import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    card: {
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        minHeight: "350px",
        height: "100%",
        width: "300px"
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: 180
    },
    content: {
        overflowWrap: "break-word",
        flexDirection: "column",
        display: "flex",
        height: "100%"
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        }
    }
});


export default useStyles;