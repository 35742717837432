import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        }
    },
    informationTextStyle: {
        flexDirection: "column",
        display: "flex"
    },
    bookmarkStyle: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        height: 36,
        width: 36
    },
    renderInformationsStyle: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex",
    },
    imageStyle: {
        height: 56,
        width: 56
    },
    renderTagsStyle: {
        boxSizing: "border-box",
        borderTopStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },

});

