import {
    Fragment,
    useEffect,
    useState 
} from "react";
import { 
    IOCoreLocale, 
    IOCoreTheme, 
    Loading, 
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    useNavigate,
    useParams 
} from "react-router-dom";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    GetAgreementResponseType 
} from "../../../services/restAPI/actions/getAgreement/types";
import IsinOlacakWhite from "../../../assets/img/isinolacakWhite.png";
import {
    getBrowserLanguageFull 
} from "../../../utils";
import {
    Footer 
} from "../../../components";

const Agreement = () => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const navigate = useNavigate();

    const {
        agreementType
    } = useParams();

    const [agreementData, setAgreementData] = useState<GetAgreementResponseType>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAgreement();
    }, []);

    const getAgreement = () => {
        const params = {
            agreementType: agreementType ?? "",
            language: getBrowserLanguageFull() ?? "tr-TR"
        };

        if(!isLoading) {
            setIsLoading(true);
        }

        RESTService.action("GetAgreement", params)
            .then((res) => {
                setAgreementData(res);
            })
            .catch((err) => {
                navigate("/notFound");
                console.error("GetAgreement Err:", err);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    if(isLoading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            style={{
                paddingBottom: spaces.container
            }}
        >
            <div className={classes.topContainer}
                style={{
                    backgroundColor: colors.secondary
                }}>
                <Text
                    className={classes.topContainerText}
                    variant="header1-medium"
                    color="white"
                    style={{
                        paddingTop: spaces.containerLarge
                    }}
                >
                    {`${localize("isinolacak-platform")} ${agreementData?.title}`}
                </Text>
                <img
                    className={classes.logoStyle}
                    src={IsinOlacakWhite}
                    style={{
                        paddingBottom: spaces.containerLarge
                    }}/>
            </div>
            {agreementData?.content && <div dangerouslySetInnerHTML=
                {{
                    __html: `${agreementData?.content}`
                }}
            />
            }
        </div>
        <Footer/>
    </Fragment>;
};

export default Agreement;