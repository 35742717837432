import {
    createUseStyles
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        borderStyle: "solid",
        userSelect: "none",
        overflowY: "auto",
        maxWidth: "%30",
        minWidth: "%25",
        display: "flex",
        "@media (max-width: 800px)": {
            display: "none"
        }
    },
    citiesContianer: {
        borderBottomStyle: "solid",
        flexDirection: "column",
        display: "flex"
    },
    dateContainer: {
        borderBottomStyle: "solid",
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex"
    },
    occupationContent: {
        boxSizing: "border-box",
        overflowX: "hidden",
        overflow: "auto",
        height: "10rem"
    },
    educationLevelContianer:{
        borderBottomStyle: "solid",
        flexDirection: "column",
        display: "flex"
    },
    educationLevelContent:{
        boxSizing: "border-box",
        overflowX: "hidden",
        overflow: "auto",
        height: "10rem"
    }
});
