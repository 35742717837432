import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        alignItems: "center",
        display: "flex"
    },
    iconCursor: {
        cursor: "pointer",
        display: "flex"
    }
});
export default useStyles;