import React, {
    Fragment,
    useEffect, 
    useState 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    useNavigate,
    useParams 
} from "react-router-dom";
import {
    InstagramIcon,
    FacebookIcon,
    XIcon 
} from "../../../assets/svgr";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    getBlogPayloadObjectType, 
    GetBlogRequestType
} from "../../../services/restAPI/actions/getBlog/types";
import BlogPostCoverPhoto from "../../../assets/img/blogCoverPhoto.png";
import {
    shareOnSocialMedia, 
    getContentArray,
    getDate
} from "../../../utils";
import {
    Footer 
} from "../../../components";

const BlogPost = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        blogID 
    } = useParams();

    const [blogPostData, setBlogPostData] = useState<getBlogPayloadObjectType>();
    const [isBlogDataLoading, setIsBlogPostDataLoading] = useState(true);

    useEffect(() => {
        getBlogPostData();
    }, [blogID]);

    const getBlogPostData = () => {
        if(blogID) {
            let variables: GetBlogRequestType = {
                language: navigator.language ?? "tr-TR",
                blogID
            };
    
            if(!isBlogDataLoading) {
                setIsBlogPostDataLoading(true);
            }
    
            RESTService.action("GetBlog", variables)
                .then((res) => {
                    setBlogPostData(res as unknown as getBlogPayloadObjectType);
                    setIsBlogPostDataLoading(false);
                })
                .catch((err) => {
                    console.error("GetBlog Err:", err);
                    setIsBlogPostDataLoading(false);
                    navigate("/notFound");
                });
        }
    };

    const renderShareContainer = () => {
        return <div className={classes.leftContainer}>
            <div
                className={classes.shareContainer}
                style={{
                    backgroundColor: colors.white,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    padding: spaces.container,
                    gap: spaces.content / 1.2
                }}
            >
                <Text variant="body-medium">
                    {localize("share-post")}
                </Text>
                <div
                    className={classes.iconContainer}
                    style={{
                        gap: spaces.content / 1.5
                    }}
                >
                    <div className={classes.icon}
                        style={{
                            backgroundColor: colors.secondary,
                            borderRadius: radiuses.hard,
                            height: 18 * 1.5,
                            width: 18 * 1.5
                        }}
                        onClick={() => {
                            if(blogPostData?.title) {
                                shareOnSocialMedia("instagram" as const, blogPostData.title);
                            }
                        }}
                    >
                        <InstagramIcon size={18} color={colors.white}/>
                    </div>
                    <div
                        className={classes.icon}
                        style={{
                            backgroundColor: colors.secondary,
                            borderRadius: radiuses.hard,
                            height: 18 * 1.5,
                            width: 18 * 1.5
                        }}
                        onClick={() => shareOnSocialMedia("facebook")}
                    >
                        <FacebookIcon size={18} color={colors.white}/>
                    </div>
                    <div
                        className={classes.icon}
                        style={{
                            backgroundColor: colors.secondary,
                            borderRadius: radiuses.hard,
                            height: 18 * 1.5,
                            width: 18 * 1.5
                        }}
                        onClick={() => shareOnSocialMedia("X", blogPostData?.title)}
                    >
                        <XIcon size={18} color={colors.white}/>
                    </div>
                </div>
                <div
                    className={classes.contents}
                    style={{
                        marginTop: spaces.content / 2,
                        gap: spaces.content / 1.5
                    }}
                >
                    {blogPostData && blogPostData.content &&
                <Fragment>
                    <Text variant="body2-medium">
                        {localize("contents")}
                    </Text>
                    {getContentArray(blogPostData.content).map((header, i) => (
                        <Fragment key={i}>
                            <Text
                                variant="body3-regular"
                                color="textSecondary"
                            >
                                {header}
                            </Text>
                        </Fragment>
                    ))}
                </Fragment>
                    }
                </div>
            </div>
        </div>;
    };

    const renderResponsiveShare = () => {
        return <div
            className={classes.smallShareContainer}
            style={{
                paddingBottom: spaces.containerLarge,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.content / 1.2
            }}
        >
            <Text variant="body-medium">
                {localize("share-post")}
            </Text>
            <div
                className={classes.iconContainer}
                style={{
                    gap: spaces.content / 1.5
                }}
            >
                <div className={classes.icon}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.hard,
                        height: 18 * 1.5,
                        width: 18 * 1.5
                    }}
                    onClick={() => {
                        if(blogPostData?.title) {
                            shareOnSocialMedia("instagram", blogPostData.title, localize("check-out-this-amazing-blog-post"));
                        }
                    }}
                >
                    <InstagramIcon size={18} color={colors.white}/>
                </div>
                <div
                    className={classes.icon}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.hard,
                        height: 18 * 1.5,
                        width: 18 * 1.5
                    }}
                    onClick={() => shareOnSocialMedia("facebook")}
                >
                    <FacebookIcon size={18} color={colors.white}/>
                </div>
                <div
                    className={classes.icon}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.hard,
                        height: 18 * 1.5,
                        width: 18 * 1.5
                    }}
                    onClick={() => shareOnSocialMedia("X", blogPostData?.title)}
                >
                    <XIcon size={18} color={colors.white}/>
                </div>
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div className={classes.rightContainer}>
            {renderResponsiveShare()}
            {!isBlogDataLoading && <img 
                style={{
                    marginBottom: spaces.containerLarge
                }} 
                src={(blogPostData && blogPostData.coverPhotoURL) ?? BlogPostCoverPhoto}
            />}
            <Text
                style={{
                    paddingBottom: spaces.content,
                    paddingTop: spaces.content
                }}
                variant="header5-bold">
                {blogPostData && blogPostData.title}
            </Text>
            <Text
                style={{
                    paddingBottom: spaces.content,
                }}
                variant="body3-medium"
                color="textSecondary">
                {blogPostData && getDate(blogPostData.createdAt)}
            </Text>
            {blogPostData && <Text>
                <div
                    className={classes.blogContent} dangerouslySetInnerHTML={{
                        __html: blogPostData.content
                    }}/>
            </Text>
            }
        </div>;
    };

    if(isBlogDataLoading) {
        return <div 
            style={{
                paddingTop: spaces.containerLarge * 1.5,
                paddingBottom: spaces.containerXXLarge,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
            }}>
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.container}
            style={{
                paddingTop: spaces.containerLarge * 1.5,
                paddingBottom: spaces.containerXXLarge,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                backgroundColor: colors.grey25
            }}>
            <div className={classes.mainContent}>
                {/* //TODO: BREADCRUMB */}
                <div
                    style={{
                        marginBottom: spaces.containerLarge
                    }}>
        
                    <Text
                        variant="header3-medium"
                    >
                        {localize("achieve-success-on-your-career-path")}
                    </Text>
                </div>
                <div
                    className={classes.mainContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    {renderShareContainer()}
                    {renderRightContainer()}
                </div>
            </div>
        </div>
        <Footer/>
    </Fragment>;
};

export default BlogPost;