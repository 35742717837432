import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledCheckSquare = ({
    size = 22,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M11 21c-4.714 0-7.071 0-8.536-1.465C1 18.072 1 15.714 1 11s0-7.071 1.464-8.536C3.93 1 6.286 1 11 1c4.714 0 7.071 0 8.535 1.464C21 3.93 21 6.286 21 11c0 4.714 0 7.071-1.465 8.535C18.072 21 15.714 21 11 21Zm4.03-13.03a.75.75 0 0 1 0 1.06l-5 5a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06l1.47 1.47 4.47-4.47a.75.75 0 0 1 1.06 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledCheckSquare;
