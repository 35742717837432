import { 
    Fragment,
    useEffect,
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    useNavigate 
} from "react-router-dom";
import {
    Footer 
} from "../../../components";
import StoryContainer from "./components/storyContainer";
import AboutChip from "./components/aboutChip";
import PhoneMacBig from "../../../assets/img/phoneMacBig.png";
import {
    getBrowserLanguageFull 
} from "../../../utils";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    IStoryContainer 
} from "./components/storyContainer/types";
import {
    StarIcon,
    SunFogIcon,
    TargetIcon 
} from "../../../assets/svgr";
import {
    AboutData 
} from "./types";

const About = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [companyGoalsData, setCompanyGoalsData] = useState<Array<IStoryContainer & {id: string}>>();
    const [aboutData, setAboutData] = useState<AboutData>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAboutData();
    }, []);

    const getAboutData = () => {
        const params = {
            language: getBrowserLanguageFull(),
            isAboutPageCategory: true
        };

        if(!isLoading) {
            setIsLoading(true);
        }

        RESTService.action("GetFAQsForAbout", params)
            .then((res) => {
                if(res.length > 0) {
                    const modifiedAboutData: AboutData = {
                    };
                    const modifiedCompanyGoalsData: Array<IStoryContainer & {id: string}> = [];
                    for(const item of res) {
                        switch(item.key) {
                        case "about-us":
                            modifiedAboutData["about-us"] = {
                                title: item.title,
                                content: item.content
                            };
                            break;
                        case "why-choose-us":
                            modifiedAboutData["why-choose-us"] = {
                                title: item.title,
                                content: item.content
                            };
                            break;
                        case "vision":
                            modifiedCompanyGoalsData.push({
                                text: item.content,
                                icon: () => <TargetIcon size={50} color="#fff"/>,
                                title: item.title,
                                id: 1 + "vision"
                            });
                            break;
                        case "mission":
                            modifiedCompanyGoalsData.push({
                                text: item.content,
                                icon: () => <SunFogIcon size={50} color="#fff"/>,
                                title: item.title,
                                id: 1 + "mission"
                            });
                            break;
                        case "values":
                            modifiedCompanyGoalsData.push({
                                text: item.content,
                                icon: () => <StarIcon size={50} color="#fff"/>,
                                title: item.title,
                                id: 1 + "values"
                            });
                            break;
                        default: 
                            console.error("About data not found!");
                        }
                    }
                    setAboutData(modifiedAboutData);
                    setCompanyGoalsData(modifiedCompanyGoalsData);
                }
            
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("GetFAQsForAbout error:", err);
            });
    };

    const renderChipContainer = () => {
        return <div
            className={classes.chipContainer}
            style={{
                gap: spaces.content
            }}>
            <AboutChip 
                title={localize("jobAdverts")}
                onClick={() => {
                    navigate("/search");
                }} 
            />
            <AboutChip 
                title={localize("asked-questions")}
                onClick={() => {
                    navigate("/faq");
                }} 
            />
            <AboutChip
                title={localize("contact")}
                onClick={() => {
                    navigate("/contactUs");
                }} 
            />
        </div>;
    };

    const renderAboutUsText = () => {
        return <div 
            className={classes.renderAboutUs} 
            style={{
                gap: spaces.container * 1.2,
            }}>
            <Text 
                variant="header2-medium"
            >
                {aboutData?.["about-us"] && aboutData["about-us"].title}
            </Text>
            <Text 
                variant="header5-regular"
            >
                {aboutData?.["about-us"] && aboutData["about-us"].content}
            </Text>
        </div>;
    };

    const renderTopContainer = () => {
        return <div 
            className={classes.renderAboutUs} 
            style={{
                gap: spaces.container * 2,
            }}
        >
            {renderAboutUsText()}
            {renderChipContainer()}
        </div>;
    };

    const renderCompanyGoals = () => {
        return <div 
            className={classes.companyGoalsContainer}
            style={{
                gap: spaces.inline * 2
            }}
        >
            {companyGoalsData && companyGoalsData.map(elm => {
                return <Fragment key={elm.id}>
                    <StoryContainer
                        title={elm.title}
                        text={elm.text}
                        icon={elm.icon}
                    />
                </Fragment>;
            }) }
        </div>;
    };

    const renderWhyChooseUs = () => {
        return <Fragment>
            <div className={classes.renderAboutUs}>
                <Text 
                    variant="header2-medium"
                >
                    {aboutData?.["why-choose-us"] && aboutData["why-choose-us"].title}
                </Text>
            </div>
            <div 
                className={classes.renderWhyChooseUs}
                style={{
                    gap: spaces.container * 1.2
                }}>
                <div className={classes.imageContainer}>
                    <img 
                        className={classes.image}
                        src={PhoneMacBig}
                    />
                </div>
                <div 
                    className={classes.whyChooseUsTextContainer}
                    style={{
                        gap: spaces.container * 2
                    }}
                >
                    <Text 
                        variant="header5-regular"
                    >
                        {aboutData?.["why-choose-us"] && aboutData["why-choose-us"].content}
                    </Text>
                </div>
            </div>
        </Fragment>;
    };

    const renderMainContent = () => {
        return <div 
            className={classes.mainContent} 
            style={{
                paddingBottom: spaces.containerXLarge * 1.5,
                paddingTop: spaces.containerLarge * 1.5,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                gap: spaces.containerXXLarge / 1.5
            }}>
            {renderTopContainer()}
            {renderCompanyGoals()}
            {renderWhyChooseUs()}
        </div>;
    };

    const renderResponsiveMainContent = () => {
        return <div 
            className={classes.responsiveMainContent} 
            style={{
                paddingBottom: spaces.containerLarge * 1.5,
                paddingTop: spaces.containerLarge * 1.5,
                paddingRight: spaces.containerLarge,
                paddingLeft: spaces.containerLarge,
                gap: spaces.containerXXLarge / 1.5
            }}>
            {renderTopContainer()}
            {renderCompanyGoals()}
            {renderWhyChooseUs()}
        </div>;
    };

    return <div 
        className={classes.container}
        style={{
            backgroundColor: colors.grey25
        }}
    >
        {renderMainContent()}
        {renderResponsiveMainContent()}
        <Footer/>
    </div>;
};

export default About;