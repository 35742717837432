import useStyles from "./styles";
import {
    IOCoreLocale,
    SelectDialog,
    IOCoreTheme,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    HeaderLeftMobileContainerType
} from "./types";
import {
    ArrowDownIcon
} from "../../../../../../assets/svgr";
import {
    SelectedItem
} from "../../dropDown/types";
import {
    CANDIDATE_HISTORY
} from "../../../constants.";
import {
    SelectedApplicationFilterTypes
} from "../../../types";

const HeaderLeftMobileContainer = ({
    setSelectDialogHistoryVisible,
    setSelectDialogCitiesVisible,
    selectDialogHistoryVisible,
    selectDialogCitiesVisible,
    setSelectedHistory,
    setSelectedCities,
    getMyApplication,
    selectedHistory,
    selectedCities
}: HeaderLeftMobileContainerType) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const onChangeSelectedFilters = (_filters: Array<SelectedApplicationFilterTypes>, item: SelectedApplicationFilterTypes) => {
        if(item.type === "history") {
            let  _history: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedHistory));
            const isExistsInSelectedData = selectedHistory.findIndex(e => e.__key === item.id);
            _history.splice(isExistsInSelectedData,1);
            setSelectedHistory(_history);
            return;
        }
        
        if(item.type === "cities") {
            let  _cities: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData = selectedCities.findIndex(e => e.__key === item.id);
            _cities.splice(isExistsInSelectedData,1);
            setSelectedCities(_cities);
            return;
        }
    };

    const renderResponsiveChoosenFilters = () => {
        let _filters: Array<SelectedApplicationFilterTypes> = [];

        if(selectedHistory.length) { 
            let _history: Array<SelectedApplicationFilterTypes> = selectedHistory.map((item) => {
                return {
                    localizedText: item.__title,
                    id: item.__key,
                    type: "history"
                };
            });
            _filters = [..._filters, ..._history];
        }

        if(selectedCities.length) { 
            let _cities: Array<SelectedApplicationFilterTypes> = selectedCities.map((item) => {
                return {
                    localizedText: item.__title,
                    id: item.__key,
                    type: "cities"
                };
            });
            _filters = [..._filters, ..._cities];
        }

        if(!_filters.length) {
            return null;
        }

        return <div
            className={classes.responsiveChoosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                marginTop: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            key={index}
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderFilterContainer = () => {
        return <div
            className={classes.filterContainer}
        >
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content,
                    marginBottom: spaces.content,
                    gap: spaces.content
                }}
            >
                <Button
                    title={localize("city")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setSelectDialogHistoryVisible(true);
                    }}
                    iconDirection="right"
                    icon={() => <ArrowDownIcon
                        size={15}
                        color={colors.primary}
                    />}
                />
                <SelectDialog
                    data={CANDIDATE_HISTORY.map((item, index) => {
                        return {
                            __title: localize(item.title),
                            __originalIndex: index,
                            __key: item.value,
                        };
                    })}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setSelectDialogHistoryVisible(false);
                    }}
                    isVisible={selectDialogHistoryVisible}
                    setSelectedItems={(item) => {
                        setSelectedHistory(item.map((c_item) => {
                            return {
                                __originalIndex: c_item.__originalIndex,
                                __title: c_item.__title,
                                __key: c_item.__key
                            };
                        }));
                    }}
                    multiSelect={true}
                    selectedItems={selectedHistory}
                    isNeedConfirm={true}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <Button
                    title={localize("by-application-date")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setSelectDialogCitiesVisible(true);
                    }}
                    iconDirection="right"
                    icon={() => <ArrowDownIcon
                        size={15}
                        color={colors.primary}
                    />}
                />
                <SelectDialog
                    data={getMyApplication ? getMyApplication.cities.map((item, index) => {
                        return {
                            __title: item.localizedText,
                            __originalIndex: index,
                            __key: item._id,
                        };
                    }) : []}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setSelectDialogCitiesVisible(false);
                    }}
                    isVisible={selectDialogCitiesVisible}
                    setSelectedItems={(item) => {
                        setSelectedCities(item.map((c_item) => {
                            return {
                                __originalIndex: c_item.__originalIndex,
                                __title: c_item.__title,
                                __key: c_item.__key
                            };
                        }));
                    }}
                    multiSelect={false}
                    selectedItems={selectedCities}
                    isNeedConfirm={true}
                    onOk={({
                        closeSheet, onSuccess 
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
        </div>;
    };

    const renderHeader = () => {
        const title = getMyApplication
            ? `${getMyApplication.totalApplicationCount} ${localize("application")}`
            : localize("application-has-not-been-made-yet");

        const subTitle = getMyApplication
            ? `${localize("all-my-application-advertisements")}`
            : localize("all-listings");

        return <div
            style={{
                padding: spaces.container
            }}>
            <div
                style={{
                    marginBottom: spaces.content 
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("management-of-application-advertisements")}
                </Text>
            </div>
            <div>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {title}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.content 
                }}>
                <Text
                    variant="body4-regular"
                    color="textDark"
                >
                    {subTitle}
                </Text>
            </div>
            {renderFilterContainer()}
            {renderResponsiveChoosenFilters()}
        </div>;
    };
    
    return <div>
        {renderHeader()}
    </div>;
};

export default HeaderLeftMobileContainer;