import React from "react";
import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const CircleExit = ({
    color = "#45BBD4",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2Zm4.3 14.3a.996.996 0 0 1-1.41 0L12 13.41 9.11 16.3a.996.996 0 1 1-1.41-1.41L10.59 12 7.7 9.11A.996.996 0 1 1 9.11 7.7L12 10.59l2.89-2.89a.996.996 0 1 1 1.41 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default CircleExit;
