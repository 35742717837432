import React, {
    FC
} from "react";
import {
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import IRowCardType from "./types";
import {
    useStyles 
} from "./styles";
import {
    ChrevronRightIcon
} from "../../assets/svgr";

const RowCard: FC<IRowCardType> = ({
    renderRightIcon: RightIconProp,
    rightIconOnClick,
    renderTitle: renderTitleProp,
    renderLeft: renderLeftProp,
    renderTool: renderToolProp,
    isShowRightIcon = true,
    className,
    disabled,
    onClick,
    title,
    style
}) => {
    const classes = useStyles();
    
    const {
        disabled: disabledStyle,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderLeft = () => {
        if(renderLeftProp) {
            return renderLeftProp();
        }

        return null;
    };

    const renderRight = () => {
        if(!isShowRightIcon) {
            return null;
        }

        if(RightIconProp) {
            return <div
                onClick={() => {
                    if(rightIconOnClick) {
                        rightIconOnClick();
                    }
                }}
            >
                <RightIconProp
                    color={colors.textSecondary}
                    size={24}
                />
            </div>;
        }

        return <ChrevronRightIcon
            color={colors.textSecondary}
            size={24}
        />;
    };

    const renderTitle = () => {
        if(renderTitleProp) {
            return renderTitleProp(title);
        }

        return <Text
            className={classes.title}
            variant="body2-regular"
        >
            {title}
        </Text>;
    };

    const renderTool = () => {
        if(renderToolProp) {
            return renderToolProp();
        }

        return null;
    };

    return <div
        onClick={disabled ? undefined : () => {
            if(onClick) onClick();
        }}
        className={`${classes.container} ${className}`}
        style={{
            paddingTop: spaces.container,
            paddingBottom: spaces.container,
            ...style,
        }}
    >
        {renderLeft()}
        {renderTitle()}
        {renderTool()}
        {renderRight()}
    </div>;
};
export default RowCard;
