import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
    },
    advertDetailsContent: {
        flexDirection: "column",
        display: "flex"
    },
    buttonContent: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex",
    },
    generalInfo: {
        flexDirection: "column",
        display: "flex"
    },
    candidateProcessContent: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    candidateProcessContainer: {
        flexDirection:"column",
        display: "flex",
        width: "100%"
    },
    closeIconBorder: {
        borderStyle: "solid",
        display: "flex"
    },
    closeIcon: {
        display: "flex"
    },
    candidateProcessTexts: {
        flexDirection: "column",
        display: "flex"
    },
    docmentProcessContainer: {
        flexDirection:"column",
        borderTopStyle: "solid",
        display: "flex",
        width: "100%"
    },
    docInitialContainer: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    docVariantIcon: {
        borderStyle: "solid",
        display: "flex"
    },
    docInitialContent: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    docInitialContentInner: {
        justifyContent: "space-between",
        borderBottomStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    docInitalContainerText: {
        flexDirection: "column",
        display: "flex"
    },
    downloadIconContainer: {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    docListContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    docListContent: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    editIcon: {
        cursor: "pointer"
    },
    docGeneralContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    docWaitingContainer: {
        alignContent: "center",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    rejectDocIcon : {
        cursor: "pointer",
        display: "flex"
    },
    downloadAllDocIcon : {
        borderLeftStyle: "solid",
        minHeight: 25
    },
    downloadAllDocCursor: {
        cursor: "pointer"
    },
    docEditContainer: {
        alignContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    verticleLine: {
        borderLeftStyle: "solid",
        minHeight: 25
    },
    ptProcessContainer: {
        flexDirection:"column",
        display: "flex",
        width: "100%"
    },
    ptProcessContent: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
    },
    ptProcessInnerContent: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    ptIconContatiner: {
        borderStyle: "solid",
        alignItems: "center",
        display: "flex"
    },
    ptTextContent: {
        flexDirection: "column",
        display: "flex"
    },
    line: {
        width: "100%",
        height: "1px"
    },
    resumeHeader: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },educationHeader: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    capabilitiesHeader: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    ptResultCardContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    arrowDownContainer: {
        cursor: "pointer",
        rotate: "270deg"
    },
});
export default useStyles;