import {
    ChangeEvent,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    WidgetAddIcon,
    CircleIcon
} from "../../../../assets/svgr";
import {
    PersonalInfoTypes
} from "./types";
import {
    RESTService
} from "../../../../services/restAPI";
import {
    GetMyProfileResponseTypes
} from "../../../../services/restAPI/actions/getMyProfile/types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import PersonalInfo from "./views/personelInfo";
import {
    getErrorText,
    handleUpload
} from "../../../../utils";
import {
    EmployerProfileLeftContainer 
} from "../../../../components";

const EmployerProfile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const classes = useStyles();
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();
    
    const [getMyProfile, setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [loadingProfilePhoto, setLoadingProfilePhoto] = useState(true);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfoTypes>({
        areaCode: {
            countryTranslationKey: "",
            flagUnicode: "",
            country: "",
            value: "",
            _id: "",
        },
        companyOccupation: {
            localizedText: "",
            _id: ""
        },
        occupation: {
            localizedText: "",
            _id: ""
        },
        district: {
            localizedText: "",
            _id: ""
        },
        sector: {
            localizedText: "",
            _id: ""
        },
        city: {
            localizedText: "",
            _id: ""
        },
        companyTitle: "",
        profileURL: "",
        address: "",
        website: "",
        title: "", 
        about: "",
        mail: ""
    });
    const [activePage, setActivePage] = useState(location.pathname);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleGetMyProfile();
    },[]);

    const handleGetMyProfile = () => {
        setLoading(true);
    
        RESTService.action("GetMyProfile", {
        })
            .then((res) => {
                setGetMyProfile(res);
    
                if (res.companyPayload) {
                    const updatedPersonalInfo = {
                        ...personalInfo,
                        occupation: {
                            localizedText: res.companyPayload.occupation.localizedText,
                            _id: res.companyPayload.occupation._id,
                        },
                        companyOccupation: {
                            localizedText: res.companyPayload.occupation.localizedText,
                            _id: res.companyPayload.occupation._id,
                        },
                        district: {
                            localizedText: res.companyPayload.district.localizedText,
                            _id: res.companyPayload.district._id,
                        },
                        sector: {
                            localizedText: res.companyPayload.sector.localizedText,
                            _id: res.companyPayload.sector._id,
                        },
                        city: {
                            localizedText: res.companyPayload.city.localizedText,
                            _id: res.companyPayload.city._id,
                        },
                        areaCode: res.companyPayload.phoneAreaCode,
                        profileURL: res.companyPayload.photoURL,
                        companyTitle: res.companyPayload.title,
                        address: res.companyPayload.address,
                        website: res.companyPayload.website,
                        about: res.companyPayload.about,
                        title: res.companyPayload.title,
                        mail: res.companyPayload.mail,
                    };
    
                    setPersonalInfo(updatedPersonalInfo);
    
                    const localStorageData = window.localStorage.getItem("company");

                    if (localStorageData) {
                        const user = JSON.parse(localStorageData);

                        const updatedUser = {
                            ...user,
                            ...updatedPersonalInfo
                        };

                        localStorage.setItem("company", JSON.stringify(updatedUser));
                    }
                }
    
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetMyProfile Err:", err);
                setLoading(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <PersonalInfo
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                getMyProfile={getMyProfile}
                loading={loading} />
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon 
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ıt-was-added")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    if(loading && loadingProfilePhoto) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("company-profile-screen")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <EmployerProfileLeftContainer isPersonalInfo={true}/>
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
        {renderSuccessDialog()}
    </div>;
};

export default EmployerProfile;
