import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        position: "sticky",
        maxWidth: "15%",
        display: "flex",
        height: "100%",
        minWidth: 200,
        width: "100%",
        "@media (max-width: 1040px)": {
            boxSizing: "border-box",
            position: "relative",
            maxWidth: "unset",
            display: "flex"
        }
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    img: {
        objectFit: "cover",
        cursor: "pointer",
        borderRadius: 65,
        opacity: 0.9,
        height: 130,
        width: 130
    },
    profileImageContainer: {
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
        borderRadius: 65,
        height: 130,
        width: 130
    },
    icon: {
        backgroundColor: "rgba(0, 0, 0, 0.075)",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        cursor: "pointer",
        display: "flex",
        width: "100%",
        bottom: 0,
        "&:hover": {
            opacity: 0.75
        },
        "& > svg": {
            transform: "rotateZ(180deg)" // TODO: This topic will be discuss.
        }
    },
    profileTitleContainer: {
        justifyContent: "center",
        display: "flex",
        width: "100%"
    },
    profileTitle: {
        textAlign: "center",
        width: "100%"
    },
    occupationContainer: {
        textAlign: "center"
    }
});

export default useStyles;