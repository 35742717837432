import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    WorkExperienceTypes,
    SelectedItem
} from "./types";
import {
    PlusIcon
} from "../../../../../../../../assets/svgr";
import NewJobExperience from "../../../../../../../../components/newWorkExperience";
import moment from "moment";
import Info from "../../../../../../../../assets/svgr/infoIcon";

const JobExperience = ({
    setWorkExperience,
    workExperience,
    setErrors,
    errors
}: WorkExperienceTypes) =>  {
    const classes = useStyles();
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const renderJobExperience = () => {
        const onChangeCompany = (text: string, index: number) => {
            let newData = JSON.parse(JSON.stringify(workExperience));
            newData[index].companyName = text;
            setWorkExperience(newData);
        };
        const onChangeExperienceLevel = (selectedItem: SelectedItem, index: number) => {
            let newData = JSON.parse(JSON.stringify(workExperience));
            newData[index].jobTitle.localizedText = selectedItem.localizedText;
            newData[index].jobTitle._id = selectedItem._id;
            setWorkExperience(newData);
        };
        
        const handleChange = (type: "startDate" | "endDate", date: string, index: number) => {
            setWorkExperience(prevData => {
                const updatedData = [...prevData];
                const currentItem = updatedData[index];
        
                if (type === "endDate" && currentItem.isStillWorkHere) {
                    return prevData;
                }
                updatedData[index] = {
                    ...currentItem,
                    [type]: moment(date).toISOString()
                };
        
                return updatedData;
            });
        };

        const onChangeIsWork = (index: number) => {
            let newData = JSON.parse(JSON.stringify(workExperience));
            newData[index].isStillWorkHere = !newData[index].isStillWorkHere;
            setWorkExperience(newData);
        };

        const addNewWorkExperience = () => {
            let _experience = JSON.parse(JSON.stringify(workExperience));
            _experience.push({
                isStillWorkHere: false,
                jobTitle: {
                    localizedText: "",
                    _id: ""
                },
                companyName: "",
                startDate: "",
                endDate: "",
            });
            setWorkExperience(_experience);
        };

        const renderNewJobExperince = () => {
            return workExperience.map((item, index: number) => (
                <NewJobExperience
                    onChangeExperienceLevel={onChangeExperienceLevel}
                    removeWorkExperience={removeWorkExperience}
                    onChangeCompany={onChangeCompany}
                    onChangeIsWork={onChangeIsWork}
                    workExperience={workExperience}
                    handleChange={handleChange}
                    setErrors={setErrors}
                    showExitIcon={true}
                    errors={errors}
                    index={index}
                    data={item}
                    key={index}
                />
            ));
        };

        const removeWorkExperience = (index: number) => {
            let updatedErrors = JSON.parse(JSON.stringify(errors));
            const nameErrorCompanyName = `workExperience-${index}-companyName-length-error`;
            const nameErrorJobTitleID = `workExperience-${index}-jobTitleID-length-error`;
            const nameErrorStartDate = `workExperience-${index}-startDate-length-error`;
            const nameErrorEndDate = `workExperience-${index}-endDate-length-error`;
        
            updatedErrors = updatedErrors.filter((error: string) =>
                error !== nameErrorCompanyName &&
                error !== nameErrorJobTitleID &&
                error !== nameErrorStartDate &&
                error !== nameErrorEndDate
            );
            setErrors(updatedErrors);

            let _experience = JSON.parse(JSON.stringify(workExperience));
            _experience.splice(index, 1);
            setWorkExperience(_experience);
        };

        const renderEmptyJobExperienceInfo = () => {
            return <div
                className={classes.emptyComapnyInfo}
                style={{
                    padding: `${spaces.inline}px ${spaces.content}px`,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    marginTop: spaces.content,
                    gap: spaces.inline,
                }}
            >
                <Info
                    color={colors.primary}
                    size={24}
                />
                <Text
                    variant="body3-regular"
                >
                    {localize("empty-company-about")}
                </Text>
            </div>;
        };

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("work-experience")}  
            </Text>
            {workExperience.length === 0 && renderEmptyJobExperienceInfo()}
            {renderNewJobExperince()}
            <Button
                title={localize("add-work-experience")}
                onClick={() => addNewWorkExperience()}
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />
                }
                variant='ghost'
            />
        </div>;
    };

    return <div>
        {renderJobExperience()}
    </div>;
};

export default JobExperience;
