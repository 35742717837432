import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Exit = ({
    color= "#9AA6B8",
    size = 23,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 23 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={23}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            d="M12.308 10.481c0-.422-.362-.764-.808-.764-.446 0-.808.342-.808.764v2.038c0 .422.362.764.808.764.446 0 .808-.342.808-.764V10.48Z"
        />
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            d="m13.357 1.339 2.621.413c1.245.197 2.253.356 3.042.578.822.231 1.5.555 2.023 1.139.523.583.749 1.27.855 2.075.102.772.102 1.74.102 2.933v6.046c0 1.194 0 2.16-.102 2.933-.106.805-.332 1.492-.855 2.075-.523.584-1.201.908-2.023 1.14-.789.221-1.797.38-3.041.577l-2.622.413c-1.112.175-2.032.32-2.767.337-.77.018-1.485-.1-2.081-.577-.503-.403-.753-.927-.883-1.515h-.492c-1.22 0-2.216 0-3.004-.1-.822-.105-1.536-.332-2.105-.87-.57-.539-.809-1.214-.92-1.992C1 16.199 1 15.256 1 14.102V8.898c0-1.154 0-2.097.106-2.842.11-.778.35-1.453.92-1.992.568-.538 1.282-.765 2.104-.87.788-.1 1.785-.1 3.004-.1h.492c.13-.587.38-1.112.883-1.515.596-.478 1.31-.595 2.081-.577.735.016 1.655.162 2.767.337ZM7.462 16.936c0 .525 0 1.006.012 1.441h-.282c-1.292 0-2.18-.001-2.846-.086-.644-.082-.96-.229-1.179-.436-.219-.207-.374-.506-.46-1.115-.09-.63-.092-1.47-.092-2.693V8.953c0-1.222.002-2.062.092-2.693.086-.61.241-.908.46-1.115.22-.207.535-.354 1.179-.436.667-.085 1.554-.086 2.846-.086h.282c-.012.435-.012.916-.012 1.441v10.872ZM10.55 2.53c-.584-.014-.832.082-.998.215-.166.133-.307.349-.389.896-.085.568-.087 1.341-.087 2.481v10.756c0 1.14.002 1.913.087 2.481.082.547.223.763.389.896s.414.229.998.216c.607-.014 1.413-.14 2.601-.327l2.508-.395c1.31-.207 2.218-.352 2.9-.543.659-.186 1.007-.391 1.25-.663.244-.271.4-.63.485-1.276.088-.667.09-1.538.09-2.795V8.528c0-1.257-.002-2.128-.09-2.795-.085-.646-.242-1.005-.485-1.276-.243-.272-.591-.477-1.25-.663-.682-.191-1.59-.336-2.9-.542l-2.508-.396c-1.188-.187-1.994-.313-2.601-.326Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Exit;
