import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Hourglass = ({
    size = 30,
    color = "#F8AA1C",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M5.278 2.819C6.12 1 9.014 1 14.8 1s8.68 0 9.522 1.819c.073.156.134.319.183.486.573 1.942-1.473 4.193-5.564 8.694L16.2 15l2.74 3c4.092 4.502 6.138 6.753 5.565 8.695-.049.167-.11.33-.183.486C23.48 29 20.586 29 14.8 29s-8.68 0-9.522-1.819a3.526 3.526 0 0 1-.183-.486c-.573-1.942 1.473-4.193 5.564-8.694L13.4 15l-2.74-3C6.567 7.497 4.521 5.246 5.094 3.304c.049-.167.11-.33.183-.486Z"
        />
    </svg>;
};
export default Hourglass;