import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme, 
    RadioButton,
    SelectBox,
    TextInput,
    CheckBox,
    Loading,
    Text,
    Button
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    MagnifierIcon
} from "../../../../../assets/svgr";
import {
    IFiltersContainerProps
} from "./types";
import {
    DISABLED_ADS_FILTERS,
    DATES_FILTER
} from "../../constants";
import {
    GetContractTypesResponseObjectType
} from "../../../../../services/restAPI/actions/getContractTypes/types";
import {
    WorkingTypeObjectType
} from "../../../../../services/restAPI/actions/getWorkingTypes/types";
import {
    CitiesResponseObjectType
} from "../../../../../services/restAPI/actions/getCities/types";
import {
    ExperienceLevelObjectType
} from "../../../../../services/restAPI/actions/getExperienceLevels/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../services/restAPI/actions/getOccupation/types";
import {
    uuid
} from "../../../../../utils";
import {
    SelectedExperienceLevelType,
    SelectedOccupationType,
    SelectedContractType,
    SelectedWorkingType
} from "../../types";

const FiltersContainer = ({
    setSelecetedExperienceLevel,
    setSelectedContractType,
    selectedExperienceLevel,
    setIsAdvertForDisabled,
    setSelectedWorkingType,
    setSelectedOccupation,
    setSelectedAdvertDate,
    setHideAppliedAdverts,
    selectedContractType,
    selectedWorkingType,
    isAdvertForDisabled,
    hideAppliedAdverts,
    selectedAdvertDate,
    selectedOccupation,
    setSelectedCities,
    selectedCities,
    onSearch
}: IFiltersContainerProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [contractTypesData, setContractTypesData] = useState<Array<GetContractTypesResponseObjectType>>([]);
    const [positionLevelsData, setPositionLevelsData] = useState<Array<ExperienceLevelObjectType>>([]);
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [workingTypesData, setWorkingTypesData] = useState<Array<WorkingTypeObjectType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);

    //Occupation Pagination States
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(false);
    const [occupationPage, setOccupationPage] = useState<number>(1);
    
    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getworkingTypesData();
        getPositionLevels();
        getContractType();
        getOccupations();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);

    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getworkingTypesData = () => {
        RESTService.action("GetWorkingTypes",{
        })
            .then((res) => {
                setWorkingTypesData(res.payload);
            })
            .catch((err) => {
                console.error("GetWorkingTypes Err:", err);
            });
    };

    const getContractType = () => {
        RESTService.action("GetContractTypes",{
        })
            .then((res) => {
                setContractTypesData(res.payload);
            })
            .catch((err) => {
                console.error("GetContractTypes Err:", err);
            });
    };

    const getPositionLevels = () => {
        RESTService.action("GetExperienceLevels",{
        })
            .then((res) => {
                setPositionLevelsData(res.payload);
            })
            .catch((err) => {
                console.error("GetExperienceLevels Err:", err);
            });
    };

    const onSearchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        params.language = _langugage ?? "tr-TR";

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
                /*  if(res.payload.length === 0 || res.payload.length < 20) {
                    setOccupationEndOfData(true);
                } */
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const onChangeWorkingType = (item: WorkingTypeObjectType) => {
        if(selectedWorkingType.length) {
            let _selectedWorkingType: Array<SelectedWorkingType> = JSON.parse(JSON.stringify(selectedWorkingType));
            const isExistsInSelectedData = selectedWorkingType.findIndex(e => e.workingTypeID === item._id);
            

            if(isExistsInSelectedData !== -1) {
                _selectedWorkingType.splice(isExistsInSelectedData, 1);
                setSelectedWorkingType(_selectedWorkingType);
            } else {
                _selectedWorkingType.push({
                    ...item,
                    workingTypeID: item._id,
                    localizedText: item.localizedText
                });
            }
        } else {
            setSelectedWorkingType([{
                localizedText: item.localizedText,
                workingTypeID: item._id
            }]);
        }
    };

    const onChangeContractType = (item: GetContractTypesResponseObjectType) => {
        if(selectedContractType.length) {
            let _selectedContractType: Array<SelectedContractType> = JSON.parse(JSON.stringify(selectedContractType));
            const isExistsInSelectedData =selectedContractType.findIndex(e => e.contractTypeID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedContractType.splice(isExistsInSelectedData, 1);
                setSelectedContractType(_selectedContractType);
            } else {
                _selectedContractType.push({
                    ...item,
                    contractTypeID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedContractType(_selectedContractType);
            }
        } else {
            setSelectedContractType([
                {
                    localizedText: item.localizedText,
                    contractTypeID: item._id
                }
            ]);
        }
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e.occupationID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    ...item,
                    occupationID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    occupationID: item._id
                }
            ]);
        }
    };

    const onChangePosition = (item: ExperienceLevelObjectType) => {
        if(selectedExperienceLevel.length) {
            let _selectedExperienceLevel: Array<SelectedExperienceLevelType> = JSON.parse(JSON.stringify(selectedExperienceLevel));

            const isExistsInSelectedData = selectedExperienceLevel.findIndex(e => e.experienceLevelID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedExperienceLevel.splice(isExistsInSelectedData, 1);
                setSelecetedExperienceLevel(_selectedExperienceLevel);
            } else {
                _selectedExperienceLevel.push({
                    ...item,
                    experienceLevelID: item._id,
                    localizedText: item.localizedText
                });
                setSelecetedExperienceLevel(_selectedExperienceLevel);
            }
        } else {
            setSelecetedExperienceLevel([
                {
                    localizedText: item.localizedText,
                    experienceLevelID: item._id
                }
            ]);
        }
    };

    const renderCities = () => {
        let initialCities: Array<CitiesResponseObjectType>| [] = selectedCities.map(e => {
            return {
                localizedText: e.localizedText,
                key: e.cityID,
                _id: e.cityID
            };
        });
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                paddingTop: spaces.container * 1.75,
                borderBottomColor: colors.stroke,
                borderBottomWidth: borders.line,
                paddingBottom: spaces.container,
                gap: spaces.container * 1.25
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
            >
                {localize("location")}
            </Text>
            <SelectBox
                titleExtractor={(e) => e.localizedText}
                initialSelectedItems={initialCities}
                inputTitle={localize("location")}
                title={localize("location")}
                keyExtractor={(e) => e._id}
                spreadBehaviour='stretch'
                isNeedConfirm={true}
                isSearchable={true}
                emptyContent={() => {
                    return <Text>
                        {localize("no-result-found")}
                    </Text>;
                }}
                multiSelect={true}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let _cities: Array<{localizedText: string, cityID: string}> = selectedItems.map((item) => {
                        return { //@ts-ignore
                            localizedText: item.localizedText,
                            //@ts-ignore
                            cityID: item._id
                        };
                    });

                    setSelectedCities(_cities);
                    closeSheet();
                    onSuccess();
                }}
                data={citiesData}
            />
        </div>;
    };

    const renderWorkingPrefrence = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("work-preference")}
            </Text>
            {
                workingTypesData.map((item,index) => {
                    let isSelected = false;

                    if(selectedWorkingType.length) {
                        isSelected =  selectedWorkingType.findIndex((e) => e.workingTypeID === item._id) !== -1;
                    }

                    return <CheckBox
                        key={index}
                        onChange={() => {
                            onChangeWorkingType(item);
                        }}
                        title={item.localizedText}
                        isSelected={isSelected}
                    />;
                })
            }
        </div>;
    };

    const renderContractType = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("working-type")}
            </Text>
            {
                contractTypesData.map((item,index) => {
                    let isSelected = false;

                    if(selectedContractType.length) {
                        isSelected =  selectedContractType.findIndex((e) => e.contractTypeID === item._id) !== -1;
                    }

                    return <CheckBox
                        key={index}
                        title={item.localizedText}
                        isSelected={isSelected}
                        onChange={() => {
                            onChangeContractType(item);
                        }}
                    />;
                })
            }
            
        </div>;
    };

    const renderAdvertDateFilter = () => {
        return <div
            className={classes.dateContainer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("ads-date")}
            </Text>
            <div>
                {DATES_FILTER.map((item) => {
                    let isSelected = false;

                    if(selectedAdvertDate) {
                        isSelected =  selectedAdvertDate === item.value ? true : false;
                    }

                    return <RadioButton
                        title={localize(item.localizedText)}
                        isSelected= {isSelected}
                        onChange={() => {
                            setSelectedAdvertDate(item.value);
                        }}
                        key={item.id}
                    />;
                })}
            </div>
            
        </div>;
    };

    const renderOccupations = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("job")}
            </Text>
            <TextInput
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                placeholder={localize("search-job")}
                onChangeText={(e) => {
                    setOccupationLoading(true);
                    setSearchOccupationPage(1);
                    setSearhOccupation(e);
                    onSearchOccupation(e);
                }}
            />
            <div
                className={classes.occupationContent}
                onScroll={(e) => {
                    if(occupationLoading) {
                        return;
                    }
                    if(occupationEndOfData) {
                        return;
                    }
                    if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                        if(searchOccupation.length) {
                            onSearchOccupation(searchOccupation);
                        } else {
                            getOccupations();
                        }
                    }
                }}
            >
                {
                    occupationRenderData.map((item) => {
                        let isSelected = false;

                        if(selectedOccupation.length) {
                            isSelected =  selectedOccupation.findIndex((e) => e.occupationID === item._id) !== -1;
                        }

                        return <CheckBox
                            title={item.localizedText}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeOccupation(item);
                            }}
                            key={item._id}
                        />;
                    })
                }
                {
                    occupationLoading ? <Loading/> : null
                }
            </div>
        </div>;
    };

    const renderPositions = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("position-level")}
            </Text>
            {
                positionLevelsData.map((item) => {
                    let isSelected = false;

                    if(selectedExperienceLevel.length) {
                        isSelected =  selectedExperienceLevel.findIndex((e) => e.experienceLevelID === item._id) !== -1;
                    }

                    return <CheckBox
                        title={item.localizedText}
                        isSelected={isSelected}
                        key={item._id}
                        onChange={() => {
                            onChangePosition(item);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderDisabledAds = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("ads-for-disabled")}
            </Text>
            {
                DISABLED_ADS_FILTERS.map((item) => {
                    let isSelected = false;

                    if(isAdvertForDisabled) {
                        isSelected =  isAdvertForDisabled === item.value ? true : false;
                    }

                    return <RadioButton
                        title={localize(item.localizedText)}
                        isSelected={isSelected}
                        key={item.id}
                        onChange={() => {
                            setIsAdvertForDisabled(item.value);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderOtherFilters = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("other-ads-filters")}
            </Text>
            {/* <CheckBox
                title="İncelenen ilanları gizle"
                key={uuid()}
            /> */}
            <CheckBox
                title={localize("hide-aplied-ads")}
                isSelected={hideAppliedAdverts}
                onChange={() => {
                    setHideAppliedAdverts(!hideAppliedAdverts);
                }}
                key={uuid()}
            />
            {/* <CheckBox
                title="Sadece bir kez yayınlanan ilanlar"
                key={uuid()}
            /> */}
        </div>;
    };

    const renderFilterIt = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Button
                title={localize("filter-it")}
                spreadBehaviour="stretch"
                onClick={onSearch}
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.white,
            borderRadius: radiuses.hard,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderCities()}
        {renderWorkingPrefrence()}
        {renderContractType()}
        {renderAdvertDateFilter()}
        {renderOccupations()}
        {renderPositions()}
        {renderDisabledAds()}
        {renderOtherFilters()}
        {renderFilterIt()}
    </div>;
};

export default FiltersContainer;
