import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media (max-width: 1040px)": {
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            flexDirection: "column",
            display:"flex",
            width: "100%"
        },
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        position: "sticky",
        maxWidth: "15%",
        display: "flex",
        height: "100%",
        minWidth: 230,
        width: "100%",
        "@media (max-width: 1040px)": {
            position: "relative",
            maxWidth: "unset",
            display: "flex"
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        minHeight: 600,
        height: "100%",
        width: "73%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        width: "100%",
        "@media (max-width: 1400px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%",
        height: 1
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    loadingContainer: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    userInfoHeaderContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    userInfoHeaderImageContent: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    userInfoHeaderImage: {
        borderStyle: "solid",
        objectFit: "cover",
        height: 130,
        width: 130
    },
    userInfoHeaderText: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    userInfoHeaderEndText: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    experienceContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    experienceCardContainer: {
        justifyContent: "center",
        flexWrap: "wrap",
        display: "flex"
    },
    educationContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    educationCardContainer: {
        justifyContent: "center",
        flexWrap: "wrap",
        display: "flex"
    },
    licanceOrCertificatesContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    licanceOrCertificatesCardContainer: {
        justifyContent: "center",
        flexWrap: "wrap",
        display: "flex"
    },
    capabilitiesContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex"
    },
    assoaciationsContainer: {
        flexDirection: "column",
        display: "flex"
    },
    hobbiesContainer: {
        flexDirection: "column",
        display: "flex"
    },
    languagesContainer: {
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        flexWrap: "wrap",
    },
    capabilitiesTopContainer: {
        justifyContent: "start",
        flexWrap: "wrap",
        display: "flex"
    }
});
export default useStyles;