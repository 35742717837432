import React from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    Text
} from "isinolacak-web-cl";
import {
    HobbiesTypes
} from "./type";

const Hobbies = ({
    setHobbies,
    setErrors,
    hobbies,
    errors
}: HobbiesTypes) =>  {
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const renderHobbies = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content* 2,
                    marginTop: spaces.content * 2
                }}
            >
                <Text
                    variant='header5-medium'
                    color='textDark'
                >
                    {localize("other")}
                </Text>
            </div>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("hobbies")}
            </Text>
            <TextInput
                infoText={errors.includes("hobbies-length-error") ? localize("ıt-must-be-at-least-3-characters") : undefined}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "hobbies-length-error";
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                isError={errors.some(err => err === "hobbies-length-error")}
                onChangeText={(text) => setHobbies(text)}
                placeholder={localize("add")}
                initialValue={hobbies}
                style={{
                    marginTop: spaces.content
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderHobbies()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default Hobbies;
