import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Profile = ({
    color = "#000",
    size = 18,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 18 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M9 1.688a3.563 3.563 0 1 0 0 7.125 3.563 3.563 0 0 0 0-7.126ZM6.563 5.25a2.438 2.438 0 1 1 4.875 0 2.438 2.438 0 0 1-4.875 0ZM9 9.938c-1.735 0-3.334.394-4.518 1.06-1.167.657-2.044 1.652-2.044 2.877v.076c-.001.872-.002 1.965.957 2.747.472.384 1.132.657 2.025.838.895.181 2.06.276 3.58.276s2.686-.095 3.58-.276c.893-.18 1.553-.454 2.025-.838.96-.782.958-1.875.958-2.747v-.076c0-1.226-.878-2.22-2.045-2.877-1.184-.666-2.783-1.06-4.518-1.06Zm-5.437 3.937c0-.639.466-1.331 1.47-1.896.987-.555 2.389-.916 3.967-.916 1.579 0 2.98.36 3.967.916 1.005.565 1.47 1.258 1.47 1.896 0 .98-.03 1.533-.542 1.95-.278.227-.742.448-1.538.608-.792.16-1.876.255-3.357.255-1.48 0-2.564-.094-3.357-.255-.795-.16-1.26-.381-1.538-.608-.512-.417-.542-.97-.542-1.95Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Profile;
