import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Youtube = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="m12.2 18.75 7.266-4.125L12.2 10.5v8.25ZM28.384 7.984c.182.646.308 1.512.392 2.612.098 1.1.14 2.049.14 2.874L29 14.625c0 3.011-.224 5.225-.616 6.641-.35 1.238-1.162 2.035-2.422 2.379-.658.179-1.862.303-3.71.385-1.82.096-3.486.137-5.026.137L15 24.25c-5.866 0-9.52-.22-10.962-.605-1.26-.344-2.072-1.141-2.422-2.379-.182-.646-.308-1.512-.392-2.612-.098-1.1-.14-2.049-.14-2.874L1 14.625c0-3.011.224-5.225.616-6.641.35-1.238 1.162-2.035 2.422-2.379.658-.179 1.862-.303 3.71-.385a95.002 95.002 0 0 1 5.026-.138L15 5c5.866 0 9.52.22 10.962.605 1.26.344 2.072 1.141 2.422 2.379Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default Youtube;
