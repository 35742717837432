import actions from "../actions";

export let SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS
    ? process.env.REACT_APP_SERVER_ADDRESS
    : "https://gw.isinolacak.com";

export let SERVER_ADDRESS_FRONT = process.env.REACT_APP_SERVER_ADDRESS_FRONT
    ? process.env.REACT_APP_SERVER_ADDRESS_FRONT
    : "https://front.isinolacak.com";

if (process.env.REACT_APP_ENVIRONMENT) {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
        SERVER_ADDRESS_FRONT = "https://front.isinolacak.com";
        SERVER_ADDRESS = "https://gw.isinolacak.com";
    }

    if(process.env.REACT_APP_ENVIRONMENT === "custom") {
        SERVER_ADDRESS = "http://192.168.136.179";
    }
}

export const SERVICE_EVENT_TYPES = {
    REFRESH_TOKEN: "REFRESH_TOKEN",
    GO_TO_LOGOUT: "GO_TO_LOGOUT"
};

export const SERVICE_CONFIGS: Record<
	keyof typeof actions,
	{
		isWorkedWithQueue: boolean;
	}
> = {
    // Authentication Actions:
    RefreshToken: {
        isWorkedWithQueue: false
    },
    Register: {
        isWorkedWithQueue: false
    },
    ReSendSMSOTP: {
        isWorkedWithQueue: false
    },
    SMSOTP: {
        isWorkedWithQueue: false
    },
    Login: {
        isWorkedWithQueue: false
    },
    ResetNewPassword: {
        isWorkedWithQueue: false
    },
    ResetPasswordCheckCode: {
        isWorkedWithQueue: false
    },
    StartResetPassword: {
        isWorkedWithQueue: false
    },
    CheckVersion: {
        isWorkedWithQueue: false
    },
    InitialConfigs: {
        isWorkedWithQueue: false
    },
    GetAreaCodes: {
        isWorkedWithQueue: false
    },
    GetSectors: {
        isWorkedWithQueue: false
    },
    GetOccupation: {
        isWorkedWithQueue: false
    },
    GetCompanySize: {
        isWorkedWithQueue: false
    },
    GetBlogs: {
        isWorkedWithQueue: false
    },
    GetBlog: {
        isWorkedWithQueue: false
    },
    GetAdvertsWithoutAuth: {
        isWorkedWithQueue: false
    },
    GetStaticDataWithoutAuth: {
        isWorkedWithQueue: false
    },
    GetPopularOccupations: {
        isWorkedWithQueue: false
    },
    GetPopularAdverts: {
        isWorkedWithQueue: false
    },
    GetBlogsForLandingPage: {
        isWorkedWithQueue: false
    },
    GetAdvertDetailWithoutAuth: {
        isWorkedWithQueue: false
    },
    GetLandingData: {
        isWorkedWithQueue: false
    },
    GetFeaturedAdverts: {
        isWorkedWithQueue: false
    },
    GetFAQsForAbout: {
        isWorkedWithQueue: false
    },

    // App Actions:
    Me: {
        isWorkedWithQueue: true
    },
    GetUserBasedAdvert: {
        isWorkedWithQueue: true
    },
    GetCities: {
        isWorkedWithQueue: true
    },
    CreateAdvert: {
        isWorkedWithQueue: true
    },
    GetEducationLevels: {
        isWorkedWithQueue: true
    },
    GetContractTypes: {
        isWorkedWithQueue: true
    },
    GetAdverts: {
        isWorkedWithQueue: true
    },
    GetAdvertWithDetail: {
        isWorkedWithQueue: true
    },
    AddAdvertToFavorite: {
        isWorkedWithQueue: true
    },
    RemoveAdvertFromFavorite: {
        isWorkedWithQueue: true
    },
    SearchAdverts: {
        isWorkedWithQueue: true
    },
    GetExperienceLevels: {
        isWorkedWithQueue: true
    },
    GetWorkingTypes: {
        isWorkedWithQueue: true
    },
    GetCapabilities: {
        isWorkedWithQueue: true
    },
    GetAdvertWithDetailForEmployer: {
        isWorkedWithQueue: true
    },
    RemoveAdvert: {
        isWorkedWithQueue: true
    },
    EditAdvert: {
        isWorkedWithQueue: true
    },
    GetPTAnswerTypes: {
        isWorkedWithQueue: true
    },
    CreatePT: {
        isWorkedWithQueue: true
    },
    GetMyActions: {
        isWorkedWithQueue: true
    },
    GetPTList: {
        isWorkedWithQueue: true
    },
    DeletePT: {
        isWorkedWithQueue: true
    },
    GetPerformanceTest: {
        isWorkedWithQueue: true
    },
    EditPT: {
        isWorkedWithQueue: true
    },
    AssignPTtoAdvert: {
        isWorkedWithQueue: true
    },
    ApplyToAdvert: {
        isWorkedWithQueue: true
    },
    SubmitPT: {
        isWorkedWithQueue: true
    },
    GetMessages: {
        isWorkedWithQueue: true
    },
    GetConversations: {
        isWorkedWithQueue: true
    },
    CreateCV: {
        isWorkedWithQueue: true
    },
    GetForeignLanguages: {
        isWorkedWithQueue: true
    },
    GetMyCV: {
        isWorkedWithQueue: true
    },
    EditCV: {
        isWorkedWithQueue: true
    },
    GetDocumentTemplate: {
        isWorkedWithQueue: true
    },
    GetDocumentTemplates: {
        isWorkedWithQueue: true
    },
    NewDocumentTemplate: {
        isWorkedWithQueue: true
    },
    EditDocumentTemplate: {
        isWorkedWithQueue: true
    },
    GetDocumentTypes: {
        isWorkedWithQueue: true
    },
    ConversationStart: {
        isWorkedWithQueue: true
    },
    UserPreference: {
        isWorkedWithQueue: true
    },
    ContactUs: {
        isWorkedWithQueue: true
    },
    Logout: {
        isWorkedWithQueue: true
    },
    GetPopularSearches: {
        isWorkedWithQueue: true
    },
    ArchiveConversation: {
        isWorkedWithQueue: true
    },
    ConversationEnd: {
        isWorkedWithQueue: true
    },
    RemoveArchiveConversation: {
        isWorkedWithQueue: true
    },
    GetConversationUserInfo: {
        isWorkedWithQueue: true
    },
    CheckChangeMailCode: {
        isWorkedWithQueue: true
    },
    CheckChangePhoneNumberCode: {
        isWorkedWithQueue: true
    },
    ChangeMail: {
        isWorkedWithQueue: true
    },
    ChangePhoneNumber: {
        isWorkedWithQueue: true
    },
    RevokeMailChange: {
        isWorkedWithQueue: true
    },
    EditUserProfile: {
        isWorkedWithQueue: true
    },
    DeleteMyAccount: {
        isWorkedWithQueue: true
    },
    ChangeCompanyMail: {
        isWorkedWithQueue: true
    },
    ChangeCompanyPhoneNumber: {
        isWorkedWithQueue: true
    },
    CheckChangeCompanyMailCode: {
        isWorkedWithQueue: true
    },
    CheckChangeCompanyPhoneNumberCode: {
        isWorkedWithQueue: true
    },
    EditCompanyProfile: {
        isWorkedWithQueue: true
    },
    GetDistricts: {
        isWorkedWithQueue: false
    },
    GetAdvertWithPAQForEmployer: {
        isWorkedWithQueue: false
    },
    GetAdvertsPAQ: {
        isWorkedWithQueue: true
    },
    GetWorkerProfileForEmployer: {
        isWorkedWithQueue: true
    },
    GetCompanyProfileForWorker: {
        isWorkedWithQueue: true
    },
    GetMyProfile: {
        isWorkedWithQueue: true
    },
    SetLanguage: {
        isWorkedWithQueue: true
    },
    DeleteDocumentTemplate: {
        isWorkedWithQueue: true
    },
    GetMyActionsEmployer: {
        isWorkedWithQueue: true
    },
    GetMyActionsWorker: {
        isWorkedWithQueue: true
    },
    GetPerformanceTestForEmployer: {
        isWorkedWithQueue: true
    },
    ReadMessages: {
        isWorkedWithQueue: true
    },
    GetMyUserPreferences: {
        isWorkedWithQueue: true
    },
    GetFAQs: {
        isWorkedWithQueue: true
    },
    GetMyApplication: {
        isWorkedWithQueue: true
    },
    UploadDocument:{
        isWorkedWithQueue: true
    },
    GetAplicationsForEmployer: {
        isWorkedWithQueue: true
    },
    EmployerHandleApplication: {
        isWorkedWithQueue: true
    },
    HandleSubmittedDocument: {
        isWorkedWithQueue: true
    },
    ExportApplicantDocument: {
        isWorkedWithQueue: true
    },
    ExportApplicantDocumentInBulk: {
        isWorkedWithQueue: true
    },
    SendMessage: {
        isWorkedWithQueue: true
    },
    CancelDocumentUpload: {
        isWorkedWithQueue: true
    },
    WorkerSearch: {
        isWorkedWithQueue: true
    },
    DeleteAdvert: {
        isWorkedWithQueue: true
    },
    CreateDraftAdvert: {
        isWorkedWithQueue: true
    },
    RepublishAdvert: {
        isWorkedWithQueue: true
    },
    SubscribeAlarm: {
        isWorkedWithQueue: true
    },
    DeleteAlarm: {
        isWorkedWithQueue: true
    },
    EditAlarm: {
        isWorkedWithQueue: true
    },
    GetAlarms: {
        isWorkedWithQueue: true
    },
    UpdateProfilePhoto: {
        isWorkedWithQueue: true
    },
    UpdateCompanyPhoto: {
        isWorkedWithQueue: true
    },
    GetAccessToken: {
        isWorkedWithQueue: true
    },
    SendMailVerifyMail: {
        isWorkedWithQueue: true
    },
    GetCurrentJobSeekers: {
        isWorkedWithQueue: true
    },
    GetAdvertsDataForDashboard: {
        isWorkedWithQueue: true
    },
    GetAgreement: {
        isWorkedWithQueue: false
    },
    GetMySubscriptionData: {
        isWorkedWithQueue: true
    },
    MakePaymentStatement: {
        isWorkedWithQueue: true
    },
    GetPackages: {
        isWorkedWithQueue: true
    },
    GetBanks: {
        isWorkedWithQueue: true
    },
    GetMyPurchaseHistory: {
        isWorkedWithQueue: true
    },
    GetMyPendingOrders: {
        isWorkedWithQueue: true
    },
    GetPushNotifications: {
        isWorkedWithQueue: true
    }
};
