import {
    IOCoreLocale,
    IOCoreTheme,
    ToolBox,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    IAdvertCardForEmployerProps,
    StickerObjectType,
} from "./types";
import VerticalDotsIcon from "../../assets/svgr/verticalDotsIcon";
import emptyProfile from "../../../app/assets/img/employer.png";
import  CloseIcon from "../../assets/svgr/closeIcon";
import BoostsTypeStickers from "../boostTypeStickers";

const AdvertCardForEmployer = ({
    actionOnClick,
    contractType,
    transactions,
    companyName,
    workingType,
    actionType,
    experience,
    jobImage,
    stickers,
    onClick,
    style,
    title,
    city
}: IAdvertCardForEmployerProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {companyName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {`${experience} • ${contractType} • ${city} (${workingType})`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
        >
            <div className={classes.informationsContent}
                onClick={onClick}
            >
                <img
                    src={jobImage && jobImage.length ? jobImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container
                    }}
                />
                {informationsContainer()}
            </div>
            {renderTransactionSelectionsButton()}
        </div>;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                stickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <div
                        key={item.key}
                        className={classes.tagContainer}
                    >
                        <BoostsTypeStickers
                            title={localizedText}
                            titleColor={item.color}
                            color={item.backColor}
                            icon={item.icon}
                            itemKey={item.key}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderActionButton = () => {
        if(!actionOnClick) {
            return null;
        }

        if(!actionType) {
            return null;
        }

        let currentActionTitle;

        if (actionType === "manage-advert") {
            currentActionTitle = localize("manage-advert");
        } 

        if (actionType === "republish") {
            currentActionTitle = localize("republish");
        }

        if (actionType === "create-advert-from-draft") {
            currentActionTitle = localize("create-advert-from-draft");
        }

        return <div
            style={{
                padding: spaces.container * 0.75,
                borderWidth: borders.line,
                borderTopColor: colors.stroke,
                borderTopStyle: "solid"
            }}
        >
            <Button
                title={currentActionTitle}
                spreadBehaviour="stretch"
                onClick={actionOnClick}
                variant="filled"
            />
        </div>;
    };

    const renderTransactionSelectionsButton = () =>{
        if(!transactions.length){
            return;
        }
        
        return <div>
            <ToolBox
                content={renderTransactionSelectionsContainer()}
            >
                <Button
                    onClick={()=>{
                    
                    }}
                    icon={() => <VerticalDotsIcon
                        color={colors.black100}
                        size={36}
                    />}
                    variant="ghost"
                />
            </ToolBox>
        </div> ;
    };

    const renderTransactionSelectionsContainer = () =>{
        return <div
            className={classes.transactionSelectionsContainer}
        >
            <div
                className={classes.buttonField}
            >
                <Button
                    onClick={()=>{
                    }}
                    icon={() => <CloseIcon
                        color={colors.textGrey}
                        size={14}
                    />}
                    variant="ghost"
                />
            </div>
            <div>
                {transactions.map((item, index) => (
                    <div key={index}>
                        <Button
                            onClick={()=>{
                                item.onClick();
                            }}
                            title={item.title}
                            variant="ghost"
                            color="textGrey"
                        />
                    </div>
                ))}
            </div>
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderInformations()}
        {renderTags()}
        {renderActionButton()}
    </div>;
};
export default AdvertCardForEmployer;
