import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Mail = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M2.64 5.64C1 7.28 1 9.92 1 15.2s0 7.92 1.64 9.56c1.64 1.64 4.28 1.64 9.56 1.64h5.6c5.28 0 7.92 0 9.56-1.64C29 23.12 29 20.48 29 15.2s0-7.92-1.64-9.56C25.72 4 23.08 4 17.8 4h-5.6C6.92 4 4.28 4 2.64 5.64Zm21.567 3.288a1.05 1.05 0 0 1-.135 1.479l-3.075 2.562c-1.24 1.034-2.247 1.873-3.134 2.443-.925.595-1.825.97-2.863.97-1.037 0-1.938-.375-2.863-.97-.887-.57-1.893-1.409-3.134-2.443l-3.075-2.562a1.05 1.05 0 1 1 1.344-1.614l3.023 2.52c1.306 1.087 2.213 1.84 2.978 2.333.741.477 1.244.637 1.727.637.483 0 .986-.16 1.727-.637.765-.492 1.672-1.246 2.978-2.334l3.023-2.519a1.05 1.05 0 0 1 1.479.135Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
        />
    </svg>;
};
export default Mail;
