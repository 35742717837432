import React, {
    useEffect,
    useState,
    useRef
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import useStyles from "./style";
import RegisterLoginImage from "../../../assets/img/registerLoginImage.svg";
import {
    InstagramIcon,
    FacebookIcon,
    YoutubeIcon,
    InfoIcon,
    XIcon,
    Logo
} from "../../../assets/svgr";
import {
    useLocation,
    useNavigate 
} from "react-router-dom";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    useGlobalState 
} from "../../../context/globalState";
import {
    ChatService 
} from "../../../services/chat";
import {
    getErrorText 
} from "../../../utils";

// TODO: All texts will be localized.
const SmsOTP = () => {
    const [globalState, setGlobalState] = useGlobalState();
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        state 
    } = useLocation();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        colors,
        spaces,
        borders
    } = IOCoreTheme.useContext();

    const [showResendButton, setShowResendButton] = useState(false);
    const [isCodeActive, setCodeActive] = useState(true);
    const [_userMail, setUserMail] = useState("");
    const [error, setError] = useState(false);
    const [time, setTime] = useState(300000);
    const [code, setCode] = useState("");

    const timerContainer = useRef<NodeJS.Timeout | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(state) {
            const {
                userMail
            } = state;
    
            setUserMail(userMail);
        } else {
            navigate("/notFound");
        }

        startTimer();
        return () => {
            if (timerContainer.current) {
                clearInterval(timerContainer.current);
            }
        };
    }, []);

    useEffect(() => {
        if (timerContainer.current) {
            clearInterval(timerContainer.current);
            startTimer();
        }
    }, [time]);

    const handleSMSOTP = (e: string) => {
        RESTService.action("SMSOTP", {
            mail: _userMail,
            code: e,
        }).then((res: any) => { //TODO: type issue will fix.
            const profilePayload = res.profilePayload;
            const companyPayload = res.companyPayload;

            window.localStorage.setItem("userType", profilePayload.type);
            window.localStorage.setItem("userLanguage",profilePayload.language);

            if (profilePayload.type === "worker") {
                const userData = {
                    phoneAreaCode: {
                        countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                        country: profilePayload.phoneAreaCode.country,
                        value: profilePayload.phoneAreaCode.value,
                        _id: profilePayload.phoneAreaCode._id
                    },
                    occupation: {
                        localizedText: profilePayload.occupation.localizedText,
                        _id: profilePayload.occupation._id
                    },
                    isMailVerified: profilePayload.isMailVerified,
                    profilePhotoURL: profilePayload.profilePhotoURL,
                    firstName: profilePayload.firstName,
                    fullName: profilePayload.fullName,
                    language: profilePayload.language,
                    lastName: profilePayload.lastName,
                    userID: profilePayload.userID,
                    phone: profilePayload.phone,
                    type: profilePayload.type,
                    mail: profilePayload.mail
                };

                setGlobalState({
                    user: userData
                });
                window.localStorage.setItem("user", JSON.stringify(userData));
            }

            if (profilePayload.type === "employer" && companyPayload) {
                const userData = {
                    isMailVerified: profilePayload.isMailVerified,
                    profilePhotoURL: profilePayload.profilePhotoURL,
                    firstName: profilePayload.firstName,
                    fullName: profilePayload.fullName,
                    language: profilePayload.language,
                    lastName: profilePayload.lastName,
                    userID: profilePayload.userID,
                    phone: profilePayload.phone,
                    type: profilePayload.type,
                    mail: profilePayload.mail,
                    phoneAreaCode: {
                        countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                        country: profilePayload.phoneAreaCode.country,
                        value: profilePayload.phoneAreaCode.value,
                        _id: profilePayload.phoneAreaCode._id
                    }
                };
                const companyData = {
                    phoneAreaCode: {
                        countryTranslationKey: companyPayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: companyPayload.phoneAreaCode.flagUnicode,
                        country: companyPayload.phoneAreaCode.country,
                        value: companyPayload.phoneAreaCode.value,
                        _id: companyPayload.phoneAreaCode._id
                    },
                    district: {
                        _id: companyPayload.district._id,
                        localizedText: companyPayload.district.localizedText
                    },
                    occupation: {
                        _id: companyPayload.occupation._id,
                        localizedText: companyPayload.occupation.localizedText
                    },
                    sector: {
                        _id: companyPayload.sector._id,
                        localizedText: companyPayload.sector.localizedText
                    },
                    city: {
                        _id: companyPayload.city._id,
                        localizedText: companyPayload.city.localizedText
                    },
                    isMailVerified: profilePayload.isMailVerified,
                    mersisNo: companyPayload.mersisNo,
                    photoURL: companyPayload.photoURL,
                    address: companyPayload.address,
                    website: companyPayload.website,
                    title: companyPayload.title,
                    phone: companyPayload.phone,
                    mail: companyPayload.mail,
                    _id: companyPayload._id
                };

                setGlobalState({
                    user: userData,
                    company: companyData
                });

                window.localStorage.setItem("company", JSON.stringify(companyData));
                window.localStorage.setItem("user", JSON.stringify(userData));
            }

            ChatService.getAccessToken();

            setTimeout(() => {
                if(profilePayload.type === "employer") {
                    navigate("/app/home");
                } else {
                    navigate("/app/search");
                }
            }, 400);
        }).catch((err) => {
            console.error("SMSOTP Err:", err);
            setError(true);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const startTimer = () => {
        setShowResendButton(false);
        timerContainer.current = setInterval(() => {
            if (time) {
                if (time > 1000) {
                    setTime(prevTime => prevTime - 1000);
                } else {
                    setCodeActive(false);
                    if (timerContainer.current !== null) {
                        clearInterval(timerContainer.current);
                    }
                    timerContainer.current = null;
                    setShowResendButton(true);
                }
            }
        }, 1000);
    };

    const timeConverter = () => {
        const totalSeconds = time / 1000;
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        const second = totalSeconds % 60;

        const resMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const resSecond = second < 10 ? `0${second}` : `${second}`;

        if (hour === 0) {
            return <Text
                variant='body2-medium'
                color='textGrey'
            >
                {localize("your-remaining-time-for-your-password")}: 
                <Text
                    variant='body2-medium'
                    color='primary'
                >
                    {`${resMinute}:${resSecond}`}
                </Text>
            </Text>;
        }
        return `${hour}:${resMinute}:${resSecond}`;
    };

    const focusInput = () => {
        inputRef.current?.focus();
    };
    
    const handleResendCode = () => {
        setTime(300000);
        setCodeActive(true);
        if(inputRef.current) inputRef.current.value = "";
        setCode("");
        startTimer();
        setError(false);
    };

    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                borderRightColor: colors.stroke,
                borderRightWidth: borders.line,
                padding: spaces.container
            }}
        >
            <div
                className={classes.leftTopContainer}
            >
                <div className={classes.visualImageContainer}>
                    <img
                        className={classes.visualImage}
                        src={RegisterLoginImage}
                        alt="sideVisualImage"
                    />
                </div>
                <div 
                    className={classes.visualImageContainerText}
                    style={{
                        marginTop: spaces.containerLarge
                    }}
                >
                    <Text 
                        variant="body2-regular"
                    >
                        {localize("with-isinolacak-you-will-reach-your-career-goals")}
                    </Text>
                </div>
            </div>
            <div 
                className={classes.footerTopContainer}
            >
                {renderFooter()}
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
        >
            <div
                className={classes.loginContainer}
                style={{
                    padding: spaces.container
                }}
            >
                <div
                    style={{
                        marginBottom: spaces.inline * 1.5
                    }}
                >
                    <Logo size={120} />
                </div>
                {renderInputs()}
            </div>
        </div>;
    };

    const renderInputs = () => {
        return <div className={classes.inputContainer}>
            <Text
                style={{
                    marginRight: spaces.content * 3.5,
                    marginBottom: spaces.content
                }}
                variant='body3-semiBold'
                color='textDark'
            >
                {localize("enter-the-password-sent-via-sms")}: 
            </Text>
            <div
                onClick={focusInput}
                className={classes.codeContainer}
                style={{
                    marginBottom: spaces.content * 2,
                    gap: spaces.content
                }}
            >
                {[...Array(6)].map((_, index) => (
                    <div
                        key={index}
                        className={classes.codeBox}
                        style={{
                            borderColor: error ? colors.error : index === code.length ? colors.attention : index < code.length ? colors.primary : colors.textGrey,
                            borderWidth: spaces.inline / 4,
                            borderRadius: spaces.inline
                        }}
                    >
                        <Text>
                            {code[index]}
                        </Text>
                    </div>
                ))}
            </div>
            <input
                className={classes.input}
                ref={inputRef}
                minLength={6}
                maxLength={6}
                onFocus={() => {
                    if(error) {
                        if(inputRef.current) inputRef.current.value = "";
                        setError(false);
                        setCode("");
                    }
                }}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 6) {
                        setCode(inputValue);
                        if (inputValue.length === 6) {
                            handleSMSOTP(inputValue);
                        }
                    }
                }}
                type="text"
                style={error ? {
                    borderColor: colors.error
                } : {
                }}
            />
            {error && (
                <div
                    className={classes.errorText}
                    style={{
                        marginBottom:  spaces.content
                    }}
                >
                    <InfoIcon />
                    <Text
                        variant='body4-regular'
                        color='error'
                        style={{
                            marginLeft: spaces.content / 3,
                        }}
                    >
                        {localize("you-have-logged-in-incorrectly-please-try-again")}
                    </Text>
                </div>
            )}
            {!showResendButton && <div>{timeConverter()}</div>}
            {showResendButton && (
                <div
                    className={classes.refleshButton}
                >
                    <Button
                        onClick={() => {
                            handleResendCode();
                            // handleSMSOTP(code); //TODO: WİLL CODE FİX
                        }}
                    
                        title={localize("resend-password")}
                        variant='ghost'
                    />
                </div>
            )}
        </div>;
    };

    const renderFooter = () => {
        return <div
            className={classes.footerContainer}
            style={{
                gap: spaces.contentLarge
            }}
        >
            <Text 
                variant='body4-regular'
                color='secondary'
            >
                {localize("about-us")}
            </Text>
            <Text 
                variant='body4-regular'
                color='secondary'
            >
                {localize("help")}
            </Text>
            <Text 
                variant='body4-regular'
                color='secondary'
            >
                {localize("our-data-policy")}
            </Text>
            <div
                className={classes.footerButtonsContainer}
                style={{
                    gap: spaces.item
                }}
            >
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                ></div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <InstagramIcon 
                        size={15}
                        color="#fff"
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <YoutubeIcon 
                        size={15}
                        color="#fff"
                    />
                </div> 
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <FacebookIcon 
                        size={15}
                        color="#fff"
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <XIcon 
                        size={15}
                        color="#fff"
                    />
                </div>
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                ></div>
            </div>
            <Text 
                variant='body4-regular'
                color='secondary'>isinolacak.
            com
            </Text>
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderLeftContainer()}
        {renderRightContainer()}
    </div>;
};
export default SmsOTP;