import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    Text
} from "isinolacak-web-cl";
import {
    LicenseOrCertificateType
} from "./types";
import {
    INPUT_YEARS, 
    MONTH_DATA
} from "../../../../constants";
import {
    CircleExitIcon
} from "../../../../../../../../../../assets/svgr";

const LicenceOrCertification = ({
    removeLicenceOrCertification,
    licenceOrCertificates,
    onChangeOrganization,
    onChangeStartDate,
    onChangeEndDate,
    showExitIcon,
    onChangeName,
    setErrors,
    errors,
    index
}: LicenseOrCertificateType) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderLicenceOrCertification = () => {
        const takenAtMonth = licenceOrCertificates[index].takenAtMonth;
          
        const selectedMonth = MONTH_DATA.find(month => month.id === takenAtMonth);
          
        return  <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.remove}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant='body-regular'
                    color='textDark'
                >
                    {localize("license-and-certificate")}
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeLicenceOrCertification(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <TextInput
                infoText={errors.includes(`licenceOrCertificates-${index}-name-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.some(err => err === `licenceOrCertificates-${index}-name-length-error`)}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `licenceOrCertificates-${index}-name-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                onChangeText={(text) => onChangeName(text, index)}
                initialValue={licenceOrCertificates[index].name}
                placeholder={localize("enter-your-information.")}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            />
            <TextInput
                infoText={errors.includes(`licenceOrCertificates-${index}-givenByOrganization-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `licenceOrCertificates-${index}-givenByOrganization-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                isError={errors.some(err => err === `licenceOrCertificates-${index}-givenByOrganization-length-error`)}
                initialValue={licenceOrCertificates[index].givenByOrganization}
                onChangeText={(text) => onChangeOrganization(text, index)}
                placeholder={localize("enter-your-information.")}
                style={{
                    marginBottom: spaces.content
                }}
            />
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content 
                }}>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`licenceOrCertificates-${index}-takenAtMonth-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `licenceOrCertificates-${index}-takenAtMonth-length-error`)}
                        keyExtractor={(item: { number: string; name: string; id: number }) => String(item.id)}
                        titleExtractor={(item: { number: string; name: string; id: number }) => item.name}
                        initialSelectedItems={selectedMonth ? [{
                            ...selectedMonth, originalIndex: MONTH_DATA.indexOf(selectedMonth) 
                        }] : []}
                        title={localize("month")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        disabled={false}
                        style={{
                            marginRight: spaces.content
                        }}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0];
                            if (selectedItem) {
                                //@ts-ignore
                                onChangeStartDate(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `licenceOrCertificates-${index}-takenAtMonth-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        inputTitle={""}
                        data={MONTH_DATA}
                    />
                </div>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`licenceOrCertificates-${index}-takenAtYear-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `licenceOrCertificates-${index}-takenAtYear-length-error`)}
                        initialSelectedItems={
                            licenceOrCertificates[index] && licenceOrCertificates[index].takenAtYear ? [{
                                year: licenceOrCertificates[index].takenAtYear 
                            }] : [] 
                        }
                        titleExtractor={(item: {year: number}) => String(item.year)}
                        keyExtractor={(item: {year: number}) => String(item.year)}
                        title={localize("year")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        disabled={!licenceOrCertificates[index].takenAtMonth}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0];
                            if (selectedItem) {
                                //@ts-ignore
                                onChangeEndDate(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `licenceOrCertificates-${index}-takenAtYear-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        inputTitle={""}
                        data={INPUT_YEARS}
                    /> 
                </div>
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderLicenceOrCertification()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default LicenceOrCertification;
