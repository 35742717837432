import {
    createUseStyles
} from "react-jss";
import "./style.css";

const useStyles = createUseStyles({
    container: {
        overflowY: "auto",
        height: "100%",
        width: "100%",
    },
    "@global": {
        "*": {
            padding: 0,
            margin: 0
        },
        "html, body, #root": {
            scrollBehavior: "smooth !important"
        }
    },
    emailVeriicationContainer: {
        position: "fixed",
        zIndex: 999,
        right: 5
    }
}, {
    name: "Project-Main"
});
export default useStyles;
