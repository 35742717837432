import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    FilledCheckSquareIcon,
    RoundArrowIcon
} from "../../../../../assets/svgr";
import {
    PTResultPropsTypes
} from "./types";

const PTResults = ({
    currentAplicant,
    ptResults,
    setViews
}: PTResultPropsTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                marginBottom: spaces.contentXLarge,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.pointer}
                onClick={() => {
                    setViews("candidateInfo");
                }}
            >
                <RoundArrowIcon/>
            </div>
            <Text
                variant="header5-medium"
            >
                {currentAplicant.fullName} {localize("pt-test")}
            </Text>
        </div>;
    };

    const renderPTAnswers = () => {
        return <div
            className={classes.ptAnswersContainer}
            style={{
                gap: spaces.contentXLarge
            }}
        >
            <Text
                variant="body-medium"
            >
                {localize("pt-results")}
            </Text>
            {renderPTAnswer()}
        </div>;
    };

    const renderPTAnswer = () => {
        return ptResults.map((item, index) => {
            return <div
            >
                <Text
                    variant="body2-medium"
                >
                    {`${localize("question")}-${index + 1}  ${item.answerType.localizedText}`} 
                </Text>
                <div
                    className={classes.question}
                    style={{
                        marginBottom: spaces.inline,
                        marginTop: spaces.content,
                        gap: spaces.inline
                    }}
                >
                    <FilledCheckSquareIcon
                        color={colors.primary}
                    />
                    <Text
                        variant="body2-regular"
                    >
                        {item.question}
                    </Text>
                </div>
                <Text
                    variant="body2-regular"
                    color="textSecondary"
                >
                    {
                        localize("ideal-response")}: {item.answerType.key === "2-option-answer" ? item.prefer === true ? localize("yes") : localize("no") : 
                        item.answerType.key === "open-ended-answer" ? item.answerType.localizedText : item.prefer
                    }
                </Text>
                <div
                    className={classes.line}
                    style={{
                        marginBottom: spaces.inline,
                        borderColor: colors.stroke,
                        borderWidth: borders.line
                    }}
                >
                </div>
                <Text
                    color={item.prefer === item.answer ? "success" : "error"}
                    variant="body2-medium"
                >
                    {localize("recived-answer")}: {item.answerType.key === "2-option-answer" ? item.answer === true ? localize("yes") : localize("no") : item.answer}
                </Text>
            </div>;
        });
    };

    return <div
        className={classes.container}
        style={{
            paddingBottom: spaces.contentXLarge,
            paddingTop: spaces.contentXLarge
        }}
    >
        {renderHeader()}
        {renderPTAnswers()}
    </div>;
};

export default PTResults;
