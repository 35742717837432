import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    IResultsCardProps,
    StickerObjectType,
} from "./types";
import emptyProfile from "../../../../../assets/img/isinolacakMiniWithPadding.png";
import BoostsTypeStickers from "../../../../../components/boostTypeStickers";

const ResultCard = ({
    experienceLevel,
    contractType,
    companyName,
    workingType,
    stickers,
    jobImage,
    onPress,
    style,
    title,
    city
}: IResultsCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {companyName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {`${experienceLevel} • ${contractType} • ${city} (${workingType})`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
            onClick={onPress}
        >
            <div
                className={classes.flexClass}
            >
                <img
                    src={jobImage && jobImage.length ? jobImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container
                    }}
                />
                {informationsContainer()}
            </div>
        </div>;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                stickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <div
                        key={item.key}
                    >
                        <BoostsTypeStickers
                            title={localizedText}
                            titleColor={item.color}
                            color={item.backColor}
                            icon={item.icon}
                            itemKey={item.key}
                        />
                    </div>;
                })
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            borderStyle: "solid"
        }}
    >
        {renderInformations()}
        {renderTags()}
    </div>;
};

export default ResultCard;