import {
    useEffect 
} from "react";
import {
    useNavigate 
} from "react-router-dom";
import AuthRoute from "../../navigation/authRoute";

const Auth = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let user = window.localStorage.getItem("user");
        if(user){
            navigate("/app");
        }
    }, []);

    return <AuthRoute />;
};
export default Auth;
