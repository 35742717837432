import React from "react";
import {
    LeftMobileContainerType
} from "./types";
import {
    OtherApplicationObjectType 
} from "../../../../../../services/restAPI/actions/getMyApplication/types";
import ApplicationCard from "../../applicantCard";

const LeftMobileContainer = ({
    handleGetMyApplication,
    setResponsiveShow,
    getMyApplication,
    setSearchParams
}: LeftMobileContainerType) => {
    return <div>
        {getMyApplication?.otherApplications.map((item: OtherApplicationObjectType, index: number) => {
            const phoneURL = item.companyInfo.photoURL ? item.companyInfo.photoURL : require("../../../../../../assets/img/isinolacakMini.png");
                        
            return <ApplicationCard
                jobTitle={item.occupation.localizedText}
                location={item.city.localizedText}
                company={item.companyInfo.title}
                timestamp={item.appliedAt}
                profilePhoto={phoneURL}
                status={item.status}
                key={index}
                onClick={() => {
                    handleGetMyApplication(item._id);
                    setResponsiveShow("content");
                    setSearchParams((e: URLSearchParams) => {
                        e.set("s", item._id);
                        return e;
                    });
                }}
            />;
        })}
    </div>;
};

export default LeftMobileContainer;