import React from "react";
import useStyles from "./styles";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import INavLinkProps from "./types";
import {
    useNavigate
} from "react-router-dom";

const NavLink = ({
    icon: IconComponentProp,
    isActive,
    isIcon,
    title,
    path,
}: INavLinkProps) => {
    const classes = useStyles();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const navigate = useNavigate();

    const renderVerticalLine = () => {
        return <div
            className={classes.verticalLine}
            style={{
                backgroundColor: colors.primary,
                marginRight: isIcon ? spaces.content / 2 : 0
            }}
        >
        </div>;
    };

    const renderIcon = () => {
        if(!IconComponentProp) {
            return null;
        }

        return <div
        >
            <IconComponentProp/>
        </div>;
    };

    const renderTitle = () => {
        return <Text
            variant="body-regular"
            style={{
                width: isIcon ? "0%" : "100%",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden"
            }}
        >
            {title}
        </Text>;
    };

    return <div
        className={classes.menuItem}
        style={{
            paddingLeft: isIcon ? spaces.content : spaces.content,
            justifyContent: isIcon ? "center" : "unset",
            marginLeft: isIcon ? 0 : spaces.content,
            gap: isIcon ? 0 : spaces.content,
            marginBottom: spaces.contentLarge
        }}
        onClick={() => {
            navigate(path);
        }}
    >
        {
            isActive && renderVerticalLine()
        }
        {renderIcon()}
        {renderTitle()}
    </div>;
};
export default NavLink;