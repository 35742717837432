import React from "react";
import useStyles from "./styles";
import {
    IOCoreColorsType,
    IOCoreLocale,
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import {
    ApplicationCardPropsTypes
} from "./types";
import {
    DocumentFullIcon,
    CheckListIcon,
    CircleIcon,
    CloseIcon,
    InfoIcon
} from "../../../../../assets/svgr";
import moment from "moment";

const ApplicantionCard = ({
    profilePhoto,
    timestamp,
    jobTitle,
    location,
    company,
    onClick,
    status
}: ApplicationCardPropsTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderCandidateInfo = () => {
        return <div
            className={classes.cardHeader}
        >
            <div
                className={classes.applicationCardInfoContent}
            >
                <img
                    src={profilePhoto && profilePhoto.length ? profilePhoto : require("../../../../../assets/img/isinolacakMini.png")}
                    alt={`${profilePhoto} Logo`}
                    className={classes.logo}
                    style={{
                        borderRadius: spaces.contentXLarge * 2,
                        borderColor: colors.primary,
                        borderWidth: borders.line
                    }}
                />
            </div>
            <div
                style={{
                    marginLeft: spaces.content
                }}
            >
                <div>
                    <Text
                        variant="body-medium"
                        color="textDark"
                    >
                        {jobTitle}
                    </Text>
                </div>
                <div>
                    <Text
                        variant="body4-regular"
                        color="textDark"
                    >
                        {company}
                    </Text>
                </div>
                <div>
                    <Text
                        variant="body4-regular"
                        color="textSecondary"
                    >
                        {location}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderPastInfo = () => {
        let _status:  {
            statusText: string,
            textColor: keyof IOCoreColorsType
        } = {
            statusText: localize("applied"),
            textColor: "primary"
        };

        if(status === "applied") {
            _status.statusText = localize("applied");
            _status.textColor = "success";
        }

        if(status === "documentSent") {
            _status.statusText = localize("document-sent");
            _status.textColor = "success";
        }

        if(status === "documentWaiting") {
            _status.statusText = localize("document-waiting");
            _status.textColor = "warning";
        }

        if(status === "reuploadDocumentWaiting") {
            _status.statusText = localize("document-waiting");
            _status.textColor = "warning";
        }

        if(status === "performanceTestSubmitted") {
            _status.statusText = localize("performance-test-submitted");
            _status.textColor = "success";
        }

        if(status === "performanceTestWaiting") {
            _status.statusText = localize("performance-test-waiting");
            _status.textColor = "warning";
        }

        if(status === "rejected") {
            _status.statusText = localize("rejected");
            _status.textColor = "error";
        }

        if(status === "finished") {
            _status.statusText = localize("finished");
            _status.textColor = "textGrey";
        }

        return <div>
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color={_status.textColor}
                >
                    {_status.statusText}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-medium"
                    color="textSecondary"
                >
                    {moment(timestamp).fromNow()}
                </Text>
            </div>
        </div>;
    };

    const renderIcon = () => {
        if(status === "rejected") {
            return  <div
                className={classes.renderIcon}
                style={{
                    borderRadius: radiuses.hard * 2,
                    backgroundColor: colors.error,
                    padding: spaces.inline
                }}
            >
                <CloseIcon
                    color={colors.white}
                    size={22}
                />
            </div>;
        }

        if(status === "documentWaiting") {
            return  <div>
                <DocumentFullIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "documentSent") {
            return  <div>
                <DocumentFullIcon
                    color={colors.success}
                    size={30}
                />
            </div>;
        }

        if(status === "reuploadDocumentWaiting") {
            return  <div>
                <DocumentFullIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "performanceTestWaiting") {
            return  <div>
                <CheckListIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "performanceTestSubmitted") {
            return  <div>
                <CheckListIcon
                    color={colors.success}
                    size={30}
                />
            </div>;
        }

        if(status === "applied") {
            return  <div>
                <CircleIcon
                    color={colors.primary}
                    size={30}
                />
            </div>;
        }

        if(status === "finished") {
            return  <div>
                <InfoIcon
                    color={colors.primary}
                    size={30}
                />
            </div>;
        }
    };

    return <div
        className={classes.card}
        onClick={onClick}
        style={{
            borderBottomRightRadius: radiuses.hard,
            borderBottomLeftRadius: radiuses.hard,
            backgroundColor: colors.white,
            borderTopColor: colors.stroke,
            borderWidth: borders.line,
            padding: spaces.content
        }}
    >
        <div
            className={classes.candidateInfoContainer}
            style={{
                marginBottom: spaces.content
            }}
        >
            {renderCandidateInfo()}
            {renderIcon()}
        </div>
        {renderPastInfo()}
    </div>;
};

export default ApplicantionCard;