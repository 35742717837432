import React, {
    useEffect
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    SentMessagePropsTypes
} from "./types";
import {
    MessageBubbleIcon,
    PhoneFilledIcon,
    ArrowDownIcon
} from "../../../../../assets/svgr";

const SendMesage = ({
    setDropdownVisible,
    onClickSendMessage,
    isOptionsVisible,
    phoneNumber,
    dropdownref
}: SentMessagePropsTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownref.current && !dropdownref.current.contains(event.target as Node)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[]);

    const renderOptions = () => {
        if(!isOptionsVisible) {
            return null;
        } 

        return <div
            className={classes.dropDownContainer}
            style={{
                backgroundColor: colors.white,
                top: spaces.containerXLarge,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            <div
                className={classes.renderOptionsContainer}
                style={{
                    marginBottom: spaces.content,
                    gap: spaces.content
                }}
                onClick={onClickSendMessage}
            >
                <div
                    style={{
                        marginRight: spaces.content / 2
                    }}
                >
                    <MessageBubbleIcon
                        color={colors.greyBase}
                    />
                </div>
                <div
                    className={classes.itemContent}
                >
                    <Text
                        variant="body3-regular"
                        color="greyBase"
                    >
                        {localize("send-message")}
                    </Text>
                    <Text
                        variant="caption-regular"
                        color="grey200"
                    >
                        {localize("send-internal-message")}
                    </Text>
                </div>
            </div>
            <div
                onClick={() => {
                    navigator.clipboard.writeText(phoneNumber);
                }}
                className={classes.renderOptionsContainer}
                style={{
                    gap: spaces.content
                }}
            >
                <div
                    style={{
                        marginRight: spaces.content / 2
                    }}
                >
                    <PhoneFilledIcon
                        color={colors.greyBase}
                    />
                </div>
                <div
                    className={classes.itemContent}
                >
                    <Text
                        variant="body3-regular"
                        color="greyBase"
                    >
                        {phoneNumber}
                    </Text>
                    <Text
                        variant="caption-regular"
                        color="grey200"
                    >
                        {localize("copy-phone-number")}
                    </Text>
                </div>
            </div>
        </div>;
    };

    return <div>
        <div
            className={classes.container}
            ref={dropdownref}
        >
            <Button
                title={localize("send-messsage")}
                iconDirection="right"
                variant="outline"
                size="medium"
                onClick={() => {
                    if(isOptionsVisible) {
                        setDropdownVisible(false);
                    } else {
                        setDropdownVisible(true);
                    }
                }}
                icon={() => <ArrowDownIcon
                    color={colors.primary}
                />}
            />
            {renderOptions()}
        </div>
    </div>;
};

export default SendMesage;
