import {
    uuid 
} from "../../../../../../../utils";

export const DISABLED_ADS_FILTERS: Array<{
    id: string;
    value: "true" | "false" | "all"
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        value: "all",
        localizedText: "all",
    },
    {
        id: uuid(),
        value: "true",
        localizedText: "only-disabled-ads",
    },
    {
        id: uuid(),
        value: "false",
        localizedText: "no-disabled-ads"
    }
];

export const OTHER_FILTERS: Array<{
    id: string;
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        localizedText: "hide-revieved-ads"
    },
    {
        id: uuid(),
        localizedText: "hide-aplied-ads"
    },
    {
        id: uuid(),
        localizedText: "only-1-time-ads"
    }
];