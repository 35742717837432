import React, {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Switcher,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    CircleIcon,
    RadarIcon
} from "../../../../../../assets/svgr";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    PersonalInfoTypes
} from "../../types";
import {
    UserPreferenceRequestType
} from "../../../../../../services/restAPI/actions/userPreference/types";
import {
    GetMyUserPreferencesResponseTypes 
} from "../../../../../../services/restAPI/actions/getMyUserPreferences/types";
import {
    GetMyProfileResponseTypes
} from "../../../../../../services/restAPI/actions/getMyProfile/types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {
    getErrorText 
} from "../../../../../../utils";
import {
    Footer,
    WorkerProfileLeftContainer 
} from "../../../../../../components";

const WorkerCommunicationPreferences = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const localStorageData: string | null = window.localStorage.getItem("user");

    let user: PersonalInfoTypes;
    if (localStorageData) {
        user = JSON.parse(localStorageData);
    } else {
        user = {
            areaCode: {
                countryTranslationKey: "",
                flagUnicode: "",
                country: "",
                value: "",
                _id: "",
            },
            occupation: {
                localizedText: "",
                _id: ""
            },
            phoneNumber: "",
            firstName: "",
            lastName: "",
            fullName: "",
            photoURL: "",
            about: "",
            mail: "",
        };
    }
    
    const [getMyUserPreferences, setGetMyUserPreferences] = useState<GetMyUserPreferencesResponseTypes>();
    const [getMyProfile ,setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [activePage, setActivePage] = useState(location.pathname);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [notifyMe, setNotifyMe] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [sendSMS, setSendSMS] = useState(false);
    const [loading, setLoading] = useState(true);
    const [callMe, setCallMe] = useState(false);

    useEffect(() => {
        handleGetCompanyProfileForWorker();
        handleGetMyProfile();
    }, []);

    const handleGetMyProfile = () => {
        setLoadingProfile(true);
        RESTService.action("GetMyProfile",{
        })
            .then((res) => {
                setGetMyProfile(res);
                setLoadingProfile(false);
            })
            .catch((err) => {
                console.error("GetMyProfile Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleGetCompanyProfileForWorker = () => {
        setLoading(true);
        RESTService.action("GetMyUserPreferences",{
        })
            .then((res) => {
                setGetMyUserPreferences(res);
                setLoading(false);

                if(res.commercialPreferences.notifications) {
                    setNotifyMe(res.commercialPreferences.notifications);
                }

                if(res.commercialPreferences.privacy) {
                    setPrivacy(res.commercialPreferences.privacy);
                }

                if(res.commercialPreferences.email) {
                    setSendEmail(res.commercialPreferences.email);
                }

                if(res.commercialPreferences.phone) {
                    setCallMe(res.commercialPreferences.phone);
                }

                if(res.commercialPreferences.sms) {
                    setSendSMS(res.commercialPreferences.sms);
                }
            })
            .catch((err) => {
                console.error("GetMyProfile Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };
    
    const handleUserPreference = () => {
        const params: UserPreferenceRequestType = {
            commercialPreferences: {
                notifications: notifyMe,
                privacy: privacy,
                email: sendEmail,
                phone: callMe,
                sms: sendSMS
            }
        };
        setLoadingButton(true);
        RESTService.action("UserPreference", params)
            .then((res) => {
                setIsVisibleDialog(true);
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("UserPreference Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("saved-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    const renderLine = () => (
        <div
            className={classes.renderline}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke
            }}
        />
    );

    const renderSecurity = () => (
        <div
            className={classes.preferencesContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content
            }}
            onClick={() => setPrivacy(!privacy)}
        >
            <div
                className={classes.preferencesContent}
            >
                <Text
                    className={classes.phoneCallPermission}
                    variant="body2-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("security")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {localize("security-description")}
                </Text>
            </div>
            <Switcher
                onChange={() => setPrivacy(!privacy)}
                isActive={privacy}
            />
        </div>
    );

    const renderNotificationsPermission = () => (
        <div
            className={classes.preferencesContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content
            }}
            onClick={() => setNotifyMe(!notifyMe)}
        >
            <div
                className={classes.preferencesContent}
            >
                <Text
                    variant="body2-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("notifications")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {localize("notification-description")}
                </Text>
            </div>
            <Switcher
                onChange={() => setNotifyMe(!notifyMe)}
                className={classes.switcher}
                isActive={notifyMe}
            />
        </div>
    );

    const renderEmailPermission = () => (
        <div
            className={classes.preferencesContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content
            }}
            onClick={() => setSendEmail(!sendEmail)}
        >
            <div
                className={classes.preferencesContent}
            >
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={classes.eMailPermission}
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("e-mail")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {localize("notification-description")}
                </Text>
            </div>
            <Switcher
                onChange={() => setSendEmail(!sendEmail)}
                className={classes.switcher}
                isActive={sendEmail}
            />
        </div>
    );

    const renderSMSPermission = () => (
        <div
            className={classes.preferencesContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content
            }}
            onClick={() => setSendSMS(!sendSMS)}
        >
            <div
                className={classes.preferencesContent}
            >
                <Text
                    className={classes.smsPermission}
                    variant="body2-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("sms")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {localize("notification-description")}
                </Text>
            </div>
            <Switcher
                onChange={() => setSendSMS(!sendSMS)}
                isActive={sendSMS}
            />
        </div>
    );

    const renderPhoneCallPermission = () => (
        <div
            className={classes.preferencesContainer}
            style={{
                marginBottom: spaces.content * 2,
                marginTop: spaces.content
            }}
            onClick={() => setCallMe(!callMe)}
        >
            <div
                className={classes.preferencesContent}
            >
                <Text
                    className={classes.phoneCallPermission}
                    variant="body2-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("search-by-phone")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {localize("notification-description")}
                </Text>
            </div>
            <Switcher
                onChange={() => setCallMe(!callMe)}
                isActive={callMe}
            />
        </div>
    );

    const renderSaveButton = () => (
        <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                onClick={handleUserPreference}
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>
    );

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `50px ${spaces.containerXLarge}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("communication-preferences")}
                </Text>
            </div>
            <div>
                {renderSecurity()}
                {renderLine()}
                {renderNotificationsPermission()}
                {renderLine()}
                {renderEmailPermission()}
                {renderLine()}
                {renderSMSPermission()}
                {renderLine()}
                {renderPhoneCallPermission()}
            </div>
            {renderSaveButton()}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if(loading && loadingProfile) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.mainContainer}
            style={{
                padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
                backgroundColor: colors.layer3,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("person-profile")}
            </Text>
            <div
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                <WorkerProfileLeftContainer/>
                {renderContentContainer()}
                {renderRightContainer()}
            </div>
            {renderSuccessDialog()}
        </div>;
        <Footer/>
    </Fragment>;
};

export default WorkerCommunicationPreferences;
