import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    DocumentFullIcon,
    RoundArrowIcon,
    CircleExitIcon,
    CheckListIcon,
    CircleIcon,
    InfoIcon
} from "../../../../../../assets/svgr";
import {
    HeaderContentMobileContainerType
} from "./types";
import {
    useNavigate 
} from "react-router-dom";
import moment from "moment";

const HeaderContentMobileContainer = ({
    setResponsiveShow,
    getMyApplication
}: HeaderContentMobileContainerType) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderAdvertDetails = () => {
        let buttonIcon: () => JSX.Element;
        let statusColor: keyof IOCore.ColorsType = "success";
        let buttonTitle = "";
    
        switch(getMyApplication?.status) {
        case "applied":
            buttonIcon = () => (
                <CircleIcon
                    color="white"
                    size={17}
                />
            );
            buttonTitle = localize("applied");
            statusColor = "success";
            break;
        case "performanceTestWaiting":
            buttonIcon = () => (
                <CheckListIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("performance-test-waiting");
            statusColor = "warning";
            break;
        case "performanceTestSubmitted":
            buttonIcon = () => (
                <CheckListIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("performance-test-submitted");
            statusColor = "success";
            break;
        case "documentWaiting":
            buttonIcon = () => (
                <DocumentFullIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("document-waiting");
            statusColor = "warning";
            break;
        case "documentSent":
            buttonIcon = () => (
                <DocumentFullIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("document-sent");
            statusColor = "warning";
            break;
        case "rejected":
            buttonIcon = () => (
                <CircleExitIcon
                    color="white"
                    size={17}
                />
            );
            buttonTitle = localize("rejected");
            statusColor = "error";
            break;
        case "finished":
            buttonIcon = () => (
                <CircleIcon
                    color="white"
                    size={17}
                />
            );
            buttonTitle = localize("finished");
            statusColor = "textGrey";
            break;
        default:
            buttonTitle = localize("finished");
            statusColor = "textGrey";;
            break;
        }

        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.advertDetailsContent}
            >
                <div
                    style={{
                        flexDirection: "row",
                        gap: spaces.content,
                        display: "flex"
                    }}
                >
                    <div
                        onClick={() => setResponsiveShow("left")}
                    >
                        <RoundArrowIcon
                            color={colors.primary}
                            size={30}
                        />
                    </div>
                    <Text
                        variant="header4-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content
                        }}
                    > 
                        {getMyApplication?.occupation.localizedText}
                    </Text>
                </div>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getMyApplication?.companyInfo.title}
                </Text>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getMyApplication?.city.localizedText}
                </Text>
                <div
                    className={classes.advertDetailsContainer}
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content,
                        gap: spaces.content / 2
                    }}
                >
                    <InfoIcon 
                        color={colors.primary}
                        size={17}
                    />
                    <Text
                        variant="body-regular"
                        color="textDark"
                    >
                        {getMyApplication?.workingType.localizedText} - {getMyApplication?.contractType.localizedText}
                    </Text>
                </div>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                >
                    {moment(getMyApplication?.appliedAt).fromNow()}
                </Text>
            </div>
            <div
                className={classes.buttonContent}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.content
                }}
            >
                <Button
                    icon={() => buttonIcon()}
                    iconDirection="right"
                    title={buttonTitle}
                    color={statusColor}
                    onClick={() => {}}
                    size="small"
                />
                <Button
                    title={localize("see-company-profile")}
                    variant="outline"
                    onClick={() => {
                        navigate("/app/profileUser", {
                            state: {
                                companyID: getMyApplication?.companyInfo._id
                            }
                        });
                    }}
                    size="small"
                />
            </div>
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderAdvertDetails()}
    </div>;
};

export default HeaderContentMobileContainer;