
import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        alignItems: "center"
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    profileTitleContainer: {
        justifyContent: "center",
        display: "flex",
        width: "100%"
    },
    profileTitle: {
        textAlign: "center",
        width: "100%"
    }
});
export default useStyles;