import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const SendIcon = ({
    color= "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M1.03 6.392C.625 2.619 4.374-.15 7.686 1.475l18.637 9.143c3.57 1.752 3.57 7.012 0 8.764L7.686 28.525C4.374 30.15.626 27.381 1.03 23.608l.75-6.992h12.665c.862 0 1.56-.723 1.56-1.616 0-.893-.698-1.616-1.56-1.616H1.78l-.75-6.992Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default SendIcon;
