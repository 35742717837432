import {
    IOCoreLocale,
    IOCoreTheme,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    IApplicantCardProps,
    StickerObjectType,
} from "./types";
import {
    RESTService 
} from "../../services/restAPI";
import {
    useNavigate 
} from "react-router-dom";
import {
    UserCheckBoldIcon,
    CalendarIcon,
    ShieldIcon,
    MailIcon
} from "../../assets/svgr";
import emptyProfile from "../../../app/assets/img/employer.png";
import BoostsTypeStickers from "../boostTypeStickers";
import { 
    BoostedObjectTypes
} from "./../../services/restAPI/actions/getMyActionsWorker/types";
import {
    getDayDifference 
} from "../../utils";

let STATUS_STICKERS: Array<StickerObjectType> = [
    {
        key: "suitableWorker",
        translationKey: "suitable-candiate",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "premium",
        translationKey: "premium",
        translationKeyParam: false,
        color: "neutralApplicationStatusFrontColor",
        backColor: "neutralApplicationStatusBackColor"
    },
    {
        key: "applicationDate",
        translationKey: "job-seeker-application-date",
        translationKeyParam: true,
        color: "textGrey",
        backColor: "grey50"
    }
];

const ApplicantCard = ({
    applicantFullName,
    applicantImage,
    educationLevel,
    applicationID,
    occupation,
    appliedAt,
    isPremium,
    onClick,
    style,
    city
}: IApplicantCardProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const navigate = useNavigate();

    const setStickers = (arr: any) => {
        let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "suitableWorker");
        let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
        newSticker.icon = () => <UserCheckBoldIcon size={12}/>;
        arr.push(newSticker);

        if(isPremium) {
            let _stickerIndex2 = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "premium");
            let newSticker2 = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex2]));
            newSticker2.icon = () => <ShieldIcon size={12}/>;
            arr.push(newSticker2);
        }

        let stickerIndex3 = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "applicationDate");
        let newSticker3 = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex3]));
        newSticker3.param = isNaN(getDayDifference(appliedAt, Date.now())) ? "-" : getDayDifference(appliedAt, Date.now());
        newSticker3.icon = () => <CalendarIcon size={12}/>;
        arr.push(newSticker3);
    };

    const handleChatStart = () => {
        if(!applicationID) {
            return;
        }

        RESTService.action("ConversationStart", {
            applicationID: applicationID,
        })
            .then((res) => {
                navigate("/app/chat", {
                    state: {
                        conversationID: res.conversationID
                    }
                });
            })
            .catch((err) => {
                console.error("ConversationStart err:", err);
            });
    };

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {applicantFullName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {occupation}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {` ${city}  ${educationLevel}`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
            onClick={onClick}
        >
            <div className={classes.informationsContent}>
                <img
                    src={applicantImage && applicantImage.length ? applicantImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container
                    }}
                />
                {informationsContainer()}
            </div>
            <Button
                onClick={() => {
                    handleChatStart();
                }}
                title={localize("send-message")}  
                icon={() => <MailIcon
                    size={18}
                />}
                textVariant="body3-medium"
            />
        </div>;
    };

    const renderTags = () => {
        let advertStickers: Array<StickerObjectType> = [];

        setStickers(advertStickers);

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                advertStickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <BoostsTypeStickers
                        title={localizedText}
                        titleColor={item.color}
                        color={item.backColor}
                        icon={item.icon}
                        itemKey={item.key}
                    />;
                })
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderInformations()}
        {renderTags()}
    </div>;
};
export default ApplicantCard;
