import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Magnifier = ({
    color = "#000",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M14.349 2.953c-6.294 0-11.396 5.102-11.396 11.396 0 6.293 5.102 11.395 11.396 11.395 6.293 0 11.395-5.102 11.395-11.395 0-6.294-5.102-11.396-11.395-11.396ZM1 14.35C1 6.976 6.976 1 14.349 1c7.372 0 13.349 5.976 13.349 13.349 0 3.334-1.223 6.384-3.245 8.723l4.26 4.26a.977.977 0 1 1-1.38 1.382l-4.26-4.26a13.297 13.297 0 0 1-8.724 3.244C6.976 27.698 1 21.72 1 14.348Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Magnifier;
