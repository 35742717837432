import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    soughtAfterQualificationContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex"
    },
    soughtAfterQualificationContent: {
        flexDirection: "column",
        display: "flex"
    },
    soughtAfterQualitificationCardContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        flexDirection: "row",
    },
    soughtAfterQualitificationCardContent: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    soughtAfterQualitificationCardCItem: {
        flexDirection: "column",
        display: "flex"
    },
    advertDetailsContent: {
        flexDirection: "column",
        display: "flex"
    },
    advertDetailsContainer: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    buttonContent: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex",
    },
    advertDetailAboutContent: {
        flexDirection: "column",
        display: "flex"
    },
    companyAbout: {
        alignContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    companyAboutImage: {
        objectFit: "cover",
        height: 32,
        width: 32
    },
    line: {
        width: "100%",
        height: "1px"
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: 600,
        width: "100%"
    }
});
export default useStyles;