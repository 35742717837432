import moment from "moment";
import {
    IOCoreTranslationType
} from "isinolacak-web-cl";
import {
    UserType
} from "../constants/types";
import {
    getAdvertsDataForDashboardGraphDailyObjectType 
} from "../services/restAPI/actions/getAdvertsDataForDashboard/types";
import {
    ReplaceMetaType
} from "../types";
import "moment/locale/tr";
import {
    STORAGE_SERVER_ADDRESS 
} from "../constants";

export const getErrorText = (
    err: any,
    possibleKeys: Array<string>
): {
    isErrorHandled: boolean;
    errorText?: keyof IOCoreTranslationType;
} => {
    if(err && err.payload) {
        let errorText: keyof IOCoreTranslationType | undefined | "" = "";

        for(let i = 0; i < possibleKeys.length; i++) {
            if(err.payload[possibleKeys[i]]) {
                if(typeof err.payload[possibleKeys[i]] === "string") {
                    errorText = err.payload[possibleKeys[i]];
                } else {
                    const errorKeys = Object.keys(err.payload[possibleKeys[i]]);
                    errorText = err.payload[possibleKeys[i]][errorKeys[0]];
                }
                break;
            }
        }

        if(errorText === "") return {
            isErrorHandled: false
        };

        return {
            isErrorHandled: true,
            errorText
        };
    } else {
        return {
            isErrorHandled: false
        };
    }
};

export const getBrowserLanguageFull = () => {
    let browserLang = window.localStorage.getItem("userLanguage") ?? "tr-TR";
    return browserLang;
};

export const getBrowserLanguage = () => {
    let browserLang = window.localStorage.getItem("lang") || navigator.language;
    if(browserLang.length > 2) {
        browserLang = browserLang.slice(0, 2);
    }
    return browserLang;
};

export const getBrowserTheme = (): IOCore.ThemeKeyType | null => {
    const browserTheme = window.localStorage.getItem("theme");
    return browserTheme;
};

export const random = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const goTo = (path: string) => {
    window.location.href = path;
    // window.history.pushState(undefined, path, path);
    window.dispatchEvent(new Event("popstate"));
};

export const replaceMeta = (props: ReplaceMetaType) => {
    const keys = Object.keys(props);
    keys.forEach((item) => {
        // @ts-ignore
        document.getElementById(`og${item}`)?.setAttribute("content", props[item]);
    });
};

export const uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

//@ts-ignore
export const convertFlagCode = (flag) => {
    //@ts-ignore
    let c = flag.split(" ").map(value => {
        //@ts-ignore
        return String.fromCodePoint("0x" + value.replace("U+", ""));
    });
    return c;
};

export const getDayDifference = (dateValue1: number | string, dateValue2: number | string) => {
    let date1 = new Date(dateValue1);
    let date2 = new Date(dateValue2);

    const firstDateInMs = date1.getTime();
    const secondDateInMs = date2.getTime();
    const aDayInMs = 24 * 60 * 60 * 1000;
    const differenceBtwDates = secondDateInMs - firstDateInMs;
    const daysDiff = Math.round(differenceBtwDates / aDayInMs);
    return daysDiff;
};

export const getFullDate = () => {
    const date = new Date();
    const weekday = date.toLocaleDateString(navigator.language ?? "en-US", {
        weekday: "long"
    });
    const day = date.toLocaleDateString(navigator.language ?? "en-US", {
        day: "numeric"
    });
    const month = date.toLocaleDateString(navigator.language ?? "en-US", {
        month: "long"
    });
    const year = date.toLocaleDateString(navigator.language ?? "en-US", {
        year: "numeric"
    });
    
    return `${weekday}, ${day} ${month}, ${year}`;
};

export const isPermittedUser = (ownUserType: UserType | undefined, allowedUserTypes: Array<UserType>): boolean => {
    let _isVisible = false;

    if(allowedUserTypes && allowedUserTypes.length){
        let isAccessUser = allowedUserTypes.findIndex(e => e === ownUserType);

        if(isAccessUser !== -1){
            _isVisible = true;
        }
    }
    return _isVisible;
};

export const cleanHTMLElements = (content: string): string => {
    const MAX_CHAR_IN_CONTENT = 150;
    const cleanText = content.replace(/<\/[^>]+(>|$)/g, "");
    if(cleanText.length >= MAX_CHAR_IN_CONTENT) {
        return cleanText.slice(0, MAX_CHAR_IN_CONTENT) + "...";
    } else {
        return cleanText;
    }
};

export const getContentArray = (content: string): string[] => {
    const regex = /<h[1-6]>(.*?)<\/h[1-6]>/g;
    let match;
    const headings = [];
    const decodeHtml = (html: string): string => {
        const textarea = document.createElement("textarea");
        textarea.innerHTML = html;
        return textarea.value;
    };

    while ((match = regex.exec(content)) !== null) {
        headings.push(decodeHtml(match[1]));
    } 
    return headings;
};


export const getDate = (date: string): string => {
    const curDate = new Date(date);
    const month = curDate.toLocaleString(navigator.language ?? "tr-TR", {
        month: "long"
    }); 
    const day = curDate.toLocaleString(navigator.language ?? "tr-TR", {
        day: "numeric"
    });

    return `${day} ${month}`;
};

export const handleUpload = async (selectedFile: File | null, uploadToken: string) => {
    if (!selectedFile || !uploadToken) {
        console.error("no-file-selected-or-upload-token-missing");
        return;
    }

    const maxFileSize = 4 * 1024 * 1024;

    if (selectedFile.size > maxFileSize) {
        alert("file-size-exceeds-limit");
        return;
    }

    try {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const response = await fetch(
            STORAGE_SERVER_ADDRESS,
            {
                headers: {
                    "Authorization": uploadToken
                },
                body: formData,
                method: "POST"
            }
        );
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
    } catch (err) {
        console.error("Err: upload", err);
        alert("something-went-wrong");
    }
};

export const shareOnSocialMedia = (type: "instagram" | "X" | "facebook", title?: string, content?: string): void => {
    if(type === "instagram" && title) {
        const blogPostTitle = encodeURIComponent(title!);
        const blogPostURL = encodeURIComponent(window.location.href);
        const message = `${content}: ${blogPostTitle} ${blogPostURL}`;
        navigator.clipboard.writeText(message);
        window.open("https://www.instagram.com/?url=isinolacak.com", "_blank", "canonical");
    }

    if(type === "X") {
        window.open(`https://twitter.com/intent/tweet?text=%C4%B0%C5%9Fin%20olacaktaki%20harika%20blog%20posta%20bak%C4%B1n%0A${title}%0ATamam%C4%B1n%C4%B1%20okumak%20i%C3%A7in:&url=isinolacak.com&hashtags=isinolacak`, "_blank");
    }

    if(type === "facebook") {
        window.open("https://www.facebook.com/sharer/sharer.php?u=isinolacak.com");
    } 
};

export const aggregateDailyData = (data: Array<getAdvertsDataForDashboardGraphDailyObjectType>) => {
    const intervals = Array.from({
        length: 9 
    }, (_, i) => ({
        date: `${i * 3}:00`,
        count: 0,
    }));

    for(let item of data) {
        const date = new Date(item.date);
        const hour = date.getHours();
        const intervalIndex = Math.floor(hour / 3);
        intervals[intervalIndex].count += item.count;
    };

    return intervals;
};

export function translateMonth(index: number) {
    moment.locale("tr");

    const date = moment().month(index - 1).date(1);

    return date.format("MMMM");
}

export const getUserType = (user: "employer" | "worker" | undefined): "employer" | "worker" | "noAuth" | undefined => {
    if(!window.localStorage.getItem("user")) {
        return "noAuth";
    }
    return user;
};
