import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    languageContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    filterContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    choosenFiltersChip: {
        display: "flex",
        flexWrap: "wrap"
    },
    choosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    responsiveChoosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (min-width: 600px)": {
            display: "none"
        }
    },
});
export default useStyles;