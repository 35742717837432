import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "stretch",
        display: "flex",
        flexGrow: 1
    },
    title: {
        display: "flex"
    }
});
