import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const MessageBubble = ({
    size = 22,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="m12.087 20.388.542-.916c.42-.71.63-1.066.968-1.262.338-.197.763-.204 1.613-.219 1.256-.021 2.043-.098 2.703-.372a5 5 0 0 0 2.706-2.706C21 13.995 21 12.83 21 10.5v-1c0-3.273 0-4.91-.737-6.112a5 5 0 0 0-1.65-1.651C17.41 1 15.773 1 12.5 1h-3c-3.273 0-4.91 0-6.112.737a5 5 0 0 0-1.651 1.65C1 4.59 1 6.228 1 9.5v1c0 2.33 0 3.495.38 4.413a5 5 0 0 0 2.707 2.706c.66.274 1.447.35 2.703.372.85.015 1.275.022 1.613.219.337.196.548.551.968 1.262l.542.916c.483.816 1.69.816 2.174 0ZM15 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-3-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default MessageBubble;
