import {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    Loading,
    Text,
} from "isinolacak-web-cl";
import IRegisterCompanyInfo from "./types";
import {
    useNavigate
} from "react-router-dom";
import {
    GetSectorsResponseObjectType,
    GetSectorsRequestType
} from "../../../../../../../services/restAPI/actions/getSectors/types";
import {
    RESTService
} from "../../../../../../../services/restAPI";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../../../services/restAPI/actions/getOccupation/types";
import {
    GetAreaCodesObjectType
} from "../../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    getBrowserLanguageFull 
} from "../../../../../../../utils";

// TODO: All texts will be localized.
const CompanyInfo = ({
    setRegisterInfo,
    registerInfo,
    setErrors,
    areaCode,
    errors
}: IRegisterCompanyInfo) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const [getOccupation, setGetOccupation] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [sectorData, setSectorData] = useState<Array<GetSectorsResponseObjectType>>([]);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [searchOccupations, setSearchOccupations] = useState<string>("");
    const [occupationsLoading, setOccupationsLoading] = useState(false);
    const [searchSectorPage, setSearchSectorPage] = useState<number>(1);
    const [sectorTotalDataCount, setSectorTotalDataCount] = useState(1);
    const [occupationPage, setOccupationPage] = useState<number>(1);
    const [searchSectors, setSearchSectors] = useState<string>("");
    const [sectorsLoading, setSectorsLoading] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(1);
    const [sectorPage, setSectorPage] = useState<number>(1);

    const searchDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const sectorDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        getOccupationData();
        getSectorsData();
    },[]);

    useEffect(() => {
        searchSector(searchSectors);
    }, [searchSectors]);

    useEffect(() => {
        searchOccupation(searchOccupations);
    }, [searchOccupations]);

    const searchOccupation = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if (searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getOccupationData({
                    language: getBrowserLanguageFull(),
                    search: searchText,
                    page: 1
                });
    
                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupationData();
        };

    };

    const getOccupationData = (searchParams?: GetOccupationRequestType) => {
        if(!occupationsLoading) setOccupationsLoading(true);

        let params: GetOccupationRequestType = {
            language: getBrowserLanguageFull(),
            page:  occupationPage
        };

        if(searchOccupations && searchOccupations.length) {
            params.search = searchOccupations;
            params.page = searchOccupationPage;
        }

        if(searchParams) {
            params = searchParams;
        }
        
        RESTService.action("GetOccupation",params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setGetOccupation(res.payload);

                if(searchParams?.search || searchOccupations.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(params.page ?? 1);
                    setSearchOccupationPage(1);
                }

                setOccupationsLoading(false);
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const searchSector = (searchText: string) => {
        if(sectorDebouncer.current) {
            clearTimeout(sectorDebouncer.current);
        }
        sectorDebouncer.current = null;

        if (searchText && searchText.length) {
            sectorDebouncer.current = setTimeout(() => {
                getSectorsData({
                    language: getBrowserLanguageFull(),
                    search: searchText,
                    page: 1
                });
    
                // @ts-ignore
                clearTimeout(sectorDebouncer.current);
                sectorDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getSectorsData();
        }
    };

    const getSectorsData = (searchParams?: GetSectorsRequestType) => {
        if(!sectorsLoading) setSectorsLoading(true);

        let params: GetSectorsRequestType = {
            language: getBrowserLanguageFull(),
            page:  sectorPage
        };

        if(searchSectors && searchSectors.length) {
            params.page = searchSectorPage;
            params.search= searchSectors;
        }

        if(searchParams) {
            params = searchParams;
        }
        
        RESTService.action("GetSectors",params)
            .then((res) => {
                setSectorTotalDataCount(res.totalData);
                setSectorData(res.payload);

                if(searchParams?.search || searchSectors.length){
                    setSectorPage(1);
                } else {
                    setSectorPage(params.page ?? 1);
                    setSearchSectorPage(1);
                }

                setSectorsLoading(false);
            })
            .catch((err) => {
                console.error("GetSectors Err:", err);
            });
    };

    const renderPhoneInput = () => {
        return <div
            className={classes.phoneInputContainer}
            style={{
                gap: spaces.content
            }}
        >
            <div
                className={classes.phoneAreaCodeSelectbox}
            >
                <SelectBox
                    //@ts-ignore // TODO: will be fixed at the web cl
                    initialSelectedItems={registerInfo.areaCode._id.length ? [registerInfo.areaCode] : null}
                    infoText={errors.includes("register-area-code-error") ? localize("register-area-code-error") : undefined}
                    isError={errors.includes("register-area-code-error")}
                    keyExtractor={(e) => e._id}
                    spreadBehaviour='stretch'
                    //@ts-ignore //TODO: This will be fix from web cl
                    titleExtractor={(
                        item: GetAreaCodesObjectType
                    ) => {
                        return <div
                            className={classes.areaCodesContainer}>
                            <Text>
                                {item.flagUnicode}
                            </Text>
                            <Text>
                                {item.value}
                            </Text>
                        </div>;
                    }}
                    isNeedConfirm={true}
                    multiSelect={false}
                    disabled={false}
                    onOk={({
                        selectedItems,
                        closeSheet,
                        onSuccess
                    }) => {
                        if(selectedItems[0]) {
                            setRegisterInfo({
                                ...registerInfo,
                                areaCode: {
                                    //@ts-ignore //TODO: Will be fix
                                    _id: selectedItems[0]._id,
                                    //@ts-ignore
                                    country: selectedItems[0].country,
                                    //@ts-ignore
                                    countryTranslationKey: selectedItems[0].countryTranslationKey,
                                    //@ts-ignore
                                    flagUnicode: selectedItems[0].flagUnicode,
                                    //@ts-ignore
                                    value: selectedItems[0].value
                                }
                            });
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "register-area-code-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }
                
                        closeSheet();
                        onSuccess();
                    }}
                    data={areaCode ? areaCode.payload : []}
                />
            </div>
            <TextInput
                infoText={errors.includes("register-corparate-phone-number-error") ? localize("register-corparate-phone-number-error") : undefined}
                isError={errors.includes("register-corparate-phone-number-error")}
                placeholder={localize("company-contact-number")}
                initialValue={registerInfo.phoneNumber}
                spreadBehaviour="stretch"
                id="companyPhone-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-corparate-phone-number-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        phoneNumber: value
                    });
                }}
            />
        </div>;
    };

    const renderInputs = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.content,
                gap: spaces.content / 2
            }}
        >
            <Text
                variant='body2-semiBold'
                style={{
                    marginBottom: spaces.content
                }}
            >
                {localize("registration-information")}
            </Text>
            <TextInput
                infoText={errors.includes("register-company-title-error") ? localize("register-company-title-error") : undefined}
                isError={errors.includes("register-company-title-error")}
                initialValue={registerInfo.title}
                placeholder={localize("company's-trade-name")}
                spreadBehaviour="stretch"
                id="companyTitle-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-company-title-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        title: value
                    });
                }}
            />
            <SelectBox
                infoText={errors.includes("register-occupation-error") ? localize("register-occupation-error") : undefined}
                initialSelectedItems={registerInfo.position?._id.length ? [registerInfo.position] : []}
                isError={errors.includes("register-occupation-error")}
                inputTitle={localize("your-role-in-the-company")}
                titleExtractor={(e) => e.localizedText}
                keyExtractor={(e) => e._id}
                spreadBehaviour='stretch'
                onSearch={(e) => {
                    setSearchOccupations(e);
                    setOccupationsLoading(true);
                }}
                title={localize("your-role-in-the-company")}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                isSearchable={true}
                emptyContent={() => {
                    if(occupationsLoading) {
                        return <div
                            className={classes.selectboxEmpty}
                        >
                            <Loading/>
                        </div>;
                    }

                    return <div
                        className={classes.selectboxEmpty}
                    >
                        <Text>
                            {localize("no-result-found")}
                        </Text>
                    </div>;
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    if(selectedItems[0]) {
                        setRegisterInfo({
                            ...registerInfo,
                            position: {
                                //@ts-ignore // TODO: Will fix from component library.
                                localizedText: selectedItems[0].localizedText,
                                //@ts-ignore
                                _id: selectedItems[0]._id,
                                key: selectedItems[0].__key
                            }
                        });
                        let _errors = JSON.parse(JSON.stringify(errors));
                        let nameError = "register-occupation-error";
                        let index = _errors.indexOf(nameError);
                        _errors.splice(index, 1);
                        setErrors(_errors);
                    }
                    
                    closeSheet();
                    onSuccess();
                }}
                data={getOccupation}
            />
            <SelectBox
                infoText={errors.includes("register-sector-error") ? localize("register-sector-error") : undefined}
                initialSelectedItems={registerInfo.sector?._id.length ? [registerInfo.sector] : []}
                isError={errors.includes("register-sector-error")}
                titleExtractor={(e) => e.localizedText}
                keyExtractor={(e) => e._id}
                spreadBehaviour="stretch"
                emptyContent={() => {
                    if(sectorsLoading) {
                        return <div
                            className={classes.selectboxEmpty}
                        >
                            <Loading/>
                        </div>;
                    }

                    return <div
                        className={classes.selectboxEmpty}
                    >
                        <Text>
                            {localize("no-result-found")}
                        </Text>
                    </div>;
                }}
                inputTitle={localize("sectors")}
                onSearch={(e) => {
                    setSectorsLoading(true);
                    setSearchSectors(e);
                }}
                title={localize("sectors")}
                isNeedConfirm={true}
                isSearchable={true}
                multiSelect={false}
                disabled={false}
                paginationProps={{
                    currentPage: searchSectors && searchSectors.length ? searchSectorPage : sectorPage,
                    totalDataCount: sectorTotalDataCount,
                    itemPerPage: 20,
                    onSelect(item, index) {
                        if(searchSectors && searchSectors.length) {
                            setSectorPage(item.pageNumber);
                        } else {
                            setSectorPage(item.pageNumber);
                        }

                        let params: {
                            search?: string;
                            page: number;
                            language: string;
                        } = {
                            page: item.pageNumber,
                            language: getBrowserLanguageFull()
                        };

                        if(searchSectors && searchSectors.length) {
                            params.search = searchSectors;
                        }

                        getSectorsData(params);
                    }
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    if(selectedItems[0]) {
                        setRegisterInfo({
                            ...registerInfo,
                            sector: {
                                //@ts-ignore // TODO: Will fix from component library.
                                localizedText: selectedItems[0].localizedText,
                                //@ts-ignore
                                _id: selectedItems[0]._id,
                                key: selectedItems[0].__key
                            }
                        });
                        let _errors = JSON.parse(JSON.stringify(errors));
                        let nameError = "register-sector-error";
                        let index = _errors.indexOf(nameError);
                        _errors.splice(index, 1);
                        setErrors(_errors);
                    }

                    closeSheet();
                    onSuccess();
                }}
                data={sectorData}
            />
            <TextInput
                infoText={errors.includes("register-corparate-mail-error") ? localize("register-corparate-mail-error") : undefined}
                isError={errors.includes("register-corparate-mail-error")}
                initialValue={registerInfo.mail}
                placeholder={localize("company-e-mail")}
                spreadBehaviour="stretch"
                id="corparateEmail-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-corparate-mail-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        mail: value
                    });
                }}
            />
            <TextInput
                placeholder={localize("company-website")}
                initialValue={registerInfo.website}
                spreadBehaviour="stretch"
                id="companyWebSite-input"
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        website: value
                    });
                }}
            />
            {renderPhoneInput()}
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderInputs()}
    </div>;
};
export default CompanyInfo;
