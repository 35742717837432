import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Close = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M28.379 1.642a2.115 2.115 0 0 0-2.995 0L15 12.006 4.615 1.62a2.115 2.115 0 0 0-2.994 0 2.115 2.115 0 0 0 0 2.994L12.006 15 1.62 25.384a2.115 2.115 0 0 0 0 2.995 2.115 2.115 0 0 0 2.994 0L15 17.994 25.384 28.38a2.115 2.115 0 0 0 2.995 0 2.115 2.115 0 0 0 0-2.995L17.994 15 28.38 4.615c.807-.807.807-2.166 0-2.973Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Close;
