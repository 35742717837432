
import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        width: "100%",
        userSelect: "none"
    },
    goBack: {
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 21,
        height: 42,
        width: 42
    },
    questionListContainer: {
        flexDirection: "column",
        width: "100%"
    },
    headerRight: {
        paddingRight: 0
    },
    headerTitle: {
        position: "absolute"
    },
    exportButtonsContainer: {
        flexDirection: "row"
    },
    exportButton: {
        width: "100%"
    },
    question: {
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 21,
        height: 42,
        width: 42
    },
    registerSuccessContainer: {
        justifyContent: "center",
        alignItems: "center"
    },
    registerSuccessContentContianer: {
        alignItems: "center"
    },
    successBottomSheetContent: {
        textAlign: "center"
    },
    savedContainer: {
        position: "relative"
    },
    loading: {
        justifyContent: "center",
        display: "flex",
        height: "500px",
        width: "100%"
    },
    titleInput: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    }
});
export default useStyles;
