import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    PAQResultPropsTypes
} from "./types";
import {
    FilledCheckSquareIcon
} from "../../../../../assets/svgr";

const PAQResults = ({
    paqResults
}: PAQResultPropsTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                marginBottom: spaces.contentXLarge,
                gap: spaces.inline
            }}
        >
            <Text
                variant="body-medium"
            >
                {localize("paq-results")}
            </Text>
        </div>;
    };

    const renderPAQAnswer = () => {
        return paqResults.map((item,index) => {
            return <div
                key={index}
                className={classes.paqContainer}
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                <div
                    className={classes.checkIcon}
                    style={{
                        marginBottom: spaces.content * 0.75,
                        gap: spaces.inline
                    }}
                >
                    <FilledCheckSquareIcon/>
                    <Text
                        variant="body2-regular"
                    >
                        {item.title}
                    </Text>
                </div>
                <div
                    className={classes.paqType}
                    style={{
                        marginBottom: spaces.inline,
                        marginTop: spaces.content,
                        gap: spaces.inline
                    }}
                >
                    <Text
                        variant="body3-regular"
                        color="textSecondary"
                    >
                        {item.type === "yes-no" ? localize("yes-no-answer") : localize("numeric-answer")}
                    </Text>
                </div>
                <div
                    className={classes.paqResponse}
                    style={{
                        marginBottom: spaces.inline,
                        borderColor: colors.stroke,
                        borderWidth: borders.line
                    }}
                >
                </div>
                <Text
                    variant="body3-regular"
                >
                    {localize("recived-answer")}: {item.type === "numeric" ? item.answer : item.answer === true ? localize("yes") : localize("no") }
                </Text>
            </div>;
        });
    };

    return <div
        className={classes.container}
        style={{
            paddingTop: spaces.contentXLarge
        }}
    >
        {renderHeader()}
        <div
            className={classes.contentContainer}
        >
            {renderPAQAnswer()}
        </div>
    </div>;
};

export default PAQResults;
