import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledNotification = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M9.893 26.539A6.48 6.48 0 0 0 15 29a6.48 6.48 0 0 0 5.108-2.461c-3.391.459-6.825.459-10.215 0ZM24.449 10.8v.986c0 1.183.337 2.34.97 3.324l1.55 2.412c1.417 2.203.335 5.198-2.128 5.894a36.11 36.11 0 0 1-19.682 0c-2.464-.697-3.545-3.691-2.128-5.894l1.55-2.412c.633-.985.97-2.141.97-3.324V10.8C5.551 5.388 9.781 1 15 1c5.218 0 9.449 4.388 9.449 9.8Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledNotification;
