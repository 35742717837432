import {
    createUseStyles
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        overflowX: "hidden",
        userSelect: "none", 
        overflow: "auto",
        display: "flex"
    },
    modalHeader: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    closeButtonField: {
        justifyContent: "end",
        flexDirection: "row",
        position: "absolute",
        display: "flex",
        right: 5,
        top: 5
    },
    content:{
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    citiesContianer: {
        flexDirection: "column",
        display: "flex"
    },
    saveButtonField:{
        justifyContent: "end",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    citiesSelectContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    citiesSelectContent: {
        justifyContent: "space-between",
        borderBottom: "solid",
        flexDirection: "row",
        alignItems:"center",
        display: "flex"
    },
    occupationContent: {
        boxSizing: "border-box",
        overflowX: "hidden",
        overflow: "auto",
        height: "10rem"
    },
    filterHeader:{
        justifyContent: "space-between",
        border: "1px solid #E2E2E2",
        alignItems: "center",
        flexDirection: "row",
        borderRadius: "8px",
        textAlign: "center",
        display: "flex"
    },
    checkBoxContainer:{
        border: "1px solid #E2E2E2",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8px",
        textAlign: "center",
        display: "flex"
    },
    dataContainer:{
        flexDirection: "column",
        display: "flex"
    },
    selectedDataContainer:{
        flexWrap: "wrap",
        display: "flex"
    },
    alarmTitleField:{
        flexDirection: "column",
        display: "flex"
    }
});
