import React, {
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CompanyPhoneNumberTypes
} from "./types";
import {
    RoundArrowIcon
} from "../../../../../../../../assets/svgr";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    GetAreaCodesResponseType,
    GetAreaCodesObjectType
} from "../../../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    getErrorText 
} from "../../../../../../../../utils";

const ChangeWorkerPhoneNumber = ({
    areaCodes,
    setErrors,
    setViews,
    setToken,
    errors
}: CompanyPhoneNumberTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [areaCode, setAreaCode] = useState<GetAreaCodesResponseType>();
    const [loadingButton, setLoadingButton] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleChangePhoneNumber = () => {
        let _errors = JSON.parse(JSON.stringify(errors));

        if (!areaCode) {
            _errors.push("areaCode-empty-error");
        }

        if (phoneNumber.length < 10) {
            _errors.push("phoneNumber-min-length-error");
        }

        if (!/^\d+$/.test(phoneNumber)) {
            _errors.push("phoneNumber-numeric-error");
        }

        if (phoneNumber.length > 10) {
            _errors.push("phoneNumber-max-length-error");
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        setLoadingButton(true);
        RESTService.action("ChangePhoneNumber", {
            phone: phoneNumber,
            //@ts-ignore
            phoneAreaCode: areaCode
        })
            .then((res) => {
                setToken(res.token);
                setLoadingButton(false);
                setViews("sms-phone-number");
            })
            .catch((err) => {
                console.error("ChangePhoneNumber Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSaveButton = () => {
        return <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                onClick={() => {
                    handleChangePhoneNumber();
                }}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderTitle = () => {
        const phoneNumberLengthError = errors.includes("phoneNumber-min-length-error");
        const phoneNumberNumericError = errors.includes("phoneNumber-numeric-error");
        const phoneNumberMaxLengthError = errors.includes("phoneNumber-max-length-error");

        const errorText = phoneNumberLengthError
            ? localize("ıt-must-be-at-least-10-characters")
            : phoneNumberNumericError
                ? localize("only-numbers-are-allowed")
                : phoneNumberMaxLengthError
                    ? localize("it-cannot-be-longer-than-10-digits")
                    : undefined;
            
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("please-enter-your-new-contact-number")}
            </Text>
            <div
                className={classes.inputContainer}
            >
                <div
                    className={classes.areaCodeSelectBox}
                    style={{
                        marginRight: spaces.content 
                    }}
                >
                    <SelectBox
                        infoText={errors.includes("areaCode-empty-error") ? localize("needs-to-be-filled") : undefined}
                        isError={errors.includes("areaCode-empty-error")}
                        keyExtractor={(e) => e._id}
                        spreadBehaviour='stretch'
                        //@ts-ignore
                        titleExtractor={(
                            item: GetAreaCodesObjectType
                        ) => {
                            return <div 
                                className={classes.areaCodesContainer}>
                                <Text>
                                    {item.flagUnicode}
                                </Text>
                                <Text>
                                    {item.value}
                                </Text>
                            </div>;
                        }}
                        isNeedConfirm={true}
                        multiSelect={false}
                        disabled={false}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            setAreaCode(
                                // @ts-ignore // TODO: Will be fixed
                                selectedItems[0]._id
                            );
                            let updatedErrors = errors.filter(err => err !== "areaCode-empty-error");
                            setErrors(updatedErrors);
                            closeSheet();
                            onSuccess();
                        }}
                        style={{
                            marginTop: spaces.content / 2,
                        }}
                        //@ts-ignore
                        data={areaCodes?.payload}
                    />
                </div>
                <div
                    className={classes.textInputPhoneNumber}
                >
                    <TextInput
                        isError={phoneNumberLengthError || phoneNumberNumericError || phoneNumberMaxLengthError}
                        infoText={errorText}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            _errors = _errors.filter((error: string) => 
                                error !== "phoneNumber-min-length-error" && 
                                error !== "phoneNumber-numeric-error" &&
                                error !== "phoneNumber-max-length-error"
                            );
                            setErrors(_errors);
                        }}
                        placeholder={"(5XX) XXX XX XX"}
                        onChangeText={(e) => {
                            setPhoneNumber(e);
                        }}
                        className={classes.textInput}
                        style={{
                            marginTop: spaces.content / 2
                        }}
                    />
                </div>
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderTitle()}
        </div>;
    };


    return <div
        style={{
            padding: spaces.content * 2,
        }}
    >
        <div
            className={classes.container}
            style={{
                gap: spaces.content
            }}
        >
            <div
                onClick={() => setViews("default-open")}
                className={classes.iconCursor}
            >
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("contact-number-update")}
            </Text>
        </div>
        {renderContent()}
        {renderSaveButton()}
    </div>;
};

export default ChangeWorkerPhoneNumber;
