import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Instagram = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M9.12 1h11.76C25.36 1 29 4.64 29 9.12v11.76A8.12 8.12 0 0 1 20.88 29H9.12C4.64 29 1 25.36 1 20.88V9.12A8.12 8.12 0 0 1 9.12 1Zm-.28 2.8A5.04 5.04 0 0 0 3.8 8.84v12.32a5.037 5.037 0 0 0 5.04 5.04h12.32a5.04 5.04 0 0 0 5.04-5.04V8.84a5.037 5.037 0 0 0-5.04-5.04H8.84Zm13.51 2.1a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5ZM15 8a7 7 0 1 1 0 14 7 7 0 0 1 0-14Zm0 2.8a4.2 4.2 0 1 0 0 8.4 4.2 4.2 0 0 0 0-8.4Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default Instagram;
