import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        "@media (max-width: 1040px)": {
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            flexDirection: "column",
            height: "100%",
            display:"flex",
        },
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            justifyContent: "flex-start",
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    noApplicant: {
        alignItems: "center",
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        maxWidth: "30%",
        minWidth: "25%",
        height: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    responsiveNoApplicant: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        maxWidth: "100%",
        minWidth: "100%",
        height: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    noApplicantHeader: {
        flexDirection: "column",
        alignSelf: "flex-start",
        display:"flex"
    },
    leftMainContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        display: "flex",
        maxWidth: "30%",
        minWidth: "25%",
        height: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        maxWidth: "100%",
        minWidth: "100%",
        height: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    mainResponsiveCandidateContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        maxWidth: "100%",
        minWidth: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    responsiveCandidateContainer: {
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        maxWidth: "100%",
        minWidth: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    paginationContainer: {
        borderStyle: "solid",
        display: "flex",
        overflow: "hidden"
    },
    responsiveHeader: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        minHeight: 600,
        width: "100%",
        height: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    noApplicantcontentContainer: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        minHeight: 600,
        width: "100%",
        height: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    noApplicanting: {
        objectFit: "cover",
        width: 300,
        height: 300
    },
    advertDetailsContent: {
        flexDirection: "column",
        width: "100%",
        display: "flex"
    },
    generalInfo: {
        flexDirection: "column",
        display: "flex"
    },
    ResponsiveContentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        minHeight: 600,
        width: "100%",
        height: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    filterContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    responsiveFilterContainer: {
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
    buttonContent: {
        flexDirection: "row",
        display: "flex",
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    line: {
        width: "100%",
        height: "1px"
    },
    leftLoading: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 200,
        width: 200
    },
    languageContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    contentContainerStyle:{
        flexDirection: "column",
        width: "100%",
        display: "flex"
    },
    loadingcontainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "500px",
        width: "50vh" //TODO: fix
    },
    choosenFiltersChip: {
        display: "flex",
        flexWrap: "wrap"
    },
    choosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    responsiveChoosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (min-width: 1040px)": {
            display: "none"
        }
    },
});
export default useStyles;