import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        display: "flex",
        height: "100%",
        width: "100%",
    },
    chatHeader: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    line: {
        width: "100%",
        height: 1
    },
    filterButtons: {
        justifyContent: "flex-start",
        scrollbarWidth: "none",
        flexDirection: "row",
        alignItems: "center",
        overflowX: "auto",
        display: "flex",
        width: "100%",
        " & > *": {
            flex: "0 0 auto"
        }
    },
    contentContainer: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "row",
        maxHeight: "100%",
        display: "flex",
        height: "82vh",
        width: "100%",
        "@media screen and (max-width: 750px)": {
            gap: "0 !important",
            height: "89vh",
        }
    },
    conversationContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignSelf: "stretch",
        display: "flex",
        minWidth: 300,
        width: "100%"
    },
    conversationHeader: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    messageBoxNavbar: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    messageBoxNavbarContent: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    messagesContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "100%",
        minWidth: 330,
        width: "100%"
    },
    messageBoxContainer: {
        flexDirection: "column",
        position: "relative",
        overflowY: "auto",
        display: "flex",
        height: "100%",
    },
    unReadMessageBoxContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        position: "sticky",
        display: "flex",
        width: "100%",
        zIndex: 999
    },
    unReadMessageBox: {
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
    },
    messageBoxHeaderTitle: {
        flexDirection: "column",
        display: "flex"
    },
    profilePhotoContainer: {
        justifyContent: "center",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        overflow: "hidden",
        display: "flex",
        minHeight: 60,
        minWidth: 60,
        height: 60,
        width: 60,
    },
    userInfoContainer: {
        flexDirection: "column",
        display: "flex"
    },
    messageLoadingContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
    },
    messageValueContainer: {
        flexDirection: "column",
        alignItems: "flex-end",
        display: "flex",
        "@media screen and (min-width: 750px)": {
            gap: "0px !important"
        },
        "@media screen and (max-width: 750px)": {
            alignItems: "flex-start",
            flexDirection: "row"
        }
    },
    newAdvertCardContainer: {
        borderStyle: "solid",
        minWidth: 250,
        "@media screen and (max-width: 1050px)": {
            display: "none"
        }
    },
    stateCardContainer: {
        justifyContent: "center",
        display: "flex",
        width: "100%"
    },
    optionDots: {
        justifyContent: "center",
        flexDirection:"column",
        alignItems: "center",
        display: "flex",
        gap: 4
    },
    optionDot: {
        borderRadius: 2,
        height: 4,
        width: 4,
    },
    optionsContent: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        textAlign: "left",
        display: "flex",
    },
    messageBubbleField:{
        alignItems: "flex-end",
        justifyContent: "end",
        flexDirection:"row"
    },
    profilePhotoField:{
        objectFit: "cover",
        height: "100%",
        width: "100%"
    },
    redirectButtonField:{
        justifyContent: "end",
        flexDirection: "row",
        position: "sticky",
        alignItems: "end",
        display: "flex"
    },
});
export default useStyles;
