import {
    useState 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    CheckBox,
    Button,
    Text,
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";

const EmailPreferences = () => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [isJobRecommendationStatus, setIsJobRecommendationStatus] = useState<boolean>(false);
    const [isMarketingMailsStatus, setIsMarketingMailsStatus] = useState<boolean>(true);
    const [isApplicationStatus, setIsApplicationStatus] = useState<boolean>(false);
    const [isSystemMailsStatus, setIsSystemMailsStatus] = useState<boolean>(true);
    const [isJobPostingStatus, setIsJobPostingStatus] = useState<boolean>(false);

    const emailPreference = () => {
        //TODO: will be request send.
    };

    const renderInformationalContent= () =>{
        return <div
            className={
                classes.informationalContent
            }
            style={{
                gap: spaces.content * 2
            }}
        >
            <Text
                variant="header3-medium"
                color="textDark"
                className={
                    classes.header
                }
            >
                {localize("update-your-email-preference")}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("email-preference-update-page-text-content")}
            </Text>
        </div>;
    };

    const renderPreferenceContent = () =>{
        return <div
            className={
                classes.preferenceContainer
            }
            style={{
                gap: spaces.content * 4
            }}
        >
            <div 
                className={
                    classes.preferenceContent
                }
                style={{
                    gap: spaces.content * 2
                }}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                
                    }}
                >
                    {localize("mail-content-types")}
                </Text>
                <div
                    className={
                        classes.preferenceField
                    }   
                    style={{
                        gap: spaces.content
                    }}
                >
                    <div
                        className={
                            classes.preferenceBox
                        }
                    >
                        <div
                            className={
                                classes.preferenceType
                            }
                        >
                            <CheckBox
                                isSelected={isMarketingMailsStatus}
                                onChange={() => {
                                    setIsMarketingMailsStatus(!isMarketingMailsStatus);
                                }}
                            />
                            <Text
                                variant="header5-medium"
                                color="textDark"
                            >
                                {localize("marketing-emails")} 
                            </Text>
                        </div>
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize("marketing-emails-text-content")} 
                        </Text>
                    </div>
                    <div
                        className={
                            classes.preferenceBox
                        }
                    >
                        <div
                            className={
                                classes.preferenceType
                            }
                        >
                            <CheckBox
                                isSelected={isSystemMailsStatus}
                                onChange={() => {
                                    setIsSystemMailsStatus(!isSystemMailsStatus);
                                }}
                            />
                            <Text
                                variant="header5-medium"
                                color="textDark"
                            >
                                {localize("system-emails")} 
                            </Text>
                        </div>
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize("system-emails-text-content")} 
                        </Text>
                    </div>
                    <div
                        className={
                            classes.preferenceBox
                        }
                    >
                        <div
                            className={
                                classes.preferenceType
                            }
                        >
                            <CheckBox
                                isSelected={isJobRecommendationStatus}
                                onChange={() => {
                                    setIsJobRecommendationStatus(!isJobRecommendationStatus);
                                }}
                            />
                            <Text
                                variant="header5-medium"
                                color="textDark"
                            >
                                {localize("job-recommendation")} 
                            </Text>
                        </div>
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize("job-recommendation-text-content")} 
                        </Text>
                    </div>
                    <div
                        className={
                            classes.preferenceBox
                        }
                    >
                        <div
                            className={
                                classes.preferenceType
                            }
                        >
                            <CheckBox
                                isSelected={isApplicationStatus}
                                onChange={() => {
                                    setIsApplicationStatus(!isApplicationStatus);
                                }}
                            />
                            <Text
                                variant="header5-medium"
                                color="textDark"
                            >
                                {localize("application-status")} 
                            </Text>
                        </div>
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize("application-status-text-content")} 
                        </Text>
                    </div>
                    <div
                        className={
                            classes.preferenceBox
                        }
                    >
                        <div
                            className={
                                classes.preferenceType
                            }
                        >
                            <CheckBox
                                isSelected={isJobPostingStatus}
                                onChange={() => {
                                    setIsJobPostingStatus(!isJobPostingStatus);
                                }}
                            />
                            <Text
                                variant="header5-medium"
                                color="textDark"
                            >
                                {localize("job-posting")} 
                            </Text>
                        </div>
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize("job-posting-text-content")} 
                        </Text>
                    </div>
                </div>
            </div>
            <Button
                onClick={()=>{
                    emailPreference();
                }}
                spreadBehaviour="free"
                title={localize("save")}   
                style={{
                    width: "246px"
                }}
            />
        </div>;
    };

    return <div
        className={
            classes.container
        }
        style={{
            padding: `${spaces.containerXLarge}px ${spaces.containerXLarge * 2}px`,
            backgroundColor: colors.layer3,
            gap: spaces.containerXLarge
        }}
    >   
        {renderInformationalContent()}
        {renderPreferenceContent()}
    </div>;
};

export default EmailPreferences;
