import useStyles from "./styles";
import {
    IOCoreTranslationType,
    IOCoreColorsType,
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CurrentCandidatePropsType
} from "./types";
import moment from "moment";
import {
    DocumentVariantIcon,
    ChrevronRightIcon,
    FilledCheckIcon,
    PencilEditIcon,
    ArrowDownIcon,
    DocumentsIcon,
    DownloadIcon,
    CompanyIcon,
    DiplomaIcon,
    CloseIcon
} from "../../../../../assets/svgr";
import QualificationCard from "../qualificationCard";
import CandidateInfo from "../candidateInfo";
import PAQResults from "../paqResults";
import {
    CandidateWorkExperienceType,
    ApplicationsObjectType,
    CandidateEducationType,
    CandidateCapabilities,
    DocumentStatusType 
} from "../../../../../services/restAPI/actions/getAplicationsForEmployer/types";

const CurrentCandidate = ({
    candidateContactDropdownRef,
    setShowAllWorkExperience,
    setShowCandidateContact,
    setIsShowMoreDocuments,
    setShowAllCapabilities,
    handleCandidateStatus,
    showAllWorkExperience,
    handleDocumentStatus,
    showCandidateContact,
    downloadAllDocument,
    setCurrentCandidate,
    isShowMoreDocuments,
    setShowAllEducation,
    showAllCapabilities,
    setSelectedStatus,
    statusDropdownRef,
    downloadDocument,
    showAllEducation,
    currentCandidate,
    selectedStatus,
    isResponsive,
    setIsStatus,
    setViews,
    isStatus
}: CurrentCandidatePropsType) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const renderCandidateInfo = () => {
        if(isResponsive) {
            return null;
        }

        if(!currentCandidate) {
            return null;
        }

        return <CandidateInfo
            candidateContactDropdownRef={candidateContactDropdownRef}
            setShowCandidateContact={setShowCandidateContact}
            handleCandidateStatus={handleCandidateStatus}
            showCandidateContact={showCandidateContact}
            //@ts-ignore
            setCurrentCandidate={setCurrentCandidate}
            setSelectedStatus={setSelectedStatus}
            currentCandidate={currentCandidate}
            statusDropdownRef={statusDropdownRef}
            selectedStatus={selectedStatus}
            setIsStatus={setIsStatus}
            isStatus={isStatus}
        />;
    };

    const renderCandidateProcesses = () => {
        if(currentCandidate?.status !== "rejected") {
            return null;
        }

        return <div
            className={classes.candidateProcessContainer}
            style={{
                paddingBottom: spaces.containerXLarge / 2,
                paddingTop: spaces.containerXLarge / 2
            }}
        >
            <Text
                variant="header5-medium"
            >
                {localize("candidate-process")}
            </Text>
            <div
                className={classes.candidateProcessContent}
                style={{
                    paddingTop: spaces.content * 1.25
                }}
            >
                <div
                    className={classes.closeIconBorder}
                    style={{
                        marginRight: spaces.content * 0.75,
                        borderRadius: radiuses.hard * 2,
                        borderWidth: borders.indicator,
                        borderColor: colors.error,
                        padding: spaces.content
                    }}
                >
                    <div
                        className={classes.closeIcon}
                        style={{
                            backgroundColor: colors.error,
                            borderRadius: radiuses.hard,
                            padding: spaces.content / 2
                        }}
                    >
                        <CloseIcon
                            color={colors.white}
                        />
                    </div>
                </div>
                <div
                    className={classes.candidateProcessTexts}
                    style={{
                        gap: spaces.inline / 2
                    }}
                >
                    <Text
                        variant="body-medium"
                    >
                        {localize("candidate-rejeceted")}
                    </Text>
                    <Text
                        variant="body2-medium"
                        color="textGrey"
                    >
                        {moment(currentCandidate.updatedAt).format("DD.MM.YYYY  ")}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderDocumentProcesses = () => {
        if(!(currentCandidate?.status === "documentSent" || currentCandidate?.status === "documentWaiting" || currentCandidate?.status === "reuploadDocumentWaiting")) {
            return null;
        }

        return <div
            className={classes.docmentProcessContainer}
            style={{
                paddingBottom: spaces.containerXLarge / 2,
                paddingTop: spaces.containerXLarge / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line
                
            }}
        >
            <Text
                variant="header5-medium"
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                {localize("doc-operations")}
            </Text>
            {renderDocumentInitialRequest()}
        </div>;
    };

    const renderDocumentInitialRequest = () => {
        let _documentStats: {
            title: keyof IOCoreTranslationType,
            color: string;
        } = {
            title: "waiting-for-doc",
            color: colors.warning
        };

        if(currentCandidate?.status === "documentSent") {
            _documentStats.title = "doc-sended",
            _documentStats.color = colors.primary;
        }

        if(currentCandidate?.status === "documentWaiting") {
            _documentStats.title = "waiting-for-doc",
            _documentStats.color = colors.warning;
        }

        if(currentCandidate?.status === "reuploadDocumentWaiting") {
            _documentStats.title = "waiting-for-reupload-doc",
            _documentStats.color = colors.primary;
        }

        return <div
            className={classes.docInitialContainer}
        >
            <div
                className={classes.docVariantIcon}
                style={{
                    marginRight: spaces.content * 0.75,
                    borderColor: _documentStats.color,
                    borderRadius: radiuses.hard * 2,
                    backgroundColor: colors.white,
                    borderWidth: borders.line,
                    padding: spaces.content
                    
                }}
            >
                <DocumentVariantIcon
                    color={_documentStats.color}
                    size={30}
                />
            </div>
            <div
                className={classes.docInitialContent}
            >
                <div
                    className={classes.docInitialContentInner}
                    style={{
                        borderBottomColor: colors.stroke,
                        borderBottomWidth: borders.line,
                        paddingBottom: spaces.content,
                        marginBottom: spaces.content
                    }}
                >
                    <div
                        className={classes.docInitalContainerText}
                    >
                        <Text
                            variant="body-medium"
                        >
                            {localize(_documentStats.title)}
                        </Text>
                        <Text
                            variant="body2-medium"
                            color="textSecondary"
                        >
                            {moment(currentCandidate?.updatedAt).format("DD.MM.YYYY")}
                        </Text>
                    </div>
                    <div
                        className={classes.downloadIconContainer}
                    >
                        <div
                            onClick={() => {
                                downloadAllDocument(currentCandidate?._id ?? "");
                            }} 
                        >
                            <DownloadIcon
                                size={30}
                                color={colors.textSecondary}
                            />
                        </div>
                        <Text
                            variant="body3-regular"
                            color="textSecondary"
                        >
                            {localize("download-all")}
                        </Text>
                    </div>
                </div>
                {renderDocumentList()}
            </div>
        </div>;
    };

    const renderDocumentList = () => {
        return <div
            className={classes.docListContainer}
        >
            <div
                className={classes.docListContent}
            >
                <Text
                    variant="body2-medium"
                >
                    {localize("see-all-docs")}
                </Text>
                <div
                    className={classes.editIcon}
                    onClick={() => {
                        setIsShowMoreDocuments(!isShowMoreDocuments);
                    }}
                >
                    <ArrowDownIcon
                        size={24}
                        color={colors.primary}
                    />
                </div>
            </div>
            {renderDocuments()}
        </div>;
    };

    const renderDocuments = () => {
        if(!isShowMoreDocuments) {
            return null;
        }

        return currentCandidate?.documents.map((item, index) => {
            return <div
                className={classes.docGeneralContainer}
                style={{
                    borderBottomStyle: index + 1 === currentCandidate.documents.length ? "none" : "solid",
                    paddingBottom: spaces.content * 0.75,
                    paddingTop: spaces.content * 0.75,
                    borderBottomColor: colors.stroke,
                    borderBottomWidth: borders.line
                }}
            >
                <Text
                    variant="body3-medium"
                    color="textSecondary"
                >
                    {item.title}
                </Text>
                {renderDocumentWaiting(item.status)}
                {renderDocumentOperations(item._id,index,item.status)}
                {renderDocumentEdit(item._id,index,item.status)}
            </div>;
        });
    };

    const renderDocumentWaiting = (status: DocumentStatusType) => {
        if(status !== "not-sent") {
            return null;
        }

        return <div
            className={classes.docWaitingContainer}
            style={{
                gap: spaces.content * 0.75
            }}
        >
        
            <Text
                color="textDark"
                variant="body4-regular"
            >
                {localize("waiting-for-doc")}
            </Text>
        </div>;
    };

    const renderDocumentOperations = (id: string, index: number, status: DocumentStatusType) => {
        if(status !== "sent") {
            return null;
        }

        return <div
            className={classes.docWaitingContainer}
            style={{
                gap: spaces.content * 0.75
            }}
        >
            <div
                onClick={() => {
                    handleDocumentStatus(id,true);
                }}
            >
                <FilledCheckIcon
                    size={32}
                    color={colors.success}
                />
            </div>
            <div
                className={classes.rejectDocIcon}
                onClick={() => {
                    handleDocumentStatus(id,false);
                }}
                style={{
                    backgroundColor: colors.error,
                    borderRadius: radiuses.hard,
                    padding: spaces.inline
                }}
            >
                <CloseIcon
                    color={colors.white}
                    size={15}
                />
            </div>
            <div
                className={classes.downloadAllDocIcon}
                style={{
                    borderLeftColor: colors.textSecondary,
                    borderWidth: borders.indicator
                }}
            >
            </div>
            <div
                className={classes.downloadAllDocCursor}
                onClick={() => {
                    downloadDocument(id);
                }}
            >
                <DownloadIcon
                    color={colors.textSecondary}
                    size={30}
                />
            </div>
        </div>;
    };

    const renderDocumentEdit = (id: string, index: number, status: DocumentStatusType) => {
        if(status === "sent" || status === "not-sent") {
            return null;
        }

        let _docStatus: {
            title: keyof IOCoreTranslationType;
            color: keyof IOCoreColorsType;
        } = {
            title: "approved",
            color: "success"
        };

        if(status === "rejected") {
            _docStatus.title = "rejected-doc";
            _docStatus.color = "error";
        }

        return <div
            className={classes.docEditContainer}
            style={{
                gap: spaces.content * 0.75
            }}
        >
            
            <Text
                color={_docStatus.color}
                variant="body4-regular"
            >
                {localize(_docStatus.title)}
            </Text>
            <div
                className={classes.editIcon}
                onClick={() => {
                    let _currentCandidate:ApplicationsObjectType = JSON.parse(JSON.stringify(currentCandidate));
        
                    _currentCandidate.documents[index].status = "sent";

                    setCurrentCandidate(_currentCandidate);
                }}
            >
                <PencilEditIcon
                    color={colors.textSecondary}
                    size={24}
                />
            </div>
            <div
                className={classes.verticleLine}
                style={{
                    borderLeftColor: colors.textSecondary,
                    borderWidth: borders.indicator
                }}
            >
            </div>
            <div
                className={classes.downloadAllDocCursor}
                onClick={() => {
                    downloadDocument(id);
                }}
            >
                <DownloadIcon
                    color={colors.textSecondary}
                    size={30}
                />
            </div>
        </div>;
    };

    const renderPerformanceTestProcesses = () => {
        if(!(currentCandidate?.status === "performanceTestWaiting" || currentCandidate?.status === "performanceTestSubmitted")) {
            return null;
        }

        let _ptStatus: {
            title: keyof IOCoreTranslationType;
            color: keyof IOCoreColorsType;
        } = {
            title: "waiting-for-complete",
            color: "warning"
        };

        if(currentCandidate.status === "performanceTestSubmitted") {
            _ptStatus.title = "completed",
            _ptStatus.color = "success";
        }

        return  <div
            className={classes.ptProcessContainer}
            style={{
                cursor: currentCandidate?.status === "performanceTestSubmitted" ? "pointer" : "initial",
                paddingBottom: spaces.containerXLarge / 2,
                paddingTop: spaces.containerXLarge / 2
            }}
        >
            <Text
                variant="header5-medium"
            >
                {localize("pt-test")}
            </Text>
            <div
                className={classes.ptProcessContent}
            >
                <div
                    className={classes.ptProcessInnerContent}
                    style={{
                        paddingTop: spaces.content * 1.25
                    }}
                >
                    <div
                        className={classes.ptIconContatiner}
                        style={{
                            borderColor: colors[_ptStatus.color],
                            borderRadius: radiuses.hard * 2,
                            marginRight: spaces.content,
                            borderWidth: borders.line,
                            padding: spaces.content
                        }}
                    >
                        <DocumentVariantIcon
                            color={colors[_ptStatus.color]}
                        />
                    </div>
                    <div
                        className={classes.ptTextContent}
                        style={{
                            gap: spaces.inline / 2
                        }}
                    >
                        <Text
                            variant="body-medium"
                        >
                            {localize(_ptStatus.title)}{currentCandidate.status === "performanceTestSubmitted" ? `- %${currentCandidate.performanceTest.score}` : ""}
                        </Text>
                        <Text
                            variant="body2-medium"
                            color="textGrey"
                        >
                            {moment(currentCandidate.updatedAt).format("DD.MM.YYYY")}
                        </Text>
                    </div>
                </div>
                <ChrevronRightIcon
                    color={colors.textGrey}
                />
            </div>
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderCandidateDetails = () => {
        return <div
            style={{
                marginBottom: spaces.contentLarge,
                paddingTop: spaces.contentXLarge,
                gap: spaces.contentXLarge
            }}
        >
            <Text
                variant="header5-medium"
            >
                {localize("peak-to-profile")}
            </Text>
            <div
                style={{
                    paddingTop: spaces.contentXLarge
                }}
            >
                <div
                    className={classes.resumeHeader}
                >
                    <Text
                        variant="body-medium"
                    >
                        {localize("cv")}
                    </Text>
                    <Button
                        onClick={() => {
                            setShowAllWorkExperience(!showAllWorkExperience);
                        }}
                        title={localize("all-see")}
                        variant="ghost"
                    />
                </div>
                {renderResume()}
            </div>
            <div
                style={{
                    paddingTop: spaces.contentXLarge
                }}
            >
                <div
                    className={classes.educationHeader}
                >
                    <Text
                        variant="body-medium"
                    >
                        {localize("education")}
                    </Text>
                    <Button
                        onClick={() => {
                            setShowAllEducation(!showAllEducation);
                        }}
                        title={localize("all-see")}
                        variant="ghost"
                    />
                </div>
                {renderEducation()}
            </div>
            <div
                style={{
                    paddingTop: spaces.contentXLarge
                }}
            >
                <div
                    className={classes.capabilitiesHeader}
                >
                    <Text
                        variant="body-medium"
                    >
                        {localize("abilities")}
                    </Text>
                    <Button
                        onClick={() => {
                            setShowAllCapabilities(!showAllCapabilities);
                        }}
                        title={localize("all-see")}
                        variant="ghost"
                    />
                </div>
                {renderCapabilites()}
            </div>
        </div>;
    };

    const renderResume = () => {
        if(!currentCandidate) {
            return null;
        }

        let _workExperience: Array<CandidateWorkExperienceType> = currentCandidate.workExperience.slice(0,2);
        if(showAllWorkExperience) {
            _workExperience = currentCandidate.workExperience;
        }

        return _workExperience.map((item, index) => {
            return <div>
                {renderLine()}
                <QualificationCard
                    category={`${moment(item.startDate).year()} - ${moment(item.endDate).year()}`}
                    title={item.jobTitle.localizedText}
                    level={item.companyName}
                    logo={() => <CompanyIcon
                        size={30}
                    />}
                />
            </div>;
        });
    };

    const renderEducation = () => {
        if(!currentCandidate) {
            return null;
        }

        let _education: Array<CandidateEducationType> = currentCandidate.education.slice(0,2);
        
        if(showAllEducation) {
            _education = currentCandidate.education;
        }

        return _education.map((item) => {
            return <div>
                {renderLine()}
                <QualificationCard
                    category={`${moment(item.startDate).year()} - ${moment(item.endDate).year()}`}
                    level={`${item.educationLevel.localizedText}, ${item.major}`}
                    title={item.school}
                    logo={() => <DiplomaIcon
                        size={30}
                    />
                    }
                />
            </div>;
        });
    };

    const renderCapabilites = () => {
        if(!currentCandidate) {
            return null;
        }

        let _capabilities: Array<CandidateCapabilities> = currentCandidate.capabilities.slice(0,2);

        if(showAllCapabilities) {
            _capabilities = currentCandidate.capabilities;
        }

        return _capabilities.map((item, index) => {
            return <div>
                {renderLine()}
                <QualificationCard
                    logo={() => <DocumentsIcon
                        size={30}
                    />
                    }
                    title={item.localizedText}
                    level={String(item.point)}
                    category={"alan"} /* //TODO: check this */
                />
            </div>;
        });
    };

    const renderPAQResults = () => {
        if(!currentCandidate?.PAQ.length) {
            return null;
        }

        return <PAQResults
            paqResults={currentCandidate.PAQ}
        />;
    };

    const renderPTResultCard = () => {
        if(!currentCandidate) {
            return null;
        }

        if(currentCandidate.performanceTest.questions) {
            return <div
                style={{
                    paddingBottom: spaces.content
                }}
            >
                {renderLine()}
                <div
                    className={classes.ptResultCardContainer}
                    style={{
                        paddingTop: spaces.contentLarge,
                        marginBottom: spaces.content
                    }}
                >
                    <Text
                        variant="body-medium"
                    >
                        {localize("review-pt-results")}
                    </Text>
                    <div
                        className={classes.arrowDownContainer}
                        onClick={() => {
                            setViews("ptReview");
                        }}
                    >
                        <ArrowDownIcon
                            color={colors.primary}
                        />
                    </div>
                </div>
                <Text
                    variant= "body2-medium"
                    color= {currentCandidate.performanceTest.score < 50 ? "success" : "warning"}
                >
                    {`${localize("ideal-response-rate")}%${currentCandidate.performanceTest.score}`}
                </Text>
            </div>;
        }
        return null;
    };

    return <div
        className={classes.container}
    >
        {renderCandidateInfo()}
        {renderCandidateProcesses()}
        {renderDocumentProcesses()}
        {renderPerformanceTestProcesses()}
        {renderLine()}
        {renderCandidateDetails()}
        {renderLine()}
        {renderPAQResults()}
        {renderPTResultCard()}
    </div>;
};

export default CurrentCandidate;
