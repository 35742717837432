import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        position: "relative",
        display: "flex"
    },
    dropDownContainer: {
        flexDirection: "column",
        position: "absolute",
        borderStyle: "solid",
        display: "flex",
        minWidth: "160px",
        zIndex: 9999
    },
    renderOptionsContainer: {
        flexDirection: "row",
        cursor: "pointer",
        display: "flex",
        width: "100%"
    },
    itemContent: {
        flexDirection: "column",
        display:"flex",
        width: "100%"
    }
});
export default useStyles;