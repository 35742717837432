import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Help = ({
    color = "#000",
    size = 18,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 18 / size;
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M9 1.688a8.062 8.062 0 1 0 0 16.124A8.062 8.062 0 0 0 9 1.687ZM2.062 9.75a6.91 6.91 0 0 1 1.65-4.492L6.116 7.66a3.546 3.546 0 0 0-.678 2.09c0 .78.252 1.503.678 2.09l-2.402 2.402a6.91 6.91 0 0 1-1.65-4.492ZM6.91 6.865 4.508 4.463A6.91 6.91 0 0 1 9 2.813a6.91 6.91 0 0 1 4.492 1.65L11.09 6.865A3.546 3.546 0 0 0 9 6.187c-.78 0-1.503.252-2.09.678Zm-2.402 8.172A6.91 6.91 0 0 0 9 16.687a6.91 6.91 0 0 0 4.492-1.65l-2.402-2.402a3.547 3.547 0 0 1-2.09.678c-.78 0-1.503-.252-2.09-.678l-2.402 2.402Zm7.377-3.197 2.402 2.402a6.91 6.91 0 0 0 1.65-4.492 6.91 6.91 0 0 0-1.65-4.492L11.885 7.66c.426.587.678 1.31.678 2.09 0 .78-.252 1.503-.678 2.09ZM6.563 9.75a2.438 2.438 0 1 1 4.875 0 2.438 2.438 0 0 1-4.876 0Z"
            transform={`scale(${1 / pathScale})`}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Help;