import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    Text
} from "isinolacak-web-cl";
import {
    PackageCardForPaymenyPageProps 
} from "./types";

const PackageCardForPaymentPage = ({
    isSelected,
    onChange,
    payment,
    title,
    style,
    type
}: PackageCardForPaymenyPageProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half,
            borderColor: colors.primary,
            borderWidth: borders.line,
            padding: spaces.content
        }}
        onClick={() =>{
            onChange();
        }}
    >
        <div
            className={classes.leftContainer}
            style={{
                marginRight: spaces.container * 1.5,
                gap: spaces.inline
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {title}
            </Text>
            <Text
                variant='body3-regular'
                color='textSecondary'
            >
                {type ? `${payment} ₺ ${localize(type)}` : `${payment} ₺`}
            </Text>
        </div>
        <RadioButton
            isSelected={isSelected}
        />
    </div>;
};
export default PackageCardForPaymentPage;
