import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        maxHeight: "100%",
        overflowY: "auto",
        display: "flex",
        height: "100%",
        width: "50%",
        "@media (max-width: 1040px)": {
            width: "100%"
        },
        "@media (max-height: 600px)": {
            width: "100%"
        },
    },
    loginContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        maxWidth: 400,
        width: "100%"
    },
    inputContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        width: "100%"
    },
    buttonContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    userAgreementContainer: {
        flexDirection: "column",
        display: "flex"
    },
    userAgreementContent: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    selectboxEmpty: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 200
    },
    areaCodesContainer: {
        justifyContent:"space-between",
        flexDirection:"row",
        display: "flex"
    },
    phoneInputContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    occupationSelectbox:{
        display: "block",
        width: "100%"
    },
    alreadyHaveAnAccount: {
        display: "inline",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    passwordRules:{
        flexDirection: "row",
        display: "flex"
    },
    agreementText: {
        display: "inline",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }
});

export default useStyles;