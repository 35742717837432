import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxShadow: "0px 4px 4px 0px #00000040",
        flexDirection: "column",
        borderStyle: "solid",
        minWidth: 300,
        display: "flex",
        maxWidth: "23%",
        flexGrow: 1,
        "@media screen and (max-width: 735px)": {
            width: "fit-content",
            minWidth: "250px"
        }
    },
    informationTextStyle: {
        flexGrow: 1
    },
    renderInformationsStyle: {
        alignItems: "center",
        flexDirection: "row",
        display:"flex",
        flexGrow: 1
    },
    renderExtraInformationsStyle: {
        flexDirection: "column",
        borderBottomStyle: "solid",
        display:"flex",
        flexGrow: 1
    },
    imageStyle: {
        borderStyle: "solid", 
        objectFit: "cover",
        height: 56,
        width: 56
    },
    renderTagsStyle: {
        width: "100%",
        height: 37
    },
    buttonContainer: {
        marginRight: "auto",
        marginLeft: "auto",
        width: "40%"
    },
    descriptionContainer: {
        overflow: "hidden",
        maxHeight: 50
    },
    
});

