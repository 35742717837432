import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    getHeaders
} from "../../utils";
import {
    RefreshTokenResponseType,
    RefreshTokenRequestType
} from "./type";
import {
    ChatService 
} from "../../../chat";

const refreshToken = async (params: RefreshTokenRequestType): Promise<RefreshTokenResponseType> => {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return await axios(`${SERVER_ADDRESS}/authentication/refreshToken`, {
        method: "POST",
        headers: getHeaders(),
        data: JSON.stringify({
            token: refreshToken
        })
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                window.localStorage.setItem("accessToken", res.data.accessToken);
                window.localStorage.setItem("refreshToken", res.data.refreshToken);
                ChatService.getAccessToken();
                return res.data;
            }
            throw res;
        })
        .catch((err) => {
            throw err;
        });
};
export default refreshToken;
