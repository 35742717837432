import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
    },
    contentContianer: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width:"100%"
    },
    leftContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    filterContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 800px)": {
            gap: "0 !important"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        width: "100%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    searchContianer: {
        borderBottomStyle: "solid",
        borderTopStyle: "solid",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        width: "100%",
        "@media (max-width: 800px)": {
            display: "none"
        }
    },
    searchContianerResponsive: {
        borderBottomStyle: "solid",
        borderTopStyle: "solid",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        "@media (min-width: 800px)": {
            display: "none"
        }
    },
    noResultContainer: {
        backgroundColor: "white",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "20rem",
        width: "100%",
        "@media (max-width: 800px)": {
            height: "20rem"
        }
    },
    noResultContent: {
        flexDirection: "column",
        display: "flex"
    },
    indicatorContainer: {
        alignContent: "space-between",
        flexDirection: "column",
        display: "flex"
    },
    indicatorTitleContent: {
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    indicatorInfoContent: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems:"center",
        display: "flex",
        width: "100%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    choosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (max-width: 800px)": {
            display: "none"
        }
    },
    choosenFiltersTitle: {
        alignContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    innerContent:{
        width: "100%"
    },
    choosenFiltersChip: {
        flexWrap: "wrap",
        display: "flex"
    },
    searchResultsContainer: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    paginationContainer: {
        borderStyle: "solid",
        alignSelf: "center",
        display: "flex",
        "@media (max-width: 800px)": {
            display: "none"
        }
    },
    responsivePaginationContainer: {
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        display: "flex",
        width: "100%",
        "@media (min-width: 800px)": {
            display: "none"
        }
    },
    responsivePaginationContent: {
        flexDirection: "row",
        display: "flex"
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex",
        width: "100%",
    },
    sortContainer: {
        flexDirection: "column",
        borderStyle: "solid",
        position: "absolute",
        display: "flex",
        width:"100%",
        top: 45
    },
    popularCandidatesChip: {
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex"
    },
    mostSearchedContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    searchResultContent:{
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    alarmInfoContainer:{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%",
        "@media (max-width: 800px)": {
            justifyContent: "start",
            flexDirection: "column",
            alignItems: "start",
            gap: 20
        }
    },
    textField:{
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    headerContainer: {
        flexDirection: "column",
        display:"flex",
        width: "100%"
    },
    filterMainContainer: {
        minWidth: "25%",
        maxWidth: "35",
        "@media (max-width: 800px)": {
            minWidth: 0,
            maxWidth:  0,
        }
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    dialogBottom : {
        justifyContent: "space-evenly",
        flexDirection: "row",
        display: "flex"
    },
    searchContainerInner: {
        boxSizing: "border-box",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        width: "100%"
    },
    searchTextInput: {
        minWidth: 450
    },
    searchTextButton: {
        minWidth: 85
    },
    responsiveSearchTextInput: {
        minWidth: 500,
        "@media (max-width: 800px)": {
            minWidth: 300,
        }
    },
});
