import React, {
    FC
} from "react";
import useStyles from "./styles";
import {
    IOCoreTheme,
    Button,
    IOCoreLocale
} from "isinolacak-web-cl";
import {
    useNavigate
} from "react-router-dom";
import IDropDownMenuProps from "./type";
import {
    ArrowDownIcon 
} from "../../assets/svgr";
import {
    NavbarContext 
} from "../../context";

const DropDownMenu: FC<IDropDownMenuProps> = ({
    isHrefAdd = true,
    menuStyle,
    isActive,
    onClick,
    style,
    title,
    data
}) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors
    } = IOCoreTheme.useContext();

    const {
        activePath,
        activeHref
    } = NavbarContext.NavigationContext.useContext();

    const isActivePath = (path: string, href?: string) => {
        if(path !== activePath){
            return false;
        }

        if(href && activeHref !== href){
            return false;
        }

        return true;
    };

    const renderButton = () => {
        return <Button
            textColor={"textDark"}
            textVariant='header5-medium'
            spreadBehaviour='stretch'
            iconDirection='right'
            onClick={onClick}
            variant='ghost'
            title={title}
            icon={() => <div
                className={classes.dropdownButtonIcon}
                style={{
                    rotate: isActive ? "180deg": "0deg"
                }}
            >
                <ArrowDownIcon
                    color={colors.textDark}
                    size={12}
                />
            </div>}
        />;
    };

    const renderMenuItems = () => {
        if(!isActive) {
            return null;
        }

        return data.map((item, index) => {
            return <Button
                textColor={isActivePath(item.path, item.href) ? "primary" : "textDark"}
                title={localize(item.titleKey)}
                key={`menu-item${index}`}
                textVariant='body-medium'
                spreadBehaviour='stretch'
                variant='ghost'
                onClick={() => {
                    if(item.onClick){
                        item.onClick();
                    } else if(item.path) {
                        if(item.href){
                            NavbarContext.emitWithKey(item.path, item.href);
                            if(isHrefAdd) {
                                navigate(item.path+"?href="+item.href);
                            } else {
                                navigate(item.path); 
                            }
                        } else {
                            navigate(item.path);
                        }
                    }
                }}
            />;
        });
    };

    return <div
        className={classes.container}
        style={{
            borderBottomColor: colors.stroke,
            borderWidth:borders.line,
            borderBottomStyle: isActive ? "solid" :"unset",
            ...style
        }}
    >
        {renderButton()}
        <div
            style={{
                ...menuStyle,
                height: isActive ? "auto" : 0,
            }}
        >
            {renderMenuItems()}
        </div>
    </div>;
};
export default DropDownMenu;
