import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    CustomTooltipProps 
} from "./types";
import {
    translateMonth 
} from "../../../../../../utils";

const CustomTooltip = ({
    dateFilter,
    payload, 
    active, 
    label
}: CustomTooltipProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const getLabel = () => {
        if (dateFilter === "weekly") {
            return `${label}. ${localize("week")}`;
        } else if (dateFilter === "monthly") {
            return translateMonth(Number(label));
        } else {
            return label;
        }
    };

    if (active && payload && payload.length) {
        return <div
            className={classes.container} 
            style={{
                backgroundColor: colors.primary,
                borderRadius: radiuses.half,
                padding: spaces.container
            }}>
            <Text 
                variant="body-regular"
                color="white"
            >
                {getLabel()}
            </Text>
            <Text 
                variant="body-regular"
                color="white"
            >
                {`${localize("view-counts")}: ${payload[0].value}`}
            </Text>
        </div>;
    }
    
    return null;
};

export default CustomTooltip;