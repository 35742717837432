import useStyles from "./styles";
import {
    IOCoreTranslationType,
    IOCoreColorsType,
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    CandidateCardPropsTypes
} from "./types";
import {
    DocumentFullIcon,
    CheckListIcon,
    CloseIcon,
    InfoIcon
} from "../../../../../assets/svgr";
import moment from "moment";

const CandidateCard = ({
    profilePhoto,
    whenAplied,
    occupation,
    matchPoint,
    location,
    status,
    onClick,
    fullName
}: CandidateCardPropsTypes) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const renderCandidateInfo = () => {
        return <div
            className={classes.cardHeader}
            style={{
                marginBottom: spaces.content / 2
            }}
        >
            <div
                style={{
                    position: "relative"
                }}
            >
                <img
                    src={profilePhoto && profilePhoto.length ? profilePhoto : require("../../../../../assets/img/isinolacakMini.png")}
                    alt={`${profilePhoto} Logo`}
                    className={classes.logo}
                    style={{
                        borderRadius: spaces.contentXLarge * 2,
                        borderColor: colors.primary,
                        borderWidth: borders.line,
                        padding: spaces.inline
                    }}
                />
                <div
                    className={classes.scoreContainer}
                    style={{
                        padding:  `0px ${spaces.inline}px`,
                        backgroundColor: colors.white,
                        bottom:  -1 * spaces.inline,
                        borderRadius: radiuses.hard,
                        borderColor: colors.stroke,
                        borderWidth: borders.line
                    }}
                >
                    <Text
                        variant="header4-medium"
                        color="primary"
                    >
                        {matchPoint}
                    </Text>
                </div>
            </div>
            <div
                style={{
                    marginLeft: spaces.content
                }}
            >
                <div>
                    <Text
                        variant="body-medium"
                        color="textDark"
                    >
                        {fullName}
                    </Text>
                </div>
                <div>
                    <Text
                        variant="body4-regular"
                        color="textDark"
                    >
                        {occupation}
                    </Text>
                </div>
                <div>
                    <Text
                        variant="body4-regular"
                        color="textSecondary"
                    >
                        {location}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderPastInfo = () => {
        let _status:  {
            statusText: keyof IOCoreTranslationType,
            textColor: keyof IOCoreColorsType
        } = {
            statusText: "aplied-status",
            textColor: "primary"
        };

        if(status === "applied") {
            _status.statusText = "aplied-status", /* TODO: Ask this */
            _status.textColor = "primary";
        }

        if(status === "documentSent") {
            _status.statusText = "doc-list-sended";
            _status.textColor = "success";
        }

        if(status === "documentWaiting") {
            _status.statusText = "doc-list-waiting";
            _status.textColor = "warning";
        }

        if(status === "reuploadDocumentWaiting") {
            _status.statusText = "reupload-doc-waiting";
            _status.textColor = "warning";
        }

        if(status === "finished") {
            _status.statusText = "interview-completed";
            _status.textColor = "primary";
        }

        if(status === "performanceTestSubmitted") {
            _status.statusText =  "pt-sended";
            _status.textColor = "success";
        }

        if(status === "performanceTestWaiting") {
            _status.statusText = "pt-result-waiting";
            _status.textColor = "warning";
        }

        if(status === "rejected") {
            _status.statusText = "candidate-rejected";
            _status.textColor = "error";
        }

        return <div>
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color={_status.textColor}
                >
                    {localize(_status.statusText)}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-medium"
                    color="textSecondary"
                >
                    {moment(whenAplied).fromNow()}
                </Text>
            </div>
        </div>;
    };

    const renderIcon = () => {
        if(status === "rejected") {
            return  <div
                className={classes.rejected}
                style={{
                    borderRadius: radiuses.hard * 2,
                    backgroundColor: colors.error,
                    padding: spaces.inline,
                }}
            >
                <CloseIcon  /* // TODO: Ask this icon */ 
                    color={colors.white}
                    size={22}
                />
            </div>;
        }

        if(status === "documentWaiting") {
            return  <div>
                <DocumentFullIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "documentSent") {
            return  <div>
                <DocumentFullIcon
                    color={colors.success}
                    size={30}
                />
            </div>;
        }

        if(status === "reuploadDocumentWaiting") {
            return  <div>
                <DocumentFullIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "performanceTestWaiting") {
            return  <div>
                <CheckListIcon
                    color={colors.warning}
                    size={30}
                />
            </div>;
        }

        if(status === "performanceTestSubmitted") {
            return  <div>
                <CheckListIcon
                    color={colors.success}
                    size={30}
                />
            </div>;
        }

        if(status === "applied") {
            return  <div>
                <InfoIcon  /* // TODO: Ask this icon */ 
                    color={colors.primary}
                    size={30}
                />
            </div>;
        }

        if(status === "finished") {
            return  <div>
                <InfoIcon  /* // TODO: Ask this icon */ 
                    color={colors.primary}
                    size={30}
                />
            </div>;
        }
    };

    return <div
        className={classes.card}
        onClick={onClick}
        style={{
            borderBottomRightRadius: radiuses.hard,
            borderBottomLeftRadius: radiuses.hard,
            backgroundColor: colors.white,
            borderTopColor: colors.stroke,
            borderWidth: borders.line,
            padding: spaces.content
        }}
    >
        <div
            className={classes.candidateInfoContainer}
            style={{
                marginBottom: spaces.content
            }}
        >
            {renderCandidateInfo()}
            {renderIcon()}
        </div>
        {renderPastInfo()}
    </div>;
};

export default CandidateCard;
