export const getHeaders = (isAuth?: boolean, params?: any) => {
    let additionalParams: {
        Authorization?: string;
    } = {
    };

    if(isAuth) {
        const accessToken = window.localStorage.getItem("accessToken");
        // TODO: THİS CODE CONTROLL.
        additionalParams.Authorization = accessToken && accessToken.length ? accessToken : "";
    }

    return {
        "Content-Type": "application/json",
        "Accept": "application/json",
        ...additionalParams,
        ...params
    };
};
