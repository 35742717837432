import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledArrowDownIcon = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15.74 22.67 28.6 9.41c.803-.828.316-2.41-.74-2.41H2.14C1.085 7 .598 8.582 1.4 9.41l12.86 13.26c.425.44 1.055.44 1.48 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledArrowDownIcon;
