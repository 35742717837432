import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderStyle: "solid",
        width: "100%"
    },
    headerContainer: {
        display: "flex",
        width: "100%",
        borderStyle: "solid",
        boxSizing: "border-box",
        justifyContent: "space-between",
        alignItems: "center"
    },
    lineHr: {
        width: "90%",
        height: 1
    },
    lineVr: {
        width: 1,
        height: 40,
        "@media screen and (max-width: 1430px)": {
            display: "none"
        },
        "@media screen and (max-width: 1040px)": {
            display: "block"
        },
        "@media screen and (max-width: 550px)": {
            display: "none"
        }
    },
    ibanContainer: {
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        justifyContent: "space-between",
        alignItems: "center",
        "@media screen and (max-width: 1430px)": {
            flexDirection: "column"
        },
        "@media screen and (max-width: 1040px)": {
            flexDirection: "row"
        },
        "@media screen and (max-width: 550px)": {
            flexDirection: "column"
        }
    },
    copy: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    ibanRow: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    bankNameContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    }
});
export default useStyles;
