import { 
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    TextArea,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    LetterOpenIcon,
    InstagramIcon,
    FacebookIcon,
    XIcon
} from "../../../assets/svgr";
import {
    Footer 
} from "../../../components";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    ContactUsRequestType 
} from "../../../services/restAPI/actions/contactUs/types";
import {
    ISIN_OLACAK_INSTAGRAM, 
    ISIN_OLACAK_FACEBOOK,
    ISIN_OLACAK_TWITTER
} from "../../../constants";
import ContactSvg from "../../../assets/img/contact.svg";
import validator from "validator";

const Contact = () => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [errors, setErrors] = useState<string[]>([]);
    const [contactUs, setContactUs] = useState({
        firstName: "",
        lastName: "",
        message: "",
        mail: ""
    });
    const [successDialogMessage, setSuccessDialogMessage] = useState<boolean>(false);

    const handleContactUs = () => {
        const params: ContactUsRequestType = {
            ...contactUs 
        };
        
        if (contactUs.firstName.length > 55 || contactUs.firstName.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-name-length-error");

            setErrors(_errors);
            return;
        }

        if (contactUs.lastName.length  > 55 || contactUs.lastName.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-last-name-length-error");

            setErrors(_errors);
            return;
        }

        if(!validator.isEmail(contactUs.mail) || contactUs.mail.length > 99) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-email-error");

            setErrors(_errors);
            return;
        }

        if (contactUs.message.length > 800 || contactUs.message.length < 4) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("message-length-error");
            setErrors(_errors);
            return;
        }

        RESTService.action("ContactUs", params)
            .then(() => {
                setSuccessDialogMessage(true);
            })
            .catch((err) => {
                console.error("ContactUs Err:", err);
            });
       
    };

    const renderLeftContainer = () => {
        return <div 
            className={classes.renderLeftContainer} 
            style={{
                borderRightWidth: borders.indicator,
                borderRightColor: colors.stroke,
                padding: spaces.containerXLarge,
                gap: spaces.containerLarge
            }}>
            <div className={classes.imageContainer}>
                <img 
                    alt="Mailbox image" 
                    src={ContactSvg}
                    style={{
                        marginBottom: spaces.containerLarge
                    }} 
                />
            </div>
            <div className={classes.leftContainerTextWrapper}>
                <Text 
                    variant="header5-medium"
                    color="primary"
                    style={{
                        marginBottom: spaces.inline / 2
                    }}
                >
                    {localize("our-phone-number")}
                </Text>
                <a
                    href="+90 216 606 54 45"
                >
                    +90 216 606 54 45
                </a>
            </div>
            <div className={classes.leftContainerTextWrapper}>
                <Text 
                    variant="header5-medium"
                    color="primary"
                    style={{
                        marginBottom: spaces.inline / 2
                    }}
                >
                    {localize("our-email-address")}
                </Text>
                <Text 
                    variant="body-regular"
                >
                    info@isinolacak.com
                </Text>
            </div>
            <div className={classes.leftContainerTextWrapper}>
                <Text 
                    variant="header5-medium"
                    color="primary"
                    style={{
                        marginBottom: spaces.inline / 2
                    }}
                >
                    {localize("our-address")}
                </Text>
                <Text 
                    variant="body-regular"
                >
                    Kozyataği Mah. Sani̇ye Ermutlu <br/>Sk. By Otell No: 3 C<br/>
                    Kadiköy/ İstanbul
                </Text>
            </div>
        </div>;
    };

    const renderRightResponsiveContainer = () => {
        return <div 
            className={classes.renderRightResponsiveContainer} 
            style={{
                paddingBottom: spaces.container * 1.8,
                paddingRight: spaces.containerLarge,
                paddingLeft: spaces.containerLarge,
                paddingTop: spaces.containerXXLarge,
                gap: spaces.containerLarge
            }}
        >
            {renderRightContainerText()}
            {renderForm()}
            {renderSocialIcons()}
        </div>;
    };

    const renderRightContainer = () => {
        return <div 
            className={classes.renderRightContainer} 
            style={{
                paddingBottom: spaces.container * 1.8,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                paddingTop: spaces.containerXXLarge,
                gap: spaces.containerLarge
            }}
        >
            {renderRightContainerText()}
            {renderForm()}
            {renderSocialIcons()}
        </div>;
    };

    const renderRightContainerText = () => {
        return <div>
            <Text
                className={classes.rightContainerTitle} 
                variant="header3-medium"
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            >
                {localize("contact-us-now")}
            </Text>
            <Text 
                variant="body-regular"
                color="greyBase"
            >
                {localize("contact-us-by-form")}
            </Text>
        </div>;
    };

    const renderForm = () => {
        return <div
            className={classes.formContainer}
            style={{
                gap: spaces.container
            }}
        >
            <TextInput
                infoText={errors.includes("register-name-length-error") ? localize("register-name-length-error") : undefined}
                isError={errors.includes("register-name-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors(_errors);
                }}
                placeholder={localize("name")}
                spreadBehaviour="stretch"
                onChangeText={(e) => setContactUs({
                    ...contactUs,
                    firstName: e
                })}
            />
            <TextInput
                infoText={errors.includes("register-last-name-length-error") ?  localize("register-last-name-length-error") : undefined}
                isError={errors.includes("register-last-name-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-last-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors(_errors);
                }}
                placeholder={localize("surname")}
                spreadBehaviour="stretch"
                onChangeText={(e) => setContactUs({
                    ...contactUs,
                    lastName: e
                })}
            />
            <TextInput
                infoText={errors.includes("register-email-error") ?  localize("register-email-error") : undefined}
                isError={errors.includes("register-email-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "mail-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors(_errors);
                }}
                placeholder={localize("e-mail")}
                spreadBehaviour="stretch"
                onChangeText={(e) => setContactUs({
                    ...contactUs,
                    mail: e
                })}
            />
            <Text
                className={classes.alignRight}
                variant="body-regular"
                color="greyBase"
            >
                {localize("your-message")}
            </Text>
            <TextArea
                isError={errors.includes("message-length-error")}
                placeholder={localize("enter-your-information.")}
                spreadBehaviour="stretch"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "message-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors(_errors);
                }}
                onChangeText={(e) => setContactUs({
                    ...contactUs,
                    message: e
                })}
            />
            <Button
                onClick={() => handleContactUs()}
                spreadBehaviour="stretch"
                title={localize("send")}
                variant="filled"
                color="primary"
                size="large"
                style={{
                    marginTop: spaces.content
                }}
            />
        </div>;
    };

    const renderSocialIcons = () => {
        return <div
            className={classes.iconContainer}
            style={{
                marginTop: spaces.containerXLarge,
                gap: spaces.content / 1.5
            }}
        >
            <div className={classes.icon}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.half / 2
                }}
                onClick={() => {
                    window.open(ISIN_OLACAK_INSTAGRAM);
                }}
            >
                <InstagramIcon 
                    color={colors.white}
                    size={18} 
                />
            </div>
            <div
                className={classes.icon}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.half / 2
    
                }}
                onClick={() => 
                    window.open(ISIN_OLACAK_FACEBOOK)
                }
            >
                <FacebookIcon
                    color={colors.white}
                    size={18} 
                />
            </div>
            <div
                className={classes.icon}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.half / 2
                }}
                onClick={() => 
                    window.open(ISIN_OLACAK_TWITTER)
                }
            >
                <XIcon 
                    color={colors.white}
                    size={18} 
                />
            </div>
        </div>;
    };

    const renderDialog = () => {
        return <Dialog
            isVisible={errors.length === 0 && successDialogMessage}
            variant="ok"
            headerComponent={
                <div
                    className={classes.dialogHeaderContainer} 
                    style={{
                        gap: spaces.inline / 2
                    }}
                >
                    <LetterOpenIcon 
                        color={colors.primary}
                        size={50} 
                    />
                    <Text
                        variant="header5-medium"
                        style={{
                            marginTop: spaces.content
                        }}
                    >
                        {localize("your-message-has-been-sent")}
                    </Text>
                    <Text 
                        variant="body-regular"
                        color="greyBase"
                    >
                        {localize("congratulations-your-message-has-been-successfully-sent")}
                    </Text>
                </div>
            }
            bottomComponent={
                <Button 
                    title={localize("iocore-select-sheet-ok-button")}
                    spreadBehaviour="stretch"
                    onClick={() => {
                        setSuccessDialogMessage(false);
                        location.reload();
                    }}
                />
            }
        />;
    };

    return <div 
        className={classes.container}
        style={{
            backgroundColor: colors.grey25
        }}
    >
        <div 
            className={classes.mainContent} 
        >
            {renderLeftContainer()}
            {renderRightContainer()}
            {renderRightResponsiveContainer()}
        </div>
        <Footer/>
        {renderDialog()}
    </div>;
};

export default Contact;