export const colors: Required<IOCore.ColorsType> = {
    "primary":                                    "#45BBD4",
    "secondary":                                  "#0B2636",
    "body":                                       "#2A2A2A",
    "constrastBody":                              "#F5F5F5",
    "yellowButton":                               "#F8AA1C",
    "layer1":                                     "#FFFFFF",
    "layer2":                                     "#ffffff",
    "layer3":                                     "#FAFAFA",
    "panel":                                      "#FFFFFF",
    "warning":                                    "#F8AA1C",
    "warning50":                                  "#FEF7E8",
    "error":                                      "#DC2626",
    "error50":                                    "#FEF2F2",
    "danger":                                     "#EB7E47",
    "success":                                    "#16A34A",
    "success50":                                  "#F0FDF4",
    "accent":                                     "#EB4747",
    "attention":                                  "#EB477E",
    "info":                                       "#4799EB",
    "stroke":                                     "#E2E2E2",
    "fillsSecondary":                             "#78788052",
    "seperator":                                  "#cccccc",
    "hideBody":                                   "hsl(0, 0%, 60%)",
    "black":                                      "#000000",
    "white":                                      "#FFFFFF",
    "backgroundDark":                             "#0A0A0C",
    "backgroundLight":                            "#FFFFFF",
    "textDark":                                   "#191919",
    "textSecondary":                              "#9AA6B8",
    "textGrey":                                   "#8C8C8C",
    "textWhite":                                  "#FFFFFF",
    "black50":                                    "#F6F6F6",
    "black100":                                   "#E2E2E2",
    "black200":                                   "#D4D4D4",
    "grey25":                                     "#FAFAFA",
    "grey50":                                     "#F0F1F3",
    "grey100":                                    "#D1D4DA",
    "grey200":                                    "#BABFC8",
    "greyBase":                                   "#697488",
    "grey700":                                    "#4B5261",
    "appleBg":                                    "#000000",
    "linkedinBg":                                 "#2867B2",
    "gray0":                                      "hsl(0, 0%, 0%)",
    "gray10":                                     "hsl(0, 0%, 10%)",
    "gray20":                                     "hsl(0, 0%, 20%)",
    "gray30":                                     "hsl(0, 0%, 30%)",
    "gray40":                                     "hsl(0, 0%, 40%)",
    "gray50":                                     "hsl(0, 0%, 50%)",
    "gray60":                                     "hsl(0, 0%, 60%)",
    "gray70":                                     "hsl(0, 0%, 70%)",
    "gray80":                                     "hsl(0, 0%, 80%)",
    "gray90":                                     "hsl(0, 0%, 90%)",
    "gray92":                                     "hsl(0, 0%, 92%)",
    "gray94":                                     "hsl(0, 0%, 94%)",
    "gray96":                                     "hsl(0, 0%, 96%)",
    "gray98":                                     "hsl(0, 0%, 98%)",
    "gray100":                                    "hsl(0, 0%, 100%)",
    "modalBackground":                            "rgba(0, 0, 0, 0.5)",
    "featuredAdFrontColor":                       "#F8AA1C",
    "featuredAdBackColor":                        "#FFF8EF",
    "newAdFrontColor":                            "#16A34A",
    "newAdBackColor":                             "#F0FDF4",
    "announcementFrontColor":                     "#2563EB",
    "announcementBackColor":                      "#EFF6FF",
    "trustedFrontColor":                          "#0284C7",
    "trustedBackColor":                           "#EFF6FF",
    "lightBlue":                                  "#EBFAFD",
    "messageTicColor":                            "#89E5F8",
    "applicationNumberStickerFrontColor":         "#2563EB",
    "applicationNumberStickerBackColor":          "#EFF6FF",
    "activeAdvertStickerFrontColor":              "#16A34A",
    "activeAdvertStickerBackColor":               "#EFFFEF",
    "hiddenAdvertStickerFrontColor":              "#DC2626",
    "hiddenAdvertStickerBackColor":               "#FFF4EF",
    "advertDateStickerFrontColor":                "#2563EB",
    "advertDateStickerBackColor":                 "#EFF6FF",
    "negativeAdvertDateStickerFrontColor":        "#DC2626",
    "negativeAdvertDateStickerBackColor":         "#FFF4EF",
    "positiveApplicationStatusFrontColor":        "#16A34A",
    "positiveApplicationStatusBackColor":         "#EFFFEF",
    "neutralApplicationStatusFrontColor":         "#2563EB",
    "neutralApplicationStatusBackColor":          "#EFF6FF",
    "waitingApplicationStatusFrontColor":         "#F8AA1C",
    "waitingApplicationStatusBackColor":          "#FFFBEF",
    "negativeApplicationStatusFrontColor":        "#DC2626",
    "negativeApplicationStatusBackColor":         "#FFF4EF",
    "iceblue":                                    "#F5FDFF"
};

export const typography: Required<IOCore.TypographyType> = {
    "header1-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 48
    },
    "header1-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 48
    },
    "header1-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 48
    },
    "header1-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 48
    },
    "header2-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 39
    },
    "header2-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 39
    },
    "header2-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 39
    },
    "header2-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 39
    },
    "header3-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 31
    },
    "header3-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 31
    },
    "header3-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 31
    },
    "header3-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 31
    },
    "header4-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 25
    },
    "header4-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 25
    },
    "header4-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 25
    },
    "header4-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 25
    },
    "header5-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 20
    },
    "header5-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 20
    },
    "header5-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 20
    },
    "header5-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 20
    },
    "body-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 16
    },
    "body-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 16
    },
    "body-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 16
    },
    "body-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 16
    },
    "body2-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 14
    },
    "body2-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 14
    },
    "body2-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 14
    },
    "body2-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 14
    },
    "body3-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 12
    },
    "body3-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 12
    },
    "body3-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 12
    },
    "body3-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 12
    },
    "body4-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 10
    },
    "body4-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 10
    },
    "body4-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 10
    },
    "body4-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 10
    },
    "caption-regular": {
        fontFamily: "Inter-Regular",
        fontWeight: "400",
        fontSize: 8
    },
    "caption-medium": {
        fontFamily: "Inter-Medium",
        fontWeight: "500",
        fontSize: 8
    },
    "caption-semiBold": {
        fontFamily: "Inter-SemiBold",
        fontWeight: "600",
        fontSize: 8
    },
    "caption-bold": {
        fontFamily: "Inter-Bold",
        fontWeight: "700",
        fontSize: 8
    }
};

export const designTokens: Required<IOCore.DesignTokensType> = {
    spaces: {
        containerXXLarge: 80, 
        containerXLarge: 40,
        containerLarge: 28,
        contentXLarge: 28,
        contentLarge: 20,
        container: 16,
        content: 12,
        inline: 8,
        item: 16
    },
    borders: {
        indicator: 2,
        line: 1
    },
    radiuses: {
        quarter: 4,
        hard: 20,
        half: 10
    },
    disabled: {
        opacity: 0.33
    }
};

const theme: Required<IOCore.ThemeType> = {
    key: "light",
    designTokens,
    typography,
    colors,
};
export default theme;
