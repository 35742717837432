import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const BookMarkFull = ({
    color = "#45BBD4",
    size = 12,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 12 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M10.5 5.549v2.496c0 1.549 0 2.323-.367 2.661a1.12 1.12 0 0 1-.631.29c-.494.056-1.07-.454-2.223-1.473-.51-.45-.764-.676-1.06-.735a1.113 1.113 0 0 0-.439 0c-.295.059-.55.284-1.059.735-1.153 1.02-1.729 1.53-2.223 1.473a1.12 1.12 0 0 1-.631-.29C1.5 10.368 1.5 9.594 1.5 8.046V5.548c0-2.145 0-3.217.659-3.883C2.818 1 3.879 1 6 1c2.121 0 3.182 0 3.841.666.659.666.659 1.738.659 3.883ZM4.125 3c0-.207.168-.375.375-.375h3a.375.375 0 1 1 0 .75h-3A.375.375 0 0 1 4.125 3Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default BookMarkFull;
