import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        display: "flex",
        width: "100%",
        "@media (max-width: 600px)": {
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            flexDirection: "column",
            display:"flex",
            width: "100%"
        },
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 600px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        position: "sticky",
        minWidth: "320px",
        maxWidth: "15%",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 600px)": {
            position: "relative",
            display: "none",
            minWidth: "100%",
            maxWidth: "100%",
        }
    },
    leftMobileContent: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (min-width: 600px)": {
            flexDirection: "column",
            display: "none",
            width: "100%"
        }
    },
    leftMobileContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        maxWidth: "100%",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (min-width: 600px)": {
            flexDirection: "column",
            maxWidth: "100%",
            display: "none",
            width: "100%"
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 600px)": {
            flexDirection: "column",
            display: "none",
            width: "100%",
        }
    },
    contentMobileContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (min-width: 600px)": {
            flexDirection: "column",
            display: "none",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        maxWidth: "12%",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%",
        height: "1px"
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    soughtAfterQualificationContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex"
    },
    soughtAfterQualificationContent: {
        flexDirection: "column",
        display: "flex"
    },
    soughtAfterQualitificationCardContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        flexDirection: "row",
    },
    soughtAfterQualitificationCardContent: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    soughtAfterQualitificationCardCItem: {
        flexDirection: "column",
        display: "flex"
    },
    advertDetailsContent: {
        flexDirection: "column",
        display: "flex"
    },
    advertDetailsContainer: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    buttonContent: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex",
    },
    advertDetailAboutContent: {
        flexDirection: "column",
        display: "flex"
    },
    companyAbout: {
        alignContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    contentContainerStyle:{
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    companyAboutImage: {
        height: 32,
        width: 32
    },
    filterContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        height: "100%",
        width: "100%",
        "@media (max-width: 601px)": {
            display: "none"
        }
    },
    languageContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    choosenFiltersChip: {
        display: "flex",
        flexWrap: "wrap"
    },
    choosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    responsiveChoosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (max-width: 601px)": {
            display: "none"
        }
    },
    responsivePaginationContainer: {
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        display: "flex",
        "@media (min-width: 600px)": {
            display: "none"
        }
    },
    responsivePaginationContent: {
        flexDirection: "row",
        display: "flex"
    },
    paginationContainer: {
        borderStyle: "solid",
        overflow: "hidden",
        display: "flex"
    },
    headerLeftMobileContainerBorderStyle: {
        borderStyle: "solid"
    },
    headerContentMobileContainerBorderStyle: {
        borderStyle: "solid"
    },
    noApplicant: {
        alignItems: "center",
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        maxWidth: "30%",
        minWidth: "25%",
        height: "100%",
        "@media (max-width: 600px)": {
            display: "none"
        }
    },
    noApplicantHeader: {
        flexDirection: "column",
        alignSelf: "flex-start",
        display:"flex"
    },
    noApplicantcontentContainer: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        minHeight: 600,
        width: "100%",
        height: "100%",
        "@media (max-width: 600px)": {
            display: "none"
        }
    },
    noApplicanting: {
        objectFit: "cover",
        width: 300,
        height: 300
    },
    responsiveNoApplicant: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        maxWidth: "100%",
        minWidth: "100%",
        height: "100%",
        minHeight: 500,
        "@media (min-width: 600px)": {
            display: "none"
        }
    },
});
export default useStyles;