import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const MyActionsMenu = ({
    color = "#000",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M1 7.2A1.2 1.2 0 0 1 2.2 6h25.6a1.2 1.2 0 0 1 0 2.4H2.2A1.2 1.2 0 0 1 1 7.2Zm0 8A1.2 1.2 0 0 1 2.2 14h17.6a1.2 1.2 0 0 1 0 2.4H2.2A1.2 1.2 0 0 1 1 15.2Zm0 8A1.2 1.2 0 0 1 2.2 22h8a1.2 1.2 0 0 1 0 2.4h-8A1.2 1.2 0 0 1 1 23.2Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default MyActionsMenu;
