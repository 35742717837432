import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Company = ({
    color = "#45BBD4",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M21.376 5.123c.345.535.345 1.28.345 2.769V21.41h.512c.423 0 .767.356.767.795 0 .439-.344.795-.767.795H1.767A.782.782 0 0 1 1 22.205c0-.44.344-.795.767-.795h.512V7.892c0-1.49 0-2.234.345-2.769.15-.231.341-.43.565-.585.451-.312 1.057-.352 2.164-.357-.004.31-.004.632-.004.965v1.42H4.326a.782.782 0 0 0-.768.795c0 .44.344.796.768.796h1.023v1.59H4.326a.782.782 0 0 0-.768.795c0 .44.344.795.768.795h1.023v1.59H4.326a.782.782 0 0 0-.768.796c0 .44.344.795.768.795h1.023v6.892h1.535V5.24c0-2 0-2.999.6-3.62C8.082 1 9.046 1 10.976 1h2.046c1.93 0 2.895 0 3.494.621.6.621.6 1.62.6 3.62V21.41h1.534v-6.892h1.023a.782.782 0 0 0 .768-.795.782.782 0 0 0-.768-.795h-1.023v-1.59h1.023a.782.782 0 0 0 .768-.796.782.782 0 0 0-.768-.795h-1.023v-1.59h1.023a.782.782 0 0 0 .768-.796.782.782 0 0 0-.768-.795h-1.023v-1.42c0-.333 0-.656-.004-.965 1.107.005 1.713.045 2.164.357.224.155.416.354.565.585Zm-8.609 13.901A.782.782 0 0 0 12 18.23a.782.782 0 0 0-.767.795v2.386h1.534v-2.386Zm-3.58-4.24c0-.44.343-.796.766-.796h4.094c.423 0 .767.356.767.795 0 .44-.344.795-.768.795H9.954a.782.782 0 0 1-.767-.795Zm.766-3.977a.782.782 0 0 0-.767.795c0 .44.344.796.767.796h4.094a.782.782 0 0 0 .767-.796.782.782 0 0 0-.768-.795H9.954Zm-.511-4.506c0-1.61 1.26-2.915 2.814-2.915S15.07 4.69 15.07 6.3s-1.26 2.916-2.814 2.916S9.442 7.91 9.442 6.3Zm1.535 0c0-.732.572-1.325 1.279-1.325.706 0 1.279.593 1.279 1.325s-.573 1.326-1.28 1.326c-.706 0-1.278-.594-1.278-1.326Z"
            transform={`scale(${1 / pathScale})`}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Company;
