import React from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    LeftContainerType
} from "./types";
import ApplicationCard from "../../applicantCard";

const LeftContainer = ({
    handleGetMyApplication,
    setResponsiveShow,
    getMyApplication,
    setSearchParams
}: LeftContainerType) => {
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();
    
    return <div>
        <div
            style={{
                padding: spaces.container 
            }}>
            <div>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {getMyApplication?.totalApplicationCount} {localize("application")}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.content 
                }}>
                <Text
                    variant="body4-regular"
                    color="textDark"
                >
                    {localize("all-my-application-advertisements")}
                </Text>
            </div>
        </div>
        {getMyApplication.otherApplications.map((item, index) => {
            const phoneURL = item.companyInfo.photoURL ? item.companyInfo.photoURL : require("../../../../../../assets/img/isinolacakMini.png");

            return <ApplicationCard
                jobTitle={item.occupation.localizedText}
                location={item.city.localizedText}
                company={item.companyInfo.title}
                timestamp={item.appliedAt}
                profilePhoto={phoneURL}
                status={item.status}
                key={index}
                onClick={() => {
                    handleGetMyApplication(item._id, undefined, true);
                    setResponsiveShow("content");
                    setSearchParams((e: URLSearchParams) => {
                        e.set("s", item._id);
                        return e;
                    });
                }}
            />;
        })}
    </div>;
};

export default LeftContainer;