import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    previouslyCreatedDocumentsContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    previouslyCreatedDocumentsContent: {
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        display: "flex"
    },
    documentsIcon: {
        borderStyle: "solid"
    },
    previouslyCreatedDocumentsRightIcon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    textIconDiv: {
        alignItems: "center",
        display: "flex"
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    }
});
export default useStyles;