import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const StopTime = ({
    color = "#DC2626",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M27.29 16.855C27.29 23.563 21.851 29 15.144 29 8.437 29 3 23.563 3 16.855 3 10.148 8.437 4.711 15.145 4.711c6.707 0 12.144 5.437 12.144 12.144ZM13.794 20.23v-6.747c0-.629 0-.943-.102-1.191a1.35 1.35 0 0 0-.73-.73c-.249-.103-.563-.103-1.192-.103s-.943 0-1.191.102c-.33.137-.593.4-.73.73-.103.249-.103.563-.103 1.192v6.747c0 .629 0 .943.103 1.191.137.33.4.593.73.73.248.103.562.103 1.191.103s.943 0 1.191-.103c.33-.137.594-.4.73-.73.103-.248.103-.562.103-1.191Zm6.747 0v-6.747c0-.629 0-.943-.103-1.191a1.35 1.35 0 0 0-.73-.73c-.248-.103-.562-.103-1.19-.103-.63 0-.944 0-1.192.102-.33.137-.593.4-.73.73-.103.249-.103.563-.103 1.192v6.747c0 .629 0 .943.103 1.191.137.33.4.593.73.73.248.103.562.103 1.191.103s.943 0 1.191-.103c.33-.137.593-.4.73-.73.103-.248.103-.562.103-1.191Z"
            clipRule="evenodd"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M12.446 1a1.012 1.012 0 1 0 0 2.024h5.397a1.012 1.012 0 0 0 0-2.024h-5.397Z"
        />
    </svg>;
};
export default StopTime;
