import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Ticket = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="m17.811 25.004.008-2.804c0-.773.629-1.4 1.404-1.4.775 0 1.403.627 1.403 1.4v2.767c0 .674 0 1.011.216 1.218.216.207.547.193 1.209.165 2.608-.11 4.21-.463 5.34-1.59 1.132-1.13 1.484-2.733 1.594-5.345.022-.518.033-.777-.064-.95-.097-.173-.483-.389-1.255-.82a2.798 2.798 0 0 1 0-4.89c.772-.431 1.158-.647 1.255-.82.097-.173.086-.432.064-.95-.11-2.612-.462-4.215-1.594-5.345-1.23-1.226-3.018-1.535-6.052-1.614a.696.696 0 0 0-.713.697V8.2c0 .773-.628 1.4-1.403 1.4a1.402 1.402 0 0 1-1.404-1.4l-.01-3.502A.7.7 0 0 0 17.107 4h-4.914C6.9 4 4.253 4 2.609 5.64c-1.132 1.13-1.484 2.733-1.594 5.345-.022.518-.033.777.064.95.097.173.483.389 1.255.82a2.798 2.798 0 0 1 0 4.89c-.772.431-1.158.647-1.255.82-.097.173-.086.432-.064.95.11 2.612.462 4.216 1.594 5.345C4.254 26.4 6.9 26.4 12.193 26.4h4.214c.66 0 .99 0 1.196-.204.205-.205.206-.534.208-1.192Zm2.815-8.404v-2.8c0-.773-.628-1.4-1.403-1.4s-1.404.627-1.404 1.4v2.8c0 .773.629 1.4 1.404 1.4.775 0 1.403-.627 1.403-1.4Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Ticket;