import {
    IOCoreTheme,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    IResultsCardProps,
    StickerDataType,
    StickerTypes
} from "./types";
import emptyProfile from "../../../../../assets/img/isinolacakMiniWithPadding.png";

const STICKER_DATA: StickerDataType = {
    aplicationBeingReviewed: {
        titleKey: "aplicant-card-sticker-aplication-being-reviewed",
        // icon: (props) => <EyeIcon {...props}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
        titleKeyParams: false
    },
    daysAgo: {
        titleKey: "aplicant-card-sticker-days-ago",
        // icon: (props) => <HistoryIcon {...props}/>,
        frontColor: "textGrey",
        titleKeyParams: true,
        backColor: "stroke"
    },
    aplicationDocumentsSubmited: {
        titleKey: "aplicant-card-sticker-aplication-submited",
        // icon: (props) => <ForwardIcon {...props}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
        titleKeyParams: false
    },
    videoInterviewInvite: {
        titleKey: "aplicant-card-sticker-video-interview-invitation",
        // icon: (props) => <ForwardIcon {...props}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
        titleKeyParams: false
    },
    performanceTestSubmited: {
        titleKey: "aplicant-card-sticker-performance-test-submited",
        // icon: (props) => <ForwardIcon {...props}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
        titleKeyParams: false
    }
};

const ResultCard = ({
    foreignLanguage,
    educationLevel,
    actionOnPress,
    capabilities,
    userFullName,
    actionType,
    occupation,
    userImage,
    stickers,
    onClick,
    style,
    city
}: IResultsCardProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {userFullName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {occupation}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {`${city} • ${capabilities}`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
            onClick={()=>{
                onClick();
            }}
        >
            <div
                style={{
                    display: "flex"
                }}
            >
                <img
                    src={userImage && userImage.length ? userImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container
                    }}
                />
                {informationsContainer()}
            </div>
        </div>;
    };

    const renderItem = ({
        item
    }: {
        item: StickerTypes,
        index: number;
    }) => {
        const currentStickerData = STICKER_DATA[item];

        return <Sticker 
            title={currentStickerData.titleKeyParams ? "Deneme" : "Deneme"}
            titleColor={currentStickerData.frontColor}
            color={currentStickerData.backColor}
            icon={currentStickerData.icon}
            spreadBehaviour="free"
            style={{
                marginRight: spaces.content,
            }}
        />;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingTop: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                paddingLeft: spaces.content,
                borderTopStyle: "solid"
            }}
        >
            {
                stickers.map((item: any, index: any) => {
                    return renderItem({
                        item,
                        index
                    });
                })
            }
        </div>;
    };

    const renderActionButton = () => {
        if(!actionOnPress) {
            return null;
        }

        if(!actionType) {
            return null;
        }

        let currentActionTitle;

        if(actionType === "uploadDocuments") {
            currentActionTitle = "Upload Documents";
        }

        if(actionType === "completePerformanceTest") {
            currentActionTitle = "CompletePerformanceTest";
        }

        if(actionType === "joinInterview") {
            currentActionTitle = "Join Interview";
        }

        return <div
            style={{
                padding: spaces.container * 0.75,
                borderTopColor: colors.stroke,
                borderWidth: borders.line,
                borderTopStyle: "solid"
            }}
        >
            <Button
                title={currentActionTitle}
                spreadBehaviour="stretch"
                onClick={actionOnPress}
                variant="filled"
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            borderStyle: "solid"
        }}
        onClick={()=>{
            onClick();
        }}
    >
        {renderInformations()}
    </div>;
};

export default ResultCard;