import {
    Navigate,
    Routes,
    Route
} from "react-router-dom";
import ResetPassword from "../pages/auth/resetPassword";
import Register from "../pages/auth/register";
import SmsOTP from "../pages/auth/smsOTP";
import Login from "../pages/auth/login";

const AuthRoute = () => {
    const userType = window.localStorage.getItem("userType");
    return <Routes>
        <Route
            path="/"
            element={<Login/>}
        />
        <Route
            path="/login"
            element={<Login/>}
        />
        <Route
            path="/register"
            element={<Register/>}
        />
        <Route
            path="/resetPassword"
            element={<ResetPassword/>}
        />
        <Route
            path="/smsOTP"
            element={<SmsOTP/>}
        />
        <Route
            path="/*"
            element={<Navigate to="/notFound" />}
        />
    </Routes>;
};
export default AuthRoute;
