import React, {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Pagination,
    StateCard,
    Loading,
    Button,
    Text,
    Chip
} from "isinolacak-web-cl";
import {
    SearchIcon,
    RadarIcon
} from "../../../assets/svgr";
import {
    SelectedItem
} from "./views/dropDown/types";
import {
    SelectedApplicationFilterTypes,
    ResponsiveShowType,
    ViewsType
} from "./types";
import {
    RESTService
} from "../../../services/restAPI";
import {
    GetMyApplicationResponseTypes,
    GetMyApplicationRequestTypes,
} from "../../../services/restAPI/actions/getMyApplication/types";
import {
    useSearchParams,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    CANDIDATE_HISTORY
} from "./constants.";
import HeaderContentMobileContainer from "./views/responsiveContainers/headerContentMobileContainer";
import HeaderLeftMobileContainer from "./views/responsiveContainers/headerLeftMobileContainer";
import ContentMobileContainer from "./views/responsiveContainers/contentMobileContainer";
import LeftMobileContainer from "./views/responsiveContainers/leftMobileContainer";
import ContentContainer from "./views/responsiveContainers/contentContainer";
import LeftContainer from "./views/responsiveContainers/leftContainer";
import DropDown from "./views/dropDown";
import {
    getErrorText 
} from "../../../utils";

const ApplicationManagement = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        state 
    } = useLocation();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();
    
    const [getMyApplication, setGetMyApplication] = useState<GetMyApplicationResponseTypes>();
    const [selectDialogHistoryVisible, setSelectDialogHistoryVisible] = useState(false);
    const [selectDialogCitiesVisible, setSelectDialogCitiesVisible] = useState(false);
    const [responsiveShow, setResponsiveShow] = useState<ResponsiveShowType>("left");
    const [selectedHistory, setSelectedHistory] = useState<Array<SelectedItem>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedItem>>([]);
    const [historySelectVisible, setHistorySelectVisible] = useState(false);
    const [citiesSelectVisible, setCitiesSelectVisible] = useState(false);
    const [applicationLoading, setApplicationLoading] = useState(true);
    const [views, setViews] = useState<ViewsType>("default");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [ID, setID] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();

    const historyDropdownRef = useRef<HTMLDivElement>(null);
    const citiesDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (state) {
            setID(state.applicationID);
            handleGetMyApplication(state.applicationID);
            setSearchParams(e => {
                e.set("s",state.applicationID);
                return e;
            }, {
                replace: true
            });
        } else if (searchParams.get("s")) {
            let _id = searchParams.get("s");

            if(_id?.length) {
                setID(_id);
                handleGetMyApplication(_id);
            }
        } else {
            navigate("/notFound");
        }
    }, []);
    
    useEffect(() => {
        let _params: GetMyApplicationRequestTypes = {
            applicationID: ID,
            page: page
        };

        if(selectedCities.length) {
            _params.cityID = selectedCities.map((e) => e.__key);
        }

        if(selectedHistory.length) {
            _params.applicationDate = selectedHistory.map((e) => e.__key.toString()).join();
        }


    }, [ID, selectedCities, selectedHistory, page]);

    const handleGetMyApplication = (id: string, params?: GetMyApplicationRequestTypes, isOtherApplication?: boolean) => {
        let __params: GetMyApplicationRequestTypes = {
            applicationID: id
        };

        if(params) {
            __params = params;
        } else {
            if(isOtherApplication) {
                setApplicationLoading(true);
            } else {
                setLoading(true);
            }
        }

        RESTService.action("GetMyApplication", __params)
            .then((res) => {
                setGetMyApplication(res);
                setLoading(false);
                setApplicationLoading(false);
            }).catch((err) => {
                console.error("GetApplicationErr", err);
                setLoading(false);
                
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const onChangeSelectedFilters = (_filters: Array<SelectedApplicationFilterTypes>, item: SelectedApplicationFilterTypes) => {
        if(item.type === "history") {
            let  _history: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedHistory));
            const isExistsInSelectedData = selectedHistory.findIndex(e => e.__key === item.id);
            _history.splice(isExistsInSelectedData,1);
            setSelectedHistory(_history);
            return;
        }
        
        if(item.type === "cities") {
            let  _cities: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData = selectedCities.findIndex(e => e.__key === item.id);
            _cities.splice(isExistsInSelectedData,1);
            setSelectedCities(_cities);
            return;
        }
    };

    const renderPagination = () => {
        if (!getMyApplication) {
            return null;
        }

        if (getMyApplication.totalApplicationCount < 10) {
            return null;
        }

        return <div
            className={classes.paginationContainer}
            style={{
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.content
            }}
        >
            <Pagination
                totalDataCount={getMyApplication.totalApplicationCount}
                onSelect={(item, index) => setPage(item.pageNumber)}
                currentPage={page}
                itemPerPage={10}
            />
        </div>;
    };

    const renderNoOtherApplication = () => {
        return <div
            className={classes.noApplicant}
            style={{
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                paddingTop: spaces.container,
                paddingRight: spaces.content,
                gap: spaces.containerXXLarge,
                borderRadius: radiuses.hard,
                paddingLeft: spaces.content,
                borderColor: colors.stroke,
                borderWidth:borders.line
            }}
        >
            <div
                className={classes.noApplicantHeader}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                >
                    {0} {localize("application")}
                </Text>
                <Text
                    variant="body4-regular"
                >
                    {localize("the-postings-you-apply-for-will-be-displayed-here")}
                </Text>
            </div>
            <div>
                <StateCard
                    title={localize("no-aplicant-statecard-header")}
                    content={localize("all-applications-submitted-will-be-displayed-here")}
                    icon={() => <SearchIcon
                        size={100}
                    />}
                />
            </div>
        </div>;
    };

    const renderLeftContainer = () => {
        if (!getMyApplication) {
            return renderNoOtherApplication();
        }

        return  <div
            className={classes.leftContainer}
            style={{
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2
            }}
        >
            <LeftContainer
                handleGetMyApplication={handleGetMyApplication}
                setResponsiveShow={setResponsiveShow}
                getMyApplication={getMyApplication}
                setSearchParams={setSearchParams}
            />
            {renderPagination()}
        </div>;
    };

    const renderResponsivePagination = () => {
        if (!getMyApplication) {
            return null;
        }

        if (getMyApplication.totalApplicationCount < 10) {
            return null;
        }

        return <div
            className={classes.responsivePaginationContainer}
        >
            <Text
                variant="body4-medium"
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                {`Şu an ${getMyApplication.totalApplicationCount} sayfa içerisinde ${page}. sayfadasınız.`}
            </Text>
            <div
                className={classes.responsivePaginationContent}
                style={{
                    gap: spaces.contentLarge
                }}
            >
                <Button
                    title={localize("previus-page")}
                    textColor="textWhite"
                    disabled={page === 1}
                    color="textSecondary"
                    onClick={() => 
                        setPage(page - 1)
                    }
                    size="large"
                />
                <Button
                    title={localize("next-page")}
                    textColor="textWhite"
                    onClick={() => 
                        setPage(page + 1)
                    }
                    size="large"
                />
            </div>
        </div>;
    };

    const renderResponsiveNoOtherApplicant = () => {
        return <div
            className={classes.responsiveNoApplicant}
            style={{
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                paddingTop: spaces.container,
                paddingRight: spaces.content,
                gap: spaces.containerXXLarge,
                borderRadius: radiuses.hard,
                paddingLeft: spaces.content,
                borderColor: colors.stroke,
                borderWidth:borders.line
            }}
        >
            <div>
                <StateCard
                    title={localize("no-aplicant-statecard-header")}
                    content={localize("all-applications-submitted-will-be-displayed-here")}
                    isAction={true}
                    buttonProps={{
                        onClick: () => {
                            navigate("/app/search");
                        },
                        title: localize("find-ad"),
                        spreadBehaviour: "stretch"
                    }}
                    icon={() => <SearchIcon
                        color={colors.primary}
                        size={100}
                    />}
                />
            </div>
        </div>;
    };

    const renderLeftMobileContainer = () => {
        if(responsiveShow === "content") {
            return null;
        }

        return <div
            className={classes.leftMobileContainer}
        >
            <div
                className={classes.headerLeftMobileContainerBorderStyle}
                style={{
                    marginBottom: spaces.contentLarge,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                <HeaderLeftMobileContainer
                    setSelectDialogHistoryVisible={setSelectDialogHistoryVisible}
                    setSelectDialogCitiesVisible={setSelectDialogCitiesVisible}
                    selectDialogHistoryVisible={selectDialogHistoryVisible}
                    selectDialogCitiesVisible={selectDialogCitiesVisible}
                    setSelectedHistory={setSelectedHistory}
                    historyDropdownRef={historyDropdownRef}
                    setSelectedCities={setSelectedCities}
                    citiesDropdownRef={citiesDropdownRef}
                    getMyApplication={getMyApplication}
                    selectedHistory={selectedHistory}
                    selectedCities={selectedCities}
                />
            </div>
            <div
                className={classes.leftMobileContent}
                style={{
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                {!getMyApplication ? (
                    renderResponsiveNoOtherApplicant()
                ) : (
                    <LeftMobileContainer
                        handleGetMyApplication={handleGetMyApplication}
                        setResponsiveShow={setResponsiveShow}
                        getMyApplication={getMyApplication}
                        setSearchParams={setSearchParams}
                    />
                )}
            </div>
            {renderResponsivePagination()}
        </div>;
    };

    const renderResponsiveChoosenFilters = () => {
        let _filters: Array<SelectedApplicationFilterTypes> = [];

        if(selectedHistory.length) { 
            let _history: Array<SelectedApplicationFilterTypes> = selectedHistory.map((item) => {
                return {
                    localizedText: item.__title,
                    id: item.__key,
                    type: "history"
                };
            });
            _filters = [..._filters, ..._history];
        }

        if(selectedCities.length) { 
            let _cities: Array<SelectedApplicationFilterTypes> = selectedCities.map((item) => {
                return {
                    localizedText: item.__title,
                    id: item.__key,
                    type: "cities"
                };
            });
            _filters = [..._filters, ..._cities];
        }

        if(!_filters.length) {
            return null;
        }

        return <div
            className={classes.responsiveChoosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                marginTop: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            key={index}
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderFilterContainer = () => {
        if(!getMyApplication) {
            return null;
        }
        return <div
            className={classes.filterContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content,
                    marginBottom: spaces.content,
                    gap: spaces.content
                }}
            >
                <DropDown
                    //@ts-ignore
                    data={getMyApplication.cities.map((item, index) => {
                        return {
                            __title: item.localizedText,
                            __originalIndex: index,
                            __key: item._id
                        };
                    })}
                    setSelectedItems={item => {
                        setSelectedCities(item.map(c_item => ({
                            __originalIndex: c_item.__originalIndex,
                            __title: c_item.__title,
                            __key: c_item.__key
                        })));
                    }}
                    onClickOpen={() => setCitiesSelectVisible(!citiesSelectVisible)}
                    onClose={() => setCitiesSelectVisible(false)}
                    dropdownref={citiesDropdownRef}
                    isVisible={citiesSelectVisible}
                    selectedItems={selectedCities}
                    buttonTitle={localize("city")}
                    isNeedConfirm={true}
                    multiSelect={true}
                    initialData={[]}
                    onOk={({
                        closeSheet,
                        onSuccess 
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <DropDown
                    setSelectedItems={item => setSelectedHistory(item.map(c_item => ({
                        __originalIndex: c_item.__originalIndex,
                        __title: c_item.__title,
                        __key: c_item.__key
                    })))}
                    onClickOpen={() => setHistorySelectVisible(!historySelectVisible)}
                    onClose={() => setHistorySelectVisible(false)}
                    buttonTitle={localize("by-application-date")}
                    isVisible={historySelectVisible}
                    selectedItems={selectedHistory}
                    dropdownref={historyDropdownRef}
                    //@ts-ignore
                    data={CANDIDATE_HISTORY.map((item, index) => ({
                        __originalIndex: index,
                        __title: localize(item.title),
                        __key: item.value
                    }))}
                    isNeedConfirm={true}
                    multiSelect={false}
                    initialData={[]}
                    onOk={({
                        closeSheet,
                        onSuccess
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
        </div>;
    };

    const renderNoOtherApplicantContent = () => {
        return <div
            className={classes.noApplicantcontentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <img
                src={getMyApplication?.companyInfo.photoURL ? getMyApplication?.companyInfo.photoURL : require("../../../assets/img/isinolacakMini.png")}
                className={classes.noApplicanting}
            />
            <StateCard
                title={localize("application-has-not-been-made-yet")}
                content={localize("all-details-of-your-applications-and-application-processes-will-be-displayed-here")}
                isAction={true}
                buttonProps={{
                    onClick: () => {
                        navigate("/app/search");
                    },
                    title: localize("find-ad"),
                    spreadBehaviour: "stretch"
                }}
            />
        </div>;
    };

    const renderContentContainer = () => {
        if(!getMyApplication) {
            return renderNoOtherApplicantContent();
        }

        return <div>
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                {renderFilterContainer()}
                {renderResponsiveChoosenFilters()}
            </div>
            <div
                className={classes.contentContainer}
                style={{
                    padding: `0px ${spaces.containerXLarge / 2}px`,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                <ContentContainer
                    setGetMyApplication={setGetMyApplication}
                    applicationLoading={applicationLoading}
                    getMyApplication={getMyApplication}
                    setViews={setViews}
                    views={views}
                />
            </div>
        </div>;
    };

    const renderContentMobileContainer = () => {
        if(responsiveShow === "left") {
            return null;
        }

        return <div>
            <div
                className={classes.headerContentMobileContainerBorderStyle}
                style={{
                    marginBottom: spaces.contentLarge,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                <HeaderContentMobileContainer
                    setResponsiveShow={setResponsiveShow}
                    getMyApplication={getMyApplication}
                />
            </div>
            <div
                className={classes.contentMobileContainer}
                style={{
                    padding: `0px ${spaces.containerXLarge / 2}px`,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                <ContentMobileContainer
                    setGetMyApplication={setGetMyApplication}
                    getMyApplication={getMyApplication}
                    setViews={setViews}
                    views={views}
                />
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon
                    color={colors.primary}
                    size={45}
                />
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if (loading) {
        return <div className={classes.loadingContiner}>
            <Loading />
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("management-of-application-advertisements")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            {renderLeftContainer()}
            {renderLeftMobileContainer()}
            <div
                className={classes.contentContainerStyle}
            >
                {renderContentContainer()}
                {renderContentMobileContainer()}
            </div>
            {renderRightContainer()}
        </div>
    </div>;
};

export default ApplicationManagement;
