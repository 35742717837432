import {
    DateAxisValuesType
} from "./types";

export const DATE_AXIS_VALUES: DateAxisValuesType = {
    monthly: {
        XAxis: "index",
        YAxis: "count"
    },
    weekly: {
        XAxis: "index",
        YAxis: "count"
    },
    daily: {
        XAxis: "date",
        YAxis: "count"
    },
};

export const CUSTOM_HOUR_TICKS = Array.from({
    length: 9 
}, (_, i) => i * 3).map(hour => `${hour}:00`);
