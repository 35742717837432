import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        }
    },
    title: {
        display: "flex",
        width: "100%"
    }
});
