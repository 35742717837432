import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    StateCard,
    Button
} from "isinolacak-web-cl";
import {
    TrashOutlineIcon
} from "../../../../../../../../assets/svgr";
import { 
    RESTService 
} from "../../../../../../../../services/restAPI";
import{
    IDeleteAlarmType
} from "./types";
import {
    GetAlarmsResponseObjectType
} from "../../../../../../../../services/restAPI/actions/getAlarms/types";

const DeleteAlarm = ({
    onClickCloseButton,
    setAlarmData,
    alarmData,
    alarmID
}: IDeleteAlarmType) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const deleteAlarm = () => {
        RESTService.action("DeleteAlarm", {
            alarmID: alarmID
        })
            .then((res) => {
                let _alarmData = JSON.parse(JSON.stringify(alarmData));
                let currentAdvertIndex = _alarmData.findIndex((e: GetAlarmsResponseObjectType) => e._id === alarmID);
                if (currentAdvertIndex !== -1) {
                    _alarmData.splice(currentAdvertIndex, 1);
                }
                setAlarmData(_alarmData);
                onClickCloseButton();
            })
            .catch((err) => {
                console.error("DeleteAlarm Err:", err);
            });
    };

    return <div
        className={classes.pageModalContainer}
        style={{
            padding: spaces.content
        }}
    >
        <StateCard
            title={localize("you-are-about-to-delete-the-advert-alarm")}
            content={localize("you-can-edit-the-alarm-and-reuse-it")}
            icon={() =>
                <TrashOutlineIcon
                    color={colors.error}
                    size={68}
                />
            }
            style={{
                textAlign: "center"
            }}
            contentColor= "textSecondary"
        />
        <div
            className={classes.pageModalContent}
            style={{
                gap: spaces.content
            }}
        >
            <Button
                onClick={() => {
                    onClickCloseButton();
                }}
                title={localize("give-up")}
                spreadBehaviour='stretch'
                variant='outline'
            />
            <Button
                onClick={() => {
                    deleteAlarm();
                }}
                title={localize("remove")}
                spreadBehaviour='stretch'
            />
        </div>
    </div>;
};

export default DeleteAlarm;
