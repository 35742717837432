import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const CheckList = ({
    size = 24,
    color,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M7.956 1.269a.908.908 0 0 1-.037 1.196L3.534 6.978a.726.726 0 0 1-.577.225.748.748 0 0 1-.543-.312L1.17 5.2c-.267-.363-.216-.896.114-1.19.329-.294.812-.238 1.079.125l.724.987 3.784-3.893a.718.718 0 0 1 1.084.04Zm3.277 2.834c0-.468.343-.847.767-.847h10.233c.423 0 .767.38.767.847 0 .467-.344.846-.767.846H12c-.424 0-.767-.38-.767-.846ZM7.956 9.166a.908.908 0 0 1-.037 1.196l-4.385 4.513a.726.726 0 0 1-.577.226.748.748 0 0 1-.543-.312L1.17 13.097a.907.907 0 0 1 .114-1.19c.329-.295.812-.239 1.079.125l.724.986 3.784-3.893a.718.718 0 0 1 1.084.041ZM11.233 12c0-.467.343-.846.767-.846h10.233c.423 0 .767.379.767.846 0 .467-.344.846-.767.846H12c-.424 0-.767-.379-.767-.846Zm-3.277 5.064a.908.908 0 0 1-.037 1.196l-4.385 4.512a.726.726 0 0 1-.577.226.748.748 0 0 1-.543-.312L1.17 20.994c-.267-.363-.216-.896.114-1.19.329-.294.812-.238 1.079.125l.724.987 3.784-3.894a.718.718 0 0 1 1.084.042Zm3.277 2.833c0-.467.343-.846.767-.846h10.233c.423 0 .767.38.767.846 0 .468-.344.847-.767.847H12c-.424 0-.767-.38-.767-.847Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};

export default CheckList;
