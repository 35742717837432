import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Letter = ({
    color = "#000",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M12.322 4h5.356c2.393 0 4.29 0 5.773.2 1.527.205 2.762.637 3.737 1.612.975.974 1.407 2.21 1.613 3.737.199 1.484.199 3.38.199 5.773v.147c0 2.393 0 4.289-.2 5.772-.205 1.527-.637 2.763-1.612 3.738-.974.974-2.21 1.407-3.737 1.612-1.484.2-3.38.2-5.773.2h-5.356c-2.393 0-4.29 0-5.773-.2-1.527-.205-2.763-.638-3.737-1.612-.975-.975-1.407-2.21-1.613-3.738C1 19.758 1 17.862 1 15.47v-.147c0-2.393 0-4.29.2-5.773.205-1.527.637-2.763 1.612-3.737.974-.975 2.21-1.407 3.737-1.613C8.033 4 9.93 4 12.322 4ZM6.809 6.136c-1.31.176-2.065.506-2.616 1.057-.551.551-.881 1.306-1.057 2.616-.18 1.339-.183 3.103-.183 5.586 0 2.484.003 4.248.183 5.586.176 1.31.506 2.065 1.057 2.616.551.552 1.306.882 2.616 1.058 1.339.18 3.103.182 5.586.182h5.21c2.483 0 4.247-.002 5.585-.182 1.31-.176 2.066-.506 2.617-1.058.551-.55.881-1.306 1.058-2.616.18-1.338.182-3.102.182-5.586 0-2.483-.003-4.247-.183-5.586-.176-1.31-.506-2.065-1.057-2.616-.551-.551-1.306-.881-2.616-1.057-1.339-.18-3.103-.183-5.586-.183h-5.21c-2.483 0-4.247.003-5.586.183ZM6.436 9.56a.977.977 0 0 1 1.375-.125l2.812 2.343c1.215 1.012 2.059 1.713 2.77 2.17.69.444 1.158.593 1.607.593.45 0 .917-.149 1.606-.592.712-.458 1.556-1.159 2.771-2.171l2.812-2.343a.977.977 0 1 1 1.25 1.5l-2.86 2.384c-1.155.962-2.09 1.742-2.916 2.273-.86.553-1.698.903-2.663.903-.965 0-1.803-.35-2.663-.903-.826-.531-1.761-1.31-2.916-2.273l-2.86-2.384a.977.977 0 0 1-.125-1.375Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Letter;
