import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const DoubleTic = ({
    color= "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M20.589 7.296a1.2 1.2 0 0 1 .115 1.693L8.132 23.39a1.2 1.2 0 0 1-1.807 0l-5.029-5.76a1.2 1.2 0 0 1 1.808-1.578l4.125 4.724L18.896 7.411a1.2 1.2 0 0 1 1.693-.115ZM28.628 7.431a1.2 1.2 0 0 1 .041 1.697l-13.715 14.4a1.2 1.2 0 0 1-1.823-.101l-.686-.9a1.2 1.2 0 0 1 1.72-1.651L26.932 7.472a1.2 1.2 0 0 1 1.697-.04Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default DoubleTic;
