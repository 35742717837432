import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    inputContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    codeContainer: {
        justifyContent: "space-between",
        display: "flex",
        width: 250
    },
    codeBox: {
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        display: "flex",
        height: 40,
        width: 50
    },
    input: {
        visibility: "visible",
        position: "absolute",
        cursor: "pointer",
        outline: "none",
        border: "none",
        padding: 0,
        opacity: 0,
        left: 0,
        top: 0
    },
    errorText: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    refleshButton: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex"
    },
    saveButton: {
        marginTop: "auto"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    }
});

export default useStyles;
