import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    InputYearsSelectedItems,
    EducationSelectedItems,
    EducationTypes
} from "./types";
import {
    PlusIcon
} from "../../../../../../../../assets/svgr";
import NewEducation from "../../../../../../../../components/newEducation";
import Info from "../../../../../../../../assets/svgr/infoIcon";

const Education = ({
    setEducation,
    education,
    setErrors,
    errors
}: EducationTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const onChangeEducationLevel = (selectedItem: EducationSelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].educationLevel.localizedText = selectedItem.localizedText;
        newData[index].educationLevel._id = selectedItem._id;
        setEducation(newData);
    };
    
    const onChangeStartDate = (selectedItem: InputYearsSelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].startDate = selectedItem.year;
        setEducation(newData);
    };

    const onChangeEndDate = (selectedItem: InputYearsSelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].endDate = selectedItem.year;
        setEducation(newData);
    };

    const onChangeSchool = (text: string, index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].school = text;
        setEducation(newData);
    };

    const onChangeMajor = (text: string, index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].major = text;
        setEducation(newData);
    };

    const onChangeIsWork = (index: number) => {
        let newData = JSON.parse(JSON.stringify(education));
        newData[index].educationHere = !newData[index].educationHere;
        setEducation(newData);
    };

    const EducationInputComponent = () => {
        return education.map((item, index: number) => (
            <NewEducation
                onChangeEducationLevel={onChangeEducationLevel}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                removeEducation={removeEducation}
                onChangeIsWork={onChangeIsWork}
                onChangeSchool={onChangeSchool}
                onChangeMajor={onChangeMajor}
                education={education}
                setErrors={setErrors}
                showExitIcon={true}
                errors={errors}
                index={index}
                data={item}
                key={index}
            />
        ));
    };

    const addNewEducation = () => {
        let _education = JSON.parse(JSON.stringify(education));
        _education.push({
            educationLevel: {
                localizedText: "",
                _id: ""
            },
            startDate: 0,
            endDate: 0,
            school: "",
            major: ""
        });
        setEducation(_education);
    };

    const removeEducation = (index: number) => {
        let updatedErrors = JSON.parse(JSON.stringify(errors));
        const nameErrorEducationLevelID = `education-${index}-educationLevelID-length-error`;
        const nameErrorSchool = `education-${index}-school-length-error`;
        const nameErrorMajor = `education-${index}-major-length-error`;
        const nameErrorStartDate = `education-${index}-startDate-length-error`;
        const nameErrorEndDate = `education-${index}-endDate-length-error`;
    
        updatedErrors = updatedErrors.filter((error: string) =>
            error !== nameErrorEducationLevelID &&
            error !== nameErrorSchool &&
            error !== nameErrorMajor &&
            error !== nameErrorStartDate &&
            error !== nameErrorEndDate
        );
        setErrors(updatedErrors);

        let newData = JSON.parse(JSON.stringify(education));
        newData.splice(index, 1);
        setEducation(newData);
    };

    const renderEmptyEducationInfo = () => {
        return <div
            className={classes.emptyComapnyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.content,
                gap: spaces.inline,
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderEducation = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("education")}
            </Text>
            {education.length === 0 && renderEmptyEducationInfo()}
            {EducationInputComponent()}
            <Button
                onClick={() => addNewEducation()}
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />}
                title={localize("add-education")}
                variant='ghost'
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderEducation()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default Education;
