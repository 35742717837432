import useStyles from "./style";
import {
    PerformanceTestCardProps
} from "./types";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    PencilEditIcon,
    ChartIcon,
    TrashIcon
} from "../../../assets/svgr";

const PTCard = ({
    isSelected,
    onDelete,
    onClick,
    title,
    onEdit
}: PerformanceTestCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderInfoContainer = () => {
        return <div
            className={classes.infoContainer}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {title}
            </Text>
        </div>;
    };

    const renderChartIcon = () => {
        return <div
            className={classes.chartContainer}
            style={{
                borderColor: colors.primary,
                padding: spaces.inline / 4,
                borderWidth: borders.line
            }}
        >
            <ChartIcon/>
        </div>;
    };

    const renderMenuIcon = () => {
        return <div
            className={classes.menuContainer}
            style={{
                gap: spaces.content
            }}
        >
            <div
                className={classes.icon}
                onClick={onEdit}
            >
                <PencilEditIcon
                    size={20}
                />
            </div>
            <div
                className={classes.icon}
                onClick={onDelete}
            >
                <TrashIcon
                    color={colors.gray60}
                    size={20}
                />
            </div>
        </div>;
    };

    return <div
        className={classes.container}
        onClick={onClick}
        style={{       
            padding: `${spaces.container}px ${spaces.containerXLarge / 2}px`,
            borderColor: isSelected ? colors.primary : colors.stroke,
            marginBottom: spaces.container,
            borderRadius: radiuses.half,
            borderWidth: borders.line
        }}
    >
        <div
            className={classes.content}
            style={{
                gap: spaces.content
            }}
        >
            {renderChartIcon()}
            {renderInfoContainer()}
        </div>
        {renderMenuIcon()}
    </div>;
};

export default PTCard;