import {
    wrap 
} from "module";
import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        flexGrow: 1
    },
    mainContent: {
        flexDirection: "column",
        display: "flex",
        flexGrow: 1,
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    responsiveMainContent: {
        flexDirection: "column",
        display: "flex",
        flexGrow: 1,
        "@media screen and (min-width: 1200px)": {
            display: "none"
        }
    },
    renderTopContainer: {
        flexDirection: "column",
        textAlign: "center",
        display: "flex"
    },
    chipContainer: {
        justifyContent: "center",
        marginRight: "auto",
        marginLeft: "auto",
        flexWrap: "wrap",
        display: "flex",
        maxWidth: 700,
        flexGrow: 1
    },
    renderBottomContainer: {
        justifyContent: "center",
        textAlign: "start",
        display: "flex",
        flexGrow: 1
    },
});