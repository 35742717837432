import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import {
    CardDataType 
} from "./types";
import {
    useNavigate 
} from "react-router-dom";
import {
    cleanHTMLElements,
    getDate 
} from "../../../../../utils";

const BlogCard = ({
    coverPhoto, title, date, content, blogID
}: CardDataType) => {
    const navigate = useNavigate();

    const classes = useStyles();
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div 
        className={classes.card}
        style={{
            backgroundColor: colors.white,
            borderRadius: radiuses.half
        }}
    >
        <img src={coverPhoto} alt="" className={classes.image} />
        <div 
            className={classes.content}
            style={{
                paddingTop: spaces.content / 1.5,
                padding: spaces.content,
                gap: spaces.content
            }}
        >
            <div 
                className={classes.content}
            >
                <Text
                    style={{
                        paddingBottom: spaces.content / 2
                    }}
                    variant="body-bold"
                >
                    {title}
                </Text>
                <Text
                    variant="body3-medium"
                    color="textSecondary"
                >
                    {date && getDate(date)}
                </Text>
            </div>
            <div
                className={classes.content}
            >
                <Text
                    style={{
                        paddingBottom: spaces.content / 2
                    }}
                    variant="body3-medium"
                    color="textSecondary">
                    {content && cleanHTMLElements(content)}
                </Text>
                <div onClick={() => navigate(`/blog/${blogID}`)}>
                    <Text
                        className={classes.link}
                        variant="body2-medium"
                        color="primary"
                    >
                        {localize("continue-reading")}...
                    </Text>
                </div>
            </div>
        </div>
    </div>;
};

export default BlogCard;