import React, {
    Fragment,
    useEffect,
    useState 
} from "react";
import {
    useStyles
} from "./styles";
import moment from "moment";
import {
    IOCoreLocale,
    IOCoreTheme, 
    StateCard,
    Loading,
    Chip,
    Text,
    IOCoreIconType
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../services/restAPI";
import {
    SubPageType,
    URLDataType
} from "./types";
import {
    StickerDocumentIcon,
    DocumentFullIcon,
    CloseCircleIcon,
    FilledTicIcon,
    MyActionsIcon,
    HourglassIcon,
    CalenderIcon,
    EyeOpenIcon
} from "../../../../assets/svgr";
import {
    GetMyActionsWorkerPayloadObjectTypes
} from "../../../../services/restAPI/actions/getMyActionsWorker/types";
import {
    GetMyActionsRequestType
} from "../../../../services/restAPI/actions/getMyActions/types";
import {
    StickerObjectType
} from "../../../../components/advertCardForEmployer/types";
import {
    AdvertCard,
    Footer
} from "../../../../components";
import {
    useNavigate
} from "react-router-dom";
import {
    NavbarContext
} from "../../../../context";

let STATUS_STICKERS: Array<StickerObjectType> = [
    {
        key: "applied",
        translationKey: "application-sent",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "performanceTestWaiting",
        translationKey: "performance-test-submit-waiting",
        translationKeyParam: false,
        color: "waitingApplicationStatusFrontColor",
        backColor: "waitingApplicationStatusBackColor"
    },
    {
        key: "documentWaiting",
        translationKey: "documents-submit-waiting",
        translationKeyParam: false,
        color: "waitingApplicationStatusFrontColor",
        backColor: "waitingApplicationStatusBackColor"
    },
    {
        key: "performanceTestSubmitted",
        translationKey: "performance-test-completed",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "documentSent",
        translationKey: "documents-sent",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "documentInReview",
        translationKey: "documents-in-review",
        translationKeyParam: false,
        color: "neutralApplicationStatusFrontColor",
        backColor: "neutralApplicationStatusBackColor"
    },
    {
        key: "finished",
        translationKey: "documents-completed",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "rejected",
        translationKey: "application-rejected",
        translationKeyParam: false,
        color: "negativeApplicationStatusFrontColor",
        backColor: "negativeApplicationStatusBackColor"
    },
    {
        key: "reuploadDocumentWaiting",
        translationKey: "document-resubmit-waiting",
        translationKeyParam: false,
        color: "waitingApplicationStatusFrontColor",
        backColor: "waitingApplicationStatusBackColor"
    },
    {
        key: "lastUpdate",
        translationKey: "advert-last-update-date",
        translationKeyParam: true,
        color: "advertDateStickerFrontColor",
        backColor: "advertDateStickerBackColor"
    },   
    {
        key: "openToApply",
        translationKey: "open-to-apply",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "closeToApply",
        translationKey: "close-to-apply",
        translationKeyParam: false,
        color: "negativeApplicationStatusFrontColor",
        backColor: "negativeApplicationStatusBackColor"
    }
];

const WorkerMyActions = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getMyActionsData, setGetMyActionsData] = useState<Array<GetMyActionsWorkerPayloadObjectTypes>>([]);
    const [getMyActionsIsEndOfData, setGetMyActionsIsEndOfData] = useState(false);
    const [getMyActionsIsLoadMore,setGetMyActionsIsLoadMore] = useState(false);
    const [getMyActionsPage, setGetMyActionsPage] = useState(1);
    const [subPage, setSubPage] = useState<SubPageType>();
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);

    useEffect(() => {
        NavbarContext.addEventListener("/app/myActions", handleSubPage);
        return () => {
            NavbarContext.removeEventListener("/app/myActions");
        };
    }, []);

    useEffect(() => {
        const location = window.location.search;
        const url = new URLSearchParams(location);
    
        let _urlData: URLDataType = {
        };

        // @ts-ignore
        for (const [key, value] of url.entries()) {
            if (key === "href") { _urlData.href = value; }
        }

        let _subPage: SubPageType = "applied";
        if(_urlData.href) {
            switch (_urlData.href) {
            case "applied":
                _subPage = "applied";
                break;
            case "documentSubmitted":
                _subPage = "documentSubmitted";
                break;
            case "favs":
                _subPage = "favs";
                break;
            }
        }

        handleSubPage(_subPage);
    }, []);

    useEffect(() => {
        handleGetMyActions();
    }, [subPage, page]);


    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleSubPage = (subPage: SubPageType) => {
        setSubPage(subPage);
        if(subPage) {
            navigate("/app/myActions?href="+subPage);
        } else {
            navigate("/app/myActions");
        }
    };

    const setAdvertStickers = (item: any, arr: Array<StickerObjectType>, icon: IOCoreIconType) => {
        if(item.applicationStatus) {
            switch (item.applicationStatus) {
            case "applied":
                icon = () => <FilledTicIcon size={12}/>;
                break;
            case "performanceTestWaiting":
                icon = () => <HourglassIcon size={12}/>;
                break;
            case "performanceTestSubmitted":
                icon = () => <FilledTicIcon size={12}/>;
                break;
            case "documentWaiting":
                icon = () => <HourglassIcon size={12}/>;
                break;
            case "documentSent":
                icon = () => <StickerDocumentIcon size={12}/>;
                break;
            case "documentInReview":
                icon = () => <EyeOpenIcon size={12} color={colors.neutralApplicationStatusFrontColor}/>;
                break;
            case "reuploadDocumentWaiting":
                icon = () => <HourglassIcon size={12}/>;
                break;
            case "rejected":
                icon = () => <CloseCircleIcon size={12} color={colors.negativeApplicationStatusFrontColor} />;
                break;
            case "finished":
                        
                break;
                
            default:
                break;
            }
        }
        
        if(subPage === "documentSubmitted" && item.applicationStatus) {
            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === item.applicationStatus);
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.icon = icon;
            arr.push(newSticker);
        }

        if(subPage === "applied" && item.applicationStatus) {
            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === item.applicationStatus);
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.icon = icon;
            arr.push(newSticker);
        }

        if(subPage === "favs") {
            if(!item.isPublished || item.isClosed) {
                let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "closeToApply");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
                newSticker.icon = () => <DocumentFullIcon color={colors.negativeApplicationStatusFrontColor} size={12}/>;
                arr.push(newSticker);
            } else {
                let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "openToApply");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
                newSticker.icon = () => <DocumentFullIcon color={colors.positiveApplicationStatusFrontColor} size={12}/>;
                arr.push(newSticker);
            }

            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "lastUpdate");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.param = moment(item.updatedAt).format("DD.MM.YYYY").toString();
            newSticker.icon = () => <CalenderIcon size={12}/>;
            arr.push(newSticker);
        }
    };

    const handleGetMyActions = () => {
        if(getMyActionsIsEndOfData) {
            return;
        }

        let filter = "applied";

        if(subPage === "applied") {
            filter = "applied";
        }

        if(subPage === "documentSubmitted") {
            filter = "documentSubmitted";
        }

        if(subPage === "favs") {
            filter = "favs";
        }

        let params: GetMyActionsRequestType = {
            page: getMyActionsPage,
            filter: filter
        };
        setLoading(true);
        RESTService.action("GetMyActionsWorker", params)
            .then((res) => {
                //@ts-ignore
                setGetMyActionsData(res.payload);
                setGetMyActionsIsEndOfData(false);
                setGetMyActionsIsLoadMore(false);
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetMyActions Err:", err);
            });
    };

    const handleAddMyActionsToFavorite = (advertID: string) => {
        RESTService.action("AddAdvertToFavorite", {
            advertID: advertID
        }).then((res) => {
            let _data = JSON.parse(JSON.stringify(getMyActionsData));
            const currentIndex = getMyActionsData.findIndex((item: { _id: string; }) => {
                return item._id === advertID;
            });
            _data[currentIndex].isFavorite = true;
            setGetMyActionsData(_data);
        })
            .catch((err) => {
                console.error("AddAdvertToFavorite Err:", err);
            });
    };

    const handleRemoveMyActionsFromFavorite = (advertID: string) => {
        RESTService.action("RemoveAdvertFromFavorite", {
            advertID: advertID
        })
            .then((res) => {
                let _data = JSON.parse(JSON.stringify(getMyActionsData));
                const currentIndex = getMyActionsData.findIndex((item: { _id: string; }) => {
                    return item._id === advertID;
                });
                _data[currentIndex].isFavorite = false;
                if (currentIndex !== -1 && subPage === "favs") {
                    _data.splice(currentIndex, 1);
                }
                setGetMyActionsData(_data);
            })
            .catch((err) => {
                console.error("RemoveAdvertFromFavorite Err:", err);
            });
    };

    const renderHeaderChipContainer = () => {
        return <div
            className={classes.renderHeaderChipContainer}
            style={{
                marginTop: spaces.content * 2,
            }}
        >
            <div
                className={classes.chipContainer}
                style={{
                    marginTop: spaces.content * 2,
                    padding: spaces.content,
                    gap: spaces.content * 1,
                }}
            >
                <Chip
                    title={localize("ı-applied")}
                    selected={subPage === "applied"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if (subPage === "applied") {
                            handleSubPage("applied");
                            return;
                        }
                        handleSubPage("applied");
                    }}
                />
                <Chip
                    title={localize("documents-ı-sent")}
                    selected={subPage === "documentSubmitted"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if (subPage === "documentSubmitted") {
                            handleSubPage("documentSubmitted");
                            return;
                        }
                        handleSubPage("documentSubmitted");
                    }}
                />
                <Chip
                    title={localize("myActions-worker-saved")}
                    selected={subPage === "favs"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if (subPage === "favs") {
                            handleSubPage("favs");
                            return;
                        }
                        handleSubPage("favs");
                    }}
                />
            </div>
        </div>;
    };

    const applicantCard = () => {
        if(loading) {
            return <div
                className={classes.loadingContainer}
                style={{
                    marginTop: spaces.container
                }}
            >
                <Loading/>
            </div>;
        }

        if (!getMyActionsData.length) {
            return <div
                className={classes.stateCard}
                style={{
                    marginTop: spaces.content * 5,
                    color: colors.textDark
                }}
            >
                <StateCard
                    content={localize("you-will-view-your-activities-for-your-live-advertisements-on-this-page")}
                    title={localize("this-place-is-empty-right-now")}
                    icon={() => <MyActionsIcon />}
                />
            </div>;
        }

        return <div
            className={classes.cardContainer}
            style={{
                marginTop: spaces.content * 3,
                gap: spaces.content * 2
            }}
        >
            {getMyActionsData.map((item, index) => {
                let advertStickers: Array<StickerObjectType> = [];

                let stickerIcon: IOCoreIconType = () => <></>;

                setAdvertStickers(item, advertStickers, stickerIcon);

                return  <AdvertCard
                    key={"advert-card-" + index}
                    experienceLevel={item.experienceLevel.localizedText}
                    contractType={item.contractType.localizedText}
                    jobImage={item.companyInfo.companyPhotoURL}
                    workingType={item.workingType.localizedText}
                    actionType={item?.applicationID ? "manage-application" : undefined}
                    title={item.occupation.localizedText}
                    companyName={item.companyInfo.title}
                    city={item.city.localizedText}
                    stickers={advertStickers}
                    isSaved={item.isFavorite}
                    onClickBookMark={() => {
                        if(item.isFavorite) {
                            handleRemoveMyActionsFromFavorite(item._id);
                        }else {
                            handleAddMyActionsToFavorite(item._id);
                        }
                    }}
                    onClick={() => 
                        navigate("/app/jobDetails", {
                            state: {
                                jobID: item._id
                            }
                        })
                    }
                    actionOnClick={() =>                  
                        navigate("/app/applicationManagement", {
                            state: {
                                applicationID: item.applicationID
                            }
                        })
                    }
                />;
            })}
        </div>;
    };

    const renderMyActions = () => {
        return <div
            style={{
                marginTop: spaces.content * 2
            }}
        >
            <Text
                variant="header5-medium"
                color="textDark"
                style={{
                    marginLeft: spaces.content * 1,
                }}
            >
                {localize("overview")}
            </Text>
            {renderHeaderChipContainer()}
            {applicantCard()}
        </div>;
    };

    return <Fragment>
        <div
            className={classes.container}
            style={{
                backgroundColor: colors.grey25,
                padding: !isSmall? spaces.content * 3 : spaces.inline
            }}
        >
            {renderMyActions()}
        </div>
        <Footer/>
    </Fragment>;
};

export default WorkerMyActions;
