import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    UserCheckBoldIcon,
    CalendarIcon
} from "../../../../../../assets/svgr";
import { 
    StickerObjectType,
    IAdvertCardProps
} from "./types";
import IsinOlacakAdvertLogo from "../../../../../../assets/img/isinolacakMini.png";
import {
    getDayDifference
} from "../../../../../../utils";
import BoostsTypeStickers from "../../../../../../components/boostTypeStickers";

let STATUS_STICKERS: Array<StickerObjectType> = [
    {
        key: "suitableWorker",
        translationKey: "suitable-candiate",
        translationKeyParam: false,
        color: "positiveApplicationStatusFrontColor",
        backColor: "positiveApplicationStatusBackColor"
    },
    {
        key: "applicationDate",
        translationKey: "job-seeker-application-date",
        translationKeyParam: true,
        color: "textGrey",
        backColor: "grey50"
    }
];

const FeaturedCandidateCard = ({
    experienceLevel,
    candidatePhoto,
    candidateName,
    occupation,
    education,
    appliedAt,
    onClick,
    style,
    city,
}: IAdvertCardProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const setStickers = (arr: any) => {
        let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "suitableWorker");
        let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
        newSticker.icon = () => <UserCheckBoldIcon size={12}/>;
        arr.push(newSticker);

        let stickerIndex2 = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "applicationDate");
        let newSticker2 = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex2]));
        newSticker2.param = isNaN(getDayDifference(appliedAt, Date.now())) ? "-" : getDayDifference(appliedAt, Date.now());
        newSticker2.icon = () => <CalendarIcon size={12}/>;
        arr.push(newSticker2);
    };

    const informationsContainer = () => {
        return <div
            style={{
                marginBottom: spaces.content / 2.5
            }}
            className={classes.informationTextStyle}
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {candidateName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {occupation}
                </Text>
            </div>
            <div>
                <div>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                        className={
                            classes.informationTextStyle
                        }
                    >
                        {experienceLevel && experienceLevel + " • "}
                        {city && city + " • "}
                        {education && education}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={classes.renderInformationsStyle}
            onClick={onClick}
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
        >
            <div 
                style={{
                    padding: spaces.content / 3
                }}
            >
                <img
                    src={candidatePhoto ?? IsinOlacakAdvertLogo}
                    className={classes.imageStyle}
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container,
                        padding: spaces.content / 5,
                        borderColor: colors.stroke
                    }}
                />
            </div>
            {informationsContainer()}
        </div>;
    };
    const renderTags = () => {
        let advertStickers: Array<StickerObjectType> = [];

        setStickers(advertStickers);

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                advertStickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <BoostsTypeStickers
                        title={localizedText}
                        titleColor={item.color}
                        color={item.backColor}
                        icon={item.icon}
                        itemKey={item.key}
                    />;
                })
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderInformations()}
        {renderTags()}
    </div>;
};

export default FeaturedCandidateCard;