import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        borderStyle: "solid",
        width: "100%",
        minWidth: "330px",
        maxWidth: "23%",
        display: "flex",
        "@media screen and (max-width: 1760px)": {
            maxWidth: "32% !important"
        },
        "@media screen and (max-width: 1495px)": {
            maxWidth: "48% !important"
        },
        "@media screen and (max-width: 1050px)": {
            maxWidth: "93% !important"
        }
    },
    informationTextStyle: {
        width: "100%"
    },
    renderInformationsStyle: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex",
        height: "100%"
    },
    imageStyle: {
        objectFit: "cover",
        height: 56,
        width: 56
    },
    renderTagsStyle: {
        boxSizing: "border-box",
        borderTopStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    tagContainer:{
    },
    transactionSelectionsContainer: {
        flexDirection: "column",
        display: "flex"
    },
    buttonField: {
        justifyContent: "end",
        flexDirection: "row",
        position: "absolute",
        display: "flex",
        right: 0,
        top: 0
    },
    informationsContent:{ 
        flexDirection: "row",
        display:"flex"
    },
});
