import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Hamburger = ({
    color= "#9AA6B8",
    size = 20,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 20 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            d="M19 5c0 .552-.345 1-.771 1H1.77C1.345 6 1 5.552 1 5s.345-1 .771-1H18.23c.426 0 .771.448.771 1ZM19 10c0 .552-.345 1-.771 1H1.77C1.345 11 1 10.552 1 10s.345-1 .771-1H18.23c.426 0 .771.448.771 1ZM19 15c0 .552-.345 1-.771 1H1.77C1.345 16 1 15.552 1 15s.345-1 .771-1H18.23c.426 0 .771.448.771 1Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Hamburger;
