import {
    IOCoreLocale,
    IOCoreTheme,
    Text,
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    EmptyContainerProps 
} from "./types";

const EmptyContainer = ({
    variant
}: EmptyContainerProps) => {

    const classes = useStyles();

    const {
        localize,
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div 
        className={classes.renderEmptyContainer} 
        style={{
            backgroundColor: colors.white,
            borderRadius: radiuses.half,
            marginTop: spaces.container,
            borderColor: colors.stroke, 
            borderWidth: borders.line,
            padding: spaces.container,
            gap: spaces.inline / 1.5
        }}
    >
        <Text
            variant="header5-medium"
            color="black"
        >
            {variant === "popularAdverts" ? 
                localize("there-are-currently-no-popular-ads"):
                localize("there-are-currently-no-featured-ads")}
        </Text>
        <Text
            variant="body3-regular"
            color="grey200"
        >
            {variant === "popularAdverts" ? 
                localize("the-most-popular-ads-for-you-will-be-displayed-here"):
                localize("the-featured-ads-for-you-will-be-displayed-here")}
        </Text>
    </div>;
};

export default EmptyContainer;