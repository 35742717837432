import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    Text
} from "isinolacak-web-cl";
import {
    GetForeignLanguagesType,
    ForeignLanguagesTypes,
    LanguageItem
} from "./types";
import {
    GetForeignLanguagesRequestType,
    GetForeignLanguagesLevelsType
} from "../../../../../../../../../../services/restAPI/actions/getForeignLanguages/types";
import {
    CircleExitIcon 
} from "../../../../../../../../../../assets/svgr";
import {
    RESTService 
} from "../../../../../../../../../../services/restAPI";
import {
    getErrorText 
} from "../../../../../../../../../../utils";

const ForeignLanguages = ({
    setSearchForeignLanguagesText,
    searchForeignLanguagesText,
    foreignLanguagesTotalData,
    setForeignLanguagesPage,
    onChangeForeignLanguage,
    setGetForeignLanguages,
    removeForeignLanguages,
    onChangeForeignLevel,
    foreignLanguagesPage,
    getForeignLanguages,
    foreignLanguages,
    showExitIcon,
    setErrors,
    errors,
    index
}: ForeignLanguagesTypes) => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces 
    } = IOCoreTheme.useContext();

    const [selectedLanguageLevels, setSelectedLanguageLevels] = useState<Array<GetForeignLanguagesLevelsType>>([]);
    const [searchPage, setSearchPage] = useState(1);

    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        if (getForeignLanguages && getForeignLanguages[0] && getForeignLanguages[0]._id) {
            const initialLanguage = getForeignLanguages[0];
            if (initialLanguage.levels) {
                const initialLevels = initialLanguage.levels.map((level) => ({
                    ...level,
                    level: initialLanguage._id
                }));
                setSelectedLanguageLevels(initialLevels);
            }
        }
    }, []);

    const searchForeignLanguages = (searchText: string) => {
        let lang = _langugage ?? "tr-TR";
        const timer = setTimeout(() => {
            if (searchText && searchText.length) {
                let params: GetForeignLanguagesRequestType = {
                    search: searchText,
                    language: lang,
                    page:  1
                };
                getForeignLanguagesData(params);
            }
            if(!searchText.length) {
                setForeignLanguagesPage(1);
                setSearchPage(1);
                getForeignLanguagesData({
                    language: lang,
                    page:  1
                });
            }
        }, 750);
        return () => clearTimeout(timer);
    };

    const getForeignLanguagesData = (searchParams?: GetForeignLanguagesRequestType) => {;
        let params: GetForeignLanguagesRequestType = {
            page:  foreignLanguagesPage,
            language: _langugage ?? "tr-TR"
        };

        if(searchForeignLanguagesText && searchForeignLanguagesText.length) {
            params.language= _langugage ?? "tr-TR";
            params.search = searchForeignLanguagesText;
            params.page = searchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = _langugage ?? "tr-TR";

        RESTService.action("GetForeignLanguages",params)
            .then((res) => {
                setGetForeignLanguages(res.payload);

                if(searchParams || searchForeignLanguagesText.length){
                    setForeignLanguagesPage(1);
                } else {
                    setForeignLanguagesPage(params.page ?? 1);
                    setSearchPage(1);
                }
            })
            .catch((err) => {
                console.error("GetForeignLanguages Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleLanguageChange = (selectedItem: LanguageItem) => {
        const languageId = selectedItem._id;
        const selectedLanguage = getForeignLanguages.find(language => language._id === languageId);
        if (selectedLanguage && selectedLanguage.levels) {
            let languageLevels: Array<GetForeignLanguagesLevelsType> = selectedLanguage.levels.map((level) => ({
                ...level,
                level: languageId
            }));
            setSelectedLanguageLevels(languageLevels);
            onChangeForeignLanguage(selectedItem, index);
        }
    };

    const renderLanguage = () => {
        if(!foreignLanguagesTotalData) {
            return null;
        }

        let initialItem: GetForeignLanguagesType[] = [foreignLanguages[index].language];
        if (initialItem.length > 0 && !initialItem[0]._id) {
            initialItem = [];
        }

        let initialLevel = {
            levelLocalizedText: foreignLanguages[index].level.localizedText,
            levelID: foreignLanguages[index].level.levelID
        };

        const isLanguageSelected = initialItem.length > 0;

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}>
            <div
                className={classes.remove}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant='body-regular'
                    color='textDark'
                >
                    {localize("language")}
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeForeignLanguages(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content 
                }}>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`foreignLanguages-${index}-languageID-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `foreignLanguages-${index}-languageID-length-error`)}
                        titleExtractor={(e) => e.localizedText}
                        initialSelectedItems={initialItem}
                        inputTitle={localize("language")}
                        title={localize("language")}
                        keyExtractor={(e) => e._id}
                        spreadBehaviour='stretch'
                        onSearch={(e) => {
                            setSearchForeignLanguagesText(e);
                            searchForeignLanguages(e);
                        }}
                        style={{
                            marginRight: spaces.content
                        }}
                        isNeedConfirm={true}
                        isSearchable={true}
                        emptyContent={() => {
                            return <Text>
                                {localize("no-result-found")}
                            </Text>;
                        }}
                        multiSelect={false}
                        disabled={false}
                        paginationProps={
                            foreignLanguagesTotalData?.totalData > 20 ?
                                {
                                    totalDataCount: foreignLanguagesTotalData?.totalData,
                                    itemPerPage: 20,
                                    currentPage: searchForeignLanguagesText ? searchPage : foreignLanguagesPage,
                                    onSelect(item, index) {
                                        if(searchForeignLanguagesText && searchForeignLanguagesText.length) {
                                            setSearchPage(item.pageNumber);
                                        } else {
                                            setForeignLanguagesPage(item.pageNumber);
                                        }
                                    },
                                }: undefined}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0];
                            if (selectedItem) {
                                //@ts-ignore
                                handleLanguageChange(selectedItems[0]);
                                let updatedErrors = errors.filter(err => err !== `foreignLanguages-${index}-languageID-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        data={getForeignLanguages}
                    />
                </div>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`foreignLanguages-${index}-levelID-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `foreignLanguages-${index}-levelID-length-error`)}
                        titleExtractor={(item: GetForeignLanguagesLevelsType) => item.levelLocalizedText}
                        initialSelectedItems={initialLevel && initialLevel.levelID ? [initialLevel] : []}
                        keyExtractor={(e) => e.levelID}
                        inputTitle={localize("level")}
                        title={localize("level")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            //@ts-ignore
                            const selectedItem = selectedItems[0];
                            if (selectedItem) {
                                //@ts-ignore
                                onChangeForeignLevel(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `foreignLanguages-${index}-levelID-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        disabled={!isLanguageSelected}
                        data={selectedLanguageLevels}
                    />
                </div>
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderLanguage()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default ForeignLanguages;