import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    languageContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    selectBoxStyles: {
        display:"block",
        width: "100%"
    },
    remove: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    }
});
export default useStyles;