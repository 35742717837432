import React, {
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    ContentMobileContainerType
} from "./types";
import {
    PillIcon
} from "../../../../../../assets/svgr";
import {
    ApplicantCapabilitiesObjectType,
    AdvertCapabilitiesObjectType
} from "../../../../../../services/restAPI/actions/getMyApplication/types";
import QualificationCard from "../../qualificationCard";
import SendDocumentCard from "../../sendDocumentCard";
import CompletePT from "../../completePT";
import PAQCard from "../../paqCard";

const ContentMobileContainer = ({
    setGetMyApplication,
    getMyApplication,
    setViews,
    views
}: ContentMobileContainerType) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [showAllCapabilities, setShowAllCapabilities] = useState(false);

    const handleToggleShowAll = () => {
        setShowAllCapabilities(!showAllCapabilities);
    };
    
    const itemsToShow = showAllCapabilities
        ? getMyApplication?.advertCapabilities
        : getMyApplication?.advertCapabilities.slice(0, 2);

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderAdvertDetailsAbout = () => {
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.advertDetailAboutContent}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.contentXLarge
                    }}
                > 
                    ({localize("job-title")}) {getMyApplication?.occupation.localizedText}
                </Text>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    ({localize("description")}) {localize("about-the-job-posting")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {getMyApplication?.advertDescription}
                </Text>
            </div>
        </div>;
    };

    const renderSoughtAfterQualification = () => {
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.soughtAfterQualificationContainer}
            >
                <div
                    className={classes.soughtAfterQualificationContent}
                >
                    <Text
                        variant="body-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2
                        }}
                    >
                        {localize("required-qualifications")}
                    </Text>
                    <Text
                        variant="body3-regular"
                        color="textSecondary"
                    >
                        {localize("required-qualifications-description")}
                    </Text>
                </div>
                <Button
                    title={showAllCapabilities ? localize("collapse") : localize("see-all")}
                    onClick={handleToggleShowAll}
                    variant="ghost"
                />
            </div>
            {itemsToShow?.map((item: AdvertCapabilitiesObjectType, index: number) => {
                return <QualificationCard
                    title={item.localizedText}
                    key={index}
                />;
            })}
            <div
                className={classes.soughtAfterQualitificationCardContainer}
                style={{
                    borderRadius: spaces.containerXLarge,
                    marginTop: spaces.contentLarge,
                    borderColor: colors.primary,
                    padding: spaces.content
                }}
            >
                <div
                    className={classes.soughtAfterQualitificationCardContent}
                >
                    <div>
                        <PillIcon
                            size={35}
                        />
                    </div>
                    <div
                        className={classes.soughtAfterQualitificationCardCItem}
                        style={{
                            marginLeft: spaces.content,
                        }}
                    >
                        <Text
                            variant="body2-medium"
                            color="primary"
                            style={{
                                marginBottom: spaces.content / 2
                            }}
                        >
                            {localize("skills-added-by-the-user-who-created-the-job-posting")}
                        </Text>
                        <Text
                            variant="body3-regular"
                            color="textSecondary"
                        >
                            {getMyApplication?.applicantCapabilities.map((item: ApplicantCapabilitiesObjectType) => item.localizedText).join(" - ")}
                        </Text>
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderCompanyAbout = () => {
        const phoneURL = getMyApplication?.companyInfo.photoURL ? getMyApplication?.companyInfo.photoURL : require("../../../../../../assets/img/isinolacakMini.png");

        return <div
            style={{
                padding: spaces.container
            }}
        >
            <Text
                variant="header5-medium"
                color="textDark"
            >
                {localize("company-about")}
            </Text>
            <div
                className={classes.companyAbout}
                style={{
                    marginTop: spaces.content,
                }}
            >
                <img
                    className={classes.companyAboutImage}
                    style={{
                        borderRadius: spaces.containerXXLarge * 3,
                    }}
                    src={phoneURL}
                />
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginLeft: spaces.content
                    }}
                >
                    {getMyApplication?.companyInfo.title}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {getMyApplication?.companyInfo.about}
                </Text>
            </div>
        </div>;
    };

    const renderCompletePT = () => {
        return <div>
            <CompletePT
                setGetMyApplication={setGetMyApplication}
                getMyApplication={getMyApplication}
                setViews={setViews}
            />
        </div>;
    };

    const renderPAQandSendDocument = () => {
        if (getMyApplication?.status === "performanceTestWaiting" || getMyApplication?.status === "performanceTestSubmitted") {
            return <PAQCard
                onClick={() => {setViews("completePT");}}
                getMyApplication={getMyApplication}
            />;

        } else if(getMyApplication?.status === "documentWaiting" || getMyApplication?.status === "documentSent") {
            return <SendDocumentCard
                setGetMyApplication={setGetMyApplication}
                getMyApplication={getMyApplication}
            />;
        }
    };

    return <div>
        {views === "default" && <div>
            {renderPAQandSendDocument()}
            {renderAdvertDetailsAbout()}
            {renderLine()}
            {renderSoughtAfterQualification()}
            {renderLine()}
            {renderCompanyAbout()}
        </div>}
        {views === "completePT" && <div>
            {renderCompletePT()}
        </div>}
    </div>;
};

export default ContentMobileContainer;