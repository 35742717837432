import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const DocumentFull = ({
    size = 24,
    color,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M2.432 2.289C1 3.577 1 5.652 1 9.8v4.4c0 4.148 0 6.223 1.432 7.511C3.864 23 6.168 23 10.778 23h2.444c4.61 0 6.914 0 8.346-1.289C23 20.423 23 18.348 23 14.2V9.8c0-4.148 0-6.223-1.432-7.511C20.136 1 17.831 1 13.222 1h-2.444c-4.61 0-6.914 0-8.346 1.289ZM6.194 7.6c0-.456.41-.825.917-.825h9.778c.506 0 .917.37.917.825 0 .456-.41.825-.917.825H7.11c-.506 0-.917-.37-.917-.825Zm0 4.4c0-.456.41-.825.917-.825h9.778c.506 0 .917.37.917.825 0 .456-.41.825-.917.825H7.11c-.506 0-.917-.37-.917-.825Zm.917 3.575c-.506 0-.917.37-.917.825 0 .456.41.825.917.825h6.111c.507 0 .917-.37.917-.825 0-.456-.41-.825-.917-.825h-6.11Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};

export default DocumentFull;
