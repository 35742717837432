import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        width: "100%"
    },
    renderHeaderChipContainer: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf:"center",
        display: "flex",
    },
    chipContainer: {
        justifyContent: "space-between",
        overflowX: "auto",
        display: "flex",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
        },
        "&::-webkit-scrollbar-button": {
            width: "3px",
            height: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#45BBD4",
            border: "0px none #ffffff",
            borderRadius: "100px",
            "&:hover": {
                background: "#1f1f1f",
            },
            "&:active": {
                background: "#45BBD4",
            },
        },
        "&::-webkit-scrollbar-track": {
            background: "#E2E2E2",
            border: "0px none #ffffff",
            borderRadius: "100px",
            "&:hover": {
                background: "#E2E2E2",
            },
            "&:active": {
                background: "#45BBD4",
            },
        },
        "&::-webkit-scrollbar-corner": {
            background: "transparent",
        }
    },
    stateCard: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    cardContainer: {
        boxSizing: "border-box",
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (max-width: 880px)": {
            justifyContent: "center",
        }
    },
    addButton: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        "@media screen and (max-width: 880px)": {
            position: "fixed",
            zIndex: 999,
            bottom: 30,
            right: 30,
        }
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex"
    },
    dialogBottom:{
        justifyContent: "space-evenly",
        flexDirection: "row",
        display: "flex"
    }
});
