import React, {
    ChangeEvent,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    DocumentFullIcon,
    RoundArrowIcon,
    PencilEditIcon,
    ArrowDownIcon,
    DownloadIcon,
    CircleIcon,
    PlusIcon
} from "../../../../../assets/svgr";
import {
    SendDocumentPropsCardType
} from "./types";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    getErrorText,
    uuid
} from "../../../../../utils";
import {
    GetMyApplicationResponseTypes,
    DocumentObjectType
} from "../../../../../services/restAPI/actions/getMyApplication/types";
import moment from "moment";

const SendDocumentCard = ({
    setGetMyApplication,
    getMyApplication
}: SendDocumentPropsCardType) => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();
    
    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [showDocuments, setShowDocuments] = useState(true);

    const handleUploadDocument = (documentTypeID: string, selectedFile?: File | null) => {
        RESTService.action("UploadDocument", {
            advertID: getMyApplication?.advertID,
            documentTypeID: documentTypeID,
        })
            .then((res) => {
                if(selectedFile) {
                    handleUpload(selectedFile, res.payload.uploadToken, documentTypeID);
                }
            })
            .catch((err) => {
                console.error("ERR", err);
                
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleUpload = async (selectedFile: File | null, uploadToken: string, docID: string) => {
        if (!selectedFile || !uploadToken) {
            console.error(localize("no-file-selected-or-upload-token-missing"));
            return;
        }

        const maxFileSize = 4 * 1024 * 1024;

        if (selectedFile.size > maxFileSize) {
            alert(localize("file-size-exceeds-limit"));
            return;
        }

        try {
            const formData = new FormData();
            formData.append("file", selectedFile);
    
            const response = await fetch(
                "https://storage-test.isinolacak.com/upload",
                {
                    headers: {
                        "Authorization": uploadToken
                    },
                    body: formData,
                    method: "POST"
                }
            );
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            let _status: GetMyApplicationResponseTypes = JSON.parse(JSON.stringify(getMyApplication));
            const _index = getMyApplication?.documents.findIndex((e) => e.documentTypeID === docID);
            if(_index !== -1) {
                _status.documents[_index].status = "sent";
                setGetMyApplication(_status);
            }

            alert(localize("the-file-has-been-uploaded-successfully"));
        } catch (err) {
            console.error("Err: upload", err);
            alert(localize("something-went-wrong"));
        }
    };

    const onFileChange = (event: ChangeEvent<HTMLInputElement>, documentTypeID: string) => {
        if (event.target.files && event.target.files.length > 0) {
            handleUploadDocument(documentTypeID, event.target.files[0]);
        }
    };
    
    const toggleDocuments = () => {
        setShowDocuments(!showDocuments);
    };

    const handleDownload = (url: string) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = uuid();
                document.body.appendChild(link);
    
                link.click();
    
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const downloadDocument = (documentID: string) => {
        RESTService.action("ExportApplicantDocument", {
            documentID: documentID
        }).then((res) => {
            handleDownload(res.downloadURL);
        }).catch((err) => {
            console.error("ExportDocumentr Err:", err);
            
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const renderInfoContainer = () => {
        const updatedAt = getMyApplication?.updatedAt;
        const formattedDate = updatedAt ? moment(updatedAt).format("DD.MM.YYYY") : "";

        let infoText = "";

        if(getMyApplication?.status === "documentSent") {
            infoText = localize("documents-sent");
        }

        if(getMyApplication?.status === "documentWaiting") {
            infoText = localize("send-documents");
        }

        if(getMyApplication?.status === "reuploadDocumentWaiting") {
            infoText = localize("missing-documents-are-waiting");
        }

        return <div
            className={classes.infoContainer}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {infoText}
            </Text>
            <Text
                variant="body2-medium"
                color="textSecondary"
            >
                {formattedDate}
            </Text>
        </div>;
    };

    const renderDocumentIcon = () => {
        let statusColor = "";

        switch(getMyApplication?.status) {
        case "documentWaiting":
            statusColor = colors.warning;
            break;
        case "documentSent":
            statusColor = colors.success;
            break;
        case "reuploadDocumentWaiting":
            statusColor = colors.warning;
            break;
        default:
            statusColor = colors.primary;
            break;
        }
        return <div
            className={classes.chartContainer}
            style={{
                padding: spaces.inline / 4,
                borderWidth: borders.line,
                borderColor: statusColor
            }}
        >
            <DocumentFullIcon
                color={statusColor}
                size={20}
            />
        </div>;
    };

    const renderMenuIcon = () => {
        let statusColor = colors.textGrey;
        let textDocument = localize("install");

        switch(getMyApplication?.status) {
        case "documentWaiting":
            statusColor = colors.warning;
            textDocument = localize("install");
            break;
        case "documentSent":
            statusColor = colors.success;
            textDocument = localize("uploaded");
            break;
        case "reuploadDocumentWaiting":
            statusColor = colors.warning;
            textDocument = localize("install");
            break;
        default:
            statusColor = colors.textGrey;
            break;
        }

        return <div
            className={classes.menuContainer}
        >
            <div
                className={classes.icon}
            >
                <CircleIcon
                    color={statusColor}
                    size={26}
                />
            </div>
            <div>
                <Text
                    variant="body3-regular"
                    color="textSecondary"
                >
                    {textDocument}
                </Text>
            </div>
        </div>;
    };

    const renderAllSendDocument = () => {
        return <div
            className={classes.allSendDocumentContainer}
            style={{
                marginTop: spaces.content 
            }}
            onClick={toggleDocuments}
        >
            <div>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {localize("see-all-documents")}
                </Text>
            </div>
            <div>
                {
                    showDocuments ? 
                        <ArrowDownIcon
                            color={colors.primary}
                        />
                        : 
                        <RoundArrowIcon
                            color={colors.primary}
                        />
                }
            </div>
        </div>;
    };

    const renderDocuments = () => {
        return <div>
            {getMyApplication?.documents.map((item: DocumentObjectType, index: number) => {
                let statusText = <div></div>;
    
                if (item.status === "sent") {
                    statusText = <div>
                        <Text
                            variant="body-regular"
                            color="success"
                        >
                            {localize("sent")}
                        </Text>
                    </div>;
                    
                } else if (item.status === "approved") {
                    statusText = <div>
                        <Text
                            variant="body-regular"
                            color="success"
                        >
                            {localize("approved")}
                        </Text>
                    </div>;
                } else if (item.status === "rejected") {
                    statusText = <div>
                        <Text
                            variant="body-regular"
                            color="warning"
                        >
                            {localize("rejected-document")}
                        </Text>
                    </div>;
                }
                
                return <div
                    key={index}
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content,
                    }}
                >
                    <div
                        className={classes.documentContainer}
                    >
                        <Text
                            variant="body3-regular"
                            color="textSecondary"
                        >
                            {item.title}
                        </Text>
                        <div
                            className={classes.documentContent}
                            style={{
                                gap: spaces.content
                            }}
                        >
                            {statusText}
                            {
                                item.status === "not-sent" && <div>
                                    <input
                                        onChange={(e) => onFileChange(e, item.documentTypeID)}
                                        id={`file-upload-${index}`}
                                        className={classes.input}
                                        type="file"
                                    />
                                    <label
                                        htmlFor={`file-upload-${index}`}
                                    >
                                        <PlusIcon
                                            color={colors.greyBase}
                                            size={15}
                                        />
                                    </label>
                                </div>
                            }
                            {
                                item.status === "sent" && <div
                                    className={classes.statusControll}
                                    style={{
                                        gap: spaces.content
                                    }}
                                >
                                    <input
                                        onChange={(e) => onFileChange(e, item.documentTypeID)}
                                        id={`file-upload-${index}`}
                                        className={classes.input}
                                        type="file"
                                    />
                                    <label
                                        htmlFor={`file-upload-${index}`}
                                    >
                                        <PencilEditIcon
                                            color={colors.greyBase}
                                            size={15}
                                        />
                                    </label>
                                    <div
                                        className={classes.heightLine}
                                        style={{
                                            borderWidth: borders.line,
                                            borderColor: colors.stroke
                                        }}
                                    />
                                    <div
                                        onClick={() => downloadDocument(item._id)}
                                    >
                                        <DownloadIcon
                                            color={colors.greyBase}
                                            size={15}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                item.status === "approved" && <div
                                    className={classes.statusControll}
                                    style={{
                                        gap: spaces.content
                                    }}
                                >
                                    <PencilEditIcon
                                        color={colors.greyBase}
                                        size={15}
                                    />
                                    <div
                                        className={classes.heightLine}
                                        style={{
                                            borderWidth: borders.line,
                                            borderColor: colors.stroke
                                        }}
                                    />
                                    <div
                                        onClick={() => downloadDocument(item._id)}
                                    >
                                        <DownloadIcon
                                            color={colors.greyBase}
                                            size={15}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                item.status === "rejected" && <div
                                    className={classes.statusControll}
                                    style={{
                                        gap: spaces.content
                                    }}
                                >
                                    <PencilEditIcon
                                        color={colors.greyBase}
                                        size={15}
                                    />
                                    <div
                                        className={classes.heightLine}
                                        style={{
                                            borderWidth: borders.line,
                                            borderColor: colors.stroke
                                        }}
                                    />
                                    <div
                                        onClick={() => downloadDocument(item._id)}
                                    >
                                        <DownloadIcon
                                            color={colors.greyBase}
                                            size={15}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>;
            })}
        </div>;
    };
    
    return <div
        style={{       
            padding: `${spaces.container}px ${spaces.containerXLarge / 2}px`,
            marginBottom: spaces.container,
        }}
    >
        <div
            className={classes.container}
        >
            <div
                className={classes.content}
                style={{
                    gap: spaces.content 
                }}
            >
                {renderDocumentIcon()}
                {renderInfoContainer()}
            </div>
            {renderMenuIcon()}
        </div>
        {renderAllSendDocument()}
        {showDocuments && renderDocuments()}
    </div>;
};

export default SendDocumentCard;
