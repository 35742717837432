import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const InfoCircle = ({
    color= "#9AA6B8",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M29 15c0 7.732-6.268 14-14 14S1 22.732 1 15 7.268 1 15 1s14 6.268 14 14Zm-14 8.05c.58 0 1.05-.47 1.05-1.05v-8.4a1.05 1.05 0 1 0-2.1 0V22c0 .58.47 1.05 1.05 1.05ZM15 8a1.4 1.4 0 1 1 0 2.8A1.4 1.4 0 0 1 15 8Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default InfoCircle;
