import {
    createUseStyles
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        display: "flex"
    },
    content: {
        flexDirection: "column",
        display: "flex"
    },
    yesNoAnswerContianer: {
        display: "flex",
        flexDirection: "row"
    },
    header: {
        alignItems: "center",
        flexDirection: "row",
        cursor: "pointer",
        display: "flex",
    },
    workTypeContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    line: {
        width: "99%",
    }
});
