import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        borderBottomStyle: "solid",
        borderRightStyle: "solid",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        position: "fixed",
        userSelect: "none",
        display: "flex",
        minWidth: 300,
        zIndex: 9999,
        bottom: 0,
        left: 0,
        top: 0,
        "@media screen and (min-width: 601px)": {
            display: "none"   
        }
    },
    verticalLine: {
        borderRadius: 10,
        height: 20,
        width: 6
    },
    topContainer: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    headerTitlesContainer: {
        flexDirection: "column",
        alignItems:"center",
        display: "flex"
    },
    headerTitles: {
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        display: "flex"
    },
    headerSection: {
        textOverflow: "ellipsis",
        flexDirection: "column",
        whiteSpace: "nowrap",
        textAlign: "center",
        overflow: "hidden",
        display: "flex"
    },
    navSectionContainer: {
        justifyContent: "space-around",
        textOverflow: "ellipsis",
        flexDirection: "column",
        whiteSpace: "nowrap",
        textAlign: "center",
        overflow: "hidden",
        display: "flex"
    },
    navButtonsContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems:"center",
        display: "flex"
    },
    sizeButtonContainer: {
        boxSizing: "border-box",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        cursor: "pointer",
        display: "flex"
    },
    footerButtonsContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        overflow: "hidden",
        display: "flex"
    },
    horizontalLine: {
        alignSelf: "center",
        display: "flex",
        width: "12rem",
        height: 1
    },
    logoContainer: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: "center",
        overflow: "hidden"
    },
    exitButton: {
        justifyContent: "center",
        textOverflow: "ellipsis",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        textAlign: "center",
        overflow: "hidden",
        display: "flex"
    },
    loginButton: {
        justifyContent: "center",
        textOverflow: "ellipsis",
        boxSizing: "border-box",
        whiteSpace: "nowrap",
        flexDirection: "row",
        textAlign: "center",
        overflow: "hidden",
        display: "flex",
    },
    footerButton: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 23,
        width: 23,
        "&:hover": {
            opacity: 0.8
        }
    }
});
export default useStyles;