import React, {
    useEffect 
} from "react";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    PrivacyPolicyPageProps 
} from "./types";

const PrivacyPolicy = ({
    setHeaderTitle
}: PrivacyPolicyPageProps) =>  {
    const {
        spaces
    } = IOCoreTheme.useContext();

    useEffect(() => {
        setHeaderTitle("privacy-policy");
    }, []);

    const PrivacyPolicyText = () => {
        return <div
            style={{
                marginBottom: spaces.content
            }}
        >
            <Text
                variant='body2-regular'
                color='textDark'
            >
                Bu Gizlilik Bildirimi, Türkiye Cumhuriyeti kanunlarına göre kurulmuş bir tüzel kişilik olan Hangisi İnternet ve Bilgi Teknolojileri A.Ş. için geçerlidir.
                Bu web sitesini ziyaret etmeniz ve bu site vasıtasıyla sunduğumuz hizmetlerden yararlanmanız sırasında size ve talep ettiğiniz hizmetlere ilişkin olarak elde ettiğimiz bilgilerin
                ne şekilde kullanılacağı ve korunacağı işbu ‘Gizlilik Bildiriminde belirtilen şartlara tabidir. Bu web sitesini ziyaret etmekle ve bu site vasıtasıyla sunduğumuz hizmetlerden
                yararlanmayı talep etmekle işbu ‘Gizlilik Bildirimi’nde belirtilen şartları kabul etmektesiniz.

                İşin Olacak (Gizlilik Bildirimi’nde "biz" veya "bizim" olarak anılabilir), gizlilik kurallarına önem veren bir kurumsal bir firma olup, İşin Olacak Ağı içerisinde
                olarak bu internet sitesini hizmete sunmaktadır. Bu Gizlilik Bildirimi’nde internet siteleri aracılığı ile toplanan ziyaretçi bilgilerinin nasıl toplandığı,işlendiği ve korunduğu
                açıklanmaktadır.

                Bu Gizlilik Bildirimi’nde bilgileriniz zaman zaman “kişisel veri” olarak ifade edilmektedir. Ayrıca internet sayfamızda kişisel verilerinizin toplanması,korunması ve depolanmasına
                ilişkin olarak “işleme” ifadesi kullanılabilir.
            </Text>
        </div>;
    };

    return <div>
        {PrivacyPolicyText()}
    </div>;
};

export default PrivacyPolicy;
