import {
    useEffect, 
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    IOCore
} from "isinolacak-web-cl";
import {
    getBrowserLanguage,
    getBrowserLanguageFull,
    getBrowserTheme,
    getErrorText
} from "./utils";
import {
    GlobalStateProvider,
    NavbarContext
} from "./context";
import Navigation from "./navigation";
import locales from "./locales";
import {
    themes
} from "./theme";
import {
    RESTService 
} from "./services/restAPI";
import {
    ChatService 
} from "./services/chat";
import {
    useGlobalState
} from "./context/globalState";
import {
    useNavigate 
} from "react-router-dom";
import {
    CheckVersionResponseType
} from "./services/restAPI/actions/checkVersion/types";
import {
    InitialConfigsResponseType
} from "./services/restAPI/actions/initialConfigs/types";
import {
    EmailVerificationContainer 
} from "./components";
import {
    IsErrorType 
} from "./pages/auth/login/types";

// TODO: Moment will be set to user time zone preferences (or user device infos.).
const userLanguage = window.localStorage.getItem("userLanguage");

IOCoreLocale.locales = locales;
IOCoreLocale.prepare({
    initialLanguage: userLanguage ?? "tr-TR",
    locales: locales
});

IOCoreTheme.themes = themes;
IOCoreTheme.prepare({
    initialThemeKey: "light",
    themes: themes
});

const App = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {
        localize 
    } = IOCoreLocale.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const [isError, setIsError] = useState<IsErrorType>({
        passwordError: null,
        customError: null,
        mailError: null
    });

    useEffect(() => {
        init();
    }, []);

    const handleMe = () => {
        RESTService.action("Me", {
        }).then((res) => {
            const profilePayload = res.profilePayload;
            const companyPayload = res.companyPayload;
    
            window.localStorage.setItem("userLanguage",profilePayload.language);
            window.localStorage.setItem("userType", profilePayload.type);
    
            if (profilePayload.type === "worker") {
                const userData = {
                    phoneAreaCode: {
                        countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                        country: profilePayload.phoneAreaCode.country,
                        value: profilePayload.phoneAreaCode.value,
                        _id: profilePayload.phoneAreaCode._id
                    },
                    occupation: {
                        localizedText: profilePayload.occupation.localizedText,
                        _id: profilePayload.occupation._id
                    },
                    isMailVerified: profilePayload.isMailVerified,
                    profilePhotoURL: profilePayload.profilePhotoURL,
                    firstName: profilePayload.firstName,
                    fullName: profilePayload.fullName,
                    lastName: profilePayload.lastName,
                    language: profilePayload.language,
                    userID: profilePayload.userID,
                    phone: profilePayload.phone,
                    type: profilePayload.type,
                    mail: profilePayload.mail
                };
    
                setGlobalState({
                    user: userData
                });
    
                window.localStorage.setItem("user", JSON.stringify(userData));
            }
            if (profilePayload.type === "employer" && companyPayload) {
                const userData = {
                    isMailVerified: profilePayload.isMailVerified,
                    profilePhotoURL: profilePayload.profilePhotoURL,
                    firstName: profilePayload.firstName,
                    fullName: profilePayload.fullName,
                    language: profilePayload.language,
                    lastName: profilePayload.lastName,
                    userID: profilePayload.userID,
                    phone: profilePayload.phone,
                    type: profilePayload.type,
                    mail: profilePayload.mail,
                    phoneAreaCode: {
                        countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                        country: profilePayload.phoneAreaCode.country,
                        value: profilePayload.phoneAreaCode.value,
                        _id: profilePayload.phoneAreaCode._id
                    }
                };
                const companyData = {
                    phoneAreaCode: {
                        countryTranslationKey: companyPayload.phoneAreaCode.countryTranslationKey,
                        flagUnicode: companyPayload.phoneAreaCode.flagUnicode,
                        country: companyPayload.phoneAreaCode.country,
                        value: companyPayload.phoneAreaCode.value,
                        _id: companyPayload.phoneAreaCode._id
                    },
                    district: {
                        _id: companyPayload.district._id,
                        localizedText: companyPayload.district.localizedText
                    },
                    occupation: {
                        _id: companyPayload.occupation._id,
                        localizedText: companyPayload.occupation.localizedText
                    },
                    sector: {
                        _id: companyPayload.sector._id,
                        localizedText: companyPayload.sector.localizedText
                    },
                    city: {
                        _id: companyPayload.city._id,
                        localizedText: companyPayload.city.localizedText
                    },
                    isMailVerified: profilePayload.isMailVerified,
                    mersisNo: companyPayload.mersisNo,
                    photoURL: companyPayload.photoURL,
                    address: companyPayload.address,
                    website: companyPayload.website,
                    title: companyPayload.title,
                    phone: companyPayload.phone,
                    mail: companyPayload.mail,
                    _id: companyPayload._id
                };
                setGlobalState({
                    user: userData,
                    company: companyData
                });
                window.localStorage.setItem("company", JSON.stringify(companyData));
                window.localStorage.setItem("user", JSON.stringify(userData));
            }
    
        }).catch((err) => {
            console.error("Me Err:", err);
            //setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
            setIsError({
                passwordError: null,
                customError: localize("login-error"),
                mailError: null
            });
        });
    };

    const init = async () => {
        const storedUser = window.localStorage.user;
        const storedCompany = window.localStorage.company;
        let _globalState = JSON.parse(JSON.stringify(globalState));
        if(storedUser) {
            if(JSON.parse(storedUser).isMailVerified) {
                _globalState = {
                    ..._globalState,
                    user: JSON.parse(storedUser)
                };
            } else {
                handleMe();
            }
        }
        if(storedCompany) {
            _globalState = {
                ..._globalState,
                company: JSON.parse(storedCompany)
            };
        }
        
        getVersion();
        setGlobalState(_globalState);

        RESTService.addEventListener("GO_TO_LOGOUT", (z) => {
            window.localStorage.clear();
            setGlobalState({
                ...globalState,
                company: null,
                user: null
            });
            navigate("/auth/login");
            ChatService.disconnect();
        });
    };

    const localizePrepare = (configs: InitialConfigsResponseType) => {
        let lang =  userLanguage ?? getBrowserLanguageFull();
        lang = lang.replace("_", "-");

        let _locales = JSON.parse(JSON.stringify(locales));
        const indexOfSelectedLang = _locales.findIndex((langData: typeof locales[0]) => langData.code === lang);

        configs.localizations.forEach((locale: {
                key: string;
                value: string;
            }) => {
            _locales[indexOfSelectedLang].translations[locale.key] = locale.value;
        });

        IOCoreLocale.locales = _locales;
        IOCoreLocale.prepare({
            initialLanguage: lang,
            locales: _locales
        });
    };

    const getVersion = async () => {
        let lang = userLanguage ?? getBrowserLanguageFull();
        lang = lang.replace("_", "-");
        RESTService.action("CheckVersion", {
        }).then( (res) => {
            const storedVersionStringify = window.localStorage.getItem("verison");
            
            if(!storedVersionStringify) {
                getInitialConfigs(res);
                return;
            }
            
            const storedVersion = JSON.parse(storedVersionStringify);
            
            if(Number(storedVersion.configVersion) < Number(res.webVersion)) {
                getInitialConfigs(res);
                return;
            }
            
            const storedLocalize = window.localStorage.getItem("configs");
            if(storedLocalize) {
                localizePrepare(JSON.parse(storedLocalize));
            }
            
            window.localStorage.setItem("version", JSON.stringify(res));

        }).catch((err) => {
            console.error(err);
        });
    };

    const getInitialConfigs = (version: CheckVersionResponseType) => {
        let lang = userLanguage ?? getBrowserLanguageFull();
        lang = lang.replace("_", "-");
        RESTService.action("InitialConfigs", {
            language: lang
        }).then((res) => {
            localizePrepare(res);

            window.localStorage.setItem("configs", JSON.stringify(res));
            window.localStorage.setItem("version", JSON.stringify(version));
        }).catch((err) => {
            if(err.code && err.code === 400 && err.message && err.message.indexOf("language") !== -1) {
                const currentText = lang.split("-");

                window.localStorage.setItem("language", currentText[0] === "tr" ? "tr-TR" : "en-US");

                getVersion();
            } else {
                console.error(err);
            }
        });
    };

    const renderEmailVerification  =() =>{
        if(!globalState.user){
            return null;
        }

        if(globalState.user?.isMailVerified){
            return null;
        }

        return <div
            className={classes.emailVeriicationContainer}
        >
            <EmailVerificationContainer/>
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderEmailVerification()}
        <Navigation/>
    </div>;
};

const IOCoreContextP = () => {
    const browserLang: string = getBrowserLanguage();
    const browserTheme = getBrowserTheme();

    return <RESTService.Provider>
        <ChatService.Provider>
            <IOCore.Provider>
                <GlobalStateProvider>
                    <NavbarContext.Provider>
                        <App/>
                    </NavbarContext.Provider>
                </GlobalStateProvider>
            </IOCore.Provider>
        </ChatService.Provider>
    </RESTService.Provider>;
};
export default IOCoreContextP;
