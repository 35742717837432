import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FacebookCircle = ({
    size = 30,
    color = "black",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M29 14.5C29 6.496 22.504 0 14.5 0S0 6.496 0 14.5c0 7.018 4.988 12.861 11.6 14.21v-9.86H8.7V14.5h2.9v-3.625A5.08 5.08 0 0 1 16.675 5.8H20.3v4.35h-2.9c-.797 0-1.45.652-1.45 1.45v2.9h4.35v4.35h-4.35v10.077C23.273 28.203 29 22.026 29 14.5Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FacebookCircle;
