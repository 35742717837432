import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    Text
} from "isinolacak-web-cl";
import {
    AssociationsTypes
} from "./types";
import {
    CircleExitIcon 
} from "../../../../../../../../../../assets/svgr";

const Associations = ({
    onChangeOssiciationName,
    removeAssociations,
    associations,
    showExitIcon,
    setErrors,
    errors,
    index
}: AssociationsTypes) =>  {
    const classes = useStyles();
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderAssociationOrOrganization = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.remove}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant='body-regular'
                    color='textDark'
                >
                    {localize("association-or-organization-membership")}
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeAssociations(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <TextInput
                infoText={errors.includes(`associations-${index}-associations-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `associations-${index}-associations-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                isError={errors.some(err => err === `associations-${index}-associations-length-error`)}
                onChangeText={(text) => onChangeOssiciationName(text, index)}
                initialValue={associations[index]}
                placeholder={localize("add")}
                style={{
                    marginTop: spaces.content
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderAssociationOrOrganization()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default Associations;
