import {
    Fragment,
    useEffect,
    useState 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Text
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    RoundArrowIcon
} from "../../../../assets/svgr";
import {
    useNavigate 
} from "react-router-dom";
import {
    useGlobalState 
} from "../../../../context/globalState";
import {
    RESTService 
} from "../../../../services/restAPI";
import {
    GetCurrentJobSeekersResponseType
} from "../../../../services/restAPI/actions/getCurrentJobSeekers/types";
import {
    getAdvertsDataForDashboardGraphMonthlyObjectType,
    getAdvertsDataForDashboardGraphWeeklyObjectType,
    getAdvertsDataForDashboardGraphDailyObjectType,
    GetAdvertsDataForDashboardResponseType 
} from "../../../../services/restAPI/actions/getAdvertsDataForDashboard/types";
import FeaturedCandidateCard from "./components/featuredCandidateCard";
import CustomTooltip from "./components/customTooltip";
import GraphChip from "./components/graphChip";
import {
    ResponsiveContainer, 
    CartesianGrid,
    AreaChart,
    Tooltip, 
    XAxis,
    YAxis, 
    Area
} from "recharts";
import {
    aggregateDailyData,
    translateMonth,
    getFullDate,
} from "../../../../utils";
import {
    CUSTOM_HOUR_TICKS,
    DATE_AXIS_VALUES
} from "./constants";

const Home = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const userFirstName = globalState?.user?.firstName;

    const [activeAdvertLogData, setActiveAdvertLogData] = useState<GetAdvertsDataForDashboardResponseType["activeAdvertLog"]>();
    const [monthlyGraphData, setMonthlyGraphData] = useState<Array<getAdvertsDataForDashboardGraphMonthlyObjectType>>();
    const [weeklyGraphData, setWeeklyGraphData] = useState<Array<getAdvertsDataForDashboardGraphWeeklyObjectType>>();
    const [dailyGraphData, setDailyGraphData] = useState<Array<getAdvertsDataForDashboardGraphDailyObjectType>>();
    const [currentJobSeekers, setCurrentJobSeekers] = useState<GetCurrentJobSeekersResponseType>([]);
    const [dateFilter, setDateFilter] = useState<keyof typeof DATE_AXIS_VALUES>("monthly");
    const [isCurrentJobSeekersLoading, setIsCurrentJobSeekersLoading] = useState(true);
    const [isGraphDataLoading, setIsGraphDataLoading] = useState(true);

    useEffect(() => {
        getCurrentJobSeekers();
    },[]);

    useEffect(() => {
        getGraphData();
    },[]);

    const getGraphData = () => {
        let params = {
        };

        if(!isGraphDataLoading) {
            setIsGraphDataLoading(true);
        }

        RESTService.action("GetAdvertsDataForDashboard", params)
            .then((res) => {
                setActiveAdvertLogData(res?.activeAdvertLog);
                setMonthlyGraphData(res?.chartData?.monthly);
                setWeeklyGraphData(res?.chartData?.weekly);
                setDailyGraphData(aggregateDailyData(res?.chartData?.daily));
            })
            .catch((err) => {
                console.error("GetAdvertsDataForDashboard Err:", err);
            }).finally(() => {
                setIsGraphDataLoading(false);
            });  
    };

    const getCurrentJobSeekers = () => {
        let params = {
        };

        if(!isCurrentJobSeekersLoading) {
            setIsCurrentJobSeekersLoading(true);
        }

        RESTService.action("GetCurrentJobSeekers", params)
            .then((res) => {
                setCurrentJobSeekers(res as unknown as GetCurrentJobSeekersResponseType);
            })
            .catch((err) => {
                console.error("GetCurrentJobSeekers Err:", err);
            }).finally(() => {
                setIsCurrentJobSeekersLoading(false);
            });  
    };

    const renderWelcomeContainer = () => {
        return <div 
            className={classes.renderWelcomeContainer}
            style={{
                marginBottom: spaces.containerLarge,
                gap: spaces.inline
            }}
        >
            <Text 
                variant="header5-regular"
                color="black"
            >
                {localize("welcome")}, {userFirstName} 👋
            </Text>
            <Text 
                variant="body3-regular"
                color="textGrey"
            >
                {getFullDate()}
            </Text>
        </div>;
    };

    const renderGraphChipContainer = () => {
        return <div
            className={classes.graphChipContainer}
            style={{
                marginBottom: spaces.item,
                gap: spaces.content
            }}
        >
            <GraphChip 
                selected={dateFilter === "monthly"}
                disabled={isGraphDataLoading} 
                title={localize("monthly")} 
                onClick={() => {
                    setDateFilter("monthly");   
                }}
            />
            <GraphChip 
                selected={dateFilter === "weekly"}
                disabled={isGraphDataLoading} 
                title={localize("weekly")}
                onClick={() => {
                    setDateFilter("weekly");   
                }}
            />
            <GraphChip
                selected={dateFilter === "daily"}
                disabled={isGraphDataLoading}  
                title={localize("daily")}
                onClick={() => {
                    setDateFilter("daily");   
                }}
            />
        </div>;
    };

    const renderGraphContainer = () => {
        return <div
            className={classes.renderGraphContainer}
            style={{
                paddingLeft: spaces.container * 2,
                paddingBottom: spaces.container,
                backgroundColor: colors.white,
                paddingTop: spaces.container,
                borderRadius: radiuses.hard,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                paddingRight: 0
            }}>
            {renderGraphChipContainer()}
            <div className={classes.graphContainer}>
                <ResponsiveContainer 
                    minHeight={1}
                    minWidth={1}
                    height="100%"
                    width="100%" 
                >
                    <AreaChart data={
                        (dateFilter === "monthly" ? monthlyGraphData : 
                            dateFilter === "weekly" ? weeklyGraphData :
                                dailyGraphData)
                    }
                    margin={{
                        top: spaces.inline, 
                        bottom: 0, 
                        right: 0, 
                        left: 0, 
                    }}>
                        <defs>
                            <linearGradient 
                                id="colorUv" 
                                x1="0" 
                                y1="0" 
                                x2="0"
                                y2="1"
                            >
                                <stop 
                                    stopColor={colors.primary} 
                                    stopOpacity={1}
                                    offset="40%"
                                />
                                <stop 
                                    stopColor={colors.white} 
                                    stopOpacity={0.1}
                                    offset="100%" 
                                />
                            </linearGradient>
                        </defs>
                        <XAxis
                            unit={dateFilter === "weekly" ? `.${localize("week")}` : ""}
                            ticks={dateFilter === "daily" ? CUSTOM_HOUR_TICKS : undefined} 
                            reversed={dateFilter === "weekly" ? true : false}
                            dataKey={DATE_AXIS_VALUES[dateFilter]["XAxis"]}
                            interval="preserveStartEnd"
                            tickSize={10}
                            axisLine={{
                                strokeWidth: borders.indicator / 1.5,
                                stroke: colors.greyBase
                            }}
                            tick={{
                                fontSize: spaces.content / 1.2,
                                fill: colors.greyBase
                            }}
                            tickFormatter={(tick) => {
                                if(dateFilter === "monthly") {
                                    return translateMonth(tick);
                                } else {
                                    return tick;
                                }
                            }}
                            style={{
                                fontSize: spaces.content * 1.2
                            }}
                        />
                        <YAxis
                            orientation="right" 
                            style={{
                                fontSize: spaces.content * 1.2
                            }}
                        />
                        <CartesianGrid 
                            stroke={colors.stroke} 
                            strokeDasharray="0" 
                            vertical={false} 
                        />
                        <Tooltip 
                            formatter={(value, name) => [`${value}`, `${name}`]}
                            content={<CustomTooltip 
                                dateFilter={dateFilter}
                            />}
                        />
                        <Area 
                            dataKey={DATE_AXIS_VALUES[dateFilter]["YAxis"]}
                            stroke={colors.greyBase} 
                            fill="url(#colorUv)"
                            fillOpacity={1} 
                            type="linear" 
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>;
    };

    const renderGeneralData = () => {
        return <div 
            className={classes.renderGeneralData}
            style={{
                gap: spaces.content
            }}
        >
            <div 
                className={classes.generalDataContainer}
                style={{
                    backgroundColor: colors.backgroundLight,
                    borderWidth: borders.indicator,
                    borderRadius: radiuses.hard,
                    borderColor: colors.stroke,
                    padding: spaces.container,
                    gap: spaces.content
                }}
            >
                <div 
                    className={classes.generalDataTextContainer}
                    style={{
                        gap: spaces.inline / 1.5
                    }}
                >
                    <div 
                        className={classes.generalDataText}
                    >
                        <Text
                            variant="body3-regular"
                            color="black"
                        >
                            {localize("active-adverts")}
                        </Text>
                        <div 
                            className={classes.generalDataRedirectIcon}  
                            onClick={() => {
                                navigate("/app/myactions");
                            }}
                        >
                            <RoundArrowIcon 
                                color={colors.primary} 
                                size={14}
                            />
                        </div>
                    </div>
                    <Text
                        variant="header4-medium"
                        color="primary" 
                    >
                        {activeAdvertLogData?.activeAdvertCount}
                    </Text>
                </div>
                <div
                    className={classes.generalDataTextContainer}
                    style={{
                        gap: spaces.inline / 1.5
                    }}
                >
                    <Text 
                        variant="body3-regular"
                        color="black"
                    >
                        {localize("view-counts")}
                    </Text>
                    <Text
                        color="primary" 
                        variant="header4-medium"
                    >
                        {activeAdvertLogData?.activeAdvertViewCount}
                    </Text>
                </div>
            </div>
            <div 
                className={classes.generalDataContainer}
                style={{
                    backgroundColor: colors.backgroundLight,
                    borderWidth: borders.indicator,
                    borderRadius: radiuses.hard,
                    borderColor: colors.stroke,
                    padding: spaces.container,
                    gap: spaces.content
                }}
            >
                <div 
                    className={classes.generalDataTextContainer}
                    style={{
                        gap: spaces.inline / 1.5
                    }}
                >
                    <Text 
                        variant="body3-regular"
                        color="black"
                    >
                        {localize("number-of-applications")}
                    </Text>
                    <Text
                        variant="header4-medium"
                        color="primary" 
                    >
                        {activeAdvertLogData?.applicationsCount}
                    </Text>
                </div>
                <div
                    className={classes.generalDataTextContainer}
                    style={{
                        gap: spaces.inline / 1.5
                    }}
                >
                    <Text 
                        variant="body3-regular"
                        color="black"
                    >
                        {localize("unreviewed-applications")}
                    </Text>
                    <Text
                        color="primary" 
                        variant="header4-medium"
                    >
                        {activeAdvertLogData?.unExaminedApplication}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderBanner = () => {
        return <div 
            className={classes.bannerContainer}
            style={{
                borderRadius: radiuses.hard
            }}
        >
            <Text 
                variant="header4-medium"
                color="white"
            >
                BANNER 
                {/* //TODO: THIS PART WILL BE REPLACED SOON */}
            </Text>
        </div>;
      
    };

    const renderTopContainer = () => {
        return <div className={classes.renderTopContainer}>
            <Text 
                variant="header5-medium"
                color="black"
            >
                {localize("overview")}
            </Text>
            <div 
                className={classes.generalOverviewContainer}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.container
                }}
            >
                {renderGraphContainer()}
                {renderGeneralData()}
                {renderBanner()}
            </div>
        </div>;
    };

    const renderEmptyContainer = () => {
        return <div 
            className={classes.renderEmptyContainer} 
            style={{
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                marginTop: spaces.container,
                borderColor: colors.stroke, 
                borderWidth: borders.line,
                padding: spaces.container,
                gap: spaces.inline / 1.5
            }}
        >
            <Text
                variant="header5-medium"
                color="black"
            >
                {localize("this-place-is-empty-right-now")}
            </Text>
            <Text
                variant="body3-regular"
                color="grey200"
            >
                {localize("candidates-that-match-will-be-shown")}
            </Text>
        </div>;
    };

    const renderBottomContainer = () => {
        return <div
            className={classes.renderBottomContainer} 
            style={{
                marginTop: spaces.content
            }}
        >
            <div className={classes.generalDataText}>
                <Text
                    variant="header5-medium"
                    color="black"
                >
                    {localize("current-job-seekers")}
                </Text>
                <div
                    className={classes.linkContainer}
                    onClick={() => {
                        navigate("/app/myActions");
                    }}
                >
                    <Text
                        className={classes.link}
                        variant="body3-medium"
                        color="primary"
                    >
                        {localize("all-listings")}
                    </Text>
                </div>
            </div>
            {(!isCurrentJobSeekersLoading && currentJobSeekers.length === 0) ?
                renderEmptyContainer() :
                <div 
                    className={classes.candidatesContainer}
                    style={{
                        marginTop: spaces.content,
                        gap: spaces.container
                    }}
                >
                    {isCurrentJobSeekersLoading ? <Loading/> : currentJobSeekers && currentJobSeekers.map(jobSeeker => {
                        return <Fragment key={jobSeeker.userID}>
                            <FeaturedCandidateCard
                                occupation={jobSeeker.occupation.localizedText}
                                education={jobSeeker.education.localizedText}
                                appliedAt={jobSeeker.appliedAt}
                                candidateName={jobSeeker.fullName}
                                city={jobSeeker.city.localizedText}
                            />
                        </Fragment>;
                    })}
                </div>}
        </div>;
    };
    
    const renderContainer = () => {
        return <div
            className={classes.container}
            style={{
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                paddingBottom: spaces.container * 2,
                paddingTop: spaces.container * 2,
                backgroundColor: colors.grey25,
                gap: spaces.content
            }}
        >
            {renderWelcomeContainer()}
            {renderTopContainer()}
            {renderBottomContainer()}
        </div>;
    };

    const renderResponsiveContainer = () => {
        return <div
            className={classes.responsiveContainer}
            style={{
                paddingBottom: spaces.container * 2,
                paddingRight: spaces.containerLarge,
                paddingLeft: spaces.containerLarge,
                paddingTop: spaces.container * 2,
                backgroundColor: colors.grey25,
                gap: spaces.content
            }}
        >
            {renderWelcomeContainer()}
            {renderTopContainer()}
            {renderBottomContainer()}
        </div>;
    };

    return <Fragment>
        {renderResponsiveContainer()}
        {renderContainer()}
    </Fragment>;
};
export default Home;