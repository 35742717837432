import {
    Navigate,
    Routes,
    Route
} from "react-router-dom";
import EmailPreferences from "../pages/common/emailPreferences";
import WorkerJobDetail from "../pages/common/jobDetails";
import Agreement from "../pages/common/agreement";
import NotFound from "../pages/common/notFound";
import BlogPost from "../pages/common/blogPost";
import Contact from "../pages/common/contact";
import Share from "../pages/common/share";
import Search from "../pages/common/search";
import About from "../pages/common/about";
import Home from "../pages/common/home";
import Blog from "../pages/common/blog";
import FAQ from "../pages/common/faq";
import Auth from "../pages/auth";
import App from "../pages/app";

const Navigation = () => {
    let userType = window.localStorage.getItem("userType");
    userType = "worker";
    return <Routes>
        <Route
            path="/app/*"
            element={<App />}
        />
        <Route
            path="/auth/*"
            element={<Auth />}
        />
        <Route
            path="/blog"
            element={<Blog/>}
        />
        <Route
            path="/blog/:blogID"
            element={<BlogPost/>}
        />
        <Route
            path="/aboutUs"
            element={<About/>}
        />
        <Route
            path="/contactUs"
            element={<Contact/>}
        />
        <Route
            path="/agreement/:agreementType"
            element={<Agreement/>}
        />
        <Route
            path="/faq"
            element={<FAQ/>}
        />
        <Route
            path="/search"
            element={<Search />}
        />
        <Route
            path="/jobDetails"
            element={<WorkerJobDetail/>}
        />
        <Route
            path="/jobDetails/:id"
            element={<WorkerJobDetail />}
        />
        <Route
            path="/notFound"
            element={<NotFound/>}
        />
        <Route
            path="/home"
            element={<Home/>}
        />
        <Route
            path="/"
            element={<Home/>}
        />
        <Route
            path="/share"
            element={<Share/>}
        />
        <Route
            path="/share/:dataType"
            element={<Share/>}
        />
        <Route
            path="/share/:dataType/:dataID"
            element={<Share/>}
        />
        <Route
            path="/*"
            element={<Navigate to="/notFound" />}
        />
        <Route
            path="/emailPreferences"
            element={<EmailPreferences/>}
        />
    </Routes>;
};
export default Navigation;
