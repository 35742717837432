import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    card: {
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        width: "280px",
        "&:hover": {
            transform: "scale(1.02)",
            cursor: "pointer"
        }
    },
    image: {
        objectFit: "cover",
        height: "200px",
        width: "100%"
    }
});


export default useStyles;