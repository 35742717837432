import {
    useEffect,
    useState,
    Fragment,
    useRef,
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    Switcher,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useNavigate
} from "react-router-dom";
import WorkerRegisterProps from "./types";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    md5
} from "js-md5";
import validator from "validator";
import {
    InfoIcon,
    Logo
} from "../../../../../assets/svgr";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../services/restAPI/actions/getOccupation/types";
import {
    GetAreaCodesObjectType
} from "../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    getBrowserLanguageFull, 
    getErrorText
} from "../../../../../utils";

const WorkerRegister = ({
    areaCode: areaCodes,
    setRegisterType
}: WorkerRegisterProps) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [occupations, setOccupations] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [occupationsSearchPage, setOccupationsSearchPage] = useState(1);
    const [searchOccupationText, setSearchOccupationText] = useState("");
    const [isContractSelected, setIsContractSelected] = useState(false);
    const [isPostPermSelected, setIsPostPermSelected] = useState(false);
    const [occupationsLoading, setOccupationsLoading] = useState(false);
    const [areaCode, setAreaCode] = useState<GetAreaCodesObjectType>();
    const [occupationsPage, setOccupationsPage] = useState(1);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [totalDataCount, setTotalDataCount] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errors, setErrors] = useState<string[]>([]);
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [occupation, setOccupation] = useState<GetOccupationResponseObjectType>({
        localizedText: "",
        _id: "",
        key: ""
    });

    const searchDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        getOccupationData();
    }, []);

    useEffect(() => {
        search(searchOccupationText);
    }, [searchOccupationText]);

    const search = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if(searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getOccupationData({
                    language: getBrowserLanguageFull(),
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupationData();
        }
    };

    const handleRegister = () => {
        if(name.length > 55 || name.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-name-length-error");

            setErrors(_errors);
            return;
        }

        if(lastName.length > 33 || lastName.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-last-name-length-error");

            setErrors(_errors);
            return;
        }

        if(!areaCode || !areaCode._id) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-area-code-error");

            setErrors(_errors);
            return;
        }  

        if(!validator.isMobilePhone(phoneNumber)) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-phone-number-error");

            setErrors(_errors);
            return;
        }

        if(!validator.isEmail(email) || email.length > 99) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-email-error");

            setErrors(_errors);
            return;
        }

        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[)!@#$%^&_\-*[\]{};':"<>?,./])[^\s]{8,16}$/.test(password)) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-password-error");

            setErrors(_errors);
            return;
        }

        if(password !== repeatPassword) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-repeat-password-error");

            setErrors(_errors);
            return;
        }

        if(!occupation || !occupation._id.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-occupation-error");

            setErrors(_errors);
            return;
        }

        RESTService.action("Register",{
            isApproveCommercialMessages: isPostPermSelected,
            language: getBrowserLanguageFull(),
            isApproveContracts: isContractSelected,
            occupationID: occupation._id,
            phoneAreaCode: areaCode._id,
            password: md5(password),
            lastName: lastName,
            phone: phoneNumber,
            firstName: name,
            type: "worker",
            mail: email
        })
            .then(() => {
                return navigate("/auth/SmsOTP", {
                    state: {
                        selection: "worker",
                        userMail: email
                    }
                });
            })
            .catch((err) => {
                console.error("Register Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getOccupationData = (searchParams?: GetOccupationRequestType) => {
        if(!occupationsLoading) setOccupationsLoading(true);

        let params: GetOccupationRequestType = {
            language: getBrowserLanguageFull(),
            page:  occupationsPage
        };

        if(searchOccupationText && searchOccupationText.length) {
            params.search = searchOccupationText;
            params.page = occupationsSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        RESTService.action("GetOccupation", params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setOccupations(res.payload);

                if(searchParams?.search || searchOccupationText.length){
                    setOccupationsPage(1);
                } else {
                    setOccupationsPage(params.page ?? 1);
                    setOccupationsSearchPage(1);
                }

                setOccupationsLoading(false);
            })
            .catch((err) => {
                setOccupationsLoading(false);
                console.error("GetOccupation Err:", err);
            });
    };

    const renderPhoneInput = () => {
        return <div
            className={classes.phoneInputContainer}
            style={{
                gap: spaces.content
            }}
        >
            <SelectBox
                initialSelectedItems={areaCode?._id.length ? [areaCode] : []}
                keyExtractor={(e) => e._id}
                spreadBehaviour='stretch'
                //@ts-ignore
                titleExtractor={(
                    item: GetAreaCodesObjectType
                ) => {
                    return <div 
                        className={classes.areaCodesContainer}>
                        <Text>
                            {item.flagUnicode}
                        </Text>
                        <Text>
                            {item.value}
                        </Text>
                    </div>;
                }}
                infoText={errors.includes("register-area-code-error") ? localize("register-area-code-error") : undefined}
                isError={errors.includes("register-area-code-error")}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    if(selectedItems[0]) {
                        setAreaCode({
                            //@ts-ignore //TODO: Will be fix
                            _id: selectedItems[0]._id,
                            //@ts-ignore
                            country: selectedItems[0].country,
                            //@ts-ignore
                            countryTranslationKey: selectedItems[0].countryTranslationKey,
                            //@ts-ignore
                            flagUnicode: selectedItems[0].flagUniCode,
                            //@ts-ignore
                            value: selectedItems[0].value
                        });
                        let _errors = JSON.parse(JSON.stringify(errors));
                        let nameError = "register-area-code-error";
                        let index = _errors.indexOf(nameError);
                        _errors.splice(index, 1);
                        setErrors(_errors);
                    }
                    
                    closeSheet();
                    onSuccess();
                }}
                data={areaCodes ? areaCodes.payload : []}
            />
            <TextInput
                infoText={errors.includes("register-phone-number-error") ? localize("register-phone-number-error") : undefined}
                isError={errors.includes("register-phone-number-error")}
                placeholder={localize("phone-number")}
                spreadBehaviour="stretch"
                onKeyDown={(e) => {
                    if (!/[0-9]|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
                id="mail-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-phone-number-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);

                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setPhoneNumber(value);
                    if (/[0-9]|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(value)) {
                        setPhoneNumber(value);
                    }
                }}
            />
        </div>;
    };

    const renderInputs = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.content,
                gap: spaces.content / 2
            }}
        >
            <Text
                variant='body2-semiBold'
            >
                {localize("registration-information")}
            </Text>
            <TextInput
                infoText={errors.includes("register-name-length-error") ? localize("register-name-length-error") : undefined}
                isError={errors.includes("register-name-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors(_errors);
                }}
                spreadBehaviour="stretch"
                placeholder={localize("name")}
                id="name-input"
                onChangeText={(value) => {
                    setName(value);
                }}
            />
            <TextInput
                infoText={errors.includes("register-last-name-length-error") ? localize("register-last-name-length-error") : undefined}
                isError={errors.includes("register-last-name-length-error")}
                spreadBehaviour="stretch"
                placeholder={localize("surname")}
                id="lastname-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-last-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);

                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setLastName(value);
                }}
            />
            {
                renderPhoneInput()
            }
            <TextInput
                infoText={errors.includes("register-email-error") ? localize("register-email-error") : undefined}
                isError={errors.includes("register-email-error")}
                spreadBehaviour="stretch"
                placeholder={localize("e-mail")}
                id="email-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-email-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);

                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setEmail(value);
                }}
            />
            <TextInput
                infoText={errors.includes("register-password-error") ? localize("register-password-error") : undefined}
                isError={errors.includes("register-password-error")}
                spreadBehaviour="stretch"
                placeholder={localize("password")}
                id="password-input"
                password={true}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-password-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);

                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setPassword(value);
                }}
            />
            {renderPasswordRules()}
            <TextInput
                infoText={errors.includes("register-repeat-password-error") ? localize("register-repeat-password-error") : undefined}
                isError={errors.includes("register-repeat-password-error")}
                spreadBehaviour="stretch"
                placeholder={localize("password-verification")}
                id="passwordconfirm-input"
                password={true}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-repeat-password-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);

                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRepeatPassword(value);
                }}
            />
            <div
                className={classes.occupationSelectbox}
            >
                <SelectBox
                    infoText={errors.includes("register-occupation-error") ? localize("register-occupation-error") : undefined}
                    initialSelectedItems={occupation?._id.length ? [occupation] : []}
                    isError={errors.includes("register-occupation-error")}
                    titleExtractor={(e) => e.localizedText}
                    isSearchLoading={occupationsLoading}
                    inputTitle={localize("job")}
                    keyExtractor={(e) => e._id}
                    spreadBehaviour='stretch'
                    onSearch={(e) => {
                        setSearchOccupationText(e);
                    }}
                    title={localize("job")}
                    isNeedConfirm={true}
                    isSearchable={true}
                    emptyContent={() => {
                        if(occupationsLoading) {
                            return <div
                                className={classes.selectboxEmpty}
                            >
                                <Loading/>
                            </div>;
                        }

                        return <div
                            className={classes.selectboxEmpty}
                        >
                            <Text>
                                {localize("no-result-found")}
                            </Text>
                        </div>;
                    }}
                    multiSelect={false}
                    disabled={false}
                    onOk={({
                        selectedItems,
                        closeSheet,
                        onSuccess
                    }) => {
                        if(selectedItems[0]) {
                            setOccupation({
                            //@ts-ignore // TODO: Will fix from component library.
                                localizedText: selectedItems[0].localizedText,
                                //@ts-ignore
                                _id: selectedItems[0]._id,
                                key: selectedItems[0].__key
                            });
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "register-occupation-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }
                    
                        closeSheet();
                        onSuccess();
                    }}
                    data={occupations}
                />
            </div>
        </div>;
    };

    const renderPasswordRules = () => {
        const hasSpecialChar = /[)!@#$%^&_\-*[\]{};':"<>?,./]/.test(password);
        const passwordLengthZero = password.length === 0;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasMinLength = password.length >= 8;
        const hasNumber = /\d/.test(password);
    
        const getColor = (
            condition: boolean,
            color: keyof IOCore.ColorsType,
        ): keyof IOCore.ColorsType => {
            return passwordLengthZero ? "greyBase" : condition ? color : "error";
        };
    
        const iconColor = getColor(
            hasMinLength && hasSpecialChar && hasUpperCase && hasLowerCase && hasNumber,
            "success"
        );
    
        return <div
            className={classes.passwordRules}
            style={{
                gap: spaces.content / 2
            }}
        >
            <div>
                <InfoIcon
                    color={colors[iconColor]}
                    size={16}
                />
            </div>
            <Text
                color={getColor(hasMinLength, "success")}
                variant="body3-regular"
            >
                {localize("your-password-must-be-at-least-8-characters")}
                <Text
                    color={getColor(hasUpperCase, "success")}
                    variant="body3-regular"
                >
                    {localize("one-capital-letter")}
                </Text>
                <Text
                    color={getColor(hasLowerCase, "success")}
                    variant="body3-regular"
                >
                    {localize("a-lowercase-letter")}
                </Text>
                <Text
                    color={getColor(hasNumber && hasSpecialChar, "success")}
                    variant="body3-regular"
                >
                    {localize("ıt-must-contain-one-special-character-and-one-number")}
                </Text>
            </Text>
        </div>;
    };

    const renderUserAgreementContainer = () => {
        return <div
            className={classes.userAgreementContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.userAgreementContent}
            >
                <Text
                    variant='body3-medium'
                    color='textGrey'
                    style={{
                        paddingRight: spaces.inline
                    }}
                >
                    {getBrowserLanguageFull() !== "tr-TR" && (
                        <Fragment>
                        I confirm that I consent to the processing of my personal data in the ways specified in the{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Explicit Consent Text
                                </Text>
                            </div>
                            , and I acknowledge that I have read the{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/nondisclosure-agreement`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Information on the Protection of Personal Data
                                </Text>
                            </div>.
                        </Fragment>
                    )}
                    {getBrowserLanguageFull() === "tr-TR" && (
                        <Fragment>
                        Kişisel verilerimin{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            >
                                <Text variant='body3-medium' color='greyBase'>
                                    Açık Rıza Metni’ninde
                                </Text>
                            </div> 
                            {" "}belirtlilen şekillerde işlenmesine verdiğimi,{" "} 
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/nondisclosure-agreement`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Kişisel Verilerin Korunması
                                </Text>
                            </div> 
                            {" "}Hakkında Bilgilendirme’yi okuduğumu kabul ettiğimi onaylıyorum.
                        </Fragment>
                    )}
                </Text>
                <Switcher
                    isActive={isContractSelected}
                    onChange={() => {
                        setIsContractSelected(!isContractSelected);
                    }}
                />
            </div>
            <div
                className={classes.userAgreementContent}
            >
                <Text
                    variant='body3-medium'
                    color='textGrey'
                >
                    {getBrowserLanguageFull() !== "tr-TR" && (
                        <Fragment>
                            "I give permission for <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            ><Text variant='body3-medium' color='greyBase'>commercial electronic messages</Text></div> to be sent by 'İşin olacak'." 
                        </Fragment>
                    )}
                    {getBrowserLanguageFull() === "tr-TR" && (
                        <Fragment>
                            “İşin olacak” tarafından <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            ><Text variant='body3-medium' color='greyBase'>ticari elektronik ileti</Text></div> gönderilmesine izin veriyorum.
                        </Fragment>
                    )}
                </Text>
                <Switcher
                    isActive={isPostPermSelected}
                    onChange={() => {
                        setIsPostPermSelected(!isPostPermSelected);
                    }}
                />
            </div>
        </div>;
    };

    const renderButtons = () => {
        return <div
            className={classes.buttonContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                disabled={isContractSelected && errors.length < 1 === true ? false: true}
                title={localize("register")}
                spreadBehaviour="stretch"
                color="secondary"
                size="large"
                onClick={() => {
                    handleRegister();
                }}
            />
            <Button
                title={localize("register-with-company-account")}
                spreadBehaviour="stretch"
                variant="outline"
                color="secondary"
                size="large"
                onClick={() => {
                    setRegisterType("employer");
                }}
            />
            <div 
                style={{
                    marginTop: spaces.inline / 1.5
                }}
            >
                <Text
                    variant="body3-regular"
                >
                    {localize("already-have-an-account")}{" "}
                </Text>
                <div 
                    className={classes.alreadyHaveAnAccount}
                    style={{
                        textDecorationColor: colors.primary
                    }}
                    onClick={() => {
                        navigate("/auth/login");
                    }}
                >
                    <Text
                        variant="body3-semiBold"
                        color="primary"
                    >
                        {localize("login")}
                    </Text>
                </div>
            </div>
        </div>;
    };

    return <div
        className={classes.container}
    >
        <div
            className={classes.loginContainer}
            style={{
                paddingBottom: spaces.container,
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                paddingTop: spaces.inline
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline * 2,
                    marginTop: spaces.inline * 3
                }}
            >
                <Logo size={120} />
            </div>
            {renderInputs()}
            {renderUserAgreementContainer()}
            {renderButtons()}
        </div>
    </div>;
};
export default WorkerRegister;