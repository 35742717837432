import React, {
    useEffect,
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    TextInput,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    GetAdvertPAQResponseObjectTypes 
} from "../../../../../../services/restAPI/actions/getAdvertPAQ/types";
import {
    PAQTypes
} from "./types";
import {
    RoundArrowIcon,
    InfoIcon
} from "../../../../../../assets/svgr";
import {
    getErrorText 
} from "../../../../../../utils";

const PAQ = ({
    getAdvertWithDetailData,
    setPAQAnswers,
    setIsVisible,
    PAQAnswers,
    setViews,
    jobID
}: PAQTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getAdvertsPAQ, setGetAdvertsPAQ] = useState<Array<GetAdvertPAQResponseObjectTypes>>([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        handleGetAdvertsPAQ();
    }, []);

    const onChangeYesNO = (answer: boolean, id: string) => {
        let _paqAnswers = JSON.parse(JSON.stringify(PAQAnswers));

        let _index = PAQAnswers.findIndex(e => e.questionID === id);
        if(_index === -1) {
            _paqAnswers.push({
                answer: answer,
                questionID: id
            });
            setPAQAnswers(_paqAnswers);
        } else {
            setPAQAnswers(_paqAnswers);
            _paqAnswers[_index] = {
                answer: answer,
                questionID: id
            };
        }
    };

    const onChangeMinMax = (answer: string, id: string)=> {
        let _paqAnswers = JSON.parse(JSON.stringify(PAQAnswers));

        let _index = PAQAnswers.findIndex(e => e.questionID === id);
    
        if(_index === -1) {
            _paqAnswers.push({
                answer: Number(answer),
                questionID: id
            });
            setPAQAnswers(_paqAnswers);
        } else {
            _paqAnswers[_index] = {
                answer: Number(answer),
                questionID: id
            };
            setPAQAnswers(_paqAnswers);
        }
    };

    const handleGetAdvertsPAQ = () => {
        setLoading(true);
        RESTService.action("GetAdvertsPAQ", {
            advertID: jobID
        })
            .then((res) => {
                setGetAdvertsPAQ(res);
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetAdvertsPAQ Err:", err);
                setLoading(false);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const applyToAdvert = () => {
        setButtonLoading(true);
        RESTService.action("ApplyToAdvert", {
            advertID: jobID,
            preAssessmentQuestionAnswers: PAQAnswers
        })
            .then((res) => {
                setViews("job-detail");
                setButtonLoading(false);
                setIsVisible(true);
            })
            .catch((err) => {
                console.error("ApplyToAdvert Err:", err);
                setButtonLoading(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderMinMaxAnswer = (_id: string) => {
        return <div>
            <TextInput
                placeholder={localize("enter-answer")}
                infoText={localize("enter-as-number")}
                onKeyDown={(e) => {
                    if (!/[0-9]|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(e.key)) {
                        e.preventDefault();
                    }
                }}
                onChangeText={(e) => {
                    if(/[0-9]|Backspace|Tab|Enter|Delete|ArrowLeft|ArrowRight/.test(e)){
                        onChangeMinMax(e,_id);
                    }
                }}
            />
        </div>;
    };

    const renderYesNoAnswer = (id:string) => {
        let _index = PAQAnswers.findIndex(e => e.questionID === id);
        let isSelected: number | boolean | undefined = undefined;
        if(_index !== -1) {
            isSelected =  PAQAnswers[_index].answer;
            
        }
        return <div
            className={classes.yesNoAnswerContianer}
        >
            <RadioButton 
                isSelected={isSelected === true ? true : undefined}
                title={localize("yes")}
                onChange={() => {
                    onChangeYesNO(true,id);
                }}
            />
            <RadioButton 
                isSelected={isSelected === false ? true : undefined}
                title={localize("no")}
                onChange={() => {
                    onChangeYesNO(false,id);
                }}
            />
        </div>;
    };

    const renderApplicationButton = () => {
        return <div
            style={{
                marginBottom: spaces.containerLarge,
                marginTop: spaces.contentXLarge
            }}
        >
            <Button
                title={localize("send-reply")}
                spreadBehaviour="stretch"
                loading={buttonLoading}
                onClick={() => {
                    applyToAdvert();
                }}
            />
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke,
                marginTop: spaces.content,
                height: 1
            }}
        >
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loading}
        >
            <Loading/>
        </div>;
    }

    return <div
        style={{
            marginTop: spaces.contentXLarge
        }}
    >
        <div
            className={classes.header}
            style={{
                marginBottom: spaces.content,
                gap: spaces.inline / 2
            }}
            onClick={() => {
                setViews("job-detail");
            }}
        >
            <div>
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant="header4-medium"
                color="textDark"
            >
                {localize("pre-assessment-questions")}
            </Text>
        </div>
        <div
            className={classes.container}
            style={{
                gap: spaces.content / 2
            }}>
            <Text
                variant="body-medium"
                color="textDark"
            >
                {getAdvertWithDetailData?.occupation.localizedText}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {getAdvertWithDetailData?.companyInfo.title}
            </Text>
            <div
                className={classes.workTypeContainer}
                style={{
                    gap: spaces.inline / 2
                }}
            >
                <InfoIcon
                    color={colors.primary}
                />
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getAdvertWithDetailData?.workingType.localizedText} - {getAdvertWithDetailData?.contractType.localizedText}
                </Text>
            </div>
        </div>
        {renderLine()}
        <div>
            {getAdvertsPAQ.map((item, index) => {
                return <div
                    className={classes.content}
                    style={{
                        gap: spaces.content / 2,
                        marginBottom: spaces.content,
                    }}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                        style={{
                            marginTop: spaces.content
                        }}
                    >
                        {`${localize("question")} - ${index + 1}`}
                    </Text>
                    <Text
                        variant="body2-regular"
                        color="textDark"
                    >
                        {item.title}
                    </Text>
                    {
                        item.type === "yes-no" ? renderYesNoAnswer(item.questionID) : renderMinMaxAnswer(item.questionID)
                    }
                </div>;
            })}
            <div>
            </div>
        </div>
        {renderApplicationButton()}
    </div>;
};

export default PAQ;
