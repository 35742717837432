import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        justifyContent: "start",
        flexDirection: "column",
        boxSizing: "border-box",
        minHeight: "100%",
        display: "flex",
        height: "100%",
        width: "100%",
        flexGrow: 1,
        "@media screen and (max-width: 1500px)": {
            height: "max-content"
        },
        "@media screen and (max-width: 700px)": {
            paddingBottom: "30px !important",
            paddingRight: "10px !important",
            paddingLeft: "10px !important",
            paddingTop: "30px !important",
        }
    },
    blogContainer: {
        height: "100%",
        width: "100%"
    },
    blog: {
        justifyContent: "start",
        alignItems: "start",
        flexWrap: "wrap",
        display: "flex",
        flexGrow: 1,
        "@media screen and (max-width: 1120px)": {
            justifyContent: "center",
        }
    },
    title: {
        "@media screen and (max-width: 1070px)": {
            textAlign: "center"
        }
    }
});