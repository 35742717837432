import {
    useEffect,
    useState,
    FC
} from "react";
import useStyles from "./styles";
import IHamburgerMenuProps from "./type";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useNavigate
} from "react-router-dom";
import {
    InstagramIcon,
    HamburgerIcon,
    FacebookIcon,
    YoutubeIcon,
    XIcon,
    Logo
} from "../../assets/svgr";
import DropDownMenu from "../dropdownMenu";
import {
    WORKER_HEADER_MENU_FOR_HAMBURGER_MENU,
    SIDEBAR_NAV_LINKS_WITHOUTH_LOGIN,
    NAV_LINKS_WITHOUT_LOGIN,
    NAV_LINKS_FOR_EMPLOYER,
    NAV_LINKS_FOR_WORKER,
    ISIN_OLACAK_INSTAGRAM,
    ISIN_OLACAK_YOUTUBE,
    ISIN_OLACAK_FACEBOOK
} from "../../constants";
import {
    useGlobalState 
} from "../../context/globalState";
import {
    RESTService 
} from "../../services/restAPI";
import {
    ChatService 
} from "../../services/chat";
import {
    NavbarContext 
} from "../../context";
import {
    UserInfo
} from "./views";

const HamburgerMenu: FC<IHamburgerMenuProps> = ({
}) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        isActive
    } = NavbarContext.HamburgerContext.useContext();

    const {
        activePath
    } = NavbarContext.NavigationContext.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const [workerHeaderMenu, setWorkerHeaderMenu] = useState(WORKER_HEADER_MENU_FOR_HAMBURGER_MENU);
    const [navData, setNavData] = useState(SIDEBAR_NAV_LINKS_WITHOUTH_LOGIN);
    const [isLoadingLogout, setIsLoadingLogout] = useState<boolean>(false);
    const [headerData, setHeaderData] = useState(NAV_LINKS_WITHOUT_LOGIN);
    const [isResponsive, setIsResponsive] = useState<boolean>(false);
    const [workerNavMenu, setWorkerNavMenu] = useState(false);

    const resize = () => {
        if(window.innerWidth < 400) {
            setIsResponsive(true);
        }
        if(window.innerWidth >= 400) {
            setIsResponsive(false);
        }
    };

    useEffect(() => {
        resize();
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    useEffect(() => {
        if(globalState.user?.type === "worker"){
            setHeaderData(NAV_LINKS_FOR_WORKER);
        } else if(globalState.user?.type === "employer"){
            setHeaderData(NAV_LINKS_FOR_EMPLOYER);
        } else {
            setHeaderData(NAV_LINKS_WITHOUT_LOGIN);
        }
    }, [globalState]);

    const logout = () => {
        setIsLoadingLogout(true);
        RESTService.action("Logout", {
        })
            .then(() => {
                window.localStorage.clear();
                setGlobalState({
                    ...globalState,
                    company: null,
                    user: null
                });
                setIsLoadingLogout(false);
                handleNavigate("/auth/login");
                ChatService.disconnect();
            })
            .catch((err) => {
                console.error("Logout Err:", err);
            });
    };

    const renderVerticalLine = () => {
        return <div
            className={classes.verticalLine}
            style={{
                backgroundColor: colors.primary,
                marginRight: spaces.content / 2
            }}
        >
        </div>;
    };

    const handleNavigate = (navigateTo: string) => {
        NavbarContext.HamburgerContext.setState({
            isActive: false
        });
        navigate(navigateTo);
    };

    const renderHamburgerIcon = () => {
        return <div
            className={classes.sizeButtonContainer}
            style={{
                marginBottom: spaces.contentLarge * 2,
                rotate: isActive ? "90deg": "0deg",
                marginLeft: spaces.content
            }}
            onClick={() => {
                NavbarContext.HamburgerContext.setState({
                    isActive: false
                });
            }}
        >
            <HamburgerIcon
                size={25}
            />
        </div>;
    };

    const renderHeaderTitles = () => {
        if(!headerData) {
            return;
        }

        return headerData.map((item, index) => {
            if(item.subLinks?.length){
                return <DropDownMenu
                    title={localize(item.titleKey)}
                    key={"DropdownMenu-"+index}
                    isActive={workerNavMenu}
                    onClick={() => {
                        setWorkerNavMenu(!workerNavMenu);
                    }}
                    data={item.subLinks}
                />;
            }
            return <div
                key={"headerTitle-"+index}
                onClick={() => {
                    handleNavigate(item.path);
                }}
                className={classes.headerTitles}
                style={{
                    width: isActive ? "100%" : 0
                }}
            >
                {item.path === activePath && renderVerticalLine()}
                <Text
                    variant='header5-medium'
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        overflow: "hidden"
                    }}
                >
                    {localize(item.titleKey)}
                </Text>
            </div>;
        });
    };

    const renderNavButtons = () => {
        if(!navData) {
            return null;
        }

        return navData.map((item, index) => {
            return <Button
                variant={activePath === item.path ? "filled": "outline"}
                title={localize(item.titleKey)}
                key={"NavButton-"+index}
                spreadBehaviour='free'
                onClick={() => {
                    handleNavigate(item.path);
                }}
                style={{
                    overflow: "hidden"
                }}
            />;
        });
    };

    const renderFooter = () => {
        return <div
            className={classes.footerButtonsContainer}
            style={{
                gap: isResponsive ? spaces.item/3 : spaces.item,
                marginBottom: spaces.container,
                width: isActive ? "100%" : 0
                
            }}
        >
            <div
                className={classes.footerButton}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.quarter
                }}
                onClick={() => {
                    window.open(ISIN_OLACAK_INSTAGRAM);
                }}
            >
                <InstagramIcon
                    color={colors.white}
                    size={15}
                />
            </div> <div
                className={classes.footerButton}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.quarter
                }}
                onClick={() => {
                    window.open(ISIN_OLACAK_YOUTUBE);
                }}
            >
                <YoutubeIcon
                    color={colors.white}
                    size={15}
                />
            </div> <div
                className={classes.footerButton}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.quarter
                }}
                onClick={() => {
                    window.open(ISIN_OLACAK_FACEBOOK);
                }}
            >
                <FacebookIcon
                    color={colors.white}
                    size={15}
                />
            </div> <div
                className={classes.footerButton}
                style={{
                    backgroundColor: colors.secondary,
                    borderRadius: radiuses.quarter
                }}
                onClick={() => {
                    window.open(ISIN_OLACAK_FACEBOOK);
                }}
            >
                <XIcon
                    color={colors.white}
                    size={15}
                />
            </div>
        </div>;
    };

    const renderUserInfo = () => {
        if(!(globalState && globalState.user && globalState.user.type === "employer")){
            return null;
        }
        return <div
            style={{
                marginBottom: isResponsive ? 0 : spaces.container
            }}
        >
            <UserInfo
                profilePhoto={globalState.user.profilePhotoURL}
                userName={globalState.user.fullName}
                userType={globalState.user.type}
            />
        </div>;
    };

    const renderWorkerHeaders = () => {
        if(!(globalState && globalState.user?.type === "worker")){
            return;
        }

        return <UserInfo
            profilePhoto={globalState.user.profilePhotoURL}
            userName={globalState.user.fullName}
            userType={globalState.user.type}
            navData={workerHeaderMenu}
        />;
    };

    const renderHeaderSection = () => {
        if(!headerData) {
            return;
        }

        return <div
            className={classes.headerSection}
            style={{
                gap: isResponsive ? spaces.container/3 : spaces.container,
                width: isActive ? "100%" : 0
            }}
        >
            {renderUserInfo()}
            <div
                className={classes.headerTitlesContainer}
                style={{
                    gap: isResponsive ? spaces.content/3 : spaces.content
                }}
            >
                {renderHeaderTitles()}
            </div>
            <div
                className={classes.horizontalLine}
                style={{
                    backgroundColor: colors.stroke
                }}
            >
            </div>
        </div>;
    };

    const renderNavSection = () => {
        if(!window.localStorage.getItem("user")) {
            return null;
        }

        if(!navData) {
            return null;
        }

        return <div
            className={classes.navSectionContainer}
            style={{
                paddingTop: isResponsive ? spaces.container  : spaces.container*2,
                gap: isResponsive ? spaces.container: spaces.container*2,
                width: isActive ? "100%" : 0
            }}
        >
            <Text
                variant='header5-regular'
                style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    overflow: "hidden"
                }}
            >
                {localize("management-panel")}
            </Text>
            <div
                className={classes.navButtonsContainer}
                style={{
                    gap: isResponsive ? spaces.content / 3 : spaces.content,
                    width: isActive ? "100%" : 0
                }}
            >
                {renderNavButtons()}
            </div>
            <div
                className={classes.horizontalLine}
                style={{
                    backgroundColor: colors.stroke
                }}
            >
            </div>
        </div>;
    };
    

    const renderLogo = () => {
        return  <div
            className={classes.logoContainer}
            style={{
                width: isActive ? "100%" : 0
            }}
            onClick={() => {
                if(globalState.user?.type === "employer"){
                    navigate("/app/");
                }else{
                    navigate("/");
                }
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline * 5
                }}
            >
                <Logo size={80} />
            </div>
        </div>;
    };

    const renderLogout = () =>  {
        if(!window.localStorage.getItem("user")) {
            return null;
        }

        return <div
            className={classes.exitButton}
            style={{
                width: isActive ? "100%" : 0
            }}
        >
            <Button
                title={localize("log-out")}
                spreadBehaviour="free"
                textColor= "textWhite"
                color= "warning"
                size='large'
                onClick={() => {
                    logout();
                }}
                loading={isLoadingLogout}
                style={{
                    marginBottom: isResponsive ? spaces.contentLarge : spaces.contentLarge * 2,
                    marginTop: isResponsive ? spaces.container/3 : spaces.container,
                    overflow: "hidden"
                
                }}
            />
        </div>;
    };

    const renderLogIn = () => {
        if(window.localStorage.getItem("user")) {
            return null;
        }

        return <div
            className={classes.loginButton}
            style={{
                gap: isResponsive ? spaces.content / 3 : spaces.content,
                width: isActive ? "100%" : 0
            }}
        >
            <Button
                title={localize("register")}
                textColor= "secondary"
                spreadBehaviour="free"
                color= "secondary"
                variant='outline'
                size='large'
                onClick={() => {
                    handleNavigate("/auth/register");
                }}
                style={{
                    marginBottom: spaces.contentLarge * 2,
                    marginTop: spaces.container,
                    overflow: "hidden"
                }}
            />
            <Button
                title={localize("login")}
                spreadBehaviour="free"
                textColor= "textWhite"
                color= "secondary"
                size='large'
                onClick={() => {
                    handleNavigate("/auth/login");
                }}
                style={{
                    marginBottom: spaces.contentLarge * 2,
                    marginTop: spaces.container,
                    overflow: "hidden"
                }}
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            transform: isActive ? "translateX(0px)" : "translateX(-100%)",
            visibility: isActive ? "visible" : "hidden",
            borderBottomColor: colors.stroke,
            borderRightColor: colors.stroke,
            backgroundColor: colors.layer1,
            width: isActive ? "14rem": 0,
            borderWidth: borders.line
        }}
    >
        <div
            className={classes.topContainer}
            style={{
                paddingBottom: isResponsive ? spaces.container/3 : spaces.container,
                backgroundColor: colors.layer3,
                paddingTop: spaces.container,
            }}
        >   
            {renderHamburgerIcon()}
            {renderLogo()}
        </div>
        {renderWorkerHeaders()}
        {renderHeaderSection()}
        {renderNavSection()}
        {renderLogout()}
        {renderLogIn()}
        {renderFooter()}
    </div>;
};
export default HamburgerMenu;
