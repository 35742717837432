import NContext, {
    ConfigType
} from "ncore-context";
import {
    ActionResponseTypes,
    ServiceContextType,
    ActionRequestTypes,
    ActionsType,
    ServiceType
} from "../types";
import actions from "../actions";
import {
    SERVICE_EVENT_TYPES,
    SERVICE_CONFIGS
} from "../constants";

class ServiceContextInheritence<T extends ServiceType<ActionsType>> extends NContext<ServiceContextType<ActionsType>, ConfigType<ServiceContextType<ActionsType>>> {
    isTokenRefreshing = false;
    actions;
    queue: Array<{
        actionName: ActionsType;
        params: any;
    }> = [];

    constructor(initialState: T) {
        super(
            initialState,
            {
                key: "service-context"
            }
        );

        this.actions = initialState.actions;
    }

    removeAllQueueListeners = () => {
        this.queue.forEach((queueItem) => {
            this.removeEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${queueItem.actionName}`);
            this.removeEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${queueItem.actionName}-error`);
        });
    };

    startRefreshToken = () => {
        this.isTokenRefreshing = true;

        this.actions["RefreshToken"]({
        })
            .then(() => {
                this.isTokenRefreshing = false;

                this.queue.forEach(queueItem => {
                    this.emitWithKey(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${queueItem.actionName}`, true);
                });
            })
            .catch(() => {
                this.isTokenRefreshing = false;

                this.queue.forEach(queueItem => {
                    this.emitWithKey(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${queueItem.actionName}-error`, false);
                });
                this.removeAllQueueListeners();
                this.queue = [];
            });
    };

    countOfActionInQueue = (actionName: ActionsType) => {
        let count = 0;

        for(let i = 0; i < this.queue.length; i++) {
            if(this.queue[i].actionName === actionName) {
                count += 1;
            }
        }

        return count;
    };

    addQueue = <K extends ActionsType>(actionName: K, params: ActionRequestTypes[K]) => {
        if(!this.isTokenRefreshing) {
            this.startRefreshToken();
        }

        return new Promise((resolve, reject) => {
            const actionCount = this.countOfActionInQueue(actionName);

            if(actionCount > 0) {
                return;
            }

            let timeout: NodeJS.Timeout | null = setTimeout(() => {
                reject(false);
            }, 30000);

            this.queue.push({
                actionName: actionName,
                params: params
            });

            const successQueueTriggered = () => {
                if(timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }

                this.removeEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${actionName}`);
                this.removeEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${actionName}-error`);

                resolve(true);
            };

            const errorQueueTriggered = () => {
                if(timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }

                reject(false);
            };

            this.addEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${actionName}`, successQueueTriggered);
            this.addEventListener(`${SERVICE_EVENT_TYPES["REFRESH_TOKEN"]}-${actionName}-error`, errorQueueTriggered);
        });
    };

    action = async <K extends ActionsType>(actionName: K, params: ActionRequestTypes[K]): Promise<Pick<ActionResponseTypes, K>[K]> => {
        // @ts-ignore
        return await this.actions[actionName](params)
            .then((res) => {
                return res;
            })
            .catch(async (err) => {
                if(!SERVICE_CONFIGS[actionName].isWorkedWithQueue) {
                    throw err;
                }

                if(err.code === 401 || err.status === 401) {
                    return await this.addQueue(actionName, params)
                        .then(async () => {
                            // @ts-ignore
                            return await this.actions[actionName](params);
                        })
                        .catch((c_err) => {
                            this.emitWithKey(SERVICE_EVENT_TYPES["GO_TO_LOGOUT"], true);
                            this.removeAllQueueListeners();
                            this.queue = [];

                            throw c_err;
                        });
                }

                throw err;
            });
    };
};

const RESTServiceInherit = new ServiceContextInheritence({
    actions: actions
});
export default RESTServiceInherit;
