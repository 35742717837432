import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Shield = ({
    size = 30,
    color = "#0284C7",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M.524 4.624C0 5.43 0 7.829 0 12.625v2.362c0 8.457 5.887 12.561 9.581 14.304 1.002.473 1.503.709 2.919.709 1.416 0 1.917-.236 2.919-.71C19.113 27.549 25 23.445 25 14.988v-2.362c0-4.796 0-7.195-.524-8.001-.525-.807-2.613-1.58-6.788-3.123l-.796-.294C14.715.402 13.627 0 12.5 0c-1.127 0-2.215.402-4.392 1.207l-.796.294C3.137 3.045 1.05 3.817.524 4.624Zm16.225 8.125a1.189 1.189 0 0 0-.083-1.588.989.989 0 0 0-1.47.09l-4.184 5.06-1.207-1.46a.989.989 0 0 0-1.471-.09 1.189 1.189 0 0 0-.083 1.588l1.984 2.4c.197.24.48.376.777.376.297 0 .58-.137.777-.376l4.96-6Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Shield;
