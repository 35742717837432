import {
    uuid 
} from "../../../../../../../utils";

export const DISABLED_CANDIDATES_FILTERS: Array<{
    id: string;
    value: "false" 
        | "true" 
        | "all"
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        value: "all",
        localizedText: "all",
    },
    {
        id: uuid(),
        value: "true",
        localizedText: "show-only-disabled-candidates",
    },
    {
        id: uuid(),
        value: "false",
        localizedText: "dont-show-disabled-candidates"
    }
];