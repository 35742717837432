import useStyles from "./styles";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    IUserInfoProps 
} from "./types";
import {
    UserIcon
} from "../../../../assets/svgr";
import {
    DropdownMenu
} from "./../../../../components/index";
import {
    useState 
} from "react";
import emptyProfile from "../../../../assets/img/Ellipse.png";

const UserInfo = ({
    profilePhoto,
    userType,
    userName,
    navData
}: IUserInfoProps) => {
    const classes = useStyles();

    const {
        spaces,
        colors,
        borders,
        radiuses
    } = IOCoreTheme.useContext();

    const [isActive, setIsActive] = useState(false);

    const renderUserInfo = () => {
        if(userType !==  "employer") {
            return null;
        }
        return <div
            className={classes.userInfoContainer}
        >
            <Text
                variant="header5-medium"
                color="backgroundDark"
                style={{
                    width: "100%"
                }}
            >
                {userName}
            </Text>
        </div>;
    };

    const renderWorkerUserInfo = () => {
        if(userType !==  "worker" || !navData) {
            return null;
        }

        return <div
            className={classes.userInfoContainer}
        >
            <DropdownMenu
                isActive={isActive}
                onClick={() => {
                    setIsActive(!isActive);
                }}
                title={userName}
                data={navData}
            />
        </div>;
    };

    const renderProfilePhoto = () => {
        return <div
            className={classes.profilePhotoContianer}
            style={{
                borderRadius: radiuses.hard * 100,
                borderWidth: borders.indicator,
                borderColor: colors.primary
            }}
        >
            {
                <img 
                    src={
                        profilePhoto ?
                            profilePhoto
                            :
                            emptyProfile
                    }
                    alt="profile"
                    className={classes.profilePhotoField}
                /> 
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            borderTopRightRadius: radiuses.half,
            borderTopLeftRadius: radiuses.half,
            padding: spaces.content / 2,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            gap: spaces.content
        }}
        onClick={() => {
        }}
    >
        {renderProfilePhoto()}
        {renderWorkerUserInfo()}
        {renderUserInfo()}
    </div>;
};
export default UserInfo;