import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Diploma = ({
    color = "#45BBD4",
    size = 22,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M1 11V7c0-2.828 0-4.243.879-5.121C2.757 1 4.172 1 7 1h8c2.828 0 4.243 0 5.121.879C21 2.757 21 4.172 21 7v4c0 2.828 0 4.243-.879 5.121-.476.477-1.11.695-2.04.795a2.94 2.94 0 0 0-.195-.234c-.116-.128-.263-.273-.406-.414l-1.493-1.472-.551-.558a4.501 4.501 0 0 0-8.872 0l-.55.558-1.494 1.472c-.143.141-.29.286-.406.414-.05.055-.12.134-.194.234-.931-.1-1.565-.318-2.041-.795C1 15.243 1 13.828 1 11Zm7-6.75a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5H8ZM5.25 8.5A.75.75 0 0 1 6 7.75h10a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z"
            transform={`scale(${1 / pathScale})`}
            clipRule="evenodd"
            fillRule="evenodd"
            fill={color}
        />
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            d="M14 15a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
        <path
            d="M8.001 14.917 8 15a3 3 0 1 0 .001-.083ZM6.676 16.25l-1.08 1.065c-.325.32-.487.48-.543.614a.643.643 0 0 0 .26.813c.122.071.342.093.783.138.248.025.373.037.477.075a.838.838 0 0 1 .5.494c.039.103.052.225.077.47.045.435.068.652.14.773a.662.662 0 0 0 .824.256c.137-.056.3-.215.623-.535l1.08-1.07a4.509 4.509 0 0 1-3.141-3.094ZM12.182 19.343l1.08 1.07c.325.32.487.48.624.535.313.126.66.018.824-.256.072-.12.095-.338.14-.772.025-.246.038-.368.077-.47a.839.839 0 0 1 .5-.495c.104-.038.229-.05.478-.075.44-.045.66-.067.782-.138a.643.643 0 0 0 .26-.813c-.056-.135-.218-.295-.542-.614l-1.08-1.066a4.509 4.509 0 0 1-3.143 3.094Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default Diploma;
