import useStyles from "./styles";
import {
    useEffect,
    useState 
} from "react";
import {
    IOCoreTranslationType,
    IOCoreLocale,
    IOCoreTheme,
    ToolBox,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    RESTService 
} from "../../../../../../../../services/restAPI";
import {
    GetMySubscriptionDataResponseType
} from "../../../../../../../../services/restAPI/actions/getMySubscriptionData/types";
import {
    GetMyPurchaseHistoryResponseType, 
    PurchaseHistoryObjectType
} from "../../../../../../../../services/restAPI/actions/getMyPurchaseHistory/types";
import {
    GetMyPendingOrdersResponseType, 
    PendingOrderObjectType
} from "../../../../../../../../services/restAPI/actions/getMyPendingOrders/types";
import {
    SubscriptionsPageProps 
} from "./types";
import {
    RowCard 
} from "../../../../../../../../components";
import {
    FilledWalletIcon,
    VerticalDotsIcon,
    OutlineTrashIcon,
    TicketIcon,
    PenIcon
} from "../../../../../../../../assets/svgr";
import moment from "moment";

const puchaseHistoryHeaders: Array<keyof IOCoreTranslationType> = [
    "purchase-code",
    "purchase-date",
    "purchase-content",
    "payment-detail"
];

const orderHistoryHeaders: Array<keyof IOCoreTranslationType> = [
    "purchase-code",
    "order-date",
    "purchase-content",
    "payment-detail"
];

const Subscriptions = ({
    setCurrentPackage,
    setHeaderTitle,
    setLastOrder,
    setPremium,
    lastOrder,
    setViews,
    views
}: SubscriptionsPageProps) =>  {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [subscriptionData, setSubscriptionData] = useState<GetMySubscriptionDataResponseType>();
    const [purchaseHistory, setPurchaseHistory] = useState<GetMyPurchaseHistoryResponseType>();
    const [orderHistory, setOrderHistory] = useState<GetMyPendingOrdersResponseType>();
    const [purchaseHistoryLoading, setPurchaseHistoryLoading] = useState(false);
    const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);
    const [toolboxOpen, setToolboxOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        handleSubscriptionData();
    }, []);

    useEffect(() => {
        if(views === "subscriptions") {
            setHeaderTitle("subscriptions");
            setLastOrder("");
        } else if(views === "purchaseHistory") {
            setHeaderTitle("purchase-history");
        }
        handleOrderHistory();
        handlePurchaseHistory();
    }, [views]);

    const handleSubscriptionData = () => {
        setIsLoading(true);
        RESTService.action("GetMySubscriptionData", {
        })
            .then((res) => {
                setSubscriptionData(res);
                setIsLoading(false);
                setPremium(res.isPremium);
                if(res.isPremium) {
                    setCurrentPackage(res.package?._id);
                }
            })
            .catch((err) => {
                console.error("RemoveAdvert Err:", err);
            });
    };

    const handlePurchaseHistory = () => {
        setPurchaseHistoryLoading(true);
        RESTService.action("GetMyPurchaseHistory", {
        })
            .then((res) => {
                setPurchaseHistory(res);
                setPurchaseHistoryLoading(false);
            })
            .catch((err) => {
                console.error("GetMyPurchaseHistory Err:", err);
            });
    };

    const handleOrderHistory = () => {
        setOrderHistoryLoading(true);
        RESTService.action("GetMyPendingOrders", {
        })
            .then((res) => {
                setOrderHistory(res);
                setOrderHistoryLoading(false);
            })
            .catch((err) => {
                console.error("GetMyPendingOrders Err:", err);
            });
    };

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                marginBottom: spaces.content,
                borderColor: colors.primary,
                borderRadius: radiuses.half,
                background: colors.iceblue,
                padding: spaces.container,
                borderWidth: borders.line,
                gap: spaces.content
            }}
        >
            <div
                className={classes.headerTextsContainer}
            >
                <Text
                    variant='body2-medium'
                    color='textDark'
                >
                    {subscriptionData?.isPremium ? localize("upgrade-your-subscription"): localize("no-subscription-yet")}
                </Text>
                <Text
                    variant='body4-regular'
                    color='textDark'
                >
                    {subscriptionData?.isPremium ? localize("upgrade-subscription-to-benefit-from-more-privileges"): localize("subscribe-to-benefit-from-privileges")}
                </Text>
            </div>
            <div>
                <Button
                    title={subscriptionData?.isPremium ? localize("upgrade") : localize("subscribe")}
                    spreadBehaviour="baseline"
                    onClick={() => {
                        setViews("payment");
                    }}
                />
            </div>
        </div>;
    };

    const renderPurchaseHistoryCard = () => {
        return <RowCard
            onClick={() => {
                handlePurchaseHistory();
                handleOrderHistory();
                setViews("purchaseHistory");
            }}
            renderLeft={() =>
                <div
                    className={classes.rowLeftIconStyle}
                    style={{
                        borderColor: colors.primary,
                        borderRadius: radiuses.half,
                        marginRight: spaces.content,
                        background: colors.iceblue,
                        borderWidth: borders.line,
                        padding: spaces.inline
                    }}
                >
                    <FilledWalletIcon size={24} />
                </div>
            }
            renderTitle={() => <div
                className={classes.rowStyle}
            >
                <Text
                    variant="body2-medium"
                    color="textDark"
                >
                    {localize("purchase-history")}
                </Text>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                    className={classes.rowStyle}
                >
                    {localize("access-all-purchase-details")}
                </Text>
            </div>}
            title=""
        />;
    };

    const renderToolboxContent = () => {
        return <Button
            onClick={()=>{
        
            }}
            title="Toolbox"
            icon={() => <VerticalDotsIcon
                color={colors.black100}
                size={12}
            />}
            variant="ghost"
        />;
    };

    const renderSubscriptionToolbox = () => {
        return <div
            className={classes.toolboxContainer}
            style={{
                borderColor: colors.stroke,
                borderRadius: radiuses.half,
                background: colors.white,
                borderWidth: borders.line,
                padding: spaces.inline
            }}
        >
            <ToolBox
                content={renderToolboxContent()}
            >
                <div
                    className={classes.toolboxItemContainer}
                    style={{
                        gap: spaces.inline
                    }}>
                    <div
                        className={classes.toolboxItem}
                        style={{
                            gap: spaces.inline / 2
                        }}>
                        <PenIcon size={15} />
                        <Text
                            variant="body2-regular"
                            color="textGrey"
                        >
                            {localize("go-to-plan")}
                        </Text>
                    </div>
                    <div
                        className={classes.toolboxItem}
                        style={{
                            gap: spaces.inline / 2
                        }}>
                        <OutlineTrashIcon size={15} />
                        <Text
                            variant="body2-regular"
                            color="textGrey"
                        >
                            {localize("cancel-subscription")}
                        </Text>
                    </div>
                </div>
            </ToolBox>
        </div>;
    };
    
    const renderSubscription = () => {
        if(!subscriptionData?.isPremium) {
            return <RowCard
                onClick={() => {
                    setViews("payment");
                }}
                renderLeft={() =>
                    <div
                        className={classes.rowLeftIconStyle}
                        style={{
                            borderColor: colors.primary,
                            borderRadius: radiuses.half,
                            marginRight: spaces.content,
                            background: colors.iceblue,
                            borderWidth: borders.line,
                            padding: spaces.inline
                        }}
                    >
                        <TicketIcon size={24} />
                    </div>
                }
                renderTitle={() => <div
                    className={classes.rowStyle}
                >
                    <Text
                        variant="body2-medium"
                        color="textDark"
                    >
                        {localize("no-subscription")}
                    </Text>
                    {toolboxOpen && renderSubscriptionToolbox()}
                </div>}
                renderRightIcon={() => <VerticalDotsIcon color={colors.textSecondary} size={24} />}
                title=""
            />;
        }
        
        return <RowCard
            renderLeft={() =>
                <div
                    className={classes.rowLeftIconStyle}
                    style={{
                        borderColor: colors.primary,
                        borderRadius: radiuses.half,
                        marginRight: spaces.content,
                        background: colors.iceblue,
                        borderWidth: borders.line,
                        padding: spaces.inline
                    }}
                >
                    <TicketIcon size={24} />
                </div>
            }
            renderTitle={() => <div
                className={classes.rowStyle}
            >
                <Text
                    variant="body2-medium"
                    color="textDark"
                >
                    {subscriptionData?.package?.title}
                </Text>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                    className={classes.rowStyle}
                >
                    {`${subscriptionData?.package?.subType && localize(subscriptionData?.package?.subType)} - ${moment(subscriptionData?.package?.purchaseDate).format("DD.MM.YYYY")}`}
                </Text>
                {toolboxOpen && renderSubscriptionToolbox()}
            </div>}
            renderRightIcon={() => <VerticalDotsIcon color={colors.textSecondary} size={24} />}
            rightIconOnClick={() => {
                setToolboxOpen(!toolboxOpen);
            }}
            title=""
        />;
    };

    const renderMainContent = () => {
        /* if(!subscriptionData?.isPremium) {
            return null;
        } */

        return <div>
            {renderPurchaseHistoryCard()}
            {renderSubscription()}
        </div>;
    };

    const renderPurchaseHistoryHeaderItem = (item: keyof IOCoreTranslationType) => {
        return <div
            className={classes.purchaseHistoryItem}
            style={{
                borderColor: colors.black,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            <Text
                variant="body3-semiBold"
                color="textDark"
            >
                {localize(item)}
            </Text>
        </div>;
    };

    const renderPurchaseHistoryContentItem = (item: string | number, params?: string) => {
        return <div
            className={classes.purchaseHistoryContentItem}
            style={{
                borderColor: colors.black,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            <Text
                variant="body3-regular"
                color="textDark"
            >
                {params ? `${item} ${params}`: item}
            </Text>
        </div>;
    };

    const renderPurchaseHistoryContentRow = (item: PurchaseHistoryObjectType) => {
        return <div
            className={classes.purchaseHistoryRow}
            style={{
                borderColor: colors.black,
                borderWidth: borders.line
            }}
        >
            {renderPurchaseHistoryContentItem(item.code)}
            {renderPurchaseHistoryContentItem(moment(item.purchaseDate).format("DD.MM.YYYY"))}
            {
                item.subType && item.subType.length ?
                    renderPurchaseHistoryContentItem(localize(item.subType), item.title) :
                    renderPurchaseHistoryContentItem(item.title)
            }
            {renderPurchaseHistoryContentItem(item.paymentAmount, "TRY")}
        </div>;
    };

    const renderOrderHistoryContentRow = (item: PendingOrderObjectType) => {
        return <div
            className={classes.purchaseHistoryRow}
            style={{
                borderColor: colors.black,
                borderWidth: borders.line,
                background: item.code === lastOrder ? colors.warning50 : colors.white
            }}
        >
            {renderPurchaseHistoryContentItem(item.code)}
            {renderPurchaseHistoryContentItem(moment(item.orderDate).format("DD.MM.YYYY"))}
            {
                item.subType && item.subType.length ?
                    renderPurchaseHistoryContentItem(localize(item.subType), item.title) :
                    renderPurchaseHistoryContentItem(item.title)
            }
            {renderPurchaseHistoryContentItem(item.paymentAmount, "TRY")}
        </div>;
    };

    const renderPurchaseHistoryContent = () => {
        return <div>
            {   
                purchaseHistory &&
                purchaseHistory.map((item) => {
                    return renderPurchaseHistoryContentRow(item);
                })
            }
        </div>;
    };

    const renderOrderHistoryContent = () => {
        return <div
            style={{
                marginBottom: spaces.container
            }}
        >
            {   
                orderHistory &&
                orderHistory.map((item) => {
                    return renderOrderHistoryContentRow(item);
                })
            }
        </div>;
    };

    const renderPurchaseHistoryHeaders = () => {
        if(!purchaseHistory?.length) {
            return <div>
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("completed-orders")}
                </Text>
                <div
                    className={classes.noOrderHistory}
                >
                    <Text
                        variant="body2-regular"
                        color="textGrey"
                    >
                        {localize("no-completed-orders-yet")}
                    </Text>
                </div>
            </div>;
        }

        return <div>
            <Text
                variant="header5-medium"
                color="textDark"
            >
                {localize("completed-orders")}
            </Text>
            <div
                className={classes.historyHeadersContainer}
                style={{
                    borderTopRightRadius: radiuses.half,
                    borderTopLeftRadius: radiuses.half,
                    marginTop: spaces.container,
                    borderWidth: borders.line,
                    background: colors.grey50
                }}
            >
                {
                    puchaseHistoryHeaders.map((item) => {
                        return renderPurchaseHistoryHeaderItem(item);
                    })
                }
            </div>
        </div>;
    };

    const renderOrderHistoryHeaders = () => {
        if(!orderHistory?.length) {
            return <div>
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("pending-orders")}
                </Text>
                <div
                    className={classes.noOrderHistory}
                >
                    <Text
                        variant="body2-regular"
                        color="textGrey"
                    >
                        {localize("no-pending-orders-yet")}
                    </Text>
                </div>
            </div>;
        }

        return <div>
            <Text
                variant="header5-medium"
                color="textDark"
            >
                {localize("pending-orders")}
            </Text>
            <div
                className={classes.historyHeadersContainer}
                style={{
                    borderTopRightRadius: radiuses.half,
                    borderTopLeftRadius: radiuses.half,
                    marginTop: spaces.container,
                    borderWidth: borders.line,
                    background: colors.grey50
                }}
            >
                {
                    orderHistoryHeaders.map((item) => {
                        return renderPurchaseHistoryHeaderItem(item);
                    })
                }
            </div>
        </div>;
    };

    const renderPurchaseHistory = () => {
        return <div>
            {
                purchaseHistoryLoading || orderHistoryLoading ?
                    <Loading />:
                    <div>
                        {renderOrderHistoryHeaders()}
                        {renderOrderHistoryContent()}
                        {renderPurchaseHistoryHeaders()}
                        {renderPurchaseHistoryContent()}
                    </div>
            }
        </div>;
    };

    return isLoading ? <Loading /> : <div>
        {renderHeader()}
        {views === "purchaseHistory" ? renderPurchaseHistory() : renderMainContent()}
    </div>;
};

export default Subscriptions;
