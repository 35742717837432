import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        display: "flex",
        width: "100%"
    },
    content: {
        flexDirection: "row",
        display: "flex"
    },
    ınfoContainer: {
        flexDirection: "column",
        display: "flex"
    },
    dropDownContainer: {
        flexDirection: "column",
        position: "absolute",
        borderStyle: "solid",
        display: "flex",
        zIndex: 9999,
        width: "15%",
        right: 0
    },
    chartContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        borderRadius: "200px",
        display: "flex",
        height: "30px",
        width: "30px"
    },
    menuContainer: {
        justifyContent: "center",
        alignItems: "center",
        cursor:"pointer",
        display: "flex"
    }
});

export default useStyles;