import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    wrapper: {
        overflow: "hidden"
    },
    questionContainer: {
        justifyContent: "space-between",
        background: "transparent",
        alignItems: "center",
        textAlign: "left",
        cursor: "pointer",
        display: "flex",
        "&:hover": {
            backgroundImage: "linear-gradient(90deg, transparent, #45BBD120, transparent)"
        }
    },
    questionContainerActive: {
        justifyContent: "space-between",
        background: "transparent",
        alignItems: "center",
        textAlign: "left",
        cursor: "pointer",
        display: "flex",
        "&:hover": {
            backgroundImage: "linear-gradient(90deg, transparent, #45BBD120, transparent)"
        }
    },
    answerContainer: {
        transition: "height .7s ease-in-out",
        borderTopStyle: "solid",
    },
    accordionCardContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        display: "flex"
    }
});
