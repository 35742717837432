import {
    ReactNode,
    Fragment,
    useEffect
} from "react";
import {
    NavigationContextType,
    HamburgerContextType,
    NavbarContextType,
    URLDataType
} from "./type";
import NContext, {
    ConfigType
} from "ncore-context";
import {
    NAV_LINKS_WITHOUT_LOGIN
} from "../../constants";
import Header from "../../components/header";
import {
    HamburgerMenu,
    SidebarMenu 
} from "../../components";
import {
    useLocation 
} from "react-router-dom";
import useStyles from "./style";

class HamburgerContextService extends NContext<HamburgerContextType, ConfigType<HamburgerContextType>> {
};

class NavigationContextService extends NContext<NavigationContextType, ConfigType<NavigationContextType>> {
};

class NavbarContextInheritence<T extends NavbarContextType> extends NContext<NavbarContextType, ConfigType<NavbarContextType>> {
    navLinks;
    HamburgerContext: HamburgerContextService;
    NavigationContext: NavigationContextService;

    constructor(initialState: T) {
        super(
            initialState,
            {
                key: "navbar-context"
            }
        );
        this.navLinks = initialState.navLinks;
        this.HamburgerContext = new HamburgerContextService({
            isActive: false
        }, {
            key: "hamburger-context"
        });
        this.NavigationContext = new NavigationContextService({
            activePath: window.location.pathname,
            activeHref: ""
        }, {
            key: "navigation-context"
        });
    }

    openMenu = () => {
        this.HamburgerContext.setState({
            isActive: true
        });
    };

    closeMenu = () => {
        this.HamburgerContext.setState({
            isActive: false
        });
    };

    Provider = ({
        children
    }: {
        children: ReactNode
    }) => {
        const location = useLocation();
        const classes = useStyles();

        useEffect(() => {
            const url = new URLSearchParams(location.search);
    
            let _urlData: URLDataType = {
            };

            // @ts-ignore
            for (const [key, value] of url.entries()) {
                if (key === "href") { _urlData.href = value; }
            }
        
            this.NavigationContext.setState({
                activePath: location.pathname,
                activeHref: _urlData.href
            });
        }, [location]);

        return <this.HamburgerContext.Provider>
            <this.NavigationContext.Provider>
                <Fragment>
                    <Header />
                    <div
                        className={classes.container}
                    >
                        <SidebarMenu />
                        {children}
                    </div>
                    <HamburgerMenu
                        userType="worker"
                    />
                </Fragment>
            </this.NavigationContext.Provider>
        </this.HamburgerContext.Provider>;
    };
}

const NavbarContextInherit = new NavbarContextInheritence({
    navLinks: NAV_LINKS_WITHOUT_LOGIN
});
export default NavbarContextInherit;
