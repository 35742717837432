import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1
    },
    contentContianer: {
        boxSizing: "border-box",
        flexDirection: "row",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        height: "100%",
        width:"100%",
        "@media screen and (max-width: 1100px)": {
            display: "none"
        }
    },
    responsiveContentContianer: {
        boxSizing: "border-box",
        flexDirection: "row",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        height: "100%",
        width:"100%",
        "@media screen and (min-width: 1100px)": {
            display: "none"
        }
    },
    leftContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    mainContainer: {
        display: "flex",
        "@media screen and (max-width: 1100px)": {
            gap: "0 !important"
        }
    },
    filterContainer: {
        "@media screen and (max-width: 1100px)": {
            gap: "0 !important"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        width: "100%",
        "@media (max-width: 1400px)": {
            display: "none"
        }
    },
    searchContianer: {
        borderBottomStyle: "solid",
        borderTopStyle: "solid",
        boxSizing: "border-box",
        width: "100%",
        "@media (max-width: 1100px)": {
            display: "none"
        }
    },
    searchContainerInner: {
        boxSizing: "border-box",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        width: "100%"
    },
    searchContainerResponsive: {
        borderBottomStyle: "solid",
        borderTopStyle: "solid",
        boxSizing: "border-box",
        flexDirection: "column",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (min-width: 1100px)": {
            display: "none"
        }
    },
    noResultContainer: {
        backgroundColor: "white",
        boxSizing: "border-box",
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        width: "100%",
        height: 320
    },
    noResultContent: {
        flexDirection: "column",
        display: "flex"
    },
    indicatorContainer: {
        alignContent: "space-between",
        flexDirection: "column",
        display: "flex"
    },
    indicatorTitleContent: {
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    indicatorInfoContent: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems:"center",
        display: "flex",
        width: "100%",
        "@media (max-width: 1100px)": {
            display: "none"
        }
    },
    choosenFiltersContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%",
        "@media (max-width: 600px)": {
            display: "none"
        }
    },
    choosenFiltersTitle: {
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    choosenFiltersChip: {
        display: "flex",
        flexWrap: "wrap"
    },
    searchResultsContainer: {
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "start",
        minWidth: "100%",
        display: "flex"
    },
    paginationContainer: {
        borderStyle: "solid",
        alignSelf: "center",
        display: "flex",
        "@media (max-width: 1100px)": {
            display: "none"
        }
    },
    responsivePaginationContainer: {
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        alignSelf: "center",
        display: "flex",
        width: "100%",
        "@media (min-width: 600px)": {
            display: "none"
        }
    },
    responsivePaginationContent: {
        flexDirection: "row",
        display: "flex"
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex",
        width: "100%"
    },
    sortContainer: {
        flexDirection: "column",
        position: "absolute",
        borderStyle: "solid",
        display: "flex",
        width:"100%",
        zIndex: 99,
        top: 45
    },
    popularAdsChip: {
        flexDirection: "row",
        display: "flex",
        flexWrap: "wrap"
    },
    mostSearchedContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    renderViewMore: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        minWidth: "500px",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 1100px)": {
            textAlign: "center",
            minWidth: "unset"
        },
    },
    renderBluredContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        pointerEvents: "none",
        filter: "blur(2px)",
        userSelect: "none",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media (max-width: 1100px)": {
            display: "none"
        }
    },
    dataContainer: {
        height: "100%",
        flexShrink: 1,
        flexGrow: 1
    },
    sortDropdown: {
        position: "relative",
        "@media (max-width: 1100px)": {
            display: "none"
        }
    },
    searchTextInput: {
        minWidth: 550
    },
    searchTextButton: {
        minWidth: 85
    },
    responsiveSearchTextInput: {
        minWidth: 500,
        "@media (max-width: 600px)": {
            minWidth: 300,
        }
    },
});
