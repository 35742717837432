import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    WidgetAddIcon,
    DocumentsIcon,
    DiplomaIcon,
    MapIcon
} from "../../../assets/svgr";
import {
    RESTService
} from "../../../services/restAPI";
import {
    GetWorkerProfileForEmployerResponseTypes
} from "../../../services/restAPI/actions/getWorkerProfileForEmployer/types";
import InfoCard from "./infoCard";
import CvCard from "./cvCard";
import {
    useLocation, 
    useNavigate,
    useParams
} from "react-router-dom";
import {
    getErrorText 
} from "../../../utils";
import IsinolacakPlaceholderImage from "../../../assets/img/isinolacakMiniWithPadding.png";

const WorkerProfileForEmployer = () => {
    const navigate = useNavigate();
    const urlParams = useParams();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        state 
    } = useLocation();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getWorkerProfileForEmployer, setWorkerProfileForEmployer] = useState<GetWorkerProfileForEmployerResponseTypes>();
    const [licanceOrCertificates, setLicanceOrCertificates] = useState(false);
    const [expreienceSeeAll, setExpreienceSeeAll] = useState(false);
    const [languagesSeeAll, setLanguagesSeeAll] = useState(false);
    const [educationSeeAll, setEducationSeeAll] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ID, setID] = useState("");

    useEffect(() => {
        if(state) {
            setID(state.workerID);
            handleGetCompanyProfileForWorker(state.workerID);
        } else if (urlParams.id) {
            setID(urlParams.id);
            handleGetCompanyProfileForWorker(urlParams.id);
        }  else {
            navigate("/notFound");
        }
    }, []);

    useEffect(() => {
        handleGetCompanyProfileForWorker(ID);
    }, []);

    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
  

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleGetCompanyProfileForWorker = (id: string) => {
        setLoading(true);
        if(id) {
            RESTService.action("GetWorkerProfileForEmployer", {
                workerID: id
            }).then((res) => {
                setWorkerProfileForEmployer(res);
                setLoading(false);
            }).catch((err) => {
                console.error("ERR", err);
                setLoading(true);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }

                alert(localize("invalid-request"));
            });
        }
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                marginBottom: spaces.content * 2,
                backgroundColor: colors.stroke,
                marginTop: spaces.content * 2
            }}
        >
        </div>;
    };

    const handleExperienceSeeAllShow = () => {
        setExpreienceSeeAll(!expreienceSeeAll);
    };

    const handleEducationSeeAllShow = () => {
        setEducationSeeAll(!educationSeeAll);
    };

    const handleLicanceOrCertificatesSeeAllShow = () => {
        setLicanceOrCertificates(!licanceOrCertificates);
    };

    const handleLanguageSeeAllShow = () => {
        setLanguagesSeeAll(!languagesSeeAll);
    };
    
    const renderLeftContainer = () => {
        if(!getWorkerProfileForEmployer?.userInfo?.about) {
            return <div
                className={classes.leftContainer}
                style={{
                    padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    top: spaces.content * 2,
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("about")}
                </Text>
                <InfoCard
                    text={localize("not-yet-added")}
                />
            </div>;
        }
        
        return <div
            className={classes.leftContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2,
            }}
        >
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("about")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content * 1,
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {getWorkerProfileForEmployer?.userInfo.about}
                </Text>
            </div>
        </div>;
    };

    const renderUserInfoHeader = () => {
        return <div
            className={classes.userInfoHeaderContainer}
        >
            <div
                className={classes.userInfoHeaderImageContent}
            >
                <img
                    className={classes.userInfoHeaderImage}
                    style={{
                        borderRadius: radiuses.quarter * 20,
                        borderColor: colors.primary,
                        marginTop: spaces.content,
                    }}
                    src={getWorkerProfileForEmployer?.userInfo.photoURL ? getWorkerProfileForEmployer.userInfo.photoURL : IsinolacakPlaceholderImage}
                    alt="Profile"
                />
                <div
                    className={classes.userInfoHeaderText}
                    style={{
                        marginTop: spaces.content
                    }}
                >
                    <Text
                        variant="header4-medium"
                        color="textDark"
                    >
                        {getWorkerProfileForEmployer?.userInfo.firstName} {getWorkerProfileForEmployer?.userInfo.lastName}
                    </Text>
                    <Text
                        variant="body2-medium"
                        color="textSecondary"
                    >
                        {getWorkerProfileForEmployer?.userInfo?.occupation?.localizedText ? 
                            `${getWorkerProfileForEmployer.userInfo.occupation.localizedText} at ${getWorkerProfileForEmployer.workExperience[0]?.companyName}` 
                            : localize("no-position-ınformation")}
                    </Text>
                </div>
                <div
                    className={classes.userInfoHeaderEndText}
                    style={{
                        marginTop: spaces.content
                    }}
                >
                    <Text
                        variant="body2-regular"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2
                        }}
                    >
                        {getWorkerProfileForEmployer?.education[0]?.school ? getWorkerProfileForEmployer?.education[0].school : localize("no-university-ınformation")}
                    </Text>
                    <Text
                        variant="body2-regular"
                        color="textDark"
                    >
                        {`${getWorkerProfileForEmployer?.userInfo?.city?.localizedText} - Türkiye` ? `${getWorkerProfileForEmployer?.userInfo.city.localizedText} - Türkiye` : localize("no-location-ınformation")}
                    </Text>
                </div>
            </div>
            {renderLine()}
        </div>;
    };

    const renderExperience = () => {
        if(!getWorkerProfileForEmployer?.workExperience?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("experience")}
                </Text>
                <InfoCard
                    text={localize("no-experience-added-yet")}
                />
            </div>;
        }

        const displayedExperiences = expreienceSeeAll ? getWorkerProfileForEmployer?.workExperience : getWorkerProfileForEmployer?.workExperience.slice(0, 2);

        return  <div>
            <div
                className={classes.experienceContainer}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("experience")}
                </Text>
                {getWorkerProfileForEmployer?.workExperience && <Button
                    title={expreienceSeeAll ? localize("collapse") : localize("see-all")}
                    onClick={() => {handleExperienceSeeAllShow();}}
                    variant="ghost"
                />}
            </div>
            {<div
                className={classes.experienceCardContainer}
            >
                {displayedExperiences?.map((item, index) => {
                    return <CvCard
                        desc={`${new Date(item.startDate).toLocaleDateString()} - ${item.isStillWorkHere ? "Present" : new Date(item.endDate).toLocaleDateString()}`}
                        image={<DocumentsIcon color={colors.primary}/>}
                        title={item.jobTitle.localizedText}
                        subTitle={item.companyName}
                        key={index}
                    />;
                })}
            </div>}
            {renderLine()}
        </div>;
    };
    
    const renderEducation = () => {
        if(!getWorkerProfileForEmployer?.education?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("education")}
                </Text>
                <InfoCard
                    text={localize("no-education-added-yet")}
                />
            </div>;
        }

        const displayedEducation = educationSeeAll ? getWorkerProfileForEmployer?.education : getWorkerProfileForEmployer?.education.slice(0, 2);
        return <div>
            <div
                className={classes.educationContainer}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("education")}
                </Text>
                <Button
                    title={educationSeeAll ? localize("collapse") : localize("see-all")}
                    onClick={() => {handleEducationSeeAllShow();}}
                    variant="ghost"
                />
            </div>
            <div
                className={classes.educationCardContainer}
            >
                {displayedEducation?.map((item, index) => {
                    return <CvCard
                        desc={`${new Date(item.startDate).toLocaleDateString()} - ${new Date(item.endDate).toLocaleDateString()}`}
                        subTitle={`${item?.educationLevel?.localizedText}, ${item.major}`}
                        image={<DiplomaIcon/>}
                        title={item.school}
                        key={index}
                    />;
                })}
            </div>
            {renderLine()}
        </div>;
    };

    const renderLicensesAndCertificates = () => {
        if(!getWorkerProfileForEmployer?.licencesAndCertificates?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("license-and-certificate")}
                </Text>
                <InfoCard
                    text={localize("no-license-and-certification-added-yet")}
                />
            </div>;
        }

        const displayedLicensesAndCertificates = licanceOrCertificates ? getWorkerProfileForEmployer?.licencesAndCertificates : getWorkerProfileForEmployer?.licencesAndCertificates.slice(0, 2);
        return <div>
            <div
                className={classes.licanceOrCertificatesContainer}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("license-and-certificate")}
                </Text>
                {getWorkerProfileForEmployer?.licencesAndCertificates && <Button
                    title={licanceOrCertificates ? localize("collapse") : localize("see-all")}
                    onClick={() => {handleLicanceOrCertificatesSeeAllShow();}}
                    variant="ghost"
                />}
            </div>
            <div
                className={classes.licanceOrCertificatesCardContainer}
            >
                {displayedLicensesAndCertificates?.map((item, index) => {
                    return <CvCard
                        image={<DocumentsIcon color={colors.primary}/>}
                        desc={`${item.takenAtMonth} - ${item.takenAtYear}`}
                        subTitle={item.givenByOrganization}
                        title={item.name}
                        key={index}
                    />;
                })}
            </div>
            {renderLine()}
        </div>;
    };

    const renderLanguages = () => {
        if(!getWorkerProfileForEmployer?.foreignLanguages?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("language")}
                </Text>
                <InfoCard
                    text={localize("no-language-added-yet")}
                />
            </div>;
        }

        const displayedLanguages = licanceOrCertificates ? getWorkerProfileForEmployer?.foreignLanguages : getWorkerProfileForEmployer?.foreignLanguages.slice(0, 2);
        return <div
            style={{
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.licanceOrCertificatesContainer}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("language")}
                </Text>
                <Button
                    title={languagesSeeAll ? localize("collapse") : localize("see-all")}
                    onClick={() => {handleLanguageSeeAllShow();}}
                    variant="ghost"
                />
            </div>
            <div
                style={{
                    marginTop: spaces.content,
                 
                    display: "flex"
                }}
            >
                <div
                    className={classes.licanceOrCertificatesCardContainer}
                    style={{
                        gap: spaces.container
                    }}
                >
                    {displayedLanguages?.map((item, index) => {
                        return <div>
                            <CvCard
                                image={<MapIcon/>}
                                subTitle={item.level.localizedText}
                                title={item.language.localizedText}
                            />
                        </div>;
                    })}
                </div>
            </div>
            {renderLine()}
        </div>;
    };

    const renderSkills = () => {
        if(!getWorkerProfileForEmployer?.capabilities?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.content 
                    }}
                >
                    {localize("abilities")}
                </Text>
                <InfoCard
                    text={localize("no-skills-added-yet")}
                />
            </div>;
        }

        return <div
            style={{
                marginTop: spaces.content
            }}
        >
            <Text
                variant="header4-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.content 
                }}
            >
                {localize("abilities")}
            </Text>
            <div
                className={classes.capabilitiesTopContainer}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.inline
                }}
            >
                {getWorkerProfileForEmployer?.capabilities.map((item, index) => {
                    
                    return <div
                        className={classes.capabilitiesContainer}
                        style={{
                            borderRadius: radiuses.quarter * 20,
                            borderColor: colors.primary,
                            borderWidth: borders.line,
                            padding: spaces.content
                        }}
                        key={index}
                    >
                        <Text
                            variant="body-regular"
                            color="primary"
                        >
                            {item.localizedText}
                        </Text>
                    </div>;
                })}
            </div>
            {renderLine()}
        </div>;
    };

    const renderMemberships = () => {
        if(!getWorkerProfileForEmployer?.associations?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    {localize("association-or-organization-membership")}
                </Text>
                <InfoCard
                    text={localize("no-associations-an-organizations-added-yet")}
                />
            </div>;
        }

        return <div
            style={{
                marginBottom: spaces.content
            }}
        >
            <Text
                variant="header4-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.content
                }}
            >
                {localize("association-or-organization-membership")}
            </Text>
            {getWorkerProfileForEmployer?.associations.map((item, index) => {
                return <div
                    className={classes.assoaciationsContainer}
                    key={index}
                >
                    {index === item.length - 1 && renderLine()}
                    <Text
                        variant="body2-regular"
                        color="textDark"
                        style={{
                            marginTop: spaces.content
                        }}
                    >
                        {`Dernek - ${item}`}
                    </Text>
                </div>;
            })}
            {renderLine()}
        </div>;
    };

    const renderHobbies = () =>{
        if(!getWorkerProfileForEmployer?.hobbies?.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    {localize("hobbies")}
                </Text>
                <InfoCard
                    text={localize("no-hobbies-added-yet")}
                />
            </div>;
        }

        return <div
            className={classes.hobbiesContainer}
            style={{
                marginBottom: spaces.content
            }}
        >
            <Text
                variant="header4-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.content
                }}
            >
                {localize("hobbies")}
            </Text>
            <Text
                variant="body3-regular"
                color="textGrey"
                style={{
                    marginTop: spaces.content / 2
                }}
            >
                {getWorkerProfileForEmployer?.hobbies}
            </Text>
            {renderLine()}
        </div>;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderUserInfoHeader()}
            {renderExperience()}
            {renderEducation()}
            {renderLicensesAndCertificates()}
            {renderLanguages()}
            {renderSkills()}
            {renderMemberships()}
            {renderHobbies()}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon 
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => navigate("/app/newAdvert")}
            />
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${!isSmall ? spaces.container * 8 : 0}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("person-profile")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            {renderLeftContainer()}
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
    </div>;
};

export default WorkerProfileForEmployer;
