import {
    Fragment,
    useState, 
} from "react";
import {
    useStyles 
} from "./styles";
import RowCardAccordionItem from "../rowCardAccordionItem";
import {
    RowCardAccordionProps 
} from "./types";

const RowCardAccordion = ({
    setClickedChipId,
    clickedChipId, 
    data
}: RowCardAccordionProps) => {
    const classes = useStyles();

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    
    const handleItemClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return <div className={classes.container}>
        {data && data.length > 0 && data.map((item, index) => (
            <Fragment>
                <Fragment key={item[0].categoryID}>
                    <RowCardAccordionItem
                        setClickedChipId={setClickedChipId}
                        isClickedChip={item[0].categoryID === clickedChipId}
                        onClick={() => handleItemClick(index)}
                        isOpen= {activeIndex === index}
                        question={item[0].category}
                        answerData={item}
                    />
                </Fragment>
            </Fragment>
        ))}
    </div>;
};

export default RowCardAccordion;
