import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Bell = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M9.493 26.539A6.48 6.48 0 0 0 14.6 29a6.48 6.48 0 0 0 5.107-2.461c-3.39.459-6.824.459-10.214 0Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M24.049 10.8v.986c0 1.183.337 2.34.97 3.324l1.55 2.412c1.417 2.203.335 5.198-2.127 5.894a36.11 36.11 0 0 1-19.683 0c-2.464-.697-3.545-3.691-2.129-5.894l1.55-2.412c.634-.985.971-2.141.971-3.324V10.8c0-5.412 4.23-9.8 9.449-9.8 5.218 0 9.449 4.388 9.449 9.8ZM11.9 11.85c-.559 0-1.012-.47-1.012-1.05 0-.58.453-1.05 1.012-1.05h5.4c.41 0 .778.256.935.648.157.393.07.844-.22 1.145l-3.67 3.807H17.3c.559 0 1.012.47 1.012 1.05 0 .58-.453 1.05-1.012 1.05h-5.4c-.41 0-.778-.256-.935-.648a1.079 1.079 0 0 1 .22-1.144l3.67-3.808H11.9Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Bell;
