import {
    LocaleConfig
} from "isinolacak-web-cl/lib/types";
import tr from "./variants/tr";
import en from "./variants/en";

const locales: LocaleConfig[] = [
    tr,
    en
];

export {
    default as en
} from "./variants/en";
export {
    default as tr
} from "./variants/tr";
export default locales;
