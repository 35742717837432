import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    ForeignLanguageSelectedItem,
    InputYearsSelectedItems,
    MonthSelectedItems,
    SelectedItems,
    OtherTypes
} from "./types";
import {
    PlusIcon
} from "../../../../../../../../assets/svgr";
import Info from "../../../../../../../../assets/svgr/infoIcon";
import LicenceOrCertification from "./views/licanceOrCertificates";
import ForeignLanguages from "./views/foreignLanguages";
import Capabilities from "./views/capabilities";
import Associations from "./views/associations";

const Other = ({
    setSearchForeignLanguagesText,
    setForeignLanguagesTotalData,
    searchForeignLanguagesText,
    foreignLanguagesTotalData,
    setLicenceOrCertificates,
    setForeignLanguagesPage,
    setGetForeignLanguages,
    licenceOrCertificates,
    foreignLanguagesPage,
    setForeignLanguages,
    getForeignLanguages,
    foreignLanguages,
    setCapabilities,
    setAssociations,
    associations,
    capabilities,
    setErrors,
    errors
}: OtherTypes) =>  {
    const classes = useStyles();
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const onChangeSkill = (selectedItem: SelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(capabilities));
        newData[index].localizedText = selectedItem.localizedText;
        newData[index].capabilityID = selectedItem._id;
        setCapabilities(newData);
    };

    const onChangeLevel = (selectedItem: {point: number}, index: number) => {
        let newData = JSON.parse(JSON.stringify(capabilities));
        newData[index].point = selectedItem;
        setCapabilities(newData);
    };

    const onChangeForeignLanguage = (selectedItem: SelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(foreignLanguages));
        newData[index].language.localizedText = selectedItem.localizedText;
        newData[index].language._id = selectedItem._id;
        setForeignLanguages(newData);
    };

    const onChangeForeignLevel = (selectedItem: ForeignLanguageSelectedItem, index: number) => {
        let newData = JSON.parse(JSON.stringify(foreignLanguages));
        newData[index].level.levelLocalizedText = selectedItem.levelLocalizedText;
        newData[index].level.levelID = selectedItem.levelID;
        setForeignLanguages(newData);
    };

    const onChangeName = (text: string, index: number) => {
        let newData = JSON.parse(JSON.stringify(licenceOrCertificates));
        newData[index].name = text;
        setLicenceOrCertificates(newData);
    };

    const onChangeOrganization = (text: string, index: number) => {
        let newData = JSON.parse(JSON.stringify(licenceOrCertificates));
        newData[index].givenByOrganization = text;
        setLicenceOrCertificates(newData);
    };

    const onChangeStartDate = (selectedItem: MonthSelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(licenceOrCertificates));
        newData[index].takenAtMonth = Number(selectedItem.number);
        setLicenceOrCertificates(newData);
    };

    const onChangeEndDate = (selectedItem: InputYearsSelectedItems, index: number) => {
        let newData = JSON.parse(JSON.stringify(licenceOrCertificates));
        newData[index].takenAtYear = selectedItem.year;
        setLicenceOrCertificates(newData);
    };

    const onChangeOssiciationName = (text: string, index: number) => {
        let newData = JSON.parse(JSON.stringify(associations));
        newData[index] = text;
        setAssociations(newData);
    };

    const removeCapabilities = (index: number) => {
        let updatedErrors = JSON.parse(JSON.stringify(errors));
        const nameErrorCapabilityID = `capabilities-${index}-capabilityID-length-error`;
        const nameErrorPoint = `capabilities-${index}-point-length-error`;
        updatedErrors = updatedErrors.filter((error: string) => error !== nameErrorCapabilityID && error !== nameErrorPoint);
        setErrors(updatedErrors);

        let updatedCapabilities = JSON.parse(JSON.stringify(capabilities));
        updatedCapabilities.splice(index, 1);
        setCapabilities(updatedCapabilities);
    };

    const renderSkillsMap = () => {  
        return capabilities.map((item, index: number) => (
            <Capabilities
                removeCapabilities={removeCapabilities}
                onChangeLevel={onChangeLevel}
                onChangeSkill={onChangeSkill}
                capabilities={capabilities}
                showExitIcon={index !== 0}
                setErrors={setErrors}
                errors={errors}
                index={index}
                key={index}
            />
        ));
    };

    const removeForeignLanguages = (index: number) => {
        let updatedErrors = JSON.parse(JSON.stringify(errors));
        const nameErrorLanguageID = `foreignLanguages-${index}-languageID-length-error`;
        const nameErrorLevelID = `foreignLanguages-${index}-levelID-length-error`;
        updatedErrors = updatedErrors.filter((error: string) => error !== nameErrorLanguageID && error !== nameErrorLevelID);
        setErrors(updatedErrors);

        let _foreignLanguages = JSON.parse(JSON.stringify(foreignLanguages));
        _foreignLanguages.splice(index, 1);
        setForeignLanguages(_foreignLanguages);
    };

    const renderForeignLanguagesMap = () => {  
        return foreignLanguages.map((item, index: number) => (
            <ForeignLanguages
                setSearchForeignLanguagesText={setSearchForeignLanguagesText}
                setForeignLanguagesTotalData={setForeignLanguagesTotalData}
                searchForeignLanguagesText={searchForeignLanguagesText}
                foreignLanguagesTotalData={foreignLanguagesTotalData}
                setForeignLanguagesPage={setForeignLanguagesPage}
                onChangeForeignLanguage={onChangeForeignLanguage}
                removeForeignLanguages={removeForeignLanguages}
                setGetForeignLanguages={setGetForeignLanguages}
                foreignLanguagesPage={foreignLanguagesPage}
                onChangeForeignLevel={onChangeForeignLevel}
                setForeignLanguages={setForeignLanguages}
                getForeignLanguages={getForeignLanguages}
                foreignLanguages={foreignLanguages}
                setErrors={setErrors}
                showExitIcon={true}
                errors={errors}
                index={index}
                data={item}
                key={index}
            />
        ));
    };

    const removeLicenceOrCertification = (index: number) => {
        let updatedErrors = JSON.parse(JSON.stringify(errors));
        const nameErrorName = `licenceOrCertificates-${index}-name-length-error`;
        const nameErrorGivenByOrganization = `licenceOrCertificates-${index}-givenByOrganization-length-error`;
        const nameErrorTakenAtMonth = `licenceOrCertificates-${index}-takenAtMonth-length-error`;
        const nameErrorTakenAtYear = `licenceOrCertificates-${index}-takenAtYear-length-error`;
    
        updatedErrors = updatedErrors.filter((error: string) =>
            error !== nameErrorName &&
            error !== nameErrorGivenByOrganization &&
            error !== nameErrorTakenAtMonth &&
            error !== nameErrorTakenAtYear
        );
        setErrors(updatedErrors);

        let _licenceOrCertificates = JSON.parse(JSON.stringify(licenceOrCertificates));
        _licenceOrCertificates.splice(index, 1);
        setLicenceOrCertificates(_licenceOrCertificates);
    };

    const renderLicenseOrCertificatesMap = () => { 
        return licenceOrCertificates.map((item, index: number) => (
            <LicenceOrCertification
                removeLicenceOrCertification={removeLicenceOrCertification}
                licenceOrCertificates={licenceOrCertificates}
                onChangeOrganization={onChangeOrganization}
                onChangeStartDate={onChangeStartDate}
                onChangeEndDate={onChangeEndDate}
                onChangeName={onChangeName}
                setErrors={setErrors}
                showExitIcon={true}
                errors={errors}
                index={index}
                key={index}
            />
        ));
    };

    const removeAssociations = (index: number) => {
        let updatedErrors = JSON.parse(JSON.stringify(errors));
        const nameErrorAssociations = `associations-${index}-associations-length-error`;
    
        updatedErrors = updatedErrors.filter((error: string) =>
            error !== nameErrorAssociations
        );
        setErrors(updatedErrors);

        let _associations = JSON.parse(JSON.stringify(associations));
        _associations.splice(index, 1);
        setAssociations(_associations);
    };

    const renderOssociationOrOrganizationMap = () => {
        if (!associations) {
            return null;
        }
    
        return associations.map((item, index: number) => (
            <Associations
                onChangeOssiciationName={onChangeOssiciationName}
                removeAssociations={removeAssociations}
                associations={associations}
                setErrors={setErrors}
                showExitIcon={true}
                errors={errors}
                index={index}
                key={index}
            />
        ));
    };

    const renderCapabilities = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content,
            }}
        >
            {renderSkillsMap()}
            <Button
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />}
                title={localize("add-capabilities")}
                onClick={() => {
                    let _capabilities = JSON.parse(JSON.stringify(capabilities));
                    _capabilities.push({
                        localizedText: "",
                        capabilityID: "",
                        point: ""
                    });
                    setCapabilities(_capabilities);
                }}
                variant='ghost'
            />
        </div>;
    };

    const renderEmptyForeignLanguagesInfo = () => {
        return <div
            className={classes.emptyComapnyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.content,
                gap: spaces.inline,
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderForeignLanguages = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content,
            }}
        >
            {
                foreignLanguages.length === 0 && <div>
                    <Text
                        variant='body-regular'
                        color='textDark'
                    >
                        {localize("language")}
                    </Text>
                    {renderEmptyForeignLanguagesInfo()}
                </div>
            }
            {renderForeignLanguagesMap()}
            <Button
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />}
                title={localize("add-language")}
                onClick={() => {
                    let _foreignLanguages = JSON.parse(JSON.stringify(foreignLanguages));
                    _foreignLanguages.push({
                        language: {
                            localizedText: "",
                            _id: ""
                        },
                        level: {
                            localizedText: "",
                            _id: ""
                        }
                    });
                    setForeignLanguages(_foreignLanguages);
                }}
                variant='ghost'
            />
        </div>;
    };

    const renderEmptyLicenseOrCertificatesInfo = () => {
        return <div
            className={classes.emptyComapnyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.content,
                gap: spaces.inline,
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderLicenseOrCertificates = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content,
            }}
        >
            {
                licenceOrCertificates.length === 0 && <div>
                    <Text
                        variant='body-regular'
                        color='textDark'
                    >
                        {localize("license-and-certificate")}
                    </Text>
                    {renderEmptyLicenseOrCertificatesInfo()}
                </div>
            }
            {renderLicenseOrCertificatesMap()}
            <Button
                title={localize("add-license-and-certificate")}
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />}
                onClick={() => {
                    let _licenceOrCertificates = JSON.parse(JSON.stringify(licenceOrCertificates));
                    _licenceOrCertificates.push({
                        givenByOrganization: "",
                        takenAtMonth: "",
                        takenAtYear: "",
                        name: ""
                    });
                    setLicenceOrCertificates(_licenceOrCertificates);
                }}
                variant='ghost'
            />
        </div>;
    };

    const renderEmptyOssociationInfo = () => {
        return <div
            className={classes.emptyComapnyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.content,
                gap: spaces.inline,
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderOssociation = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content,
            }}
        >
            {
                associations?.length === 0 && <div>
                    <Text
                        variant='body-regular'
                        color='textDark'
                    >
                        {localize("association-or-organization-membership")}
                    </Text>
                    {renderEmptyOssociationInfo()}
                </div>
            }
            {renderOssociationOrOrganizationMap()}
            <Button
                title={localize("add-association-or-organization-membership")}
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={19}
                />}
                onClick={() => {
                    let _associations = JSON.parse(JSON.stringify(associations));
                    _associations.push("");
                    setAssociations(_associations);
                }}
                variant='ghost'
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderCapabilities()}
            {renderForeignLanguages()}
            {renderLicenseOrCertificates()}
            {renderOssociation()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default Other;
