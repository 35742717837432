import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Share = ({
    color = "#9AA6B8",
    size = 22,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M15.652 1a3.341 3.341 0 0 0-3.297 3.922L7.481 8.32a.78.78 0 0 0-.07.055 3.344 3.344 0 0 0-2.063-.707A3.34 3.34 0 0 0 2 11a3.34 3.34 0 0 0 3.348 3.333c.778 0 1.494-.264 2.063-.707.022.02.045.038.07.056l4.874 3.396A3.341 3.341 0 0 0 15.651 21 3.341 3.341 0 0 0 19 17.667c0-1.841-1.5-3.334-3.348-3.334a3.348 3.348 0 0 0-2.661 1.31l-4.615-3.216c.206-.433.321-.916.321-1.427 0-.51-.115-.994-.321-1.427l4.615-3.216a3.348 3.348 0 0 0 2.66 1.31A3.341 3.341 0 0 0 19 4.333C19 2.493 17.5 1 15.652 1Zm-1.803 3.333c0-.991.807-1.795 1.803-1.795.995 0 1.803.804 1.803 1.795 0 .992-.808 1.795-1.803 1.795a1.799 1.799 0 0 1-1.803-1.795Zm-8.5 4.872c-.996 0-1.804.804-1.804 1.795 0 .991.808 1.795 1.803 1.795.996 0 1.804-.804 1.804-1.795 0-.991-.808-1.795-1.804-1.795Zm10.303 6.667c-.996 0-1.803.803-1.803 1.795 0 .99.807 1.794 1.803 1.794.995 0 1.803-.803 1.803-1.794 0-.992-.808-1.795-1.803-1.795Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Share;
