import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        height: "100%",
        width: "100%",
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    dialogBottom: {
        justifyContent: "space-evenly",
        flexDirection: "row",
        display: "flex"
    }
});

export default useStyles;