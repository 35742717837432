import axios from "axios";
import {
    SERVER_ADDRESS 
} from "../../constants";
import {
    ConversationEndResponseType,
    ConversationEndRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const ConversationEnd = async (params: ConversationEndRequestType): Promise<ConversationEndResponseType> => {
    return await axios(`${SERVER_ADDRESS}/chat/conversationEnd`, {
        method: "POST",
        headers: getHeaders(true),
        data: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }
            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default ConversationEnd;
