import { 
    useEffect,
    Fragment,
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    GetFAQsForAboutResponseTypes 
} from "../../../services/restAPI/actions/getFAQsForAbout/types";
import Accordion from "./components/rowCardAccordion";
import FAQChip from "./components/FAQChip";
import {
    Footer
} from "../../../components";
import {
    getBrowserLanguageFull 
} from "../../../utils";

const FAQ = () => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const [faqData, setFaqData] = useState<GetFAQsForAboutResponseTypes>([]);
    const [clickedChipId, setClickedChipId] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getFaqData();
    }, []);

    const getFaqData = () => {
        const params = {
            language: getBrowserLanguageFull()
        };

        if(!isLoading) {
            setIsLoading(true);
        }

        RESTService.action("GetFAQsForAbout", params)
            .then((res) => {
                setFaqData(res);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error("GetFAQsForAbout error:", err);
            });
    };

    const seperateItIntoCategories = () => {
        const categoriedFAQs: {
            [key: string]: GetFAQsForAboutResponseTypes
        } = {
        };
        if(faqData && faqData.length > 0) {
            for(let elm of faqData) {
                if(categoriedFAQs[elm.categoryID]) {
                    categoriedFAQs[elm.categoryID].push(elm);
                } else {
                    categoriedFAQs[elm.categoryID] = [elm];
                }
            }
        }
        return categoriedFAQs;
    };

    const renderChipContainer = () => {
        return <div
            className={classes.chipContainer}
            style={{
                gap: spaces.content
            }}>
            {faqData && faqData.length > 0 && Object.values(seperateItIntoCategories()).map(item => {
                return <Fragment key={item[0].categoryID + "chip"}>
                    <FAQChip 
                        title={item[0].category}
                        onClick={() => {
                            setClickedChipId(item[0].categoryID);
                        }} 
                    />
                </Fragment>;
            })  }
        </div>;
    };

    const renderTopTextContainer = () => {
        return <div 
            className={classes.renderTopContainer} 
            style={{
                gap: spaces.inline,
            }}>
            <Text 
                variant="header2-medium"
            >
                {localize("asked-questions")}
            </Text>
            <Text 
                variant="header5-regular"
                color="greyBase"
            >
                {localize("find-answers-to-your-questions-easily")}
            </Text>
        </div>;
    };

    const renderTopContainer = () => {
        return <div 
            className={classes.renderTopContainer} 
            style={{
                gap: spaces.container * 2,
            }}
        >
            {renderTopTextContainer()}
            {renderChipContainer()}
        </div>;
    };

    const renderBottomContainer = () => {
        return <Fragment>
            <div
                className={classes.renderBottomContainer}
                style={{
                    marginTop: spaces.containerXXLarge,
                    gap: spaces.container * 1.2
                }}>
                <Accordion clickedChipId={clickedChipId} setClickedChipId={setClickedChipId} data={Object.values(seperateItIntoCategories())}/>
            </div>
        </Fragment>;
    };

    const renderMainContent = () => {
        return <div 
            className={classes.mainContent} 
            style={{
                paddingBottom: spaces.containerXXLarge * 1.5,
                paddingTop: spaces.containerLarge * 1.5,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                gap: spaces.containerXXLarge / 1.5
            }}>
            {renderTopContainer()}
            {renderBottomContainer()}
        </div>;
        
    };

    const renderResponsiveMainContent = () => {
        return <div 
            className={classes.responsiveMainContent} 
            style={{
                paddingBottom: spaces.containerXXLarge * 1.5,
                paddingTop: spaces.containerLarge * 1.5,
                gap: spaces.containerXXLarge / 1.5,
                paddingRight: spaces.containerLarge,
                paddingLeft: spaces.containerLarge
            }}
        >
            {renderTopContainer()}
            {renderBottomContainer()}
        </div>;
    };

    if(isLoading) {
        return <div 
            className={classes.mainContent} 
            style={{
                paddingBottom: spaces.containerXXLarge * 1.5,
                paddingTop: spaces.containerLarge * 1.5,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                gap: spaces.containerXXLarge / 1.5
            }}>
            <Loading/>
        </div>;
    }

    return <div 
        className={classes.container}
    >
        {renderMainContent()}
        {renderResponsiveMainContent()}
        <Footer/>
    </div>;
};

export default FAQ;