import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    renderLine: {
        borderStyle: "solid",
        width: "99%"
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
});
export default useStyles;