import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    header: {
        justifyContent: "center",
        flexDirection: "row",
        display: "flex"
    },
    headerIcon: {
        cursor: "pointer"
    },
    ptListContainer: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    dialogBottom : {
        justifyContent: "space-evenly",
        flexDirection: "row",
        display: "flex"
    },
    loadingcontainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "500px",
        width: "100%"
    }
});

export default useStyles;