import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        cursor: "pointer",
        display: "flex",
        width: "100%"
    },
    content: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    icon: {
        cursor: "pointer"
    },
    infoContainer: {
        flexDirection: "column",
        display: "flex"
    },
    chartContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        borderRadius: "200px",
        display: "flex",
        height: "30px",
        width: "30px"
    },
    menuContainer: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    }
});

export default useStyles;