import {
    useEffect,
    useState
} from "react";
import useStyles from "./style";
import {
    PerformanceTestQuestionCardProps
} from "./types";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    VerticalDotsIcon,
    ChartIcon
} from "../../../assets/svgr";

const PerformanceTestQuestionCard = ({
    questionType,
    dropdownref,
    onDelete,
    question,
    onEdit
}: PerformanceTestQuestionCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [testOptions, setTestOptions] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownref.current && !dropdownref.current.contains(event.target as Node)) {
            setTestOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[]);

    const renderTestOptions = () => {
        if(!testOptions) {
            return null;
        }

        return <div
            className={classes.dropDownContainer}
            ref={dropdownref}
            style={{
                backgroundColor: colors.white,
                padding: spaces.content / 2,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content
            }}
        >
            <Button
                title={localize("edit")}
                textVariant='body3-regular'
                textColor='textGrey'
                variant='ghost'
                onClick={onEdit}
            />
            <Button
                title={localize("delete-question")}
                textVariant='body3-regular'
                textColor='textGrey'
                onClick={onDelete}
                variant='ghost'
            />
        </div>;
    };

    const renderInfoContainer = () => {
        return <div
            className={classes.ınfoContainer}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {question}
            </Text>
            <Text
                variant="body4-regular"
                color="textDark"
            >
                {questionType}
            </Text>
        </div>;
    };

    const renderChartIcon = () => {
        return <div
            className={classes.chartContainer}
            style={{
                borderColor: colors.primary,
                padding: spaces.inline / 4,
                borderWidth: borders.line
            }}
        >
            <ChartIcon/>
        </div>;
    };

    const renderMenuIcon = () => {
        return <div
            className={classes.menuContainer}
            onClick={() => {
                setTestOptions(true);
            }}
        >
            <VerticalDotsIcon
                color={colors.stroke}
            />
        </div>;
    };

    return <div
        className={classes.container}
        ref={dropdownref}
        style={{       
            padding: `${spaces.container}px ${spaces.containerXLarge / 2}px`,
            marginBottom: spaces.container,
            borderColor: colors.primary,
            borderRadius: radiuses.half,
            borderWidth: borders.line
        }}
    >
        <div
            className={classes.content}
            style={{
                gap: spaces.content
            }}
        >
            {renderChartIcon()}
            {renderInfoContainer()}
        </div>
        {renderMenuIcon()}
        {renderTestOptions()}
    </div>;
};

export default PerformanceTestQuestionCard;