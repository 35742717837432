import axios from "axios";
import {
    SERVER_ADDRESS 
} from "../../constants";
import {
    GetWorkerProfileForEmployerRequestTypes,
    GetWorkerProfileForEmployerResponseTypes
} from "./types";
import {
    getHeaders
} from "../../utils";

const GetWorkerProfileForEmployer = async (params: GetWorkerProfileForEmployerRequestTypes): Promise<GetWorkerProfileForEmployerResponseTypes> => {
    return await axios(`${SERVER_ADDRESS}/profile/getWorkerProfileForEmployer`, {
        method: "GET",
        headers: getHeaders(true),
        params: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default GetWorkerProfileForEmployer;
