import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderStyle: "solid",
        maxWidth: "28%",
        width: "100%",
        "@media screen and (max-width: 1690px)": {
            maxWidth: "40%"
        },
        "@media screen and (max-width: 1500px)": {
            maxWidth: "100%"
        },
        "@media screen and (max-width: 1040px)": {
            maxWidth: "40%"
        },
        "@media screen and (max-width: 700px)": {
            maxWidth: "100%"
        }
    },
    leftContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
    }
});
export default useStyles;
