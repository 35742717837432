import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Download = ({
    color = "#45BBD4",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M12.624 17.084a.846.846 0 0 1-1.248 0l-4.513-4.936a.846.846 0 0 1 1.249-1.142l3.042 3.327V1.846a.846.846 0 0 1 1.692 0v12.487l3.042-3.327a.846.846 0 0 1 1.25 1.142l-4.514 4.936Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M2.692 15.385a.846.846 0 0 0-1.692 0v.062c0 1.542 0 2.786.131 3.764.137 1.016.43 1.87 1.108 2.55.68.679 1.534.971 2.55 1.108C5.767 23 7.01 23 8.553 23h6.893c1.543 0 2.787 0 3.765-.131 1.016-.137 1.87-.43 2.55-1.108.679-.68.971-1.534 1.108-2.55.131-.978.131-2.222.131-3.764v-.062a.846.846 0 0 0-1.692 0c0 1.62-.002 2.749-.117 3.6-.111.829-.315 1.267-.627 1.58-.312.311-.75.515-1.578.626-.852.115-1.982.117-3.601.117h-6.77c-1.62 0-2.749-.002-3.6-.117-.829-.111-1.267-.315-1.58-.627-.311-.312-.515-.75-.626-1.578-.115-.852-.117-1.982-.117-3.601Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default Download;
