import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    CvCardProps
} from "./types";

const CvCard = ({
    subTitle,
    image,
    title,
    desc
}: CvCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderInfoContainer = () => {
        return <div
            className={classes.infoContainer}
            style={{
                marginLeft: spaces.content
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {title}
            </Text>
            <Text
                variant="body4-regular"
                color="textSecondary"
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {subTitle}
            </Text>
            <Text
                variant="body4-regular"
                color="textSecondary"
            >
                {desc}
            </Text>
        </div>;
    };

    const renderChartIcon = () => {
        return <div
            className={classes.chartContainer}
            style={{
                borderColor: colors.stroke,
                padding: spaces.inline / 4,
                borderWidth: borders.line
            }}
        >
            {image}
        </div>;
    };

    return <div
        className={classes.container}
        style={{       
            padding: `${spaces.container}px ${spaces.containerXLarge / 2}px`,
            marginBottom: spaces.container,
            borderRadius: radiuses.half,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        <div
            className={classes.content}
            style={{
                gap: spaces.content
            }}
        >
            {renderChartIcon()}
            {renderInfoContainer()}
        </div>
    </div>;
};

export default CvCard;