import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const X = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M23.051 2h4.294l-9.38 10.949L29 27.846h-8.64l-6.767-9.036-7.743 9.036H1.552l10.033-11.71L1 2h8.86l6.117 8.26L23.051 2Zm-1.506 23.221h2.38L8.565 4.487H6.013l15.533 20.734Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default X;
