import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledSquare = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15 30c-7.071 0-10.607 0-12.803-2.197C0 25.607 0 22.071 0 15 0 7.929 0 4.393 2.197 2.197 4.393 0 7.929 0 15 0c7.071 0 10.607 0 12.803 2.197C30 4.393 30 7.929 30 15c0 7.071 0 10.607-2.197 12.803C25.607 30 22.071 30 15 30Zm6.046-19.546c.439.44.439 1.152 0 1.591l-7.5 7.5c-.44.44-1.152.44-1.591 0l-3-3a1.125 1.125 0 0 1 1.59-1.59l2.205 2.204 6.704-6.704c.44-.44 1.152-.44 1.591 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledSquare;
