import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledTic = ({
    size = 30,
    color = "#16A34A",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M29 15c0 7.732-6.268 14-14 14S1 22.732 1 15 7.268 1 15 1s14 6.268 14 14Zm-8.358-4.242c.41.41.41 1.074 0 1.485l-7 7a1.05 1.05 0 0 1-1.484 0l-2.8-2.8a1.05 1.05 0 0 1 1.484-1.486l2.058 2.058 3.129-3.129 3.128-3.128a1.05 1.05 0 0 1 1.485 0Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default FilledTic;