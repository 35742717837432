import {
    createUseStyles
} from "react-jss";

export const useStyles = createUseStyles({
    informationalContent:{
        flexDirection: "column",
        display: "flex"
    },
    preferenceContainer:{
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    preferenceContent:{
        flexDirection: "column",
        display: "flex",
        width: "100%",
    },
    preferenceField:{
        flexDirection: "column",
        display: "flex",
    },
    preferenceType:{
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    preferenceBox:{
        flexDirection: "column",
        display: "flex",
    },
    container:{
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    header:{
        textAlign: "center",
        width: "100%"
    },
});
