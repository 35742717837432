import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const StickerUser = ({
    size = 30,
    color = "#2563EB",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <circle cx={15.316} cy={6.895} r={5.895} fill={color} transform={`scale(${1 / pathScale})`} />
        <ellipse cx={15.316} cy={23.105} fill={color} rx={10.316} ry={5.895} transform={`scale(${1 / pathScale})`} />
    </svg>;
};
export default StickerUser;
