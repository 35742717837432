import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading
} from "isinolacak-web-cl";
import {
    PersonalInfoEditingTypes, 
    PersonalInfoPropsTypes
} from "./types";
import {
    GetAreaCodesResponseType
} from "../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    useLocation 
} from "react-router-dom";
import CompanyPhoneNumberSmsOTP from "./views/companyNumberSmsOTP";
import CompanyEmailSmsOTP from "./views/companyEmailSmsOTP";
import CompanyPhoneNumber from "./views/companyPhoneNumber";
import PersonalInfoInput from "./views/defaultOpen";
import CompanyMail from "./views/companyMail";
import {
    getErrorText
} from "../../../../../../utils";

const PersonalInfo = ({
    setPersonalInfo,
    personalInfo,
    getMyProfile,
    loading
}: PersonalInfoPropsTypes) =>  {
    const location = useLocation();
    const classes = useStyles();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [views, setViews] = useState<PersonalInfoEditingTypes>("default-open");
    const [areaCodes, setAreaCodes] = useState<GetAreaCodesResponseType>();
    const [errors, setErrors] = useState<string[]>([]);
    const [token, setToken] = useState("");

    useEffect(() => {
        getAreaCodes();
    }, []);

    const getAreaCodes = () => {
        RESTService.action("GetAreaCodes",{
        })
            .then((res) => {
                setAreaCodes(res);
            })
            .catch((err) => {
                console.error("GetAreaCodes Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderContent = () => {
        return <div>
            {views === "company-mail-change" && <CompanyMail 
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                setErrors={setErrors}
                setToken={setToken}
                setViews={setViews}
                errors={errors}
                token={token}
            />
            }
            {views === "company-phone-number-change" && <CompanyPhoneNumber
                areaCodes={areaCodes}
                setErrors={setErrors}
                setViews={setViews}
                setToken={setToken}
                errors={errors}
                token={token}
            />
            }
            {views === "default-open" && <PersonalInfoInput
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                getMyProfile={getMyProfile}
                setErrors={setErrors}
                setViews={setViews}
                errors={errors}
            />}
            {views === "company-mail-sms" && <CompanyEmailSmsOTP
                setToken={setToken}
                token={token}
            />
            }
            {views === "company-number-sms" && <CompanyPhoneNumberSmsOTP
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                setToken={setToken}
                token={token}
            />
            }
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.container}
        style={{
            padding: spaces.content * 2,
        }}
    >
        {renderContent()}
    </div>;
};

export default PersonalInfo;
