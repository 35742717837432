import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        position: "absolute",
        userSelect: "none",
        display: "flex",
        right: 0,
        bottom:0,
        left: 0,
        top: 0,
        "@media (min-width: 650px)": {
            display: "none"
        }
    },
    headerContainer: {
        borderBottomStyle: "solid",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    headerContent: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    sortContainer: {
        borderBottomStyle: "solid",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex"
    },
    sortContent: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    sortItem: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    filtersContainer: {
        flexDirection: "column",
        display: "flex"
    },
    filterButtonContainer: {
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    citiesSelectContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    citiesSelectContent: {
        justifyContent: "space-between",
        flexDirection: "row",
        borderBottom: "solid",
        alignItems:"center",
        display: "flex"
    },
    occupationSelectContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        overflowX: "hidden",
        display: "flex",
        overflow: "auto",
        width: "100%"
    }
});
