import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const StickerNoUser = ({
    size = 30,
    color = "#2563EB",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <circle cx={14.8} cy={6.6} r={5.6} fill={color} transform={`scale(${1 / pathScale})`} />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M19.58 17.11c-1.413-.452-3.044-.71-4.78-.71C9.388 16.4 5 18.907 5 22c0 2.997 4.12 5.444 9.299 5.593a6.833 6.833 0 0 1-.09-.525c-.11-.809-.11-1.797-.109-2.848v-.24c0-1.051 0-2.04.108-2.848.121-.9.41-1.885 1.225-2.7.814-.813 1.799-1.103 2.699-1.224.437-.058.926-.085 1.449-.098Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M21.1 29c-2.31 0-3.465 0-4.182-.718-.718-.717-.718-1.872-.718-4.182 0-2.31 0-3.465.718-4.182.717-.718 1.872-.718 4.182-.718 2.31 0 3.465 0 4.182.718C26 20.635 26 21.79 26 24.1c0 2.31 0 3.465-.718 4.182C24.565 29 23.41 29 21.1 29Zm-1.6-7.655a.817.817 0 0 0-1.155 1.155l1.6 1.6-1.6 1.6a.817.817 0 1 0 1.155 1.155l1.6-1.6 1.6 1.6a.816.816 0 1 0 1.155-1.155l-1.6-1.6 1.6-1.6a.817.817 0 1 0-1.155-1.155l-1.6 1.6-1.6-1.6Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default StickerNoUser;
