import {
    AdvertDateSearchType, 
    SearchSortType
} from "../../../services/restAPI/actions/searchAdverts/types";
import {
    uuid
} from "../../../utils";
import {
    FilterSelectType
} from "./views/responsiveFilter/types";

export const SORT_FILTER: Array<{
    localizedText: keyof IOCore.TranslationType;
    value: SearchSortType
    id: string;
}> = [
    {
        id: uuid(),
        localizedText: "recomended",
        value: "recommended"
    },
    {
        id: uuid(),
        localizedText: "new-to-old",
        value: "newToOld"
    },
    {
        id: uuid(),
        localizedText: "old-to-new",
        value: "oldToNew"
    }
];

export const FILTERS: Array<{
    localizedText: keyof IOCore.TranslationType;
    value: FilterSelectType;
    id: string;
}> = [
    {
        id: uuid(),
        localizedText: "location-city",
        value: "citySelect"
    },
    {
        id: uuid(),
        localizedText: "work-preference",
        value: "workPreferenceSelect"
    },
    {
        id: uuid(),
        localizedText: "working-type",
        value: "workTypeSelect"
    },
    {
        id: uuid(),
        localizedText: "ads-date",
        value: "advertDateSelect"
    },
    {
        id: uuid(),
        localizedText: "job",
        value: "occupationSelect"
    },
    {
        localizedText: "position-level",
        value: "positionLevelSelect",
        id: uuid()
    },
    {
        id: uuid(),
        localizedText: "ads-for-disabled",
        value: "disabledAdsSelect"
    },
    {
        id: uuid(),
        localizedText: "other-ads-filters",
        value: "otherAdFiltersSelect"
    }
];

export const DATES_FILTER: Array<{
    localizedText: keyof IOCore.TranslationType;
    value: AdvertDateSearchType;
    id: string;
}> = [
    {
        localizedText: "all",
        value: "all",
        id: uuid()
    },
    {
        localizedText: "todays-ad",
        value: "today",
        id: uuid()
    },
    {
        localizedText: "last-3-hours",
        value: "last3Hours",
        id: uuid()
    },
    {
        localizedText: "last-12-hours",
        value: "last12Hours",
        id: uuid()
    },
    {
        localizedText: "this-weeks-ads",
        value: "thisWeek",
        id: uuid()
    }
];

export const NO_RESULT: Array<{
    localizeText: keyof IOCore.TranslationType;
    id: string;
}> = [
    {
        id: uuid(),
        localizeText: "no-result-1"
    },
    {
        id: uuid(),
        localizeText: "no-result-2"
    },
    {
        id: uuid(),
        localizeText: "no-result-3"
    },
    {
        id: uuid(),
        localizeText: "no-result-4"
    },
    {
        id: uuid(),
        localizeText: "no-result-5"
    }
];

export const DISABLED_ADS_FILTERS: Array<{
    id: string;
    value: "true" | "false" | "all"
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        value: "all",
        localizedText: "all",
    },
    {
        id: uuid(),
        value: "true",
        localizedText: "only-disabled-ads",
    },
    {
        id: uuid(),
        value: "false",
        localizedText: "no-disabled-ads"
    }
];

export const OTHER_FILTERS: Array<{
    id: string;
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        localizedText: "hide-revieved-ads"
    },
    {
        id: uuid(),
        localizedText: "hide-aplied-ads"
    },
    {
        id: uuid(),
        localizedText: "only-1-time-ads"
    }
];
