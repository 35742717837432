import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    quesitonContainer: {
        alignItems: "center",
        flexDirection: "row",
        minWidth: "100%",
        display:"flex",
        width: "100%"
    },
    questionIndex: {
        justifyContent: "center",
        alignItems: "center",
        minHeight: "20px",
        borderRadius: 100,
        minWidth: "20px",
        display: "flex",
        height: "20px",
        width: "20px"
    },
    buttonsContainer: {
        justifyContent:"space-between",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    }
});

export default useStyles;