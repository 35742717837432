import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        minWidth: 300,
        width: "100%",
        maxWidth: "24%",
        "&:hover": {
            transform: "scale(1.02)",
            cursor: "pointer"
        },
        "@media screen and (max-width: 1340px)": {
            maxWidth: "30%"
        },
        "@media screen and (max-width: 1000px)": {
            maxWidth: "48%"
        },
        "@media screen and (max-width: 690px)": {
            maxWidth: "100%"
        }
    },
    
    informationTextStyle: {
        flexGrow: 0
    },
    renderInformationsStyle: {
        boxSizing: "border-box",
        whiteSpace: "nowrap", 
        flexDirection: "row",
        alignItems: "start",
        overflow: "hidden", 
        display:"flex",
        width: "100%", 
        flexGrow: 1
    },
    informationTextContainer: {
        boxSizing: "border-box",
        whiteSpace: "nowrap", 
        overflow: "hidden", 
        width: "100%" 
    },
    informationTitleStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block"
    },
    imageStyle: {
        objectFit: "contain",
        borderStyle: "solid",
        borderWidth: 1,
        height: 55,
        width: 55
    },
    renderTagsStyle: {
        borderTopStyle: "solid",
        overflow: "hidden",
        flexWrap: "wrap",
        display: "flex",
        minHeight: 37,
        width: "97%"
    }
});

