import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Refresh = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M29 15c0 7.732-6.268 14-14 14S1 22.732 1 15 7.268 1 15 1s14 6.268 14 14ZM5.845 13.717a9.225 9.225 0 0 1 9.168-8.167 9.219 9.219 0 0 1 7.34 3.63 1.05 1.05 0 0 1-1.669 1.274 7.119 7.119 0 0 0-5.67-2.804 7.126 7.126 0 0 0-7.051 6.067h.508a1.05 1.05 0 0 1 .742 1.793l-1.635 1.633a1.05 1.05 0 0 1-1.484 0L4.458 15.51a1.05 1.05 0 0 1 .742-1.793h.645Zm18.061-.86a1.05 1.05 0 0 0-1.483 0l-1.636 1.633a1.05 1.05 0 0 0 .742 1.793h.508a7.126 7.126 0 0 1-7.05 6.067 7.118 7.118 0 0 1-5.653-2.78 1.05 1.05 0 1 0-1.663 1.281 9.218 9.218 0 0 0 7.316 3.599c4.74 0 8.646-3.57 9.168-8.167h.645a1.05 1.05 0 0 0 .742-1.793l-1.636-1.633Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Refresh;
