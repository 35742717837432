import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const OutlineCreditCard = ({
    size = 30,
    color="#FFFFFF",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M12.322 4h5.356c2.393 0 4.29 0 5.773.2 1.527.205 2.762.637 3.737 1.612.975.974 1.407 2.21 1.613 3.737.117.877.166 1.896.185 3.078a.98.98 0 0 1 .005.299c.009.737.009 1.534.009 2.396v.147c0 2.393 0 4.289-.2 5.772-.205 1.527-.637 2.763-1.612 3.738-.974.974-2.21 1.407-3.737 1.612-1.484.2-3.38.2-5.773.2h-5.356c-2.393 0-4.29 0-5.773-.2-1.527-.205-2.763-.638-3.737-1.612-.975-.975-1.407-2.21-1.613-3.738C1 19.758 1 17.862 1 15.47v-.147c0-.862 0-1.66.01-2.396a.986.986 0 0 1 .004-.299c.02-1.182.068-2.202.185-3.078.206-1.527.638-2.763 1.613-3.737.974-.975 2.21-1.407 3.737-1.613C8.033 4 9.93 4 12.322 4Zm-9.366 9.767c-.002.506-.003 1.048-.003 1.628 0 2.484.003 4.248.183 5.586.176 1.31.506 2.065 1.057 2.616.551.552 1.306.882 2.616 1.058 1.339.18 3.103.182 5.586.182h5.21c2.483 0 4.247-.002 5.585-.182 1.31-.176 2.066-.506 2.617-1.058.551-.55.881-1.306 1.058-2.616.18-1.338.182-3.102.182-5.586 0-.58 0-1.122-.003-1.628H2.956Zm24.057-1.953H2.987c.026-.764.07-1.424.148-2.005.177-1.31.507-2.065 1.058-2.616.551-.551 1.306-.881 2.616-1.057 1.339-.18 3.103-.183 5.586-.183h5.21c2.483 0 4.247.003 5.585.183 1.31.176 2.066.506 2.617 1.057.551.551.881 1.306 1.058 2.616.078.58.122 1.24.148 2.005Zm-20.804 8.79c0-.539.438-.976.977-.976h5.21a.977.977 0 1 1 0 1.953h-5.21a.977.977 0 0 1-.977-.976Zm8.465 0c0-.539.438-.976.977-.976h1.954a.977.977 0 0 1 0 1.953H15.65a.977.977 0 0 1-.977-.976Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default OutlineCreditCard;
