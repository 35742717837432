import React,{
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    Text
} from "isinolacak-web-cl";
import {
    NewEducationTypes
} from "./types";
import {
    CircleExitIcon
} from "../../assets/svgr";
import {
    RESTService
} from "../../services/restAPI";
import {
    EducationLevelsObjectType
} from "../../services/restAPI/actions/getEducationLevels/types";
import {
    InputYearsSelectedItems,
    EducationSelectedItems
} from "../../pages/app/worker/profile/views/cv/views/education/types";
import {
    INPUT_YEARS
} from "../../pages/app/worker/profile/views/cv/constants";
import {
    getErrorText 
} from "../../utils";

const NewEducation = ({
    onChangeEducationLevel,
    onChangeStartDate,
    removeEducation,
    onChangeEndDate,
    onChangeSchool,
    onChangeMajor,
    showExitIcon,
    education,
    setErrors,
    errors,
    index
}: NewEducationTypes) =>  {
    const classes = useStyles();
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [educationData, setEducationData] = useState<Array<EducationLevelsObjectType>>([]);
    
    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getEducationLevel();
    },[]);

    const getEducationLevel = () => {
        RESTService.action("GetEducationLevels",{
            language: _langugage ?? "tr-TR"
        }).then((res) => {
            setEducationData(res.payload);
        }).catch((err) => {
            console.error("ERR:", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const renderEducation = () => {
        let initialItem: Array<EducationLevelsObjectType> | [] = education[index].educationLevel && education[index].educationLevel._id.length ? [education[index].educationLevel] : [];

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.remove}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {localize("training-details")}
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeEducation(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <SelectBox
                infoText={errors.some(err => err === `education-${index}-educationLevelID-length-error`) ? localize("needs-to-be-filled") : undefined}
                isError={errors.some(err => err === `education-${index}-educationLevelID-length-error`)}
                titleExtractor={(item) => item.localizedText}
                title={localize("education-level")}
                initialSelectedItems={initialItem}
                keyExtractor={(item) => item._id}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                style={{
                    marginBottom: spaces.content 
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    const selectedItem = selectedItems[0] as unknown as EducationSelectedItems;
                    if (selectedItem) {
                        onChangeEducationLevel(selectedItem, index);
                        let updatedErrors = errors.filter(err => err !== `education-${index}-educationLevelID-length-error`);
                        setErrors(updatedErrors);
                    }
                    closeSheet();
                    onSuccess();
                }}
                inputTitle=""
                data={educationData}
            />
            <TextInput
                infoText={errors.includes(`education-${index}-school-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.some(err => err === `education-${index}-school-length-error`)}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `education-${index}-school-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                onChangeText={(text) => onChangeSchool(text, index)}
                initialValue={education[index].school}
                placeholder={localize("school")}
                style={{
                    marginBottom: spaces.content 
                }}
            />
            <TextInput
                infoText={errors.includes(`education-${index}-major-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.some(err => err === `education-${index}-major-length-error`)}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `education-${index}-major-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                onChangeText={(text) => onChangeMajor(text, index)}
                initialValue={education[index].major}
                placeholder={localize("major")}
                style={{
                    marginBottom: spaces.content
                }}
            />
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.content
                }}>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`education-${index}-startDate-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `education-${index}-startDate-length-error`)}
                        initialSelectedItems={
                            education[index] && education[index].startDate ? [{
                                year: education[index].startDate 
                            }] : [] 
                        }
                        titleExtractor={(item: {year: number}) => String(item.year)}
                        keyExtractor={(item: {year: number}) => String(item.year)}
                        title={localize("start-date")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        disabled={false}
                        style={{
                            marginBottom: spaces.content
                        }}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0] as unknown as InputYearsSelectedItems;
                            if (selectedItem) {
                                onChangeStartDate(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `education-${index}-startDate-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        inputTitle={""}
                        data={INPUT_YEARS}
                    />
                </div>
                <div
                    className={classes.selectBoxStyles}
                >
                    <SelectBox
                        infoText={errors.includes(`education-${index}-endDate-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `education-${index}-endDate-length-error`)}
                        initialSelectedItems={
                            education[index] && education[index].endDate ? [{
                                year: education[index].endDate 
                            }] : [] 
                        }
                        titleExtractor={(item: {year: number}) => String(item.year)}
                        keyExtractor={(item: {year: number}) => String(item.year)}
                        title={localize("end-date")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        disabled={!education[index].startDate}
                        style={{
                            marginBottom: spaces.content
                        }}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0] as unknown as InputYearsSelectedItems;
                            const startDate = education[index].startDate;
                            if (selectedItem.year < startDate) {
                                alert(localize("end-date-cannot-be-before-start-date"));
                                return;
                            }
                            if (selectedItem) {
                                onChangeEndDate(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `education-${index}-endDate-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        inputTitle={""}
                        data={INPUT_YEARS}
                    /> 
                </div>
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderEducation()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default NewEducation;
