import {
    useEffect,
    useState,
    useRef
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Pagination,
    TextInput,
    Loading,
    Button,
    Text,
    Chip
} from "isinolacak-web-cl";
import {
    SelectedForeignLanguageType,
    SelectedEducationLevelType,
    SelectedFiltersObjectType,
    SelectedCapabilitiesType,
    DisabledCandidatesType,
    SelectedOccupationType,
    SelectedFiltersType,
    SelectedCitiesType,
    SearchStorageType 
} from "./types";
import {
    MagnifierIcon,
    RadarIcon,
    WidgetAddIcon
} from "../../../assets/svgr";
import {
    ResponsiveFilters,
    FiltersContainer,
    AlarmContainer,
    ResultCard
} from "./views"; 
import {
    //SORT_FILTER,
    NO_RESULT
} from "./constants";
import {
    RESTService
} from "../../../services/restAPI";
import {
    WorkerSearchRequestType,
    WorkerSearchObjectType
} from "../../../services/restAPI/actions/workerSearch/types";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";


const FindCandidate = () => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const navigate = useNavigate();

    const [selectedEducationLevel, setSelectedEducationLevel] =  useState<Array<SelectedEducationLevelType>>([]);
    const [selectedForeignLanguage, setSelectedForeignLanguage] = useState<Array<SelectedForeignLanguageType>>([]);
    const [selectedCapabilities, setSelectedCapabilities] = useState<Array<SelectedCapabilitiesType>>([]);
    const [isCandidatesForDisabled, setIsCandidatesForDisabled] = useState<DisabledCandidatesType>("all");
    const [selectedOccupation, setSelectedOccupation] =  useState<Array<SelectedOccupationType>>([]);
    const [searchResultData, setSearchResultData] = useState<Array<WorkerSearchObjectType>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>([]);
    const [showResponsiveFilter, setShowResposiveFilter] = useState<boolean>(false);
    //const [sortMethod, setSortMethod] = useState<SearchSortType>("recommended");
    const [totalSearchResult, setTotalSearchResult] = useState<number>(0);
    const [isSortDropdown, setIsSortDropdown] = useState<boolean>(false);
    const [isİnitial, setIsInitial] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [isSmall, setIsSmall] = useState(false);
    const [page, setPage] = useState(1);

    const [searchParams, setSearchParams] = useSearchParams();

    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsSortDropdown(false);
        }
    };

    let _storedFilter: SearchStorageType = {
    };

    useEffect(() => {
        let filters: WorkerSearchRequestType = {
            page: page
        };

        let storedFilters = window.localStorage.getItem("storedSearchFilters");

        if(searchParams.size > 0) {
            if(searchParams.get("s")) { 
                let _searchText = searchParams.get("s");
    
                if(_searchText?.length) {
                    setSearchText(_searchText);

                    filters.searchText = _searchText;
                }
            }

            if(searchParams.get("l")) { 
                let _setSelectedCities = searchParams.get("l");

                if(_setSelectedCities?.length) {
                    let __setSelectedCities = JSON.parse(_setSelectedCities);
                    setSelectedCities(__setSelectedCities);

                    //@ts-ignore
                    let _selectedCityID: Array<string> = __setSelectedCities.map((item) => {
                        return item.cityID;
                    });
        
                    filters.cityIDs = _selectedCityID;
                }
            }

            if(searchParams.get("e")) { 
                let _selectedEducationLevel = searchParams.get("e");
    
                if(_selectedEducationLevel?.length) {
                    let __selectedEducationLevel = JSON.parse(_selectedEducationLevel);
                    setSelectedEducationLevel(__selectedEducationLevel);
                    //@ts-ignore
                    let _selectedEducationID: Array<string> = __selectedEducationLevel.map((item) => {
                        return item.educationLevelIDs;
                    });
        
                    filters.educationLevelIDs = _selectedEducationID;
                }
            }

            if(searchParams.get("c")) { 
                let _selectedForeignLanguage = searchParams.get("c");
    
                if(_selectedForeignLanguage?.length) {
                    let __selectedForeignLanguage = JSON.parse(_selectedForeignLanguage);
                    setSelectedForeignLanguage(__selectedForeignLanguage);
                    //@ts-ignore
                    let _selectedForeignLanguageID: Array<string> = __selectedForeignLanguage.map((item) => {
                        return item.foreignLanguageIDs;
                    });
        
                    filters.foreignLanguageIDs = _selectedForeignLanguageID;
                }
            }

            if(searchParams.get("o")) { 
                let _selectedOccupation = searchParams.get("o");
    
                if(_selectedOccupation?.length) {
                    let __selectedOccupation = JSON.parse(_selectedOccupation);
                    setSelectedOccupation(__selectedOccupation);
                    //@ts-ignore
                    let _selectedOccupationTypeID: Array<string> = __selectedOccupation.map((item) => {
                        return item.occupationID;
                    });
        
                    filters.occupationIDs = _selectedOccupationTypeID;
                }
            }

            if(searchParams.get("w")) { 
                let _selectedCapabilities = searchParams.get("w");
    
                if(_selectedCapabilities?.length) {
                    let __selectedCapabilities = JSON.parse(_selectedCapabilities);
                    setSelectedCapabilities(__selectedCapabilities);
                    //@ts-ignore
                    let __selectedCapabilitiesID: Array<string> = __selectedCapabilities.map((item) => {
                        return item.capabilityIDs;
                    });
        
                    filters.capabilityIDs = __selectedCapabilitiesID;
                }
            }

            if(searchParams.get("x")) { 
                let _isCandidatesForDisabled = searchParams.get("x");
    
                if(_isCandidatesForDisabled?.length) {
                    //@ts-ignore
                    setIsCandidatesForDisabled(true);
                    
                    //@ts-ignore
                    filters.showSuitableForDisabledPeopleAdverts = "true";
                }
            }

            /*if(searchParams.get("r")) { 
                let _sortMethod = searchParams.get("r");
    
                if(_sortMethod?.length) {
                    //@ts-ignore
                    setSortMethod(__sortMethod);
                    //@ts-ignore
                    filters.sortMethod = __sortMethod;
                }
            }*/
        } else if(storedFilters) {
            let parsedStoredFilters:SearchStorageType  = JSON.parse(storedFilters);

            if(parsedStoredFilters.selectedEducationLevel) {
                setSelectedEducationLevel(parsedStoredFilters.selectedEducationLevel);
                let _selectedCityID: Array<string> = parsedStoredFilters.selectedEducationLevel.map((item) => {
                    return item.educationLevelID;
                });
        
                filters.educationLevelIDs = _selectedCityID;
            }

            if(parsedStoredFilters.selectedCities) {
                setSelectedCities(parsedStoredFilters.selectedCities);
                let _selectedCityID: Array<string> = parsedStoredFilters.selectedCities.map((item) => {
                    return item.cityID;
                });
        
                filters.cityIDs = _selectedCityID;
            }

            if(parsedStoredFilters.isAdvertForDisabled) {
                setIsCandidatesForDisabled(parsedStoredFilters.isAdvertForDisabled);
                if(parsedStoredFilters.isAdvertForDisabled !== "all") {
                    filters.showSuitableForDisabledPeopleAdverts = parsedStoredFilters.isAdvertForDisabled;
                }
            }

            if(parsedStoredFilters.selectedForeignLanguage) {
                setSelectedForeignLanguage(parsedStoredFilters.selectedForeignLanguage);
                let _foreignLanguageID: Array<string> = parsedStoredFilters.selectedForeignLanguage.map((item) => {
                    return item.foreignLanguageID;
                });
        
                filters.foreignLanguageIDs = _foreignLanguageID;
            }

            /*if(parsedStoredFilters.sortMethod) {
                setSortMethod(parsedStoredFilters.sortMethod);
                if(parsedStoredFilters.sortMethod !== "recommended") {
                    filters.sortMethod = parsedStoredFilters.sortMethod;
                }
            }*/

            if(parsedStoredFilters.selectedCapabilities) {
                setSelectedCapabilities(parsedStoredFilters.selectedCapabilities);
                let _capabilitiesID: Array<string> = parsedStoredFilters.selectedCapabilities.map((item) => {
                    return item.capabilitiesID;
                });
        
                filters.capabilityIDs = _capabilitiesID;
            }

            if(parsedStoredFilters.selectedOccupation) {
                setSelectedOccupation(parsedStoredFilters.selectedOccupation);
                let _occupationID: Array<string> = parsedStoredFilters.selectedOccupation.map((item) => {
                    return item.occupationID;
                });
        
                filters.occupationIDs = _occupationID;
            }
        }

        onSearch(true,filters);
        window.scrollTo(0, 0);

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(window.innerWidth < 800) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    

    const onResize = () => {
        if(window.innerWidth < 800) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const onSearch = (initial?: boolean,  __filters?:WorkerSearchRequestType) => {
        setLoading(true);
        let filters: WorkerSearchRequestType = {
            page: page
        };

        if(!initial) {
            if(searchText.length) {
                filters.searchText = searchText;
                setSearchParams(e => {
                    e.set("s",searchText);
                    return e;
                }, {
                    replace: true
                });
            } else {
                searchParams.delete("s");
                setSearchParams(searchParams);
            }

            if(selectedEducationLevel.length) {
                _storedFilter.selectedEducationLevel = selectedEducationLevel;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _educationLevelID: Array<string> = selectedEducationLevel.map((item) => {
                    return item.educationLevelID;
                });

                setSearchParams(e => {
                    e.set("e",JSON.stringify(selectedEducationLevel));
                    return e;
                }, {
                    replace: true
                });

                filters.educationLevelIDs = _educationLevelID;
            } else {
                _storedFilter.selectedEducationLevel = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("e");
                setSearchParams(searchParams);
            }

            if(selectedForeignLanguage.length) {
                _storedFilter.selectedForeignLanguage = selectedForeignLanguage;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _foreignLanguageID: Array<string> = selectedForeignLanguage.map((item) => {
                    return item.foreignLanguageID;
                });

                setSearchParams(e => {
                    e.set("c",JSON.stringify(selectedForeignLanguage));
                    return e;
                }, {
                    replace: true
                });

                filters.foreignLanguageIDs = _foreignLanguageID;
            } else {
                _storedFilter.selectedForeignLanguage = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("c");
                setSearchParams(searchParams);
            }

            if(selectedOccupation.length) {
                _storedFilter.selectedOccupation = selectedOccupation;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _occupationID: Array<string> = selectedOccupation.map((item) => {
                    return item.occupationID;
                });

                setSearchParams(e => {
                    e.set("o",JSON.stringify(selectedOccupation));
                    return e;
                }, {
                    replace: true
                });

                filters.occupationIDs = _occupationID;
            } else {
                _storedFilter.selectedOccupation = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("o");
                setSearchParams(searchParams);
            }

            if(selectedCapabilities.length) {
                _storedFilter.selectedCapabilities = selectedCapabilities;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _capabilitiesID: Array<string> = selectedCapabilities.map((item) => {
                    return item.capabilitiesID;
                });

                setSearchParams(e => {
                    e.set("w",JSON.stringify(selectedCapabilities));
                    return e;
                }, {
                    replace: true
                });

                filters.capabilityIDs = _capabilitiesID;
            } else {
                _storedFilter.selectedCapabilities = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("w");
                setSearchParams(searchParams);
            }

            if(selectedCities.length) { 
                _storedFilter.selectedCities = selectedCities;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _cityID: Array<string> = selectedCities.map((item) => {
                    return item.cityID;
                });

                setSearchParams(e => {
                    e.set("l",JSON.stringify(selectedCities));
                    return e;
                }, {
                    replace: true
                });

                filters.cityIDs = _cityID;
            } else {
                _storedFilter.selectedCities = selectedCities;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("l");
                setSearchParams(searchParams);
            }

            if(isCandidatesForDisabled !== "all") {
                _storedFilter.isAdvertForDisabled = isCandidatesForDisabled;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("x",isCandidatesForDisabled);
                    return e;
                }, {
                    replace: true
                });

                filters.showSuitableForDisabledPeopleAdverts = isCandidatesForDisabled;
            } else {
                _storedFilter.isAdvertForDisabled = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("x");
                setSearchParams(searchParams);
            }

            /* if(sortMethod !== "recommended") {
                _storedFilter.sortMethod = sortMethod;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("r",sortMethod);
                    return e;
                }, {
                    replace: true
                });

                filters.sortMethod = sortMethod;
            } else {
                _storedFilter.sortMethod = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("r");
                setSearchParams(searchParams);
            }*/
        }
        
        RESTService.action("WorkerSearch",initial && __filters ? __filters : filters)
            .then((res) => {
                setTotalSearchResult(res.totalDataCount);
                setSearchResultData(res.payload);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.error("WorkerSearch Err:", err);
            });
    };

    const onChangeSelectedFilters = (_filters: SelectedFiltersType, item: SelectedFiltersObjectType) => {
        if(item.type === "educationLevel") {
            let  _educationLevel: Array<SelectedEducationLevelType> = JSON.parse(JSON.stringify(selectedEducationLevel));
            const isExistsInSelectedData =selectedEducationLevel.findIndex(e => e.educationLevelID === item.id);
            _educationLevel.splice(isExistsInSelectedData,1);
            setSelectedEducationLevel(_educationLevel);

            _storedFilter.selectedEducationLevel = _educationLevel;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "foreignLanguage") {
            let  _foreignLanguage: Array<SelectedForeignLanguageType> = JSON.parse(JSON.stringify(selectedForeignLanguage));
            const isExistsInSelectedData =selectedForeignLanguage.findIndex(e => e.foreignLanguageID === item.id);
            _foreignLanguage.splice(isExistsInSelectedData,1);
            setSelectedForeignLanguage(_foreignLanguage);

            _storedFilter.selectedForeignLanguage = _foreignLanguage;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "occupation") {
            let  _occupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));
            const isExistsInSelectedData =selectedOccupation.findIndex(e => e.occupationID === item.id);
            _occupation.splice(isExistsInSelectedData,1);
            setSelectedOccupation(_occupation);

            _storedFilter.selectedOccupation = _occupation;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "capabilities") {
            let  _capabilities: Array<SelectedCapabilitiesType> = JSON.parse(JSON.stringify(selectedCapabilities));
            const isExistsInSelectedData =selectedCapabilities.findIndex(e => e.capabilitiesID === item.id);
            _capabilities.splice(isExistsInSelectedData,1);
            setSelectedCapabilities(_capabilities);
            _storedFilter.selectedCapabilities = _capabilities;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "cities") {
            let  _cities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData =selectedCities.findIndex(e => e.cityID === item.id);
            _cities.splice(isExistsInSelectedData,1);
            setSelectedCities(_cities);
            _storedFilter.selectedCities = _cities;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "disabledCandidates") {
            setIsCandidatesForDisabled("all");
            _storedFilter.isAdvertForDisabled = "all";
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        /*if(item.type === "sort") {
            setSortMethod("recommended");
            window.localStorage.setItem("recommended","recommended");
            return;
        }*/
    }; 
    
    const renderSearchContainer = () => {
        return <div
            className={classes.searchContianer}
            style={{   
                borderBottomColor: colors.stroke,
                backgroundColor: colors.white,
                borderTopColor: colors.stroke,
            }}
        >
            <div 
                className={classes.searchContainerInner} 
                style={{
                    paddingRight: spaces.containerXXLarge,
                    paddingLeft: spaces.containerXXLarge,
                    paddingBottom: spaces.content,
                    paddingTop: spaces.content,
                    gap: spaces.content
                }}>
                <TextInput
                    initialValue={searchParams.get("s") ? searchParams.get("s") as string : searchText}
                    placeholder={localize("search-suitable-candidate-for-you")}
                    className={classes.searchTextInput}
                    spreadBehaviour="free"
                    clearEnabled= {true}
                    iconOnClick={() => {
                        onSearch();
                    }}
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            onSearch();
                            window.scrollTo(0, 0);               
                        }
                    }}
                    icon={() => {
                        return <div
                            style={{
                                marginRight: spaces.content / 2
                            }}
                        >
                            <MagnifierIcon
                                color={colors.textGrey}
                                size={20}
                            />
                        </div>;
                    }}
                    onChangeText={(e) => {
                        setSearchText(e);
                    }}
                />
                <Button
                    className={classes.searchTextButton}
                    title={localize("search-candidate")}
                    onClick={() => {
                        setIsInitial(false);
                        onSearch();
                    }}
                    spreadBehaviour="free"
                />
            </div>
        </div>;
    };


    const renderSearchResponsiveContainer = () => {
        return <div
            className={classes.searchContianerResponsive}
            style={{
                paddingRight: spaces.container * 2.3,
                paddingLeft: spaces.content * 2.3,
                borderBottomColor: colors.stroke,
                paddingTop: spaces.content * 3,
                backgroundColor: colors.white,
                paddingBottom: spaces.content,
                borderTopColor: colors.stroke,
                gap: spaces.container
            }}
        >
            <TextInput
                placeholder={localize("search-suitable-candidate-for-you")}
                clearEnabled= {true}
                onKeyDown={(e) => {
                    if(e.key === "Enter"){
                        onSearch();
                        window.scrollTo(0, 0);               
                    }
                }}
                iconOnClick={() => {
                    setIsInitial(false);
                    onSearch();
                }}
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                onChangeText={(e) => {
                    setSearchText(e);
                }}
            />
            <Button
                onClick={() => {
                    setShowResposiveFilter(!showResponsiveFilter);
                }}
                title={localize("filter-and-sort")}
                spreadBehaviour="baseline"
                variant="outline"
                color="textGrey"
            />
        </div>;
    };
    
    const renderNoResult = () => {
        if(isİnitial) {
            return null;
        }

        if(searchResultData.length || loading) {
            return null;
        }

        return <div
            className={classes.noResultContainer}
            style={{
                padding: spaces.content * 2,
                borderRadius: radiuses.hard,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.noResultContent}
                style={{
                    marginBottom: spaces.content * 2.33,
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("no-candidate-found")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textDark"
                >
                    {localize("no-candidate-found-long")}
                </Text>
            </div>
            <div
                className={classes.noResultContent}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    NO_RESULT.map((item) => {
                        return <Text
                            variant="body4-regular"
                            color="textDark"
                        >
                        &#x2022; {localize(item.localizeText)}
                        </Text>;
                    })
                }
            </div>
        </div>;
    };

    const renderIndicatorsContainer = () => {
        if(isİnitial) {
            return null;
        }

        return <div
            className={classes.indicatorContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.container
            }}
        >
            <div
                className={classes.indicatorInfoContent}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("candidate",[totalSearchResult ? totalSearchResult : 0])}
                </Text>
                {/*
                <div
                    style={{
                        position: "relative"
                    }}
                >
                    <Button
                        onClick={() => {
                            if(isSortDropdown) {
                                setIsSortDropdown(false);
                            } else {
                                setIsSortDropdown(true);
                            }
                        }}
                        //title={sortMethod === "recommended" ? localize("recomended") : sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new")}
                        textColor="textSecondary"
                        color="textSecondary"
                        variant="outline"
                        iconDirection="right"
                        icon={() => <ArrowDownIcon
                            color={colors.textGrey}
                        />
                        }
                    />
                    <div
                        
                    >
                        {renderSortDropdown()}
                    </div>
                </div>
                */}
            </div>
        </div>;
    };

    /*const renderSortDropdown = () => {
        if(!isSortDropdown) {
            return null;
        }

        return <div
            className={classes.sortContainer}
            ref={dropdownRef}
            style={{
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            {
                SORT_FILTER.map((item, index) => {
                    return <Button
                        key={`menu-item${index}`}
                        title={localize(item.localizedText)}
                        textVariant='body3-regular'
                        textColor='textGrey'
                        variant='ghost'
                        onClick={() => {
                            //setSortMethod(item.value);
                            setIsSortDropdown(false);
                        }}
                    />;
                })
            }
        </div>;
    };*/

    const renderChoosenFilters = (_filters :SelectedFiltersType) => {
        return <div
            className={classes.choosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersTitle}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {`${localize("choosen-filters")} (${_filters.length})`}
                </Text>
                <div
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        window.localStorage.removeItem("storedSearchFilters");
                        setIsCandidatesForDisabled("all");
                        setSelectedForeignLanguage([]);
                        //setSortMethod("recommended");
                        setSelectedEducationLevel([]);
                        setSelectedCapabilities([]);
                        setSelectedOccupation([]);
                        setSelectedCities([]);
                    }}
                >
                    <Text
                        variant="body4-regular"
                        color="primary"
                    >
                        {localize("clear-filters")}
                    </Text>
                </div>
            </div>
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderFilters = () => {
        return <div
            className={classes.filterMainContainer}
        >
            <FiltersContainer
                setIsCandidatesForDisabled={setIsCandidatesForDisabled}
                setSelectedForeignLanguage={setSelectedForeignLanguage}
                setSelectedEducationLevel={setSelectedEducationLevel}
                setSelectedCapabilities={setSelectedCapabilities}
                selectedForeignLanguage={selectedForeignLanguage}
                isCandidatesForDisabled={isCandidatesForDisabled}
                selectedEducationLevel={selectedEducationLevel}
                setSelectedOccupation={setSelectedOccupation}
                selectedCapabilities={selectedCapabilities}
                selectedOccupation={selectedOccupation}
                setSelectedCities={setSelectedCities}
                selectedCities={selectedCities}
                //setSortMethod={setSortMethod}
                //sortMethod={sortMethod}
            />
            {
                
                <ResponsiveFilters
                    setSelectedForeignLanguages={setSelectedForeignLanguage}
                    setIsCandidatesForDisabled={setIsCandidatesForDisabled}
                    setSelectedEducationLevel={setSelectedEducationLevel}
                    isCandidatesForDisabled={isCandidatesForDisabled}
                    selectedForeignLanguages={selectedForeignLanguage}
                    setSelectedCapabilities={setSelectedCapabilities}
                    setShowResponsiveFilter={setShowResposiveFilter}
                    selectedEducationLevel={selectedEducationLevel}
                    setSelectedOccupation={setSelectedOccupation}
                    showResponsiveFilter={showResponsiveFilter}
                    selectedCapabilities={selectedCapabilities}
                    selectedOccupation={selectedOccupation}
                    setSelectedCities={setSelectedCities}
                    selectedCities={selectedCities}
                    //setSortMethod={setSortMethod}
                    //sortMethod={sortMethod}
                    onSearch={()=>{
                        onSearch();
                    }}
                />
            }
        </div>;
    };

    const renderSearchResults = () => {
        if(!searchResultData.length) {
            return null;
        }

        return <div
            className={classes.searchResultsContainer}
            style={{
                gap: spaces.content
            }}
        >
            {
                searchResultData.map((item,index) => {
                    return <ResultCard
                        capabilities={item.capability.localizedText}
                        occupation={item.occupation.localizedText}
                        userImage={item.profilePhotoURL}
                        city={item.city.localizedText}
                        userFullName={item.fullName}
                        key={index}
                        onClick={() => navigate("/app/profileUser", {
                            state: {
                                workerID: item._id
                            }
                        })}
                    />;
                })
            }
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginTop: spaces.containerXLarge,
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderPagination = () => {
        if(totalSearchResult < 20) {
            return null;
        }
        return <div
            className={classes.paginationContainer}
            style={{
                marginBottom: spaces.containerXLarge,
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <Pagination
                totalDataCount= {totalSearchResult}
                currentPage={page}
                itemPerPage= {20}
                onSelect={(item, index) => {
                    setPage(item.pageNumber);
                }}
            />
        </div>;
    };

    const renderResposivePagination = () => {
        if(totalSearchResult < 20) {
            return null;
        }

        return <div
            className={classes.responsivePaginationContainer}
            style={{
                marginBottom: spaces.content,
                gap: spaces.content
            }}
        >
            <Text
                variant="body4-medium"
            >
                {localize("you-are-currently-on-page", [totalSearchResult])}
                {`Şu an ${totalSearchResult} sayfa içerisinde ${page}. sayfadasınız.`}
            </Text>
            <div
                className={classes.responsivePaginationContent}
                style={{
                    gap: spaces.content
                }}
            >
                <Button
                    title={localize("previus-page")}
                    textColor="textWhite"
                    disabled={page === 1}
                    color="textSecondary"
                    onClick={() => {
                        page - 1;
                    }}
                />
                <Button
                    disabled={totalSearchResult / 20 < 1}
                    title={localize("next-page")}
                    textColor="textWhite"
                    onClick={() => {
                        page + 1;
                    }}
                />   
            </div>
        </div>;
    };

    const renderHeader =()=> {
        let _filters: SelectedFiltersType = [];

        if(selectedEducationLevel.length) {
            let _educationLevels: SelectedFiltersType = selectedEducationLevel.map((item) => {
                return {
                    id: item.educationLevelID,
                    localizedText: item.localizedText,
                    type: "educationLevel"
                };
            });

            _filters = [..._filters, ..._educationLevels];
        }

        if(selectedCapabilities.length) {
            let _capabilities: SelectedFiltersType = selectedCapabilities.map((item) => {
                return {
                    id: item.capabilitiesID,
                    localizedText: item.localizedText,
                    type: "capabilities"
                };
            });

            _filters = [..._filters, ..._capabilities];
        }

        if(selectedOccupation.length) {
            let _occupations: SelectedFiltersType = selectedOccupation.map((item) => {
                return {
                    id: item.occupationID,
                    localizedText: item.localizedText,
                    type: "occupation"
                };
            });

            _filters = [..._filters, ..._occupations];
        }

        if(selectedForeignLanguage.length) {
            let _foreignLanguage: SelectedFiltersType = selectedForeignLanguage.map((item) => {
                return {
                    id: item.foreignLanguageID,
                    localizedText: item.localizedText,
                    type: "foreignLanguage"
                };
            });

            _filters = [..._filters, ..._foreignLanguage];
        }

        if(selectedCities.length) { 
            let _cities: SelectedFiltersType = selectedCities.map((item) => {
                return {
                    id: item.cityID,
                    localizedText: item.localizedText,
                    type: "cities"
                };
            });

            _filters = [..._filters, ..._cities];
        }

        if(isCandidatesForDisabled !== "all") {
            let _isCandidatesForDisabled: SelectedFiltersObjectType = {
                id: isCandidatesForDisabled,
                localizedText: isCandidatesForDisabled === "true" ? localize("only-disabled-ads") : localize("no-disabled-ads"),
                type: "disabledCandidates"
            };

            _filters.push(_isCandidatesForDisabled);
        }

        /*if(sortMethod !== "recommended") {
            let _sortMethod: SelectedFiltersObjectType = {
                id: sortMethod,
                localizedText: sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new"),
                type: "sort"
            };

            _filters.push(_sortMethod);
        }*/

        let _educationLevelIDs: Array<string> = selectedEducationLevel.map((item) => {
            return item.educationLevelID.toString();
        });

        let _foreignLanguages: Array<string> = selectedForeignLanguage.map((item) => {
            return item.foreignLanguageID.toString();
        });

        let _capabilityIDs: Array<string> = selectedCapabilities.map((item) => {
            return item.capabilitiesID.toString();
        });

        let _occupationIDs: Array<string> = selectedOccupation.map((item) => {
            return item.occupationID.toString();
        });

        let _cities : Array<string> = selectedCities.map((item) =>{
            return item.cityID.toString();
        });

        return <div
            className={classes.headerContainer}
        >
            {
                _filters.length ?
                    renderChoosenFilters(_filters)
                    :
                    null
            }
            {
                _occupationIDs.length ?
                    <AlarmContainer
                        isDisabledPeople= {isCandidatesForDisabled === "true" ? true : isCandidatesForDisabled === "false" && false}
                        foreignLanguages= {_foreignLanguages}
                        educationLevel= {_educationLevelIDs}
                        capability= {_capabilityIDs}
                        occupation= {_occupationIDs}
                        city= {_cities}
                    />:
                    null
            }
        </div>;
    };

    
    const renderLoading = () => {
        if(!loading) {
            return null;
        }

        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.grey25
        }}
    >   
        {renderSearchContainer()}
        {renderSearchResponsiveContainer()}
        <div
            className={classes.contentContianer}
            style={{
                paddingRight: !isSmall ? spaces.containerXXLarge : spaces.content,
                paddingLeft: !isSmall ? spaces.containerXXLarge : spaces.content,
                paddingBottom: spaces.containerXXLarge,
                paddingTop: spaces.content * 1.5,
                marginBottom: spaces.container,
                backgroundColor: colors.grey25,
                gap: spaces.containerLarge
            }}
        >
            <div
                className={classes.leftContainer}
            >
                {renderIndicatorsContainer()}
                <div
                    className={classes.filterContainer}
                    style={{
                        gap: spaces.containerLarge
                    }}
                >
                    {renderFilters()}
                    <div
                        className={classes.searchResultsContainer}
                    >
                        {renderHeader()}
                        {loading ? renderLoading() : renderSearchResults()}
                        {renderNoResult()}
                    </div>
                </div>
            </div>
            {renderRightContainer()}
        </div>
        {renderPagination()}
        {renderResposivePagination()}
    </div>;
};
export default FindCandidate;