import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Tic = ({
    color= "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M28.425 3.414c.699.61.77 1.672.16 2.37l-17.6 20.16a1.68 1.68 0 0 1-2.53 0l-7.04-8.063a1.68 1.68 0 0 1 2.53-2.21l5.775 6.614 16.334-18.71a1.68 1.68 0 0 1 2.37-.16Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Tic;
