import {
    useEffect,
    useState,
    useRef
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    RadioButton,
    IOCoreTheme,
    TextInput,
    CheckBox,
    Loading,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    SelectedForeignLanguageType,
    SelectedEducationLevelType,
    SelectedCapabilitiesType,
    DisabledCandidatesType,
    SelectedOccupationType,
    SelectedCitiesType,
    ICreateAlarmType,
    ViewDataType
} from "./types";
import {
    FilledArrowDownIcon, 
    FilledArrowUpIcon, 
    MagnifierIcon,
    CloseIcon
} from "../../../../../../../../assets/svgr";
import { 
    RESTService 
} from "../../../../../../../../services/restAPI";
import {
    GetCapabilitiesRequestType,
    GetCapabilitiesObjectType
} from "../../../../../../../../services/restAPI/actions/getCapabilities/types";
import {
    GetForeignLanguagesPayloadObjectType
} from "../../../../../../../../services/restAPI/actions/getForeignLanguages/types";
import {
    CitiesResponseObjectType,
} from "../../../../../../../../services/restAPI/actions/getCities/types";
import {
    EducationLevelsObjectType    
} from "../../../../../../../../services/restAPI/actions/getEducationLevels/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../../../../services/restAPI/actions/getOccupation/types";
import {
    SubscribeAlarmMatchParametersTypes 
} from "../../../../../../../../services/restAPI/actions/subscribeAlarm/types";
import {
    DISABLED_CANDIDATES_FILTERS,
} from "../constants";


const CreateAlarm = ({
    onClickCloseButton,
    setAlarmData,
    alarmData,
}: ICreateAlarmType) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [foreignLanguagesData, setForeignLanguagesData] = useState<Array<GetForeignLanguagesPayloadObjectType>>([]);
    const [selectedForeignLanguages, setSelectedForeignLanguages] = useState<Array<SelectedForeignLanguageType>>([]);
    const [selectedEducationLevels, setSelectedEducationLevels] = useState<Array<SelectedEducationLevelType>>([]);
    const [selectedCapabilities, setSelectedCapabilities] = useState<Array<SelectedCapabilitiesType>>([]);
    const [isCandidatesForDisabled, setIsCandidatesForDisabled] = useState<DisabledCandidatesType>("all");
    const [educationLevelData, setEducationLevelData] = useState<Array<EducationLevelsObjectType>>([]);
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [selectedOccupation, setSelectedOccupation] = useState<Array<SelectedOccupationType>>([]);
    const [capabilitiesData, setCapabilitiesData] = useState<Array<GetCapabilitiesObjectType>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);
    const [searchCapabilitiesPage, setSearchCapabilitiesPage] = useState<number>(1);
    const [capabilitiesEndOfData, setCapabilitiesEndOfData] = useState(false);
    const [capabilitiesPage, setCapabilitiesPage] = useState<number>(1);
    const [alarmTitle, setAlarmTitle] = useState<string>("");
    const [isSeeAll, setIsSeeAll] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    //Occupation Pagination States
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(false);
    const [occupationPage, setOccupationPage] = useState<number>(1);

    const [viewData,setViewData] = useState<ViewDataType>(undefined);

    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    
    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getForeignLanguages();
        getEducationLevel();
        getCapabilities();
        getOccupations();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);

    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    const onSearchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const onChangeCities = (item: CitiesResponseObjectType) => {
        if(selectedCities.length) {
            let _selectedCities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));

            const isExistsInSelectedData = selectedCities.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCities.splice(isExistsInSelectedData, 1);
                setSelectedCities(_selectedCities);
            } else {
                _selectedCities.push({
                    _id: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCities(_selectedCities);
            }
        } else {
            setSelectedCities([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeEducationLevels = (item: EducationLevelsObjectType) => {
        if(selectedEducationLevels.length) {
            let _selectedEducationLevel:  Array<SelectedEducationLevelType> = JSON.parse(JSON.stringify(selectedEducationLevels));

            const isExistsInSelectedData = selectedEducationLevels.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedEducationLevel.splice(isExistsInSelectedData, 1);
                setSelectedEducationLevels(_selectedEducationLevel);
            } else {
                _selectedEducationLevel.push({
                    _id: item._id,
                    localizedText: item.localizedText
                });
                setSelectedEducationLevels(_selectedEducationLevel);
            }
        } else {
            setSelectedEducationLevels([
                {
                    localizedText: item.localizedText,
                    _id: item._id,
                }
            ]);
        }
    };

    const onChangeCapabilities = (item: GetCapabilitiesObjectType) => {
        if(selectedCapabilities.length) {
            let _selectedCapabilities:  Array<SelectedCapabilitiesType> = JSON.parse(JSON.stringify(selectedCapabilities));

            const isExistsInSelectedData = selectedCapabilities.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCapabilities.splice(isExistsInSelectedData, 1);
                setSelectedCapabilities(_selectedCapabilities);
            } else {
                _selectedCapabilities.push({
                    _id: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCapabilities(_selectedCapabilities);
            }
        } else {
            setSelectedCapabilities([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeForeignLanguages = (item: GetForeignLanguagesPayloadObjectType) => {
        if(selectedForeignLanguages.length) {
            let _selectedForeignLanguages:  Array<SelectedForeignLanguageType> = JSON.parse(JSON.stringify(selectedForeignLanguages));

            const isExistsInSelectedData = selectedForeignLanguages.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedForeignLanguages.splice(isExistsInSelectedData, 1);
                setSelectedForeignLanguages(_selectedForeignLanguages);
            } else {
                _selectedForeignLanguages.push({
                    ...item,
                    _id: item._id,
                    localizedText: item.localizedText
                });
                setSelectedForeignLanguages(_selectedForeignLanguages);
            }
        } else {
            setSelectedForeignLanguages([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getEducationLevel = () => {
        RESTService.action("GetEducationLevels",{
            
        }).then((res) => {
            setEducationLevelData(res.payload);
        }).catch((err) => {
            console.error("GetEducationLevels Err:", err);
        });
    };

    const getForeignLanguages = () => {
        RESTService.action("GetForeignLanguages",{
            language: "tr-TR"
        }).then((res) => {
            setForeignLanguagesData(res.payload);
        }).catch((err) => {
            console.error("GetForeignLanguages Err:", err);
        });
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        params.language = _langugage ?? "tr-TR";

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const getCapabilities = (capabilitiesParams ?:GetCapabilitiesRequestType) => {
        let params: GetCapabilitiesRequestType = {
            language: navigator.language ?? "TR-tr",
            page:  capabilitiesPage
        };

        if(capabilitiesParams) {
            params = capabilitiesParams;
        }

        RESTService.action("GetCapabilities",params)
            .then((res) => {
                let _getCapabilities = JSON.parse(JSON.stringify(capabilitiesData));
                let newCapabilities = res.payload.filter(newItem => !_getCapabilities.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setCapabilitiesData([..._getCapabilities, ...newCapabilities]);

                if(capabilitiesParams?.search && capabilitiesParams.search.length){
                    setSearchCapabilitiesPage(e => e + 1);
                    setCapabilitiesPage(1);
                } else {
                    setCapabilitiesPage(e => e + 1);
                    setSearchCapabilitiesPage(1);
                }

                if(res.payload.length === 0 || res.payload.length < 20) {
                    setCapabilitiesEndOfData(true);
                }
            })
            .catch((err) => {
                console.error("GetCapabilities Err:", err);
            });
    };

    const subscribeAlarm = () => {
        if(!alarmTitle){
            setErrorText(localize("alarm-title-cannot-be-left-blank"));
            return;
        }

        if(!selectedOccupation?.length){
            setErrorText(localize("occupation-selection-is-required"));
            return;
        }

        setErrorText("");

        let selectedOccupationIDs: Array<string> = [

        ];

        selectedOccupation.map((item) =>{
            selectedOccupationIDs.push(item._id);
        });
        
        let _matchParameters : SubscribeAlarmMatchParametersTypes ={
            occupationIDs: selectedOccupationIDs
        };

        if(selectedCities?.length){
            let selectedCityIDs: Array<string> = [];
            selectedCities.map((item) =>{
                selectedCityIDs.push(item._id);
            });
            _matchParameters.cityIDs = selectedCityIDs;
        }

        if(selectedEducationLevels?.length){
            let selectedEducationLevelIDs: Array<string> = [];
            selectedEducationLevels.map((item) =>{
                selectedEducationLevelIDs.push(item._id);
            });
            _matchParameters.educationLevelIDs = selectedEducationLevelIDs;
        }

        if(selectedForeignLanguages?.length){
            let selectedForeignLanguageIDs: Array<string> = [];
            selectedForeignLanguages.map((item) =>{
                selectedForeignLanguageIDs.push(item._id);
            });
            _matchParameters.foreignLanguages = selectedForeignLanguageIDs;
        }

        if(isCandidatesForDisabled === "true"){
            _matchParameters.isDisabledPeople = true;
        }

        if(isCandidatesForDisabled === "false"){
            _matchParameters.isDisabledPeople = false;
        }

        if(selectedCapabilities?.length){
            let selectedCapabiliyIDs: Array<string> = [];
            selectedCapabilities.map((item) =>{
                selectedCapabiliyIDs.push(item._id);
            });
            _matchParameters.capabilityIDs = selectedCapabiliyIDs;
        }
        
        RESTService.action("SubscribeAlarm",{
            title: alarmTitle,
            matchParameters:_matchParameters
        })
            .then((res) => {
                let _alarmData = JSON.parse(JSON.stringify(alarmData));
                _alarmData.push({
                    matchParameters: res.matchParameters,
                    title: res.title,
                    _id: res._id
                });
                setAlarmData(_alarmData);
                onClickCloseButton();
            })
            .catch((err) => {
                setErrorText(localize(err.message));
                console.error("subscribeAlarm Err:", err);
            });
    };

    const renderModalHeader = () =>{
        return <div
            className={classes.modalHeader}
            style={{
                gap: spaces.content
            }}
        >
            <div
                className={classes.closeButtonField}
            >
                <Button
                    onClick={()=>{
                        onClickCloseButton();
                    }}
                    icon={() => <CloseIcon
                        color={colors.textGrey}
                        size={14}
                    />
                    }
                    variant="ghost"
                />
            </div>
            <Text
                variant="header3-medium"
                color="textDark"
            >
                {localize("create-new-alarm")}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("edit-candidate-alarm-info")}
            </Text>
        </div>;
    };

    const renderAlarmTitleField = () =>{
        return <div
            className={classes.alarmTitleField}
            style={{
                paddingRight:spaces.container,
                paddingLeft:spaces.container,
                gap: spaces.content
            }}
        >
            <Text
                variant="body-medium"
                color="backgroundDark"
                style={{
                    marginTop: spaces.container *2
                }}
            >
                {localize("alarm-title")}
            </Text>
            <TextInput
                placeholder={localize("create-alarm-name")}
                onChangeText={(text) => setAlarmTitle(text)}
                spreadBehaviour="free"
                style={{
                    width:"100%"
                }}
            />
        </div>;
    };

    const renderCities = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content,
                }}
                onClick={()=>{
                    if(viewData === "city"){
                        setViewData(undefined);
                    }else {
                        setViewData("city");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("choose-city")}
                </Text>
                {
                    viewData === "city" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedCities.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeCities(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "city" &&
                    <div
                        className={classes.dataContainer}
                        style={{
                            gap: spaces.inline
                        }}
                    >
                        <div
                            className={classes.occupationContent}
                        >
                            <div
                                className={classes.checkBoxContainer}
                                style={{
                                    padding: spaces.content
                                }}
                            >
                                {
                                    citiesData.map((item) => {
                                        let isSelected = false;

                                        if(selectedCities.length) {
                                            let _isSelected =  selectedCities.findIndex((e:CitiesResponseObjectType) => e._id === item._id);
                                            if(_isSelected !== -1){
                                                isSelected = true;
                                            }
                                        }

                                        return <CheckBox
                                            title={item.localizedText}
                                            isSelected={isSelected}
                                            onChange={() => {
                                                onChangeCities(item);
                                            }}
                                            key={item._id}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>;
    };

    const renderEducationLevels = () => {
        if(!isSeeAll){
            return;
        }
        
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content,
                }}
                onClick={()=>{
                    if(viewData === "educationLevel"){
                        setViewData(undefined);
                    }else {
                        setViewData("educationLevel");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("education-level")}
                </Text>
                {
                    viewData === "educationLevel" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedEducationLevels.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeEducationLevels(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "educationLevel" &&
                    <div
                        className={classes.dataContainer}
                        style={{
                            gap: spaces.inline
                        }}
                    >
                        <div
                            className={classes.occupationContent}
                        >
                            <div
                                className={classes.checkBoxContainer}
                                style={{
                                    padding: spaces.content
                                }}
                            >
                                {
                                    educationLevelData.map((item) => {
                                        let isSelected = false;

                                        if(selectedEducationLevels.length) {
                                            let _isSelected =  selectedEducationLevels.findIndex((e:EducationLevelsObjectType) => e._id === item._id);
                                            if(_isSelected !== -1){
                                                isSelected = true;
                                            }
                                        }

                                        return <CheckBox
                                            title={item.localizedText}
                                            isSelected={isSelected}
                                            onChange={() => {
                                                onChangeEducationLevels(item);
                                            }}
                                            key={item._id}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>;
    };

    const renderOccupations = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "occupation"){
                        setViewData(undefined);
                    }else {
                        setViewData("occupation");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("choose-occupation")}
                </Text>
                {
                    viewData === "occupation" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedOccupation.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeOccupation(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "occupation" &&
                <div>
                    <TextInput
                        icon={() => {
                            return <div
                                style={{
                                    marginRight: spaces.content / 2
                                }}
                            >
                                <MagnifierIcon
                                    color={colors.textGrey}
                                    size={20}
                                />
                            </div>;
                        }}
                        spreadBehaviour="stretch"
                        placeholder={localize("search-job")}
                        onChangeText={(e) => {
                            setOccupationLoading(true);
                            setSearchOccupationPage(1);
                            setSearhOccupation(e);
                            onSearchOccupation(e);
                        }}
                    />
                    <div
                        className={classes.occupationContent}
                        onScroll={(e) => {
                            if(occupationLoading) {
                                return;
                            }
                            if(occupationEndOfData) {
                                return;
                            }
                            if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                                if(searchOccupation.length) {
                                    onSearchOccupation(searchOccupation);
                                } else {
                                    getOccupations();
                                }
                            }
                        }}
                    >
                        {
                            occupationRenderData.map((item) => {
                                let isSelected = false;

                                if(selectedOccupation.length) {
                                    isSelected =  selectedOccupation.findIndex((e) => e._id === item._id) !== -1;
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        onChangeOccupation(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                        {
                            occupationLoading ? <Loading/> : null
                        }
                    </div>
                </div>
            }
        </div>;
    };

    const renderCapabilities = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "capability"){
                        setViewData(undefined);
                    }else {
                        setViewData("capability");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("capabilities")}
                </Text>
                {
                    viewData === "capability" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedCapabilities.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeCapabilities(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "capability" &&
                    <div
                        className={classes.dataContainer}
                        style={{
                            gap: spaces.inline
                        }}
                    >
                        <div
                            className={classes.occupationContent}
                        >
                            <div
                                className={classes.checkBoxContainer}
                                style={{
                                    padding: spaces.content
                                }}
                            >
                                {
                                    capabilitiesData.map((item) => {
                                        let isSelected = false;

                                        if(selectedCapabilities.length) {
                                            let _isSelected =  selectedCapabilities.findIndex((e) => e._id === item._id);
                                            if(_isSelected !== -1){
                                                isSelected = true;
                                            }
                                        }

                                        return <CheckBox
                                            title={item.localizedText}
                                            isSelected={isSelected}
                                            onChange={() => {
                                                onChangeCapabilities(item);
                                            }}
                                            key={item._id}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>;
    };

    const renderForeignLanguages = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content,
                }}
                onClick={()=>{
                    if(viewData === "foreignLanguage"){
                        setViewData(undefined);
                    }else {
                        setViewData("foreignLanguage");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("foreign-language")}
                </Text>
                {
                    viewData === "foreignLanguage" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedForeignLanguages.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeForeignLanguages(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "foreignLanguage" &&
                    <div
                        className={classes.dataContainer}
                        style={{
                            gap: spaces.inline
                        }}
                    >
                        <div
                            className={classes.occupationContent}
                        >
                            <div
                                className={classes.checkBoxContainer}
                                style={{
                                    padding: spaces.content
                                }}
                            >
                                {
                                    foreignLanguagesData.map((item) => {
                                        let isSelected = false;

                                        if(selectedForeignLanguages.length) {
                                            let _isSelected =  selectedForeignLanguages.findIndex((e) => e._id === item._id);
                                            if(_isSelected !== -1){
                                                isSelected = true;
                                            }
                                        }

                                        return <CheckBox
                                            title={item.localizedText}
                                            isSelected={isSelected}
                                            onChange={() => {
                                                onChangeForeignLanguages(item);
                                            }}
                                            key={item._id}
                                        />;
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>;
    };

    const renderDisabledCandidates = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("disable-candidate")}
            </Text>
            {
                DISABLED_CANDIDATES_FILTERS.map((item:any) => {
                    let isSelected = false;

                    if(isCandidatesForDisabled) {
                        isSelected =  isCandidatesForDisabled === item.value ? true : false;
                    }

                    return <RadioButton
                        title={localize(item.localizedText)}
                        isSelected={isSelected}
                        key={item.id}
                        onChange={() => {
                            setIsCandidatesForDisabled(item.value);
                            window.localStorage.setItem("setIsCandidatesForDisabled",item.value);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderSeeAll = () =>{
        return <Button
            title={isSeeAll ? localize("reduce") : localize("see-all")}
            iconDirection="right"
            variant="ghost"
            onClick={() =>{
                setIsSeeAll(!isSeeAll);
            }}
            icon={() =>{
                return isSeeAll ?
                    <FilledArrowUpIcon 
                        size={20}
                    />
                    :
                    <FilledArrowDownIcon 
                        size={20}
                    />;
            }}
        />;
    };

    const renderModalContent = () =>{
        return <div
            className={classes.content}
            style={{
                gap: spaces.content
            }}
        >
            {renderAlarmTitleField()}
            {renderOccupations()}
            {renderCities()}
            {renderEducationLevels()}
            {renderCapabilities()}
            {renderForeignLanguages()}
            {renderDisabledCandidates()}
            {renderSeeAll()}
        </div>;
    };

    const renderErrorTextField = () =>{
        return <Text
            variant="body-medium"
        
            color="danger"
            style={{
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                width: "100%"
            }}
        >
            {errorText}
        </Text>;
    };

    const renderButtonField = () =>{
        return <div
            className={classes.saveButtonField}
        >
            <Button
                title = {localize("create")}
                onClick = {() => {
                    subscribeAlarm();
                }}
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer3,
            padding: spaces.container,
            gap: spaces.container
        }}
    >
        {renderModalHeader()}
        {renderModalContent()}
        {renderErrorTextField()}
        {renderButtonField()}
    </div>;
};

export default CreateAlarm;
