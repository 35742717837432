import React, {
    useEffect,
    useRef,
    useState
} from "react";
import useStyles from "./styles";
import { 
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    PageModal,
    CheckBox,
    Loading,
    Dialog,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    DocumentUploadProps,
    DocumentStateType,
    SelectedItems,
    SelectedItem
} from "./types";
import { 
    RoundArrowIcon,
    DocumentsIcon,
    CircleIcon,
    TrashIcon,
    InfoIcon,
    PenIcon
} from "../../assets/svgr";
import {
    RESTService
} from "../../services/restAPI";
import {
    GetDocumentTemplatesResponseType,
    GetDocumentTemplatesObjectType
} from "../../services/restAPI/actions/getDocumentTemplates/types";
import {
    GetDocumentTypesResponseObjectType
} from "../../services/restAPI/actions/getDocumentTypes/types";
import {
    EditDocumentTemplateRequestType,
    EditDocumentTemplateObject
} from "../../services/restAPI/actions/editDocumentTemplate/types";
import {
    DocumentTypesObject
} from "../../services/restAPI/actions/getDocumentTempate/types";
import {
    NewDocumentTemplateRequestType
} from "../../services/restAPI/actions/newDocumentTemplate/types";
import {
    ITextInputRef 
} from "isinolacak-web-cl/lib/components/textInput/textInput.props";
import {
    getErrorText,
    uuid
} from "../../utils";

const DocumentComponents = ({
    setCreateAttachmentStatus,
    setDocumentSave
}: DocumentUploadProps) => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();
    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();
    const [getDocumentTemplates, setGetDocumentTemplates] = useState<GetDocumentTemplatesResponseType | null>(null);
    const [getDocumentTypes, setGetDocumentTypes] = useState<GetDocumentTypesResponseObjectType[]>([]);
    const [deleteDocumentLoadingButton, setDeleteDocumentLoadingButton] = useState(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>();
    const [newDocumentLoadingButton, setNewDocumentLoadingButton] = useState(false);
    const [newDocumentTemplateInput, setNewDocumentTemplateInput] = useState("");
    const [editDocumentLoadingButton, setEditLoadingButton] = useState(false);
    const [selectedItems, setSelectedItems] = useState<SelectedItems>([]);
    const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [document, setDocument] = useState<DocumentStateType>({
        isVisible: false,
        data: {
            documentTypes: [{
                localizedText: "",
                title: "",
                _id: ""
            }],
            title: "",
            _id: ""
        }
    });
    const [editTitle, setEditTitle] = useState("");
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState("");

    const ınputRef = useRef<ITextInputRef | null>(null);
    
    useEffect(() => {
        handleGetDocumentTemplates();
        handleGetDocumentTypes();
    }, []);

    const handleNewDocumentTemplate = () => {
        if (getDocumentTemplates && getDocumentTemplates.some(doc => doc.title === title)) {
            alert(localize("a-document-with-this-name-already-exists"));
            return;
        }
    
        let updatedItems = selectedItems.map((item: {documentID: string; title: string; }) => {
            if (item.documentID && item.documentID.startsWith("new")) {
                return {
                    title: item.title
                };
            } else {
                return {
                    documentTypeID: item.documentID
                };
            }
        });

        const variables: NewDocumentTemplateRequestType = {
            documentTypes: [],
            title: ""
        };
        let _errors = JSON.parse(JSON.stringify(errors));
    
        if (updatedItems.length) {
            variables.documentTypes = updatedItems;
        } else {
            _errors.push("new-updatedItems-length-error");
            setErrors(_errors);
        }
    
        if (title.length) {
            if (title.length >= 2) {
                variables.title = title;
            } else {
                _errors.push("new-title-length-error");
                setErrors(_errors);
            }
        } else {
            _errors.push("new-title-length-error");
            setErrors(_errors);
        }
    
        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }
        
        setNewDocumentLoadingButton(true);
        RESTService.action("NewDocumentTemplate", variables)
            .then((res) => {
                let updatedDocumentTemplates = JSON.parse(JSON.stringify(getDocumentTemplates || []));
  
                const newDocument = {
                    documentTypes: res.documentTypes,
                    title: title,
                    _id: res._id
                };
                
                updatedDocumentTemplates.push(newDocument);
                setGetDocumentTemplates(updatedDocumentTemplates);
                setSelectedDocumentId(newDocument._id);
                setNewDocumentLoadingButton(false);
                setIsVisibleDialog(true);
    
                ınputRef.current?.cleanText();
                setTitle("");
                setSelectedItems([]);
            }).catch((err) => {
                console.error("NewDocumentTemplate ERR:", err);
                setNewDocumentLoadingButton(false);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const handleGetDocumentTypes = () => {
        setLoading(true);
        RESTService.action("GetDocumentTypes", {
        }).then((res) => {
            setGetDocumentTypes(res.payload);
            setLoading(false);
        }).catch((err) => {
            console.error("GetDocumentTypes ERR:", err);
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const handleGetDocumentTemplates = () => {
        setLoading(true);
        RESTService.action("GetDocumentTemplates", {
            page: 1
        }).then((res) => {
            setGetDocumentTemplates(res);
            setLoading(false);
        }).catch((err) => {
            console.error("GetDocumentTemplates ERR:", err);
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const handleEditDocumentTemplates = () => {
        const selectedDocument = getDocumentTemplates?.find(item => item._id === selectedDocumentId);
        if (!selectedDocument) return;

        let _errors = JSON.parse(JSON.stringify(errors));
        const updatedItems: Array<EditDocumentTemplateObject> = selectedItems.map((item: {
            documentID: string;
            title: string;
            _id: string;
        }) => {
            if (item.documentID && item.documentID.startsWith("new")) {
                return {
                    title: item.title
                };
            } else if (item.documentID && item.documentID.length) {
                return {
                    documentTypeID: item.documentID
                };
            } else {
                return {
                    documentTypeID: item._id
                };
            }
        });

        const variables: EditDocumentTemplateRequestType = {
        };
    
        if (selectedDocument._id) {
            variables.documentTemplateID = selectedDocument._id;
        }
    
        if (updatedItems.length > 0) {
            variables.documentTypes = updatedItems;
        } else {
            _errors.push(`edit-${selectedDocumentId}-updatedItems-length-error`);
            setErrors(_errors);
        }
    
        if (editTitle.length) {
            if(editTitle.length >= 2) {
                variables.title = editTitle;
            } else {
                _errors.push(`edit-${selectedDocumentId}-title-length-error`);
                setErrors(_errors);
            }
        } else {
            _errors.push(`edit-${selectedDocumentId}-title-length-error`);
            setErrors(_errors);
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }
    
        setEditLoadingButton(true);
        RESTService.action("EditDocumentTemplate", variables)
            .then((res) => {
                setDocument({
                    isVisible: false,
                    data: {
                        documentTypes: [{
                            localizedText: "",
                            title: "",
                            _id: "",
                        }],
                        title: "",
                        _id: ""
                    }
                });
                setLoading(false);
                setIsVisibleDialog(true);
                setEditLoadingButton(false);
    
                //@ts-ignore
                setGetDocumentTemplates(prevTemplates => {
                    if (!prevTemplates) return [res];
                    
                    const documentExists = prevTemplates.some(doc => doc._id === res._id);
                    
                    if (documentExists) {
                        return prevTemplates.map(doc => 
                            doc._id === res._id 
                                ? {
                                    ...doc, ...res 
                                }
                                : doc
                        ) as GetDocumentTemplatesResponseType;
                    } else {
                        let _doc: GetDocumentTemplatesObjectType = {
                            _id: res._id,
                            documentTypes: res.documentTypeIDs,
                            title: res.title
                        };
                        return [...prevTemplates, _doc] as unknown as GetDocumentTemplatesResponseType;
                    }
                });
            })
            .catch((err) => {
                console.error("EditDocumentTemplate ERR:", err);
                setLoading(false);
                setEditLoadingButton(false);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const handleDeleteDocumentTemplates = (id: string) => {
        setDeleteDocumentLoadingButton(true);
        RESTService.action("DeleteDocumentTemplate", {
            documentTemplateID: id
        }).then((res) => {
            const updatedDocumentTemplates = JSON.parse(JSON.stringify(getDocumentTemplates || []));
            const filteredTemplates = updatedDocumentTemplates.filter((template: { _id: string; }) => template._id !== id);
            
            setGetDocumentTemplates(filteredTemplates);
            setDeleteDocumentLoadingButton(false);
            setIsVisibleDialog(true);
        }).catch((err) => {
            console.error("DeleteDocumentTemplate ERR:", err);
            setDeleteDocumentLoadingButton(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const addNewDocument = () => {
        setIsAddingNewDocument(true);
    };

    const saveNewDocument = () => {
        if (getDocumentTypes.some(docType => docType.localizedText === newDocumentTemplateInput)) {
            alert(localize("a-document-type-with-this-name-already-exists"));
            return;
        }

        if (newDocumentTemplateInput) {
            const newDocument: GetDocumentTypesResponseObjectType = {
                localizedText: newDocumentTemplateInput,
                _id: `new${uuid()}`
            };
            setGetDocumentTypes([...getDocumentTypes, newDocument]);
            setNewDocumentTemplateInput("");
            setIsAddingNewDocument(false);
        }
    };

    const toggleSelectedDocument = (id: string) => {
        setSelectedDocumentId((prevId) => prevId === id ? prevId : id);
    };

    const handleUseDocument = () => {
        if (selectedDocumentId) {
            const selectedDocument = getDocumentTemplates?.find(item => item._id === selectedDocumentId);
            if (selectedDocument) {
                setDocumentSave(selectedDocument);
                setTimeout(() => {
                    setCreateAttachmentStatus("advertInfo");
                }, 300);
            }
        }
    };

    const renderPreviouslyCreatedDocuments = () => {
        if (!getDocumentTemplates || !getDocumentTemplates.length) {
            return <div className={classes.previouslyCreatedDocumentsContainer}>
                <InfoIcon color={colors.textGrey} />
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    style={{
                        marginLeft: spaces.content
                    }}
                >
                    {localize("created-ads-will-be-listed-for-you-to-use-later")}
                </Text>
            </div>;
        } else {
            if(loading) {
                return <div
                    className={classes.loadingContainer}
                    style={{
                        marginTop: spaces.container
                    }}
                >
                    <Loading/>
                </div>;
            }

            return <div>
                <Text
                    variant="header5-medium"
                    color="textDark"
                    style={{
                        marginRight: spaces.content
                    }}
                >
                    {localize("previously-created-documents")}
                </Text>
                {getDocumentTemplates.map((item, index) => {
                    return <div
                        className={classes.previouslyCreatedDocumentsContent}
                        key={index}
                        onClick={() => {
                            toggleSelectedDocument(item._id);
                        }}
                        style={{
                            borderColor: selectedDocumentId === item._id ? colors.primary : colors.stroke,
                            borderWidth: spaces.inline / 5,
                            borderRadius: spaces.inline,
                            padding: spaces.inline / 1,
                            marginTop: spaces.content
                        }}
                    >
                        <div
                            className={classes.documentsIcon}
                            style={{
                                borderRadius: spaces.contentXLarge,
                                borderWidth: spaces.inline / 5,
                                padding: spaces.content / 2,
                                borderColor: colors.primary
                            }}
                        >
                            <DocumentsIcon />
                        </div>
                        <div
                            style={{
                                flexGrow: spaces.item
                            }}>
                            <Text
                                variant="body-regular"
                                color="textDark"
                                style={{
                                    marginLeft: spaces.content
                                }}
                            >
                                {item.title}
                            </Text>
                        </div>
                        <div
                            className={classes.previouslyCreatedDocumentsRightIcon}
                        >
                            <div
                                onClick={() => {
                                    setDocument({
                                        isVisible: true,
                                        data: {
                                        //@ts-ignore
                                            documentTypes: item.documentTypes,
                                            title: item.title,
                                            _id: item._id
                                        }
                                    });
                                    setEditTitle(item.title);
                                    setSelectedItems(item.documentTypes);
                                }}
                                style={{
                                    marginRight: spaces.inline
                                }}
                            >
                                <PenIcon
                                    size={20}
                                />
                            </div>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteDocumentTemplates(item._id);
                                }}
                            >
                                <TrashIcon
                                    color={colors.textSecondary}
                                    size={20}
                                />
                            </div>
                        </div>
                    </div>;
                })}
                {selectedDocumentId && selectedDocumentId.length && (
                    <Button
                        onClick={() => {
                            handleUseDocument();
                        }}
                        spreadBehaviour="stretch"
                        title={localize("use")}
                        style={{
                            marginBottom: spaces.content * 1.5,
                            marginTop: spaces.content * 1.5,
                        }}
                    />
                )}
            </div>;
        }
    };

    const renderPageModal = () => {
        return <PageModal
            onOverlayPress={() => setDocument({
                isVisible: false,
                data: {
                    documentTypes: [{
                        localizedText: "",
                        title: "",
                        _id: "",
                    }],
                    title: "",
                    _id: ""
                }
            })}
            contentContainerStyle={{
                backgroundColor: colors.white 
            }}
            content={
                <div
                    style={{
                        padding: spaces.content 
                    }}>
                    <Text
                        variant="header5-medium"
                        color="textDark"
                        style={{
                            marginRight: spaces.content 
                        }}
                    >
                        {localize("IOCoreDefaultDialogPrimaryButtonTitle")}
                    </Text>
                    <TextInput
                        infoText={errors.includes(`edit-${selectedDocumentId}-title-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                        isError={errors.some(err => err === `edit-${selectedDocumentId}-title-length-error`)}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = `edit-${selectedDocumentId}-title-length-error`;
                            let _index = _errors.indexOf(nameError);
                            _errors.splice(_index, 1);
                            setErrors(_errors);
                        }}
                        placeholder={localize("new-document-name")}
                        onChangeText={(text) => setEditTitle(text)}
                        initialValue={document.data?.title}
                        defaultValue={editTitle}
                        style={{
                            marginTop: spaces.content 
                        }}
                    />
                    <SelectBox
                        infoText={errors.includes(`edit-${selectedDocumentId}-updatedItems-length-error`) ? localize("needs-to-be-filled") : undefined}
                        isError={errors.some(err => err === `edit-${selectedDocumentId}-updatedItems-length-error`)}
                        titleExtractor={(item: DocumentTypesObject) => item.localizedText}
                        keyExtractor={(item: DocumentTypesObject) => item._id}
                        initialSelectedItems={selectedItems}
                        title={localize("documents")}
                        isNeedConfirm={true}
                        multiSelect={true}
                        disabled={false}
                        style={{
                            marginTop: spaces.content
                        }}
                        renderItem={({
                            isSelected,
                            onClick,
                            index,
                            item
                        }) => {
                            //@ts-ignore //TODO: TYPES ERROR
                            if (item && item.type === "add") {
                                return isAddingNewDocument ? 
                                    <div>
                                        <TextInput
                                            onChangeText={(text) => setNewDocumentTemplateInput(text)}
                                            placeholder={localize("new-document-name")}
                                            initialValue={newDocumentTemplateInput}
                                            style={{
                                                marginBottom: spaces.content 
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                saveNewDocument();
                                            }}
                                            title={localize("save")}
                                        />
                                    </div>
                                    : <div onClick={addNewDocument}>
                                        <Text
                                            variant="body2-medium"
                                            color="textGrey"
                                            style={{
                                                marginBottom: spaces.content 
                                            }}
                                        >
                                                + {localize("add-document")}
                                        </Text>
                                    </div>;
                            }
                            return <div>
                                <CheckBox
                                    key={`select-box-item-${index}`}
                                    //@ts-ignore
                                    onChange={() => onClick(item)}
                                    isSelected={isSelected}
                                    //@ts-ignore
                                    title={item.localizedText}
                                />
                            </div>;
                        }}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }: {
                            closeSheet: () => void;
                            onSuccess: () => void;
                            selectedItems: any
                        }) => {
                            const updatedItems = selectedItems.map((item: SelectedItem) => ({
                                localizedText: item.localizedText,
                                title: item.localizedText,
                                documentID: item._id,
                                key: item.__key,
                                _id: item._id
                            }));
                            setSelectedItems(updatedItems);
                            
                            let _getDocumentTemplates = JSON.parse(JSON.stringify(getDocumentTemplates));
                            const currentDocIndex = getDocumentTemplates?.findIndex(item => item._id === document.data?._id);

                            if(currentDocIndex !== undefined && currentDocIndex !== -1) {
                                _getDocumentTemplates[currentDocIndex].documentTypes = selectedItems;
                                setGetDocumentTemplates(_getDocumentTemplates);
                            }
                            let updatedErrors = errors.filter(err => err !== `updatedItems-${selectedDocumentId}-edit-length-error`);
                            setErrors(updatedErrors);
                            closeSheet();
                            onSuccess();
                        }}
                        inputTitle=""
                        data={[...(getDocumentTypes), {
                            type: "add" 
                        }] as any}
                    />
                    <Button
                        onClick={() => handleEditDocumentTemplates()}
                        spreadBehaviour="stretch"
                        title={localize("save")}
                        loading={editDocumentLoadingButton}
                        style={{
                            marginBottom: spaces.content * 1.5, 
                            marginTop: spaces.content * 1.5 
                        }}
                    />
                </div>
            }
            isVisible={document.isVisible}
        />;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("saved-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    if(loading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <div
        style={{
            padding: spaces.content * 2
        }}
    >
        <div
            className={classes.textIconDiv}
            style={{
                gap: spaces.content
            }}
        >
            <div
                onClick={() => {
                    setCreateAttachmentStatus("advertInfo");
                }}
            >
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("creating-documents")}
            </Text>
        </div>
        <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content 
            }}>
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("document-detail")}
            </Text>
            <TextInput
                infoText={errors.includes("new-title-length-error") ? localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.some(err => err === "new-title-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "new-title-length-error";
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                    setErrors(_errors);
                }}
                onChangeText={(text) => setTitle(text)}
                ref={(e) => ınputRef.current = e}
                placeholder={localize("name")}
                initialValue={title}
                style={{
                    marginTop: spaces.content 
                }}
            />
            <SelectBox
                infoText={errors.includes("new-updatedItems-length-error") ? localize("needs-to-be-filled") : undefined}
                isError={errors.some(err => err === "new-updatedItems-length-error")}
                titleExtractor={(item: GetDocumentTypesResponseObjectType) => item.localizedText}
                keyExtractor={(item: any) => item._id}
                title={localize("documents")}
                isNeedConfirm={true}
                multiSelect={true}
                disabled={false}
                style={{
                    marginTop: spaces.content 
                }}
                renderItem={({
                    isSelected,
                    onClick,
                    index,
                    item,
                }) => {
                    //@ts-ignore //TODO: TYPES ERROR
                    if (item && item.type === "add") {
                        if (isAddingNewDocument) {
                            return <div>
                                <TextInput
                                    onChangeText={(e) => setNewDocumentTemplateInput(e)}
                                    initialValue={newDocumentTemplateInput}
                                    placeholder={localize("new-document-name")}
                                    style={{
                                        marginBottom: spaces.content 
                                    }}
                                />
                                <Button 
                                    onClick={() => {
                                        saveNewDocument();
                                    }}
                                    title={localize("save")}
                                />
                            </div>;
                        } else {
                            return <div
                                onClick={addNewDocument}
                            >
                                <Text
                                    variant="body2-medium"
                                    color="textGrey"
                                    style={{
                                        marginBottom: spaces.content 
                                    }}
                                >
                                    + {localize("add-document")}
                                </Text>
                            </div>;
                        }
                    }

                    return <div>
                        <CheckBox
                            key={`select-box-item-${index}`}
                            onChange={() => {
                                //@ts-ignore //TODO: TYPES ERROR
                                onClick(item);
                            }}
                            isSelected={isSelected}
                            //@ts-ignore //TODO: TYPES ERROR
                            title={item.localizedText}
                        />
                    </div>;
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }: {
                    closeSheet: () => void;
                    onSuccess: () => void;
                    selectedItems: any;
                }) => {
                    const updatedItems = selectedItems.map((item: SelectedItem) => ({
                        title: item.localizedText,
                        documentID: item._id,
                        key: item.__key
                    }));
                    setSelectedItems(updatedItems);
                    let updatedErrors = errors.filter(err => err !== "new-updatedItems-length-error");
                    setErrors(updatedErrors);
                    closeSheet();
                    onSuccess();
                }}
                inputTitle={""}
                data={[...(getDocumentTypes), {
                    type: "add" 
                }] as any}
            />
        </div>
        <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}>
            <Button
                onClick={() => {handleNewDocumentTemplate();}}
                loading={newDocumentLoadingButton}
                title={localize("create")}
                spreadBehaviour="stretch"
                style={{
                    marginBottom: spaces.content * 1.5 
                }}
            />
        </div>
        {renderPreviouslyCreatedDocuments()}
        {renderPageModal()}
        {renderSuccessDialog()}
    </div>;
};

export default DocumentComponents;