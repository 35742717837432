import React, {
    useEffect,
    Fragment,
    useState,
    useRef
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    TextArea,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    PersonalInfoInputTypes,
    SelectedItem
} from "./types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../../../../services/restAPI/actions/getOccupation/types";
import {
    GetSectorsResponseObjectType,
    GetSectorsRequestType
} from "../../../../../../../../services/restAPI/actions/getSectors/types";
import {
    GetAreaCodesObjectType
} from "../../../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    GetCitiesPayloadObjectType
} from "../../../../../../../../services/restAPI/actions/getCities/types";
import {
    GetDistrictsObjectType
} from "../../../../../../../../services/restAPI/actions/getDistricts/types";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    CircleIcon
} from "../../../../../../../../assets/svgr";
import {
    EditCompanyProfileRequestTypes 
} from "../../../../../../../../services/restAPI/actions/editCompanyProfile/types";
import {
    getErrorText 
} from "../../../../../../../../utils";

const PersonalInfoInput = ({
    setPersonalInfo,
    personalInfo,
    getMyProfile,
    setErrors,
    setViews,
    errors
}: PersonalInfoInputTypes) => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getOccupation, setGetOccupation] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [districts, setDistricts] = useState<Array<GetDistrictsObjectType>>([]);
    const [city, setCity] = useState<Array<GetCitiesPayloadObjectType>>([]);
    const [occupationsSearchPage, setOccupationsSearchPage] = useState(1);
    const [searchOccupationText, setSearchOccupationText] = useState("");
    const [occupationsLoading, setOccupationsLoading] = useState(false);
    const [cityForDistrict, setCityForDistrict] = useState<string>("");
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [occupationsPage, setOccupationsPage] = useState(1);
    const [loadingButton, setLoadingButton] = useState(false);
    const [totalDataCount, setTotalDataCount] = useState(1);

    const [getSectors, setGetSectors] = useState<Array<GetSectorsResponseObjectType>>([]);
    const [sectorsSearchPage, setSectorsSearchPage] = useState(1);
    const [searchSectorsText, setSearchSectorsText] = useState("");
    const [sectorsLoading, setSectorsLoading] = useState(false);
    const [sectorsPage, setSectorsPage] = useState(1);
    const [totalDataCountSectors, setTotalDataCountSectors] = useState(1);

    const searchDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const _langugage = window.localStorage.getItem("userLanguage");
    
    useEffect(() => {
        setCityForDistrict(company.city._id);
        getOccupationData();
        getSectorsData();
        getCity();
    }, []);

    useEffect(() => {
        if(cityForDistrict) {
            getDistricts(cityForDistrict);
        }
    }, [city]);

    useEffect(() => {
        search(searchOccupationText);
        searchSectors(searchSectorsText);
    }, [searchOccupationText, searchSectorsText]);

    const searchSectors = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if(searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getSectorsData({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getSectorsData();
        }
    };

    const getSectorsData = (searchParams?: GetSectorsRequestType) => {
        if(!sectorsLoading) setSectorsLoading(true);

        let params: GetSectorsRequestType = {
            page: sectorsPage,
        };

        if(searchSectorsText && searchSectorsText.length) {
            params.search = searchSectorsText;
            params.page = sectorsSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetSectors", params)
            .then((res) => {
                setTotalDataCountSectors(res.totalData);
                setGetSectors(res.payload);

                if(searchParams?.search || searchSectorsText.length){
                    setSectorsPage(1);
                } else {
                    setSectorsPage(params.page ?? 1);
                    setSectorsSearchPage(1);
                }

                setSectorsLoading(false);
            })
            .catch((err) => {
                setSectorsLoading(false);
                console.error("GetSectors Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const search = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if(searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getOccupationData({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupationData();
        }
    };

    const getOccupationData = (searchParams?: GetOccupationRequestType) => {
        if(!occupationsLoading) setOccupationsLoading(true);

        let params: GetOccupationRequestType = {
            page: occupationsPage,
        };

        if(searchOccupationText && searchOccupationText.length) {
            params.search = searchOccupationText;
            params.page = occupationsSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetOccupation", params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setGetOccupation(res.payload);

                if(searchParams?.search || searchOccupationText.length){
                    setOccupationsPage(1);
                } else {
                    setOccupationsPage(params.page ?? 1);
                    setOccupationsSearchPage(1);
                }

                setOccupationsLoading(false);
            })
            .catch((err) => {
                setOccupationsLoading(false);
                console.error("GetOccupation Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const getCity = () => {
        RESTService.action("GetCities", {
        })
            .then((res) => {
                setCity(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getDistricts = (cityID: string) => {
        RESTService.action("GetDistricts", {
            cityID: cityID
        })
            .then((res) => {
                setDistricts(res.payload);
            })
            .catch((err) => {
                console.error("GetDistricts Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleEditUserProfile = () => {
        let params: EditCompanyProfileRequestTypes = {
        };
        let _errors = JSON.parse(JSON.stringify(errors));
    
        if (personalInfo.about?.length) {
            if (personalInfo.about.length >= 3 && personalInfo.about.length <= 2000) {
                params.about = personalInfo.about;
            } else if (personalInfo.about.length < 3) {
                _errors.push("about-min-length-error");
            } else if (personalInfo.about.length > 2000) {
                _errors.push("about-max-length-error");
            }
        }
    
        if (personalInfo.address?.length) {
            if (personalInfo.address.length >= 3 && personalInfo.address.length <= 500) {
                params.address = personalInfo.address;
            } else if (personalInfo.address.length < 3) {
                _errors.push("address-min-length-error");
            } else if (personalInfo.address.length > 500) {
                _errors.push("address-max-length-error");
            }
        }
    
        if (personalInfo.title?.length) {
            if (personalInfo.title.length >= 3 && personalInfo.title.length <= 100) {
                params.title = personalInfo.title;
            } else if (personalInfo.title.length < 3) {
                _errors.push("title-min-length-error");
            } else if (personalInfo.title.length > 100) {
                _errors.push("title-max-length-error");
            }
        }
    
        if (personalInfo.website?.length) {
            params.website = personalInfo.website;
        }
    
        if (personalInfo.occupation && personalInfo.occupation._id.length) {
            params.occupationID = personalInfo.occupation._id;
        }
    
        if (personalInfo.district && personalInfo.district._id) {
            params.districtID = personalInfo.district._id;
        } else {
            _errors.push("district-length-error");
        }
    
        if (personalInfo.sector._id.length) {
            params.sectorID = personalInfo.sector._id;
        }
    
        if (personalInfo.city && personalInfo.city._id) {
            params.cityID = personalInfo.city._id;
        }
    
        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }
    
        setLoadingButton(true);
        RESTService.action("EditCompanyProfile", params)
            .then((res) => {
                setIsVisibleDialog(true);
        
                setPersonalInfo({
                    ...personalInfo,
                    companyTitle: params.title,
                    companyOccupation: {
                        localizedText: personalInfo.occupation?.localizedText,
                        _id: personalInfo.occupation._id
                    }
                });
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("EditCompanyProfile Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const localStorageData: any = localStorage.getItem("company");
    const company = JSON.parse(localStorageData);
    
    const renderSaveButton = () => {
        return <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                onClick={() => handleEditUserProfile()}
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderTitle = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("company's-trade-name")}
            </Text>
            <TextInput
                infoText={
                    errors.includes("title-min-length-error") 
                        ? localize("ıt-must-be-at-least-3-characters") 
                        : errors.includes("title-max-length-error") 
                            ? localize("ıt-must-be-at-most-100-characters") 
                            : undefined
                }
                isError={
                    errors.includes("title-min-length-error") || errors.includes("title-max-length-error")
                }
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let minError = "title-min-length-error";
                    let maxError = "title-max-length-error";
                    _errors = _errors.filter((error: string) => error !== minError && error !== maxError);
                    setErrors(_errors);
                }}
                placeholder={localize("enter-your-information.")}
                initialValue={personalInfo.title}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        title: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderOccupation = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("your-role-in-the-company")}
            </Text>
            <SelectBox
                //@ts-ignore
                initialSelectedItems={
                    personalInfo && personalInfo.occupation ? [personalInfo.occupation] : undefined
                }
                titleExtractor={(item: GetOccupationResponseObjectType) => item.localizedText}
                title={localize("choose-your-role-in-company")}
                keyExtractor={(item) => item._id}
                onSearch={(e) => {
                    setSearchOccupationText(e);
                }}
                isSearchable={true}
                emptyContent={() => {
                    if(occupationsLoading) {
                        return <Fragment></Fragment>;
                    }

                    return <Text>
                        {localize("no-result-found")}
                    </Text>;
                }}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    if (selectedItems.length > 0) {
                        const selectedItem = selectedItems[0] as unknown as SelectedItem;
                        setPersonalInfo({
                            ...personalInfo,
                            occupation: {
                                _id: selectedItem._id,
                                localizedText: selectedItem.localizedText
                            }
                        });
                    }
                    closeSheet();
                    onSuccess();
                }}
                data={getOccupation}
                inputTitle={""}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderSector = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("sectors")}
            </Text>
            <SelectBox
                //@ts-ignore
                initialSelectedItems={
                    personalInfo && personalInfo.sector ? [personalInfo.sector] : undefined
                }
                titleExtractor={(item: GetSectorsResponseObjectType) => item.localizedText}
                keyExtractor={(item: GetSectorsResponseObjectType) => item._id}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                onSearch={(e) => {
                    setSearchSectorsText(e);
                }}
                isSearchable={true}
                emptyContent={() => {
                    if(sectorsLoading) {
                        return <Fragment></Fragment>;
                    }

                    return <Text>
                        {localize("no-result-found")}
                    </Text>;
                }}
                paginationProps={
                    totalDataCount > 20 ? {
                        currentPage: searchSectorsText && searchSectorsText.length ? sectorsSearchPage : sectorsPage,
                        totalDataCount: totalDataCountSectors,
                        itemPerPage: 20,
                        onSelect(item, index) {
                            if (searchSectorsText && searchSectorsText.length) {
                                setSectorsSearchPage(item.pageNumber);
                            } else {
                                setSectorsPage(item.pageNumber);
                            }
            
                            let params: {
                                search?: string;
                                page: number;
                            } = {
                                page: item.pageNumber
                            };
            
                            if (searchSectorsText && searchSectorsText.length) {
                                params.search = searchSectorsText;
                            }
            
                            getSectorsData(params);
                        }
                    } : undefined
                }
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let selectedItemsData = selectedItems.map((item: any) => item._id);
                    let selectedItemsString = selectedItemsData.join(",");
                    setPersonalInfo({
                        ...personalInfo,
                        sector: {
                            _id: selectedItemsString
                        }
                    });
                    closeSheet();
                    onSuccess();
                }}
                title={localize("choose-your-sector")}
                inputTitle={""}
                data={getSectors}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderAdress = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("address")}
            </Text>
            <TextInput
                infoText={
                    errors.includes("address-min-length-error") 
                        ? localize("ıt-must-be-at-least-3-characters") 
                        : errors.includes("address-max-length-error") 
                            ? localize("ıt-must-be-at-most-500-characters") 
                            : undefined
                }
                isError={
                    errors.includes("address-min-length-error") || errors.includes("address-max-length-error")
                }
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let minError = "address-min-length-error";
                    let maxError = "address-max-length-error";
                    _errors = _errors.filter((error: string) => error !== minError && error !== maxError);
                    setErrors(_errors);
                }}
                initialValue={personalInfo.address}
                placeholder={localize("enter-your-address-info")}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        address: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderCity = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("city")}
            </Text>
            <SelectBox
                //@ts-ignore
                initialSelectedItems={
                    personalInfo && personalInfo.city ? [personalInfo.city] : []
                }
                titleExtractor={(item: GetCitiesPayloadObjectType) => item.localizedText}
                keyExtractor={(item: GetCitiesPayloadObjectType) => item._id}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let selectedItemsData = selectedItems.map((item: any) => item._id);
                    let selectedItemsString = selectedItemsData.join(",");
                    setPersonalInfo({
                        ...personalInfo,
                        district: {
                            localizedText: "",
                            _id: ""
                        },
                        city: {
                            _id: selectedItemsString
                        }
                    });
                    getDistricts(selectedItemsData[0]);
                    closeSheet();
                    onSuccess();
                }}
                title={localize("choose-city")}
                inputTitle={""}
                data={city}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderCounty = () => {
        let initialItem: any = personalInfo && personalInfo.district ? [personalInfo.district] : [];
        //TODO: Select Box Start Selected items will be looked here once the issue is resolved.
        // if (!personalInfo.district._id.length) {
        //     initialItem = [];
        // }

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("county")}
            </Text>
            <SelectBox
                infoText={errors.includes("district-length-error") ? localize("needs-to-be-filled") : undefined}
                titleExtractor={(item: GetDistrictsObjectType) => item.localizedText}
                isError={errors.some(err => err === "district-length-error")}
                keyExtractor={(item: GetDistrictsObjectType) => item._id}
                initialSelectedItems={initialItem}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let selectedItemsData = selectedItems.map((item: any) => item._id);
                    let selectedItemsString = selectedItemsData.join(",");
                    setPersonalInfo({
                        ...personalInfo,
                        district: {
                            _id: selectedItemsString
                        }
                    });
                    let updatedErrors = errors.filter(err => err !== "district-length-error");
                    setErrors(updatedErrors);
                    closeSheet();
                    onSuccess();
                }}
                title={localize("choose-district")}
                inputTitle={""}
                data={districts}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderMail = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("e-mail")}
            </Text>
            <div onClick={() => setViews("company-mail-change")}>
                <TextInput
                    placeholder={localize("enter-your-information.")}
                    initialValue={personalInfo.mail}
                    style={{
                        marginTop: spaces.content / 2
                    }}
                />
            </div>
        </div>;
    };

    const renderWebsite = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("website")}
            </Text>
            <TextInput
                placeholder={localize("optional")}
                initialValue={personalInfo.website}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        website: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderPhoneNumber = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("company-contact-number")}
            </Text>
            <div onClick={() => setViews("company-phone-number-change")}>
                <div className={classes.datePickerInputContainer}>
                    <div>
                        <SelectBox
                            initialSelectedItems={
                                personalInfo && personalInfo.areaCode ? [personalInfo.areaCode] : undefined
                            }
                            //@ts-ignore
                            titleExtractor={(item: GetAreaCodesObjectType) => {
                                return (
                                    <div className={classes.areaCodesContainer}>
                                        <Text>{item.flagUnicode}</Text>
                                        <Text>{item.value}</Text>
                                    </div>
                                );
                            }}
                            keyExtractor={(e) => e._id}
                            spreadBehaviour="stretch"
                            isNeedConfirm={true}
                            multiSelect={false}
                            disabled={false}
                            onOk={({
                                closeSheet,
                                onSuccess
                            }) => {
                                closeSheet();
                                onSuccess();
                            }}
                            style={{
                                marginTop: spaces.content / 2,
                                marginRight: spaces.content
                            }}
                            data={[personalInfo.areaCode]}
                        />
                    </div>
                    <div
                        style={{
                            display: "block",
                            width: "100%"
                        }}
                    >
                        <TextInput
                            placeholder={"(5XX)XXX XX XX"}
                            initialValue={company.phone}
                            style={{
                                marginTop: spaces.content / 2
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderAbout = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("about")}
            </Text>
            <TextArea
                infoText={
                    errors.includes("about-min-length-error") 
                        ? localize("ıt-must-be-at-least-3-characters") 
                        : errors.includes("about-max-length-error") 
                            ? localize("ıt-must-be-at-most-2000-characters") 
                            : undefined
                }
                isError={
                    errors.includes("about-min-length-error") || errors.includes("about-max-length-error")
                }
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let minError = "about-min-length-error";
                    let maxError = "about-max-length-error";
                    _errors = _errors.filter((error: string) => error !== minError && error !== maxError);
                    setErrors(_errors);
                }}
                placeholder={localize("enter-information-about-yourself-or-your-company")}
                initialValue={personalInfo.about}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        about: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ıt-was-edited-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    const renderContent = () => {
        return <div>
            {renderTitle()}
            {renderOccupation()}
            {renderSector()}
            {renderAdress()}
            {renderCity()}
            {renderCounty()}
            {renderMail()}
            {renderWebsite()}
            {renderPhoneNumber()}
            {renderAbout()}
        </div>;
    };

    return <div
        style={{
            padding: spaces.content * 2
        }}
    >
        <Text
            variant="header5-medium"
            color="textDark"
        >
            {localize("personal-information")}
        </Text>
        {renderContent()}
        {renderSaveButton()}
        {renderSuccessDialog()}
    </div>;
};

export default PersonalInfoInput;
