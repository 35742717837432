import {
    IOCoreTheme,
    ToolBox,
    Text,
    IOCoreLocale
} from "isinolacak-web-cl";
import useStyles from "./styles";
import moment from "moment";
import {
    ConversationProps
} from "./types";
import emptyProfile from "../../assets/img/isinolacakMiniWithPadding.png";

const Conversation = ({
    onRemoveArchiveConversation,
    onArchiveConversation,
    onEndConversation,
    unReadMessageCount = 0,
    profilePhotoURL,
    isArchived,
    updatedAt,
    fullName,
    onClick,
    message
}: ConversationProps) => {
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderProfilePhoto = () => {
        return <div
            className={classes.profilePhotoContainer}
            style={{
                borderRadius: radiuses.hard * 100,
                borderWidth: borders.indicator,
                borderColor: colors.primary
            }}
        >
            <img 
                src={
                    profilePhotoURL ?
                        profilePhotoURL
                        :
                        emptyProfile
                }
                alt="profile"
                className={classes.profilePhotoContent}
            />
        </div>;
    };

    const renderContent = () => {
        return <div
            className={classes.contentContainer}
            onClick={onClick}
        >
            <div
                className={classes.header}
            >
                <Text 
                    variant='body2-regular'
                >
                    {fullName}
                </Text>
                <Text 
                    variant='body4-regular'
                    color='textSecondary'
                >
                    {
                        updatedAt
                            ?
                            moment(updatedAt).format("DD MMMM")
                            : null
                    }
                </Text>
            </div>
            <div
                className={classes.message}
            >
                <Text 
                    variant='body4-regular'
                    color='textSecondary'
                >
                    {message}
                </Text>
                {
                    unReadMessageCount !==0 &&
                    <div
                        className={classes.badge}
                        style={{
                            backgroundColor: colors.primary,
                        }}
                    >
                        <Text 
                            variant="caption-regular" 
                            color="constrastBody"
                        >
                            {unReadMessageCount}
                        </Text>
                    </div>
                }
            </div>
        </div>;
    };

    const renderOptions = () => {
        return <ToolBox
            content={renderOptionsContent()}
        >
            <div
                className={classes.optionDots}
                style={{
                    padding: spaces.container / 4
                }}
            >
                <div
                    className={classes.optionDot} 
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
                <div
                    className={classes.optionDot} 
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
                <div
                    className={classes.optionDot} 
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
            </div>
        </ToolBox>;
    };

    const renderOptionsContent = () => {
        return <div
            className={classes.optionsContent}
            style={{
                backgroundColor: colors.layer1,
                gap: spaces.content
            }}
        >
            <div
                onClick={() => {
                    onEndConversation();
                }}
            >
                <Text 
                    variant='body3-regular' 
                    color='textSecondary'
                >
                    {localize("terminate-chat")}
                </Text>
            </div>
            <div
                onClick={() => {
                    if(isArchived){
                        onRemoveArchiveConversation();
                    } else {
                        onArchiveConversation();
                    }
                }}
            >
                <Text
                    variant='body3-regular'
                    color='textSecondary'
                >
                    {
                        isArchived ?
                            localize("unarchive")
                            :
                            localize("archive")
                    }
                </Text>
            </div>
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            padding: `${spaces.container / 2}px ${spaces.container * .75}px`,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            borderTopColor: unReadMessageCount !== 0 ? colors.primary : colors.stroke,
            gap: spaces.inline,
            backgroundColor: unReadMessageCount !== 0 ? colors.layer2 : colors.layer3
        }}
    >
        {renderProfilePhoto()}   
        {renderContent()}   
        {renderOptions()}   
    </div>;
};

export default Conversation;
