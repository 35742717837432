import {
    useEffect,
    useState,
    useRef,
    FC
} from "react";
import useStyles, {
    toolBoxStyler 
} from "./style";
import {
    FilterType, 
    NotificationType
} from "./type";
import {
    IOCoreLocale,
    IOCoreTheme, 
    StateCard, 
    Button,
    Text
} from "isinolacak-web-cl";
import NotificationCard from "../notificationCard";
import {
    NotificationIcon,
    BellIcon
} from "../../assets/svgr";
import defaultPP from "../../assets/img/isinolacakMiniWithPadding.png";
import {
    RESTService 
} from "../../services/restAPI";

const NavigationBox: FC = ({
}) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors,
    } = IOCoreTheme.useContext();

    const [isVisible, setIsVisible] = useState(false);
    const [filterType, setFilterType] = useState<FilterType>("all"); 
    const [data, setData] = useState<Array<NotificationType>>([]);
    const [tInfo, setTInfo] = useState({
        windowHeight: 0,
        windowWidth: 0,
        w: 0,
        h: 0,
        x: 0,
        y: 0
    });

    const testRef = useRef<null | HTMLDivElement>(null);

    const {
        toolBoxContainer,
    } = toolBoxStyler({
        radiuses,
        spaces,
        colors,
        tInfo
    });

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    const onResize = () => {
        if(!(testRef && testRef.current)) {
            return;
        }
        const position = testRef.current.getBoundingClientRect();
        if(
            position.width !== tInfo.w ||
            position.height !== tInfo.h ||
            position.y !== tInfo.y ||
            position.x !== tInfo.x
        ) {
            setTInfo({
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                h: position.height,
                w: position.width,
                y: position.y,
                x: position.x,
            });
        }
    };

    const getNotifications = () => {
        RESTService.action("GetPushNotifications", {
        })
            .then(res => {
                setData(res);
            })
            .catch(err => {
                console.error("GetPushNotifications err:", err);
            });
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                borderColor: colors.primary,
                borderWidth: borders.line
            }}
        >
        </div>;
    };

    const renderHeader = () => {
        return <div
            className={classes.navigationHeader}
            style={{
                borderWidth: 0,
                borderBottomWidth: borders.line,
                borderColor: colors.stroke,
                padding: spaces.container,
                paddingBottom: spaces.container / 2
            }}
        >
            <div
                className={classes.navigationHeaderTitle}
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                <Text variant="body-semiBold">Bildirimler</Text>
                <Button
                    title="Hepsini okudum"
                    onClick={() => {}}
                    variant="ghost"
                    size="xSmall"
                />
            </div>
            {renderTabBars()}
        </div>;
    };

    const renderTabBars = () => {
        return <div
            className={classes.tabBar}
            style={{
                gap: spaces.content
            }}
        >
            <div>
                <Button
                    color={filterType === "all" ? "primary" : "body"}
                    title={localize("all")}
                    variant="ghost"
                    size="small"
                    onClick={() => {
                        setFilterType("all");
                    }}
                    style={{
                        padding: 0
                    }}
                />
                {filterType === "all" && renderLine()}
            </div>
            <div>
                <Button
                    color={filterType === "unReaded" ? "primary" : "body"}
                    title={localize("unread-items")}
                    variant="ghost"
                    size="small"
                    onClick={() => {
                        setFilterType("unReaded");
                    }}
                    style={{
                        padding: 0
                    }}
                />
            
                {filterType === "unReaded" && renderLine()}
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div
            style={{
            }}
        >
            {
                data.map((item, index) => {
                    return <NotificationCard
                        key={item._id.slice(4, 10)}
                        content={item.content}
                        title={item.title}
                        createdAt={item.createdAt}
                        isUnreaded={item.status === "isSended"}
                        profilePhotoURL={item.profilePhotoURL ? item.profilePhotoURL : defaultPP}
                    />;
                })
            }
        </div>;
    };

    const renderStateCard = () => {
        if(!(data && data.length)){
            return <div
                className={classes.stateCardContainer}
                style={{
                    padding: spaces.containerLarge
                }}
            >
                <StateCard
                    icon={() => {
                        return <BellIcon size={85} color={colors.textSecondary} />;
                    }}
                    title="Henüz bildiriminiz yok."
                    content="Burayı takip etmeyi unutma..."
                />
            </div>;
        }
    };

    const renderNavigationContainer = () => {
        return <div>
            {renderHeader()}
            {renderContent()}
            {renderStateCard()}
        </div>;
    };

    return <div
        className={classes.container}
    >
        <Button
            spreadBehaviour='free'
            variant='outline'
            color='gray80'
            size='large'
            onClick={() => {
                setIsVisible(prev => {
                    if(prev) {
                        setTInfo(c_prev => {
                            return {
                                ...c_prev,
                                x: 0,
                                y: 0
                            };
                        });
                    } else {
                        getNotifications();
                    }
                    return !prev;
                });
            }}
            icon={({
                size
            }) => {
                return <NotificationIcon
                    color={colors.textSecondary}
                    size={size}
                />;
            }}
            style={{
                whiteSpace: "nowrap"
            }}
        />
        {
            isVisible ?
                <div
                    className={classes.toolBoxContainer}
                    ref={(e) => {
                        testRef.current = e;
                        onResize();
                    }}
                    style={{
                        ...toolBoxContainer,
                    }}
                >
                    {renderNavigationContainer()}
                </div>
                :
                null
        }
    </div>;
};
export default NavigationBox;
