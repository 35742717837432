import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const RoundArrow = ({
    color = "#9AA6B8",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5-12.75c.414 0 .75.336.75.75s-.336.75-.75.75H9.5l2.22 2.22a.75.75 0 0 1-1.06 1.06l-3.5-3.5a.75.75 0 0 1 0-1.06l3.5-3.5a.75.75 0 0 1 1.06 1.06L9.5 11.25H17z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default RoundArrow;