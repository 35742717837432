import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Plus = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M27 17H17v10c0 1.1-.9 2-2 2s-2-.9-2-2V17H3c-1.1 0-2-.9-2-2s.9-2 2-2h10V3c0-1.1.9-2 2-2s2 .9 2 2v10h10c1.1 0 2 .9 2 2s-.9 2-2 2Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Plus;
