import {
    RefreshCircleIcon,
    CloseCircleIcon,
    DoubleTicIcon,
    ClockIcon,
    TicIcon
} from "../../assets/svgr";
import useStyles from "./styles";
import IMessageBubbleType, {
    PositionType
} from "./type";
import {
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";

const MessageBubble = ({
    direction = "right",
    onClickRefreshIcon,
    status = "sending",
    message,
    style,
    date
}: IMessageBubbleType) => {
    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const classes = useStyles();

    const renderMessageContentBuble = () => {
        let radiusByDirection: PositionType = {
        };
        if(direction == "right"){
            radiusByDirection.borderBottomRightRadius = 0;
        }
        else {
            radiusByDirection.borderBottomLeftRadius = 0;
        }
        let _bgColor = colors.primary;
        let _textColor: keyof IOCore.ColorsType = "textWhite";
        
        if(direction === "right") {
            _bgColor = colors.grey200;
        }

        return <div
            className={classes.contentBubbleContainer}
        >
            <div
                className={classes.contentBubble}
                style={{
                    padding: `${spaces.container / 2}px ${spaces.container * .75}px`,
                    maxWidth: status=== "error" ? "90%" : "80%",
                    alignSelf: direction === "left" ? "flex-start" : "flex-end",
                    borderRadius: radiuses.half * 1.5,
                    marginBottom: spaces.inline / 2,
                    marginTop: spaces.inline / 2,
                    backgroundColor: _bgColor,
                    ...radiusByDirection,
                    gap: spaces.content / 3
                }}
            >
                <Text
                    variant="body4-regular"
                    color={_textColor}
                >
                    {message}
                </Text>
                <div
                    className={classes.statusIconField}
                >
                    {direction === "right" ? renderMessageStatusIcon() : null}
                </div>
            </div>
            <Text
                variant="body3-regular"
                color="textGrey"
                style={{
                    alignSelf: direction === "left" ? "flex-start" : "flex-end"
                }}
            >
                {date}
            </Text>
        </div>;
    };

    const renderMessageStatusIcon = () => {
        switch(status) {
        case "sending":
            return <ClockIcon size={10} color={colors.textWhite} />;
        case "sended":
            return <TicIcon size={10} color={colors.textWhite} />;
        case "received":
            return <DoubleTicIcon size={10} color={colors.textWhite} />;
        case "readed":
            return <DoubleTicIcon size={10} color={colors.messageTicColor} />;
        case "error":
            return <CloseCircleIcon size={16} color={colors.error}/>;
        }
    };

    const renderRefreshIcon = () =>{
        if(status !== "error"){
            return;
        };
        return <Button
            onClick={()=>{
                onClickRefreshIcon();
            }}
            variant='ghost'
            icon={() => <RefreshCircleIcon
                size={20}
                color={colors.primary}
            />}
        />;
    };

    return <div
        style={{
            ...style,
            marginBottom: spaces.content,
        }}
    >
        <div
            className={classes.messageBubbleField}
            style={{
                display: status === "error"? "flex" : "",
            }}
        >
            {renderMessageContentBuble()}
            {renderRefreshIcon()}
        </div>  
    </div>;
};
export default MessageBubble;
