import {
    ChangeEvent,
    Fragment,
    useEffect,
    useState 
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale, 
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import IGlobalState from "../../context/globalState/type";
import {
    NotificationIcon, 
    SettingsIcon, 
    DocumentIcon, 
    UsersIcon,
    CallIcon, 
    HelpIcon,
    ImgDownloadIcon
} from "../../assets/svgr";
import {
    useLocation, 
    useNavigate 
} from "react-router-dom";
import IsinolacakPlaceholderImage from "../../assets/img/isinolacakMiniWithPadding.png";
import {
    RESTService 
} from "../../services/restAPI";
import {
    handleUpload 
} from "../../utils";

const WorkerProfileLeftContainer = ({
    isPersonalInfo = false
}: {
    isPersonalInfo?: boolean
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();


    const [userData, setUserData] = useState<{
        occupation?: string;
        photoURL?: string | null;
        fullName?: string;
    }>({
        occupation: "",
        photoURL: "",
        fullName: ""
    });
    const [activePage, setActivePage] = useState(location.pathname);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        const localStorageData: any = localStorage.getItem("user");
        const user: IGlobalState["user"] = JSON.parse(localStorageData);
        setUserData({
            occupation: user?.occupation?.localizedText,
            photoURL: user?.photoURL,
            fullName: user?.fullName
        });
    }, [userData?.photoURL]);

    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const handleUpdateProfilePhoto = (selectedFile?: File | null) => {
        RESTService.action("UpdateProfilePhoto", {
        })
            .then((res) => {
                if(selectedFile) {
                    handleUpload(selectedFile, res.payload.uploadToken)
                        .then(async (res) => {
                            const t = await res?.json();
                            console.error(t);
                            let userData = window.localStorage.getItem("user");
                            if(userData) {
                                userData = JSON.parse(userData);
                                //@ts-ignore
                                userData.photoURL  = t.payload.preSignedURL;
                                window.localStorage.setItem("user", JSON.stringify(userData));
                                window.location.reload();
                            }
                            // setIsVisibleDialog(true);
                        }).catch((err) => {
                            console.error("UpdateProfilePhoto: ERR", err);
                        });
                }
            })
            .catch((err) => {
                console.error("ERR", err);
            });
    };
    
    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            handleUpdateProfilePhoto(event.target.files[0]);
        }
    };

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleNavigate = (path: string) => {
        setActivePage(path);
        navigate(path);
    };
        
    return <div
        className={classes.leftContainer}
        style={{
            padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
            backgroundColor: colors.layer1,
            borderRadius: radiuses.half,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            top: spaces.content * 2,
        }}
    >
        <label
            className={classes.profileImageContainer}
            style={{
                borderWidth: borders.line * 2,
                borderColor: colors.primary
            }}
        >
            <img
                className={classes.img}
                src={userData.photoURL ?? IsinolacakPlaceholderImage}
                alt="Profile"
            />
            {isPersonalInfo && <Fragment>
                <div
                    className={classes.icon}
                    style={{
                        paddingBottom: spaces.container / 4,
                        paddingTop: spaces.container / 4
                    }}
                >
                    <ImgDownloadIcon
                        size={35}
                    />
                </div>
                <input
                    onChange={onFileChange}
                    type="file"
                    style={{
                        display: "none"
                    }}
                /> 
            </Fragment>}
        </label>
        <div
            className={classes.profileTitleContainer}
            style={{
                marginTop: spaces.content
            }}
        >
            <Text
                className={classes.profileTitle}
                variant="header5-medium"
                color="textDark"
            >
                {userData?.fullName}
            </Text>
        </div>
        <div
            className={classes.occupationContainer}
            style={{
                marginTop: spaces.inline / 2,
                marginBottom: spaces.content,
            }}>
            <Text
                variant="body4-regular"
                color="primary"
            >
                {userData?.occupation}
            </Text>
        </div>
        <div
            className={classes.buttonContainer}
            style={{
                gap: spaces.content
            }}
        >
            <div
                className={classes.button}
                style={{
                    gap: spaces.content,
                }}
                onClick={() => handleNavigate("/app/profile")}
            >
                <UsersIcon
                    color={activePage === "/app/profile" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/profile" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("personal-information")}
                </Text>
            </div>

            <div
                className={classes.button}
                style={{
                    gap: spaces.content
                }}
                onClick={() => {
                    handleNavigate("/app/cv");
                }}
            >
                <DocumentIcon
                    color={activePage === "/app/cv" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/cv" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("cv")}
                </Text>
            </div>

            <div
                className={classes.button}
                style={{
                    gap: spaces.content
                }}
                onClick={() => {
                    handleNavigate("/app/communicationPreferences");
                }}
            >
                <CallIcon
                    color={activePage === "/app/communicationPreferences" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/communicationPreferences" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("communication-preferences")}
                </Text>
            </div>

            <div
                className={classes.button}
                style={{
                    gap: spaces.content
                }}
                onClick={() => {
                    handleNavigate("/app/myAlarms");
                }}
            >
                <NotificationIcon
                    size={18}
                    color={activePage === "/app/myAlarms" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/myAlarms" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("my-advert-alarm")}
                </Text>
            </div>

            <div
                className={classes.button}
                style={{
                    gap: spaces.content
                }}
                onClick={() => {
                    handleNavigate("/app/settings");
                }}
            >
                <SettingsIcon
                    color={activePage === "/app/settings" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/settings" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("settings")}
                </Text>
            </div>

            <div
                className={classes.button}
                style={{
                    gap: spaces.content
                }}
                onClick={() => {
                    handleNavigate("/app/contactUs");
                }}
            >
                <HelpIcon
                    color={activePage === "/app/contactUs" ? colors.primary : colors.textDark}
                />
                <Text
                    color={activePage === "/app/contactUs" ? "primary" : "textDark"}
                    className={classes.buttonText}
                    variant="body2-regular"
                >
                    {localize("contact-us")}
                </Text>
            </div>
        </div>
    </div>;
};

export default WorkerProfileLeftContainer; 