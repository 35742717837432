import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    renderEmptyContainer: {
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        minHeight: 100,
        display:"flex",
        flexGrow: 1
    },
});

export default useStyles;