import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FreeInfoIcon = ({
    color = "#45BBD4",
    size = 19,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 19 / size;
    return   <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <g fill={color} clipPath="url(#a)">
            <path
                d="M9.5 4.947a.594.594 0 0 0-.594.594v4.75a.594.594 0 0 0 1.188 0v-4.75a.594.594 0 0 0-.594-.594ZM9.5 13.458a.792.792 0 1 1 0-1.584.792.792 0 0 1 0 1.584Z"
                scale={pathScale / 1}
            />
            <path
                d="M18.01 9.5a8.51 8.51 0 1 1-17.02 0 8.51 8.51 0 0 1 17.02 0ZM9.5 16.821a7.323 7.323 0 1 0 0-14.646 7.323 7.323 0 0 0 0 14.646Z"
                scale={pathScale / 1}
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M19 19H0V0h19z" />
            </clipPath>
        </defs>
    </svg>;
};
export default FreeInfoIcon;