import actions from "../actions";

export let SERVER_ADDRESS = process.env.REACT_APP_CHAT_SERVICE_SERVER_ADDRESS ? process.env.REACT_APP_CHAT_SERVICE_SERVER_ADDRESS : "wss://chat.isinolacak.com";
export let REST_SERVER_ADDRESS = process.env.REACT_APP_SERVER_ADDRESS ? process.env.REACT_APP_SERVER_ADDRESS : "https://gw.isinolacak.com";

if(process.env.REACT_APP_ENVIRONMENT) {
    if(process.env.REACT_APP_ENVIRONMENT === "development") {
        REST_SERVER_ADDRESS = "https://gw.isinolacak.com";
        SERVER_ADDRESS = "https://chat.isinolacak.com";
    }

    if(process.env.REACT_APP_ENVIRONMENT === "custom") {
        REST_SERVER_ADDRESS = "http://192.168.0.3/";
        SERVER_ADDRESS = "ws://192.168.0.3:4556";
    }
}
export const SERVICE_EVENT_TYPES = {
    "MESSAGE_RECEIVED": "MESSAGE_RECEIVED"
};

export const SERVICE_CONFIGS: Record<keyof typeof actions, {
    isWorkedWithQueue: boolean;
}> = {
    SendMessage: {
        isWorkedWithQueue: true
    }
};
