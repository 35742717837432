import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    inputContainer: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
    },
    areaCodesContainer: {
        justifyContent:"space-between",
        flexDirection:"row",
        display: "flex"
    },
    container: {
        alignItems: "center",
        display: "flex"
    },
    textInput:{
        display: "block",
        width: "100%"
    },
    areaCodeSelectBox: {
        width: "30%"
    },
    textInputPhoneNumber: {
        width: "70%" 
    }
});
export default useStyles;