import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    contentContainer: {
        flexDirection: "row",
        flexFlow: "wrap",
        display: "flex",
        flexWrap:"wrap"
    },
    headerContainer: {
        flexDirection: "row",
        display: "flex"
    },
    paqContainer: {
        flexDirection: "column",
        display: "flex",
        flex: "1 1 %50",
        width: "50%"
    },
    checkIcon: {
        alignItems: "center",
        display: "flex"
    },
    paqType: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    paqResponse: {
        borderStyle: "solid",
        width: "20%"
    }
});
export default useStyles;