import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Info =  ({
    color = "#D13333",
    size = 16,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 16 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M14.666 8.5a6.667 6.667 0 1 1-13.333 0 6.667 6.667 0 0 1 13.333 0Zm-5.833 3.333a.833.833 0 1 1-1.667 0 .833.833 0 0 1 1.667 0ZM8 4.333a.833.833 0 0 0-.834.834V8.5a.833.833 0 0 0 1.667 0V5.167A.833.833 0 0 0 8 4.333Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Info;
