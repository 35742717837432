import {
    uuid
} from "../../../utils";
import {
    FilterSelectType
} from "./views/responsiveFilter/types";

/*export const SORT_FILTER: Array<{
    localizedText: keyof IOCore.TranslationType;
    value: SearchSortType
    id: string;
}> = [
    {
        id: uuid(),
        localizedText: "recomended",
        value: "recommended"
    },
    {
        id: uuid(),
        localizedText: "new-to-old",
        value: "newToOld"
    },
    {
        id: uuid(),
        localizedText: "old-to-new",
        value: "oldToNew"
    }
];*/

export const FILTERS: Array<{
    localizedText: keyof IOCore.TranslationType;
    value: FilterSelectType;
    id: string;
}> = [
    {
        id: uuid(),
        localizedText: "location-city",
        value: "citySelect"
    },
    {
        id: uuid(),
        localizedText: "education-level",
        value: "educationLevelSelect"
    },
    {
        id: uuid(),
        localizedText: "occupation",
        value: "occupationSelect"
    },
    {
        id: uuid(),
        localizedText: "capabilities",
        value: "capabilitiesSelect"
    },
    {
        localizedText: "foreign-language",
        value: "foreignLanguageSelect",
        id: uuid()
    },
    {
        id: uuid(),
        localizedText: "disable-candidate",
        value: "disabledCandidatesSelect"
    },
];


export const NO_RESULT: Array<{
    localizeText: keyof IOCore.TranslationType;
    id: string;
}> = [
    {
        id: uuid(),
        localizeText: "no-result-1"
    },
    {
        id: uuid(),
        localizeText: "no-result-2"
    },
    {
        id: uuid(),
        localizeText: "no-result-3"
    },
    {
        id: uuid(),
        localizeText: "no-result-4"
    },
    {
        id: uuid(),
        localizeText: "no-result-5"
    }
];

export const DISABLED_CANDIDATES_FILTERS: Array<{
    id: string;
    value:"false" 
        | "true" 
        | "all"
    localizedText: keyof IOCore.TranslationType;
}> = [
    {
        id: uuid(),
        value: "all",
        localizedText: "all",
    },
    {
        id: uuid(),
        value: "true",
        localizedText: "show-only-disabled-candidates",
    },
    {
        id: uuid(),
        value: "false",
        localizedText: "dont-show-disabled-candidates"
    }
];
