import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    profilePhotoContainer: {
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
        height: 40,
        width: 40,
    },
    contentContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
        display: "flex",
        width: "100%"
    },
    timeContainer: {
        justifyContent: "center",
        flexDirection: "column",
        whiteSpace: "nowrap",
        alignItems: "flex-end",
        display: "flex",
    },
    badge: {
        borderRadius: 10,
        height: 17,
        width: 17
    },
    profilePhotoContent:{
        objectFit: "cover",
        minHeight: "100%",
        minWidth: "100%",
        borderRadius: 40,
        height: 50,
        width: 50
    }
});

export default useStyles;