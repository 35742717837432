import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        "@media (min-width: 600px)": {
            maxWidth: "100%",
            display: "none",
            width: "100%"
        }
    },
    advertDetailsContent: {
        flexDirection: "column",
        display: "flex"
    },
    advertDetailsContainer: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    buttonContent: {
        alignItems: "flex-start",
        flexDirection: "row",
        display: "flex",
    }
});
export default useStyles;