import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const CloseCircle = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M29 15c0 7.732-6.268 14-14 14S1 22.732 1 15 7.268 1 15 1s14 6.268 14 14Zm-18.242-4.242a1.05 1.05 0 0 1 1.484 0L15 13.514l2.758-2.758a1.05 1.05 0 0 1 1.484 1.486L16.485 15l2.757 2.758a1.05 1.05 0 0 1-1.485 1.484L15 16.485l-2.758 2.757a1.05 1.05 0 1 1-1.485-1.485L13.515 15l-2.758-2.758a1.05 1.05 0 0 1 0-1.485Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default CloseCircle;
