import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const WidgetAdd = ({
    color = "#45BBD4",
    size = 52,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 52 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M37.688 5.844c.88 0 1.593.713 1.593 1.593v4.782h4.782a1.594 1.594 0 1 1 0 3.187H39.28v4.781a1.594 1.594 0 1 1-3.187 0v-4.78h-4.782a1.594 1.594 0 1 1 0-3.188h4.782V7.438c0-.88.713-1.594 1.593-1.594Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
        <path
            d="M4.75 13.813c0-4.508 0-6.762 1.4-8.163 1.4-1.4 3.655-1.4 8.162-1.4 4.508 0 6.762 0 8.163 1.4 1.4 1.4 1.4 3.655 1.4 8.162 0 4.508 0 6.762-1.4 8.163-1.4 1.4-3.655 1.4-8.163 1.4-4.507 0-6.761 0-8.162-1.4-1.4-1.4-1.4-3.655-1.4-8.163ZM28.125 37.188c0-4.508 0-6.762 1.4-8.163 1.4-1.4 3.655-1.4 8.163-1.4 4.507 0 6.761 0 8.162 1.4 1.4 1.4 1.4 3.655 1.4 8.163 0 4.507 0 6.761-1.4 8.162-1.4 1.4-3.655 1.4-8.163 1.4-4.507 0-6.761 0-8.162-1.4-1.4-1.4-1.4-3.655-1.4-8.163ZM4.75 37.188c0-4.508 0-6.762 1.4-8.163 1.4-1.4 3.655-1.4 8.162-1.4 4.508 0 6.762 0 8.163 1.4 1.4 1.4 1.4 3.655 1.4 8.163 0 4.507 0 6.761-1.4 8.162-1.4 1.4-3.655 1.4-8.163 1.4-4.507 0-6.761 0-8.162-1.4-1.4-1.4-1.4-3.655-1.4-8.163Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default WidgetAdd;