import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        maxWidth: "max-content", 
        display: "flex"
    },
});

export default useStyles;