import {
    useState 
} from "react";
import {
    RESTService
} from "../../../services/restAPI";
import validator from "validator";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useNavigate 
} from "react-router-dom";
import {
    InstagramIcon,
    LinkedinIcon,
    FacebookIcon,
    YoutubeIcon,
    GoogleIcon,
    AppleIcon,
    XIcon,
    Logo
} from "../../../assets/svgr";
import RegisterLoginImage from "../../../assets/img/registerLoginImage.svg";
import {
    useGlobalState 
} from "../../../context/globalState";
import {
    IsErrorType 
} from "./types";
import {
    ISIN_OLACAK_INSTAGRAM, 
    ISIN_OLACAK_FACEBOOK,
    ISIN_OLACAK_TWITTER, 
    ISIN_OLACAK_YOUTUBE
} from "../../../constants";
import {
    md5 
} from "js-md5";
import {
    ChatService 
} from "../../../services/chat";
import {
    getErrorText 
} from "../../../utils";

const Login = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState<IsErrorType>({
        passwordError: null,
        customError: null,
        mailError: null
    });

    const handleLogin = async () => {
        if (!validator.isEmail(email)) {
            setIsError({
                mailError: localize("email-format-error"),
                passwordError: null,
                customError: null
            });
            setLoading(false);
            return;
        }

        if (password.length < 8 || password.length > 16) {
            setIsError({
                passwordError: localize("password-length-limit"),
                customError: null,
                mailError: null
            });
            setLoading(false);
            return;
        }

        RESTService.action("Login", {
            password: md5(password),
            mail: email
        }).then((res) => {
            if (res.isSMSOTPRequired) {
                return navigate("/auth/SmsOTP", {
                    state: {
                        selection: "",
                        userMail: email
                    }
                });
            } else {
                const profilePayload = res.profilePayload;
                const companyPayload = res.companyPayload;
    
                window.localStorage.setItem("userLanguage",profilePayload.language);
                window.localStorage.setItem("userType", profilePayload.type);
    
                if (profilePayload.type === "worker") {
                    const userData = {
                        phoneAreaCode: {
                            countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                            flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                            country: profilePayload.phoneAreaCode.country,
                            value: profilePayload.phoneAreaCode.value,
                            _id: profilePayload.phoneAreaCode._id
                        },
                        occupation: {
                            localizedText: profilePayload.occupation.localizedText,
                            _id: profilePayload.occupation._id
                        },
                        isMailVerified: profilePayload.isMailVerified,
                        profilePhotoURL: profilePayload.profilePhotoURL,
                        firstName: profilePayload.firstName,
                        fullName: profilePayload.fullName,
                        lastName: profilePayload.lastName,
                        language: profilePayload.language,
                        userID: profilePayload.userID,
                        phone: profilePayload.phone,
                        type: profilePayload.type,
                        mail: profilePayload.mail
                    };
    
                    setGlobalState({
                        user: userData
                    });
    
                    window.localStorage.setItem("user", JSON.stringify(userData));
                }
                if (profilePayload.type === "employer" && companyPayload) {
                    const userData = {
                        isMailVerified: profilePayload.isMailVerified,
                        profilePhotoURL: profilePayload.profilePhotoURL,
                        firstName: profilePayload.firstName,
                        fullName: profilePayload.fullName,
                        language: profilePayload.language,
                        lastName: profilePayload.lastName,
                        userID: profilePayload.userID,
                        phone: profilePayload.phone,
                        type: profilePayload.type,
                        mail: profilePayload.mail,
                        phoneAreaCode: {
                            countryTranslationKey: profilePayload.phoneAreaCode.countryTranslationKey,
                            flagUnicode: profilePayload.phoneAreaCode.flagUnicode,
                            country: profilePayload.phoneAreaCode.country,
                            value: profilePayload.phoneAreaCode.value,
                            _id: profilePayload.phoneAreaCode._id
                        }
                    };
                    const companyData = {
                        phoneAreaCode: {
                            countryTranslationKey: companyPayload.phoneAreaCode.countryTranslationKey,
                            flagUnicode: companyPayload.phoneAreaCode.flagUnicode,
                            country: companyPayload.phoneAreaCode.country,
                            value: companyPayload.phoneAreaCode.value,
                            _id: companyPayload.phoneAreaCode._id
                        },
                        district: {
                            _id: companyPayload.district._id,
                            localizedText: companyPayload.district.localizedText
                        },
                        occupation: {
                            _id: companyPayload.occupation._id,
                            localizedText: companyPayload.occupation.localizedText
                        },
                        sector: {
                            _id: companyPayload.sector._id,
                            localizedText: companyPayload.sector.localizedText
                        },
                        city: {
                            _id: companyPayload.city._id,
                            localizedText: companyPayload.city.localizedText
                        },
                        isMailVerified: profilePayload.isMailVerified,
                        mersisNo: companyPayload.mersisNo,
                        photoURL: companyPayload.photoURL,
                        address: companyPayload.address,
                        website: companyPayload.website,
                        title: companyPayload.title,
                        phone: companyPayload.phone,
                        mail: companyPayload.mail,
                        _id: companyPayload._id
                    };
                    setGlobalState({
                        user: userData,
                        company: companyData
                    });
                    window.localStorage.setItem("company", JSON.stringify(companyData));
                    window.localStorage.setItem("user", JSON.stringify(userData));
                }
    
                ChatService.getAccessToken();
    
                setTimeout(() => {
                    if(profilePayload.type === "employer") {
                        navigate("/app/home");
                    } else {
                        navigate("/app/search");
                    }
                }, 300);
            }
        }).catch((err) => {
            console.error("CheckChangePhoneNumberCode Err:", err);
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
            setIsError({
                passwordError: null,
                customError: localize("login-error"),
                mailError: null
            });
        });
    };

    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                borderRightColor: colors.stroke,
                borderRightWidth: borders.line,
                padding: spaces.container
            }}
        >
            <div
                className={classes.leftTopContainer}
            >
                <div className={classes.visualImageContainer}>
                    <img
                        className={classes.visualImage}
                        src={RegisterLoginImage}
                        alt="sideVisualImage"
                    />
                </div>
                <div 
                    className={classes.visualImageContainerText}
                    style={{
                        marginTop: spaces.containerLarge
                    }}
                >
                    <Text 
                        variant="body2-regular"
                    >
                        {localize("with-isinolacak-you-will-reach-your-career-goals")}
                    </Text>
                </div>
            </div>
            <div
                className={classes.footerTopContainer} 
            >
                {renderFooter()}
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div className={classes.rightContainer}>
            <div
                className={classes.loginContainer}
                style={{
                    padding: spaces.container
                }}
            >
                <div
                    style={{
                        marginBottom: spaces.inline * 3,
                    }}
                >
                    <Logo size={120} />
                </div>
                {renderInputs()}
                {renderLoginButtons()}
                {/* {renderLine()} */}
                {/* {renderSocialButtons()} */}
            </div>
        </div>;
    };

    const renderInputs = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <TextInput
                infoText={isError.mailError ? isError.mailError : ""}
                isError={isError.mailError ? true : false}
                placeholder={localize("e-mail")}
                spreadBehaviour="stretch"
                id="mail-input"
                onChangeText={(value) => {
                    setEmail(value);
                }}
            />
            <TextInput
                infoText={isError.passwordError ? isError.passwordError : ""}
                isError={isError.passwordError ? true : false}
                placeholder={localize("password")}
                spreadBehaviour="stretch"
                id="password-input"
                password
                onKeyDown={(e) => {
                    if(e.key === "Enter"){
                        if(!loading) {
                            setLoading(true);
                            handleLogin();
                        }
                    }
                }}
                onChangeText={(value) => {
                    setPassword(value);
                }}
            />
            <div
                onClick={() => {
                    navigate("/auth/resetPassword");
                }}
            >
                <Text
                    variant="body2-regular"
                    color="primary"
                >
                    {localize("forgot-password")}
                </Text>
            </div>
            {isError.customError ? (
                <Text
                    variant="body2-regular"
                    color="accent"
                >
                    {isError.customError}
                </Text>
            ) : null}
        </div>;
    };

    const renderLoginButtons = () => {
        return <div
            className={classes.buttonContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                spreadBehaviour="stretch"
                title={localize("login")}
                color="secondary"
                size="large"
                loading={loading}
                onClick={() => {
                    setLoading(true);
                    handleLogin();
                }}
            />
            <Button
                title={localize("register")}
                spreadBehaviour="stretch"
                variant="outline"
                color="secondary"
                size="large"
                onClick={() => {
                    navigate("/auth/register");
                }}
            />
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.lineContainer}
            style={{
                marginBottom: spaces.inline * 1.5
            }}
        >
            <div
                className={classes.line}
                style={{
                    backgroundColor: colors.stroke
                }}
            />
            <Text
                color="textSecondary"
                variant="body2-regular"
                style={{
                    margin: `0px ${spaces.item}px`
                }}
            >
                {localize("or")}
            </Text>
            <div
                className={classes.line}
                style={{
                    backgroundColor: colors.stroke
                }}
            />
        </div>;
    };

    const renderSocialButtons = () => {
        return <div
            className={classes.socialButtonContainer}
            style={{
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                color="stroke"
                variant="outline"
                icon={() => {
                    return <GoogleIcon />;
                }}
                size="large"
                onClick={() => {}}
            />
            <Button
                color="appleBg"
                icon={() => {
                    return <AppleIcon />;
                }}
                size="large"
                onClick={() => {}}
            />
            <Button
                color="linkedinBg"
                icon={() => {
                    return <LinkedinIcon />;
                }}
                size="large"
                onClick={() => {}}
            />
        </div>;
    };

    const renderFooter = () => {
        return <div
            className={classes.footerContainer}
            style={{
                gap: spaces.contentLarge
            }}
        >
            <div 
                onClick={() => {
                    navigate("/aboutUs");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("about-us")}
                </Text>
            </div>
            <div 
                onClick={() => {
                    navigate("/contactUs");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("help")}
                </Text>
            </div>
            <div 
                onClick={() => {
                    navigate("/agreement/user-policy");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("data-policy")}
                </Text>
            </div>
            <div
                className={classes.footerButtonsContainer}
                style={{
                    gap: spaces.item
                }}
            >
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                />
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_INSTAGRAM);
                    }}
                >
                    <InstagramIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_YOUTUBE);
                    }}
                >
                    <YoutubeIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_FACEBOOK);
                    }}
                >
                    <FacebookIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_TWITTER);
                    }}
                >
                    <XIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                />
            </div>
            <Text 
                variant="body4-regular" 
                color="secondary"
            >
				isinolacak.com
            </Text>
        </div>;
    };

    return  <div
        className={classes.container}
    >
        {renderLeftContainer()}
        {renderRightContainer()}
    </div>;
};
export default Login;
