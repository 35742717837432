import React, {
    Fragment,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    StateCard,
    PageModal,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    RoundArrowIcon,
    FreeInfoIcon,
    QuestionIcom,
    TrashIcon,
    RadarIcon,
    MapIcon
} from "../../../../../../assets/svgr";
import {
    GetMyProfileResponseTypes
} from "../../../../../../services/restAPI/actions/getMyProfile/types";
import {
    PersonalInfoTypes 
} from "../../types";
import {
    SettingsTypes
} from "./types";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    WorkerProfileLeftContainer,
    RowCard,
    Footer
} from "../../../../../../components";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import ChangeLanguage from "./views/changeLanguage";
import AskedQuestion from "./views/askedQuestions";
import {
    getErrorText 
} from "../../../../../../utils";
import PrivacyPolicy from "./views/privacyPolicy";

const WorkerSettings = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [getMyProfile ,setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [views, setViews] = useState<SettingsTypes>("open");
    const [isVisible, setIsVisible] = useState(false);

    const localStorageData: string | null = window.localStorage.getItem("user");

    let user: PersonalInfoTypes;
    if (localStorageData) {
        user = JSON.parse(localStorageData);
    } else {
        user = {
            areaCode: {
                countryTranslationKey: "",
                flagUnicode: "",
                country: "",
                value: "",
                _id: "",
            },
            occupation: {
                localizedText: "",
                _id: ""
            },
            phoneNumber: "",
            firstName: "",
            lastName: "",
            fullName: "",
            photoURL: "",
            about: "",
            mail: "",
        };
    }

    useEffect(() => {
        handleGetMyProfile();
    }, []);

    const handleGetMyProfile = () => {
        setLoadingProfile(true);
        RESTService.action("GetMyProfile",{
        })
            .then((res) => {
                setGetMyProfile(res);
                setLoadingProfile(false);
            })
            .catch((err) => {
                console.error("GetMyProfile Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleDeleteMyAccount = () => {
        RESTService.action("DeleteMyAccount", {
        })
            .then((res) => {
                setTimeout(() => {
                    navigate("/auth/login");
                }, 300);
            })
            .catch((err) => {
                console.error("DeleteMyAccount Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderLine = () => {
        return <div
            className={classes.renderLine}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke
            }}
        />;
    };

    const renderRowCard = () => {
        return <div>
            <RowCard
                className={classes.rowCardClick}
                onClick={() => {
                    setViews("changeLanguage");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <MapIcon />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("change-language")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                className={classes.rowCardClick}
                onClick={() => {
                    setViews("asked-questions");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <QuestionIcom />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("asked-questions")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                className={classes.rowCardClick}
                onClick={() => {
                    setViews("privacyPolicy");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <FreeInfoIcon />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("privacy-policy")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                className={classes.rowCardClick}
                onClick={() => {
                    setIsVisible(true);
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <TrashIcon
                            color={colors.error}
                            size={19}
                        />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="error"
                    className={classes.rowStyle}
                >
                    {localize("delete-the-account")}
                </Text>}
                title=""
            />
            {renderLine()}
        </div>;
    };

    const renderContent = () => {
        switch (views) {
        case "changeLanguage":
            return <ChangeLanguage />;
        case "asked-questions":
            return <AskedQuestion />;
        case "privacyPolicy":
            return <PrivacyPolicy />;
        case "open":
        default:
            return renderRowCard();
        }
    };

    const renderPageModal = () => {
        return <PageModal
            onOverlayPress={() => setIsVisible(false)}
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.content
                    }}
                >
                    <StateCard
                        content={localize("you-are-about-to-delete-your-account-desc")}
                        title={localize("you-are-about-to-delete-your-account")}
                        icon={() =>
                            <TrashIcon
                                color={colors.error}
                                size={68}
                            />
                        }
                    />
                    <div
                        className={classes.pageModalContent}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <Button
                            onClick={() => {setIsVisible(false);}}
                            title={localize("give-up")}
                            spreadBehaviour='stretch'
                            variant='outline'
                        />
                        <Button
                            onClick={() => {handleDeleteMyAccount();}}
                            title={localize("delete")}
                            spreadBehaviour='stretch'
                            color="error"
                        />
                    </div>
                </div>
            }
            isVisible={isVisible}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `50px ${spaces.containerXLarge}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div>   
                <div
                    className={classes.content}
                    style={{
                        marginBottom: spaces.content * 2
                    }}
                >
                    {(
                        views === "asked-questions" ||
                        views === "changeLanguage" ||
                        views === "privacyPolicy"
                    ) && (
                        <div
                            className={classes.iconStyles}
                            style={{
                                marginRight: spaces.content
                            }}
                            onClick={() => {
                                setViews("open");
                            }}
                        >
                            <RoundArrowIcon />
                        </div>
                    )}
                    <Text
                        variant="header5-medium"
                        color="textDark"
                    >
                        {localize("settings")}
                    </Text>
                </div>
                <div>
                    {renderContent()}
                </div>
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if(loadingProfile) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.mainContainer}
            style={{
                padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
                backgroundColor: colors.layer3,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("person-profile")}
            </Text>
            <div
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                <WorkerProfileLeftContainer/>
                {renderContentContainer()}
                {renderRightContainer()}
            </div>
            {renderPageModal()}
        </div>
        <Footer/>
    </Fragment>;
};

export default WorkerSettings;