import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    topContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%"
    },
    mainContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        marginRight: "auto",
        alignItems: "center",
        marginLeft: "auto",
        maxWidth: "1440px",
        display: "flex",
        width: "100%"
    },
    headerContainer: {
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 1100px)": {
            background: "linear-gradient(to left, #EAFBFF 75%, white 25%)",
            boxSizing: "border-box",
        }
    },
    headerRightContainer: {
        "@media screen and (max-width: 1100px)": {
            display: "none"
        }
    },
    headerLeftContainer: {
        justifyContent: "space-around",
        flexDirection: "column",
        alignItems: "start",
        display: "flex",
        width: "50%",
        "@media screen and (max-width: 1100px)": {
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "start",
            display: "flex",
            width: "100%"
        }
    },
    headerTextInputContainer: {
        display: "flex",
    },
    headerTextInputButton: {
        flexBasis: "15%",
        "@media screen and (max-width: 1100px)": {
            flexBasis: "30%"
        },
    }, 
    headerRegisterContainer: {
        textAlign: "start"
    },
    checkBoxContainer: {
        flexDirection: "column",
        display: "flex"
    },
    checkBoxElement: {
        justifyContent: "start",
        alignItems: "center",
        display: "flex"
    },
    linkElement: {
        textDecoration: "underline",
        textUnderlineOffset: "10%",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8
        }
    },
    lineHeightStyle: {
        lineHeight: 1.3,
        "@media screen and (max-width: 1100px)": {
            lineHeight: 1.5
        }
    },
    discoverMainContainer: {
        boxSizing: "border-box",
        width: "100%"
    },
    discoverContainer: {
        background: "linear-gradient(#45D4D4, #4990F9)",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    discoverRightContainer: {
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        overflowY: "auto",
        display: "flex"
    },
    discoverRightTextContainer: {
        flexDirection: "column",
        display: "flex",
        width: "75%"
    },
    discoverLogoImageStyle: {
        alignSelf: "start"
    },
    alternativeDiscoverContainer: {
        background: "linear-gradient(270deg, #A1F0FB 0%, #FFFFFF 100%)",
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        width: "100%"
    },
    alternativeDiscoverTextContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex"
    },
    alternativeDiscoverImage: {
        width: 200,
        "@media screen and (max-width: 650px)": {
            width: 150
        }
    },
    popularAdvertsContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    popularAdvertsSubContainer: {
        justifyContent: "start",
        overflow: "auto",
        display: "flex",
        "@media screen and (max-width: 1100px)": {
            justifyContent: "start"
        },
        "@media screen and (max-width: 650px)": {
            justifyContent: "center",
            overflow: "hidden"
        }
    },
    featuredAdvertsContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        width: "100%"
    },
    featuredAdvertsSubContainer: {
        justifyContent: "start",
        boxSizing: "border-box",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (max-width: 1100px)": {
            justifyContent: "center",
            flexGrows: 1
        },
    },
    popularAdvertsTitle: {
        marginRight: "auto",
        marginLeft: "auto"
    },
    chipContainer: {
        WebkitOverflowScrolling: "touch",
        justifyContent: "start",
        alignItems: "center",
        overflow: "auto",
        display: "flex",
        "&::-webkit-scrollbar": {
            width: "10px",
            height: "10px"
        },
        "&::-webkit-scrollbar-button": {
            width: "3px",
            height: "3px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#45BBD4",
            border: "0px none #ffffff",
            borderRadius: "100px",
            "&:hover": {
                background: "#1f1f1f",
            },
            "&:active": {
                background: "#45BBD4",
            },
        },
        "&::-webkit-scrollbar-track": {
            background: "#E2E2E2",
            border: "0px none #ffffff",
            borderRadius: "100px",
            "&:hover": {
                background: "#E2E2E2",
            },
            "&:active": {
                background: "#E2E2E2",
            },
        },
        "&::-webkit-scrollbar-corner": {
            background: "transparent"
        }
    },
    popularPositionsContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    popularPositionsTitle: {
        textAlign: "start"
    },
    popularPositionsGrid: {
        boxSizing: "border-box",
        justifyContent: "start",
        alignItems: "start",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (max-width: 1100px)": {
            justifyContent: "center",
            alignItems: "center",
        },
    },
    popularPositionsItem: {
        transition: "color .1s ease-out",
        boxSizing: "border-box",
        textAlign: "center",
        flexBasis: "12.5%",
        flexGrow: 0,
        "&:hover": {
            textDecoration: "underline",
            color: "black !important",
            cursor: "pointer"
        },
        "@media screen and (max-width: 1100px)": {
            flexBasis: "20%"
        },
        "@media screen and (max-width: 650px)": {
            flexBasis: "40%"
        }
    },
    link: {
        textDecoration: "none",
        display: "block",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    carrerGuideContainer: {
        boxSizing: "border-box",
        textAlign: "center",
        maxWidth: "100%"
    },
    carrerGuideCards: {
        justifyContent: "center",
        flexWrap: "wrap",
        display: "flex"
    },
    allListingsText: {
        marginLeft: "auto"
    }
});

export default useStyles;