import {
    useEffect 
} from "react";
import AppRoute from "../../navigation/appRoute";
import {
    useNavigate 
} from "react-router-dom";

const App = () => {
    const navigate = useNavigate();
    useEffect(() => {
        let user = window.localStorage.getItem("user");
        if(!user){
            navigate("/auth");
        }
    }, []);

    return <AppRoute />;
};
export default App;
 