import {
    useEffect,
    Fragment,
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    Switcher,
    Text
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../../../services/restAPI";
import {
    CitiesResponseObjectType
} from "../../../../../../../services/restAPI/actions/getCities/types";
import {
    GetDistrictsObjectType
} from "../../../../../../../services/restAPI/actions/getDistricts/types";
import IRegisterPersonalInfoProps from "./types";
import {
    FilledTriangleBottomIcon,
    InfoIcon
} from "../../../../../../../assets/svgr";
import {
    getBrowserLanguageFull 
} from "../../../../../../../utils";

const PersonalInfo = ({
    setRegisterInfo,
    registerInfo,
    setErrors,
    errors
}: IRegisterPersonalInfoProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [districts, setDistricts] = useState<Array<GetDistrictsObjectType>>([]);
    const [cities, setCities] = useState<Array<CitiesResponseObjectType>>([]);

    useEffect(() => {
        getDistricts();
    }, [registerInfo.city._id]);

    useEffect(() => {
        getCities();
    }, []);

    const getCities = () => {
        RESTService.action("GetCities", {
        })
            .then((res) => {
                setCities(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getDistricts = () => {
        if(registerInfo.city._id) {
            RESTService.action("GetDistricts", {
                cityID: registerInfo.city._id
            })
                .then((res) => {
                    setDistricts(res.payload);
                })
                .catch((err) => {
                    console.error("GetDistricts Err:", err);
                });
        }
    };

    const renderInputs = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.content,
                gap: spaces.content / 2
            }}
        >
            <Text
                variant='body2-semiBold'
                style={{
                    marginBottom: spaces.content
                }}
            >
                {localize("registration-information")}
            </Text>
            <TextInput
                infoText={errors.includes("register-name-length-error") ? localize("register-name-length-error") : undefined}
                isError={errors.includes("register-name-length-error")}
                initialValue={registerInfo.name}
                spreadBehaviour="stretch"
                placeholder={localize("name")}
                id="name-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        name: value
                    });
                }}
            />
            <TextInput
                infoText={errors.includes("register-last-name-length-error") ? localize("register-last-name-length-error") : undefined}
                isError={errors.includes("register-last-name-length-error")}
                initialValue={registerInfo.lastName}
                spreadBehaviour="stretch"
                placeholder={localize("surname")}
                id="lastname-input"
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-last-name-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        lastName: value
                    });
                }}
            />
            <div
                className={classes.selectboxContainer}
            >
                <SelectBox
                    infoText={errors.includes("register-company-city-error") ? localize("register-company-city-error") : undefined}
                    isError={errors.includes("register-company-city-error")}
                    titleExtractor={(item: CitiesResponseObjectType) => item.localizedText}
                    keyExtractor={(e) => e.localizedText}
                    spreadBehaviour='stretch'
                    isNeedConfirm={true}
                    disabled={false}
                    inputTitle={localize("company-city")}
                    title={localize("company-city")}
                    //@ts-ignore // TODO: will be fixed at the web cl
                    initialSelectedItems={registerInfo.city._id.length ? [registerInfo.city] : []}
                    onOverlayPress={() => {
                        if(!(cities && cities.length)){
                            getCities();
                        }
                    }}
                    data={cities.map((item) => {
                        return {
                            ...item,
                            key: item._id
                        };
                    })}
                    customIcon={() => {
                        return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                    }}
                    onOk={({
                        selectedItems,
                        closeSheet,
                        onSuccess
                    }) => {
                        if(selectedItems[0]) {
                            setRegisterInfo((prev) => {
                            //@ts-ignore
                                if(prev.city._id !== selectedItems[0]._id) {
                                    setDistricts([]);
                                    return {
                                        ...prev,
                                        district: {
                                        //@ts-ignore //TODO: Will be fix
                                            localizedText: "",
                                            //@ts-ignore
                                            key: "",
                                            //@ts-ignore
                                            _id: ""
                                        },
                                        city: {
                                        //@ts-ignore //TODO: Will be fix
                                            localizedText: selectedItems[0].localizedText,
                                            //@ts-ignore
                                            key: selectedItems[0].key,
                                            //@ts-ignore
                                            _id: selectedItems[0]._id
                                        }
                                    };
                                }
                                return {
                                    ...prev,
                                    city: {
                                    //@ts-ignore //TODO: Will be fix
                                        localizedText: selectedItems[0].localizedText,
                                        //@ts-ignore
                                        key: selectedItems[0].key,
                                        //@ts-ignore
                                        _id: selectedItems[0]._id
                                    }
                                };
                            });
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "register-company-city-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }

                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
            <div
                className={classes.selectboxContainer}
            >
                <SelectBox
                    infoText={errors.includes("register-company-district-error") ? localize("register-company-district-error") : undefined}
                    titleExtractor={(item: GetDistrictsObjectType) => item.localizedText}
                    isError={errors.includes("register-company-district-error")}
                    keyExtractor={(e) => e.key}
                    spreadBehaviour='stretch'
                    isNeedConfirm={true}
                    disabled={!registerInfo.city._id}
                    //@ts-ignore // TODO: will be fixed at the web cl
                    initialSelectedItems={(registerInfo.district._id.length > 0 && registerInfo.city._id.length > 0) ? [registerInfo.district] : []}
                    inputTitle={localize("company-district")}
                    title={localize("company-district")}
                    onOverlayPress={() => {
                        if(!(districts && districts.length)){
                            getDistricts();
                        }
                    }}
                    data={districts.map((item) => {
                        return {
                            ...item,
                            key: item._id
                        };
                    })}
                    customIcon={() => {
                        return <FilledTriangleBottomIcon 
                            color={colors.primary} 
                            size={15} 
                        />;
                    }}
                    onOk={({
                        selectedItems,
                        closeSheet,
                        onSuccess
                    }) => {
                        if(selectedItems[0]) {
                            setRegisterInfo({
                                ...registerInfo,
                                district: {
                                //@ts-ignore //TODO: Will be fix
                                    localizedText: selectedItems[0].localizedText,
                                    //@ts-ignore
                                    key: selectedItems[0].key,
                                    //@ts-ignore
                                    _id: selectedItems[0]._id,
                                }
                            });
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "register-company-district-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }
                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
            <TextInput
                infoText={errors.includes("register-password-error") ? localize("register-password-error") : undefined}
                isError={errors.includes("register-password-error")}
                initialValue={registerInfo.password}
                spreadBehaviour="stretch"
                placeholder={localize("password")}
                id="password-input"
                password={true}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-password-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        password: value
                    });
                }}
            /> 
            {renderPasswordRules()}
            <TextInput
                infoText={errors.includes("register-repeat-password-error") ? localize("register-repeat-password-error") : undefined}
                isError={errors.includes("register-repeat-password-error")}
                initialValue={registerInfo.repeatPassword}
                placeholder={localize("password-verification")}
                id="passwordconfirm-input"
                spreadBehaviour="stretch"
                password={true}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "register-repeat-password-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
    
                    setErrors(_errors);
                }}
                onChangeText={(value) => {
                    setRegisterInfo({
                        ...registerInfo,
                        repeatPassword: value
                    });
                }}
            />
        </div>;
    };

    const renderPasswordRules = () => {
        const hasSpecialChar = /[)!@#$%^&*[\]{};':"<>?,./]/.test(registerInfo.password);
        const passwordLengthZero = registerInfo.password.length === 0;
        const hasUpperCase = /[A-Z]/.test(registerInfo.password);
        const hasLowerCase = /[a-z]/.test(registerInfo.password);
        const hasMinLength = registerInfo.password.length >= 8;
        const hasNumber = /\d/.test(registerInfo.password);
    
        const getColor = (
            condition: boolean,
            color: keyof IOCore.ColorsType,
        ): keyof IOCore.ColorsType => {
            return passwordLengthZero ? "greyBase" : condition ? color : "error";
        };
    
        const iconColor = getColor(
            hasMinLength && hasSpecialChar && hasUpperCase && hasLowerCase && hasNumber,
            "success"
        );
    
        return <div
            className={classes.passwordRules}
            style={{
                gap: spaces.content / 2
            }}
        >
            <div>
                <InfoIcon
                    color={colors[iconColor]}
                    size={16}
                />
            </div>
            <Text
                color={getColor(hasMinLength, "success")}
                variant="body3-regular"
            >
                {localize("your-password-must-be-at-least-8-characters")}
                <Text
                    color={getColor(hasUpperCase, "success")}
                    variant="body3-regular"
                >
                    {localize("one-capital-letter")}
                </Text>
                <Text
                    color={getColor(hasLowerCase, "success")}
                    variant="body3-regular"
                >
                    {localize("a-lowercase-letter")}
                </Text>
                <Text
                    color={getColor(hasNumber && hasSpecialChar, "success")}
                    variant="body3-regular"
                >
                    {localize("ıt-must-contain-one-special-character-and-one-number")}
                </Text>
            </Text>
        </div>;
    };
    
    const renderUserAgreementContainer = () => {
        return <div
            className={classes.userAgreementCotainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content 
            }}
        >
            <div
                className={classes.userAgreementContent}
            >
                <Text
                    variant='body3-medium'
                    color='textGrey'
                    style={{
                        paddingRight: spaces.inline
                    }}
                >
                    {getBrowserLanguageFull() !== "tr-TR" && (
                        <Fragment>
                        I confirm that I consent to the processing of my personal data in the ways specified in the{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Explicit Consent Text
                                </Text>
                            </div>
                            , and I acknowledge that I have read the{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/nondisclosure-agreement`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Information on the Protection of Personal Data
                                </Text>
                            </div>.
                        </Fragment>
                    )}
                    {getBrowserLanguageFull() === "tr-TR" && (
                        <Fragment>
                        Kişisel verilerimin{" "}
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/user-policy`);
                                }} 
                            >
                                <Text variant='body3-medium' color='greyBase'>
                                    Açık Rıza Metni’ninde
                                </Text>
                            </div> 
                            {" "}belirtlilen şekillerde işlenmesine verdiğimi,{" "} 
                            <div
                                className={classes.agreementText}
                                onClick={() => {
                                    window.open(`${window.location.origin}/agreement/nondisclosure-agreement`);
                                }} 
                            >
                                <Text 
                                    variant='body3-medium' 
                                    color='greyBase'
                                >
                                    Kişisel Verilerin Korunması
                                </Text>
                            </div> 
                            {" "}Hakkında Bilgilendirme’yi okuduğumu kabul ettiğimi onaylıyorum.
                        </Fragment>
                    )}
                </Text>
                <Switcher
                    isActive={registerInfo.approveContracts}
                    onChange={() => {
                        setRegisterInfo((prev) => {
                            return {
                                ...prev, 
                                approveContracts: !prev.approveContracts
                            };
                        });
                    }}
                />
            </div>
            <div
                className={classes.userAgreementContent}
            >
                <Text
                    variant='body3-medium'
                    color='textGrey'
                >
                    {/* //TODO: Localize Fix later */}
                    {getBrowserLanguageFull() !== "tr-TR" && (
                        <div>
                            "I give permission for <Text variant='body3-medium' color='greyBase'>commercial electronic messages</Text> to be sent by 'İşin olacak'." 
                        </div>
                    )}
                    {getBrowserLanguageFull() === "tr-TR" && (
                        <div>
                            “İşin olacak” tarafından <Text variant='body3-medium' color='greyBase' >ticari elektronik ileti</Text> gönderilmesine izin veriyorum.
                        </div>
                    )}
                </Text>
                <Switcher
                    isActive={registerInfo.approveCommercialMessages}
                    onChange={() => {
                        setRegisterInfo((prev) => {
                            return {
                                ...prev, 
                                approveCommercialMessages: !prev.approveCommercialMessages
                            };
                        });
                    }}
                />
            </div>

        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderInputs()}
        {renderUserAgreementContainer()}
    </div>;
};
export default PersonalInfo;
