import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    inputContainer: {
        boxSizing: "border-box",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        display: "flex",
        width: "100%"
    },
    selectboxEmpty: {
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
        display: "flex"
    },
    phoneInputContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    areaCodesContainer: {
        justifyContent:"space-between",
        flexDirection:"row",
        display: "flex"
    },
    phoneAreaCodeSelectbox: {
        width: "30%"
    }
});

export default useStyles;