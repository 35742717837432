import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    remove: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    }
});
export default useStyles;