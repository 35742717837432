import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    IStoryContainer 
} from "./types";

const StoryContainer = ({
    title, 
    icon, 
    text
}: IStoryContainer) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div 
        style={{
            paddingBottom: spaces.content * 1.2,
            backgroundColor: colors.secondary,
            paddingRight: spaces.content * 2,
            paddingLeft: spaces.content * 2,
            borderWidth: borders.line * 1.5,
            borderRadius: radiuses.half,
            borderColor: colors.stroke,
            paddingTop: spaces.content,
            gap: spaces.inline
        }}
        className={classes.storyContainer}
    >
        {icon()}
        <Text 
            variant="body-semiBold" 
            color={"white"}
        >
            {title}
        </Text>
        <Text 
            variant="body3-regular" 
            color={"white"}
        >
            {text}
        </Text>
    </div>;
};

export default StoryContainer;