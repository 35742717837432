import {
    SendMessageResponseType,
    SendMessageRequestType
} from "./types";
import {
    Socket
} from "socket.io-client";

const SendMessage = async (
    params: SendMessageRequestType,
    socket: Socket
): Promise<SendMessageResponseType> => {
    socket.emit("send-message", {
        conversationID: params.conversationID,
        messageID: params.messageID,
        message: params.message
    });

    return {
        message: "message-sended",
        code: 201
    };
};
export default SendMessage;
