import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const User = ({
    color = "#000",
    size = 18,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 18 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M9 1.688a3.563 3.563 0 1 0 0 7.125 3.563 3.563 0 0 0 0-7.126ZM6.563 5.25a2.438 2.438 0 1 1 4.875 0 2.438 2.438 0 0 1-4.875 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
        <path
            d="M14.892 11.68a.562.562 0 0 1 .012.795l-1.375 1.417a.563.563 0 0 1-.836-.032l-.625-.75a.562.562 0 1 1 .864-.72l.225.27.94-.968a.562.562 0 0 1 .795-.012Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M11.081 10.135a10.858 10.858 0 0 0-2.08-.197c-1.736 0-3.335.394-4.52 1.06-1.166.657-2.043 1.652-2.043 2.877v.076c-.001.872-.002 1.965.957 2.747.472.384 1.132.657 2.025.838.895.181 2.06.276 3.58.276 2.151 0 3.607-.19 4.59-.556.884-.329 1.408-.811 1.688-1.418a3.563 3.563 0 1 0-4.197-5.703Zm-.018 2.615a2.437 2.437 0 1 1 4.875 0 2.437 2.437 0 0 1-4.875 0Zm-.746-1.601a3.563 3.563 0 0 0 2.666 5.127c-.803.252-2.05.412-3.983.412-1.48 0-2.564-.094-3.357-.255-.795-.16-1.26-.381-1.538-.608-.512-.417-.542-.97-.542-1.95 0-.639.466-1.331 1.47-1.896.987-.555 2.389-.916 3.967-.916.455 0 .896.03 1.317.086Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default User;
