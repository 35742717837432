import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "row",
        userSelect: "none",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    leftContainer: {
        borderRightStyle: "solid",
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "50%",
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    leftTopContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: "100%",
        marginTop: "auto",
        display: "flex",
        maxWidth: 550,
        width: "75%"
    },
    visualImageContainer: {
        justifyContent: "center",
        maxHeight: "60%",
        display: "flex",
        flexGrow: 1,
    },
    visualImage: {
        objectFit: "scale-down",
        width: "100%"
    },
    visualImageContainerText: {
        textAlign: "center"
    },
    footerContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    footerButtonsContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    footerTopContainer: {
        marginTop: "auto"
    },
    footerButton: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 23,
        width: 23,
        "&:hover": {
            opacity: 0.8
        }
    },
    footerLink: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
            opacity: 0.8
        }
    },
    verticalLine: {
        height: 20,
        width: 1
    },
    rightContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "50%",
        "@media (max-width: 1040px)": {
            width: "100%"
        }
    },
    loginContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        maxWidth: 400,
        width: "100%"
    },
    inputContainer: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    buttonContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    lineContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    line: {
        display: "block",
        width: "30%",
        height: 1
    },
    socialButtonContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
});

export default useStyles;