import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const DownArrow = ({
    color= "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M1.576 8.949a1.355 1.355 0 0 1 1.91-.147L15.25 18.885 27.013 8.802a1.355 1.355 0 0 1 1.764 2.057L16.132 21.698a1.355 1.355 0 0 1-1.764 0L1.723 10.859a1.355 1.355 0 0 1-.147-1.91Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default DownArrow;
