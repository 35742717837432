import {
    Navigate,
    Routes,
    Route
} from "react-router-dom";
import EmployerCommunicationPreferences from "../pages/app/employer/profile/views/communicationPreferences";
import WorkerCommunicationPreferences from "../pages/app/worker/profile/views/communicationPreferences";
import MyCandidateAlarms from "../pages/app/employer/profile/views/myCandidateAlarms";
import EmployerContactUs from "../pages/app/employer/profile/views/contantUs";
import MyAdvertAlarms from "../pages/app/worker/profile/views/myAdvertAlarms";
import WorkerProfileForEmployer from "../pages/app/workerProfileForEmployer";
import EmployerSettings from "../pages/app/employer/profile/views/settings";
import CompanyProfileForWorker from "../pages/app/companyProfileForWorker";
import WorkerContactUs from "../pages/app/worker/profile/views/contantUs";
import WorkerSettings from "../pages/app/worker/profile/views/settings";
import ApplicationManagement from "../pages/app/applicationManagement";
import CandidateManagement from "../pages/app/candidateManegement";
import EmployerJobDetails from "../pages/app/employer/jobDetails";
import EmployerMyActions from "../pages/app/employer/myActions";
import WorkerJobDetails from "../pages/app/worker/jobDetails";
import EmployerProfile from "../pages/app/employer/profile";
import WorkerMyActions from "../pages/app/worker/myActions";
import FindCandidate from  "../pages/app/findCandidate";
import WorkerProfile from "../pages/app/worker/profile";
import CV from "../pages/app/worker/profile/views/cv";
import EmployerHome from "../pages/app/employer/home";
import EditAdvert from "../pages/app/editAdvert";
import NewAdvert from "../pages/app/newAdvert";
import SearchPage from "../pages/app/search";
import Contact from "../pages/app/contact";
import Chat from "../pages/app/chat";

const AppRoute = () => {
    const userType = window.localStorage.getItem("userType");
    return <Routes>
        <Route
            path="/"
            element={userType === "employer" && <EmployerHome/>}
        />
        <Route
            path="/home"
            element={userType === "employer" && <EmployerHome/>}
        />
        <Route
            path="/contact"
            element={<Contact/>}
        />
        <Route
            path="/search"
            element={userType === "worker" ? <SearchPage/> : null}
        />
        <Route
            path="/candidateManagement"
            element={userType === "employer" ? <CandidateManagement/> : null}
        />
        <Route
            path="/candidateManagement/:id"
            element={userType === "employer" ? <CandidateManagement/> : null}
        />
        <Route
            path="/myActions"
            element={userType === "worker" ? <WorkerMyActions /> : <EmployerMyActions />}
        />
        <Route
            path="/findCandidate"
            element={<FindCandidate/>}
        />
        <Route
            path="/profile"
            element={userType === "worker" ? <WorkerProfile /> : <EmployerProfile />}
        />
        <Route
            path="/newAdvert"
            element={<NewAdvert/>}
        />
        <Route
            path="/editAdvert"
            element={<EditAdvert/>}
        />
        <Route
            path="/chat"
            element={<Chat/>}
        />
        <Route
            path="/jobDetails"
            element={userType === "worker" ? <WorkerJobDetails /> : <EmployerJobDetails />}
        />
        <Route
            path="/jobDetails/:id"
            element={userType === "worker" ? <WorkerJobDetails /> : <EmployerJobDetails />}
        />
        <Route
            path="/*"
            element={<Navigate to="/notFound" />}
        />
        <Route
            path="/profileUser"
            element={userType === "worker" ? <CompanyProfileForWorker /> : <WorkerProfileForEmployer />}
        />
        <Route
            path="/profileUser/:id"
            element={userType === "worker" ? <CompanyProfileForWorker /> : <WorkerProfileForEmployer />}
        />
        <Route
            path="/communicationPreferences"
            element={userType === "worker" ? <WorkerCommunicationPreferences /> : <EmployerCommunicationPreferences />}
        />
        <Route
            path="/contactUs"
            element={userType === "worker" ? <WorkerContactUs /> : <EmployerContactUs />}
        />
        <Route
            path="/settings"
            element={userType === "worker" ? <WorkerSettings /> : <EmployerSettings />}
        />
        <Route
            path="/cv"
            element={<CV />}
        />
        <Route
            path="/myAlarms"
            element={userType === "worker" ? <MyAdvertAlarms /> : <MyCandidateAlarms />}
        />
        <Route
            path="/applicationManagement"
            element={userType === "worker" ? <ApplicationManagement /> : <ApplicationManagement />}
        />
    </Routes>;
};
export default AppRoute;
