import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    card: {
        flexDirection: "column",
        borderStyle: "solid",
        minWidth: "285px",
        display: "flex"
    },
    cardHeader: {
        alignItems: "center",
        display: "flex"
    },
    icon: {
        borderStyle: "solid"
    }
});
export default useStyles;