import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    candidateInfoContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        cursor: "pointer",
        display:"flex"
    },
    card: {
        borderTopStyle: "solid",
        flexDirection: "column",
        display: "flex"
    },
    cardHeader: {
        alignItems: "center",
        display: "flex"
    },
    logo: {
        borderStyle: "solid",
        objectFit: "cover",
        height: "70px",
        width: "70px"
    },
    applicationCardInfoContent: {
        position: "relative"
    },
    renderIcon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 30,
        width: 30
    }
});
export default useStyles;