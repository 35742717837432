import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    StateCard,
    TextInput,
    Sticker,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    MagnifierIcon,
    SettingsIcon,
    WidgetAddIcon,
    TrashIcon,
    FlameIcon,
    UsersIcon,
    ShareIcon,
    HelpIcon,
    CallIcon,
    VerifiedCheckIcon
} from "../../../../assets/svgr";
import {
    JobDetailsTypes
} from "./types";
import {
    GetAdvertWithDetailForEmployerResponseType
} from "../../../../services/restAPI/actions/getAdvertWithDetailForEmployer/types";
import {
    RESTService
} from "../../../../services/restAPI";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    ApplicantCard,
    EmployerProfileLeftContainer
} from "../../../../components";
import {
    getErrorText
} from "../../../../utils";

const EmployerJobDetails = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const {
        state
    } = useLocation();

    const urlParams = useParams();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [data, setData] = useState<GetAdvertWithDetailForEmployerResponseType>();
    const [activeTab, setActiveTab] = useState<JobDetailsTypes>("job-details");
    const [activePage, setActivePage] = useState(location.pathname);
    const [isShareVisible,setIsShareVisible] = useState(false);
    const [isVisible,setIsVisible] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shareURL, setShareURL] = useState("");
    const [ID, setID] = useState("");

    
    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    useEffect(() => {
        if(state) {
            setID(state.advertID);
            setShareURL(`${window.location.origin}/share/jobDetails/${state.advertID}`);
            handleGetAdvertWiltDetail(state.advertID);
        } else if (urlParams.id) {
            setID(urlParams.id);
            setShareURL(`${window.location.origin}/share/jobDetails/${urlParams.id}`);
            handleGetAdvertWiltDetail(urlParams.id);
        } else {
            navigate("/notFound");
        }
    }, []);

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleGetAdvertWiltDetail = (id: string) => {
        setLoading(true);
        RESTService.action("GetAdvertWithDetailForEmployer", {
            advertID: id
        }).then((res) => {
            setLoading(false);
            setData(res);
        }).catch((err) => {
            console.error("GetAdvertWithDetailForEmployer Err:", err);
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const handleRemoveAdvertFromPublication = () => {
        RESTService.action("RemoveAdvert", {
            advertID: data?._id
        }).then((res) => {
            setTimeout(() => {
                navigate("/app/myActions");
            }, 300);
        }).catch((err) => {
            console.error("RemoveAdvert Err:", err);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const handleNavigate = (path: string) => {
        setActivePage(path);
        navigate(path);
    };
    
    const renderHeaderJobDetailCard = () => {
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.headerContainer}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {data?.occupation.localizedText}
                </Text>
                <div
                    className={classes.headerContainerIcon}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <div
                        className={classes.icon}
                        onClick={() => {
                            navigator.clipboard.writeText(shareURL);
                            setIsShareVisible(true);
                        }}
                        style={{
                            borderRadius: spaces.containerXXLarge,
                            borderWidth: spaces.inline / 5,
                            backgroundColor: colors.white,
                            borderColor: colors.textGrey
                        }}
                    >
                        <ShareIcon
                            color={colors.textGrey}
                            size={15}
                        />
                    </div>
                    <div
                        onClick={() => setIsVisible(true)}
                        className={classes.icon}
                        style={{
                            borderRadius: spaces.containerXXLarge,
                            borderWidth: spaces.inline / 5,
                            backgroundColor: colors.white,
                            borderColor: colors.textGrey
                        }}
                    >
                        <TrashIcon color={colors.textGrey} size={15} />
                    </div>
                </div>
            </div>
            <div
                className={classes.headerContainerIcon}
                style={{
                    paddingTop: spaces.container
                }}
            >
                <img
                    src={data?.companyInfo.photoURL && data?.companyInfo.photoURL.length ? data?.companyInfo.photoURL : require("../../../../assets/img/isinolacakMini.png")}
                    className={classes.companyHeaderLogo}
                    style={{
                        borderRadius: radiuses.hard,
                        marginRight: spaces.content,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.inline
                    }}
                />
                <div
                    className={classes.cardText}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2
                        }}
                    >
                        {data?.companyInfo.title}
                    </Text>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {`${data?.experienceLevel.localizedText} • ${data?.contractType.localizedText} • ${data?.city.localizedText} (${data?.workingType.localizedText})`}
                    </Text>
                </div>
            </div>
            {renderStickers()}
        </div>;
    };

    const renderSuccesShareModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            onOverlayPress={() => {
                setIsShareVisible(false);
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.contentLarge
                    }}
                >
                    <div
                        style={{
                            marginBottom: spaces.content
                        }}
                    >
                        <VerifiedCheckIcon
                            color={colors.success}
                            size={68}
                        />
                    </div>
                    <div
                        className={classes.pageModalContainer}
                        style={{
                            marginBottom: spaces.content * 3
                        }}
                    >
                        <Text
                            variant="header5-medium"
                        >
                            {localize("advert-link-copied")}
                        </Text>
                        <Text
                            variant="body-regular"
                            color="textSecondary"
                        >
                            {localize("advert-link-copied-content")}
                        </Text>
                    </div>
                    <Button
                        title={localize("IOCoreDefaultDialogPrimaryButtonTitle")}
                        onClick ={
                            () => {
                                setIsShareVisible(false);
                            }
                        }
                        spreadBehaviour="stretch"
                        color="primary"
                        size="large"
                    />
                </div>
            }
            isVisible={isShareVisible}
        />;
    };

    const renderStickers = () => {
        return <div
            className={classes.sticker}
            style={{
                gap: spaces.container
            }}
        >
            {
                data?.boosted.map((item,index) => {
                    return  <Sticker
                        key={`boosted-${index}`}
                        title={item.localizedText}
                        color={item.backColor}
                        titleColor={item.color}
                        icon={() => <div
                            dangerouslySetInnerHTML={{
                                __html: item.icon
                            }}
                        ></div>}
                    />;
                })
            }
        </div>;
    };
    
    const renderJobDetailAbout = () => {
        return <div>
            <div>
                <div
                    style={{
                        marginTop: spaces.content
                    }}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                    >
                        {localize("about-the-job-posting")}
                    </Text>
                </div>
                <div
                    style={{
                        marginTop: spaces.content * 1,
                        marginBottom: spaces.content 
                    }}>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {data?.description}
                    </Text>
                </div>
            </div>
        </div>;
    };
    
    const renderJobDetailApplicationProcess = () => {
        return <div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("application-process")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content * 1,
                    marginBottom: spaces.content 
                }}>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {localize("application-process-text")}
                </Text>
            </div>
        </div>;
    };

    const renderApplicants = () => {
        return <div>
            {data?.applicants.map((item) => (
                <ApplicantCard
                    educationLevel={item.education.length ? item.education[0].educationLevel.localizedText : ""}
                    occupation={item.occupation.localizedText}
                    applicationID={item.applicationID}
                    applicantFullName={item.fullName}
                    applicantImage={item.photoURL}
                    city={item.city.localizedText}
                    appliedAt={item.appliedAt}
                    isPremium={item.isPremium}
                    onClick={() => { 
                        navigate("/app/profileUser", {
                            state: {
                                workerID: item.userID
                            }
                        }); 
                    }}
                />
            ))}
        </div>;
    };

    const renderApplicantUserButton = () => {
        return <div
            className={classes.buttonContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                title={localize("manage-advert")}
                spreadBehaviour="stretch"
                color="secondary"
                size="large"
                onClick={() => {
                    navigate("/app/candidateManagement",{
                        state: {
                            advertID: ID
                        }
                    });
                }}
            />
            <Button
                title={localize("edit-ad")}
                spreadBehaviour="stretch"
                onClick={() => {
                    navigate("/app/editAdvert",{
                        state: {
                            advertID: ID
                        }
                    });
                }}
                variant="outline"
                color="secondary"
                size="large"
            />
        </div>;
    };

    const renderSearchContainer = () => {
        if(!data?.applicants.length) {
            return <div
                className={classes.searhStateCard}
                style={{
                    borderBottomColor: colors.stroke,
                    backgroundColor: colors.white,
                    paddingBottom: spaces.content,
                    borderTopColor: colors.stroke,
                    paddingTop: spaces.content,
                    gap: spaces.content
                }}>
                <StateCard
                    content={localize("no-aplicant-to-advert")}
                    title={localize("no-apply-for-now")}
                />
            </div>;
        }

        return <div
            className={classes.searchContianer}
            style={{
                borderBottomColor: colors.stroke,
                backgroundColor: colors.white,
                paddingBottom: spaces.content,
                borderTopColor: colors.stroke,
                paddingTop: spaces.content,
                gap: spaces.content,
            }}
        >
            <TextInput
                placeholder={localize("find-candidates")}
                clearEnabled= {true}
                iconOnClick={() => {
                }}
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                onChangeText={(e) => {
                }}
            />
            <Button
                title={localize("search")}
                spreadBehaviour="free"
                onClick={() => {}}
            />
        </div>;
    };

    const renderTabBar = () => {
        return <div>
            <div
                className={classes.tabBar}
            >
                <div
                    className={`${classes.tabItem} ${activeTab === "job-details" ? "active" : ""}`}
                    onClick={() => setActiveTab("job-details")}
                >
                    <Text
                        color={activeTab === "job-details" ? "primary" : "textSecondary"}
                        variant="body2-regular"
                    >
                        {localize("job-details")}
                    </Text>
                </div>
                <div
                    className={`${classes.tabItem} ${activeTab === "applicant-user" ? "active" : ""}`}
                    onClick={() => setActiveTab("applicant-user")}
                >
                    <Text
                        color={activeTab === "applicant-user" ? "primary" : "textSecondary"}
                        variant="body2-regular"
                    >
                        {localize("applicant-candidates")}
                    </Text>
                </div>
            </div>
            <div className={classes.tabContent}>
                {activeTab === "job-details" && 
                <>
                    {renderJobDetailAbout()}
                    {renderJobDetailApplicationProcess()}
                </>
                }
                {activeTab === "applicant-user" && 
                <div>
                    {renderSearchContainer()}
                    {renderApplicants()}
                </div>}
            </div>
            {renderApplicantUserButton()}
        </div>;
    };
    
    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2
            }}
        >
            <img
                src={data?.companyInfo.photoURL && data?.companyInfo.photoURL.length ? data?.companyInfo.photoURL : require("../../../../assets/img/isinolacakMini.png")}
                alt="Profile"
                className={classes.companyLogo}
                style={{
                    borderRadius: radiuses.hard * 20,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    padding: spaces.inline
                }}
            />
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {data?.companyInfo.title}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.contentXLarge
                }}>
                <Text
                    variant="body4-regular"
                    color="primary"
                >
                    {data?.occupation.localizedText}
                </Text>
            </div>
            <div
                className={classes.buttonContainer}
                style={{
                    gap: spaces.content
                }}
            >
                <div
                    className={classes.button}
                    style={{
                        gap: spaces.content
                    }}
                    onClick={() => {
                        handleNavigate("/app/profile");
                    }}
                >
                    <UsersIcon
                        color={activePage === "/app/profile" ? colors.primary : colors.textDark}
                    />
                    <Text
                        color={activePage === "/app/profile" ? "primary" : "textDark"}
                        className={classes.buttonText}
                        variant="body2-regular"
                    >
                        {localize("personal-information")}
                    </Text>
                </div>

                <div
                    className={classes.button}
                    style={{
                        gap: spaces.content
                    }}
                    onClick={() => {
                        handleNavigate("/app/communicationPreferences");
                    }}
                >
                    <CallIcon
                        color={colors.textDark}
                    />
                    <Text
                        color={"textDark"}
                        className={classes.buttonText}
                        variant="body2-regular"
                    >
                        {localize("communication-preferences")}
                    </Text>
                </div>

                <div
                    className={classes.button}
                    style={{
                        gap: spaces.content
                    }}
                    onClick={() => {
                        handleNavigate("/app/settings");
                    }}
                >
                    <SettingsIcon
                        color={colors.textDark}
                    />
                    <Text
                        color={"textDark"}
                        className={classes.buttonText}
                        variant="body2-regular"
                    >
                        {localize("settings")}
                    </Text>
                </div>

                <div
                    className={classes.button}
                    style={{
                        gap: spaces.content
                    }}
                    onClick={() => {
                        handleNavigate("/app/contactUs");
                    }}
                >
                    <HelpIcon
                        color={colors.textDark}
                    />
                    <Text
                        color={"textDark"}
                        className={classes.buttonText}
                        variant="body2-regular"
                    >
                        {localize("contact-us")}
                    </Text>
                </div>

                <div
                    className={classes.button}
                    style={{
                        gap: spaces.content
                    }}
                    onClick={() => {
                        handleNavigate("/app/myAlarms");
                    }}
                >
                    <HelpIcon
                        color={colors.textDark}
                    />
                    <Text
                        color={"textDark"}
                        className={classes.buttonText}
                        variant="body2-regular"
                    >
                        {localize("my-candidate-alarm")}
                    </Text>
                </div>
            </div>
        </div>;
    };
    
    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
            }}
        >
            {renderHeaderJobDetailCard()}
            {renderTabBar()}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderAdvertDeleteModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.content
                    }}
                >
                    <StateCard
                        content={localize("we-don't-want-you-to-miss-the-employee-you-are-looking-for")}
                        title={localize("you-are-about-to-remove-your-ad")}
                        icon={() =>
                            <TrashIcon
                                color={colors.error}
                                size={68}
                            />
                        }
                    />
                    <div
                        className={classes.pageModalContent}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <Button
                            onClick={() => {setIsVisible(false);}}
                            title={localize("give-up")}
                            spreadBehaviour='stretch'
                            variant='outline'
                        />
                        <Button
                            onClick={() => handleRemoveAdvertFromPublication()}
                            title={localize("remove")}
                            spreadBehaviour='stretch'
                        />
                    </div>
                </div>
            }
            isVisible={isVisible}
        />;
    };

    if(loading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${!isSmall ? spaces.container * 8 : 0}px`,
            backgroundColor: colors.layer3
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("job-details")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <EmployerProfileLeftContainer/>
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
        {renderSuccesShareModal()}
        {renderAdvertDeleteModal()}
    </div>;
};

export default EmployerJobDetails;
