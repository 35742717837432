import {
    Button,
    IOCoreLocale,
    IOCoreTheme 
} from "isinolacak-web-cl";
import useStyles from "./style";
import {
    NavLinkProps 
} from "./type";
import {
    useNavigate 
} from "react-router-dom";
import {
    NavbarContext 
} from "../../../../context";

const NavLink = ({
    isDropDownTitle,
    isActive,
    titleKey,
    isChild,
    path,
    href,
}: NavLinkProps) => {
    
    const {
        colors,
        spaces,
        borders
    } = IOCoreTheme.useContext();

    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const renderBottomLine = () => {
        return <div
            className={classes.bottomLine}
            style={{
                backgroundColor: isActive ? colors.primary : "transparent",
                height: borders.indicator
            }}
        >
        </div>;
    };

    return <div
        className={classes.container}
    >
        <Button
            color={isActive ? "primary" : "body"}
            title={localize(titleKey)}
            variant="ghost"
            onClick={() => {
                if(isDropDownTitle){
                    return;
                }
                if(href){
                    NavbarContext.emitWithKey(path, href);
                    navigate(path+"?href="+href);
                }else {
                    navigate(path);
                }
            }}
        />
        {!isChild && renderBottomLine()}
    </div>;
};
export default NavLink;
