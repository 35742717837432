import React from "react";
import {
    AnswerChildObjectType 
} from "../../types";
import {
    IOCoreLocale, IOCoreTheme, SelectBox 
} from "isinolacak-web-cl";

const FivePoints = ({
    setData,
    index,
    data
}: AnswerChildObjectType) => {
    const {
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    let initalSelected = [
        {
            value: String(data.questions[index].answerPayload.attributes.expectationIndex),
            key: String(data.questions[index].answerPayload.attributes.expectationIndex)
        }
    ];

    return <SelectBox
        titleExtractor={(item) => item.value ? item.value : ""}
        inputTitle={localize("expectation")}
        initialSelectedItems={initalSelected}
        title={localize("expectation")}
        keyExtractor={(e) => e.key}
        data={[
            {
                value: "1",
                key: "1"
            },
            {
                value: "2",
                key: "2"
            },
            {
                value: "3",
                key: "3"
            },
            {
                value: "4",
                key: "4"
            },
            {
                value: "5",
                key: "5"
            }
        ]}
        isNeedConfirm={true}
        multiSelect={false}
        disabled={false}
        style={{
            marginBottom: spaces.content * 1.5
        }}
        onOk={({
            selectedItems,
            closeSheet,
            onSuccess
        }) => {
            const selectedIndex = selectedItems[0].__title;
            let _data = JSON.parse(JSON.stringify(data));
            _data.questions[index].answerPayload.attributes.expectationIndex = selectedIndex;
            setData(_data);
            closeSheet();
            onSuccess();
        }}
    />;
};
export default FivePoints;
