import axios from "axios";
import {
    ReSendSMSOTPResponseType,
    ReSendSMSOTPRequestType
} from "./types";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    getHeaders
} from "../../utils";

const ReSendSMSOTP = async (params: ReSendSMSOTPRequestType): Promise<ReSendSMSOTPResponseType> => {
    return await axios(`${SERVER_ADDRESS}/authentication/reSendCodeSMSOTP`, {
        method: "POST",
        headers: getHeaders(),
        data: JSON.stringify(params)
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }

            throw err;
        });
};
export default ReSendSMSOTP;
