import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledArrowUpIcon = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15.74 7.33 28.6 20.59c.803.828.316 2.41-.74 2.41H2.14c-1.056 0-1.543-1.582-.74-2.41L14.26 7.33c.425-.44 1.055-.44 1.48 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledArrowUpIcon;
