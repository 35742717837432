import React, {
    ChangeEvent,
    Fragment,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    CircleIcon,
    RadarIcon
} from "../../../../assets/svgr";
import {
    RESTService
} from "../../../../services/restAPI";
import {
    PersonalInfoTypes
} from "./types";
import {
    GetMyProfileResponseTypes
} from "../../../../services/restAPI/actions/getMyProfile/types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import PersonalInfo from "./views/personelInfo";
import {
    Footer,
    WorkerProfileLeftContainer 
} from "../../../../components";

const WorkerProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();
    
    const [getMyProfile, setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [personalInfo, setPersonalInfo] = useState<PersonalInfoTypes>({
        areaCode: {
            countryTranslationKey: "",
            flagUnicode: "",
            country: "",
            value: "",
            _id: "",
        },
        occupation: {
            localizedText: "",
            _id: ""
        },
        phoneNumber: "",
        firstName: "",
        lastName: "",
        fullName: "",
        photoURL: "",
        about: "",
        mail: "",
    });

    useEffect(() => {
        handleGetMyProfile();
    }, []);

    const handleGetMyProfile = () => {
        setLoading(true);
    
        RESTService.action("GetMyProfile", {
        })
            .then((res) => {
                setGetMyProfile(res);
    
                if (res.profilePayload) {
                    const updatedPersonalInfo = {
                        ...personalInfo,
                        occupation: {
                            localizedText: res.profilePayload.occupation.localizedText,
                            _id: res.profilePayload.occupation._id,
                        },
                        photoURL: res.profilePayload.profilePhotoURL,
                        areaCode: res.profilePayload.phoneAreaCode,
                        firstName: res.profilePayload.firstName,
                        lastName: res.profilePayload.lastName,
                        phoneNumber: res.profilePayload.phone,
                        fullName: res.profilePayload.fullName,
                        about: res.profilePayload.about,
                        mail: res.profilePayload.mail
                    };
    
                    setPersonalInfo(updatedPersonalInfo);
    
                    const localStorageData = window.localStorage.getItem("user");

                    if (localStorageData) {
                        const user = JSON.parse(localStorageData);

                        const updatedUser = {
                            ...user,
                            ...updatedPersonalInfo
                        };

                        localStorage.setItem("user", JSON.stringify(updatedUser));
                    }
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetMyProfile Err:", err);
                setLoading(false);
            });
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ınstalled-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    variant="outline"
                    title={localize("ok")}
                />
            </div>}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <PersonalInfo
                setPersonalInfo={setPersonalInfo}
                getMyProfile={getMyProfile}
                personalInfo={personalInfo}
            />
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.mainContainer}
            style={{
                padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
                backgroundColor: colors.layer3,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("person-profile")}
            </Text>
            <div
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                <WorkerProfileLeftContainer isPersonalInfo={true}/>
                {renderContentContainer()}
                {renderRightContainer()}
        
            </div>
            {renderSuccessDialog()}
        </div>
        <Footer/>
    </Fragment>;
};

export default WorkerProfile;
