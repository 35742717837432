import {
    useEffect,
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import useStyles from "./style";
import {
    RegisterType
} from "./types";
import {
    useNavigate 
} from "react-router-dom";
import {
    InstagramIcon,
    FacebookIcon,
    YoutubeIcon,
    XIcon
} from "../../../assets/svgr";
import WorkerRegister from "./views/worker";
import EmployerRegister from "./views/employer";
import RegisterLoginImage from "../../../assets/img/registerLoginImage.svg";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    GetAreaCodesResponseType 
} from "../../../services/restAPI/actions/getAreaCodes/types";
import {
    ISIN_OLACAK_INSTAGRAM, 
    ISIN_OLACAK_FACEBOOK, 
    ISIN_OLACAK_TWITTER, 
    ISIN_OLACAK_YOUTUBE 
} from "../../../constants";
import {
    getErrorText 
} from "../../../utils";

const Register = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [registerType, setRegisterType] = useState<RegisterType>("worker");
    const [areaCodes, setAreaCodes] = useState<GetAreaCodesResponseType>();

    useEffect(() => {
        getAreaCodes();
    }, []);

    const getAreaCodes = () => {
        RESTService.action("GetAreaCodes",{
        })
            .then((res) => {
                setAreaCodes(res);
            })
            .catch((err) => {
                console.error("GetAreaCodes Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                borderRightColor: colors.stroke,
                borderRightWidth: borders.line,
                padding: spaces.container
            }}
        >
            <div
                className={classes.leftTopContainer}
            >
                <div className={classes.visualImageContainer}>
                    <img
                        className={classes.visualImage}
                        src={RegisterLoginImage}
                        alt="sideVisualImage"
                    />
                </div>
                <div 
                    className={classes.visualImageContainerText}
                    style={{
                        marginTop: spaces.containerLarge
                    }}
                >
                    <Text 
                        variant="body2-regular"
                    >
                        {localize("with-isinolacak-you-will-reach-your-career-goals")}
                    </Text>
                </div>
            </div>
            <div 
                className={classes.footerTopContainer}
            >
                {renderFooter()}
            </div>
        </div>;
    };

    const renderFooter = () => {
        return <div
            className={classes.footerContainer}
            style={{
                gap: spaces.contentLarge
            }}
        >
            <div 
                onClick={() => {
                    navigate("/aboutUs");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("about-us")}
                </Text>
            </div>
            <div 
                onClick={() => {
                    navigate("/contactUs");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("help")}
                </Text>
            </div>
            <div 
                onClick={() => {
                    navigate("/agreement/user-policy");
                }}
            >
                <Text
                    className={classes.footerLink}
                    variant="body4-regular"
                    color="secondary"
                >
                    {localize("data-policy")}
                </Text>
            </div>
            <div
                className={classes.footerButtonsContainer}
                style={{
                    gap: spaces.item
                }}
            >
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                />
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_INSTAGRAM);
                    }}
                >
                    <InstagramIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_YOUTUBE);
                    }}
                >
                    <YoutubeIcon 
                        color={colors.white} 
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_FACEBOOK);
                    }}
                >
                    <FacebookIcon 
                        color={colors.white} 
                        size={15} 
                    />
                </div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        window.open(ISIN_OLACAK_TWITTER);
                    }}
                >
                    <XIcon 
                        color={colors.white}
                        size={15} 
                    />
                </div>
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                />
            </div>
            <Text 
                variant="body4-regular" 
                color="secondary"
            >
					isinolacak.com
            </Text>
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderLeftContainer()}
        {
            registerType === "worker" ? <WorkerRegister 
                setRegisterType={setRegisterType}
                registerType={registerType}
                areaCode={areaCodes}
            /> 
                : 
                <EmployerRegister
                    setRegisterType={setRegisterType}
                    registerType={registerType}
                    areaCode={areaCodes}
                />
        }
        
    </div>;
};
export default Register;
