import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "row",  
        display: "flex",
        height: "91%",
        width: "100%",
        "@media screen and (min-height: 1440px)": {
            height: "95%",
        }
    }
});
export default useStyles;
