import {
    createUseStyles
} from "react-jss";

export const useStyles = createUseStyles({
    pageModalContainer: {
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none"
    },
    pageModalContent: {
        flexDirection: "row",
        display: "flex"
    }
});
