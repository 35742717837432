import {
    Fragment,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    RadarIcon
} from "../../../assets/svgr";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    AdvertCardForEmployerProfile,
    Footer
} from "../../../components";
import {
    RESTService
} from "../../../services/restAPI";
import {
    GetCompanyProfileForWorkerResponseTypes
} from "../../../services/restAPI/actions/getCompanyProfileForWorker/types";
import InfoCard from "./infoCard";
import {
    StickerObjectType
} from "../../../components/advertCardForEmployer/types";
import {
    getErrorText
} from "../../../utils";
import IsinolacakPlaceholder from "../../../assets/img/isinolacakMiniWithPadding.png";

const CompanyProfileForWorker = () => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        state 
    } = useLocation();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getCompanyProfileForWorker, setGetCompanyProfileForWorker] = useState<GetCompanyProfileForWorkerResponseTypes>();
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ID, setID] = useState("");

    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
  

    useEffect(() => {
        if(state) {
            setID(state.companyID);
            handleGetCompanyProfileForWorker(state.companyID);
            
        } else if (urlParams.id) {
            setID(urlParams.id);
            handleGetCompanyProfileForWorker(urlParams.id);
        }  else {
            navigate("/notFound");
        }
    }, []);

    useEffect(() => {
        if(ID) {
            handleGetCompanyProfileForWorker(ID);
        }
    }, [ID]);

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleGetCompanyProfileForWorker = (id: string) => {
        setLoading(true);
        RESTService.action("GetCompanyProfileForWorker", {
            companyID: id
        }).then((res) => {
            setGetCompanyProfileForWorker(res);
            setLoading(false);
        }).catch((err) => {
            console.error("ERR", err);
            setLoading(false);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                marginBottom: spaces.content * 2,
                backgroundColor: colors.stroke,
                marginTop: spaces.content * 2
            }}
        >
        </div>;
    };
    
    const renderLeftContainer = () => {
        if(!getCompanyProfileForWorker?.about) {
            return <div
                className={classes.leftContainer}
                style={{
                    padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    top: spaces.content * 2
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("about")}
                </Text>
                <div style={{
                    padding: spaces.content
                }}>
                    <InfoCard
                        text={localize("not-yet-added")}
                    />
                </div>
            </div>;
        }

        return <div
            className={classes.leftContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2,
            }}
        >
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("about")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content * 1,
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {getCompanyProfileForWorker?.about}
                </Text>
            </div>
        </div>;
    };

    const renderCompanyInfoHeader = () => {
        return <div
            className={classes.companyInfoHeaderContainer}
        >
            <div
                className={classes.comapnyInfoHeaderContent}
            >
                <img
                    className={classes.companyInfoHeaderımage}
                    style={{
                        borderRadius: radiuses.quarter * 20,
                        borderColor: colors.primary,
                        marginTop: spaces.content
                    }}
                    src={getCompanyProfileForWorker?.photoURL ? getCompanyProfileForWorker.photoURL : IsinolacakPlaceholder}
                    alt="Profile"
                />
                <div
                    className={classes.companyInfoHeaderText}
                    style={{
                        marginTop: spaces.content,
                        gap: spaces.inline / 2
                    }}
                >
                    <Text
                        variant="header4-medium"
                        color="textDark"
                    >
                        {getCompanyProfileForWorker?.title}
                    </Text>
                    <Text
                        variant="body2-regular"
                        color="textSecondary"
                    >
                        {getCompanyProfileForWorker?.sector.localizedText ? getCompanyProfileForWorker?.sector.localizedText : localize("sector-information-was-not-entered")}
                    </Text>
                    <Text
                        variant="body2-regular"
                        color="primary"
                    >
                        {getCompanyProfileForWorker?.website ? getCompanyProfileForWorker?.website : localize("no-website-information-entered")}
                    </Text>
                </div>
                <div
                    style={{
                        marginBottom: spaces.content
                    }}
                />
                <div>
                    <Text
                        variant="body2-regular"
                        color="textDark"
                    >
                        {getCompanyProfileForWorker?.city.localizedText ? getCompanyProfileForWorker?.city.localizedText : localize("location-information-was-not-entered")}, Türkiye
                    </Text>
                </div>
            </div>
            {renderLine()}
        </div>;
    };

    const renderAdvertCard = () => {
        if(!getCompanyProfileForWorker?.adverts && !getCompanyProfileForWorker?.adverts.length) {
            return <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("job-postings")}
                </Text>
                <InfoCard
                    text={localize("no-job-postings-have-been-added-yet")}
                />
            </div>;
        }

        return <div>
            <div
                className={classes.advertCardContainer}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("job-postings")}
                </Text>
                <Button
                    onClick={() => {
                        navigate("/app/search");
                    }}
                    title={localize("see-all")}
                    variant="ghost"
                />
            </div>
            <div
                className={classes.advertCardContent}
                style={{
                    gap: spaces.content
                }}
            >
                {getCompanyProfileForWorker?.adverts.map((item, index) => {
                    let stickers: Array<StickerObjectType> = [];
                    item.boosts.forEach((boost) => {
                        stickers.push({
                            localizedText: boost.localizedText,
                            backColor: boost.backColor,
                            color: boost.color,
                            icon: () => <div
                                dangerouslySetInnerHTML={{
                                    __html: boost.icon
                                }}
                            ></div>,
                            key: boost.key
                        });
                    });
                    return <AdvertCardForEmployerProfile
                        jobImage={getCompanyProfileForWorker?.photoURL ? getCompanyProfileForWorker.photoURL : IsinolacakPlaceholder}
                        contractType={item.contractType.localizedText}
                        workingType={item.workingType.localizedText}
                        experienceLevel={item.experienceLevel.localizedText}
                        companyName={getCompanyProfileForWorker.title}
                        title={item.occupation.localizedText}
                        city={item.city.localizedText}
                        stickers={stickers}
                        onClick={() => {
                            navigate("/app/jobDetails",{
                                state: {
                                    jobID: item._id
                                }
                            });
                        }}
                    />;
                })}
            </div>
        </div>;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `20px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderCompanyInfoHeader()}
            {renderAdvertCard()}
        </div>;
    };
    
    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.mainContainer}
            style={{
                padding: `${spaces.container * 3}px ${!isSmall ? spaces.container * 3 : 0}px`,
                backgroundColor: colors.layer3,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("person-profile")}
            </Text>
            <div
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                {renderLeftContainer()}
                {renderContentContainer()}
                {renderRightContainer()}
            </div>
        </div>
        <Footer/>
    </Fragment>;
};

export default CompanyProfileForWorker;
