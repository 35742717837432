import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    cardContainer: {
        boxSizing: "border-box",
        alignItems: "center",
        borderStyle: "solid",
        display: "flex",
        width: "100%"
    },
    iconContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
});
export default useStyles;