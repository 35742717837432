import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        maxWidth: "450px",
        display: "flex",
    },
    closeButtonField:{
        position: "absolute",
        right: 0,
        top: 0
    },
    contentContainer:{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        display: "flex"
    },
    rightContentContainer:{
        flexDirection: "row",
        display: "flex"
    },
    succesfullStatusContentContainer:{
        flexDirection: "row",
        alignItems: "center",
        width: "300px",
        display: "flex"
    },
    textField:{
        justifyContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    pageModalContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        userSelect: "none",
        display:"flex"
    },
    pageModalContent: {
        flexDirection: "row",
        display: "flex"
    }
});
