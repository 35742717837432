import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        cursor: "pointer",
        display: "flex",
        width: "100%"
    },
    content: {
        flexDirection: "row",
        display: "flex"
    },
    icon: {
        cursor: "pointer"
    },
    infoContainer: {
        flexDirection: "column",
        display: "flex"
    },
    chartContainer: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        borderRadius: "200px",
        display: "flex",
        height: "30px",
        width: "30px"
    },
    menuContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    line: {
        width: "100%",
        height: "1px"
    },
    allSendDocumentContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex"
    },
    documentContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
    documentContent: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
    heightLine: {
        borderStyle: "solid",
        height: 20
    },
    input:{
        display: "none" 
    },
    statusControll: {
        flexDirection: "row",
        alignItems: "center",
        display:"flex"
    }
});

export default useStyles;