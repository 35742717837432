import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Home = ({
    color= "#45BBD4",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            d="M18.25 2h-2.5a.5.5 0 0 0-.5.5v.059l3.5 2.8V2.5a.5.5 0 0 0-.5-.5Z"
        />
        <path
            fill={color}
            fillRule="evenodd"
            transform={`scale(${1 / pathScale})`}
            d="M10.5 8.5a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            d="m20.5 9.96.782.626a.75.75 0 0 0 .936-1.172l-8.125-6.5a3.75 3.75 0 0 0-4.686 0l-8.125 6.5a.75.75 0 0 0 .937 1.172L3 9.96v10.29H1.75a.75.75 0 0 0 0 1.5h20a.75.75 0 0 0 0-1.5H20.5V9.96ZM9 8.5a2.75 2.75 0 1 1 5.5 0 2.75 2.75 0 0 1-5.5 0Zm2.8 3.75c.664 0 1.237 0 1.696.062.492.066.963.215 1.345.597s.531.853.597 1.345c.058.43.062.96.062 1.573v4.423H14V16c0-.728-.002-1.2-.048-1.546-.044-.325-.114-.427-.172-.484-.057-.057-.159-.128-.484-.172-.347-.046-.818-.048-1.546-.048-.728 0-1.2.002-1.546.048-.325.044-.427.115-.484.172-.057.057-.128.159-.172.484-.046.347-.048.818-.048 1.546v4.25H8v-4.3c0-.664 0-1.237.062-1.696.066-.492.215-.963.597-1.345s.854-.531 1.345-.597c.459-.062 1.032-.062 1.697-.062h.098Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            d="M10.5 8.5a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Home;
