import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Map =  ({
    color = "#45BBD4",
    size = 19,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 19 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M7.29 2.519a7.322 7.322 0 0 0-5.088 6.387h3.547c.025-.868.106-1.723.24-2.533.165-.993.408-1.909.721-2.692a7.36 7.36 0 0 1 .58-1.162ZM9.5.989a8.51 8.51 0 1 0 0 17.021A8.51 8.51 0 0 0 9.5.99Zm0 1.188c-.206 0-.473.1-.784.421-.313.324-.626.832-.903 1.524-.275.688-.499 1.518-.652 2.445a17.762 17.762 0 0 0-.224 2.339h5.127c-.025-.805-.1-1.595-.223-2.339-.154-.927-.377-1.757-.652-2.445-.277-.692-.59-1.2-.904-1.524-.31-.322-.578-.421-.784-.421Zm3.752 6.729a18.958 18.958 0 0 0-.24-2.533c-.165-.993-.408-1.909-.72-2.692a7.36 7.36 0 0 0-.58-1.162A7.325 7.325 0 0 1 16.8 8.906h-3.547Zm-1.188 1.187H6.937c.025.805.1 1.595.224 2.34.153.927.377 1.757.652 2.444.277.692.59 1.2.903 1.524.31.322.578.422.785.422.206 0 .474-.1.784-.422.313-.324.627-.832.903-1.524.275-.687.499-1.517.652-2.445.124-.744.2-1.534.224-2.339Zm-.352 6.388a7.36 7.36 0 0 0 .58-1.163c.312-.783.555-1.698.72-2.692.134-.81.215-1.665.24-2.533H16.8a7.322 7.322 0 0 1-5.087 6.388Zm-4.423 0a7.36 7.36 0 0 1-.579-1.163c-.313-.783-.556-1.698-.72-2.692a18.971 18.971 0 0 1-.241-2.533H2.202a7.323 7.323 0 0 0 5.087 6.388Z"
                scale={pathScale / 1}
                fillRule="evenodd"
                clipRule="evenodd"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h19v19H0z" />
            </clipPath>
        </defs>
    </svg>;
};
export default Map;
