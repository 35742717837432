import {
    IOCoreLocale,
    IOCoreTheme,
    Sticker,
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    BoostedObjectTypes,
} from "./types";

const BoostsTypeStickers = ({
    titleColor,
    title,
    color,
    style,
    icon,
    itemKey
}: BoostedObjectTypes) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();


    const renderSticker  = () => {
        return <Sticker
            title={title}
            titleColor = {titleColor}
            color={color}
            icon={icon}
            key={itemKey}
            spreadBehaviour="free"
            style={{
                ...style
            }}
        />;
    };


    return <div
        className={classes.container}
        style={{
            marginLeft: spaces.container / 2
        }}
    >
        {renderSticker()}
    </div>;
};
export default BoostsTypeStickers;