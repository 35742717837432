import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    Text
} from "isinolacak-web-cl";
import {
    BankInfoCardProps 
} from "./types";
import {
    CopyIcon 
} from "../../assets/svgr";

const BankInfoCard = ({
    isSelected,
    onChange,
    title,
    style,
    icon,
    iban
}: BankInfoCardProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                padding: spaces.container,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
            }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: icon
                }}
            ></div>
            <RadioButton
                isSelected={isSelected}
            />
        </div>;
    };

    const renderVerticalLine = () => {
        return <div
            className={classes.lineVr}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderIBAN = () => {
        return <div
            className={classes.ibanContainer}
            style={{
                paddingTop: spaces.inline,
                paddingBottom: spaces.inline,
                paddingLeft: spaces.container,
                paddingRight: spaces.container,
                gap: spaces.container
            }}
        >
            <div
                className={classes.ibanRow}
                style={{
                    gap: spaces.inline / 2
                }}
            >
                <Text
                    variant='body-medium'
                    color='textGrey'
                >
                    {localize("bank-iban-no")}
                </Text>
                <Text
                    variant='header5-regular'
                    color='textDark'
                >
                    {iban}
                </Text>
            </div>
            {renderVerticalLine()}
            <div
                className={classes.copy}
                onClick={() => {
                    navigator.clipboard.writeText(iban);
                }}
            >
                <CopyIcon size={36} />
                <Text
                    variant='caption-medium'
                    color='textSecondary'
                >
                    {localize("copy")}
                </Text>
            </div>
        </div>;
    };

    const renderBankName = () => {
        return <div
            className={classes.bankNameContainer}
            style={{
                paddingTop: spaces.inline,
                paddingBottom: spaces.inline,
                paddingLeft: spaces.container,
                paddingRight: spaces.container,
                gap: spaces.inline / 2
            }}
        >
            <Text
                variant='body-medium'
                color='textGrey'
            >
                {localize("bank-institution-name")}
            </Text>
            <Text
                variant='header5-regular'
                color='textDark'
            >
                {title}
            </Text>
        </div>;
    };

    const renderHorizontalLine = () => {
        return <div
            className={classes.lineHr}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            marginBottom: spaces.content
        }}
        onClick={() =>{
            onChange();
        }}
    >
        {renderHeader()}   
        {renderBankName()}
        {renderHorizontalLine()}
        {renderIBAN()}
    </div>;
};
export default BankInfoCard;
