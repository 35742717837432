import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    cardHeader: {
        alignItems: "center",
        display: "flex"
    },
    logo: {
        borderStyle: "solid",
        display: "flex"
    }
});
export default useStyles;