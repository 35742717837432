import useStyles from "./styles";
import {
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import {
    CardDataType 
} from "./types";
import {
    useNavigate 
} from "react-router-dom";

const ImageWithTextCard = ({
    image, 
    text,
    id
}: CardDataType) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        radiuses,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    return (
        <div
            onClick={() => navigate(`/blog/${id}`)}
            style={{
                backgroundColor: colors.grey25,
                borderRadius: radiuses.hard,
                margin: spaces.container
            }} 
            className={classes.card}
        >
            <img 
                className={classes.image} 
                src={image} 
                alt={text} 
            />
            <div
                style={{
                    padding: spaces.container / 1.5
                }} 
            >
                <Text>{text}</Text>
            </div>
        </div>
    );
};

export default ImageWithTextCard;