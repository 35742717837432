import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    image: {
        width: "100%",
        maxWidth: 1000
    }
});
export default useStyles;