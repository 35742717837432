import React, {
    useEffect,
    useState
} from "react";
import {
    AccordionCard,
    IOCoreLocale,
    IOCoreTheme
} from "isinolacak-web-cl";
import {
    GetFAQsResponseTypes 
} from "../../../../../../../../services/restAPI/actions/getFAQs/types";
import {
    RESTService 
} from "../../../../../../../../services/restAPI";
import {
    getErrorText 
} from "../../../../../../../../utils";
import {
    AskedQuestionPageProps 
} from "./types";

const AskedQuestion = ({
    setHeaderTitle
}: AskedQuestionPageProps) => {
    const {
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [getFAQs, setGetFAQs] = useState<GetFAQsResponseTypes>();

    useEffect(() => {
        handleGetFAQs();
        setHeaderTitle("asked-questions");
    }, []);

    const handleGetFAQs = () => {
        RESTService.action("GetFAQs", {

        }).then((res) => {
            setGetFAQs(res);
        }).catch((err) => {
            console.error("ERR", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    return <div>
        {getFAQs?.map((item, index) => {
            return <AccordionCard
                content={item.content}
                title={item.title}
                key={index}
                style={{
                    marginBottom: spaces.content
                }}
            />;
        })}
    </div>;
};

export default AskedQuestion;
