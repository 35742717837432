import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        userSelect: "none",
        minHeight: "100%"
    },
    renderHeaderChipContainer: {
        justifyContent: "space-between",
        justifyItems: "center",
        alignContent: "center",
        alignItems: "center",
        alignSelf:"center",
        display: "flex"
    },
    chipContainer: {
        justifyContent: "space-between",
        overflowX: "auto",
        display: "flex",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
        },
        "&::-webkit-scrollbar-button": {
            width: "3px",
            height: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
            border: "0px none #ffffff",
            background: "#45BBD4",
            borderRadius: "100px",
            "&:hover": {
                background: "#1f1f1f",
            },
            "&:active": {
                background: "#45BBD4",
            },
        },
        "&::-webkit-scrollbar-track": {
            border: "0px none #ffffff",
            background: "#E2E2E2",
            borderRadius: "100px",
            "&:hover": {
                background: "#E2E2E2",
            },
            "&:active": {
                background: "#45BBD4",
            },
        },
        "&::-webkit-scrollbar-corner": {
            background: "transparent",
        }
    },
    stateCard: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    cardContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex"
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex"
    }
});
