import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const EmailVerification = ({
    size = 200,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 200 / size;
    return  <svg
        xmlns="http://www.w3.org/2000/svg"
        width="72"
        height="45"
        fill="none"
        viewBox="0 0 72 45"
    >
        <path
            d="M0 15.815l63.406 9.128 8.509 8.586L0 15.815z"
            fill="#45BBD4"
        />
        <path
            d="M0 15.815l63.406 9.128 8.509 8.586L0 15.815z"
            opacity="0.6"
            fill="#fff"
        />
        <path
            d="M0 15.815l63.406 9.128-2.942 12.416L0 15.815z"
            fill="#45BBD4"
        />
        <path
            d="M0 15.815l63.406 9.128-2.942 12.416L0 15.815z"
            opacity="0.2"
            fill="#000"
        />
        <path
            d="M59.162 1.442l3.68 17.245a1.738 1.738 0 01-1.335 2.06l-32.842 7.01a1.742 1.742 0 01-2.061-1.335L22.92 9.177a1.741 1.741 0 011.338-2.06L57.101.106a1.741 1.741 0 012.061 1.338v-.003z"
            fill="#45BBD4"
        />
        <path
            d="M59.162 1.442l3.68 17.245a1.738 1.738 0 01-1.335 2.06l-32.842 7.01a1.742 1.742 0 01-2.061-1.335L22.92 9.177a1.741 1.741 0 011.338-2.06L57.101.106a1.741 1.741 0 012.061 1.338v-.003z"
            opacity="0.4"
            fill="#000"
        />
        <path
            d="M58.353.312L47.38 13.782a7.073 7.073 0 01-8.664 1.85L23.2 7.817a1.73 1.73 0 011.06-.704L57.1.103a1.733 1.733 0 011.252.21zM0 15.815l52.298 12.43 8.166 9.114L0 15.815z"
            fill="#45BBD4"
        />
        <path
            d="M0 15.815l52.298 12.43 8.166 9.114L0 15.815z"
            opacity="0.6"
            fill="#fff"
        />
        <path
            d="M19.077 27.795l-.14.24 5.82 3.41.14-.24-5.82-3.41zM27.466 32.71l-.14.24 2.093 1.225.14-.24-2.093-1.225zM0 15.815l52.298 12.43-10.166 12.251L0 15.815zM69.951 44.934a.555.555 0 01-.206-.039l-8.516-3.37a.556.556 0 01.409-1.035l8.519 3.37a.556.556 0 01-.206 1.074z"
            fill="#45BBD4"
        />
        <path
            d="M69.951 44.934a.555.555 0 01-.206-.039l-8.516-3.37a.556.556 0 01.409-1.035l8.519 3.37a.556.556 0 01-.206 1.074z"
            opacity="0.3"
            fill="#000"
        />
        <path
            d="M69.448 40.054a.53.53 0 01-.203-.041l-4.576-1.822a.554.554 0 01-.198-.907.556.556 0 01.607-.128l4.575 1.813a.556.556 0 01.315.72.557.557 0 01-.52.365z"
            fill="#45BBD4"
        />
        <path
            d="M69.448 40.054a.53.53 0 01-.203-.041l-4.576-1.822a.554.554 0 01-.198-.907.556.556 0 01.607-.128l4.575 1.813a.556.556 0 01.315.72.557.557 0 01-.52.365z"
            opacity="0.5"
            fill="#fff"
        />
        <path
            d="M59.883 44.865a.583.583 0 01-.204-.04l-8.308-3.298a.554.554 0 01-.198-.906.556.556 0 01.607-.129l8.308 3.288a.557.557 0 01-.206 1.073v.012z"
            fill="#45BBD4"
        />
        <path
            d="M59.883 44.865a.583.583 0 01-.204-.04l-8.308-3.298a.554.554 0 01-.198-.906.556.556 0 01.607-.129l8.308 3.288a.557.557 0 01-.206 1.073v.012z"
            opacity="0.3"
            fill="#000"
        />
    </svg>;
};
export default EmailVerification;




