import {
    useEffect,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme, 
    RadioButton,
    SelectBox,
    TextInput,
    CheckBox,
    Loading,
    Text
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    MagnifierIcon
} from "../../../../../assets/svgr";
import {
    IFiltersContainerProps
} from "./types";
import {
    DISABLED_CANDIDATES_FILTERS,
} from "../../constants";
import {
    GetCapabilitiesRequestType,
    GetCapabilitiesObjectType
} from "../../../../../services/restAPI/actions/getCapabilities/types";
import {
    GetForeignLanguagesPayloadObjectType
} from "../../../../../services/restAPI/actions/getForeignLanguages/types";
import {
    CitiesResponseObjectType,
} from "../../../../../services/restAPI/actions/getCities/types";
import {
    EducationLevelsObjectType    
} from "../../../../../services/restAPI/actions/getEducationLevels/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../services/restAPI/actions/getOccupation/types";
import {
    SelectedForeignLanguageType,
    SelectedEducationLevelType,
    SelectedCapabilitiesType,
    SelectedOccupationType,
} from "../../types";

const FiltersContainer = ({
    setSelectedForeignLanguage,
    setIsCandidatesForDisabled,
    setSelectedEducationLevel,
    isCandidatesForDisabled,
    selectedForeignLanguage,
    setSelectedCapabilities,
    selectedEducationLevel,
    setSelectedOccupation,
    selectedCapabilities,
    selectedOccupation,
    setSelectedCities,
    selectedCities,
}: IFiltersContainerProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [foreignLanguagesData, setForeignLanguagesData] = useState<Array<GetForeignLanguagesPayloadObjectType>>([]);
    const [educationLevelData, setEducationLevelData] = useState<Array<EducationLevelsObjectType>>([]);
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [capabilitiesData, setCapabilitiesData] = useState<Array<GetCapabilitiesObjectType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);

    //Occupation Pagination States
    const [capabilitiesRenderData, setCapabilitiesRenderData] = useState(capabilitiesData);
    const [foreignLanguagesEndOfData, setForeignLanguagesEndOfData] = useState(false);
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchCapabilitiesPage, setSearchCapabilitiesPage] = useState<number>(1);
    const [educationLevelEndOfData, setEducationLevelEndOfData] = useState(false);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [capabilitiesEndOfData, setCapabilitiesEndOfData] = useState(false);
    const [searchCapabilities, setSearhCapabilities] = useState<string>("");
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [capabilitiesLoading, setCapabilitiesLoading] = useState(true);
    const [capabilitiesPage, setCapabilitiesPage] = useState<number>(1);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(true);
    const [occupationPage, setOccupationPage] = useState<number>(1);

    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getForeignLanguages();
        getEducationLevel();
        getCapabilities();
        getOccupations();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);
    
    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    useEffect(() => {
        if(searchCapabilities && searchCapabilities.length) {
            let newData = JSON.parse(JSON.stringify(capabilitiesData));
            newData = newData.filter((item: GetCapabilitiesObjectType) => item.localizedText.match(new RegExp(searchCapabilities, "gi")));
            setCapabilitiesRenderData(newData);
        } else {
            setCapabilitiesRenderData(capabilitiesData);
        }
    }, [searchCapabilities, capabilitiesData]);

    // Helper Functions
    const onSearchOccupation = (e: string) => {
        const timer = setTimeout(() => {
            if (e && e.length) {
                let params: GetOccupationRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: searchOccupationPage,
                    search: e
                };
                getOccupations(params);
                setOccupationLoading(true);
            }
            if(!e.length) {
                let params: GetOccupationRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: occupationPage
                };
                getOccupations(params);
                setOccupationLoading(true);
            }
        }, 750);
        return () => clearTimeout(timer);
    };

    const onSearchCapabilities = (e: string) => {
        const timer = setTimeout(() => {
            if (e && e.length) {
                let params: GetCapabilitiesRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: searchCapabilitiesPage,
                    search: e
                };
                getCapabilities(params);
                setCapabilitiesLoading(true);
            }
            if(!e.length) {
                let params: GetCapabilitiesRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: occupationPage
                };
                getCapabilities(params);
                setCapabilitiesLoading(true);
            }
        }, 750);
        return () => clearTimeout(timer);
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e.occupationID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    ...item,
                    occupationID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    occupationID: item._id
                }
            ]);
        }
    };

    const onChangeCapabilities = (item: GetCapabilitiesObjectType) => {
        if(selectedCapabilities.length) {
            let _selectedCapabilities: Array<SelectedCapabilitiesType> = JSON.parse(JSON.stringify(selectedCapabilities));

            const isExistsInSelectedData = selectedCapabilities.findIndex((e: SelectedCapabilitiesType) => e.capabilitiesID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCapabilities.splice(isExistsInSelectedData, 1);
                setSelectedCapabilities(_selectedCapabilities);
                window.localStorage.setItem("setSelectedCapabilities",JSON.stringify(_selectedCapabilities));
            } else {
                _selectedCapabilities.push({
                    ...item,
                    capabilitiesID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCapabilities(_selectedCapabilities);
                window.localStorage.setItem("setSelectedCapabilities",JSON.stringify(_selectedCapabilities));
            }
        } else {
            setSelectedCapabilities([
                {
                    localizedText: item.localizedText,
                    capabilitiesID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedCapabilities",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    capabilitiesID: item._id
                }
            ]));
        }
    };

    const onChangeEducationLevel = (item: EducationLevelsObjectType) => {
        if(selectedEducationLevel.length) {
            let _selectedEducationLevel: Array<SelectedEducationLevelType> = JSON.parse(JSON.stringify(selectedEducationLevel));

            const isExistsInSelectedData = selectedEducationLevel.findIndex((e: SelectedEducationLevelType) => e.educationLevelID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedEducationLevel.splice(isExistsInSelectedData, 1);
                setSelectedEducationLevel(_selectedEducationLevel);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedEducationLevel));
            } else {
                _selectedEducationLevel.push({
                    ...item,
                    educationLevelID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedEducationLevel(_selectedEducationLevel);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedEducationLevel));
            }
        } else {
            setSelectedEducationLevel([
                {
                    localizedText: item.localizedText,
                    educationLevelID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedEducation",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    educationLevelData: item._id
                }
            ]));
        }
    };

    const onChangeForeignLanguage = (item: GetForeignLanguagesPayloadObjectType) => {
        if(selectedForeignLanguage.length) {
            let _selectedForeignLanguages: Array<SelectedForeignLanguageType> = JSON.parse(JSON.stringify(selectedForeignLanguage));

            const isExistsInSelectedData = selectedForeignLanguage.findIndex((e: SelectedForeignLanguageType) => e.foreignLanguageID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedForeignLanguages.splice(isExistsInSelectedData, 1);
                setSelectedForeignLanguage(_selectedForeignLanguages);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedForeignLanguages));
            } else {
                _selectedForeignLanguages.push({
                    ...item,
                    foreignLanguageID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedForeignLanguage(_selectedForeignLanguages);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedForeignLanguages));
            }
        } else {
            setSelectedForeignLanguage([
                {
                    localizedText: item.localizedText,
                    foreignLanguageID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedEducation",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    foreignLanguageData: item._id
                }
            ]));
        }
    };

    // Request Functions
    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getEducationLevel = () => {
        RESTService.action("GetEducationLevels",{
            language: _langugage ?? "tr-TR",
        }).then((res) => {
            setEducationLevelData(res.payload);
            if(res.payload.length === 0 || res.payload.length < 20) {
                setEducationLevelEndOfData(true);
            }
        }).catch((err) => {
            console.error("GetEducationLevels Err:", err);
        });
    };

    const getForeignLanguages = () => {
        RESTService.action("GetForeignLanguages",{
            language: _langugage ?? "tr-TR",
        }).then((res) => {
            setForeignLanguagesData(res.payload);
            if(res.payload.length === 0 || res.payload.length < 20) {
                setForeignLanguagesEndOfData(true);
            }
        }).catch((err) => {
            console.error("GetForeignLanguages Err:", err);
        });
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            language: _langugage ?? "tr-TR",
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
                /*  if(res.payload.length === 0 || res.payload.length < 20) {
                    setOccupationEndOfData(true);
                } */
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const getCapabilities = (capabilitiesParams ?:GetCapabilitiesRequestType) => {
        let params: GetCapabilitiesRequestType = {
            language: _langugage ?? "tr-TR",
            page:  capabilitiesPage,
        };

        if(capabilitiesParams) {
            params = capabilitiesParams;
        }

        RESTService.action("GetCapabilities",params)
            .then((res) => {
                let _getCapabilities = JSON.parse(JSON.stringify(capabilitiesData));
                let newCapabilities = res.payload.filter(newItem => !_getCapabilities.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setCapabilitiesData([..._getCapabilities, ...newCapabilities]);

                if(capabilitiesParams?.search && capabilitiesParams.search.length){
                    setSearchCapabilitiesPage(e => e + 1);
                    setCapabilitiesPage(1);
                } else {
                    setCapabilitiesPage(e => e + 1);
                    setSearchCapabilitiesPage(1);
                }

                if(res.payload.length === 0 || res.payload.length < 20) {
                    setCapabilitiesEndOfData(true);
                }
                setCapabilitiesLoading(false);
            })
            .catch((err) => {
                console.error("GetCapabilities Err:", err);
            });
    };

    //Render Functions
    const renderCities = () => {
        let initialCities: Array<CitiesResponseObjectType>| [] = selectedCities.map(e => {
            return {
                localizedText: e.localizedText,
                key: e.cityID,
                _id: e.cityID
            };
        });
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                paddingTop: spaces.container * 1.75,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                gap: spaces.container * 1.25,
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
            >
                {localize("location")}
            </Text>
            <SelectBox
                titleExtractor={(e) => e.localizedText}
                initialSelectedItems={initialCities}
                inputTitle={localize("location")}
                title={localize("location")}
                keyExtractor={(e) => e._id}
                spreadBehaviour='stretch'
                isNeedConfirm={true}
                isSearchable={true}
                emptyContent={() => {
                    return <Text>
                        {localize("no-result-found")}
                    </Text>;
                }}
                multiSelect={true}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let _cities: Array<{localizedText: string, cityID: string}> = selectedItems.map((item) => {
                        return { //@ts-ignore
                            localizedText: item.localizedText,
                            //@ts-ignore
                            cityID: item._id
                        };
                    });

                    setSelectedCities(_cities);
                    closeSheet();
                    onSuccess();
                }}
                data={citiesData}
            />
        </div>;
    };

    const renderOccupations = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("job")}
            </Text>
            <TextInput
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                placeholder={localize("search-job")}
                onChangeText={(e) => {
                    setOccupationLoading(true);
                    setSearchOccupationPage(1);
                    setSearhOccupation(e);
                    onSearchOccupation(e);
                }}
            />
            <div
                className={classes.occupationContent}
                onScroll={(e) => {
                    if(occupationLoading) {
                        return;
                    }
                    if(occupationEndOfData) {
                        return;
                    }
                    if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                        if(searchOccupation.length) {
                            onSearchOccupation(searchOccupation);
                        } else {
                            getOccupations();
                        }
                    }
                }}
            >
                {
                    occupationRenderData.map((item) => {
                        let isSelected = false;

                        if(selectedOccupation.length) {
                            isSelected =  selectedOccupation.findIndex((e) => e.occupationID === item._id) !== -1;
                        }

                        return <CheckBox
                            title={item.localizedText}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeOccupation(item);
                            }}
                            key={item._id}
                        />;
                    })
                }
                {
                    occupationLoading ? <Loading/> : null
                }
            </div>
        </div>;
    };

    const renderCapabilities = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("skill")}
            </Text>
            <TextInput
                placeholder={localize("search-skill")}
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                onChangeText={(e) => {
                    setCapabilitiesLoading(true);
                    setSearchCapabilitiesPage(1);
                    setSearhCapabilities(e);
                    onSearchCapabilities(e);
                }}
                style={{
                    padding: "0px"
                }}
            />
            <div
                className={classes.occupationContent}
                onScroll={(e) => {
                    if(capabilitiesLoading) {
                        return;
                    }
                    if(capabilitiesEndOfData) {
                        return;
                    }
                    setCapabilitiesLoading(true);
                    if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                        if(searchCapabilities.length) {
                            onSearchCapabilities(searchCapabilities);
                        } else {
                            getCapabilities();
                        }
                    }
                }}
            >
                {
                    capabilitiesRenderData.map((item) => {
                        let isSelected = false;
                        if(selectedCapabilities.length) {
                            isSelected =  selectedCapabilities.findIndex((e:any) => e.capabilitiesID === item._id) !== -1;
                        }

                        return <CheckBox
                            title={item.localizedText}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeCapabilities(item);
                            }}
                            key={item._id}
                        />;
                    })
                }
                {
                    capabilitiesLoading ? <Loading/> : null
                }
            </div>
        </div>;
    };

    const renderEducationLevels = () => {
        return <div
            className={classes.educationLevelContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("experience-level")}
            </Text>
            <div
                className={classes.educationLevelContent}
                onScroll={(e) => {
                    if(educationLevelEndOfData) {
                        return;
                    }
                    getEducationLevel();
                }}
            >
                {
                    educationLevelData.map((item) => {
                        let isSelected = false;

                        if(selectedEducationLevel.length) {
                            isSelected =  selectedEducationLevel.findIndex((e:any) => e.educationLevelID === item._id) !== -1;
                        }

                        return <CheckBox
                            title={item.localizedText}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeEducationLevel(item);
                            }}
                            key={item._id}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderForeignLanguage = () => {
        return <div
            className={classes.educationLevelContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("foreign-language")}
            </Text>
            <div
                className={classes.educationLevelContent}
                onScroll={(e) => {
                    if(foreignLanguagesEndOfData) {
                        return;
                    }
                    getForeignLanguages();
                }}
            >
                {
                    foreignLanguagesData.map((item) => {
                        let isSelected = false;

                        if(selectedForeignLanguage.length) {
                            isSelected =  selectedForeignLanguage.findIndex((e:SelectedForeignLanguageType) => e.foreignLanguageID === item._id) !== -1;
                        }

                        return <CheckBox
                            title={item.localizedText}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeForeignLanguage(item);
                            }}
                            key={item._id}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderDisabledCandidates = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container * 1.25,
                paddingLeft: spaces.container * 1.25,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                borderBottomWidth: borders.line,
                paddingTop: spaces.container
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("disable-candidate")}
            </Text>
            {
                DISABLED_CANDIDATES_FILTERS.map((item:any) => {
                    let isSelected = false;

                    if(isCandidatesForDisabled) {
                        isSelected =  isCandidatesForDisabled === item.value ? true : false;
                    }

                    return <RadioButton
                        title={localize(item.localizedText)}
                        isSelected={isSelected}
                        key={item.id}
                        onChange={() => {
                            setIsCandidatesForDisabled(item.value);
                            window.localStorage.setItem("setIsCandidatesForDisabled",item.value);
                        }}
                    />;
                })
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.white,
            borderRadius: radiuses.hard,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderCities()}
        {renderEducationLevels()}
        {renderOccupations()}
        {renderCapabilities()}
        {renderForeignLanguage()}
        {renderDisabledCandidates()}
    </div>;
};

export default FiltersContainer;
