import {
    Fragment,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    Loading,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    VerifiedCheckIcon,
    RadarIcon,
    ShareIcon
} from "../../../../assets/svgr";
import {
    AdvertCard,
    Footer
} from "../../../../components";
import {
    RESTService
} from "../../../../services/restAPI";
import {
    GetAdvertWithDetailResponseType
} from "../../../../services/restAPI/actions/getAdvertsWithDetail/types";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import {
    JobDetailsTypes,
    PAQAnswerType
} from "./types";
import Info from "../../../../assets/svgr/infoIcon";
import PAQ from "./views/paq";
import {
    getErrorText 
} from "../../../../utils";
import {
    StickerObjectType 
} from "../../../../components/advertCardForEmployer/types";
import IsinolacakPlacholder from "../../../../assets/img/isinolacakMiniWithPadding.png";

const WorkerJobDetails = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        state
    } = useLocation();

    const urlParams = useParams();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [advertData, setAdvertData] = useState<GetAdvertWithDetailResponseType>();
    const [views, setViews] = useState<JobDetailsTypes>("job-detail");
    const [PAQAnswers, setPAQAnswers] = useState<PAQAnswerType>([]);
    const [isShareVisible,setIsShareVisible] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isVisible,setIsVisible] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shareURL, setShareURL] = useState("");
    const [ID, setID] = useState("");

    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
  
    useEffect(() => {
        if(state) {
            setID(state.jobID);
            setShareURL(`${window.location.origin}/share/jobDetails/${state.jobID}`);
            handleGetAdvertWiltDetail(state.jobID);
        } else if (urlParams.id) {
            setID(urlParams.id);
            setShareURL(`${window.location.origin}/share/jobDetails/${urlParams.id}`);
            handleGetAdvertWiltDetail(urlParams.id);
        } else {
            navigate("/notFound");
        }
    }, []);

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const handleGetAdvertWiltDetail = (_jobID:string) => {
        setLoading(true);
        RESTService.action("GetAdvertWithDetail", {
            advertID: _jobID
        })
            .then((res) => {
                setAdvertData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetAdvertWithDetail Err:", err);
                setLoading(false);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const applyToAdvert = (id?:string) => {
        setButtonLoading(true);
        RESTService.action("ApplyToAdvert", {
            advertID: advertData?._id ? advertData?._id : ID
        }).then((res) => {
            alert(localize("aplied-ad-content"));
            navigate("/app/MyActions");
            /* if(res) {
                setIsVisible(true);
                let _data: GetAdvertWithDetailResponseType = JSON.parse(JSON.stringify(advertData));
                _data.isApplied = true;
                setAdvertData(_data);
            } */
        }).catch((err) => {
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
            console.error("ApplyToAdvert Err:", err);
        }).finally(() => {
            setButtonLoading(false);
        });
    };

    const addToFavorite = (id: string) => {
        RESTService.action("AddAdvertToFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data:GetAdvertWithDetailResponseType = JSON.parse(JSON.stringify(advertData));
                const currentIndex = _data?.recommendedAdverts.findIndex(item => {
                    return item._id === id;
                });

                _data.recommendedAdverts[currentIndex].isFavorite = true;
                if(currentIndex !== -1) {
                    setAdvertData(_data);
                }
            })
            .catch((err) => {
                console.error("AddAdvertToFavorite Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const removeFromFavorite = (id: string) => {
        RESTService.action("RemoveAdvertFromFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data:GetAdvertWithDetailResponseType = JSON.parse(JSON.stringify(advertData));
                const currentIndex = _data?.recommendedAdverts.findIndex(item => {
                    return item._id === id;
                });

                _data.recommendedAdverts[currentIndex].isFavorite = false;

                if(currentIndex !== -1) {
                    setAdvertData(_data);
                }
            })
            .catch((err) => {
                console.error("RemoveAdvertFromFavorite Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderEmptCompanyInfo = () => {
        return <div
            className={classes.emptyCompanyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.inline
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderEmptyOTheAdvertsInfo = () => {
        return <div
            className={classes.emptyCompanyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.inline
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderHeaderJobDetailCard = () => {
        return <div
            style={{
                padding: spaces.content
            }}
        >
            <div
                className={classes.headerContainer}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {advertData?.occupation.localizedText}
                </Text>
                <div
                    onClick={() => {
                        navigator.clipboard.writeText(shareURL);
                        setIsShareVisible(true);
                    }}
                    className={classes.icon}
                    style={{
                        borderRadius: spaces.containerXXLarge,
                        backgroundColor: colors.white,
                        borderColor: colors.stroke,
                        padding: spaces.inline / 2,
                        borderWidth: borders.line
                    }}
                >
                    <ShareIcon 
                        size={18}
                    />
                </div>
            </div>
            <div
                className={classes.headerContainerIcon}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <img
                    src={advertData?.companyInfo.photoURL ? advertData?.companyInfo.photoURL : IsinolacakPlacholder}
                    className={classes.companyHeaderLogo}
                    style={{
                        borderRadius: radiuses.half,
                        marginRight: spaces.content,
                        borderColor: colors.stroke,
                        borderWidth: borders.line
                    }}
                    alt="Profile"
                />
                <div
                    className={classes.textContent}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2
                        }}
                    >
                        {advertData?.companyInfo.title}
                    </Text>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {`${advertData?.experienceLevel.localizedText} • ${advertData?.contractType.localizedText} • ${advertData?.city.localizedText} (${advertData?.workingType.localizedText})`}
                    </Text>
                </div>
            </div>
            {renderBoosts()}
        </div>;
    };

    const renderBoosts = () => {
        if(!advertData?.boosted.length) {
            return null;
        }

        return <div
            className={classes.sticker}
            style={{
                gap: spaces.container
            }}
        >
            {
                advertData.boosted.map((item, index) => {
                    return  <Sticker
                        key={`boosted-${index}`}
                        title={item.localizedText}
                        color={item.backColor}
                        titleColor={item.color}
                        icon={() => <div
                            dangerouslySetInnerHTML={{
                                __html: item.icon
                            }}
                        ></div>}
                    />;
                })
            }
        </div>;
    };
    
    const renderJobDetailAbout = () => {
        return <div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("about-the-job-posting")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content * 1,
                    marginBottom: spaces.content 
                }}>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {advertData?.description}
                </Text>
            </div>
        </div>;
    };
    
    const renderJobDetailApplicationProcess = () => {
        return <div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("application-process")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content * 1,
                    marginBottom: spaces.content 
                }}>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {localize("application-process-text")}
                </Text>
            </div>
        </div>;
    };

    const renderApplicationButton = () => {
        return <div
            style={{
                marginTop: spaces.contentXLarge
            }}>
            <Button
                title={advertData?.isApplied ? localize("manage-application") : localize("apply")}
                loading={!advertData?.isApplied || !advertData.isHavePAQ ? buttonLoading : false}
                color="secondary"
                onClick={() => {
                    if(advertData?.isApplied) {
                        navigate("/app/applicationManagement", {
                            state:{
                                applicationID: advertData.applicationID
                            }
                        });
                        return;
                    } else if (advertData?.isHavePAQ) {
                        setViews("paq");
                    } else {
                        applyToAdvert(advertData?._id);
                    } 
                }}
                spreadBehaviour="stretch"
            />
        </div>;
    };

    const renderSimilarJobPostings = () => {
        return <div
            style={{
                marginTop: spaces.contentXLarge,
                marginBottom: spaces.contentXLarge
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("similar-job-postings")}
                </Text>
            </div>
            <div className={classes.advertCartContainer}>
                {advertData?.recommendedAdverts && advertData.recommendedAdverts.length ? advertData?.recommendedAdverts.map((item) => {
                    let stickers: Array<StickerObjectType> = [];
                    item.boosted.forEach((boost) => {
                        stickers.push({
                            localizedText: boost.localizedText,
                            backColor: boost.backColor,
                            color: boost.color,
                            icon: () => <div
                                dangerouslySetInnerHTML={{
                                    __html: boost.icon
                                }}
                            ></div>,
                            key: boost.key
                        });
                    });
                    
                    return  <AdvertCard
                        experienceLevel={item.experienceLevel.localizedText}
                        jobImage={item.companyInfo.photoURL}
                        contractType={item.contractType.localizedText}
                        workingType={item.workingType.localizedText}
                        companyName={item.companyInfo.title}
                        title={item.occupation.localizedText}
                        city={item.city.localizedText}
                        isSaved={item.isFavorite}
                        stickers={stickers}
                        onClickBookMark={() => {
                            if(item.isFavorite) {
                                removeFromFavorite(item._id);
                            } else {
                                addToFavorite(item._id);
                            }
                        }}
                        onClick={() => {
                            handleGetAdvertWiltDetail(item._id);
                        }}
                        style={{
                            minWidth: "100%"
                        }}
                        actionOnClick={() =>{
                            navigate("/app/applicationManagement", {
                                state: {
                                    applicationID: item._id
                                }
                            });
                        }}
                    />;
                })
                    :
                    renderEmptyOTheAdvertsInfo()
                }
            </div>
        </div>;
    };
    
    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2
            }}
        >
            <div
                className={classes.companyAboutHeader}
                style={{
                    marginBottom: spaces.contentXLarge
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("company-about")}
                </Text>
            </div>
            <img
                src={advertData?.companyInfo.photoURL ? advertData?.companyInfo.photoURL : IsinolacakPlacholder}
                className={classes.companyLogo}
                style={{
                    borderRadius: radiuses.hard * 20,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
                alt="Profile"
            />
            <div
                onClick={() => navigate("/app/profileUser", {
                    state: {
                        companyProfileID: advertData?.userID
                    }
                })}
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {advertData?.companyInfo.title}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.content
                }}>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {`${advertData?.companyInfo.city.localizedText} •  ${advertData?.companyInfo.sector.localizedText}`}
                </Text>
            </div>
            {renderLine()}
            <div
                className={classes.companyAboutContainer}
            >
                <div
                    style={{
                        marginTop: spaces.content
                    }}
                >
                    <Text
                        variant="body-medium"
                        color="textDark"
                    >
                        {localize("company-ınformation")}
                    </Text>
                </div>
                <div
                    style={{
                        marginTop: spaces.content * 1,
                        marginBottom: spaces.content
                    }}>
                    <div
                        style={{
                            marginBottom: spaces.contentXLarge
                        }}
                    >
                        {
                            advertData?.companyInfo.about && advertData.companyInfo.about.length ?
                                <Text
                                    variant="body4-regular"
                                    color="textGrey"
                        
                                >
                                    {advertData?.companyInfo.about}
                                </Text> 
                                :
                                renderEmptCompanyInfo()
                        }
                        
                    </div>
                    <Button
                        title={localize("go-company-page")}
                        spreadBehaviour="stretch"
                        size="large"
                        onClick={() => {
                            navigate("/app/profileUser", {
                                state: {
                                    companyID: advertData?.companyInfo._id
                                }
                            });
                        }}
                    />
                </div>
            </div>
        </div>;
    };
    
    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
            }}
        >
            {views === "job-detail" && <div>
                {renderHeaderJobDetailCard()}
                {renderLine()}
                {renderJobDetailAbout()}
                {renderJobDetailApplicationProcess()}
                {renderApplicationButton()}
                {renderSimilarJobPostings()}
            </div>}
            {views === "paq" && <div>
                <PAQ
                    getAdvertWithDetailData={advertData} 
                    setPAQAnswers={setPAQAnswers}
                    setIsVisible={setIsVisible}
                    PAQAnswers={PAQAnswers}
                    setViews={setViews}
                    jobID={ID}
                />
            </div>}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    const renderPageModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            onOverlayPress={() => {
                setIsVisible(false);
                navigate("/app/MyActions");
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.contentLarge
                    }}
                >
                    <div
                        style={{
                            marginBottom: spaces.content
                        }}
                    >
                        <VerifiedCheckIcon
                            color={colors.success}
                            size={68}
                        />
                    </div>
                    <div
                        className={classes.pageModalContainer}
                        style={{
                            marginBottom: spaces.content * 3
                        }}
                    >
                        <Text
                            variant="header5-medium"
                        >
                            {localize("aplied-ad")}
                        </Text>
                        <Text
                            variant="body-regular"
                            color="textSecondary"
                        >
                            {localize("aplied-ad-content")}
                        </Text>
                    </div>
                    <Button
                        title={localize("IOCoreDefaultDialogPrimaryButtonTitle")}
                        onClick ={
                            () => {
                                setIsVisible(false);
                                navigate("/app/MyActions");
                            }
                        }
                        spreadBehaviour="stretch"
                        color="primary"
                        size="large"
                    />
                </div>
            }
            isVisible={isVisible}
        />;
    };

    const renderSuccesShareModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            onOverlayPress={() => {
                setIsShareVisible(false);
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.contentLarge
                    }}
                >
                    <div
                        style={{
                            marginBottom: spaces.content
                        }}
                    >
                        <VerifiedCheckIcon
                            color={colors.success}
                            size={68}
                        />
                    </div>
                    <div
                        className={classes.pageModalContainer}
                        style={{
                            marginBottom: spaces.content * 3
                        }}
                    >
                        <Text
                            variant="header5-medium"
                        >
                            {localize("advert-link-copied")}
                        </Text>
                        <Text
                            variant="body-regular"
                            color="textSecondary"
                        >
                            {localize("advert-link-copied-content")}
                        </Text>
                    </div>
                    <Button
                        title={localize("IOCoreDefaultDialogPrimaryButtonTitle")}
                        onClick ={
                            () => {
                                setIsShareVisible(false);
                            }
                        }
                        spreadBehaviour="stretch"
                        color="primary"
                        size="large"
                    />
                </div>
            }
            isVisible={isShareVisible}
        />;
    };

    if(loading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <Fragment>
        <div
            className={classes.mainContainer}
            style={{
                padding: `${spaces.container * 3}px ${!isSmall ? spaces.container * 8 : 0}px`,
                backgroundColor: colors.layer3,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("job-details")}
            </Text>
            <div
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                {renderLeftContainer()}
                {renderContentContainer()}
                {renderRightContainer()}
            </div>
            {renderPageModal()}
            {renderSuccesShareModal()}
        </div>
        <Footer/>
    </Fragment>;
};

export default WorkerJobDetails;
