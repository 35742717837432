import {
    ReactNode
} from "react";
import MultiSelect from "./views/multiSelect";
import TwoOptions from "./views/twoOptions";
import FivePoints from "./views/fivePoints";
import TenPoints from "./views/tenPoints";
import {
    AnswerChildObjectType,
    QuestionObjectType,
    AnswerObjectType,
    TestObjectType,
    AnswerTypes
} from "./types";

export const ANSWER_RENDER_DATA: Record<AnswerTypes, (props: AnswerChildObjectType) => ReactNode> = {
    twoOptions: (props) => <TwoOptions
        {...props}
    />,
    fiveStarIndicator: (props) =>  <FivePoints
        {...props}
    />,
    multiSelect: (props) => <MultiSelect
        {...props}
    />,
    tenStarIndicator: (props) => <TenPoints
        {...props}
    />,
    text: (props) => null,
};

export const ANSWER_TYPES: Array<AnswerObjectType> = [
    {
        titleKey: "2-option-answer",
        key: "twoOptions",
        previewURL:"",  //TODO require("../../../../assets/image/twoOptions.png"),
        attributes: {
            expectationIndex: 0,
            inputs: [
                {
                    titleKey: "first-option",
                    localizedValue: "yes"
                },
                {
                    titleKey: "second-option",
                    localizedValue: "no"
                }
            ]
        }
    },
    {
        titleKey: "5-point-answer",
        key: "fiveStarIndicator",
        previewURL:"",  //TODO require("../../../../assets/image/pointsToFive.png"),
        attributes: {
            expectationIndex: 0,
            inputs: []
        }
    },
    {
        titleKey: "10-point-answer",
        key: "tenStarIndicator",
        previewURL:"",  //TODO require("../../../../assets/image/pointsToFive.png"),
        attributes: {
            expectationIndex: 0,
            inputs: []
        }
    },
    {
        titleKey: "multi-select-answer",
        key: "multiSelect",
        previewURL:"",  //TODO require("../../../../assets/image/multiple.png"),
        attributes: {
            isIdealInputConfirm: false,
            idealInput: [],
            inputs: [
                {
                    isValueConfirmed: false,
                    titleAttr: 1,
                    value: "",
                },
                {
                    isValueConfirmed: false,
                    titleAttr: 2,
                    value: ""
                }
            ]
        }
    },
    {
        titleKey: "open-ended-answer",
        key: "text",
        previewURL:"",  //TODO :require("../../../../assets/image/comment.png"),
        attributes: {
            inputs: [
                {
                    titleKey: "open-ended-answer",
                    value: ""
                }
            ]
        }
    }
];

export const QUESTION_DATA: Array<QuestionObjectType> = [
    {
        question: "",
        description: "",
        isQuesitionSaved: false,
        answerPayload: ANSWER_TYPES[0]
    }
];

export const TEST_DATA: TestObjectType = {
    title: "",
    questions: QUESTION_DATA
};
