import IDropDownMenuProps from "../dropdownMenu/type";

export const CONNECTIONS: IDropDownMenuProps["data"] = [
    {
        path: "/aboutUs", titleKey: "about-us" 
    },
    {
        path: "/contactUs", titleKey: "contact"
    },
    {
        path: "/agreement/user-policy", titleKey: "terms-of-use"  
    },
    {
        path: "/faq", titleKey: "faq" 
    }
];
  
export const SERVICES: IDropDownMenuProps["data"] = [
    {
        path: "/search", titleKey: "jobAdverts" 
    },
    {
        path: "/auth/register", titleKey: "findEmployee"  
    },
    {
        path: "/blog", titleKey: "blog"  
    }
];
  
export const JOB_ADVERTS: IDropDownMenuProps["data"] = [
    {
        titleKey: "istanbul-job-listings", 
        path: "/search", 
        href: "İstanbul"  
    },
    {
        titleKey: "ankara-job-listings", 
        path: "/search", 
        href: "Ankara"    
    },
    {
        titleKey: "bursa-job-listings", 
        path: "/search", 
        href: "Bursa"    
    },
    {
        titleKey: "antalya-job-listings",
        path: "/search", 
        href: "Antalya"    
    }
];