import React, {
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CompanyMailTypes
} from "./types";
import {
    RoundArrowIcon 
} from "../../../../../../../../assets/svgr";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    getErrorText 
} from "../../../../../../../../utils";

const ChangeWorkerMail = ({
    personalInfo,
    setErrors,
    setViews,
    setToken,
    errors
}: CompanyMailTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();
    
    const [loadingButton, setLoadingButton] = useState(false);
    const [mail, setMail] = useState("");

    const handleButton = () => {
        if (!personalInfo?.mail.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("mail-length-error");
            setErrors ? setErrors(_errors): "";
            return;
        }

        setLoadingButton(true);
        RESTService.action("ChangeMail", {
            mail: mail
        })
            .then((res) => {
                setToken ? setToken(res.token) : "";
                setLoadingButton(false);
                setViews ? setViews("sms-mail") : "";
            })
            .catch((err) => {
                console.error("ChangeMail Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSaveButton = () => {
        return <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                onClick={() => {
                    handleButton();
                }}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderTitle = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("please-enter-your-new-e-mail")}
            </Text>
            <TextInput
                placeholder={localize("enter-your-information.")}
                isError={errors?.includes("mail-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "mail-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                    setErrors ? setErrors(_errors) : "";
                }}
                initialValue={mail}
                onChangeText={(e) => {
                    setMail(e);
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderTitle()}
        </div>;
    };

    return <div
        style={{
            padding: spaces.content * 2,
        }}
    >
        <div
            className={classes.container}
            style={{
                gap: spaces.content
            }}
        >
            <div
                onClick={() => setViews ? setViews("default-open") : ""}
            >
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("email-update")}
            </Text>
        </div>
        {renderContent()}
        {renderSaveButton()}
    </div>;
};

export default ChangeWorkerMail;
