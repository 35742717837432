import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    headerContainer: {
        display: "flex",
        flexDirection: "column"
    },
    line: {
        width: "100%",
        height: 1
    },
    packagesContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    paymentMethodsContainer: {
        display: "flex",
        "@media screen and (max-width: 1346px)": {
            flexDirection: "column",
            alignItems: "flex-start"
        },
    },
    paymentInfo: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    }
});
export default useStyles;