import {
    useState 
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    Dialog,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    PerformanceTestListProps
} from "./types";
import PTCard from "../ptCard";
import {
    TrashIcon
} from "../../../assets/svgr";
import {
    RESTService
} from "../../../services/restAPI";
import {
    getErrorText
} from "../../../utils";

const PerformanceTestList = ({
    setPtAssignStatus,
    setPtListData,
    setSelectedPT,
    setEditPTID,
    selectedPT,
    ptListData
}: PerformanceTestListProps) => {
    const classes = useStyles();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [deletePTAlert, setDeletePTAlert] = useState<{showDialog:boolean, id: string}>({
        showDialog: false,
        id: ""
    });

    const deletePT = () => {
        RESTService.action("DeletePT", {
            performanceTestID: deletePTAlert.id
        })
            .then((res) => {
                let _ptListData = JSON.parse(JSON.stringify(ptListData));
                const indexOfPT = ptListData.findIndex(e => e._id === deletePTAlert.id);
                if(indexOfPT !== -1) {
                    _ptListData.splice(indexOfPT,1);
                    setPtListData(_ptListData);
                    setDeletePTAlert({
                        id: "",
                        showDialog: false
                    });
                }                
            })
            .catch((err) => {
                console.error("DeletePerformanceTest Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    return <div
        className={classes.container}
        style={{
            padding: `${spaces.content}px ${spaces.containerXLarge / 2}px`,
            backgroundColor: colors.layer1,
            borderWidth: borders.line
        }}
    >
        <Text
            variant="header5-medium"
            style={{
                marginBottom: spaces.content
            }}
        >
            {localize("test-list")}
        </Text>
        {
            ptListData.map((e) => {
                return <PTCard
                    isSelected={e._id === selectedPT.ptID}
                    title={e.title}
                    onEdit={() => {
                        setEditPTID(e._id);
                        setPtAssignStatus("editPT");
                    }}
                    onClick={() => {
                        setSelectedPT({
                            ptID: e._id,
                            title: e.title
                        });
                    }}
                    onDelete={() => {
                        setDeletePTAlert({
                            id: e._id,
                            showDialog: true
                        });
                    }}
                />;
            })
        }
        <Dialog
            isVisible={deletePTAlert.showDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <TrashIcon
                        color={colors.error}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("delete-pt-warn-header")}
                    </Text>
                    <Text
                        variant="body-regular"
                        color="textGrey"
                    >
                        {localize("delete-pt-warn-content")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div
                className={classes.dialogBottom}
                style={{
                    gap: spaces.containerXLarge
                }}
            >
                <Button
                    title={localize("give-up")}
                    variant="outline"
                    spreadBehaviour="stretch"
                    onClick={() => {
                        setDeletePTAlert({
                            id: "",
                            showDialog: false
                        });
                    }}
                />
                <Button
                    title={localize("remove")}
                    spreadBehaviour="stretch"
                    onClick={() => {
                        deletePT();
                    }}
                />
            </div>}
        />
    </div>;
};

export default PerformanceTestList;