import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles(
    {
        graphChipContainer: {
            transition: "all .2s ease-in-out",
            overflowWrap: "break-word",
            boxSizing: "border-box",
            borderStyle: "solid",
            textAlign: "center",
            cursor: "pointer",
            maxWidth: "70px",
            width: "100%",
            "&:hover": {
                opacity: 0.8
            },
            "&:active": {
                transform: "translateY(-2px)"
            }
        },
        graphDisabled: {
            pointerEvents: "none",
            filter: "grayscale(1)",
            opacity: .5
        }
    }
);
export default useStyles;