import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
    },
    dropdownButtonIcon: {
        justifySelf: "center",
        alignSelf: "center",
        display: "flex"
    }
});
export default useStyles;