import {
    Fragment,
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import EmployerRegisterProps, {
    RegisterInfoType
} from "./types";
import {
    useNavigate
} from "react-router-dom";
import PersonalInfo from "./views/personalInfo";
import CompanyInfo from "./views/companyInfo";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    md5
} from "js-md5";
import validator from "validator";
import {
    Logo,
    RoundArrowIcon
} from "../../../../../assets/svgr";
import {
    RegisterRequestType
} from "../../../../../services/restAPI/actions/register/types";
import {
    getBrowserLanguageFull,
    getErrorText
} from "../../../../../utils";

const EmployerRegister = ({
    setRegisterType,
    areaCode
}: EmployerRegisterProps) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [currentStep, setCurrentStep] = useState(1);

    const [registerInfo, setRegisterInfo] = useState<RegisterInfoType>({
        approveCommercialMessages: false,
        approveContracts: false,
        repeatPassword: "",
        phoneNumber: "",
        position: {
            localizedText: "",
            _id: "",
            key: ""
        },
        areaCode: {
            countryTranslationKey: "",
            flagUnicode: "",
            country: "",
            value: "",
            _id: ""
        },
        city: {
            localizedText: "",
            key: "",
            _id: ""
        },
        district: {
            localizedText: "",
            key: "",
            _id: ""
        },
        lastName: "",
        password: "",
        sector: {
            localizedText: "",
            key: "",
            _id: ""
        },
        mail: "",
        title: "",
        name: ""
    });

    const [errors, setErrors] = useState<string[]>([]);

    const errorCheck = () => {
        let _errors = JSON.parse(JSON.stringify(errors));
        if(registerInfo?.name.length > 55 ||  registerInfo?.name.length < 3) {
            _errors.push("register-name-length-error");
        }

        if(registerInfo.lastName.length > 33 || registerInfo.lastName.length < 3) {

            _errors.push("register-last-name-length-error");
        }

        if(!registerInfo.city || !registerInfo.city._id.length) {
            _errors.push("register-company-city-error");
        }

        if(!registerInfo.district || !registerInfo.district._id.length) {
            _errors.push("register-company-district-error");
        }

        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[)!@#$%^&_\-*[\]{};':"<>?,./])[^\s]{8,16}$/.test(registerInfo.password)) {
            _errors.push("register-password-error");
        }

        if(registerInfo.password !== registerInfo.repeatPassword) {
            _errors.push("register-repeat-password-error");
        }

        if(_errors.length) {
            setErrors(_errors);
            return _errors;
        }
    };

    const handleRegister = () => {
        if(registerInfo?.name.length > 55 ||  registerInfo?.name.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-name-length-error");

            setErrors(_errors);
            return;
        }

        if(registerInfo.lastName.length > 33 || registerInfo.lastName.length < 3) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-last-name-length-error");

            setErrors(_errors);
            return;
        }

        if(!registerInfo.city || !registerInfo.city._id.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-company-city-error");

            setErrors(_errors);
            return;
        }

        if(!registerInfo.district || !registerInfo.district._id.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-company-district-error");

            setErrors(_errors);
            return;
        }

        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[)!@#$%^&_\-*[\]{};':"<>?,./])[^\s]{8,16}$/.test(registerInfo.password)) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-password-error");

            setErrors(_errors);
            return;
        }

        if(registerInfo.password !== registerInfo.repeatPassword) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-repeat-password-error");

            setErrors(_errors);
            return;
        }

        if(registerInfo.title.length < 3 || registerInfo.title.length > 100) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-company-title-error");

            setErrors(_errors);
            return;
        }

        if(!registerInfo.position || !registerInfo.position._id.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-occupation-error");

            setErrors(_errors);
            return;
        }

        if(!registerInfo.sector || !registerInfo.sector._id.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-sector-error");

            setErrors(_errors);
            return;
        }

        if(!validator.isEmail(registerInfo.mail) || registerInfo.mail.length > 99) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-corparate-mail-error");

            setErrors(_errors);
            return;
        }

        if(!registerInfo.areaCode || !registerInfo.areaCode._id) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-area-code-error");

            setErrors(_errors);
            return;
        }  

        if(!validator.isMobilePhone(registerInfo.phoneNumber)) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("register-corparate-phone-number-error");

            setErrors(_errors);
            return;
        }

        let variable: RegisterRequestType = {
            isApproveCommercialMessages: registerInfo?.approveCommercialMessages,
            isApproveContracts: registerInfo?.approveContracts,
            phoneAreaCode: registerInfo?.areaCode._id,
            password: md5(registerInfo?.password),
            language: getBrowserLanguageFull(),
            lastName: registerInfo?.lastName,
            phone: registerInfo?.phoneNumber,
            firstName: registerInfo?.name,
            mail: registerInfo?.mail,
            type: "employer",
            company: {
                phoneAreaCode: registerInfo.areaCode._id,
                occupationID: registerInfo.position._id,
                districtID: registerInfo.district._id,
                sectorID: registerInfo.sector._id,
                phone: registerInfo?.phoneNumber,
                cityID: registerInfo.city._id,
                title: registerInfo?.title,
                mail: registerInfo?.mail,
            }
        };

        if(!variable.company) {
            return;
        }

        if(registerInfo.website && registerInfo.website.length) {
            variable.company.website = registerInfo.website;
        } 

        RESTService.action("Register",variable)
            .then(() => {
                return navigate("/auth/SmsOTP", {
                    state: {
                        userMail: registerInfo.mail,
                        selection: ""
                    }
                });
            })
            .catch((err) => {
                console.error("Register Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderSteps = () => {
        const steps = 2;

        return <div 
            className={classes.stepContainer}
            style={{
                marginBottom: spaces.container
            }} 
        >
            {[...Array(steps)].map((_, index) => (
                <div
                    className={classes.step}
                    key={index}
                    style={{
                        borderRadius: radiuses.half,
                        backgroundColor: index < currentStep ? (errors.length > 0 ? colors.error : colors.primary) : colors.stroke
                    }}
                />
            ))}
        </div>;
    };

    const renderInputs = () => {
        return <Fragment>
            {currentStep === 1 && <PersonalInfo
                setRegisterInfo={setRegisterInfo}
                registerInfo={registerInfo}
                setErrors={setErrors}
                areaCode={areaCode}
                errors={errors}
            />}
            {currentStep === 2 && <CompanyInfo
                setRegisterInfo={setRegisterInfo}
                registerInfo={registerInfo}
                setErrors={setErrors}
                areaCode={areaCode}
                errors={errors}
            />}
            <Button
                title={currentStep === 1 ? 
                    localize("next-step") : 
                    localize("register")
                }
                spreadBehaviour="stretch"
                color="secondary"
                size="large"
                disabled={!registerInfo.approveContracts || errors.length >= 1}
                style={{
                    marginBottom: spaces.content
                }}
                onClick={() => {
                    let _err = errorCheck(); 
                    if(_err && _err.length) {
                        return;
                    }
                    setCurrentStep((prev) => {
                        return prev === 1 ? 2 : prev;
                    });
                    (currentStep === 2) && handleRegister();
                }}
            />
        </Fragment>;
    };

    const renderButtons = () => {
        return <div
            className={classes.buttonContainer}
            style={{
                marginBottom: spaces.inline * 2.5
            }}
        >
            <Button
                title={localize("register-with-personal-account")}
                spreadBehaviour="stretch"
                variant="outline"
                color="secondary"
                size="large"
                onClick={() => {
                    setRegisterType("worker");
                }}
            />
            <div 
                style={{
                    marginTop: spaces.container
                }}
            >
                <Text
                    variant="body3-regular"
                >
                    {localize("already-have-an-account")}{" "}
                </Text>
                <div 
                    className={classes.alreadyHaveAnAccount}
                    style={{
                        textDecorationColor: colors.primary
                    }}
                    onClick={() => {
                        navigate("/auth/login");
                    }}
                >
                    <Text
                        variant="body3-semiBold"
                        color="primary"
                    >
                        {localize("login")}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderLogo = () => {
        return <div
            className={classes.logoContainer}
            style={{
                marginBottom: spaces.containerXLarge / 2,
                marginTop: spaces.containerLarge
            }}
        >
            <div 
                className={classes.backButton} 
                onClick={() => setCurrentStep(1)}
            >
                {currentStep > 1 && <div 
                    className={classes.backButtonIcon}
                >
                    <RoundArrowIcon 
                        color={colors.primary}
                        size={28} 
                    />
                </div>}
            </div>
            <div
                className={classes.logo}
                style={{
                    marginBottom: spaces.inline * 1.5,
                    marginTop: spaces.inline * 1.5
                }}
            >
                <Logo size={120} />
            </div>
        </div>;
    };

    return <div
        className={classes.container}
    >
        <div
            className={classes.loginContainer}
            style={{
                paddingBottom: spaces.container,
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                paddingTop: spaces.inline
            }}
        >
            {renderLogo()}
            {renderSteps()}
            {renderInputs()}
            {renderButtons()}
        </div>
    </div>;
};
export default EmployerRegister;