import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const VerticalDots = ({
    color = "#45BBD4",
    size = 24,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 24 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fill={color}
            transform={`scale(${1 / pathScale})`}
            d="M12 17a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM12 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM12 3a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"        />
    </svg>;
};
export default VerticalDots;
