import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles(
    {
        storyContainer: {
            overflowWrap: "break-word",
            boxSizing: "border-box",
            flexDirection: "column",
            minWidth: "min-content",
            alignItems: "center",
            borderStyle: "solid",
            textAlign: "center",
            cursor: "pointer",
            display: "flex",
            maxWidth: 285,
            "&:hover": {
                opacity: 0.9
            }
        }
    }
);
export default useStyles;