import {
    Fragment,
    useEffect,
    useRef,
    useState
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    SelectDialog,
    IOCoreTheme,
    SelectBox,
    CheckBox,
    TextArea, 
    Loading,
    Button,
    Text,
    Chip
} from "isinolacak-web-cl";
import {
    PreAssesmentQuestionsObjectType,
    SelectedCapabilityKeyType,
    PreAssesmentQuestions,
    AttachmentStatusType,
    CreateDocument,
    ErrorTypes
} from "./types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {
    RESTService
} from "../../../services/restAPI";
import {
    PerformanceTest
} from "../../../components";
import PreAssessmentQuestion from "./components/preAssessmentQuestion";
import {
    GetExperienceLevelRequestType,
    ExperienceLevelObjectType
} from "../../../services/restAPI/actions/getExperienceLevels/types";
import {
    GetContractTypesResponseObjectType,
    GetContractTypesRequestType
} from "../../../services/restAPI/actions/getContractTypes/types";
import {
    GetWorkingTypesRequestType,
    WorkingTypeObjectType
} from "../../../services/restAPI/actions/getWorkingTypes/types";
import {
    GetCapabilitiesRequestType,
    GetCapabilitiesObjectType
} from "../../../services/restAPI/actions/getCapabilities/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../services/restAPI/actions/getOccupation/types";
import {
    preAssessmentQuestions
} from "../../../services/restAPI/actions/createAdvert/types";
import {
    CitiesResponseObjectType
} from "../../../services/restAPI/actions/getCities/types";
import {
    GetAdvertWithPAQForEmployerResponseType,
    PreAssessmentQuestionsForEditAdvertType
} from "../../../services/restAPI/actions/getAdvertWithPAQForEmployer/types";
import DocumentComponents from "../../../components/documentUpload";
import {
    FilledTriangleBottomIcon,
    FilledPlusIcon,
    RadarIcon,
    CloseIcon,
    PlusIcon
} from "../../../assets/svgr";
import {
    EditAdvertRequestType 
} from "../../../services/restAPI/actions/editAdvert/types";
import {
    AssignedPTType
} from "../../../components/performanceTest/types";
import {
    getErrorText
} from "../../../utils";

const EditAdvert = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        state
    } = useLocation();
    
    const {
        advertID
    } = state;

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    // Controls
    const [selectedCapabilityKeys, setSelectedCapabilityKeys] = useState<Array<SelectedCapabilityKeyType>>([]);
    const [createAttachmentStatus, setCreateAttachmentStatus] = useState<AttachmentStatusType>("advertInfo");
    const [preAssessmentQuestions, setPreAssessmentQuestions] = useState<PreAssesmentQuestions>([]);
    const [isVisibleCapabilityDialog, setIsVisibleCapabilityDialog] = useState<boolean>(false);
    const [adverData, setAdvertData] = useState<GetAdvertWithPAQForEmployerResponseType>();
    const [documentSave, setDocumentSave] = useState<CreateDocument | null>(null);
    const [isSuitableForDisabled, setIsSuitableForDisabled] = useState(false);
    const [steps, setSteps] = useState<Array<{isCompleted: boolean; title: string;}>>([
        {
            title: localize("job-title-and-description"),
            isCompleted: false,
        },
        {
            title: localize("job-details"),
            isCompleted: false,
        },
        {
            title: localize("position-details"),
            isCompleted: false,
        },
        /* {
            title: localize("job-approval"),
            isCompleted: false,
        } */
    ]);
    const [selectedPT, setSelectedPT] = useState<AssignedPTType>({
        title: "",
        ptID: ""
    });
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState<ErrorTypes>({
        experienceLevel: "",
        contractType: "",
        description: "",
        workingType: "",
        occupation: "",
        capability: "",
        city: ""
    });
    const [newAdvertItems, setNewAdvertItems] = useState({
        experienceLevelID: "",
        contractTypeID: "",
        workingTypeID: "",
        occupationID: "",
        description: "",
        cityID: ""
    });
    // Static Datas
    const [contractTypes, setContractTypes] = useState<Array<GetContractTypesResponseObjectType>>([]);
    const [experienceLevels, setExperienceLevels] = useState<Array<ExperienceLevelObjectType>>([]);
    const [occupations, setOccupations] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [capabilities, setCapabilities] = useState<Array<GetCapabilitiesObjectType>>([]);
    const [workingTypes, setWorkingTypes] = useState<Array<WorkingTypeObjectType>>([]);
    const [cities, setCities] = useState<Array<CitiesResponseObjectType>>([]);
    // static Data Total Datas
    const [experienceLevelTotalData, setExperienceLevelTotalData] = useState<number>(0);
    const [contractTypeTotalData, setContractTypeTotalData] = useState<number>(0);
    const [workingTypeTotalData, setWorkingTypeTotalData] = useState<number>(0);
    const [capabilityTotalData, setCapabilityTotalData] = useState<number>(0);
    // Static Data Pages
    const [pageExperienceLevel, setPageExperienceLevel] = useState<number>(1);
    const [pageContractType, setPageContractType] = useState<number>(1);
    const [pageWorkingType, setPageWorkingType] = useState<number>(1);
    const [pageCapability, setPageCapability] = useState<number>(1);
    //Occupation states
    const [occupationsSearchPage, setOccupationsSearchPage] = useState(1);
    const [searchOccupationText, setSearchOccupationText] = useState("");
    const [occupationsLoading, setOccupationsLoading] = useState(false);
    const [pageOccupation, setPageOccupation] = useState<number>(1);
    const [totalDataCount, setTotalDataCount] = useState(1);

    const _langugage = window.localStorage.getItem("userLanguage");

    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        getAdvertData();
        getOccupations();
    },[]);

    useEffect(() => {
        searchOccupation(searchOccupationText);
    }, [searchOccupationText]);

    useEffect(() => {
        let _steps = JSON.parse(JSON.stringify(steps));

        if(newAdvertItems.occupationID.length && newAdvertItems.description.length){
            _steps[0].isCompleted = true;
        } else {
            _steps[0].isCompleted = false;
        }

        if(newAdvertItems.workingTypeID.length 
            && newAdvertItems.contractTypeID.length 
            && newAdvertItems.experienceLevelID.length 
            && newAdvertItems.cityID.length){
            _steps[1].isCompleted = true;
        } else {
            _steps[1].isCompleted = false;
        }

        if(selectedCapabilityKeys.length){
            _steps[2].isCompleted = true;
        } else {
            _steps[2].isCompleted = false;
        }

        setSteps(_steps);
    }, [newAdvertItems, preAssessmentQuestions, selectedCapabilityKeys]);

    useEffect(() => {
        if(pageExperienceLevel === 1){
            return;
        }

        getExperienceLevels();
    }, [pageExperienceLevel]);

    useEffect(() => {
        if(pageWorkingType === 1){
            return;
        }

        getWorkingTypes();
    }, [pageWorkingType]);

    useEffect(() => {
        if(pageContractType === 1){
            return;
        }

        getContractTypes();
    }, [pageContractType]);

    useEffect(() => {
        if(pageCapability === 1){
            return;
        }

        getCapabilities();
    }, [pageCapability]);

    const searchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const getAdvertData = () => {
        RESTService.action("GetAdvertWithPAQForEmployer",{
            advertID: advertID
        })
            .then((res) => {
                let _paq:Array<PreAssesmentQuestionsObjectType> = [];
                let _selectedCapabilities: Array<SelectedCapabilityKeyType> = [];
                if(res.preAssessmentQuestions.length) {
                    res.preAssessmentQuestions.forEach((item: PreAssessmentQuestionsForEditAdvertType) => {
                        let paqItem: PreAssesmentQuestionsObjectType = {
                            isNumeric: item.type === "numeric" ? true : false,
                            question: item.title,
                            prefer: item.prefer,
                            isSaved: true
                        };
                        _paq.push(paqItem);
                    });
                }
                if(res.capabilities.length) {
                    res.capabilities.forEach((item) => {
                        let capItem: SelectedCapabilityKeyType = {
                            __originalIndex: 0,
                            __key: item._id,
                            __title: item.localizedText,
                        };
                        _selectedCapabilities.push(capItem);
                    });
                }

                if(res.documentTemplate) {
                    setDocumentSave({
                        title: res.documentTemplate.title,
                        _id: res.documentTemplate._id
                    });
                }
                
                if(res.performanceTest && res.performanceTest._id) {
                    setSelectedPT({
                        ptID: res.performanceTest._id,
                        title: res.performanceTest.title
                    });
                }
                setIsSuitableForDisabled(res.isForDisabledPeople);
                setSelectedCapabilityKeys(_selectedCapabilities);
                if(res.preAssessmentQuestions && res.preAssessmentQuestions.length) {
                    setPreAssessmentQuestions(_paq);
                }
                setAdvertData(res);
                setNewAdvertItems({
                    experienceLevelID: res.experienceLevel._id,
                    contractTypeID: res.contractType._id,
                    workingTypeID: res.workingType._id,
                    occupationID: res.occupation._id,
                    description: res.description,
                    cityID: res.city._id,
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetAdvertWithPAQForEmployer Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    // Get Static Datas 
    const getOccupations = (searchParams?: GetOccupationRequestType) => {
        if(!occupationsLoading) setOccupationsLoading(true);

        let params: GetOccupationRequestType = {
            page:  pageOccupation
        };

        if(searchOccupationText && searchOccupationText.length) {
            params.search = searchOccupationText;
            params.page = occupationsSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = _langugage ?? "tr-TR";
        RESTService.action("GetOccupation", params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setOccupations(res.payload);

                if(searchParams?.search || searchOccupationText.length){
                    setPageOccupation(1);
                } else {
                    setPageOccupation(params.page ?? 1);
                    setOccupationsSearchPage(1);
                }

                setOccupationsLoading(false);
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getExperienceLevels = (search?: string) => {
        let variables: GetExperienceLevelRequestType = {
            language: _langugage ?? "tr-TR",
        };

        RESTService.action("GetExperienceLevels", variables)
            .then((res) => {
                setExperienceLevels(res.payload);
                if(res.totalDataCount) {
                    setExperienceLevelTotalData(res.totalDataCount);
                }
            })
            .catch((err) => {
                console.error("GetExperienceLevels Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getWorkingTypes = (search?: string) => {
        let variables: GetWorkingTypesRequestType = {
        };

        RESTService.action("GetWorkingTypes", variables)
            .then((res) => {
                setWorkingTypes(res.payload);
                if(res.totalDataCount) {
                    setWorkingTypeTotalData(res.totalDataCount);
                }
            })
            .catch((err) => {
                console.error("GetWorkingTypes Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getCities = () => {
        RESTService.action("GetCities", {
        })
            .then((res) => {
                setCities(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getContractTypes = (search?: string) => {
        let variables: GetContractTypesRequestType = {
            language: _langugage ?? "tr-TR",
        };

        RESTService.action("GetContractTypes", variables)
            .then((res) => {
                setContractTypes(res.payload);
                if(res.totalDataCount) {
                    setContractTypeTotalData(res.totalDataCount);
                }
            })
            .catch((err) => {
                console.error("GetContractTypes Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const getCapabilities = (search?: string) => {
        let variables: GetCapabilitiesRequestType = {
            language: _langugage ?? "tr-TR",
        };

        if(pageOccupation !== 1) {
            variables.page = pageCapability;
        }

        if(search && search.length) {
            variables.search = search;
        }

        RESTService.action("GetCapabilities", variables)
            .then((res) => {
                setCapabilities(res.payload);
                if(res.totalDataCount){
                    setCapabilityTotalData(res.totalDataCount);
                }
            })
            .catch((err) => {
                console.error("GetCapabilities Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const editAdvert = () => {
        let paqVariables: Array<preAssessmentQuestions> = [];
        let errorFlag = false;
        let _isError: ErrorTypes = {
            experienceLevel: "",
            contractType: "",
            description: "",
            workingType: "",
            capability: "",
            occupation: "",
            city: ""
        };

        if(!newAdvertItems.cityID.length) {
            _isError.city = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.contractTypeID.length) {
            _isError.contractType = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.description.length) {
            _isError.description = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.experienceLevelID.length) {
            _isError.experienceLevel = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.occupationID.length) {
            _isError.occupation = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.workingTypeID.length) {
            _isError.workingType = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!newAdvertItems.cityID.length) {
            _isError.city = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(!selectedCapabilityKeys.length) {
            _isError.capability = localize("you-need-to-fill-out-this-field");
            errorFlag = true;
        }

        if(errorFlag) {
            setIsError(_isError);
            return;
        }

        preAssessmentQuestions.forEach((item) => {
            if(!item.isSaved){
                return;
            }

            let paqItem: preAssessmentQuestions = {
                type: item.isNumeric ? "numeric" : "yes-no",
                title: item.question,
                prefer: typeof item.prefer !== "boolean" ? {
                    min: Number(item.prefer?.min),
                    max: Number(item.prefer?.max)
                } : true
            };
            paqVariables.push(paqItem);
        });

        let variables: EditAdvertRequestType = {
            cityID: newAdvertItems.cityID,
            contractTypeID: newAdvertItems.contractTypeID,
            experienceLevelID: newAdvertItems.experienceLevelID,
            workingTypeID: newAdvertItems.workingTypeID,
            description: newAdvertItems.description,
            advertID: advertID,
            capabilties: selectedCapabilityKeys.map(item => {
                return {
                    capabilityID: item.__key
                };
            }),
            isForDisabledPeople: isSuitableForDisabled
        };

        if(selectedPT.ptID && selectedPT.ptID.length) {
            variables.performanceTestID = selectedPT.ptID;
        }

        if(paqVariables.length){
            variables.preAssessmentQuestions = paqVariables;
        }

        if(documentSave?._id && documentSave?._id?.length) {
            variables.documentTemplateID = documentSave._id;
        }

        RESTService.action("EditAdvert", variables)
            .then((res) => {
                alert("İlan düzenleme başarılı");
                navigate("/app/myActions");
            })
            .catch((err) => {
                console.error("Edit Advert Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const handleClear = () => {
        setDocumentSave(null);
    };

    // Render Functions
    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                marginBottom: spaces.containerXLarge / 2,
                marginTop: spaces.containerXLarge / 2,
                backgroundColor: colors.stroke,
                height: borders.line,
            }}
        >
        </div>;
    };

    const renderVerticalLine = (isCompleted: boolean) => {
        return <div
            className={classes.verticalLine}
            style={{
                backgroundColor: isCompleted ? colors.primary : colors.stroke,
                marginLeft: spaces.content,
                width: borders.line,
            }}
        >
        </div>;
    };

    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge * .7}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                top: spaces.content * 2
            }}
        >
            <Text
                variant='body-medium'
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                {localize("advert-steps")}
            </Text>
            
            {steps.map((item, index) => {
                return <div
                    key={"step-" + index}
                >
                    <div
                        className={classes.stepContainer}
                    >
                        <div
                            className={classes.numberContainer}
                            style={{
                                borderColor: item.isCompleted ? colors.primary : colors.stroke,
                                borderRadius: radiuses.hard * 2,
                                marginRight: spaces.inline,
                                borderWidth: borders.line,
                                padding: 1
                            }}
                        >
                            <Text
                                color={item.isCompleted ? "primary" : "textSecondary"}
                                variant='body2-medium'
                            >
                                {index + 1}
                            </Text>
                        </div>
                        <Text
                            color={item.isCompleted ? "primary" : "textSecondary"}
                            variant='body2-medium'
                        >
                            {item.title}
                        </Text>
                    </div>
                    {index !== steps.length - 1 ?
                        renderVerticalLine(item.isCompleted)
                        :
                        null
                    }
                </div>;
            })}
        </div>;
    };

    const renderResponsiveSteps = () => {
        return <div
            className={classes.responsiveStepsContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                paddingBottom: 0,
                gap: spaces.content
            }}
        >
            <Text
                variant='header5-medium'
            >
                {localize("create-advert")}
            </Text>
            <div
                className={classes.responsiveSteps}
                style={{
                    gap: spaces.inline
                }}
            >
                {
                    steps.map((item, index) => {
                        return <div
                            className={classes.stepLine}
                            key={"stepLine-"+index}
                            style={{
                                backgroundColor: item.isCompleted ? colors.primary : colors.stroke
                            }}
                        >
                        </div>;
                    })
                }
            </div>
        </div>;
    };

    const renderContentContainer = () => {
        if(createAttachmentStatus !== "advertInfo") {
            return null;
        }

        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderResponsiveSteps()}
            {renderFirstSection()}
            {renderLine()}
            {renderSecondSection()}
            {renderLine()}
            {renderThirdSection()}
            {renderLine()}
            {renderAttachmentsSection()}
            {renderLine()}
            {renderFourthSection()}
            {renderLine()}
            <Button
                disabled={!steps[0].isCompleted || !steps[1].isCompleted || !steps[2].isCompleted ? true : false}
                title={localize("publish-advert")}
                spreadBehaviour='stretch'
                color="secondary"
                style={{
                    marginBottom: spaces.containerXLarge / 2
                }}
                onClick={() => {
                    editAdvert();
                }}
            />
        </div>;
    };

    const renderFirstSection = () => {
        return <div
            className={classes.sectionContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                gap: spaces.content * 2
            }}
        >
            <Text 
                variant='header5-medium'
            >
                {localize("advert-header-&-desc")}
            </Text>
            <div
                className={classes.questionGroup}
                style={{
                    gap: spaces.inline * 2.5
                }}
            >
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text variant='body-regular'>{localize("job-title")}</Text>
                    <div
                        style={{
                            display: "block", //TODO: This div is temporary. When Web Cl selectbox spreadbehavior issue fixed this div must remove.
                            width: "100%"
                        }}
                    >
                        <SelectBox
                            titleExtractor={(item: GetOccupationResponseObjectType) => item.localizedText}
                            //@ts-ignore
                            initialSelectedItems={adverData?.occupation ? [adverData?.occupation] :[]}
                            isError={isError.occupation.length ? true : false}
                            inputTitle={localize("edit-list-search")}
                            title={localize("job-title")}
                            keyExtractor={(e) => e._id}
                            spreadBehaviour="stretch"
                            isNeedConfirm={true}
                            isSearchable={true}
                            emptyContent={() => {
                                if(occupationsLoading) {
                                    return <Fragment>
                                        <Loading/>
                                    </Fragment>;
                                }
        
                                return <Text>
                                    {localize("no-result-found")}
                                </Text>;
                            }}
                            data={occupations.map((item) => {
                                return {
                                    ...item,
                                    key: item._id
                                };
                            })}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                setNewAdvertItems({
                                    ...newAdvertItems,
                                    occupationID: selectedItems[0].__key
                                });
                                closeSheet();
                                onSuccess();
                            }}
                            onSearch={(value) => {
                                setSearchOccupationText(value);
                                setOccupationsLoading(true);
                            }}
                            onOverlayPress={() => {
                                if(!(occupations && occupations.length)){
                                    getOccupations();
                                }
                                setPageOccupation(1);
                            }}
                            customIcon={() => {
                                return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                            }}
                        />
                    </div>
                </div>
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant='body-regular'
                    >
                        {localize("description")}
                    </Text>
                    <TextArea
                        isError={isError.description.length ? true : false}
                        initialValue={adverData?.description}
                        placeholder={localize("description")}
                        spreadBehaviour='stretch'
                        onChangeText={(value) => {
                            setNewAdvertItems({
                                ...newAdvertItems,
                                description: value
                            });
                        }}
                    />
                </div>
            </div>
        </div>;
    };

    const renderSecondSection = () => {
        return <div
            className={classes.sectionContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                gap: spaces.content * 2
            }}
        >
            <Text
                variant='header5-medium'
            >
                {localize("advert-detail")}
            </Text>
            <div
                className={classes.questionGroup}
                style={{
                    gap: spaces.inline * 2.5
                }}
            >
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant='body-regular'
                    >
                        {localize("employer-title")}
                    </Text>
                    <div
                        style={{
                            display: "block", //TODO: This div is temporary. When Web Cl selectbox spreadbehavior issue fixed this div must remove.
                            width: "100%"
                        }}
                    >
                        <SelectBox
                            titleExtractor={(item: ExperienceLevelObjectType) => item.localizedText}
                            isError={isError.experienceLevel.length ? true : false}
                            //@ts-ignore
                            initialSelectedItems={[adverData?.experienceLevel]}
                            keyExtractor={(e) => e._id}
                            spreadBehaviour='stretch'
                            isNeedConfirm={true}
                            isSearchable={false}
                            inputTitle={localize("edit-list-search")}
                            onOverlayPress={() => {
                                if(!(experienceLevels && experienceLevels.length)){
                                    getExperienceLevels();
                                }
                                setPageExperienceLevel(1);
                            }}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                setNewAdvertItems({
                                    ...newAdvertItems,
                                    experienceLevelID: selectedItems[0].__key
                                });
                                closeSheet();
                                onSuccess();
                            }}
                            data={experienceLevels.map((item) => {
                                return {
                                    ...item,
                                    key: item._id
                                };
                            })}
                            onSearch={(value) => {
                                getExperienceLevels(value);
                            }}
                            customIcon={() => {
                                return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                            }}
                            paginationProps={{
                                totalDataCount: experienceLevelTotalData ? experienceLevelTotalData : 20,
                                currentPage: pageExperienceLevel,
                                itemPerPage: 20,
                                onSelect:(item) => {
                                    setPageExperienceLevel(item.pageNumber);
                                }
                            }}
                        />
                    </div>
                </div>
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text 
                        variant='body-regular'
                    >
                        {localize("working-time")}
                    </Text>
                    <div
                        style={{
                            display: "block", //TODO: This div is temporary. When Web Cl selectbox spreadbehavior issue fixed this div must remove.
                            width: "100%"
                        }}
                    >
                        <SelectBox
                            titleExtractor={(item: WorkingTypeObjectType) => item.localizedText}
                            isError={isError.workingType.length ? true : false}
                            //@ts-ignore
                            initialSelectedItems={[adverData?.workingType]}
                            inputTitle={localize("edit-list-search")}
                            keyExtractor={(e) => e._id}
                            spreadBehaviour='stretch'
                            isNeedConfirm={true}
                            isSearchable={false}
                            onOverlayPress={() => {
                                if(!(workingTypes && workingTypes.length)){
                                    getWorkingTypes();
                                }
                                setPageWorkingType(1);
                            }}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                setNewAdvertItems({
                                    ...newAdvertItems,
                                    workingTypeID: selectedItems[0].__key
                                });
                                closeSheet();
                                onSuccess();
                            }}
                            data={workingTypes.map((item) => {
                                return {
                                    ...item,
                                    key: item._id
                                };
                            })}
                            onSearch={(value) => {
                                getWorkingTypes(value);
                            }}
                            customIcon={() => {
                                return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                            }}
                            paginationProps={{
                                totalDataCount: workingTypeTotalData ? workingTypeTotalData : 20,
                                currentPage: pageWorkingType,
                                itemPerPage: 20,
                                onSelect:(item, index) => {
                                    setPageWorkingType(item.pageNumber);
                                }
                            }}
                        />
                    </div>
                    
                </div>
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text variant='body-regular'>{localize("job-locale")}</Text>
                    <div
                        style={{
                            display: "block", //TODO: This div is temporary. When Web Cl selectbox spreadbehavior issue fixed this div must remove.
                            width: "100%"
                        }}
                    >
                        <SelectBox
                            titleExtractor={(item: CitiesResponseObjectType) => item.localizedText}
                            isError={isError.city.length ? true : false}
                            //@ts-ignore
                            initialSelectedItems={[adverData?.city]}
                            keyExtractor={(e) => e._id}
                            spreadBehaviour='stretch'
                            isNeedConfirm={true}
                            disabled={false}
                            inputTitle=''
                            onOverlayPress={() => {
                                if(!(cities && cities.length)){
                                    getCities();
                                }
                            }}
                            data={cities.map((item) => {
                                return {
                                    ...item,
                                    key: item._id
                                };
                            })}
                            customIcon={() => {
                                return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                            }}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                setNewAdvertItems({
                                    ...newAdvertItems,
                                    cityID: selectedItems[0].__key
                                });
                                closeSheet();
                                onSuccess();
                            }}
                        />
                    </div>
                </div>
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant='body-regular'
                    >
                        {localize("working-type")}
                    </Text>
                    <div
                        style={{
                            display: "block", //TODO: This div is temporary. When Web Cl selectbox spreadbehavior issue fixed this div must remove.
                            width: "100%"
                        }}
                    >
                        <SelectBox
                            titleExtractor={(item: GetContractTypesResponseObjectType) => item.localizedText}
                            isError={isError.contractType.length ? true : false}
                            //@ts-ignore
                            initialSelectedItems={[adverData?.contractType]}
                            inputTitle={localize("edit-list-search")}
                            keyExtractor={(e) => e.key}
                            spreadBehaviour='stretch'
                            isNeedConfirm={true}
                            isSearchable={false}
                            onOverlayPress={() => {
                                if(!(contractTypes && contractTypes.length)){
                                    getContractTypes();
                                }
                                setPageContractType(1);
                            }}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                setNewAdvertItems({
                                    ...newAdvertItems,
                                    contractTypeID: selectedItems[0].__key
                                });
                                closeSheet();
                                onSuccess();
                            }}
                            data={contractTypes.map((item) => {
                                return {
                                    ...item,
                                    key: item._id
                                };
                            })}
                            onSearch={(value) => {
                                getContractTypes(value);
                            }}
                            customIcon={() => {
                                return <FilledTriangleBottomIcon size={15} color={colors.primary} />;
                            }}
                            paginationProps={{
                                totalDataCount: contractTypeTotalData ? contractTypeTotalData : 20,
                                currentPage: pageContractType,
                                itemPerPage: 20,
                                onSelect:(item, index) => {
                                    setPageContractType(item.pageNumber);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderThirdSection = () => {
        return <div
            className={classes.sectionContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                gap: spaces.content * 2
            }}
        >
            <Text
                variant='header5-medium'
            >
                {localize("position-detail")}
            </Text>
            <div
                className={classes.questionGroup}
                style={{
                    gap: spaces.inline * 2.5
                }}
            >
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant='body-regular'
                    >
                        {localize("abilities")}
                    </Text>
                    <div
                        className={classes.capabilityContainer}
                        style={{
                            gap: spaces.content / 2
                        }}
                    >
                        <Chip
                            title={localize("add-ability")}
                            titleColor='primary'
                            variant='outline'
                            disabled={false}
                            size='medium'
                            icon={() => {
                                return <PlusIcon size={12} color={colors.primary} />;
                            }}
                            onClick={() => {
                                if(!(capabilities && capabilities.length)){
                                    getCapabilities();
                                }
                                setIsVisibleCapabilityDialog(true);
                                setPageCapability(1);
                            }}
                            
                        />
                        {
                            selectedCapabilityKeys.map((item, index) => {
                                return <Chip
                                    key={"selectedChip-"+index}
                                    titleColor='constrastBody'
                                    title={item.__title}
                                    disabled={false}
                                    selected={true}
                                    size='medium'
                                    icon={() => {
                                        return <CloseIcon color={colors.constrastBody} size={12} />;
                                    }}
                                    onClick={() => {
                                        let _selectedCapabilityKeys = JSON.parse(JSON.stringify(selectedCapabilityKeys));
                                        _selectedCapabilityKeys.splice(index, 1);
                                        setSelectedCapabilityKeys(_selectedCapabilityKeys);
                                    }}
                                />;
                            })
                        }
                    </div>
                </div>
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant='body-regular'
                    >
                        {localize("paq")}
                    </Text>
                    {
                        preAssessmentQuestions.map((item, index) => {
                            return <PreAssessmentQuestion
                                key={"paq-"+index}
                                initialData={item}
                                index={index}
                                onSaved={() => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].isSaved = true;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                onDelete={() => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions.splice(index, 1);
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setIsNumeric={() => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    let newValue = !_preAssessmentQuestions[index].isNumeric;
                                    _preAssessmentQuestions[index].isNumeric = newValue;
                                    if(!newValue){
                                        _preAssessmentQuestions[index].prefer = true;
                                    } else {
                                        _preAssessmentQuestions[index].prefer = {
                                            min: "",
                                            max: ""
                                        };
                                    }
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setIsSaved={(value) => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].isSaved = value;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setMinValue={(value) => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].prefer.min = value;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setMaxValue={(value) => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].prefer.max = value;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setYesNoPrefer={(value) => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].prefer = value;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                                setQuestionText={(value) => {
                                    const _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));
                                    _preAssessmentQuestions[index].question = value;
                                    setPreAssessmentQuestions(_preAssessmentQuestions);
                                }}
                            />;
                        })
                    }
                    <div
                        className={classes.newPaqButtonContainer}
                    >
                        <Button
                            title={localize("add-new-question")}
                            size='xSmall'
                            icon={({
                                color,
                                size
                            }) => {
                                return <FilledPlusIcon size={size} color={color} />;
                            }}
                            onClick={() => {
                                const paqLength = preAssessmentQuestions.length;
                                if(paqLength && !preAssessmentQuestions[paqLength -1].isSaved) {
                                    return;
                                }
                                let _preAssessmentQuestions = JSON.parse(JSON.stringify(preAssessmentQuestions));

                                _preAssessmentQuestions.push({
                                    question: "",
                                    isNumeric: false,
                                    isSaved: false,
                                    prefer: {
                                    }
                                });

                                setPreAssessmentQuestions(_preAssessmentQuestions);
                            }}
                        />
                    </div>
                </div>
            </div>
            <SelectDialog
                data={capabilities.map((item, index) => {
                    return {
                        __key: item._id,
                        __title: item.localizedText,
                        __originalIndex: index
                    };
                })}
                initialData={[{
                }]}
                isVisible={isVisibleCapabilityDialog}
                onClose={() => {
                    setIsVisibleCapabilityDialog(false);
                }}
                setSelectedItems={(item) => {
                    setSelectedCapabilityKeys(item.map((c_item) => {
                        return {
                            __key: c_item.__key,
                            __title: c_item.__title,
                            __originalIndex: c_item.__originalIndex
                        };
                    }));
                }}
                multiSelect={true}
                selectedItems={selectedCapabilityKeys}
                isNeedConfirm={true}
                onOk={({
                    closeSheet,
                    onSuccess,
                }) => {
                    closeSheet();
                    onSuccess();
                }}
                paginationProps={{
                    totalDataCount: capabilityTotalData,
                    currentPage: pageCapability,
                    itemPerPage: 20,
                    onSelect:(item, index) => {
                        setPageCapability(item.pageNumber);
                    }
                }}
            />
        </div>;
    };

    const renderAttachmentsSection = () => {
        return <div
            className={classes.sectionContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                gap: spaces.contentLarge
            }}
        > 
            <div
                className={classes.questionContainer}
            >
                <Text 
                    color="textDark"
                    variant="body-regular"
                >
                    {localize("create-document")}
                </Text>
                <div
                    style={{
                        justifyContent: "space-between",
                        alignContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        width: "100%"
                    }}
                >
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {localize("create-document-desc")}
                    </Text>
                    <Button
                        onClick={() => {
                            setCreateAttachmentStatus("createDocument");
                        }}
                        disabled={documentSave ? true : false}
                        title={localize("create")}
                        variant="outline"
                    />
                </div>
                {documentSave ?
                    <Chip
                        title={documentSave.title}
                        size="large"
                        color="appleBg"
                        isCancelable={true}
                        onClick={() => {
                            handleClear();
                        }}
                    /> : null
                }
            </div>
            <div
                className={classes.questionContainer}
            >
                <Text 
                    color="textDark"
                    variant="body-regular"
                >
                    {localize("create-performance-test")}
                </Text>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {localize("create-performance-test-desc")}
                    </Text>
                    <Button
                        title={localize("create")}
                        disabled={selectedPT.ptID && selectedPT.ptID.length ? true : false}
                        onClick={() => {
                            setCreateAttachmentStatus("createPerformanceTest");
                        }}
                        variant="outline"
                    />
                </div>
                { selectedPT.ptID && selectedPT.ptID.length ?
                    <Chip
                        title={selectedPT.title}
                        size="large"
                        color="appleBg"
                        isCancelable={true}
                        onClick={() => {
                            setSelectedPT({
                                ptID: "",
                                title: ""
                            });
                        }}
                    /> : null
                }
            </div>
        </div>;
    };
    const renderCreateDocument = () => {
        if(createAttachmentStatus !== "createDocument") {
            return null;
        }

        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <DocumentComponents
                setCreateAttachmentStatus={setCreateAttachmentStatus}
                setDocumentSave={setDocumentSave}
            />
        </div>;
    };

    const renderPerformanceTest = () => {
        if(createAttachmentStatus !== "createPerformanceTest") {
            return null;
        }

        return <PerformanceTest
            setCreateAttachmentStatus={setCreateAttachmentStatus}
            createAttachmentStatus={createAttachmentStatus}
            setSelectedPT={setSelectedPT}
            selectedPT={selectedPT}
        />;
    };

    const renderFourthSection = () => {
        return <div
            className={classes.sectionContainer}
            style={{
                padding: `${spaces.containerXLarge / 2}px ${spaces.containerXLarge / 4}px`,
                gap: spaces.content * 2
            }}
        >
            <Text 
                variant='header5-medium'
            >
                {localize("advert-confirm")}
            </Text>
            <div
                className={classes.questionGroup}
                style={{
                    gap: spaces.inline * 2.5
                }}
            >
                <div
                    className={classes.questionContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    {/* <CheckBox
                        title='Zorunlu nitelikleri karşılamayan tüm başvuruları filtrele ve ret mesajı gönder.'
                        titleType='body3-regular'
                    />
                    <CheckBox
                        title='Ön eleme sorularında en az yarısını karşılayan çalışanlara görüşme ayarla.'
                        titleType='body3-regular'
                    /> */}
                    <CheckBox
                        title={localize("suitable-for-disabled")}
                        onChange={() => {
                            setIsSuitableForDisabled(!isSuitableForDisabled);
                        }}
                        isSelected={isSuitableForDisabled}
                        titleType='body3-regular'
                    />
                </div>
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3
        }}
    >
        <div
            className={classes.titleContainer}
        >
            <Text
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("create-advert")}
            </Text>
        </div>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            {renderLeftContainer()}
            {renderContentContainer()}
            {renderCreateDocument()}
            {renderPerformanceTest()}
            {renderRightContainer()}
        </div>
    </div>;
};
export default EditAdvert;
