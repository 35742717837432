import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    emptyComapnyInfo: {
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "95%"
    }
});
export default useStyles;