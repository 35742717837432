import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    getHeaders
} from "../../utils";
import {
    LogoutResponseTypes
} from "./types";
import {
    ChatService 
} from "../../../chat";

const Logout = async (): Promise<LogoutResponseTypes> => {
    return await axios(`${SERVER_ADDRESS}/authentication/logout`, {
        method: "PUT",
        headers: getHeaders(true)
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            ChatService.ConversationsContext.setState({
                conversations: []
            });

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default Logout;
