import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const EmptyConversationIcon = ({
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M2.059 21.047A4.609 4.609 0 0 1 1.27 18.5c0-3.22 3.54-5.834 7.902-5.834S17.07 15.28 17.07 18.5c0 3.222-3.536 5.837-7.898 5.837-2.117 0-4.034-.62-5.45-1.615L1 23.413l1.059-2.366Z"
            transform={`scale(${1 / pathScale})`}
            fill="#45BBD4"
        />
        <path
            d="M12.014 18.502a.827.827 0 1 0 1.655 0 .827.827 0 0 0-1.655 0ZM8.352 18.502a.827.827 0 1 0 1.654 0 .827.827 0 0 0-1.654 0ZM4.69 18.502a.827.827 0 1 0 1.655 0 .827.827 0 0 0-1.654 0Z"
            transform={`scale(${1 / pathScale})`}
            fill="#fff"
        />
        <path
            d="M28.118 12.937a3.794 3.794 0 0 0 .658-2.107c0-2.667-2.954-4.83-6.594-4.83s-6.59 2.163-6.59 4.83c0 2.667 2.95 4.832 6.59 4.832 1.765 0 3.366-.514 4.548-1.337l2.27.57-.882-1.958Z"
            transform={`scale(${1 / pathScale})`}
            fill="#45BBD4"
        />
        <path
            d="M19.93 10.831a.69.69 0 1 1-1.381 0 .69.69 0 0 1 1.38 0ZM22.887 10.831a.69.69 0 1 1-1.379 0 .69.69 0 0 1 1.38 0ZM25.845 10.831a.69.69 0 1 1-1.38 0 .69.69 0 0 1 1.38 0Z"
            transform={`scale(${1 / pathScale})`}
            fill="#fff"
        />
    </svg>;
};
export default EmptyConversationIcon;
