import {
    IOCoreTranslationType
} from "isinolacak-web-cl";
import {
    HandleApplicationStatusType
} from "../../../services/restAPI/actions/employerHandleApplication/types";
import {
    CandidateExperienceType
} from "../../../services/restAPI/actions/getAplicationsForEmployer/types";

export const CANDIDATE_POINT: Array<{
    title: keyof IOCoreTranslationType;
    value: number;
    id: string;
}> = [
    {
        title: "score-5",
        value: 5,
        id: "5"
    },
    {
        title: "score-4",
        value: 4,
        id: "4"
    },
    {
        title: "score-3",
        value: 3,
        id: "3"
    },
    {
        title: "score-2",
        value: 2,
        id: "2"
    },
    {
        title: "score-1",
        value: 1,
        id: "1"
    }
];

export const CANDIDATE_EXP: Array<{
    title:  keyof IOCoreTranslationType;
    value: CandidateExperienceType;
    id: string;
}> = [
    {
        title: "10+",
        value: "ten",
        id: "5"
    },
    {
        title: "5+",
        value: "five",
        id: "4"
    },
    {
        title: "3+",
        value: "three",
        id: "3"
    },
    {
        title: "1+",
        value: "ten",
        id: "2"
    },
    {
        title: "beginner",
        value: "new",
        id: "1"
    }
];

export const CANDIDATE_STATUS: Array<{
    value: HandleApplicationStatusType;
    title: keyof IOCoreTranslationType;
    id: string;
}> = [
    {
        title: "doc-send",
        value: "document",
        id: "1"
    },
    {
        title: "finish-application",
        value: "finished",
        id: "2"
    },
    {
        title: "send-pt",
        value: "performanceTest",
        id: "3"
    },
    {
        title: "reject-candidate",
        value: "rejected",
        id: "4"
    },
];
