import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Call = ({
    color = "#000",
    size = 18,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 18 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M12.75 2.813a3.187 3.187 0 0 0-2.855 4.606c.12.24.168.533.09.827l-.19.709.71-.19c.293-.078.587-.029.826.09a3.188 3.188 0 1 0 1.419-6.043ZM8.438 6a4.312 4.312 0 1 1 2.392 3.862.096.096 0 0 0-.036-.01l-.834.223A1.05 1.05 0 0 1 8.675 8.79l.223-.834V7.95a.095.095 0 0 0-.01-.03A4.297 4.297 0 0 1 8.438 6Zm-5.65-2.18c.92-.97 2.441-.788 3.201.287l.946 1.338c.608.86.557 2.056-.169 2.82l-.184.193a.589.589 0 0 0-.012.283c.05.342.314 1.036 1.385 2.164 1.074 1.13 1.726 1.397 2.028 1.446.13.021.198.003.22-.005l.306-.323a2.028 2.028 0 0 1 2.501-.351l1.433.824c1.225.705 1.51 2.43.531 3.46l-1.065 1.122c-.333.35-.786.649-1.347.704-1.367.135-4.536-.04-7.858-3.538-3.1-3.264-3.689-6.102-3.763-7.49-.037-.685.268-1.272.67-1.696l.408.388-.408-.388L2.788 3.82Zm2.283.937c-.385-.544-1.075-.576-1.467-.163l-1.177 1.24c-.249.26-.38.56-.363.86.06 1.133.548 3.714 3.455 6.775 3.049 3.21 5.857 3.3 6.933 3.194.213-.021.432-.14.641-.36l1.065-1.122c.46-.483.34-1.355-.276-1.71l-1.433-.824a.903.903 0 0 0-1.124.151l-.342.36-.407-.388c.407.388.407.388.406.389v.001l-.003.002-.005.005-.01.01a.736.736 0 0 1-.102.084c-.06.043-.14.09-.242.13-.206.081-.48.125-.818.07-.658-.107-1.52-.58-2.662-1.782-1.146-1.206-1.584-2.105-1.682-2.775-.05-.34-.01-.613.063-.819a1.194 1.194 0 0 1 .195-.348l.01-.011.005-.005.002-.002.001-.001c0-.001.001-.002.41.386l-.409-.388.215-.226c.334-.352.383-.948.066-1.395l-.945-1.338Zm7.679-.82c.31 0 .563.252.563.563v.938h.937a.562.562 0 1 1 0 1.125h-.938V7.5a.562.562 0 1 1-1.124 0v-.938h-.938a.563.563 0 0 1 0-1.125h.938V4.5c0-.31.251-.563.562-.563Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Call;