import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "row",
        position: "relative",
        display: "flex",
    },
    content: {
        flexDirection: "row",
        display: "flex"
    },
    contentContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "center",
        position: "relative",
        alignSelf: "center",
        maxHeight: "80%",
        display: "flex",
        maxWidth: "90%",
        minWidth: 250,
        zIndex: 99999
    },
    cleanButton: {
        position: "absolute"
    },
    dropDownContainer: {
        flexDirection: "column",
        position: "absolute",
        borderStyle: "solid",
        display: "flex",
        zIndex: 9999
    },
    headerContainer: {
        flexDirection: "row",
        alignItems:"center",
        display: "flex",
        width: "100%"
    },
    renderActions: {
        flexDirection: "row",
        display: "flex",
    },
});

export default useStyles;