import React, {
    useEffect,
    Fragment,
    useState 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    getBlogsPayloadObjectType, 
    GetBlogsRequestType
} from "../../../services/restAPI/actions/getBlogs/types";
import BlogCard from "./components/blogCard";
import BlogCoverPhoto from "../../../assets/img/blogCoverPhoto.png";
import {
    Footer 
} from "../../../components";

const Blog = () => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [blogData, setBlogData] = useState<Array<getBlogsPayloadObjectType>>([]);
    const [isBlogDataLoading, setIsBlogDataLoading] = useState(true);

    useEffect(() => {
        getBlogData();
    }, []);

    const getBlogData = () => {
        let variables: GetBlogsRequestType = {
            language: navigator.language ?? "tr-TR",
        };

        if(!isBlogDataLoading) {
            setIsBlogDataLoading(true);
        }

        RESTService.action("GetBlogs", variables)
            .then((res) => {
                setBlogData(res.payload);
            })
            .catch((err) => {
                console.error("GetBlogs Err:", err);
            }).finally(() => {
                setIsBlogDataLoading(false);
            });  
    };

    return <Fragment>
        <div
            className={classes.container}
            style={{
                paddingTop: spaces.containerLarge * 1.5,
                paddingBottom: spaces.containerXXLarge,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                backgroundColor: colors.grey25
            }}>
            {/* //TODO: BREADCRUMB */}
            <div
                className={classes.title}
                style={{
                    marginBottom: spaces.containerLarge
                }}>
        
                <Text
                    variant="header3-medium"
                >
                    {localize("achieve-success-on-your-career-path")}
                </Text>
            </div>
            <div
                className={classes.blogContainer}
            >
                <div className={classes.blog}
                    style={{
                        columnGap: spaces.content,
                        gap: spaces.container * 2
                    }}>
                    {isBlogDataLoading ? <Loading/> : blogData &&
                        blogData.map(blogPost => {
                            return (
                                <Fragment key={blogPost._id}>
                                    <BlogCard
                                        coverPhoto={blogPost.coverPhotoURL ?? BlogCoverPhoto}
                                        title={blogPost.title}
                                        date={blogPost.createdAt}
                                        content={blogPost.content}
                                        blogID={blogPost._id}
                                    />
                                </Fragment>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <Footer/>
    </Fragment>;
};

export default Blog;