import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    input: {
        padding: "6px !important"
    },
    savedInput: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    iconContainer: {
        cursor: "pointer"
    },
    questionOptions: {
        justifyContent: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    renderOptionsContainer: {
        justifyContent: "start",
        flexDirection: "column",
        maxWidth: "fit-content",
        display: "flex",
    }
});

export default useStyles;