import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Wallet = ({
    color = "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M24.116 15.395a1.302 1.302 0 1 1-2.604 0 1.302 1.302 0 0 1 2.604 0Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M12.322 4h4.054c2.393 0 4.289 0 5.772.2 1.527.205 2.763.637 3.738 1.612 1.204 1.204 1.587 2.816 1.727 4.908.751.33 1.319 1.03 1.382 1.917.005.078.005.163.005.241v5.034c0 .079 0 .164-.005.242-.063.886-.63 1.586-1.382 1.917-.14 2.092-.523 3.704-1.727 4.908-.975.974-2.21 1.407-3.738 1.612-1.483.2-3.379.2-5.772.2h-4.054c-2.393 0-4.29 0-5.773-.2-1.527-.205-2.763-.638-3.737-1.612-.975-.975-1.407-2.21-1.613-3.738C1 19.758 1 17.862 1 15.47v-.147c0-2.393 0-4.29.2-5.773.205-1.527.637-2.763 1.612-3.737.974-.975 2.21-1.407 3.737-1.613C8.033 4 9.93 4 12.322 4Zm13.315 16.28h-2.523c-2.793 0-5.184-2.12-5.184-4.885 0-2.764 2.39-4.883 5.184-4.883h2.523c-.148-1.747-.483-2.67-1.133-3.319-.55-.551-1.306-.881-2.616-1.057-1.338-.18-3.102-.183-5.586-.183h-3.907c-2.483 0-4.247.003-5.586.183-1.31.176-2.065.506-2.616 1.057-.551.551-.881 1.306-1.057 2.616-.18 1.339-.183 3.103-.183 5.586 0 2.484.003 4.248.183 5.586.176 1.31.506 2.065 1.057 2.616.551.552 1.306.882 2.616 1.058 1.339.18 3.103.182 5.586.182h3.907c2.484 0 4.248-.002 5.586-.182 1.31-.176 2.065-.506 2.616-1.058.65-.649.985-1.57 1.133-3.318ZM6.21 10.185c0-.54.438-.977.977-.977h5.21a.977.977 0 1 1 0 1.954h-5.21a.977.977 0 0 1-.977-.977Zm20.412 2.28h-3.507c-1.853 0-3.23 1.378-3.23 2.93 0 1.55 1.377 2.93 3.23 2.93h3.507a.99.99 0 0 0 .03-.001h.002c.269-.017.384-.198.393-.307V12.773c-.009-.11-.124-.29-.393-.307h-.032Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Wallet;
