import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CandidateInfoPropsTypes
} from "./types";
import SendMesage from "../sentMessage";
import DropDown from "../dropDown";
import moment from "moment";
import {
    CANDIDATE_STATUS
} from "../../constants";
import {
    ProfileIcon
} from "../../../../../assets/svgr";
import {
    useNavigate
} from "react-router-dom";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    getErrorText
} from "../../../../../utils";

const CandidateInfo = ({
    candidateContactDropdownRef,
    setShowCandidateContact,
    handleCandidateStatus,
    setCurrentCandidate,
    showCandidateContact,
    statusDropdownRef,
    currentCandidate,
    setSelectedStatus,
    selectedStatus,
    setIsStatus,
    isStatus
}: CandidateInfoPropsTypes) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const handleChatStart = () => {
        if(!currentCandidate?._id) {
            return;
        }
        RESTService.action("ConversationStart", {
            applicationID: currentCandidate?._id,
        })
            .then((res) => {
                navigate("/app/chat", {
                    state: {
                        conversationID: res.conversationID
                    }
                });
            })
            .catch((err) => {
                console.error("ConversationStart err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            })
        ;
    };

    return <div
        style={{
            padding: spaces.container
        }}
    >
        <div
            className={classes.advertDetailsContent}
        >
            <Text
                variant="header4-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.content
                }}
            > 
                {localize("application-of",[currentCandidate.fullName])}
            </Text>
            <div
                className={classes.generalInfo}
                style={{
                    marginBottom: spaces.inline,
                    gap: spaces.inline / 2
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {currentCandidate.occupation.localizedText}
                </Text>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {currentCandidate.city.localizedText}
                </Text>
            </div>
            <Text
                variant="body4-regular"
                color="textSecondary"
            >
                {moment(currentCandidate.appliedAt).fromNow()}
            </Text>
        </div>
        <div
            className={classes.buttonContent}
            style={{
                marginTop: spaces.content,
                gap: spaces.content
            }}
        >
            <DropDown
                dropdownref={statusDropdownRef}
                data={CANDIDATE_STATUS.map((item, index) => {
                    return {
                        __key: item.value,
                        __title: localize(item.title),
                        __originalIndex: index
                    };
                })}
                buttonTitle={localize("choose-status")}
                initialData={[{
                }]}
                onClickOpen={() => {
                    setIsStatus(true);
                }}
                onClose={() => {
                    setIsStatus(false);
                }}
                isVisible={isStatus}
                setSelectedItems={(item) => {
                    setSelectedStatus(item.map((c_item) => {
                        return {
                            __key: c_item.__key,
                            __title: c_item.__title,
                            __originalIndex: c_item.__originalIndex
                        };
                    }));
                }}
                multiSelect={false}
                selectedItems={selectedStatus}
                isNeedConfirm={true}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess,
                }) => {
                    //@ts-ignore
                    handleCandidateStatus(selectedItems[0].__key);
                    closeSheet();
                    onSuccess();
                }}
            />
            <SendMesage
                setDropdownVisible={setShowCandidateContact}
                onClickSendMessage={() => {
                    handleChatStart();
                }}
                dropdownref={candidateContactDropdownRef}
                isOptionsVisible={showCandidateContact}
                phoneNumber={currentCandidate.phone}
            />
            <Button
                title={localize("see-profile")}
                onClick={() => {
                    navigate("/app/profileUser", {
                        state: {
                            workerID: currentCandidate?.userID
                        }
                    }); 
                }}
                variant="outline"
                size="medium"
                icon={() => <ProfileIcon
                    color={colors.primary}
                />}
            />
        </div>
    </div>;
};

export default CandidateInfo;
