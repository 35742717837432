import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Text 
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    useEffect
} from "react";
import {
    useGlobalState 
} from "../../../context/globalState";
import { 
    useNavigate,
    useParams 
} from "react-router-dom";
import {
    shareDirections 
} from "./constants";
import {
    getUserType 
} from "../../../utils";

const Share = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const {
        dataType, dataID
    } = useParams();

    const [globalState, setGlobalState] = useGlobalState();

    useEffect(() => {
        let userType = getUserType(globalState?.user?.type);
        let redirectTo;
        let dataKey;

        if(userType) {
            if(dataType) {
                redirectTo = shareDirections?.[userType]?.[dataType]?.["redirectTo"];
                dataKey = shareDirections?.[userType]?.[dataType]?.["dataKey"];
            }

            if(dataKey) {
                navigate(`${redirectTo}`, {
                    state: {
                        [dataKey]: dataID
                    }
                }); 
            } else if(redirectTo) {
                navigate(redirectTo);
            } else {
                navigate("/home");
            }
        }
    }, [globalState?.user?.type]);

    return <div
        className={classes.container}
    >
        <Text 
            variant="header2-regular"
            style={{
                marginBottom: spaces.containerLarge
            }}
        >
            {localize("redirecting")}...
        </Text>
        <Loading/>
    </div>;
};

export default Share;