import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    emptyComapnyInfo: {
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "95%"
    },
    removeDiv: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
});
export default useStyles;