import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Calender = ({
    color = "#2563EB",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M8.95 2.037a1.037 1.037 0 0 0-2.073 0v2.184c-1.99.16-3.297.55-4.257 1.51-.96.96-1.351 2.266-1.51 4.257h27.435c-.16-1.99-.55-3.297-1.51-4.257-.96-.96-2.267-1.35-4.257-1.51V2.037a1.037 1.037 0 0 0-2.074 0v2.092c-.92-.018-1.951-.018-3.111-.018h-5.531c-1.16 0-2.192 0-3.111.018V2.037Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M28.654 15.173c0-1.16 0-2.191-.017-3.111H1.017C1 12.982 1 14.012 1 15.172v2.766c0 5.215 0 7.822 1.62 9.442S6.847 29 12.062 29h5.53c5.215 0 7.822 0 9.442-1.62s1.62-4.227 1.62-9.442v-2.765Zm-11.061.345a2.42 2.42 0 0 0-2.42 2.42v2.766a2.42 2.42 0 0 0 4.84 0v-2.766a2.42 2.42 0 0 0-2.42-2.42Zm0 2.075a.346.346 0 0 0-.346.345v2.766a.346.346 0 0 0 .691 0v-2.766a.346.346 0 0 0-.345-.345Zm-4.443-1.995c.387.16.64.538.64.958v5.53a1.037 1.037 0 1 1-2.074 0V19.06l-.304.304a1.037 1.037 0 1 1-1.466-1.467l2.074-2.074a1.037 1.037 0 0 1 1.13-.224Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Calender;
