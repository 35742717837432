import{
    useEffect,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    Loading,
    ToolBox,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    VerticalDotsIcon,
    WidgetAddIcon,
    CloseIcon,
    PlusIcon,
    PenIcon
} from "../../../../../../assets/svgr";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import TrashOutline from "../../../../../../assets/svgr/trashOutlineIcon";
import {
    CreateAlarm,
    DeleteAlarm,
    EditAlarm 
} from "./view";
import {
    RESTService 
} from "../../../../../../services/restAPI";
import {
    GetAlarmsResponseObjectType
} from "../../../../../../services/restAPI/actions/getAlarms/types";
import {
    SelectedForeignLanguageType,
    SelectedEducationLevelType,
    SelectedCapabilitiesType,
    DisabledCandidatesType,
    SelectedOccupationType,
    SelectedCitiesType
} from "./types";
import {
    GetMyProfileResponseTypes 
} from "../../../../../../services/restAPI/actions/getMyProfile/types";
import {
    getErrorText 
} from "../../../../../../utils";
import {
    PersonalInfoTypes 
} from "../../types";
import {
    EmployerProfileLeftContainer 
} from "../../../../../../components";

const MyCandidateAlarms = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getMyProfile, setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [loadingProfilePhoto, setLoadingProfilePhoto] = useState(true);
    const localStorageData: string | null = window.localStorage.getItem("company");

    let company: PersonalInfoTypes;
    if (localStorageData) {
        company = JSON.parse(localStorageData);
    }

    useEffect(() => {
        handleGetMyProfile();
    },[]);


    const handleGetMyProfile = () => {
        setLoadingProfilePhoto(true);
        RESTService.action("GetMyProfile",{
        }).then((res) => {
            setGetMyProfile(res);
            setLoadingProfilePhoto(false);
        }).catch((err) => {
            console.error("GetMyProfile Err:", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const [selectedForeignLanguage, setSelectedForeignLanguage] = useState<Array<SelectedForeignLanguageType>>([]);
    const [selectedEducationLevel, setSelectedEducationLevel] =  useState<Array<SelectedEducationLevelType>>([]);
    const [selectedCapabilities, setSelectedCapabilities] = useState<Array<SelectedCapabilitiesType>>([]);
    const [isCandidatesForDisabled, setIsCandidatesForDisabled] = useState<DisabledCandidatesType>("all");
    const [selectedOccupation, setSelectedOccupation] =  useState<Array<SelectedOccupationType>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>([]);
    const [alarmData, setAlarmData] = useState<Array<GetAlarmsResponseObjectType>>([]);
    const [alarmTitle, setAlarmTitle] = useState<string>("");

    const [transactionPageModal, setTransactionPageModal] = useState({
        showPageModal: false,
        transactions: "",
        alarmID:  ""
    });

    useEffect(() =>{
        getAlarms();
    }, []);

    const createSelectedData = (id: string) =>{

        let _selectedForeignLanguage: Array<SelectedForeignLanguageType> = [];
        let _selectedEducationLevel: Array<SelectedEducationLevelType> = [];
        let _selectedCapabilities: Array<SelectedCapabilitiesType> = [];
        let _isCandidatesForDisabled: DisabledCandidatesType = "all";
        let _selectedOccupation: Array<SelectedOccupationType> = [];
        let _selectedCities: Array<SelectedCitiesType> = [];

        alarmData.map((item)=>{
            if(item._id !== id){
                return;
            }

            setAlarmTitle(item.title);
            item.matchParameters.foreignLanguage?.map((foreignLanguageItem) =>{
                _selectedForeignLanguage.push({
                    localizedText: foreignLanguageItem.localizedText,
                    _id: foreignLanguageItem._id
                });
            });
            item.matchParameters.educationLevel?.map((educationLevelItem) =>{
                _selectedEducationLevel.push({
                    localizedText: educationLevelItem.localizedText,
                    _id: educationLevelItem._id
                });
            });
            item.matchParameters.capability?.map((capabilityItem) =>{
                _selectedCapabilities.push({
                    localizedText: capabilityItem.localizedText,
                    _id: capabilityItem._id
                });
            });
            item.matchParameters.occupation?.map((occupationItem) =>{
                _selectedOccupation.push({
                    localizedText: occupationItem.localizedText,
                    _id: occupationItem._id
                });
            });
            item.matchParameters.city?.map((cityItem) =>{
                _selectedCities.push({
                    localizedText: cityItem.localizedText,
                    _id: cityItem._id
                });
            });
            if(item.matchParameters.isDisabledPeople === true ){
                _isCandidatesForDisabled = "true";
            }else if(item.matchParameters.isDisabledPeople === false){
                _isCandidatesForDisabled = "false";
            }
        });
        setIsCandidatesForDisabled(_isCandidatesForDisabled);
        setSelectedForeignLanguage(_selectedForeignLanguage);
        setSelectedEducationLevel(_selectedEducationLevel);
        setSelectedCapabilities(_selectedCapabilities);
        setSelectedOccupation(_selectedOccupation);
        setSelectedCities(_selectedCities);
    };


    const getAlarms = () => {
        RESTService.action("GetAlarms", {
            
        })
            .then((res) => {
                setAlarmData(res);
            })
            .catch((err) => {
                console.error("GetAlarms Err:", err);
            });
    };
    
    const renderLine = () => (
        <div
            className={classes.borderLine}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke,
            }}
        >
        </div>
    );

    const renderTransactionSelectionsButton = (alarm: GetAlarmsResponseObjectType) =>{
        return <div
        >
            <ToolBox
                content={renderTransactionSelectionsContainer(alarm)}
            >
                <Button
                    onClick={()=>{
                    
                    }}
                    icon={() => <VerticalDotsIcon
                        color={colors.black100}
                        size={36}
                    />}
                    variant="ghost"
                />
            </ToolBox>
        </div> ;
    };

    const renderTransactionSelectionsContainer = (alarm: GetAlarmsResponseObjectType) =>{
        return <div
            className={classes.transactionSelectionsContainer}
        >
            <div
                className={classes.closeButtonField}
            >
                <Button
                    onClick={()=>{
                    }}
                    icon={() => <CloseIcon
                        color={colors.textGrey}
                        size={14}
                    />}
                    variant="ghost"
                />
            </div>
            <div 
                className={classes.buttonsField}
                style={{
                    marginRight: spaces.container * 3
                }}
            >
                <Button
                    title={localize("edit")}
                    textVariant="body2-regular"
                    color="textGrey"
                    variant="ghost"
                    icon={() => <PenIcon
                        color={colors.textGrey}
                        size={20}
                    />
                    }
                    onClick={()=>{
                        createSelectedData(alarm._id);
                        setTransactionPageModal({
                            transactions: "edit-alarm",
                            alarmID: alarm._id,
                            showPageModal: true,
                        });
                    }}
                />
                <Button
                    textVariant="body2-regular"
                    title={localize("delete")}
                    color="textGrey"
                    variant="ghost"
                    icon={() => <TrashOutline
                        color={colors.textGrey}
                        size={20}
                    />
                    }
                    onClick={()=>{
                        setTransactionPageModal({
                            transactions: "delete-alarm",
                            alarmID: alarm._id,
                            showPageModal: true
                        });
                    }}
                />
            </div>
        </div>;
    };

    const renderFilters = (item: GetAlarmsResponseObjectType) =>{
        let counter = 0;
        return <div
            className={classes.filtersContainer}
            style={{
                gap: spaces.inline / 2
            }}
        >  
            {
                item.matchParameters.occupation.map((filtersItem) =>{
                    counter++;
                    return  <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{filtersItem.localizedText}
                            </Text>
                        }
                    </div>;
                })
            }
            {
                item.matchParameters.city?.map((filtersItem) =>{
                    counter++;
                    return  <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{filtersItem.localizedText}
                            </Text>
                        }
                    </div>;
                })
            }
            {
                item.matchParameters.capability?.map((filtersItem) =>{
                    counter++;
                    return  <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{filtersItem.localizedText}
                            </Text>
                        }
                    </div>;
                })
            }
            {
                item.matchParameters.foreignLanguage?.map((filtersItem) =>{
                    counter++;
                    return  <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{filtersItem.localizedText}
                            </Text>
                        }
                    </div>;
                })
            }
            {
                item.matchParameters.educationLevel?.map((filtersItem) =>{
                    counter++;
                    return  <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{filtersItem.localizedText}
                            </Text>
                        }
                    </div>;
                })
            }
            {
                item.matchParameters.isDisabledPeople === true ?
                    <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{localize("show-only-disabled-candidates")}
                            </Text>
                        }
                    </div>
                    : item.matchParameters.isDisabledPeople === false &&
                    <div>
                        {
                            counter < 4 &&
                            <Text
                                variant="body3-regular"
                                color="textGrey"
                            >
                                •{localize("dont-show-disabled-candidates")}
                            </Text>
                        }
                    </div>
            }
        </div>;
    };

    const renderAlarmField = () =>{
        return <div
            className={classes.alarmField}
        >
            {
                alarmData.map((item, index: number) => {
                    return <div
                        className={classes.alarmContainer}
                    >
                        <div
                            className={classes.alarmContent}>
                            <div
                                className={classes.textContainer}
                                style={{
                                    padding: spaces.content,
                                    gap: spaces.inline
                                }}
                            >
                                <Text
                                    variant="body2-medium"
                                    color="black"
                                >
                                    {item.title}
                                </Text>
                                {renderFilters(item)}
                            </div>
                            {renderTransactionSelectionsButton(item)}
                        </div>
                        {renderLine()}
                    </div>;
                })
            }
        </div>;
    };

    const renderButtonField = () =>{
        return <Button
            title={localize("create-new-alarm")}
            variant="ghost"
            textVariant="body2-medium"
            spreadBehaviour="free"
            icon={() => <PlusIcon
                color={colors.primary}
                size={20}
            />}
            onClick={() =>{
                setTransactionPageModal({
                    transactions: "create-alarm",
                    showPageModal: true,
                    alarmID: ""
                });
            }}
            style={{
                marginTop:spaces.content
            }}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: spaces.container * 2,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.headerField}
                style={{
                    marginBottom: spaces.container
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("my-candidate-alarm")}
                </Text>
            </div>
            {renderLine()}
            <div
                className={classes.content}
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {renderAlarmField()}
                {renderButtonField()}
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderTransactionPageModal = () => {
        return<PageModal
            isVisible={transactionPageModal.showPageModal}
            contentContainerStyle={{
                backgroundColor: colors.white,
                maxWidth: "80%"
            }}
            onOverlayPress={() =>{
                setTransactionPageModal({
                    showPageModal: false,
                    transactions: "",
                    alarmID: ""
                });
            }}
            content={
                transactionPageModal.transactions === "create-alarm" ?
                    <CreateAlarm
                        setAlarmData={setAlarmData}
                        alarmData={alarmData}
                        onClickCloseButton = {() =>{
                            setTransactionPageModal({
                                showPageModal: false,
                                transactions: "",
                                alarmID: ""
                            });
                        }}
                    />
                    :
                    transactionPageModal.transactions === "edit-alarm" ?
                        <EditAlarm
                            setSelectedForeignLanguages={setSelectedForeignLanguage}
                            setIsCandidatesForDisabled={setIsCandidatesForDisabled}
                            setSelectedEducationLevels={setSelectedEducationLevel}
                            selectedForeignLanguages={selectedForeignLanguage}
                            isCandidatesForDisabled={isCandidatesForDisabled}
                            setSelectedCapabilities={setSelectedCapabilities}
                            selectedEducationLevels={selectedEducationLevel}
                            setSelectedOccupation={setSelectedOccupation}
                            selectedCapabilities={selectedCapabilities}
                            selectedOccupation={selectedOccupation}
                            alarmID={transactionPageModal.alarmID}
                            setSelectedCities={setSelectedCities}
                            selectedCities={selectedCities}
                            setAlarmTitle = {setAlarmTitle}
                            alarmTitle={alarmTitle}
                            setAlarmData={setAlarmData}
                            alarmData={alarmData}
                            onClickCloseButton = {() =>{
                                setTransactionPageModal({
                                    showPageModal: false,
                                    transactions: "",
                                    alarmID: ""
                                });
                            }}
                        />
                        :
                        <DeleteAlarm
                            setAlarmData={setAlarmData}
                            alarmData={alarmData}
                            alarmID={transactionPageModal.alarmID}
                            onClickCloseButton = {() =>{
                                setTransactionPageModal({
                                    showPageModal: false,
                                    transactions: "",
                                    alarmID: ""
                                });
                            }}
                        />
            }
        />;
    };

    if(loadingProfilePhoto) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("company-profile-screen")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <EmployerProfileLeftContainer/>
            {renderContentContainer()}
            {renderRightContainer()}
            {renderTransactionPageModal()}
        </div>
    </div>;
};

export default MyCandidateAlarms;
