import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    carousel: {
        position: "relative",
        overflow: "hidden",
        maxWidth: 330,
        width: "100%"
    },
    carouselTrack: {
        transition: "transform 0.5s ease-in-out",
        display: "flex"
    },
    card: {
        justifyContent: "center",
        boxSizing: "border-box",
        minWidth: "100%",
        display: "flex",
        maxWidth: 200
    },
    dots: {
        justifyContent: "center",
        marginTop: "10px",
        display: "flex"
    },
    dot: {
        transition: "background-color 0.6s ease",
        display: "inline-block",
        cursor: "pointer",
        height: 10,
        width: 10
    },
    "@media screen and (max-width: 600px)": {
        card: {
            justifyContent: "center",
            boxSizing: "border-box",
            display: "flex"
        },
    },
});


export default useStyles;