import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    contentBubbleContainer: {
        flexDirection: "column",
        display: "flex",
    },
    contentBubble: {
        boxSizing: "border-box",
        alignItems: "flex-end",
        position: "relative",
        display: "flex"
    },
    statusIconField: {
        flexDirection: "row",
        alignItems: "end",
        display: "flex",
    },
    messageBubbleField:{
        justifyContent: "end",
        alignItems: "flex-end",
        flexDirection:"row"
    }
});
export default useStyles;