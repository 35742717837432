import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Copy = ({
    color = "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M19.68 1h-5.625c-2.548 0-4.567 0-6.146.207-1.626.212-2.942.66-3.98 1.67-1.038 1.01-1.498 2.29-1.717 3.873C2 8.287 2 10.25 2 12.73v8.173c0 2.111 1.329 3.92 3.217 4.68-.097-1.274-.097-3.06-.097-4.547v-7.013c0-1.795 0-3.34.17-4.579.184-1.327.598-2.599 1.658-3.63 1.06-1.032 2.367-1.434 3.73-1.613 1.272-.166 2.861-.166 4.705-.166H19.817c1.844 0 3.429 0 4.701.166C23.758 2.327 21.878 1 19.68 1Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M7.2 14.156c0-3.816 0-5.725 1.218-6.91C9.637 6.06 11.598 6.06 15.52 6.06h4.16c3.922 0 5.883 0 7.102 1.186C28 8.43 28 10.34 28 14.156v6.748c0 3.816 0 5.725-1.218 6.91C25.563 29 23.602 29 19.68 29h-4.16c-3.922 0-5.883 0-7.101-1.186C7.2 26.63 7.2 24.72 7.2 20.904v-6.748Z"
        />
    </svg>;
};
export default Copy;
