import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        width: "100%",
        flexDirection: "column",
        display: "flex",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.75
        },
    },
    informationTextStyle: {
        flexDirection: "column",
        display: "flex"
    },
    bookmarkStyle: {
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        height: 36,
        width: 36
    },
    renderInformationsStyle: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex",
    },
    imageStyle: {
        objectFit: "cover",
        height: 56,
        width: 56
    },
    renderTagsStyle: {
        width: "100%",
        height: 37
    },
});
