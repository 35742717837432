import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "start",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        maxHeight: "100%",
        overflowY: "auto",
        display: "flex",
        height: "100%",
        width: "50%",
        "@media (max-width: 1040px)": {
            width: "100%"
        },
        "@media (max-height: 600px)": {
            width: "100%"
        },
    },
    loginContainer: {
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        maxWidth: 400,
        width: "100%"
    },
    buttonContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    logoContainer: {
        display: "flex",
        width: "100%"
    },
    logo: {
        justifySelf: "center",
        alignItems: "center",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex"
    },
    backButton: {
        alignItems: "center",
        display: "flex"
    },
    backButtonIcon: {
        transition: ".2s linear",
        animation: "$fadeIn .2s",
        minWidth: 30,
        opacity: 1,
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8
        }
    },
    stepContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        height: 6
    },
    step: {
        height: "100%",
        width: "100%",
        "&:not(:last-child)": {
            marginRight: 4,
        },
    },
    "@keyframes fadeIn": {
        "from": {
            opacity: 0 
        },
        "to": {
            opacity: 1 
        }
    },
    alreadyHaveAnAccount: {
        display: "inline",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    },
});

export default useStyles;