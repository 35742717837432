const en: {
    code: string;
    isRTL: boolean;
    translations: IOCore.TranslationType;
} = {
    "code": "en-US",
    "isRTL": false,
    "translations": {
        "network-waiting": "Network Waiting",
        "IOCoreDefaultDialogSecondaryButtonTitle": "Cancel",
        "IOCoreDefaultDialogPrimaryButtonTitle": "Ok",
        "IOCoreSelectBoxSelectedText": "Choice",
        "IOCoreSelectBoxNoSelectionText": "No selection",
        "IOCoreSelectBoxMinSelectionWarningText": "At least ${0} selection is required.",
        "IOCoreSelectBoxMaxSelectionReachedText": "You cannot choose any more.",
        "IOCoreSelectBoxSelectAll": "Select All",
        "IOCoreSelectBoxClearAll": "Clear All",
        "iocore-select-box-no-selection": "No Selection",
        "iocore-select-sheet-clear-button": "Clear",
        "iocore-select-sheet-ok-button": "OK",
        "iocore-select-box-n-selected": "{{0}} Choice",
        "iocore-select-sheet-max-choice": "Maximum {{0}} can be selected.",
        "iocore-select-sheet-min-choice": "You must choose at least {{0}}.",
        "active-theme": "Theme",
        "active-language": "Language",
        "isinolacak-cl": "IOCore Mobile",
        "welcome-description": "Welcome to IOCore Design System Component Library",
        "optional": "Optional",
        "selectionCount": "Selections",
        "advert-sticker-announcement": "Announcement",
        "advert-sticker-featuredAds": "Featured Ad",
        "advert-sticker-newAd": "New Ad",
        "advert-sticker-trusted": "Trusted",
        "e-mail": "E-Mail",
        "finish":"Finish",
        "next": "Next",
        "your-remaining-time-for-your-password": "Your remaining time for your password",
        "you-have-logged-in-incorrectly-please-try-again": "Hatalı giriş yaptınız, lütfen tekrar deneyiniz",
        "resend-password": "Resend Password",
        "aplicant-card-for-employer-sticker-expired": "The job posting period has expired.",
        "aplicant-card-sticker-days-ago": "{{0}} days ago",
        "aplicant-card-for-employer-sticker-documents": "The entry documents have arrived.",
        "aplicant-card-for-employer-sticker-interview": "The video interview has concluded.",
        "aplicant-card-for-employer-sticker-performance-test": "The performance test has concluded.",
        "aplicant-card-for-employer-sticker-hasPT": "Performance test assigned",
        "aplicant-card-for-employer-sticker-waiting-for-performance-test": "Waiting for performance test",
        "aplicant-card-for-employer-sticker-avarage-points": "{{0}} Average",
        "aplicant-card-for-employer-sticker-number-of-aplicants": "{{0}} Application",
        "aplicant-card-for-employer-sticker-number-of-last-week": "Last {{0}} week",
        "myActions-for-employer-past-ads-sticker": "Past Advertisements",
        "myActions-for-employer-current-ads-sticker": "Current Ads",
        "myActions-for-employer-performance-test-detail": "Performance Test Detail",
        "myActions-for-employer-entry-documents-control": "Entry Documents Control",
        "myActions-for-employer-entry-ınterview-review": "Interview Review",
        "register-corparate-phone-number-error": "Corporate Phone Number Error",
        "register-phone-number-error": "Phone Number Error", 
        "x1": "Hello. Test Success.",
        "notifications": "Notifications",
        "notification-description": "I want to receive notifications on the desktop about campaigns and job postings.",
        "sms": "SMS",
        "search-by-phone": "Search by phone",
        "communication-preferences": "Communication Preferences",
        "contact-us": "Contact Us",
        "enter-your-information.": "Enter your information",
        "your-message": "Your Message",
        "save": "Save",
        "city": "City",
        "description": "Description",
        "write-a-description": "Write a Description",
        "education": "Education",
        "add-education": "Add Education",
        "work-experience": "Work Experience",
        "add-work-experience": "Add Work Experience",
        "add": "Add",
        "capabilities": "Capabilities",
        "level": "Level",
        "language": "Language",
        "certificate-name": "Certificate Name",
        "received-organization": "Received Organization",
        "add-capabilities": "Add Capabilities",
        "add-language": "Add Language",
        "add-license-and-certificate": "Add License and Certificate",
        "association-or-organization-membership": "Association or organization membership",
        "cv": "CV",
        "postings-i-applied-to": "Postings i applied to",
        "see-all": "See All",
        "name": "Name",
        "surname": "Surname",
        "phone-number": "Phone Number",
        "job": "Job",
        "company": "Company",
        "personal-information": "Personal Info",
        "change-language": "Change Language",
        "asked-questions": "Asked Questions",
        "privacy-policy": "Privacy Policy",
        "delete-the-account": "Delete the account",
        "log-out": "Log out",
        "settings": "Settings",
        "see-today's-ads": "See Today's Ads",
        "we-have-compiled-the-popular-ads-of-the-day-for-you.": "We have compiled the popular ads of the day for you.",
        "person-profile": "Person Profile",
        "question": "Question",
        "ı-want-to-receive-emails-about-campaigns": "I want to receive emails about campaigns.",
        "needs-to-be-filled": "Needs to be filled",
        "license-and-certificate": "License and certificate",
        "add-association-or-organization-membership": "Add association or organization membership",
        "your-ads-will-be-displayed-here-create-an-ad-now-and-start-ad-management": "Your ads will be displayed here. Create an ad now and start ad management",
        "please-enter-your-new-e-mail": "Please enter your new e-mail",
        "corporate-email-update": "Corporate email update",
        "email-update": "Email Update",
        "phone-number-update": "Phone No Update",
        "please-enter-your-new-contact-number": "Please enter your new contact number",
        "contact-number-update": "Contact number update",
        "verify": "Verify",
        "enter-the-six-digit-password-sent-via-sms...": "Enter the 6-digit password sent via SMS...",
        "my-ads": "My Ads",
        "document-list": "Document List",
        "company's-trade-name": "Company's trade name",
        "mersis-no": "Mersis No",
        "your-role-in-the-company": "Your role in the company",
        "sectors": "Sektör",
        "company-e-mail": "Company E-Posta",
        "website": "Website",
        "company-contact-number": "Company contact number",
        "about": "About",
        "create-new-ad": "Create New Ad",
        "find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.": "Find the employee you are looking for immediately by creating a new ad.",
        "company-profile-screen": "Company Profile",
        "tr": "Turkish",
        "en": "English",
        "tr-TR": "Turkish",
        "en-US": "English",
        "degree": "Degree",
        "title": "Title",
        "end-date": "End Date",
        "start-date": "Start Date",
        "ı'm-still-working-here": "I'm Still Working Here",
        "education-level": "Education Level",
        "school": "School",
        "major": "Major",
        "aplicant-card-for-employer-sticker-check-documents": "Check the documents.",
        "aplicant-card-for-employer-sticker-review-interview": "Review the interview.",
        "aplicant-card-for-employer-sticker-review-performance-test": "Review the performance test.",
        "applicant-card-upload-documents": "Upload the documents",
        "applicant-card-join-interview": "Join the interview",
        "applicant-card-complete-performance-test": "Complete the performance test",
        "aplicant-card-sticker-aplication-being-reviewed": "Your application is under review",
        "aplicant-card-sticker-aplication-submited": "Application Submitted",
        "aplicant-card-sticker-documents-submited": "Entry documents submitted",
        "aplicant-card-sticker-performance-test-submited": "Performance test submited",
        "aplicant-card-sticker-video-interview-invitation": "Inviting to video interview",
        "enter-the-password-sent-via-sms": "Enter the password sent via SMS",
        "post-an-ad": "Post an ad",
        "you-will-view-your-activities-for-your-live-advertisements-on-this-page": "You will view your activities for your live advertisements on this page",
        "this-place-is-empty-right-now": "This place is empty right now",
        "overview": "General Overview",
        "myActions-worker-ongoing-applications": "Ongoing applications",
        "myActions-worker-saved": "Saved",
        "myActions-worker-past": "Past",
        "myActions-worker-entry-documents-submitted":"Entry documents submitted",
        "myActions-worker-video-ınterview": "Video Interview",
        "myActions-worker-performance-testing": "Performance Testing",
        "search-ads-for-you": "Search for listings suitable for you",
        "search-ads": "Search Ads",
        "filter-and-sort": "Filter & Sort",
        "no-result-short": "The listing you are looking for could not be found.",
        "no-result-long": "We couldn't find the listing you're looking for... To achieve accurate results, you should consider the following:",
        "recomended": "Recomended",
        "new-to-old": "New to Old",
        "old-to-new": "Old to New",
        "todays-ad": "Today's Ads",
        "this-weeks-ads": "Last 1 Week",
        "last-12-hours": "Last 12 hours",
        "last-3-hours": "Last 3 hours",
        "all": "All",
        "only-disabled-ads": "Show only disability-friendly listings",
        "no-disabled-ads": "Do not show disability-friendly listings",
        "hide-aplied-ads": "Hide aplied listings",
        "choosen-filters": "Choosen Filters",
        "clear-filters": "Clear Filters",
        "discover": "Discover",
        "popular-searches": "Popular Searches",
        "previus-page": "Previus",
        "next-page": "Next",
        "location": "Location",
        "no-result-found": "No result found",
        "work-preference": "Work Preference",
        "working-type": "Working Type",
        "ads-date": "Advert Date",
        "search-job": "Search Occupation",
        "position-level": "Position Level",
        "ads-for-disabled": "Disability-Friendly Listings",
        "other-ads-filters": "Other Listing Filters",
        "filters": "Filters",
        "filter": "Filter",
        "sorting": "Sorting",
        "location-city": "Location / City",
        "filter-it": "Filter",
        "hide-revieved-ads": "Hide reviewed listings",
        "only-1-time-ads": "Listings published only once",
        "no-result-1": "Make sure to check for any spelling errors.",
        "no-result-2": "You can use more widely understood terms. (e.g., use 'HR' instead of 'Human Resources')",
        "no-result-3": "You can use different terms. (e.g., use 'Backend Software Specialist' instead of 'Software Developer')",
        "no-result-4": "Instead of using abbreviations, you can write out the words explicitly. (e.g., use 'Engineer' instead of 'Eng')",
        "no-result-5": "The profile and listings of blocked companies are not visible in search results. Make sure the search term you're using is not related to a company you have blocked.",
        "profile": "Profile",
        "address": "Address",
        "county": "County",
        "security": "Security",
        "security-description": "Do not show my profile in the CV pool.",
        "ıf-you-return-this-transaction-will-be-canceled": "If you return, this transaction will be canceled",
        "you-are-about-to-cancel-the-transaction": "You are about to cancel the transaction",
        "give-up": "Give Up",
        "cancel": "Cancel",
        "login": "Login",
        "register": "Register",
        "create-document": "Create Document",
        "create-performance-test": "Create Performance Test",
        "create-document-desc": "An area where you can create the documents you expect from job candidates.",
        "create-performance-test-desc": "Areas you can use to measure job candidates in various aspects",
        "register-name-length-error": "Name must be between 3 and 55 characters",
        "register-last-name-length-error": "Last name must be between 3 and 33 characters",
        "register-email-error": "Invalid email address",
        "register-password-error": "Password does not meet requirements",
        "register-company-title-error": "Company title must be between 3 and 100 characters",
        "register-company-district-error": "Company District Cannot Be Empty",
        "register-corparate-mail-error": "Invalid corporate email address",
        "register-with-personal-account": "Register with Personal Account",
        "register-with-company-account": "Register with Company Account",
        "register-company-city-error": "Company City Cannot Be Empty",
        "register-repeat-password-error": "Passwords do not match",
        "registration-information": "Registration Information",
        "register-sector-error": "Sector Cannot Be Empty",
        "password-verification": "Password Verification",
        "register-occupation-error": "Occupation Error",
        "register-area-code-error": "Area Code Error",
        "company-district": "Company's District",
        "company-website": "Company Website",
        "company-city": "Company's City",
        "password": "Password",
        "advert-steps": "Listing Steps",
        "create-advert": "Create Advert",
        "advert-header-&-desc": "Advert Header & Descrpition",
        "publish-advert": "Publish Advert",
        "job-title": "Job Title",
        "edit-list-search": "Search...",
        "advert-detail": "Advert Detail",
        "employer-title": "Employer Title",
        "working-time": "Working Time",
        "job-locale": "Job Location",
        "position-detail": "Position Detail",
        "abilities": "Abilities",
        "add-ability": "Add Ability",
        "paq": "Pre Assessment Quesitons",
        "add-new-question": "Add new question",
        "create-the-add": "Create Advert",
        "create": "Create",
        "advert-confirm": "Confirm",
        "suitable-for-disabled": "Suitable job advertisement for disabled candidates.",
        "myActions": "My Actions",
        "jobAdverts": "Job Advertisements",
        "myFavorites": "My Favorites",
        "myApplications": "My Applications",
        "findEmployee": "Find Employee",
        "blog": "Blog",
        "my-messages": "My Messages",
        "staff-search": "Staff Search",
        "management-panel": "Management Panel",
        "about-the-job-posting": "About the Job Posting",
        "application-process": "Application Process",
        "manage-advert": "Manage Ad",
        "edit-ad": "Edit Ad",
        "job-details": "Job Details",
        "applicant-candidates": "Applicant Candidates",
        "company-about": "Company About",
        "company-ınformation": "Company Information",
        "apply": "Apply",
        "manage-application": "Manage Application",
        "similar-job-postings": "Similar Job Postings",
        "we-don't-want-you-to-miss-the-employee-you-are-looking-for": "We don't want you to miss the employee you are looking for",
        "you-are-about-to-remove-your-ad": "You are about to remove your ad",
        "remove": "Remove",
        "send-reply": "Send Reply",
        "pre-assessment-questions": "Pre-Assessment Questions",
        "ı-applied": "I Applied",
        "documents-ı-sent": "Documents I Sent",
        "my-current-ads": "My Current Ads",
        "my-past-ads": "My Past Ads",
        "takedowns": "Takedowns",
        "my-draft-ads": "My Draft Ads",
        "saved": "Saved",
        "messages": "Messages",
        "remote-work-ads": "Remote Work Adverts",
        "part-time-work-ads": "Part-Time Work Adverts",
        "intern-work-ads": "Intern Work Adverts",
        "manager-work-ads": "Manager Work Adverts",
        "yes": "Yes",
        "no": "No",
        "access-denied": "Unauthorized access.",
        "company-not-found": "Company not found.",
        "advert-not-found": "Advert not found.",
        "user-must-have-cv-before-apply": "To apply, please create a CV first.",
        "already-have-an-application": "You have already applied for this advert.",
        "this-advert-is-deleted": "This advert is deleted.",
        "must-answer-pre-assesstment-questions-to-apply": "To apply, please answer pre assessment questions.",
        "advert-application-failed": "An error occurred in the advert application.",
        "ı-am-a-suitable-candidate-for-disabled-postings": "I am a suitable candidate for disabled postings.",
        "resume-approval": "Resume Approval",
        "hobbies": "Hobbies",
        "hundreds-of-employers-thousands-of-new-job-listings-every-day-platform": "Hundreds of employers, thousands of new job listings every day in this platform!",
        "not-a-member-sign-up": "Not a member? Sign up",
        "hundreds-of-position-details": "Hundreds of position details",
        "daily-updated-listings": "Daily updated listings",
        "thousands-of-free-listings": "Thousands of Free Listings",
        "easy-access-from-both-web-and-mobile": "Easy access from both web and mobile.",
        "which-work-model-suits-you-better": "Which Work Model Suits You Better?",
        "remote-work-model-or-shift-work-part-time-or-full-time-discover-the-work-model-that-suits-you-with-isinolacak-privilege": "Remote work model or shift work? Part-time or full-time? Discover the work model that suits you with isinolacak privilege.",
        "discover-now": "Discover Now",
        "popular-listings": "Popular Listings",
        "all-listings": "All Listings",
        "career-guide": "Career Guide",
        "featured-listings": "Featured Listings",
        "popular-positions": "Popular Positions",
        "connections": "Connections",
        "services": "Services",
        "all-rights-reserved": "All rights reserved.",
        "about-us": "About Us",
        "terms-of-use": "Terms of Use",
        "faq": "FAQ",
        "contact": "Contact",
        "istanbul-job-listings": "Istanbul Job Listings",
        "ankara-job-listings": "Ankara Job Listings",
        "bursa-job-listings": "Bursa Job Listings",
        "antalya-job-listings": "Antalya Job Listings",
        "search": "Search",
        "find-candidates": "Find Candidates",
        "no-aplicant-to-advert": "Your job posting has no applicants",
        "no-apply-for-now": "Currently, there are no applications.",
        "achieve-success-on-your-career-path": "Achieve Success on Your Career Path",
        "share-post": "Share Post",
        "contents": "Contents",
        "check-out-this-amazing-blog-post": "Check Out This Amazing Blog Post",
        "continue-reading": "Continue Reading",
        "about-job-advert": "About job advert",
        "isinolacak-logo": "Isinolacak Logo",
        "search-location": "Search Location",
        "search-occupation": "Seach occupation",
        "created-ads-will-be-listed-for-you-to-use-later": "Created Ads Will Be Listed For You to Use Later",
        "previously-created-documents": "Previously Created Documents",
        "creating-documents": "Creating Documents",
        "document-detail": "Document Detail",
        "new-document-name": "New Document Name",
        "add-document": "Add Document",
        "documents": "Documents",
        "use": "Use",
        "2-option-answer": "2 options respond",
        "first-option": "1. Option",
        "second-option": "2. Option",
        "third-option": "3. Option",
        "three-options": "3 options respond",
        "5-point-answer": "Rating on a scale of 1 to 5",
        "10-point-answer": "Rating on a scale of 1 to 10",
        "multi-select-answer": "Mulitple Choice",
        "open-ended-answer": "Open ended respond",
        "create-performance-test-title": "Create Performance Test",
        "create-new-pt": "Create New Performance Test",
        "delete-pt-warn-header": "You are about the delete performance test",
        "delete-pt-warn-content": "You can edit and use the test",
        "edit": "Edit",
        "delete-question": "Delete Question",
        "test-list": "Test List",
        "pt-test-header": "Performance Test Header",
        "header": "Header",
        "question-type": "Question Type",
        "add-question": "Add Question",
        "save-question": "Save Question",
        "create-test": "Create Test",
        "must-confirm-all-questions": "Must confitm all quesitons!",
        "expectation": "Expectation",
        "create-answer": "Add Answer",
        "ideal-responses": "Ideal response or responses",
        "save-questions": "Save Questions",
        "create-ad": "Create Ad",
        "no-messages-yet": "No Messages Yet",
        "view-actions-for-active-ads": "You will see your actions for active ads on this page.",
        "messaging": "Messaging",
        "advert-name": "{{0}} Ad",
        "terminated": "Terminated",
        "archived": "Archived",
        "terminate-chat": "Terminate Chat",
        "unarchive": "Unarchive",
        "archive": "Archive",
        "incoming-messages": "Incoming Messages",
        "applicants": "Applicants",
        "contacted-companies": "Contacted Companies",
        "unread": "Unread",
        "unread-message": "{{0}} Unread message",
        "enter-answer": "Please enter your answer",
        "enter-as-number": "Please enter your answer as number.",
        "advert-apply-success": "Aplication to advert is success !",
        "invalid-request": "Invalid Request",
        "something-went-wrong": "Something went wrong.",
        "sorry-we-couldnt-reach-the-page-you-were-looking-for-please-try-again": "Sorry, we couldn't reach the page you were looking for, please try again.",
        "go-back": "Go back",
        "job-title-and-description": "Job Title & Description",
        "position-details": "Position details",
        "job-approval": "Job Approval",
        "you-need-to-fill-out-this-field": "You need to fill out this field.",
        "job-creation-successful": "Job creation successful",
        "pricing": "Pricing",
        "home": "Home",
        "email-format-error": "Email format error",
        "password-length-limit": "Your password can be between 8 and 16 characters long.",
        "login-error": "An error occurred during login.",
        "forgot-password": "Forgot my password",
        "or": "or",
        "help": "Help",
        "data-policy": "Data Policy",
        "experience": "Experience",
        "job-postings": "Job Posting",
        "collapse": "Collapse",
        "not-yet-added": "Not Yet Added",
        "no-position-ınformation": "No Position Information",
        "no-university-ınformation": "No University Information",
        "no-location-ınformation": "No Location Information",
        "no-experience-added-yet": "No Experience Added Yet",
        "no-education-added-yet": "No Education Added Yet",
        "no-license-and-certification-added-yet": "No License and Certification Added Yet",
        "no-language-added-yet": "No Language Added Yet",
        "no-skills-added-yet": "No Skills Added Yet",
        "no-associations-an-organizations-added-yet": "No Associations and Organizations Added Yet",
        "no-hobbies-added-yet": "No Hobbies Added Yet",
        "no-job-postings-have-been-added-yet": "No job postings have been added yet",
        "management-of-application-advertisements": "Management of application advertisements",
        "application": "Application",
        "all-my-application-advertisements": "All my application advertisements",
        "applied-document": "Applied document",
        "performance-test-waiting": "Performance test waiting",
        "performance-test-submitted": "Performance Test Answered",
        "document-waiting": "Document list is awaited",
        "document-sent": "Document List Sent",
        "rejected": "Rejected",
        "applied": "Application was made",
        "document": "Document Waiting",
        "required-qualifications": "Required Qualifications",
        "required-qualifications-description": "It matches your abilities. You may be eligible for the job posting.",
        "skills-added-by-the-user-who-created-the-job-posting": "Skills Added by the User Who Created the Job Posting:",
        "see-company-profile": "See Company Profile",
        "finished": "Meeting completed",
        "yes-no-answer": "2 Option Answer",
        "numeric-answer": "Numeric Answer",
        "sector-information-was-not-entered": "Sector information was not entered",
        "no-website-information-entered": "No website information entered",
        "location-information-was-not-entered" :"Location information was not entered",
        "ıt-was-edited-successfully": "It was edited successfully",
        "ıt-was-added": "It was added",
        "saved-successfully": "Saved successfully",
        "updated-successfully": "Updated successfully",
        "performance-test-was-sent-successfully": "Performance test was sent successfully",
        "a-document-type-with-this-name-already-exists": "A document type with this name already exists",
        "document-created-successfully": "Document created successfully",
        "a-document-with-this-name-already-exists": "A document with this name already exists",
        "advert-no-doc": "There is no document related to this advert!",
        "advert-no-pt": "There is no performance test related to this advert!",
        "all-candidates": "All Candidates",
        "candidate-process": "Candidate Operations",
        "candidate-rejeceted": "The candidate's application was rejected.",
        "waiting-for-complete": "Expected to be Completed",
        "completed": "Completed",
        "pt-test": "Performance Test",
        "doc-operations": "Document Processes",
        "waiting-for-doc": "Waiting for Documents",
        "doc-sended": "Document sended",
        "doc-send": "Send Documents",
        "waiting-for-reupload-doc": "Documents Pending Again",
        "download-all": "Download All",
        "see-all-docs": "See all documents",
        "approved": "Approved",
        "rejected-doc": "Rejected",
        "peak-to-profile": "Profile Overview",
        "all-see": "See all",
        "filter-score": "By Score",
        "filter-location": "By Location",
        "filter-capability": "By Capability",
        "filter-experience": "By Experience",
        "review-pt-results": "Review Performance Test Result",
        "ideal-response-rate": "Ideal response rate",
        "advert-management": "Job Posting Management",
        "score-5": "5 - Best candidate",
        "score-4": "4 - Suitable candidate",
        "score-3": "3 - Average candidate",
        "score-2": "2 - Low candidate",
        "score-1": "1 - Insufficient candidate",
        "finish-application": "Finish Application",
        "send-pt": "Send Performance Test",
        "reject-candidate": "Reject the candidate",
        "beginner": "Beginner",
        "1+": "1+",
        "3+": "3+",
        "5+": "5+,",
        "10+": "10+",
        "send-message": "Send Message",
        "send-internal-message": "Send internal message",
        "copy-phone-number": "Copy phone number",
        "send-messsage": "Send message",
        "pt-results": "Performance Test Results",
        "ideal-response": "Ideal Response",
        "recived-answer": "Recived Answer",
        "paq-results": "Preliminary Assements Results",
        "application-of": "{{0}}'s Application",
        "choose-status": "Choose Status",
        "see-profile": "Go to Profile",
        "aplied-status": "Application submitted",
        "doc-list-sended": "Document list has been sent",
        "doc-list-waiting": "Document list is pending",
        "reupload-doc-waiting": "Documents are awaited again",
        "interview-completed": "Interview with the candidate is completed",
        "pt-sended": "Performance test has been sent",
        "pt-result-waiting": "Performance test results are pending",
        "candidate-rejected": "The candidate was rejected",
        "docs-not-sended": "There is no document uploadad by the candidate!",
        "no-aplicant-header": "No Application Submitted Yet",
        "no-aplicant-content": "All Candidates Will Be Displayed Here",
        "no-aplicant-statecard-header": "No Applications Yet",
        "no-aplicant-statecard-content": "All applicants will be displayed here.",
        "no-aplicant-content-content": "No Applications Received Yet",
        "no-aplicant-content-header": "Detailed information of applicants will be listed in this area. You can easily manage the recruitment process of candidates from here...",
        "no-aplicant-content-buton": "View My Listings",
        "in-the-last-24-hours": "In the last 24 hours",
        "added-yesterday": "Added yesterday",
        "added-one-week-ago": "Added one week ago",
        "added-one-month-ago": "Added one month ago",
        "added-one-years-ago": "Added one years ago",
        "by-application-date" :"By Application Date",
        "take-down": "Take Down",
        "create-draft" : "Create Draft",
        "delete-advert": "Delete Advert",
        "complete-the-performance-test": "Complete the Performance Test",
        "complate": "Complate",
        "document-upload-screen": "Document Upload Screen",
        "see-all-documents": "See All Documents",
        "sent": "sent",
        "rejected-document": "Send again",
        "completed-the-performance-test": "Completed the performance test",
        "send-documents": "Send documents",
        "documents-sent": "Documents send",
        "missing-documents-are-waiting": "Missing documents are waiting",
        "install": "Install",
        "uploaded": "Uploaded",
        "go-company-page": "Go to Company Page",
        "aplied-ad": "Your application has been submitted.",
        "aplied-ad-content": "Your application has been submitted to the advertiser.",
        "empty-company-about": "This section empty for now...",
        "advert-link-copied": "Advert Share",
        "advert-link-copied-content": "The link to the advertisement has been copied to the clipboard",
        "no-file-selected-or-upload-token-missing": "No file selected or upload token missing",
        "the-file-has-been-uploaded-successfully": "The file has been uploaded successfully",
        "the-postings-you-apply-for-will-be-displayed-here": "The postings you apply for will be displayed here",
        "all-applications-submitted-will-be-displayed-here": "All applications submitted will be displayed here",
        "application-has-not-been-made-yet": "Application has not been made yet",
        "all-details-of-your-applications-and-application-processes-will-be-displayed-here": "All details of your applications and application processes will be displayed here",
        "find-ad": "Find ad",
        "file-size-exceeds-limit": "Dosya boyutu sınırı aşıyor",
        "advert-count-length": "{{0}} Job Adverts",
        "current-search-page": "You are currently on page {{1}} of {{0}}.",
        "find-candidate": "Find Candidate",
        "search-suitable-candidate-for-you": "Search for a suitable candidate for you",
        "search-candidate": "Search Candidate",
        "show-only-disabled-candidates": "Show only disabled candidates",
        "dont-show-disabled-candidates": "Don't show disabled candidates",
        "disable-candidate": "Disable Candidate",
        "foreign-language": "Foreign Language",
        "skill": "Skill",
        "occupation": "Occupation",
        "experience-level": "Experience Level",
        "search-skill": "Search Skill",
        "find-the-suitable-candidate": "Find the right candidate for your job posting now!",
        "let-us-notify-you": "We will notify you when a new candidate is registered with this search.",
        "no-candidate-found": "The candidate you are looking for could not be found.",
        "no-candidate-found-long": "We couldn't find the candidate you were looking for... To get the right results, you should pay attention to the following:",
        "candidate": "{{0}} Candidate",
        "you-are-currently-on-page": "You are currently on page {{0}} of {{1}}.",
        "why-should-you-choose-us": "Why Choose Us?",
        "our-vision": "Our Vision",
        "our-values": "Our Values",
        "our-phone-number": "Our Phone Number",
        "our-email-address": "Our Email Address",
        "our-address": "Our Address",
        "contact-us-now": "Contact Us Now!",
        "your-message-has-been-sent": "Your Message Has Been Sent!",
        "congratulations-your-message-has-been-successfully-sent": "Congratulations, Your Message Has Been Successfully Sent.",
        "you-will-be-contacted-as-soon-as-possible": "You Will Be Contacted As Soon As Possible.",
        "contact-us-by-form": "Contact us by filling out the form below or contact us directly via our email address. We are pleased to answer your questions and help you.",
        "send": "Send",
        "are-you-sure-you-want-to-unpublish": "Are you sure you want to unpublish?",
        "are-you-sure-you-want-to-delete-the-advert": "Are you sure you want to delete the advert?",
        "delete": "Delete",
        "republish": "Republish",
        "are-you-sure-you-want-to-republish": "Are you sure you want to republish?",
        "publish": "Publish",
        "remaove-from-saved": "Remove from Saved",
        "create-advert-from-draft": "Create Advert from Draft",
        "go-to-advert": "Go to Advert",
        "draft-created": "The draft has been created.",
        "ok": "Okey",
        "training-details": "Trainings Details",
        "end-date-cannot-be-before-start-date": "End date cannot be before start date",
        "other": "Other",
        "you-are-about-to-delete-your-account": "You are about to delete your account",
        "you-are-about-to-delete-your-account-desc": "You are deleting your account, if you delete it all your information will be deleted.",
        "please-login-to-view-all-of-the-job-adverts": "Please login to view all of the job adverts",
        "check-out-the-advert": "Check out the advert",
        "job-advert": "Job Advert",
        "month": "Month",
        "year": "Year",
        "ınstalled-successfully": "Installed successfully",
        "with-isinolacak-you-will-reach-your-career-goals": "With İşin Olacak, you are one step closer to your career goals. You can easily access job postings, manage your applications, and discover the most suitable job opportunities. Sign up now and start a new beginning in your job search!",
        "next-step": "Next Step",
        "already-have-an-account": "Already have an account?",
        "welcome": "Welcome",
        "daily": "Daily",
        "weekly": "Weekly",
        "monthly": "Monthly",
        "yearly": "Yearly",
        "unreviewed-applications": "Unreviewed Applications",
        "number-of-applications": "Number of Applications",
        "active-adverts": "Active Adverts",
        "view-counts": "View Counts",
        "current-job-seekers": "Current Job Seekers",
        "week": "Week",
        "application-date": "Application Date",
        "days-ago": "days ago",
        "candidates-that-match-will-be-shown": "Current candidates that match your job postings will be displayed here.",
        "suitable-candiate": "Suitable Candidate",
        "your-password-must-be-at-least-8-characters": "Your password must be at least 8 characters,",
        "one-capital-letter": "one capital letter,",
        "a-lowercase-letter": "a lowercase letter,",
        "ıt-must-contain-one-special-character-and-one-number": "It must contain one special character and one number.",
        "verify-email-address": "Verify your email address!",
        "publish-your-first-advert-by-verifying": "Publish your first ad by verifying.",
        "review-job-posting-by-verifying": "Review job postings by verifying.",
        "company-email-verification": "Company E-Mail Verification",
        "please-enter-your-email": "Please enter your email.",
        "email-sent": "E-Mail Sent",
        "email-could-not-be-sent": "E-Mail Could Not Be Sent.",
        "ıt-must-be-at-least-3-characters": "It must be at least 3 characters",
        "ı'm-currently-working-here-and-the-end-date-cannot-be-filled-at-the-same-time": "I'm currently working here and the end date cannot be filled at the same time",
        "invalid-email-format": "Not in email format",
        "only-numbers-are-allowed": "Only numbers are allowed",
        "it-cannot-be-longer-than-10-digits": "It cannot be longer than 10 digits",
        "ıt-must-be-at-least-10-characters": "It must be at least 10 characters",
        "ıt-must-be-at-most-100-characters": "It must be at most 100 characters",
        "ıt-must-be-at-most-500-characters": "It must be at most 500 characters",
        "ıt-must-be-at-most-2000-characters": "It must be at most 2000 characters",
        "ıt-must-be-at-least-2-characters": "It must be at least 2 characters",
        "there-are-currently-no-popular-ads": "There are currently no popular ads.",
        "there-are-currently-no-featured-ads": "There are currently no featured ads.",
        "the-most-popular-ads-for-you-will-be-displayed-here": "The popular adverts for you will be displayed here.",
        "the-featured-ads-for-you-will-be-displayed-here": "The featured adverts for you will be displayed here.",
        "message-length-error": "Your message should be more than 4 letters.",
        "find-answers-to-your-questions-easily": "Find answer to your question using shortcuts.",
        "enter-new-password": "Enter your new password",
        "code-input": "Please enter your verification code",
        "remaining-time": "Remaining Time",
        "set-worker-alarm": "Set Worker Alarm",
        "set-worker-alarm-info": "When an employee is found suitable for the Employee Alarm, we will send you an email. You can edit or delete it at any time.",
        "alarm-title": "Alarm Title",
        "create-alarm-name": "Create an alarm name",
        "set-advert-alarm": "Set Advert Alarm",
        "set-advert-alarm-info": "When an worker is found suitable for the Employee Alarm, we will send you an email. You can edit or delete it at any time.",
        "set-alarm-now": "Don't Miss the Ads Set an Alarm Now",
        "set-alarm-now-info": "We will notify you when a new ad that matches your search is published.",
        "my-advert-alarm": "My Advert Alarms",
        "create-new-alarm": "Create New Alarm",
        "edit-advert-alarm": "Edit Advert Alarm",
        "edit-advert-alarm-info": "You can remove the filter selections for the advert alarm you created or add new ones at any time.",
        "you-are-about-to-delete-the-advert-alarm": "You are about to delete the Advert Alarm",
        "you-can-edit-the-alarm-and-reuse-it": "You can edit the alarm and reuse it.",
        "my-candidate-alarm": "My Candidate Alarms",
        "edit-candidate-alarm": "Edit Candidate Alarm",
        "edit-candidate-alarm-info": "You can remove the filter selections for the candidate alarm you created or add new ones at any time.",
        "you-are-about-to-delete-the-candidate-alarm": "You are about to delete the Candidate Alarm",
        "alarm-title-cannot-be-left-blank": "Alarm title cannot be left blank.",
        "no-filter-selected": "No filter selected.",
        "occupation-selection-is-required": "Occupation selection is required",
        "max-five-alarms-allowed": "You can create up to 5 alarms.",
        "reduce": "Reduce",
        "isinolacak-platform": "İsinolacak Platform",
        "active-advert": "Active Advert",
        "hidden-advert": "Hidden Advert",
        "advert-last-update-date": "Last Update: {{0}}",
        "advert-removed-from-publication-date": "{{0}} - Removed From Publication",
        "advert-end-date": "Advert End Date: {{0}}",
        "advert-publication-date": "Publication Date: {{0}}",
        "draft-advert-creation-date": "Creation Date: {{0}}",
        "advert-saved-date": "Saved Date: {{0}}",
        "application-sent": "Application Sent",
        "performance-test-completed": "Performance Test Completed",
        "performance-test-submit-waiting": "Performance Test Solution Waiting",
        "documents-submit-waiting": "Document Submission Waiting",
        "documents-in-review": "Documents In Review",
        "documents-completed": "Documents Completed",
        "document-resubmit-waiting": "Document Revision Waiting",
        "application-rejected": "Negative Application Result",
        "open-to-apply": "Open to Application",
        "close-to-apply": "Close to Application",
        "redirecting": "Redirecting",
        "job-seeker-application-date": "Applied {{0}} days ago",
        "premium": "Premium",
        "choose-your-role-in-company": "Select your role in the company.",
        "choose-your-sector": "Select your sector.",
        "enter-your-address-info": "Enter your address information.",
        "choose-city": "Select city.",
        "choose-district": "Select district.",
        "choose-occupation": "Select occupation.",
        "choose-your-communication-information": "Enter your communication information.",
        "choose-the-contact-message": "Write the message you want to convey.",
        "take-a-step-using-isinolacak": "Take a new step in your career with Isinolacak.",
        "enter-information-about-yourself": "Briefly provide information about yourself.",
        "enter-information-about-yourself-or-your-company": "Şirketiniz veya kendiniz hakkında kısaca bilgi veriniz.",
        "unread-items": "Okunmayanlar",
        "seconds-ago": "{{0}} seconds",
        "minute-ago": "{{0}} minutes",
        "hour-ago": "{{0}} hours",
        "day-ago": "{{0}} days",
        "subscriptions": "Subsciptions",
        "no-subscription-yet": "You do not have a subscription yet.",
        "subscribe-to-benefit-from-privileges": "Subscribe now to benefit from the privileges of İşin Olacak.",
        "subscribe": "Subscribe",
        "upgrade": "Upgrade",
        "upgrade-your-subscription": "Upgrade Your Subscription!",
        "upgrade-subscription-to-benefit-from-more-privileges": "Upgrade your subscription now to benefit more privileges of İşin Olacak.",
        "purchase-history": "Purchase History",
        "access-all-purchase-details": "You can find all your purchase details here.",
        "go-to-plan": "Go to Plan",
        "cancel-subscription": "Cancel Subscription",
        "purchase-code": "Order Code",
        "purchase-date": "Purchase Date",
        "order-date": "Order Date",
        "purchase-content": "Purchase Content",
        "payment-detail": "Payment Detail",
        "free-use": "Free Use",
        "premium-membership": "Premium Membership",
        "packages": "Packages",
        "choose-payment-method": "Choose Payment Method",
        "payment-method-via-bank-info": "Payment by bank transfer.",
        "payment-method-via-card-info": "Visa, MasterCard and other cards are accepted.",
        "pay-via-iban": "Pay with IBAN",
        "pay-via-card": "Pay by Card",
        "bank-iban-no": "Bank IBAN Number",
        "bank-institution-name": "Bank Institution Name",
        "choose-bank": "Choose Bank",
        "copy": "Copy",
        "send-payment-statement": "Send Payment Notification",
        "your-payment-statement-received": "Your Payment Notification Has Been Made",
        "your-payment-statement-received-info": "Congratulations, your payment notification has been sent. Your package will be added to your account shortly.",
        "buy-subscription": "Buy Subscription",
        "completed-orders": "Completed Orders",
        "pending-orders": "Pending Orders",
        "no-pending-orders-yet": "There are currently no pending orders.",
        "no-completed-orders-yet": "There are no completed orders yet.",
        "no-subscription": "No Subscription",
        "two-possible-questions": "Two possible questions",
        "sign-up": "sign up.",
        "our-data-policy": "Our Data Policy",
        "your-career-will-be-with-you": "Take a new step in your career with",
        "take-a-new-step": "İşin Olacak",
        "application-process-text": "As İşin Olacak, we are with you at every stage of your application. After you submit your application, you can easily track the status of your application and view the steps in the process instantly. When the relevant employer reviews your application, you will be notified through the system. The entire process is transparent and fast; thus, you can take your career steps with confidence. With İşin Olacak, every application opens the door to a new opportunity",
        "update-your-email-preference": "Update Your Email Preference",
        "email-preference-update-page-text-content": "You can choose which types of emails you want to receive by updating your email preferences. You can turn on and off different types of e-mails, such as marketing e-mails, system e-mails, job recommendation e-mails, application status e-mails and job posting e-mails. In this way, you can access only the information that interests you and you need, and you will not waste time on unnecessary e-mails. Don't forget to press the 'Save' button after choosing your preferences.",
        "mail-content-types": "Mail content types",
        "marketing-emails": "Marketing Emails:",
        "marketing-emails-text-content": "Emails that aim to increase interaction by informing users about the platform's new features, updates and campaigns.",
        "system-emails": "System Emails:",
        "system-emails-text-content": "Emails containing system-related information such as users' account activities, application status and advertisement updates.",
        "job-recommendation": "Job Recommendation Emails (job seekers only):",
        "job-recommendation-text-content": "Description: Personalized e-mails that recommend job postings to job-seeking users that best suit their profile and preferences.",
        "application-status": "Application Status Emails (job seekers only):",
        "application-status-text-content": "Emails informing job seekers about the status of their applications (for example, application received, invited for an interview, rejected).",
        "job-posting": "Job Posting Emails (employer only):",
        "job-posting-text-content": "Emails containing tips, reminders and suggestions for employers to facilitate and optimize the job posting process. (Edited)"
    }
};
export default en;
