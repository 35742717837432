import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "stretch",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        flexGrow: 1,
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    responsiveContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "stretch",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        flexGrow: 1,
        "@media screen and (min-width: 1200px)": {
            display: "none"
        }
    },
    renderWelcomeContainer: {
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        display: "flex"
    },
    renderTopContainer: {
        width: "100%"
    },
    renderBottomContainer: {
        flexDirection: "column",
        display: "flex",
        flexGrow: 1
    },
    renderEmptyContainer: {
        justifyContent: "center",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        minHeight: 100,
        display:"flex",
        flexGrow: 1
    },
    generalOverviewContainer: {
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        height: 310,
        "@media screen and (max-width: 1200px)": {
            flexDirection: "column",
            height: "100%"
        }
    },
    renderGraphContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        flexBasis: "40%",
        display: "flex",
        height: "100%",
        "@media screen and (max-width: 1200px)": {
            height: 300
        }
    },
    graphChipContainer: {
        display: "flex",
        "@media screen and (min-width: 1200px)": {
            margin: "0 !important"
        }
    },
    graphContainer: {
        height:"80%",
        width:"100%",
        "@media screen and (max-width: 1200px)": {
            width: "100%",
            height: 300
        }
    },
    renderGeneralData: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        flexBasis: "25%",
        display: "flex",
        height: "100%",
        maxWidth: 200,
        "@media screen and (max-width: 1200px)": {
            justifyContent: "start",
            alignItems: "stretch",
            flexDirection: "row",
            maxWidth: "unset",
            height: "auto"
        }
    },
    generalDataContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        maxHeight: "min-content",
        borderStyle: "solid",
        display: "flex",
        flexGrow: 1
    },
    generalDataTextContainer: {
        flexDirection: "column",
        display: "flex"
    },
    generalDataText: {
        alignItems: "end",
        display: "flex"
    },
    generalDataRedirectIcon: {
        transform: "rotate(180deg)",
        marginLeft: "auto",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8
        }
    },
    bannerContainer: {
        background: "linear-gradient(180deg, #00AEC6 0%, #FF671B 100%)",
        justifyContent: "center",
        alignItems: "center",
        flexBasis: "30%",
        display: "flex",
        flexGrow: 1,
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    link: {
        textDecoration: "none",
        marginLeft: "auto",
        display: "block",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    linkContainer: {
        marginLeft: "auto"
    },
    candidatesContainer: {
        justifyContent: "start",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (max-width: 600px)": {
            justifyContent: "center"
        }
    }
});
export default useStyles;