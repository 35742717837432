import {
    IOCoreLocale,
    IOCoreTheme,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    IAdvertCardProps,
} from "./types";
import {
    BookMarkFullIcon,
    BookMarkIcon 
} from "../../assets/svgr";
import emptyProfile from "../../../app/assets/img/employer.png";
import BoostsTypeStickers from "../boostTypeStickers";
import {
    StickerObjectType 
} from "../advertCardForEmployer/types";

const AdvertCard = ({
    isNoAuth = false,
    onClickBookMark,
    isSaved = false,
    experienceLevel,
    actionOnClick,
    contractType,
    companyName,
    workingType,
    actionType,
    jobImage,
    stickers,
    onClick,
    style,
    title,
    city
}: IAdvertCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {companyName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {experienceLevel && experienceLevel + " • "}
                    {contractType && contractType + " • "}
                    {city && city + " • "}
                    {workingType && `(${workingType})`}
                </Text>
            </div>
        </div>;
    };

    const bookmarkContianer = () => {
        if(isNoAuth || !onClickBookMark) {
            return null;
        }

        return <div
            className={classes.bookmarkStyle}
            style={{
                borderRadius: radiuses.hard,
                borderColor: colors.stroke,
                borderWidth: borders.line
                    
            }}
            onClick={(e) => {
                e.stopPropagation();
                if(onClickBookMark) onClickBookMark();
            }}
        >
            {
                isSaved ? <BookMarkFullIcon /> : <BookMarkIcon />
            }
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
            onClick={onClick}
        >
            <div className={classes.informationsContent}>
                <img
                    src={jobImage && jobImage.length ? jobImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container,
                        padding: spaces.inline / 2
                    }}
                />
                {informationsContainer()}
            </div>
            {bookmarkContianer()}
        </div>;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                stickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <div
                        key={item.key}
                        className={classes.tagContainer}
                    >
                        <BoostsTypeStickers
                            title={localizedText}
                            titleColor={item.color}
                            color={item.backColor}
                            icon={item.icon}
                            itemKey={item.key}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderActionButton = () => {
        if(!actionOnClick) {
            return null;
        }

        if(!actionType) {
            return null;
        }

        let currentActionTitle;

        if(actionType === "manage-application") {
            currentActionTitle = localize("manage-application");
        }

        return <div
            style={{
                padding: spaces.container * 0.75,
                borderTopColor: colors.stroke,
                borderWidth: borders.line,
                borderTopStyle: "solid"
            }}
        >
            <Button
                title={currentActionTitle}
                spreadBehaviour="stretch"
                onClick={actionOnClick}
                variant="filled"
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line,
        }}
    >
        {renderInformations()}
        {renderTags()}
        {renderActionButton()}
    </div>;
};
export default AdvertCard;
