import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    TextArea,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    WidgetAddIcon,
    CircleIcon
} from "../../../../../../assets/svgr";
import {
    PersonalInfoTypes
} from "../../types";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    ContactUsRequestType
} from "../../../../../../services/restAPI/actions/contactUs/types";
import {
    useLocation,
    useNavigate 
} from "react-router-dom";
import {
    GetMyProfileResponseTypes 
} from "../../../../../../services/restAPI/actions/getMyProfile/types";
import {
    getErrorText 
} from "../../../../../../utils";
import {
    EmployerProfileLeftContainer 
} from "../../../../../../components";

const EmployerContactUs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();
    
    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getMyProfile, setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [loadingProfilePhoto, setLoadingProfilePhoto] = useState(true);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [contactUs, setContactUs] = useState({
        firstName: "",
        lastName: "",
        message: "",
        mail: ""
    });

    const localStorageData: string | null = window.localStorage.getItem("company");

    let company: PersonalInfoTypes;
    if (localStorageData) {
        company = JSON.parse(localStorageData);
    } else {
        company = {
            areaCode: {
                countryTranslationKey: "",
                flagUnicode: "",
                country: "",
                value: "",
                _id: "",
            },
            companyOccupation: {
                localizedText: "",
                _id: ""
            },
            occupation: {
                localizedText: "",
                _id: ""
            },
            district: {
                localizedText: "",
                _id: ""
            },
            sector: {
                localizedText: "",
                _id: ""
            },
            city: {
                localizedText: "",
                _id: ""
            },
            companyTitle: "",
            profileURL: "",
            address: "",
            website: "",
            title: "", 
            about: "",
            mail: ""
        };
    }

    useEffect(() => {
        handleGetMyProfile();
    },[]);

    const handleGetMyProfile = () => {
        setLoadingProfilePhoto(true);
        RESTService.action("GetMyProfile",{
        }).then((res) => {
            setGetMyProfile(res);
            setLoadingProfilePhoto(false);
        }).catch((err) => {
            console.error("GetMyProfile Err:", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const handleContactUs = () => {
        const params: ContactUsRequestType = {
            ...contactUs 
        };
        
        if (!contactUs.firstName.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("firstName-length-error");
            setErrors(_errors);
            return;
        }

        if (!contactUs.lastName.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("lastName-length-error");
            setErrors(_errors);
            return;
        }

        if (!contactUs.mail.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("mail-length-error");
            setErrors(_errors);
            return;
        }

        if (!contactUs.message.length) {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("message-length-error");
            setErrors(_errors);
            return;
        }

        setLoadingButton(true);
        RESTService.action("ContactUs", params)
            .then((res) => {
                setLoadingButton(false);
                setIsVisibleDialog(true);
            })
            .catch((err) => {
                console.error("ContactUs Err:", err);
                setLoadingButton(true);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("saved-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                style={{
                    padding: spaces.content * 2
                }}>
                <Text
                    variant='header5-medium'
                    color='textDark'
                >
                    {localize("contact-us")}
                </Text>
                <div
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content
                    }}>
                    <Text
                        variant='body-regular'
                        color='textDark'
                    >
                        {localize("choose-your-communication-information")}
                    </Text>
                    <TextInput
                        infoText={errors.includes("firstName-length-error") ? localize("needs-to-be-filled") : undefined}
                        isError={errors.includes("firstName-length-error")}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "firstName-length-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }}
                        initialValue={contactUs.firstName}
                        placeholder={localize("name")}
                        onChangeText={(e) => setContactUs({
                            ...contactUs,
                            firstName: e
                        })}
                        style={{
                            marginTop: spaces.content 
                        }}
                    />
                </div>

                <div
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content 
                    }}>
                    <TextInput
                        infoText={errors.includes("lastName-length-error") ?  localize("needs-to-be-filled") : undefined}
                        isError={errors.includes("lastName-length-error")}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "lastName-length-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }}
                        initialValue={contactUs.lastName}
                        placeholder={localize("surname")}
                        onChangeText={(e) => setContactUs({
                            ...contactUs,
                            lastName: e
                        })}
                    />
                </div>

                <div
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content
                    }}>
                    <TextInput
                        infoText={errors.includes("mail-length-error") ?  localize("needs-to-be-filled") : undefined}
                        isError={errors.includes("mail-length-error")}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "mail-length-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }}
                        initialValue={contactUs.mail}
                        placeholder={localize("e-mail")}
                        onChangeText={(e) => setContactUs({
                            ...contactUs,
                            mail: e
                        })}
                    />
                </div>

                <div
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content
                    }}>
                    <Text
                        variant='body-regular'
                        color='textDark'
                    >
                        {localize("your-message")}
                    </Text>
                    <TextArea
                        isError={errors.includes("message-length-error")}
                        onFocus={() => {
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "message-length-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }}
                        placeholder={localize("choose-the-contact-message")}
                        initialValue={contactUs.message}
                        onChangeText={(e) => setContactUs({
                            ...contactUs,
                            message: e
                        })}
                        style={{
                            marginTop: spaces.content 
                        }}
                    />
                </div>

                <div
                    style={{
                        paddingTop: spaces.content * 2,
                        borderTopWidth: borders.line,
                        borderColor: colors.stroke
                    }}>
                    <Button
                        onClick={() => handleContactUs()}
                        spreadBehaviour="stretch"
                        title={localize("save")}
                        loading={loadingButton}
                        style={{
                            marginBottom: spaces.content * 1.5 
                        }}
                    />
                </div>
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon 
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    if(loadingProfilePhoto) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("company-profile-screen")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <EmployerProfileLeftContainer/>
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
        {renderSuccessDialog()}
    </div>;

};

export default EmployerContactUs;
