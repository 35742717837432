import {
    ReactNode
} from "react";
import NContext, {
    ConfigType
} from "ncore-context";
import {
    SocketActionResponseTypes,
    SocketActionRequestTypes,
    ConversationContextType,
    ServiceContextType,
    SocketActionsType,
    ActionsType,
    ServiceType,
    MessageType
} from "../types";
import socketActions from "../socketActions";
import actions from "../actions";
import {
    IOCoreTranslationType,
    IOCoreLocale
} from "isinolacak-web-cl";
import {
    Socket,
    io
} from "socket.io-client";
import {
    RESTService
} from "../../restAPI";
import {
    SERVER_ADDRESS
} from "../constants";

class ConversationsContextService extends NContext<ConversationContextType, ConfigType<ConversationContextType>> {
};

class ServiceContextInheritence<T extends ServiceType<ActionsType>> extends NContext<ServiceContextType<ActionsType>, ConfigType<ServiceContextType<ActionsType>>> {
    ConversationsContext: ConversationsContextService;
    socketListeners: Array<Socket> = [];
    socket: Socket | null = null;
    isTokenRefreshing = false;
    isConnected = false;
    socketActions;
    actions;
    queue: Array<{
        actionName: ActionsType;
        params: any;
    }> = [];

    constructor(initialState: T) {
        super(
            initialState,
            {
                key: "service-context"
            }
        );

        this.actions = initialState.actions;
        this.socketActions = initialState.socketActions;
        this.ConversationsContext = new ConversationsContextService({
            conversations: []
        }, {
            key: "conversations-context"
        });

        this.connect();
    }

    connect = () => {
        const chatAccessToken = window.localStorage.getItem("chatAccessToken");
        if(chatAccessToken) {
            this.socket = io(SERVER_ADDRESS, {
                auth: {
                    authorization: chatAccessToken
                },
                transports: ["websocket"]
            });

            this.socket.on("connect", () => {
                this.isConnected = true;
                this.setState({
                    isConnected: true
                });

                this.getInitialConversations();
                this.connectListeners();
            });

            this.socket.on("disconnect", () => {
                this.isConnected = false;
                this.setState({
                    isConnected: this.isConnected
                });

                this.socket?.removeAllListeners();
            });
        } else {
            console.error("Login required for chat service connection.");
        }
    };

    disconnect = () => {
        this.socket?.disconnect();
    };

    connectListeners = () => {
        if(this.socket !== null && this.isConnected) {
            if(this.socketListeners.length) {
                this.socketListeners = [];
                this.socket.removeAllListeners();
            }

            const newMessageListener = this.socket.on("new-message", (message: MessageType) => {
                this.emitWithKey("new-message", {
                    ...message
                });
                let user = window.localStorage.getItem("user");
                let userID = user ? JSON.parse(user).userID : null;
                if(userID && message.senderID !== userID) this.socketAction("ReceiveMessage", {
                    messageID: message.id
                });
            });

            const newConversationListener = this.socket.on("new-conversation", (conversation) => {
                let oldConversations = this.ConversationsContext.state.conversations;
                this.ConversationsContext.setState({
                    conversations: [
                        ...oldConversations,
                        conversation
                    ]
                });
            });

            const endConversationListener = this.socket.on("end-conversation", (conversation) => {
                let oldConversations = this.ConversationsContext.state.conversations;
                let currentIndex = oldConversations.findIndex(e => e.conversationID === conversation.conversationID);
                oldConversations[currentIndex] = conversation;

                this.ConversationsContext.setState({
                    conversations: oldConversations
                });
            });

            const updatedMessageListener = this.socket.on("updated-message", (message: MessageType) => {
                if(message.status === "readed") {
                    this.emitWithKey("read-message", {
                        ...message
                    });
                }
                if(message.status === "received") {
                    this.emitWithKey("receive-message", {
                        ...message
                    });
                }
            });
            
            this.socket.connect();
            
            this.socketListeners.push(endConversationListener);
            this.socketListeners.push(newConversationListener);
            this.socketListeners.push(updatedMessageListener);
            this.socketListeners.push(newMessageListener);
        }
    };

    getAccessToken = ()=>{
        if(localStorage.getItem("accessToken")){
            RESTService.action("GetAccessToken", {
            })
                .then((res)=>{
                    window.localStorage.setItem("chatAccessToken", res.socketToken);
                    this.connect();
                })
                .catch((err) =>{
                    console.error(err);
                });
        }
    };

    getInitialConversations = () => {
        if(this.isConnected) {
            RESTService.action("GetConversations", {
            })
                .then((res) => {
                    if(res && res.length) {
                        this.ConversationsContext.setState({
                            conversations: res
                        });
                    }
                })
                .catch((err: {
                    message: keyof IOCoreTranslationType;
                }) => {
                    console.error("Get Conversations Error:", JSON.stringify(err, null, 2));
                    if(err && err.message && Object.keys(IOCoreLocale.state.translations).indexOf(err.message) !== -1) {
                    }
                });
        }
    };

    socketAction = async <K extends SocketActionsType>(actionName: K, params: SocketActionRequestTypes[K]): Promise<Pick<SocketActionResponseTypes, K>[K] | undefined> => {
        if(this.socket && this.socket.connected) {
            // @ts-ignore
            return await this.socketActions[actionName](params, this.socket)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    console.error(err);
                    return err;
                });
        }
    };

    Provider = ({
        children
    }: {
        children: ReactNode;
    }) => {
        return <this.ConversationsContext.Provider>
            {children}
        </this.ConversationsContext.Provider>;
    };
};

const ChatServiceInherit = new ServiceContextInheritence({
    socketActions: socketActions,
    actions: actions
});
export default ChatServiceInherit;
