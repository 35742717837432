import React from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    CheckBox,
    Text
} from "isinolacak-web-cl";
import {
    ResumeApprovalTypes
} from "./types";

const ResumeApproval = ({
    setIsSuitableForDisabledPeopleAdvert,
    isSuitableForDisabledPeopleAdvert
}: ResumeApprovalTypes) =>  {
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const renderResumeApproval = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant="header5-medium"
                color="textDark"
            >
                {localize("resume-approval")}
            </Text>
            <CheckBox 
                onChange={() => setIsSuitableForDisabledPeopleAdvert(prev => !prev)}
                title={localize("ı-am-a-suitable-candidate-for-disabled-postings")}
                isSelected={isSuitableForDisabledPeopleAdvert}
                style={{
                    marginTop: spaces.content
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderResumeApproval()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default ResumeApproval;
