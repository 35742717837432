import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        borderTopStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        minWidth: 250,
    },
    profilePhotoContainer: {
        justifyContent: "center",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        overflow: "hidden",
        display: "flex",
        minHeight: 50,
        minWidth: 50,
        height: 50,
        width: 50,
    },
    contentContainer: {
        justifyContent: "center",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    header: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    message: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
    },
    badge: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 10,
        display: "flex",
        height: 20,
        width: 20
    },
    optionDots: {
        justifyContent: "center",
        flexDirection:"column",
        alignItems: "center",
        display: "flex",
        gap: 4
    },
    optionDot: {
        borderRadius: 2,
        height: 4,
        width: 4,
    },
    optionsContent: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        textAlign: "left",
        display: "flex"
    },
    profilePhotoContent:{
        objectFit: "cover",
        height: "100%",
        width: "100%"
    }
});
export default useStyles;