import {
    useRef
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextInput,
    TextArea,
    Button,
    Text
} from "isinolacak-web-cl";
import PTDProps, {
    QuestionObjectType
} from "./types";
import {
    ANSWER_RENDER_DATA,
    ANSWER_TYPES,
    TEST_DATA
} from "./constants";
import {
    FilledTriangleBottomIcon,
    PlusIcon
} from "../../../assets/svgr";
import PerformanceTestQuestionCard from "../performanceTestQuestion";
import {
    CreatePTRequestType
} from "../../../services/restAPI/actions/createPerformanceTest/types";
import {
    RESTService
} from "../../../services/restAPI";
import {
    getErrorText 
} from "../../../utils";

const CreatePerformanceTest =  ({
    setPtAssignStatus,
    setTempSelectedPT,
    setSelectedPT,
    ptAssignStatus,
    setData,
    data
}: PTDProps) =>{
    const classes = useStyles();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const dropdownRef = useRef<HTMLDivElement>(null);

    const onChangeQuestion = (text: string, index: number) => {
        const _data = JSON.parse(JSON.stringify(data));
        _data.questions[index].question = text;
        setData(_data);
    };

    const onChangeDescription = (text: string, index: number) => {
        let _data = JSON.parse(JSON.stringify(data));
        _data.questions[index].description = text;
        setData(_data); 
    };

    const onChangeQuestionType = (index: number, c_index: number) => {
        const _data = JSON.parse(JSON.stringify(data));
        _data.questions[c_index].answerPayload = ANSWER_TYPES.find((_, i) => i === index);
        setData(_data);
    };

    const CreatePerformanceTest = () => {
        let _questions: any = data.questions.map((e) => {
            if(e.answerPayload.key === "twoOptions") {
                return {
                    question: e.question,
                    description: e.description,
                    answerType: e.answerPayload.titleKey,
                    prefer: e.answerPayload.attributes.idealInput ? e.answerPayload.attributes.idealInput === "yes" ? true : false : false
                };
            }
            if(e.answerPayload.key === "text") {
                return {
                    question: e.question,
                    description: e.description,
                    answerType: e.answerPayload.titleKey,
                };
            }
            if(e.answerPayload.key === "multiSelect") {
                return {
                    question: e.question,
                    description: e.description,
                    answerType: e.answerPayload.titleKey,
                    answerOptions: e.answerPayload.attributes.inputs.map(e => e.value),
                    prefer: e.answerPayload.attributes.idealInput
                };
            }
            if(e.answerPayload.key === "fiveStarIndicator") {
                return {
                    question: e.question,
                    description: e.description,
                    answerType: e.answerPayload.titleKey,
                    prefer: Number(e.answerPayload.attributes.expectationIndex)
                };
            }
            if(e.answerPayload.key === "tenStarIndicator") {
                return {
                    question: e.question,
                    description: e.description,
                    answerType: e.answerPayload.titleKey,
                    prefer: Number(e.answerPayload.attributes.expectationIndex)
                };
            }
        });

        let params: CreatePTRequestType = {
            title: data.title,
            questions: _questions
        };

        RESTService.action("CreatePT", params)
            .then((res) => {
                setSelectedPT({
                    ptID: res._id,
                    title: params.title
                });
                setTempSelectedPT({
                    ptID: res._id,
                    title: params.title
                });
                setData(TEST_DATA);
                setPtAssignStatus("assignPT");
            })
            .catch((err) => {
                console.error("CreatePT Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderQuestionList = () => {
        return data.questions.map((item, index) => {
            if(item.isQuesitionSaved) {
                return renderSavedQuestions(item,index);
            } else {
                return renderNewQuesitonsInput(item,index);
            }
        });
    };

    const renderSavedQuestions = (item:QuestionObjectType ,index: number) => {
        return <div
            className={classes.savedContainer}
        >
            <PerformanceTestQuestionCard
                questionType={localize(item.answerPayload.titleKey)}
                question={`${localize("question")} - ${index + 1}`}
                dropdownref={dropdownRef}
                onDelete={() => {
                    let _data = JSON.parse(JSON.stringify(data));
                    _data.questions.splice(index,1);
                    setData(_data);
                }}
                onEdit={() => {
                    let _data = JSON.parse(JSON.stringify(data));
                    _data.questions[index].isQuesitionSaved = false;
                    setData(_data);
                }}
            />
        </div>;
    };

    const renderTestTitleInput = ()=> {
        return <div
            className={classes.titleContainer}
            style={{
                marginBottom: spaces.contentXLarge,
                gap: spaces.content
            }}
        >
            <Text>
                {localize("pt-test-header")}
            </Text>
            <TextInput
                spreadBehaviour="stretch"
                placeholder={localize("header")}
                onChangeText={(e) => {
                    setData({
                        ...data,
                        title: e
                    });
                }}
            />
        </div>;
    };

    const renderNewQuesitonsInput = (item:QuestionObjectType ,index: number) => {
        return <div
            className={classes.questionListContainer}
            style={{
                gap: spaces.content
            }}
        >
            <Text
                variant="body2-semiBold"
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("question")}-{index + 1}
            </Text>
            <TextInput
                title={localize("question")}
                initialValue={item.question}
                onChangeText={(text) => {
                    onChangeQuestion(text, index);
                }}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
            <TextArea
                title={localize("description")}
                initialValue={item.description}
                placeholder=""
                onChangeText={(text) => {
                    onChangeDescription(text, index);
                }}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
            <SelectBox
                titleExtractor={e => `${localize(e.titleKey)}`}
                title={localize("question-type")}
                keyExtractor={(e) => e.key}
                isNeedConfirm={true}
                multiSelect={false}
                data={ANSWER_TYPES}
                customIcon={() => {
                    return <FilledTriangleBottomIcon
                        color={colors.primary}
                        size={15} 
                    />;
                }}
                inputTitle=""
                initialSelectedItems={[{
                    ...ANSWER_TYPES[0],
                    originalIndex: 0
                }]}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
                onOk={({
                    selectedItems,
                    onSuccess,
                    closeSheet
                }) => {
                    const selectedIndex = ANSWER_TYPES.findIndex(c_item => c_item.key === selectedItems[0].__key); //TODO: This will check
                    onChangeQuestionType(selectedIndex, index);
                    onSuccess();
                    closeSheet();
                }}
            />
            {ANSWER_RENDER_DATA[item.answerPayload.key]({
                ...item.answerPayload,
                setData,
                index,
                data
            })}
            {renderSaveQuestion(index, item)}
        </div>;
    };

    const renderSaveQuestion = (index: number,item:QuestionObjectType) => {
        let isDisabled = true;

        if(item.answerPayload.key === "twoOptions") {
            isDisabled = item.answerPayload.attributes.idealInput && item.answerPayload.attributes.idealInput.length ? false : true;
        }

        if(item.answerPayload.key === "text") {
            isDisabled = false;
        }

        if(item.answerPayload.key === "multiSelect") {
            let _options = item.answerPayload.attributes.inputs.map(e => e.value);

            isDisabled = item.answerPayload.attributes.idealInput && item.answerPayload.attributes.idealInput.length && _options.length ? false : true;
        }

        if(item.answerPayload.key === "fiveStarIndicator") {
            isDisabled = item.answerPayload.attributes.expectationIndex && item.answerPayload.attributes.expectationIndex ? false : true;
        }

        if(item.answerPayload.key === "tenStarIndicator") {
            isDisabled = item.answerPayload.attributes.expectationIndex && item.answerPayload.attributes.expectationIndex ? false : true;
        }

        return <Button
            disabled={item.question.length && item.description.length && !isDisabled ? false : true}
            title={localize("save-question")}
            spreadBehaviour="stretch"
            size="large"
            style={{
                marginBottom: spaces.contentLarge
            }}
            onClick={() => {
                let _data = JSON.parse(JSON.stringify(data));
                _data.questions[index].isQuesitionSaved = true;
                setData(_data);
            }}
        />;
    };

    const renderAddQuestion = () => { 
        return  <Button
            title={localize("add-question")}
            icon={() => <PlusIcon color={colors.primary} size={20}/>}
            spreadBehaviour="stretch"
            variant="ghost"
            size="large"
            onClick={() => {
                let _data = JSON.parse(JSON.stringify(data));
                _data.questions.push({
                    answerPayload: ANSWER_TYPES[0],
                    description: "",
                    question: ""
                });
                setData(_data);
            }}
        />;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer1
        }}
    >
        {renderTestTitleInput()}
        <div
            style={{
                marginBottom: spaces.containerXXLarge
            }}
        >
            {renderQuestionList()}
            {renderAddQuestion()}
        </div>
        <Button
            title={localize("create-test")}
            spreadBehaviour="stretch"
            textColor="textDark"
            color="gray92"
            onClick={() => {
                data.questions.forEach(e => {
                    if(!e.isQuesitionSaved) {
                        return alert(localize("must-confirm-all-questions"));
                    }
                });
                CreatePerformanceTest();
            }}
        />
    </div>;
};
export default CreatePerformanceTest;
