import React, {
    useState 
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text,
    Loading
} from "isinolacak-web-cl";
import {
    ContentContainerType
} from "./types";
import {
    DocumentFullIcon,
    CircleExitIcon,
    CheckListIcon,
    CircleIcon,
    InfoIcon,
    PillIcon
} from "../../../../../../assets/svgr";
import {
    ApplicantCapabilitiesObjectType,
    AdvertCapabilitiesObjectType
} from "../../../../../../services/restAPI/actions/getMyApplication/types";
import QualificationCard from "../../qualificationCard";
import SendDocumentCard from "../../sendDocumentCard";
import CompletePT from "../../completePT";
import PAQCard from "../../paqCard";
import {
    useNavigate
} from "react-router-dom";
import moment from "moment";

const ContentContainer = ({
    setGetMyApplication,
    applicationLoading,
    getMyApplication,
    setViews,
    views
}: ContentContainerType) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [showAllCapabilities, setShowAllCapabilities] = useState(false);

    const handleToggleShowAll = () => {
        setShowAllCapabilities(!showAllCapabilities);
    };
    
    const itemsToShow = showAllCapabilities
        ? getMyApplication?.advertCapabilities
        : getMyApplication?.advertCapabilities.slice(0, 2);

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderAdvertDetails = () => {
        let statusColor: keyof IOCore.ColorsType = "white";
        let buttonTitle = localize("finished");;
        let buttonIcon: () => JSX.Element;
    
        switch(getMyApplication?.status) {
        case "applied":
            buttonIcon = () => (
                <CircleIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("applied");
            statusColor = "success";
            break;
        case "performanceTestWaiting":
            buttonIcon = () => (
                <CheckListIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("performance-test-waiting");
            statusColor = "warning";
            break;
        case "performanceTestSubmitted":
            buttonIcon = () => (
                <CheckListIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("performance-test-submitted");
            statusColor = "success";
            break;
        case "documentWaiting":
            buttonIcon = () => (
                <DocumentFullIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("document-waiting");
            statusColor = "warning";
            break;
        case "documentSent":
            buttonIcon = () => (
                <DocumentFullIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("document-sent");
            statusColor = "success";
            break;
        case "reuploadDocumentWaiting":
            buttonIcon = () => (
                <DocumentFullIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("document-waiting");
            statusColor = "warning";
            break;
        case "rejected":
            buttonIcon = () => (
                <CircleExitIcon
                    color={colors.white}
                    size={17}
                />
            );
            buttonTitle = localize("rejected");
            statusColor = "error";
            break;
        case "finished":
            buttonIcon = () => (
                <CircleIcon
                    color="white"
                    size={17}
                />
            );
            buttonTitle = localize("finished");
            statusColor = "textGrey";
            break;
        default:
            buttonTitle = localize("finished");
            statusColor = "textGrey";;
            break;
        }

        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.advertDetailsContent}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.content
                    }}
                > 
                    {getMyApplication?.occupation.localizedText}
                </Text>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getMyApplication?.companyInfo.title}
                </Text>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getMyApplication?.city.localizedText}
                </Text>
                <div
                    className={classes.advertDetailsContainer}
                    style={{
                        marginBottom: spaces.content,
                        marginTop: spaces.content,
                        gap: spaces.content / 2
                    }}
                >
                    <InfoIcon 
                        color={colors.primary}
                        size={17}
                    />
                    <Text
                        variant="body-regular"
                        color="textDark"
                    >
                        {getMyApplication?.workingType.localizedText} - {getMyApplication?.contractType.localizedText}
                    </Text>
                </div>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                >
                    {moment(getMyApplication?.appliedAt).fromNow()}
                </Text>
            </div>
            <div
                className={classes.buttonContent}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.content
                }}
            >
                <Button
                    icon={() => buttonIcon()}
                    iconDirection="right"
                    title={buttonTitle}
                    color={statusColor}
                    onClick={() => {}}
                    size="small"
                />
                <Button
                    title={localize("see-company-profile")}
                    variant="outline"
                    onClick={() => {
                        navigate("/app/profileUser", {
                            state: {
                                companyID: getMyApplication?.companyInfo._id
                            }
                        });
                    }}
                    size="small"
                />
            </div>
        </div>;
    };

    const renderAdvertDetailsAbout = () => {
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.advertDetailAboutContent}
            >
                <Text
                    variant="header4-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.contentXLarge
                    }}
                > 
                    ({localize("job-title")}) {getMyApplication?.occupation.localizedText}
                </Text>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    ({localize("description")}) {localize("about-the-job-posting")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {getMyApplication?.advertDescription}
                </Text>
            </div>
        </div>;
    };

    const renderSoughtAfterQualification = () => {
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                className={classes.soughtAfterQualificationContainer}
            >
                <div
                    className={classes.soughtAfterQualificationContent}
                >
                    <Text
                        variant="body-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2
                        }}
                    >
                        {localize("required-qualifications")}
                    </Text>
                    <Text
                        variant="body3-regular"
                        color="textSecondary"
                    >
                        {localize("required-qualifications-description")}
                    </Text>
                </div>
                <Button
                    title={showAllCapabilities ? localize("collapse") : localize("see-all")}
                    onClick={handleToggleShowAll}
                    variant="ghost"
                />
            </div>
            {itemsToShow?.map((item: AdvertCapabilitiesObjectType, index: number) => {
                return <QualificationCard
                    title={item.localizedText}
                    key={index}
                />;
            })}
            <div
                className={classes.soughtAfterQualitificationCardContainer}
                style={{
                    borderRadius: spaces.containerXLarge,
                    marginTop: spaces.contentLarge,
                    borderColor: colors.primary,
                    padding: spaces.content
                }}
            >
                <div
                    className={classes.soughtAfterQualitificationCardContent}
                >
                    <div>
                        <PillIcon
                            size={35}
                        />
                    </div>
                    <div
                        className={classes.soughtAfterQualitificationCardCItem}
                        style={{
                            marginLeft: spaces.content,
                        }}
                    >
                        <Text
                            variant="body2-medium"
                            color="primary"
                            style={{
                                marginBottom: spaces.content / 2
                            }}
                        >
                            {localize("skills-added-by-the-user-who-created-the-job-posting")}
                        </Text>
                        <Text
                            variant="body3-regular"
                            color="textSecondary"
                        >
                            {getMyApplication?.applicantCapabilities.map((item: ApplicantCapabilitiesObjectType) => item.localizedText).join(" - ")}
                        </Text>
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderCompanyAbout = () => {
        const phoneURL = getMyApplication?.companyInfo.photoURL ? getMyApplication?.companyInfo.photoURL : require("../../../../../../assets/img/isinolacakMini.png");
        
        return <div
            style={{
                padding: spaces.container
            }}
        >
            <Text
                variant="header5-medium"
                color="textDark"
            >
                {localize("company-about")}
            </Text>
            <div
                className={classes.companyAbout}
                style={{
                    marginTop: spaces.content,
                }}
            >
                <img
                    className={classes.companyAboutImage}
                    style={{
                        borderRadius: spaces.containerXXLarge * 3,
                    }}
                    src={phoneURL}
                />
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginLeft: spaces.content
                    }}
                >
                    {getMyApplication?.companyInfo.title}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="body3-regular"
                    color="textGrey"
                >
                    {getMyApplication?.companyInfo.about}
                </Text>
            </div>
        </div>;
    };

    const renderCompletePT = () => {
        return <div>
            <CompletePT
                setGetMyApplication={setGetMyApplication}
                getMyApplication={getMyApplication}
                setViews={setViews}
            />
        </div>;
    };

    const renderPAQandSendDocument = () => {
        if (getMyApplication?.status === "performanceTestWaiting" || getMyApplication?.status === "performanceTestSubmitted") {
            return <PAQCard
                onClick={() => {setViews("completePT");}}
                getMyApplication={getMyApplication}
            />;

        } else if(getMyApplication?.status === "documentWaiting" || getMyApplication?.status === "documentSent" || getMyApplication?.status === "reuploadDocumentWaiting") {
            return <SendDocumentCard
                setGetMyApplication={setGetMyApplication}
                getMyApplication={getMyApplication}
            />;
        }
    };

    if (applicationLoading) {
        return <div className={classes.loadingContiner}>
            <Loading />
        </div>;
    }

    return <div>
        {views === "default" && <div>
            {renderAdvertDetails()}
            {renderLine()}
            {renderPAQandSendDocument()}
            {renderAdvertDetailsAbout()}
            {renderLine()}
            {renderSoughtAfterQualification()}
            {renderLine()}
            {renderCompanyAbout()}
        </div>}
        {views === "completePT" && <div>
            {renderCompletePT()}
        </div>}
    </div>;

};

export default ContentContainer;