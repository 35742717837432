import React, {
    useEffect,
    useState,
    Fragment,
    useRef
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Pagination,
    TextInput,
    Loading,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    SelectedExperienceLevelType,
    SelectedFiltersObjectType,
    SelectedOccupationType,
    SelectedContractType,
    SelectedWorkingType,
    SelectedFiltersType,
    SelectedCitiesType,
    FilterSelectType,
    DisabledAdsType,
    StickerObjectType
} from "./types";
import {
    ArrowDownIcon,
    MagnifierIcon,
    RadarIcon
} from "../../../assets/svgr";
import {
    ResponsiveFilters,
    FiltersContainer,
    ResultCard
} from "./views";
import {
    SORT_FILTER,
    NO_RESULT
} from "./constants";
import {
    RESTService
} from "../../../services/restAPI";
import {
    AdvertDateSearchType,
    SearchSortType
} from "../../../services/restAPI/actions/searchAdverts/types";
import {
    getAdvertsWithoutAuthPayloadTypes,
    GetAdvertsWithoutAuthRequestType 
} from "../../../services/restAPI/actions/getAdvertsWithoutAuth/types";
import {
    useSearchParams,
    useNavigate,
    useLocation
} from "react-router-dom";
import {
    getBrowserLanguageFull
} from "../../../utils";
import {
    Footer 
} from "../../../components";

const SearchPage = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        state 
    } = useLocation();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [selectedExperienceLevel, setSelecetedExperienceLevel] =  useState<Array<SelectedExperienceLevelType>>([]);
    const [searchResultData, setSearchResultData] = useState<Array<getAdvertsWithoutAuthPayloadTypes>>([]);
    const [selectedContractType, setSelectedContractType] =  useState<Array<SelectedContractType>>([]);
    const [selectedOccupation, setSelectedOccupation] =  useState<Array<SelectedOccupationType>>([]);
    const [selectedWorkingType, setSelectedWorkingType] =  useState<Array<SelectedWorkingType>>([]);
    const [filterSelectStatus, setFilterSelectStatus] = useState<FilterSelectType>("filterSelect");
    const [selectedAdvertDate, setSelectedAdvertDate] = useState<AdvertDateSearchType>("all");
    const [isAdvertForDisabled, setIsAdvertForDisabled] = useState<DisabledAdsType>("all");
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>(() => {
        if(state?.l) {
            const cities = JSON.parse(state?.l);
            return cities;
        } else {
            return [];
        }
    });
    const [showResponsiveFilter, setShowResposiveFilter] = useState<boolean>(false);
    const [sortMethod, setSortMethod] = useState<SearchSortType>("recommended");
    const [totalSearchResult, setTotalSearchResult] = useState<number>(0);
    const [isSortDropdown, setIsSortDropdown] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>(() => {
        if(state?.s) {
            return state?.s;
        } else {
            return "";
        }
    });
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    const [searchParams, setSearchParams] = useSearchParams();
    
    const dropdownRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && dropdownRef.current.contains(event.target as Node)) {
            setIsSortDropdown(false);
        }
    };

    useEffect(() => {
        if(window.localStorage.user) {
            if(state) {
                navigate("/app/search", {
                    state
                });
            } else {
                navigate(`/app${window.location.pathname.startsWith("/") ? window.location.pathname : "/" + window.location.pathname}${window.location.search}`); 
            }
        }
    }, []);

    useEffect(() => {
        let filters: GetAdvertsWithoutAuthRequestType = {
            language: getBrowserLanguageFull()
        };

        /* 
        //useSearchParams keys for designated states
        s searchText
        e selectedExperienceLevel
        c selectedContractType
        o selectedOccupation
        w selectedWorkingType
        l selectedCities
        d selectedAdvertDate
        x isAdvertForDisabled
        r sortMethod
        */

        let searchTextState = state?.s;
        let searchCityState = state?.l;

        if(searchTextState) {
            setSearchParams(e => {
                e.set("s",searchTextState);
                return e;
            }, {
                replace: true
            });
        }

        if(searchCityState) {
            setSearchParams(e => {
                e.set("l",searchCityState);
                return e;
            }, {
                replace: true
            });
        }

        if(searchParams.size > 0) {
            if(searchParams.get("s")) { 
                let _searchText = searchParams.get("s");
    
                if(_searchText?.length) {
                    let __searchText = _searchText;
                    setSearchText(__searchText);

                    filters.searchText = __searchText;
                }
            }

            if(searchParams.get("l")) { 
                let _setSelectedCities = searchParams.get("l");

                if(_setSelectedCities?.length) {
                    let __setSelectedCities = JSON.parse(_setSelectedCities);
                    setSelectedCities([...__setSelectedCities]);

                    //@ts-ignore
                    let _selectedCityID: Array<string> = [...__setSelectedCities].map((item) => {
                        return item.cityID;
                    });
        
                    filters.cityID = _selectedCityID;
                }
            }

            if(searchParams.get("e")) { 
                let _selectedExperienceLevel = searchParams.get("e");
    
                if(_selectedExperienceLevel?.length) {
                    let __selectedExperienceLevel = JSON.parse(_selectedExperienceLevel);
                    setSelecetedExperienceLevel(__selectedExperienceLevel);
                    //@ts-ignore
                    let _selectedExperienceID: Array<string> = __selectedExperienceLevel.map((item) => {
                        return item.experienceLevelID;
                    });
        
                    filters.experienceLevelID = _selectedExperienceID;
                }
            }

            if(searchParams.get("c")) { 
                let _selectedContractType = searchParams.get("c");
    
                if(_selectedContractType?.length) {
                    let __selectedContractType = JSON.parse(_selectedContractType);
                    setSelectedContractType(__selectedContractType);
                    //@ts-ignore
                    let _selectedContractTypeID: Array<string> = __selectedContractType.map((item) => {
                        return item.contractTypeID;
                    });
        
                    filters.contractTypeID = _selectedContractTypeID;
                }
            }

            if(searchParams.get("o")) { 
                let _selectedOccupation = searchParams.get("o");
    
                if(_selectedOccupation?.length) {
                    let __selectedOccupation = JSON.parse(_selectedOccupation);
                    setSelectedOccupation(__selectedOccupation);
                    //@ts-ignore
                    let _selectedOccupationTypeID: Array<string> = __selectedOccupation.map((item) => {
                        return item.occupationID;
                    });
        
                    filters.occupationID = _selectedOccupationTypeID;
                }
            }

            if(searchParams.get("w")) { 
                let _selectedWorkingType = searchParams.get("w");
    
                if(_selectedWorkingType?.length) {
                    let __selectedWorkingType = JSON.parse(_selectedWorkingType);
                    setSelectedWorkingType(__selectedWorkingType);
                    //@ts-ignore
                    let __selectedWorkingTypeID: Array<string> = __selectedWorkingType.map((item) => {
                        return item.workingTypeID;
                    });
        
                    filters.workingTypeID = __selectedWorkingTypeID;
                }
            }

            if(searchParams.get("d")) { 
                let _selectedAdvertDate = searchParams.get("d");
    
                if(_selectedAdvertDate?.length) {
                    /*  let __selectedAdvertDate = _selectedAdvertDate; */
                    //@ts-ignore
                    setSelectedAdvertDate(_selectedAdvertDate);
                    //@ts-ignore
                    filters.advertDate = _selectedAdvertDate;
                }
            }

            if(searchParams.get("x")) { 
                let _isAdvertForDisabled = searchParams.get("x");
    
                if(_isAdvertForDisabled?.length) {
                    //@ts-ignore
                    setIsAdvertForDisabled(true);
                    
                    //@ts-ignore
                    filters.isShowDisabledPeopleAdverts = "true";
                }
            }

            if(searchParams.get("r")) { 
                let _sortMethod = searchParams.get("r");
    
                if(_sortMethod?.length) {
                    //@ts-ignore
                    setSortMethod(_sortMethod);
                    //@ts-ignore
                    filters.sortMethod = _sortMethod;
                }
            }
        }

        onSearch(true,filters);
        scrollToTop();

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const scrollToTop = () => {
        document.getElementById("root")?.scrollTo(0,0);
    };

    const onSearch = (initial?: boolean, __filters?:GetAdvertsWithoutAuthRequestType) => {
        setLoading(true);
        let filters: GetAdvertsWithoutAuthRequestType = {
            language: getBrowserLanguageFull()
        };

        if(!initial) {
            if(searchText.length) {
                filters.searchText = searchText;
                setSearchParams(e => {
                    e.set("s",searchText);
                    return e;
                }, {
                    replace: true
                });
            } else {
                searchParams.delete("s");
            }

            if(selectedExperienceLevel.length) {
                let _experienceLevelID: Array<string> = selectedExperienceLevel.map((item) => {
                    return item.experienceLevelID;
                });

                setSearchParams(e => {
                    e.set("e",JSON.stringify(selectedExperienceLevel));
                    return e;
                }, {
                    replace: true
                });

                filters.experienceLevelID = _experienceLevelID;
            } else {
                searchParams.delete("e");
                setSearchParams(searchParams);
            }

            if(selectedContractType.length) {
                let _contractTypeID: Array<string> = selectedContractType.map((item) => {
                    return item.contractTypeID;
                });

                setSearchParams(e => {
                    e.set("c",JSON.stringify(selectedContractType));
                    return e;
                }, {
                    replace: true
                });

                filters.contractTypeID = _contractTypeID;
            } else {
                searchParams.delete("c");
                setSearchParams(searchParams);
            }

            if(selectedOccupation.length) {
                let _occupationID: Array<string> = selectedOccupation.map((item) => {
                    return item.occupationID;
                });

                setSearchParams(e => {
                    e.set("o",JSON.stringify(selectedOccupation));
                    return e;
                }, {
                    replace: true
                });

                filters.occupationID = _occupationID;
            } else {
                searchParams.delete("o");
                setSearchParams(searchParams);
            }

            if(selectedWorkingType.length) {
                let _workingTypeID: Array<string> = selectedWorkingType.map((item) => {
                    return item.workingTypeID;
                });

                setSearchParams(e => {
                    e.set("w",JSON.stringify(selectedWorkingType));
                    return e;
                }, {
                    replace: true
                });

                filters.workingTypeID = _workingTypeID;
            } else {
                searchParams.delete("w");
                setSearchParams(searchParams);
            }

            if(selectedCities.length) { 
                let _cityID: Array<string> = selectedCities.map((item) => {
                    return item.cityID;
                });

                setSearchParams(e => {
                    e.set("l",JSON.stringify(selectedCities));
                    return e;
                }, {
                    replace: true
                });

                filters.cityID = _cityID;
            } else {
                searchParams.delete("l");
                setSearchParams(searchParams);
            }

            if(selectedAdvertDate !== "all") {
                setSearchParams(e => {
                    e.set("d",selectedAdvertDate);
                    return e;
                }, {
                    replace: true
                });

                filters.advertDate = selectedAdvertDate;
            } else {
                searchParams.delete("d");
                setSearchParams(searchParams);
            }

            if(isAdvertForDisabled !== "all") {
                setSearchParams(e => {
                    e.set("x",isAdvertForDisabled);
                    return e;
                }, {
                    replace: true
                });

                filters.isShowDisabledPeopleAdverts = isAdvertForDisabled;
            } else {
                searchParams.delete("x");
                setSearchParams(searchParams);
            }

            if(sortMethod !== "recommended") {
                setSearchParams(e => {
                    e.set("r",sortMethod);
                    return e;
                }, {
                    replace: true
                });

                filters.sortMethod = sortMethod;
            } else {
                searchParams.delete("r");
                setSearchParams(searchParams);
            }
        }
        
        RESTService.action("GetAdvertsWithoutAuth",initial && __filters ? __filters : filters)
            .then((res) => {
                setTotalSearchResult(res.totalDataCount);
                setSearchResultData(res.payload);
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetAdvertsWithoutAuth Err:", err);
            });
    };

    const onChangeSelectedFilters = (_filters: SelectedFiltersType, item: SelectedFiltersObjectType) => {
        if(item.type === "advertDate") {
            setSelectedAdvertDate("all");
            return;
        }

        if(item.type === "cities") {
            let  _cities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData =selectedCities.findIndex(e => e.cityID === item._id);
            _cities.splice(isExistsInSelectedData,1);
            setSelectedCities(_cities);
            return;
        }

        if(item.type === "disabledAdverts") {
            setIsAdvertForDisabled("all");
            return;
        }

        if(item.type === "experienceLevel") {
            let  _experienceLevel: Array<SelectedExperienceLevelType> = JSON.parse(JSON.stringify(selectedExperienceLevel));
            const isExistsInSelectedData =selectedExperienceLevel.findIndex(e => e.experienceLevelID === item._id);
            _experienceLevel.splice(isExistsInSelectedData,1);
            setSelecetedExperienceLevel(_experienceLevel);
            return;
        }

        if(item.type === "sort") {
            setSortMethod("recommended");
            return;
        }

        if(item.type === "occupation") {
            let  _occupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));
            const isExistsInSelectedData =selectedOccupation.findIndex(e => e.occupationID === item._id);
            _occupation.splice(isExistsInSelectedData,1);
            setSelectedOccupation(_occupation);
            return;
        }

        if(item.type === "contractType") {
            let  _contractType: Array<SelectedContractType> = JSON.parse(JSON.stringify(selectedContractType));
            const isExistsInSelectedData =selectedContractType.findIndex(e => e.contractTypeID === item._id);
            _contractType.splice(isExistsInSelectedData,1);
            setSelectedContractType(_contractType);
            return;
        }

        if(item.type === "workingType") {
            let  _workingType: Array<SelectedWorkingType> = JSON.parse(JSON.stringify(selectedWorkingType));
            const isExistsInSelectedData =selectedWorkingType.findIndex(e => e.workingTypeID === item._id);
            _workingType.splice(isExistsInSelectedData,1);
            setSelectedWorkingType(_workingType);
            return;
        }
    }; 

    const renderSearchContainer = () => {
        return <div
            className={classes.searchContianer}
            style={{   
                borderBottomColor: colors.stroke,
                backgroundColor: colors.white,
                borderTopColor: colors.stroke,
            }}
        >
            <div 
                className={classes.searchContainerInner} 
                style={{
                    paddingRight: spaces.containerXXLarge,
                    paddingLeft: spaces.containerXXLarge,
                    paddingBottom: spaces.content,
                    paddingTop: spaces.content,
                    gap: spaces.content
                }}>
                <TextInput
                    initialValue={searchParams.get("s") ? searchParams.get("s") as string : searchText}
                    placeholder={localize("search-ads-for-you")}
                    className={classes.searchTextInput}
                    spreadBehaviour="free"
                    clearEnabled= {true}
                    iconOnClick={() => {
                        onSearch();
                    }}
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            onSearch();       
                        }
                    }}
                    icon={() => {
                        return <div
                            style={{
                                marginRight: spaces.content / 2
                            }}
                        >
                            <MagnifierIcon
                                color={colors.textGrey}
                                size={20}
                            />
                        </div>;
                    }}
                    onChangeText={(e) => {
                        setSearchText(e);
                    }}
                />
                <Button
                    className={classes.searchTextButton}
                    title={localize("search-ads")}
                    onClick={() => onSearch()}
                    spreadBehaviour="free"
                    disabled={loading}
                    loading={loading}
                />
            </div>
        </div>;
    };

    const renderSearchResponsiveContainer = () => {
        return <div
            className={classes.searchContainerResponsive}
            style={{
                paddingRight: spaces.container * 1.5,
                paddingLeft: spaces.container * 1.5,
                borderBottomColor: colors.stroke,
                backgroundColor: colors.white,
                paddingBottom: spaces.content,
                borderTopColor: colors.stroke,
                paddingTop: spaces.content,
                gap: spaces.container
            }}
        >
            <div 
                className={classes.responsivePaginationContent} 
                style={{
                    gap: spaces.container,
                }}>
                <TextInput
                    initialValue={searchParams.get("s") ? searchParams.get("s") as string : searchText}
                    placeholder={localize("search-ads-for-you")}
                    className={classes.responsiveSearchTextInput}
                    clearEnabled= {true}
                    iconOnClick={() => {
                        onSearch();
                    }}
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            onSearch();               
                        }
                    }}
                    icon={() => {
                        return <div
                            style={{
                                marginRight: spaces.content / 2
                            }}
                        >
                            <MagnifierIcon
                                color={colors.textGrey}
                                size={20}
                            />
                        </div>;
                    }}
                    onChangeText={(e) => {
                        setSearchText(e);
                    }}
                />
            </div>
            <Button
                onClick={() => {
                    setShowResposiveFilter(!showResponsiveFilter);
                }}
                title={localize("filter-and-sort")}
                spreadBehaviour="baseline"
                variant="outline"
                color="textGrey"
            />
        </div>;
    };

    const renderNoResult = () => {
        if(searchResultData.length) {
            return null;
        }

        if(loading) {
            return <div
                className={classes.loadingContainer}
                style={{
                    marginTop: spaces.container
                }}
            >
                <Loading/>
            </div>;
        }

        return <div
            className={classes.noResultContainer}
            style={{
                padding: spaces.content * 2,
                borderRadius: radiuses.hard,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.noResultContent}
                style={{
                    marginBottom: spaces.content * 2.33,
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("no-result-short")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textDark"
                >
                    {localize("no-result-long")}
                </Text>
            </div>
            <div
                className={classes.noResultContent}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    NO_RESULT.map((item) => {
                        return <Fragment key={item.id}>
                            <Text
                                variant="body4-regular"
                                color="textDark"
                            >
                        &#x2022; {localize(item.localizeText)}
                            </Text>
                        </Fragment>;
                    })
                }
            </div>
        </div>;
    };

    const renderIndicatorsContainer = () => {
        return <div
            className={classes.indicatorContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.container
            }}
        >
            <div
                className={classes.indicatorInfoContent}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {`${searchResultData.length} ${localize("job-advert")}`}
                </Text>
                <div
                    className={classes.sortDropdown}
                >
                    <Button
                        onClick={() => {
                            setIsSortDropdown((prev) => !prev);
                        }}
                        title={sortMethod === "recommended" ? localize("recomended") : sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new")}
                        textColor="textSecondary"
                        iconDirection="right"
                        color="textSecondary"
                        variant="outline"
                        icon={() => <ArrowDownIcon
                            color={colors.textGrey}
                        />
                        }
                    />
                    {isSortDropdown && <div>
                        {renderSortDropdown()}
                    </div>}
                </div>
            </div>
        </div>;
    };

    const renderSortDropdown = () => {
        return <div
            className={classes.sortContainer}
            ref={dropdownRef}
            style={{
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            {
                SORT_FILTER.map((item, index) => {
                    return <Button
                        key={`menu-item${index}`}
                        title={localize(item.localizedText)}
                        textVariant='body3-regular'
                        textColor='textGrey'
                        variant='ghost'
                        onClick={() => {
                            setSortMethod(item.value);
                            setIsSortDropdown(false);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderChoosenFilters = () => {
        let _filters: SelectedFiltersType = [];

        if(selectedExperienceLevel.length) {
            let _experienceLevels: SelectedFiltersType = selectedExperienceLevel.map((item) => {
                return {
                    _id: item.experienceLevelID,
                    localizedText: item.localizedText,
                    type: "experienceLevel"
                };
            });

            _filters = [..._filters, ..._experienceLevels];
        }

        if(selectedContractType.length) {
            let _contractType: SelectedFiltersType = selectedContractType.map((item) => {
                return {
                    _id: item.contractTypeID,
                    localizedText: item.localizedText,
                    type: "contractType"
                };
            });

            _filters = [..._filters, ..._contractType];
        }

        if(selectedOccupation.length) {
            let _occupations: SelectedFiltersType = selectedOccupation.map((item) => {
                return {
                    _id: item.occupationID,
                    localizedText: item.localizedText,
                    type: "occupation"
                };
            });

            _filters = [..._filters, ..._occupations];
        }

        if(selectedWorkingType.length) {
            let _workingTypes: SelectedFiltersType = selectedWorkingType.map((item) => {
                return {
                    _id: item.workingTypeID,
                    localizedText: item.localizedText,
                    type: "workingType"
                };
            });

            _filters = [..._filters, ..._workingTypes];
        }

        if(selectedCities.length) { 
            let _cities: SelectedFiltersType = selectedCities.map((item) => {
                return {
                    _id: item.cityID,
                    localizedText: item.localizedText,
                    type: "cities"
                };
            });

            _filters = [..._filters, ..._cities];
        }

        if(selectedAdvertDate !== "all") {
            let _selectedAdvertDate: SelectedFiltersObjectType = {
                _id: selectedAdvertDate,
                localizedText: selectedAdvertDate === "today" ? localize("todays-ad") : selectedAdvertDate === "thisWeek" ? localize("this-weeks-ads") : selectedAdvertDate === "last12Hours" ? localize("last-12-hours") : localize("last-3-hours"),
                type: "advertDate"
            };

            _filters.push(_selectedAdvertDate);
        }

        if(isAdvertForDisabled !== "all") {
            let _isAdvertForDisabled: SelectedFiltersObjectType = {
                _id: isAdvertForDisabled,
                localizedText: isAdvertForDisabled === "true" ? localize("only-disabled-ads") : localize("no-disabled-ads"),
                type: "disabledAdverts"
            };

            _filters.push(_isAdvertForDisabled);
        }

        if(sortMethod !== "recommended") {
            let _sortMethod: SelectedFiltersObjectType = {
                _id: sortMethod,
                localizedText: sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new"),
                type: "sort"
            };

            _filters.push(_sortMethod);
        }

        if(!_filters.length) {
            return null;
        }

        return <div
            className={classes.choosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersTitle}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {`${localize("choosen-filters")} (${_filters.length})`}
                </Text>
                <div
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        setSelecetedExperienceLevel([]);
                        setIsAdvertForDisabled("all");
                        setSelectedAdvertDate("all");
                        setSelectedContractType([]);
                        setSortMethod("recommended");
                        setSelectedWorkingType([]);
                        setSelectedOccupation([]);
                        setSelectedCities([]);
                    }}
                >
                    <Text
                        variant="body4-regular"
                        color="primary"
                    >
                        {localize("clear-filters")}
                    </Text>
                </div>
            </div>
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Fragment key={item._id}>
                            <Chip
                                title={item.localizedText}
                                isCancelable= {true}
                                size="medium"
                                onClick={() => {
                                    onChangeSelectedFilters(_filters,item);
                                }}
                            />
                        </Fragment>;
                    })
                }
            </div>
        </div>;
    };

    const renderFilters = () => {
        return <div>
            <FiltersContainer
                setSelecetedExperienceLevel={setSelecetedExperienceLevel}
                selectedExperienceLevel={selectedExperienceLevel}
                setSelectedContractType={setSelectedContractType}
                setSelectedWorkingType={setSelectedWorkingType}
                setIsAdvertForDisabled={setIsAdvertForDisabled}
                setSelectedOccupation={setSelectedOccupation}
                setSelectedAdvertDate={setSelectedAdvertDate}
                selectedContractType={selectedContractType}
                isAdvertForDisabled={isAdvertForDisabled}
                selectedWorkingType={selectedWorkingType}
                selectedAdvertDate={selectedAdvertDate}
                selectedOccupation={selectedOccupation}
                setSelectedCities={setSelectedCities}
                selectedCities={selectedCities}
                setSortMethod={setSortMethod}
                sortMethod={sortMethod}
            />
            <ResponsiveFilters
                setSelecetedExperienceLevel={setSelecetedExperienceLevel}
                selectedExperienceLevel={selectedExperienceLevel}
                setSelectedContractType={setSelectedContractType}
                setShowResponsiveFilter={setShowResposiveFilter}
                setSelectedWorkingType={setSelectedWorkingType}
                setIsAdvertForDisabled={setIsAdvertForDisabled}
                setSelectedOccupation={setSelectedOccupation}
                setFilterSelectStatus={setFilterSelectStatus}
                setSelectedAdvertDate={setSelectedAdvertDate}
                showResponsiveFilter={showResponsiveFilter}
                selectedContractType={selectedContractType}
                isAdvertForDisabled={isAdvertForDisabled}
                selectedWorkingType={selectedWorkingType}
                selectedAdvertDate={selectedAdvertDate}
                filterSelectStatus={filterSelectStatus}
                selectedOccupation={selectedOccupation}
                setSelectedCities={setSelectedCities}
                selectedCities={selectedCities}
                setSortMethod={setSortMethod}
                sortMethod={sortMethod}
                onSearch={onSearch}
            />
        </div>;
    };

    const renderSearchResults = () => {
        if(!searchResultData.length) {
            return null;
        }

        if(loading) {
            return <div
                className={classes.loadingContainer}
                style={{
                    marginTop: spaces.container
                }}
            >
                <Loading/>
            </div>;
        }

        return <div
            className={classes.searchResultsContainer}
            style={{
                gap: spaces.content
            }}
        >
            {
                searchResultData.slice(0,10).map((item) => {
                    let stickers: Array<StickerObjectType> = [];

                    item.boosted.forEach((boost) => {
                        stickers.push({
                            localizedText: boost.localizedText,
                            backColor: boost.backColor,
                            color: boost.color,
                            icon: () => <div
                                dangerouslySetInnerHTML={{
                                    __html: boost.icon
                                }}
                            ></div>,
                            key: boost.key
                        });
                    });

                    return <Fragment key={item._id}>
                        <ResultCard
                            key={item._id}
                            experienceLevel={item.experienceLevel.localizedText}
                            contractType={item.contractType.localizedText}
                            workingType={item.workingType.localizedText}
                            title={item.occupation.localizedText}
                            companyName={item.companyInfo.title}
                            jobImage={item.companyInfo.photoURL}
                            city={item.city.localizedText}
                            stickers={stickers}
                            onPress={() => navigate("/jobDetails", {
                                state: {
                                    jobID: item._id
                                }
                            })}
                        />
                    </Fragment>;
                })
            }
            {renderViewMore()}
            {renderBluredContainer()}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                marginTop: spaces.containerLarge * 1.8,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    onSearch(false, {
                        language: getBrowserLanguageFull(),
                        advertDate: "today"
                    });
                    setSelectedAdvertDate(() => {
                        return "today";
                    });
                }}
                disabled={loading}
                loading={loading}
            />
        </div>;
    };

    const renderViewMore = () => {
        return <div
            className={classes.renderViewMore} 
            style={{
                padding: spaces.containerXLarge,
                borderWidth: borders.indicator,
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.grey50,
                gap: spaces.content * 1.5
            }}>
            <Text variant="header3-medium">
                <Text 
                    variant="header3-medium" 
                    color="primary">
                    {`+${totalSearchResult} `}
                </Text> 
                {localize("check-out-the-advert")}
            </Text>
            <Text 
                variant="body-regular"
                color="textGrey"
            >
                {localize("please-login-to-view-all-of-the-job-adverts")}
            </Text>
            <div>
                <Button onClick={() => {
                    navigate("/auth/login");
                }}
                title={localize("login")} />
            </div>
        </div>;
    };

    const renderBluredContainer = () => {
        return <div 
            className={classes.renderBluredContainer} 
            style={{
                gap: spaces.content * 1.5,
            }}>
            {
                searchResultData.slice(10).map((item) => {
                    return <Fragment key={item._id}>
                        <ResultCard
                            key={item._id}
                            experienceLevel={item.experienceLevel.localizedText}
                            contractType={item.contractType.localizedText}
                            workingType={item.workingType.localizedText}
                            companyName={item.companyInfo.title}
                            jobImage={item.companyInfo.photoURL}
                            city={item.city.localizedText}
                            title={item.occupation.localizedText}
                        />
                    </Fragment>;
                })
            }
            {renderPagination()}
        </div>;
    };

    const renderPagination = () => {
        if(totalSearchResult < 10) {
            return null;
        }
        return <div
            className={classes.paginationContainer}
            style={{
                marginBottom: spaces.containerXLarge,
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
            }}
        >
            <Pagination
                totalDataCount= {totalSearchResult}
                currentPage={page}
                itemPerPage= {20}
                onSelect={(item, index) => {
                    setPage(item.pageNumber);
                }}
            />
        </div>;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContianer}
            style={{
                paddingBottom: spaces.containerXXLarge,
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                paddingTop: spaces.content * 1.5,
                marginBottom: spaces.container,
                backgroundColor: colors.grey25,
                gap: spaces.containerLarge * 2,
            }}
        >
            <div
                className={classes.leftContainer}
            >
                {renderIndicatorsContainer()}
                <div 
                    className={classes.mainContainer} 
                    style={{
                        gap: spaces.containerLarge * 2,
                    }}>
                    <div
                        className={classes.filterContainer}
                        style={{
                            gap: spaces.container
                        }}
                    >
                        {renderFilters()}
                    </div>
                    <div
                        className={classes.dataContainer}
                    >
                        {renderChoosenFilters()}
                        {renderSearchResults()}
                        {renderNoResult()}
                    </div>
                </div>
            </div>
            {renderRightContainer()}
        </div>;
    };

    const renderResponsiveContentContainer = () => {
        return <div
            className={classes.responsiveContentContianer}
            style={{
                marginBottom: spaces.container,
                backgroundColor: colors.grey25,
                padding: spaces.content * 1.5,
                gap: spaces.container * 3
            }}
        >
            <div
                className={classes.leftContainer}
            >
                {renderIndicatorsContainer()}
                <div 
                    className={classes.mainContainer} 
                    style={{
                        gap: spaces.containerLarge,
                    }}>
                    <div
                        className={classes.filterContainer}
                        style={{
                            gap: spaces.container
                        }}
                    >
                        {renderFilters()}
                    </div>
                    <div
                        className={classes.dataContainer}
                    >
                        {renderChoosenFilters()}
                        {renderSearchResults()}
                        {renderNoResult()}
                    </div>
                </div>
            </div>
            {renderRightContainer()}
        </div>;
    };
    

    return <Fragment>
        <div
            className={classes.container}
            style={{
                backgroundColor: colors.grey25,
            }}
        >
            {renderSearchContainer()}
            {renderSearchResponsiveContainer()}
            {renderContentContainer()}
            {renderResponsiveContentContainer()}
        </div>
        <Footer/>
    </Fragment>;
};
export default SearchPage;
