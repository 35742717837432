import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Trash = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M2 7.14c0-.678.484-1.228 1.08-1.228h3.73c.741-.022 1.395-.558 1.647-1.35a7.48 7.48 0 0 0 .042-.14l.16-.548c.099-.337.184-.63.304-.891.474-1.035 1.35-1.753 2.363-1.937.256-.046.528-.046.84-.046h4.869c.311 0 .583 0 .84.046 1.012.184 1.888.902 2.362 1.937.12.261.206.554.304.89l.16.549.042.14c.252.792 1.036 1.329 1.777 1.35h3.6c.596 0 1.08.55 1.08 1.228 0 .679-.484 1.228-1.08 1.228H3.08C2.484 8.368 2 7.818 2 7.14Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M14.034 29h1.132c3.896 0 5.844 0 7.11-1.24 1.267-1.24 1.396-3.275 1.655-7.344l.374-5.863c.14-2.208.21-3.312-.425-4.011-.635-.7-1.708-.7-3.854-.7H9.174c-2.146 0-3.22 0-3.855.7-.635.7-.565 1.803-.424 4.01l.373 5.864c.26 4.069.39 6.103 1.656 7.344C8.19 29 10.138 29 14.034 29Zm-1.89-13.736c-.057-.607-.572-1.05-1.149-.99-.577.06-.998.603-.94 1.21l.7 7.368c.058.608.572 1.05 1.15.99.576-.06.997-.602.94-1.21l-.7-7.368Zm6.06-.99c.577.06.998.603.94 1.21l-.7 7.368c-.057.608-.572 1.05-1.149.99-.577-.06-.998-.602-.94-1.21l.7-7.368c.058-.607.572-1.05 1.15-.99Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Trash;
