import {
    useState 
} from "react";
import useStyles from "./style";
import {
    IOCoreTheme,
    RadioButton,
    TextInput,
    Switcher,
    Text,
    IOCoreLocale
} from "isinolacak-web-cl";
import {
    PreAssessmentQuestionProps
} from "./types";
import {
    FilledCheckIcon,
    TrashIcon
} from "../../../../../assets/svgr";

const PreAssessmentQuestion = ({
    setQuestionText,
    setYesNoPrefer,
    setIsNumeric,
    setMinValue,
    setMaxValue,
    initialData,
    setIsSaved,
    onDelete,
    onSaved,
    index
}: PreAssessmentQuestionProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [isError, setIsError] = useState({
        yesNoRadio: false,
        question: false,
        min: false,
        max: false
    });
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const renderLockedInput = () => {
        return <div
            className={classes.savedInput}
            style={{
                padding: spaces.container * 0.75,
                backgroundColor: colors.grey50,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <Text>{initialData.question}</Text>
            <div
                className={classes.iconContainer}
                onClick={() => {
                    onDelete();
                }}
            >
                <TrashIcon size={25} color={colors.primary} />
            </div>
        </div>;
    };

    const renderTextInput = () => {
        return <TextInput
            placeholder='Adayınız için soru oluşturun...'
            initialValue={initialData.question}
            disabled={initialData.isSaved}
            key={"questionInput-"+index}
            isError={isError.question}
            spreadBehaviour='stretch'
            iconDirection='right'
            onChangeText={(value) => {
                setQuestionText(value);
            }}
            icon={({
                size
            }) => {
                return <FilledCheckIcon color={colors.primary} size={size} />;
            }}
            iconOnClick={() => {
                let _isError = JSON.parse(JSON.stringify(isError));
                let errorFlag = false;
                _isError = {
                    question: false,
                    min: false,
                    max: false
                };

                if(!initialData.isNumeric && typeof initialData.prefer !== "boolean") {
                    _isError.yesNoRadio = true;
                    errorFlag = true;
                }

                if(initialData.isNumeric && typeof initialData.prefer !== "boolean" && !initialData.prefer?.min.length) {
                    _isError.min = true;
                    errorFlag = true;
                }

                if(initialData.isNumeric && typeof initialData.prefer !== "boolean" && !initialData.prefer?.max.length) {
                    _isError.max = true;
                    errorFlag = true;
                }

                if(initialData.question.length < 2){
                    _isError.question = true;
                    errorFlag = true;
                }

                if(initialData.isNumeric && !(typeof initialData.prefer !== "boolean" && Number(initialData.prefer?.min) >= 0)){
                    _isError.min = true;
                    errorFlag = true;
                }

                if(initialData.isNumeric && !(typeof initialData.prefer !== "boolean" && Number(initialData.prefer?.max) >= 0)){
                    _isError.max = true;
                    errorFlag = true;
                }

                if(errorFlag){
                    setIsError(_isError);
                    return;
                }

                onSaved();
                setIsSaved(true);
            }}
        />;
    };

    const renderOptions = () => {
        if(initialData.isSaved){
            return;
        }
        return <div>
            <Switcher
                isActive={initialData.isNumeric}
                titleDirection='right'
                onChange={() => {
                    setIsNumeric();
                }}
                style={{
                    marginBottom: spaces.inline
                }}
                renderTitle={() => {
                    return <Text
                        variant='body3-regular'
                        color='textSecondary'
                        style={{
                            marginLeft: spaces.inline
                        }}
                    >
                        {localize("two-possible-questions")}
                    </Text>;
                }}
            />
            {
                initialData.isNumeric && typeof initialData.prefer !== "boolean" ?
                    <div
                        className={classes.questionOptions}
                        style={{
                            gap: spaces.inline
                        }}
                    >
                        <TextInput
                            initialValue={initialData.prefer?.min?.toString()}
                            spreadBehaviour='baseline'
                            className={classes.input}
                            key={"minInput-"+index}
                            isError={isError.min}
                            placeholder='min'
                            style={{
                                maxWidth: 80
                            }}
                            onChangeText={(value) => {
                                setMinValue(value);
                            }}
                        />
                        <TextInput
                            initialValue={initialData.prefer?.max?.toString()}
                            spreadBehaviour='baseline'
                            className={classes.input}
                            key={"maxInput-"+index}
                            isError={isError.max}
                            placeholder='max'
                            style={{
                                maxWidth: 80
                            }}
                            onChangeText={(value) => {
                                setMaxValue(value);
                            }}
                        />
                    </div>
                    :
                    <div
                        className={classes.questionOptions}
                    >
                        <RadioButton
                            title='Evet'
                            isSelected={initialData.prefer === true}
                            onChange={() => {
                                setYesNoPrefer(true);
                            }}
                        />
                        <RadioButton
                            title='Hayır'
                            isSelected={initialData.prefer === false}
                            onChange={() => {
                                setYesNoPrefer(false);
                            }}
                        />
                    </div> 
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            gap: spaces.inline
        }}
    >
        {
            initialData.isSaved ? 
                renderLockedInput()
                :
                renderTextInput()
        }
        {renderOptions()}
    </div>;
};

export default PreAssessmentQuestion;