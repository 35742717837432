import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Notification = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15.134 1C9.56 1 5.041 5.519 5.041 11.093v.917c0 .908-.269 1.795-.772 2.55l-1.496 2.244c-1.736 2.604-.41 6.144 2.609 6.967.984.269 1.976.496 2.974.681l.003.007C9.36 27.131 12.037 29 15.134 29c3.097 0 5.773-1.869 6.775-4.54l.002-.008c.999-.185 1.991-.412 2.975-.68 3.02-.824 4.345-4.364 2.609-6.968l-1.496-2.244a4.597 4.597 0 0 1-.772-2.55v-.917C25.227 5.519 20.708 1 15.134 1Zm4.397 23.816a37.067 37.067 0 0 1-8.794 0c.925 1.33 2.536 2.23 4.397 2.23 1.86 0 3.471-.9 4.397-2.23ZM6.994 11.093a8.14 8.14 0 0 1 16.28 0v.917a6.55 6.55 0 0 0 1.1 3.634l1.495 2.243a2.632 2.632 0 0 1-1.497 4 35.111 35.111 0 0 1-18.476 0 2.632 2.632 0 0 1-1.498-4l1.496-2.243a6.55 6.55 0 0 0 1.1-3.634v-.917Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Notification;
