import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const TrashOutline = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15 3.049a3.075 3.075 0 0 0-2.899 2.048 1.024 1.024 0 1 1-1.931-.682 5.124 5.124 0 0 1 9.66 0 1.024 1.024 0 1 1-1.931.682A3.075 3.075 0 0 0 15 3.05ZM2.366 7.488c0-.566.458-1.025 1.024-1.025h23.22a1.024 1.024 0 1 1 0 2.05H3.39a1.024 1.024 0 0 1-1.024-1.025ZM6.689 10.834a1.024 1.024 0 1 0-2.044.137l.633 9.495c.116 1.752.21 3.167.432 4.277.23 1.155.621 2.12 1.43 2.875.807.756 1.795 1.083 2.963 1.235C11.226 29 12.643 29 14.4 29h1.2c1.756 0 3.175 0 4.297-.147 1.168-.152 2.156-.479 2.964-1.235.808-.756 1.2-1.72 1.43-2.875.22-1.11.315-2.525.431-4.277l.633-9.495a1.024 1.024 0 0 0-2.044-.137l-.628 9.424c-.123 1.84-.21 3.121-.402 4.085-.186.935-.446 1.43-.82 1.78-.373.349-.884.575-1.83.699-.974.127-2.258.13-4.103.13h-1.056c-1.845 0-3.13-.003-4.104-.13-.945-.124-1.456-.35-1.83-.7-.373-.35-.633-.844-.819-1.779-.192-.964-.28-2.245-.402-4.085l-.628-9.424Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M11.483 13.298a1.024 1.024 0 0 1 1.122.917l.683 6.83a1.024 1.024 0 1 1-2.039.203l-.683-6.829a1.024 1.024 0 0 1 .917-1.121ZM19.434 14.419a1.024 1.024 0 0 0-2.039-.204l-.683 6.83a1.024 1.024 0 1 0 2.04.203l.682-6.829Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default TrashOutline;
