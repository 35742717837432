import React from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    PaqPropsCardType
} from "./types";
import {
    CheckListIcon
} from "../../../../../assets/svgr";
import moment from "moment";

const PAQCard = ({
    getMyApplication,
    onClick
}: PaqPropsCardType) => {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const renderInfoContainer = () => {
        const updatedAt = getMyApplication?.updatedAt;
        const formattedDate = updatedAt ? moment(updatedAt).format("DD.MM.YYYY") : "";
    
        let infoText = "";

        if (getMyApplication?.status === "performanceTestSubmitted") {
            infoText = localize("completed-the-performance-test");
        } else if (getMyApplication?.status === "performanceTestWaiting") {
            infoText = localize("complete-the-performance-test");
        }
        return <div
            className={classes.infoContainer}
        >
            <Text
                variant="body-regular"
                color="textDark"
            >
                {infoText}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {formattedDate}
            </Text>
        </div>;
    };

    const renderPAQIcon = () => {
        let statusColor = colors.primary;

        switch(getMyApplication?.status) {
        case "performanceTestSubmitted":
            statusColor = colors.primary;
            break;
        case "performanceTestWaiting":
            statusColor = colors.warning;
            break;
        default:
            statusColor = colors.primary;
            break;
        }
        return <div
            className={classes.chartContainer}
            style={{
                padding: spaces.inline / 4,
                borderWidth: borders.line,
                borderColor: statusColor
            }}
        >
            <CheckListIcon
                color={statusColor}
                size={20}
            />
        </div>;
    };

    const renderMenuIcon = () => {
        return <div
            className={classes.menuContainer}
        >
            <div
                className={classes.icon}
            >
                <Button
                    disabled={getMyApplication?.status === "performanceTestSubmitted"}
                    title={localize("complate")}
                    onClick={onClick}
                />
            </div>
        </div>;
    };
    
    return <div
        style={{       
            padding: `${spaces.container}px ${spaces.containerXLarge / 2}px`,
            marginBottom: spaces.content,
            marginTop:  spaces.content
        }}
    >
        <div
            className={classes.container}
        >
            <div
                className={classes.content}
                style={{
                    gap: spaces.content 
                }}
            >
                {renderPAQIcon()}
                {renderInfoContainer()}
            </div>
            {renderMenuIcon()}
        </div>
    </div>;
};

export default PAQCard;
