import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media (max-width: 1040px)": {
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            flexDirection: "column",
            display:"flex",
            width: "100%"
        },
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        position: "sticky",
        maxWidth: "15%",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            boxSizing: "border-box",
            position: "relative",
            maxWidth: "unset",
            display: "flex",
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        minHeight: 600,
        height: "100%",
        width: "73%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        width: "100%",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%"
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileImageContainer: {
        borderStyle: "solid",
        position: "relative",
        overflow: "hidden",
        borderRadius: 65,
        height: 130,
        width: 130
    },
    img: {
        objectFit: "cover",
        cursor: "pointer",
        borderRadius: 65,
        opacity: 0.9,
        height: 130,
        width: 130
    },
    icon: {
        backgroundColor: "rgba(0, 0, 0, 0.075)",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        cursor: "pointer",
        display: "flex",
        width: "100%",
        bottom: 0,
        "&:hover": {
            opacity: 0.75
        },
        "& > svg": {
            transform: "rotateZ(180deg)" // TODO: This topic will be discuss.
        }
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    profileTitleContainer: {
        justifyContent: "center",
        display: "flex",
        width: "100%"
    },
    profileTitle: {
        textAlign: "center",
        width: "100%"
    },
    occupationContainer: {
        textAlign: "center"
    }
});
export default useStyles;