import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledPlus = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15 29c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14Zm1.05-18.2a1.05 1.05 0 1 0-2.1 0v3.15H10.8a1.05 1.05 0 1 0 0 2.1h3.15v3.15a1.05 1.05 0 1 0 2.1 0v-3.15h3.15a1.05 1.05 0 1 0 0-2.1h-3.15V10.8Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledPlus;
