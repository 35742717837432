import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            width: "100%"
        }
    },
    inputContainer: {
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        display: "flex",
        width: "100%"
    },
    phoneInputContainer: {
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    areaCodesContainer: {
        justifyContent:"space-between",
        flexDirection:"row",
        display: "flex"
    },
    userAgreementCotainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex"
    },
    userAgreementContent: {
        flexDirection: "row",
        display: "flex"
    },
    selectboxContainer:{
        display: "block",
        width: "100%"
    },
    passwordRules:{
        flexDirection: "row",
        display: "flex"
    },
    agreementText: {
        display: "inline",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }
});

export default useStyles;