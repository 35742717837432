const tr: {
    code: string;
    isRTL: boolean;
    translations: IOCore.TranslationType;
} = {
    "code": "tr-TR",
    "isRTL": false,
    "translations": {
        "IOCoreDefaultDialogSecondaryButtonTitle": "İptal",
        "IOCoreDefaultDialogPrimaryButtonTitle": "Tamam",
        "IOCoreSelectBoxSelectedText": "Seçim",
        "IOCoreSelectBoxNoSelectionText": "Seçim Yapılmadı",
        "IOCoreSelectBoxMinSelectionWarningText": "${0} seçim gereklidir.",
        "IOCoreSelectBoxMaxSelectionReachedText": "Daha fazla seçemezsin.",
        "IOCoreSelectBoxSelectAll": "Tümünü Seç",
        "IOCoreSelectBoxClearAll": "Tümünü Temizle",
        "iocore-select-box-no-selection": "Seçim Yapılmadı",
        "iocore-select-sheet-clear-button": "Temizle",
        "iocore-select-sheet-ok-button": "Tamam",
        "iocore-select-box-n-selected": "{{0}} Seçim",
        "iocore-select-sheet-max-choice": "En fazla {{0}} tane seçilebilir.",
        "iocore-select-sheet-min-choice": "En az {{0}} adet seçmelisiniz.",
        "active-theme": "Tema",
        "active-language": "Dil",
        "isinolacak-cl": "IOCore Mobil",
        "isinolacak-logo": "Isinolacak Logo",
        "welcome-description": "IOCore Design Sistem ve Bileşen Kütüphanesi'ne hoş geldiniz.",
        "optional": "Opsiyonel",
        "selectionCount": "Seçim",
        "advert-sticker-announcement": "Duyuru",
        "advert-sticker-featuredAds": "Öne Çıkan İlan",
        "advert-sticker-newAd": "Yeni İlan",
        "advert-sticker-trusted": "Güvenilir",
        "e-mail": "E-Posta",
        "your-remaining-time-for-your-password": "Şifre için kalan süreniz",
        "you-have-logged-in-incorrectly-please-try-again": "Hatalı giriş yaptınız, lütfen tekrar deneyiniz",
        "finish": "Bitti",
        "next": "İleri",
        "resend-password": "Şifreyi Yeniden Gönder",
        "aplicant-card-for-employer-sticker-expired": "İlan süresi doldu",
        "aplicant-card-sticker-days-ago": "{{0}} gün önce",
        "aplicant-card-for-employer-sticker-documents": "Giriş evrakları geldi",
        "aplicant-card-for-employer-sticker-interview": "Video mülakat bitti",
        "aplicant-card-for-employer-sticker-performance-test": "Performans testi bitti",
        "aplicant-card-for-employer-sticker-hasPT": "Atalı performans testi var",
        "aplicant-card-for-employer-sticker-waiting-for-performance-test": "Performans testi bekleniyor",
        "aplicant-card-for-employer-sticker-avarage-points": "{{0}} Ortalama",
        "aplicant-card-for-employer-sticker-number-of-aplicants": "{{0}} Başvuru",
        "aplicant-card-for-employer-sticker-number-of-last-week": "Son {{0}} hafta",
        "register-corparate-phone-number-error": "Kurumsal Telefon Numarası Boş Olamaz.",
        "myActions-for-employer-entry-documents-control": "Giriş Evrakları Kontrol",
        "myActions-for-employer-performance-test-detail": "Performans Test Detayı",
        "myActions-for-employer-entry-ınterview-review": "Mülakat İncele",
        "myActions-for-employer-current-ads-sticker": "Güncel İlanlar",
        "register-phone-number-error": "Telefon Numarası Boş Olamaz.", 
        "myActions-for-employer-past-ads-sticker": "Geçmiş İlanlar",
        "network-waiting": "Ağ bekleniyor",
        "x1": "Merhaba. Test Başarılı.",
        "notifications": "Bildirimler",
        "notification-description": "Kampanya ve iş ilanları ile ilgili masaüstünde bildirimler almak istiyorum.",
        "sms": "SMS",
        "search-by-phone": "Telefon ile arama",
        "communication-preferences": "İletişim Tercihleri",
        "contact-us": "Bize Ulaşın",
        "enter-your-information.": "Bilgilerinizi giriniz.",
        "your-message": "Mesajınız",
        "save": "Kaydet",
        "city": "Şehir",
        "description": "Açıklama",
        "write-a-description": "Açıklama yazınız.",
        "education": "Eğitim",
        "add-education": "Eğitim Ekle",
        "work-experience": "İş Tecrübesi",
        "add-work-experience": "İş Tecrübesi Ekle",
        "add": "Ekle",
        "capabilities": "Beceri",
        "level": "Seviye",
        "language": "Dil",
        "certificate-name": "Sertifika Adı",
        "received-organization": "Alınan Organizasyon",
        "add-capabilities": "Beceri Ekle",
        "add-language": "Yabancı Dil Ekle",
        "add-license-and-certificate": "Lisans ve Sertifika Ekle",
        "association-or-organization-membership": "Dernek veya Kuruluş Üyeliği",
        "cv": "Özgeçmiş",
        "postings-i-applied-to": "Başvurduğum İlanlar",
        "see-all": "Tümünü Gör",
        "name": "İsim",
        "surname": "Soy İsim",
        "phone-number": "Telefon Numarası",
        "job": "Meslek",
        "company": "Şirket",
        "personal-information": "Kişisel Bilgiler",
        "change-language": "Dil Değiştir",
        "asked-questions": "Sıkça Sorulan Sorular",
        "privacy-policy": "Gizlilik Politikası",
        "delete-the-account": "Hesabı Sil",
        "log-out": "Çıkış Yap",
        "settings": "Ayarlar",
        "see-today's-ads": "Günün İlanlarını Gör",
        "we-have-compiled-the-popular-ads-of-the-day-for-you.": "Günün popüler ilanlarını senin için derledik.",
        "person-profile": "Şahıs Profili",
        "question": "Soru",
        "ı-want-to-receive-emails-about-campaigns": "Kampanyalarla ilgili e-posta almak istiyorum.",
        "needs-to-be-filled": "Doldurulması Gerekiyor",
        "license-and-certificate": "Lisans ve Sertifika",
        "add-association-or-organization-membership": "Dernek veya Kuruluş Üyeliği Ekle",
        "your-ads-will-be-displayed-here-create-an-ad-now-and-start-ad-management": "İlanlarınız burada görüntülenecektir. Hemen ilan oluşturun ve ilan yönetimine başlayın",
        "please-enter-your-new-e-mail": "Lütfen yeni E-Posta'nızı giriniz.",
        "corporate-email-update": "Kurumsal E-Posta Güncelleme",
        "email-update": "E-posta Güncelleme",
        "phone-number-update": "Telefon Numarası Güncelleme",
        "please-enter-your-new-contact-number": "Lütfen yeni irtibat numaranızı giriniz.",
        "contact-number-update": "İrtibat Numarası Güncelleme",
        "verify": "Doğrula",
        "enter-the-six-digit-password-sent-via-sms...": "SMS ile gelen 6 haneli şifreyi giriniz...",
        "my-ads": "İlanlarım",
        "document-list": "Evrak Listesi",
        "company's-trade-name": "Şirketin Ticari Ünvanı",
        "mersis-no": "Mersis No",
        "your-role-in-the-company": "Şirketteki Göreviniz",
        "sectors": "Sektör",
        "company-e-mail": "Kurumsal E-Posta",
        "website": "Website",
        "company-contact-number": "Şirket İrtibat Numarası",
        "about": "Hakkımda",
        "create-new-ad": "Yeni ilan oluştur",
        "find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.": "Yeni ilan oluşturarak aradığınız çalışanı hemen bulun.",
        "company-profile-screen": "Şirket Profili",
        "tr": "Türkçe",
        "en": "İngilizce",
        "tr-TR": "Türkçe",
        "en-US": "İngilizce",
        "degree": "Ünvan",
        "title": "Başlık",
        "end-date": "Bitiş Tarihi",
        "start-date": "Başlangıç Tarihi",
        "ı'm-still-working-here": "Halen burada çalışıyorum.",
        "education-level": "Öğrenim Seviyesi",
        "school": "Okul",
        "major": "Bölüm",
        "aplicant-card-for-employer-sticker-check-documents": "Evrakları kontrol et",
        "aplicant-card-for-employer-sticker-review-interview": "Mülakatı incele",
        "aplicant-card-for-employer-sticker-review-performance-test": "Performans testini incele",
        "applicant-card-upload-documents": "Evrakları ekleyin",
        "applicant-card-join-interview": "Mülakata katıl",
        "applicant-card-complete-performance-test": "Performans testini doldur",
        "aplicant-card-sticker-aplication-being-reviewed": "Başvurunuz İnceleniyor",
        "aplicant-card-sticker-aplication-submited": "Başvurunuz İletildi",
        "aplicant-card-sticker-documents-submited": "Giriş evraklarınız iletildi",
        "aplicant-card-sticker-performance-test-submited": "Performans testi iletti",
        "aplicant-card-sticker-video-interview-invitation": "Video mülakata davet ediyor",
        "enter-the-password-sent-via-sms": "sms ile gelen şifreyi giriniz",
        "post-an-ad": "İlan Yayınla",
        "you-will-view-your-activities-for-your-live-advertisements-on-this-page": "Yayında olan ilanlarınız için hareketlerinizi bu sayfada görüntüleyeceksiniz",
        "this-place-is-empty-right-now": "Şu anda burası boş",
        "overview": "Genel Bakış",
        "myActions-worker-ongoing-applications": "Devam eden başvurular",
        "myActions-worker-saved": "Kaydedilenler",
        "myActions-worker-past": "Geçmiş",
        "myActions-worker-entry-documents-submitted": "Giriş evrakları iletilen",
        "myActions-worker-video-ınterview": "Video mülakatları",
        "myActions-worker-performance-testing": "Performans Testi",
        "search-ads-for-you": "Sana uygun ilanları ara",
        "search-ads": "İlan Ara",
        "filter-and-sort": "Filtrele & Sırala",
        "no-result-short": "Aradığınız ilan bulunumadı.",
        "no-result-long": "Aradığınız ilanı bulamadık... Doğru sonuçlara ulaşmak için şunlara dikkat etmelisiniz:",
        "recomended": "Önerilen",
        "new-to-old": "Yeniden Eskiye",
        "old-to-new": "Eskiden Yeniye",
        "todays-ad": "Bugünün ilanları",
        "this-weeks-ads": "Son 1 Hafta",
        "last-12-hours": "Son 12 saat",
        "last-3-hours": "Son 3 saat",
        "all": "Tümü",
        "only-disabled-ads": "Sadece engelli ilanlarını göster",
        "no-disabled-ads": "Engelli ilanlarını gösterme",
        "hide-aplied-ads": "Başvurulan ilanları gizle",
        "choosen-filters": "Seçili Filtreler",
        "clear-filters": "Filtreleri Temizle",
        "discover": "Keşfet",
        "popular-searches": "Popüler Aramalar",
        "previus-page": "Önceki",
        "next-page": "Sonraki",
        "location": "Lokasyon",
        "no-result-found": "Sonuç Bulunamadı",
        "work-preference": "Çalışma Tercihi",
        "working-type": "Çalışma Şekli",
        "ads-date": "İlan Tarihi",
        "search-job": "Meslek Ara",
        "position-level": "Pozisyon Seviyesi",
        "ads-for-disabled": "Engelli İlanları",
        "other-ads-filters": "Diğer İlan Filtreleri",
        "filters": "Filtreler",
        "filter": "Filtre",
        "sorting": "Sıralama",
        "location-city": "Konum / Şehir",
        "filter-it": "Filtrele",
        "hide-revieved-ads": "İncelenen ilanları gizle",
        "only-1-time-ads": "Sadece bir kez yayınlanan ilanlar",
        "no-result-1": "Yazım yanlışı yapmadığınızdan emin olmalısınız. ",
        "no-result-2": "Daha genel kullanıma sahip kelimeler kullanabilirsiniz. (Örn: \"HR\" yerine \"İK\" ya da \"İnsan Kaynakları\")",
        "no-result-3": "Daha farklı kelimeler kullanabilirsin. (Örn: \"Yazılımcı\" yerine \"Backend Yazılım Uzmanı\")",
        "no-result-4": "Kısaltma kullanmak yerine, kelimeleri açık şekilde yazabilirsiniz. (Örn: \"Müh\" yerine \"Mühendis\")\"",
        "no-result-5": "Arama sonuçlarında engellenen şirketin profili ve ilanları görünmez. Arama yaptığın kelimenin engellediğin bir şirketle ilgili olup olmadığından emin olmalısınız.",
        "profile": "Profil",
        "address": "Adres",
        "county": "İlçe",
        "security": "Gizlilik",
        "security-description": "Profilimi CV havuzunda gösterme.",
        "ıf-you-return-this-transaction-will-be-canceled": "Geri dönmeniz halinde bu işlem iptal edilecektir",
        "you-are-about-to-cancel-the-transaction": "İşlemi iptal etmek üzeresiniz",
        "give-up": "Vazgeç",
        "cancel": "İptal Et",
        "login": "Giriş Yap",
        "register": "Kayıt Ol",
        "create-document": "Evrak Oluştur",
        "create-performance-test": "Performans Testi Oluştur",
        "create-document-desc": "İş arayan adaylarınızdan beklediğiniz evrakları oluşturabileceğiniz alan.",
        "create-performance-test-desc": "İş arayan adaylarınızın çeşitli konularda ölçümleyebilmek için kullanabileceğiniz alan.",
        "register-last-name-length-error": "Soyisim 3 ile 33 karakter arasında olmalıdır",
        "register-company-title-error": "Şirket adı 3 ile 100 karakter arasında olmalıdır",
        "register-name-length-error": "İsim 3 ile 55 karakter arasında olmalıdır",
        "register-company-district-error": "Şirketin Bulunduğu İlçe Boş Bırakılamaz",
        "register-company-city-error": "Şirketin Bulunduğu Şehir Boş Bırakılamaz",
        "register-corparate-mail-error": "Geçersiz kurumsal e-posta adresi",
        "register-password-error": "Şifre gereksinimleri karşılamıyor",
        "register-with-company-account": "Şirket Hesabı ile Kayıt Ol",
        "register-with-personal-account": "Şahıs Hesabı ile Üye Ol",
        "register-repeat-password-error": "Parolar eşleşmiyor",
        "register-area-code-error": "Alan Kodu Boş Olamaz.",
        "register-email-error": "Geçersiz e-posta adresi",
        "register-sector-error": "Sektör Boş Bırakılamaz",
        "register-occupation-error": "Meslek Boş Olamaz.",
        "registration-information": "Kayıt Bilgilerin",
        "company-district": "Şirketin Bulunduğu İlçe",
        "company-city": "Şirketin Bulunduğu Şehir",
        "password-verification": "Şifre Doğrulama",
        "company-website": "Şirket Web Sitesi",
        "password": "Şifre",
        "advert-steps": "İlan Adımları",
        "create-advert": "İlan Oluşturma",
        "advert-header-&-desc": "İlan Başlığı & Açıklama",
        "publish-advert": "İlanı Yayınla",
        "job-title": "İş Ünvanı",
        "edit-list-search": "Ara...",
        "advert-detail": "İlan Detayı",
        "employer-title": "Çalışan Ünvanı",
        "working-time": "Çalışma Zamanı",
        "job-locale": "İşin Konumu",
        "position-detail": "Pozisyon Detayı",
        "abilities": "Yetenekler",
        "add-ability": "Yetenek ekle",
        "paq": "Ön Değerlendirme Soruları",
        "add-new-question": "Yeni soru ekle",
        "create-the-add": "İlan Oluşturun",
        "create": "Oluştur",
        "advert-confirm": "İlan Onayı",
        "suitable-for-disabled": "Engelli adaylar için uygun ilandır.",
        "myActions": "Hareketlerim",
        "jobAdverts": "İş ilanları",
        "myFavorites": "Favorilerim",
        "myApplications": "Başvurularım",
        "findEmployee": "Çalışan Bul",
        "blog": "Blog",
        "staff-search": "Eleman Arama",
        "my-messages": "Mesajlarım",
        "management-panel": "Yönetim Paneli",
        "about-the-job-posting": "İş İlanı Hakkında",
        "application-process": "Başvuru Süreci",
        "manage-advert": "İlanı Yönet",
        "edit-ad": "İlanı Düzenle",
        "job-details": "İlan Detay",
        "applicant-candidates": "Başvuran Adaylar",
        "company-about": "Şirket Hakkında",
        "company-ınformation": "Şirket Bilgileri",
        "apply": "Başvur",
        "manage-application": "Başvuruyu Yönet",
        "similar-job-postings": "Benzer İş İlanları",
        "we-don't-want-you-to-miss-the-employee-you-are-looking-for": "Aradığın çalışanı kaçırmanı istemeyiz",
        "you-are-about-to-remove-your-ad": "İlanınızı kaldırmak üzeresiniz",
        "remove": "Kaldır",
        "send-reply": "Cevabı Gönder",
        "pre-assessment-questions": "Ön Değerlendirme Soruları",
        "ı-applied": "Başvuru Yaptıklarım",
        "documents-ı-sent": "Evrak Gönderdiklerim",
        "my-current-ads": "Güncel İlanlarım",
        "my-past-ads": "Geçmiş İlanlarım",
        "takedowns": "Yayından Kaldırılanlar",
        "my-draft-ads": "Taslak İlanlarım",
        "saved": "Kaydedilenler",
        "messages": "Mesajlar",
        "remote-work-ads": "Uzaktan Çalışma İlanları",
        "part-time-work-ads": "Yarı Zamanlı",
        "intern-work-ads": "Stajyer İlanları",
        "manager-work-ads": "Yönetici İlanları",
        "yes": "Evet",
        "no": "Hayır",
        "access-denied": "Yetkisiz erişim.",
        "company-not-found": "Şirket bulunamadı.",
        "advert-not-found": "İlan bulunamadı.",
        "user-must-have-cv-before-apply": "Başvuru yapmak için lütfen önce CV oluşturun.",
        "already-have-an-application": "Bu ilana zaten başvurdunuz.",
        "this-advert-is-deleted": "Bu ilan silinmiş.",
        "must-answer-pre-assesstment-questions-to-apply": "Başvuru yapmak için lütfen ön eleme sorularını cevaplayın.",
        "advert-application-failed": "İlan başvurusunda bir hata oluştu.",
        "ı-am-a-suitable-candidate-for-disabled-postings": "Engelli ilanları için uygun adayım.",
        "resume-approval": "Özgeçmiş Onayı",
        "hobbies": "Hobiler",
        "hundreds-of-employers-thousands-of-new-job-listings-every-day-platform": "Yüzlerce işveren, her gün binlerce yeni iş ilanı barındıran platform!",
        "not-a-member-sign-up": "Üye değil misiniz? Hemen kaydolun.",
        "hundreds-of-position-details": "Yüzlerce pozisyon detayı",
        "daily-updated-listings": "Her gün güncellenen ilanlar",
        "thousands-of-free-listings": "Ücretsiz Binlerce İlan",
        "easy-access-from-both-web-and-mobile": "Hem web hem mobilden kolayca erişim sağlayın.",
        "which-work-model-suits-you-better": "Sana Hangi Çalışma Modeli Daha Uygun?",
        "remote-work-model-or-shift-work-part-time-or-full-time-discover-the-work-model-that-suits-you-with-isinolacak-privilege": "Uzaktan çalışma modeli ya da vardıyalı olarak mı? Yarı zamanlı yoksa full zamanlı mı ? Kendine uygun iş modelini isinolacak ayrıcalığı ile keşfet.",
        "discover-now": "Şimdi Keşfet",
        "popular-listings": "Popüler Olan İlanlar",
        "all-listings": "Tüm İlanlar",
        "career-guide": "Kariyer Rehberi",
        "featured-listings": "Öne Çıkanlar",
        "popular-positions": "Popüler Pozisyonlar",
        "connections": "Bağlantılar",
        "services": "Servisler",
        "all-rights-reserved": "Tüm hakları saklıdır.",
        "about-us": "Hakkımızda",
        "terms-of-use": "Kullanım Koşulları",
        "faq": "SSS",
        "contact": "İletişim",
        "istanbul-job-listings": "İstanbul İş İlanları",
        "ankara-job-listings": "Ankara İş İlanları",
        "bursa-job-listings": "Bursa İş İlanları",
        "antalya-job-listings": "Antalya İş İlanları",
        "search": "Ara",
        "find-candidates": "Aday Bul",
        "no-aplicant-to-advert": "İlanınıza başvuran aday yok.",
        "no-apply-for-now": "Şimdilik hiçbir başvuru yok.",
        "achieve-success-on-your-career-path": "Kariyer Yolculuğunuzda Başarıya Ulaşmak için İpuçları !",
        "share-post": "Gönderiyi Paylaş",
        "contents": "İçindekiler",
        "check-out-this-amazing-blog-post": "Harika blog yazısına göz atın.",
        "continue-reading": "Devamını Oku",
        "about-job-advert": "İş ilanı hakkında",
        "search-location": "Konum Ara",
        "search-occupation": "Pozisyon ara",
        "created-ads-will-be-listed-for-you-to-use-later": "Oluşturulan İlanlar Daha Sonra Kullanabilmeniz İçin Listelenicek",
        "previously-created-documents": "Daha Önce Oluşturulan Evraklar",
        "creating-documents": "Evrak Oluşturma",
        "document-detail": "Evrak Detayı",
        "new-document-name": "Yeni Evrak İsmi",
        "add-document": "Evrak Ekle",
        "documents": "Evraklar",
        "use": "Kullan",
        "2-option-answer": "2 seçenekli yanıt",
        "first-option": "1. Seçenek",
        "second-option": "2. Seçenek",
        "third-option": "3. Seçenek",
        "three-options": "3 seçenekli yanıt",
        "5-point-answer": "1-5 arası puanlama",
        "10-point-answer": "1-10 arası puanlama",
        "multi-select-answer": "Birden fazla seçilebilir",
        "open-ended-answer": "Açık uçlu yanıt",
        "create-performance-test-title": "Performans Testi Oluşturma",
        "create-new-pt": "Yeni Performans Testi Oluştur",
        "delete-pt-warn-header": "Performans Testini Silmek Üzeresiniz",
        "delete-pt-warn-content": "Testi düzenleyip kullanabilirsiniz",
        "edit": "Düzenle",
        "delete-question": "Soruyu Sil",
        "test-list": "Test Listesi",
        "pt-test-header": "Performans Test Başlığı",
        "header": "Başlık",
        "question-type": "Soru Tipi",
        "add-question": "Soru Ekle",
        "save-question": "Soruyu Kaydet",
        "create-test": "Testi Oluştur",
        "must-confirm-all-questions": "Devam etmek için tüm sorular onaylanmalıdır!",
        "expectation": "Beklenti",
        "create-answer": "Cevap Ekle",
        "ideal-responses": "İdeal Cevap ya da cevaplar",
        "save-questions": "Soruları Kaydet",
        "create-ad": "İlan Oluştur",
        "no-messages-yet": "Henüz Mesaj Yok",
        "view-actions-for-active-ads": "Yayında olan ilanlarınız için hareketlerinizi bu sayfada görüntüleyeceksiniz.",
        "messaging": "Mesajlaşma",
        "advert-name": "{{0}} ilanı",
        "terminated": "Sonlandırılmış",
        "archived": "Arşivlenmiş",
        "terminate-chat": "Sohbeti sonlandır",
        "unarchive": "Arşivden kaldır",
        "archive": "Arşivle",
        "incoming-messages": "Gelen Mesajlar",
        "applicants": "Başvuran Adaylar",
        "contacted-companies": "İletişim Kurulan Şirketler",
        "unread": "Okunmamış",
        "unread-message": "{{0}} Okunmamış mesaj",
        "enter-answer": "Cevabınızı giriniz",
        "enter-as-number": "Numara olarak girmeniz rica edilir.",
        "advert-apply-success": "İlana başvurma başarılı !",
        "invalid-request": "Geçersiz İstek !",
        "please-login-to-view-all-of-the-job-adverts": "Tüm ilanları inceleyebilmek için lütfen giriş yapın.",
        "check-out-the-advert": "İlanı İnceleyin",
        "job-advert": "iş ilanı",
        "something-went-wrong": "Bir şeyler ters gitti.",
        "sorry-we-couldnt-reach-the-page-you-were-looking-for-please-try-again": "Üzgünüz, aradığınız sayfaya ulaşamadık, yeniden deneyiniz.",
        "go-back": "Geri dön",
        "job-title-and-description": "İş Ünvanı & Açıklama",
        "position-details": "Pozisyon Detayı",
        "job-approval": "İlan Onayı",
        "you-need-to-fill-out-this-field": "Bu alanı doldurmanız gerekmektedir.",
        "job-creation-successful": "İlan oluşturma başarılı",
        "pricing": "Fiyatlandırma",
        "home": "Anasayfa",
        "email-format-error": "Mail format hatası",
        "password-length-limit": "Şifreniz en az 8 en fazla 16 hane uzunluğunda olabilir.",
        "login-error": "Giriş işlemi sırasında bir hata oluştu.",
        "forgot-password": "Şifremi unuttum",
        "or": "veya",
        "help": "Yardım",
        "data-policy": "Veri Politikamız",
        "experience": "Deneyim",
        "job-postings": "İş İlanları",
        "collapse": "Daralt",
        "not-yet-added": "Henüz hakkımda eklenmedi.",
        "no-position-ınformation": "Pozisyon Bilgisi Yok",
        "no-university-ınformation": "Üniversite Bilgisi Yok",
        "no-location-ınformation": "Konum Bilgisi Yok",
        "no-experience-added-yet": "Henüz deneyim eklenmedi",
        "no-education-added-yet": "Henüz eğitim eklenmedi.",
        "no-license-and-certification-added-yet": "Henüz lisans ve sertifika eklenmedi.",
        "no-language-added-yet": "Henüz dil eklenmedi.",
        "no-skills-added-yet": "Henüz yetenek eklenmedi.",
        "no-associations-an-organizations-added-yet": "Henüz dernek ve kuruluş eklenmedi.",
        "no-hobbies-added-yet": "Henüz hobi eklenmedi.",
        "no-job-postings-have-been-added-yet": "Henüz iş ilanı eklenmedi",
        "management-of-application-advertisements": "Başvuru ilanların yönetimi",
        "application": "Başvuru",
        "all-my-application-advertisements": "Tüm Başvuru İlanlarım",
        "applied-document": "Başvuruldu",
        "performance-test-waiting": "Performans Test Bekleniyor",
        "performance-test-submitted": "Performans Test Cevaplandı",
        "document-waiting": "Evrak Listesi Bekleniyor",
        "document-sent": "Evrak Listesi Gönderildi",
        "rejected": "Başvuru Reddedildi",
        "applied": "Başvuru Yapıldı",
        "document": "Evraklar Bekleniyor",
        "required-qualifications": "Aranan Nitelikler",
        "required-qualifications-description": "Yeteneklerinizle uyuşuyor. İş ilanı için uygun olabilirsiniz.",
        "skills-added-by-the-user-who-created-the-job-posting": "İş İlanını Oluşturan Kullanıcı Tarafından Eklenen Yetenekler:",
        "see-company-profile": "Şirket profilini gör",
        "finished": "Görüşme tamamlandı",
        "yes-no-answer": "2 Olasılıklı Cevap",
        "numeric-answer": "Numerik Cevap",
        "sector-information-was-not-entered": "Sektör bilgisi girilmedi",
        "no-website-information-entered": "Web sitesi bilgisi girilmedi",
        "location-information-was-not-entered" :"Konum bilgisi girilmedi",
        "ıt-was-edited-successfully": "Başarılı bir şekilde düzenlendi.",
        "ıt-was-added": "Başarılı bir şekilde gönderildi.",
        "saved-successfully": "Başarılı bir şekilde kaydedildi",
        "updated-successfully": "Başarılı bir şekilde güncellendi",
        "performance-test-was-sent-successfully": "Performans Test başarılı bir şekilde gönderildi",
        "a-document-type-with-this-name-already-exists": "Bu isimde bir belge türü zaten var",
        "document-created-successfully":"Evrak Başarılı bir şekilde oluşturuldu",
        "a-document-with-this-name-already-exists": "Bu isimde bir evrak zaten var",
        "advert-no-doc": "Bu ilana ait döküman yok!",
        "advert-no-pt": "Bu ilana ait performans testi yok !",
        "all-candidates": "Tüm Adaylar",
        "candidate-process": "Aday İşlemleri",
        "candidate-rejeceted": "Aday başvurusu red edildi.",
        "waiting-for-complete": "Tamamlanması Bekleniyor",
        "completed": "Tamamlandı",
        "pt-test": "Performans Testi",
        "doc-operations": "Evrak İşlemleri",
        "waiting-for-doc": "Evrak bekleniyor",
        "doc-sended": "Evrak gönderildi",
        "doc-send": "Evrak Gönder",
        "waiting-for-reupload-doc": "Yeniden Evrak Bekleniyor",
        "download-all": "Tümünü İndir",
        "see-all-docs": "Tüm evrakları gör",
        "approved": "Onaylandı",
        "rejected-doc": "Red Edildi",
        "peak-to-profile": "Profile Bakış",
        "all-see": "Tümünü gör",
        "filter-score": "Puana Göre",
        "filter-location": "Konuma Göre",
        "filter-capability": "Becerilere Göre",
        "filter-experience": "Tecrübeye Göre",
        "review-pt-results": "Performans Test Sonuçlarını incele",
        "ideal-response-rate": "İdeal yanıt oranı",
        "advert-management": "İlan Yönetimi",
        "score-5": "5 - En iyi aday",
        "score-4": "4 - Uygun aday",
        "score-3": "3 - Ortalama aday",
        "score-2": "2 - Düşük aday",
        "score-1": "1 - Yetersiz aday",
        "finish-application": "Başvuruyu Sonuçlandır",
        "send-pt": "Performans Testi Gönder",
        "reject-candidate": "Adayı Red Et",
        "beginner": "Yeni Başlayan",
        "1+": "1+",
        "3+": "3+",
        "5+": "5+,",
        "10+": "10+",
        "send-message": "Mesaj Gönder",
        "send-internal-message": "Sistem içi mesaj gönder",
        "copy-phone-number": "Telefon numarasını kopyala",
        "send-messsage": "Mesaj gönder",
        "pt-results": "Performans Testi Sonuçları",
        "ideal-response": "İdeal Cevap",
        "recived-answer": "İletilen Cevap",
        "paq-results": "Ön Değerlendirme Sonuçları",
        "application-of": "{{0}} Başvurusu",
        "choose-status": "Durumu Seç",
        "see-profile": "Profili gör",
        "aplied-status": "Başvuru yapıldı",
        "doc-list-sended": "Evrak listesi gönderildi",
        "doc-list-waiting": "Evrak listesi bekleniyor",
        "reupload-doc-waiting": "Yeniden evrak bekleniyor",
        "interview-completed": "Aday ile görüşme tamamlandı",
        "pt-sended": "Performans testi gönderildi",
        "pt-result-waiting": "Performans testinin sonuçlanması bekleniyor",
        "candidate-rejected": "Aday red edildi",
        "docs-not-sended": "Adayın yüklediği bir doküman yok!",
        "why-should-you-choose-us": "Neden Bizi Tercih Etmelisiniz?",
        "our-vision": "Vizyonumuz",
        "our-values": "Değerlerimiz",
        "our-phone-number": "Telefon Numaralarımız",
        "our-email-address": "E-Posta Adreslerimiz",
        "our-address": "Adreslerimiz",
        "contact-us-now": "Hemen Bizimle İletişim Kurun!",
        "your-message-has-been-sent": "Mesajınız Gönderildi!",
        "congratulations-your-message-has-been-successfully-sent": "Tebrikler, mesajınız başarı ile gönderildi.",
        "you-will-be-contacted-as-soon-as-possible": "En kısa sürede tarafınıza dönüş sağlanacaktır.",
        "contact-us-by-form": "Bizimle iletişim kurmak için aşağıdaki formu doldurun veya doğrudan e-posta adresimizden bize ulaşın. Sorularınızı yanıtlamaktan ve size yardımcı olmaktan memnuniyet duyarız.",
        "send": "Gönder",
        "no-aplicant-header": "Henüz Başvuru Yapılmadı",
        "no-aplicant-content": "Tüm Adaylar Burada Görüntülenecek",
        "no-aplicant-statecard-header": "Henüz Başvuru Yok",
        "no-aplicant-statecard-content": "Başvuru yapan tüm adaylar burada görüntülenecetir.",
        "no-aplicant-content-content": "Henüz Başvuru Alınmadı",
        "no-aplicant-content-header": "Başvuru yapan kullanıcıların ayrıntılı bilgileri bu alanda listelenecektir. Bu alandan kolayca adayların iş alım sürecini yönetebilirsiniz...",
        "no-aplicant-content-buton": "İlanlarımı Gör",
        "in-the-last-24-hours": "Son 24 saat içerisinde",
        "added-yesterday": "Dün Eklenen",
        "added-one-week-ago": "1 hafta önce eklenen",
        "added-one-month-ago": "1 ay önce eklenen",
        "added-one-years-ago": "1 yıl önce eklenen",
        "by-application-date" :"Başvuru Tarihine Göre",
        "take-down": "Yayından Kaldır",
        "create-draft" : "Taslak Oluştur",
        "delete-advert": "İlanı Sil",
        "complete-the-performance-test": "Performans Testini Tamamlayınız",
        "complate": "Tamamla",
        "document-upload-screen": "Evrak Yükleme Ekranı",
        "see-all-documents": "Tüm Evrakları Gör",
        "sent": "Gönderilmiş",
        "rejected-document": "Tekrar Gönder",
        "completed-the-performance-test": "Performans testi tamamlandı",
        "send-documents": "Evrakları Gönder",
        "documents-sent": "Evraklar Gönderildi",
        "missing-documents-are-waiting": "Eksik Evraklar Bekleniyor",
        "install": "Yükleyin",
        "uploaded": "Yüklendi",
        "go-company-page": "Şirket Sayfasına Git",
        "aplied-ad": "Başvurunuz İletildi",
        "aplied-ad-content": "İlan sahibine başvurunuz iletildi",
        "empty-company-about": "Burası şimdilik boş...",
        "advert-link-copied": "İlan Paylaşma",
        "advert-link-copied-content": "İlan linki panoya kopyalandı",
        "no-file-selected-or-upload-token-missing": "Hiçbir dosya seçilmedi veya yükleme token'ı eksik",
        "the-file-has-been-uploaded-successfully": "Dosya başarıyla yüklendi",
        "the-postings-you-apply-for-will-be-displayed-here": "Başvurduğun ilanlar burada görüntülenecek",
        "all-applications-submitted-will-be-displayed-here": "Başvurduğun tüm ilanlar burada görüntülenecek",
        "application-has-not-been-made-yet": "Henüz başvuru yapılmadı",
        "all-details-of-your-applications-and-application-processes-will-be-displayed-here": "Yaptığınız başvurların tüm detayları ve başvuru süreçleri burada görüntülenecektir",
        "find-ad": "İlan bul",
        "file-size-exceeds-limit": "File size exceeds limit",
        "advert-count-length": "{{0}} iş ilanları",
        "current-search-page": "Şu an {{0}} sayfa içerisinde {{1}}. sayfadasınız.",
        "find-candidate": "Çalışan Bul",
        "search-suitable-candidate-for-you": "Sana uygun aday ara",
        "search-candidate": "Aday Ara",
        "show-only-disabled-candidates": "Sadece engelli adayları göster",
        "dont-show-disabled-candidates": "Engelli adayları gösterme",
        "disable-candidate": "Engelli Aday",
        "foreign-language": "Yabancı Dil",
        "skill": "Yetenek",
        "occupation": "Meslek",
        "experience-level": "Eğitim Seviyesi",
        "search-skill": "Yetenek Ara",
        "find-the-suitable-candidate": "İlana Uygun Adayı Hemen Bul!",
        "let-us-notify-you": "Bu araman ile uygun yeni bir aday kayıt olduğunda sana haber verelim.",
        "no-candidate-found": "Aradığınız aday bulunamadı.",
        "no-candidate-found-long": "Aradığınız adayı bulamadık... Doğru sonuçlara ulaşmak için aşağıdakilere dikkat etmelisiniz:",
        "you-are-currently-on-page": "Şuan {{0}} sayfa içerisinde {{1}}. sayfadasınız",
        "candidate": "{{0}} Aday",
        "are-you-sure-you-want-to-unpublish": "Yayından kaldırmak istediğinizden emin misiniz?",
        "are-you-sure-you-want-to-delete-the-advert": "İlanı silmek istediğinizden emin misiniz?",
        "delete": "Sil",
        "republish": "Yeniden Yayınla",
        "are-you-sure-you-want-to-republish": "Yeniden yayınlamak istediğinizden emin misiniz?",
        "publish": "Yayınla",
        "remaove-from-saved": "Kaydedilenlerden Kaldır",
        "create-advert-from-draft": "Taslaktan İlan Oluştur",
        "go-to-advert": "İlana Git",
        "draft-created": "Taslak oluşturuldu.",
        "ok": "Tamam",
        "training-details": "Eğitim Detayı",
        "end-date-cannot-be-before-start-date": "Bitiş tarihi başlangıç tarihinden küçük olamaz",
        "other": "Diğer",
        "you-are-about-to-delete-your-account": "Hesabınızı silmek üzeresiniz",
        "you-are-about-to-delete-your-account-desc": "Hesabınızı siliyorsunuz, silmeniz halinde tüm bilgileriniz silinecektir",
        "month": "Ay",
        "year": "Yıl",
        "ınstalled-successfully": "Başarıyla Yüklendi",
        "there-are-currently-no-popular-ads": "Henüz popüler ilan bulunmamaktadır.",
        "there-are-currently-no-featured-ads": "Henüz öne çıkan bulunmamaktadır.",
        "the-most-popular-ads-for-you-will-be-displayed-here": "Sizin için en popüler ilanlar burada görüntülenecektir.",
        "the-featured-ads-for-you-will-be-displayed-here": "Sizin için öne çıkan ilanlar burada görüntülenecektir.",
        "message-length-error": "Mesajınız en az 4 hane uzunluğunda olabilir.",
        "with-isinolacak-you-will-reach-your-career-goals": "İşin Olacak ile kariyer hedeflerinize bir adım daha yakınsınız! Teknoloji ve yapay zeka destekli çözümlerimizle, en uygun iş ilanlarına kolayca ulaşabilir, başvurularınızı kolayca yönetebilir ve kariyeriniz için en iyi fırsatları keşfedebilirsiniz. Hemen üye olun, kariyer yolculuğunuza güçlü bir başlangıç yapın!",
        "next-step": "Sonraki Adım",
        "already-have-an-account": "Zaten bir hesabın var mı?",
        "welcome": "Hoşgeldiniz",
        "daily": "Günlük",
        "weekly": "Haftalık",
        "monthly": "Aylık",
        "yearly": "Yıllık",
        "unreviewed-applications": "İncelenmemiş Başvurular",
        "number-of-applications": "Başvurusu Sayısı",
        "view-counts": "Görüntülenme Sayısı",
        "active-adverts": "Aktif İlanlar",
        "current-job-seekers": "Güncel İş Arayanlar",
        "week": "Hafta",
        "application-date": "Başvuru Tarihi",
        "days-ago": "gün önce",
        "candidates-that-match-will-be-shown": "İlanlarınıza uygun adaylar burada görüntülenecektir.",
        "suitable-candiate": "Uygun Çalışan",
        "your-password-must-be-at-least-8-characters": "Şifreniz en az 8 karakter olmalı,",
        "one-capital-letter": "bir büyük harf,",
        "a-lowercase-letter": " bir küçük harf,",
        "ıt-must-contain-one-special-character-and-one-number": "bir özel karakter ve bir rakam içermeli.",
        "verify-email-address": "Mail Adresinizi Doğrulayın!",
        "publish-your-first-advert-by-verifying": "Doğrulama yaparak ilk ilanınızı yayınlayın.",
        "review-job-posting-by-verifying": "Doğrulama yaparak iş ilanlarını inceleyin.",
        "company-email-verification": "Kurumsal E-Posta Doğrulama",
        "please-enter-your-email": "Lütfen E-Posta'nızı giriniz",
        "email-sent": "E-Posta Gönderildi",
        "email-could-not-be-sent": "E-Posta Gönderilemedi",
        "ıt-must-be-at-least-3-characters": "En az 3 karakter olması gerekiyor",
        "ı'm-currently-working-here-and-the-end-date-cannot-be-filled-at-the-same-time": "Halen burada çalışıyorum ile bitiş tarihi aynı anda dolu olamaz",
        "invalid-email-format": "Email formatında değil",
        "only-numbers-are-allowed": "Yalnızca rakama izin verilir",
        "it-cannot-be-longer-than-10-digits": "10 Rakamdan uzun olamaz",
        "ıt-must-be-at-least-10-characters": "En az 10 karakter olması gerekiyor",
        "ıt-must-be-at-most-100-characters": "En fazla 100 karakter olmalı",
        "ıt-must-be-at-most-500-characters": "En fazla 500 karakter olmalı",
        "ıt-must-be-at-most-2000-characters": "En fazla 2000 karakter olmalı",
        "ıt-must-be-at-least-2-characters": "En az 2 karakter olması gerekiyor",
        "enter-new-password": "Yeni parolanızı giriniz.",
        "code-input": "Mail ile gelen 6 haneli şifreyi giriniz...",
        "remaining-time": "Şifre için kalan süreniz:",
        "set-worker-alarm": "Çalışan Alarmı Kur",
        "set-worker-alarm-info": "Çalışan Alarmı’na uygun bir çalışan bulunduğunda size e-posta ile bilgi göndereceğiz. İstediğinizde düzenleyebilir ya da silebilirsiniz.",
        "alarm-title": "Alarm Başlığı",
        "create-alarm-name": "Alarm adı oluşturunuz.",
        "set-advert-alarm": "İş İlanı Alarmı Kur",
        "set-advert-alarm-info": "İş Alarmı’na uygun bir ilan yayınlandığında size e-posta ile bilgi göndereceğiz. İstediğinizde düzenleyebilir ya da silebilirsiniz.",
        "set-alarm-now": "İlanları Kaçırma Hemen Alarm Kur",
        "set-alarm-now-info": "Bu araman uygun yeni bir ilan yayınlandığında sana haber verelim.",
        "my-advert-alarm": "İlan Alarmlarım",
        "create-new-alarm": "Yeni Alarm Oluştur",
        "edit-advert-alarm": "İş İlanı Alarmını Düzenle",
        "edit-advert-alarm-info": "Oluşturduğun iş alarmına ait filtre seçimlerini kaldırabilir ya da yenilerini istediğiniz zaman ekleyebilirsiniz.",
        "you-are-about-to-delete-the-advert-alarm": "İş Alarmını silmek üzeresiniz",
        "you-can-edit-the-alarm-and-reuse-it": "Alarmı düzenleyip yeniden kullanabilirsiniz.",
        "my-candidate-alarm": "Aday Alarmlarım",
        "edit-candidate-alarm": "Aday Alarmını Düzenle",
        "edit-candidate-alarm-info": "Oluşturduğun aday alarmına ait filtre seçimlerini kaldırabilir ya da yenilerini istediğiniz zaman ekleyebilirsiniz.",
        "you-are-about-to-delete-the-candidate-alarm": "Aday Alarmını silmek üzeresiniz",
        "alarm-title-cannot-be-left-blank": "Alarm başlığı boş bırakılamaz.",
        "no-filter-selected": "Hiçbir filtre seçilmedi.",
        "occupation-selection-is-required": "Meslek seçimi gereklidir.",
        "max-five-alarms-allowed": "En fazla 5 tane alarm oluşturabilirsiniz.",
        "reduce": "Küçült",
        "isinolacak-platform": "İsinolacak Platformu",
        "find-answers-to-your-questions-easily": "Aradığınız soruya kısa yollar ile kolayca erişin.",
        "active-advert": "Aktif İlan",
        "hidden-advert": "Gizli İlan",
        "advert-last-update-date": "Son Güncelleme: {{0}}",
        "advert-removed-from-publication-date": "{{0}} - Yayından Kaldırıldı",
        "advert-end-date": "İlan Bitiş Tarihi: {{0}}",
        "advert-publication-date": "Yayınlanma Tarihi: {{0}}",
        "draft-advert-creation-date": "Oluşturma Tarihi: {{0}}",
        "advert-saved-date": "Kaydedilme Tarihi: {{0}}",
        "application-sent": "Başvurunuz Gönderildi",
        "performance-test-completed": "Performans Testi Tamamlandı",
        "performance-test-submit-waiting": "Performans Testi Çözümü Bekleniyor",
        "documents-submit-waiting": "Evrak Gönderimi Bekleniyor",
        "documents-in-review": "Evraklar İnceleniyor",
        "documents-completed": "Evraklar Tamamlandı",
        "document-resubmit-waiting": "Evrak Revizyonu Bekleniyor",
        "application-rejected": "Başvurunuz Olumsuz Sonuçlandı",
        "open-to-apply": "Başvuruya Açık",
        "close-to-apply": "Başvuruya Kapalı",
        "redirecting": "Yönlendiriliyorsunuz",
        "job-seeker-application-date": "{{0}} gün önce başvurdu",
        "premium": "Premium",
        "choose-your-role-in-company": "Şirketteki görevinizi seçiniz.",
        "choose-your-sector": "Sektörünüzü seçiniz.",
        "enter-your-address-info": "Adres bilgilerinizi seçiniz.",
        "choose-city": "Şehir seçiniz.",
        "choose-district": "İlçe seçiniz.",
        "choose-occupation": "Meslek seçiniz.",
        "choose-your-communication-information": "İletişim bilgilerinizi giriniz.",
        "choose-the-contact-message": "İletmek istediğiniz mesajı yazınız.",
        "take-a-step-using-isinolacak": "İşin olacak ile kariyerinizde yeni bir adım atın.",
        "enter-information-about-yourself": "Kendiniz hakkında kısaca bilgi veriniz.",
        "enter-information-about-yourself-or-your-company": "Şirketiniz veya kendiniz hakkında kısaca bilgi veriniz.",
        "unread-items": "Okunmayanlar",
        "seconds-ago": "{{0}} saniye",
        "minute-ago": "{{0}} dakika",
        "hour-ago": "{{0}} saat",
        "day-ago": "{{0}} gün",
        "subscriptions": "Aboneliklerim",
        "no-subscription-yet": "Henüz aboneliğiniz bulunmamaktadır.",
        "subscribe-to-benefit-from-privileges": "İşin Olacak ayrıcalıklarından faydalanmak için hemen abone olun.",
        "subscribe": "Abone Ol",
        "upgrade": "Yükselt",
        "upgrade-your-subscription": "Aboneliğinizi Yükseltin!",
        "upgrade-subscription-to-benefit-from-more-privileges": "Daha fazla İşin Olacak ayrıcalıklarından faydalanmak için hemen aboneliğinizi yükseltin.",
        "purchase-history": "Geçmiş Satın Alımlarım",
        "access-all-purchase-details": "Tüm satın alım detaylarınıza buradan ulaşabilirsiniz.",
        "go-to-plan": "Plana Git",
        "cancel-subscription": "Aboneliği İptal Et",
        "purchase-code": "Sipariş Kodu",
        "purchase-date": "Satın Alım Tarihi",
        "order-date": "Sipariş Tarihi",
        "purchase-content": "Satın Alım İçeriği",
        "payment-detail": "Ödeme Detayı",
        "free-use": "Ücretsiz Kullanım",
        "premium-membership": "Premium Üyelik",
        "packages": "Paketler",
        "choose-payment-method": "Ödeme Yöntemi Seçin",
        "payment-method-via-bank-info": "Banka havalesi ile ödeme.",
        "payment-method-via-card-info": "Visa, MasterCard ve diğer kartlar kabul edilir.",
        "pay-via-iban": "IBAN ile Ödeme Yap",
        "pay-via-card": "Kart ile Ödeme Yap",
        "bank-iban-no": "Banka IBAN Numarası",
        "bank-institution-name": "Banka Kurum Adı",
        "choose-bank": "Banka Seç",
        "copy": "Kopyala",
        "send-payment-statement": "Ödeme Bildirimi Gönder",
        "your-payment-statement-received": "Ödeme Bildiriminiz Yapıldı",
        "your-payment-statement-received-info": "Tebrikler, ödeme bildiriminiz gönderildi. Kısa süre sonra paketiniz hesabınıza tanımlanacaktır.",
        "buy-subscription": "Abonelik Satın Al",
        "completed-orders": "Tamamlanan Siparişler",
        "pending-orders": "Bekleyen Siparişler",
        "no-pending-orders-yet": "Şuanda bekleyen bir sipariş bulunmamaktadır.",
        "no-completed-orders-yet": "Henüz tamamlanmış bir sipariş bulunmamaktadır.",
        "no-subscription": "Abonelik Yok",
        "two-possible-questions": "İki olasılıklı soru",
        "sign-up": "kaydolun.",
        "our-data-policy": "Veri Politikamız",
        "your-career-will-be-with-you": "İşin olacak ile kariyerinizde",
        "take-a-new-step": "yeni bir adım atın.",
        "application-process-text": "İşin Olacak olarak, başvurunuzun her aşamasında sizin yanınızdayız. Başvurunuzu gönderdikten sonra, başvurunuzun durumunu kolayca takip edebilir, süreçteki adımları anlık olarak görüntüleyebilirsiniz. İlgili iş veren başvurunuzu incelediğinde, sistem üzerinden bilgilendirileceksiniz. Tüm süreç şeffaf ve hızlı ilerler; bu sayede kariyer adımlarınızı güvenle atabilirsiniz. İşin Olacak ile her başvuru, yeni bir fırsatın kapısını aralar",
        "update-your-email-preference": "E Posta Tercihinizi Güncelleyin",
        "email-preference-update-page-text-content":"E-posta tercihlerinizi güncelleyerek hangi tür e-postaları almak istediğinizi belirleyebilirsiniz. Pazarlama içerikli mailler, sistem mailleri, iş ilanı tavsiye mailleri, başvuru durum mailleri ve iş ilanı yayınlama mailleri gibi farklı türlerdeki e-postaları açıp kapatabilirsiniz. Bu sayede, yalnızca ilginizi çeken ve ihtiyaç duyduğunuz bilgilere ulaşabilir, gereksiz e-postalarla zaman kaybetmezsiniz. Tercihlerinizi seçtikten sonra 'Kaydet' butonuna basmayı unutmayın.",
        "mail-content-types": "Mail içerik türleri",
        "marketing-emails": "Pazarlama Mailleri:",
        "marketing-emails-text-content": "Platformun yeni özellikleri, güncellemeler ve kampanyalar hakkında kullanıcıları bilgilendirerek etkileşimi artırmayı amaçlayan mailler.",
        "system-emails": "Sistem Mailleri:",
        "system-emails-text-content": "Kullanıcıların hesap etkinlikleri, başvuru durumları ve ilan güncellemeleri gibi sistemle ilgili bilgilendirmeleri içeren mailler.",
        "job-recommendation": "İş İlanı Tavsiye Mailleri (sadece iş arayan):",
        "job-recommendation-text-content": "Açıklama: İş arayan kullanıcılara, profil ve tercihlerine en uygun iş ilanlarını öneren kişiselleştirilmiş mailler.",
        "application-status": "Başvuru Durum Mailleri (sadece iş arayan):",
        "application-status-text-content": "İş arayanların başvurularının durumu hakkında (örneğin, başvuru alındı, görüşmeye çağrıldı, reddedildi) bilgilendiren mailler.",
        "job-posting": "İş İlanı Yayınlama Mailleri (sadece iş veren):",
        "job-posting-text-content": "İş verenlerin iş ilanı yayınlama sürecini kolaylaştırmak ve optimize etmek için ipuçları, hatırlatmalar ve öneriler içeren mailler. (düzenlendi)"
    }
};
export default tr;
