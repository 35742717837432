import {
    useState
} from "react";
import useStyles from "./styles";
import {
    NavPathDataType
} from "./type";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    SIDEBAR_NAV_LINKS_WITHOUTH_LOGIN
} from "../../constants";
import {
    useGlobalState 
} from "../../context/globalState";
import {
    RESTService 
} from "../../services/restAPI";
import {
    ChatService 
} from "../../services/chat";
import {
    ChevronLeftIcon,
    HomeIcon,
    ExitIcon
} from "../../assets/svgr";
import {
    useNavigate
} from "react-router-dom";
import {
    NavbarContext 
} from "../../context";
import {
    NavLink
} from "./views";

const SidebarMenu = () => {
    const classes = useStyles();

    const {
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const navigate = useNavigate();
    
    const {
        activePath
    } = NavbarContext.NavigationContext.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const [navPathsData, setnavPathsData] = useState<Array<NavPathDataType>>(SIDEBAR_NAV_LINKS_WITHOUTH_LOGIN);
    const [isLoadingLogout, setIsLoadingLogout] = useState<boolean>(false);
    const [isSmall, setIsSmall] = useState(true);

    const logout = () => {
        setIsLoadingLogout(true);
        RESTService.action("Logout", {
        })
            .then(() => {
                window.localStorage.clear();
                setGlobalState({
                    company: null,
                    user: null
                });
                setIsLoadingLogout(false);
                navigate("/auth/login");
                ChatService.disconnect();
            })
            .catch((err) => {
                console.error("Logout Err:", err);
            });
    };

    const renderMenuContainer = (categoryTitle: string, navPathData: Array<NavPathDataType>) => {
        return <div
            className={classes.menuContainer}
        >
            <div
                className={classes.categoryTitle}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.container
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        overflow: "hidden"
                    }}
                >
                    {isSmall ? "" : categoryTitle}
                </Text>
            </div>
            {
                navPathData.map((item, index) => {
                    return <NavLink
                        key={"navLink"+index}
                        isActive={activePath === item.path}
                        title={localize(item.titleKey)}
                        icon={item.icon}
                        path={item.path}
                        isIcon={isSmall}
                    />;
                })
            }
        </div >;
    };

    const renderSizeButton = () => {
        return <div
            className={classes.sizeButtonContainer}
            style={{
                backgroundColor: colors.primary,
                right: spaces.container * -1.2,
                top: spaces.container * 1.1,
                padding: spaces.content
            }}
            onClick={() => {
                setIsSmall(!isSmall);
            }}
        >
            <div
                className={classes.sizeButtonContent}
                style={{
                    transform: isSmall ? "scaleX(-1)" : "initial"
                }}
            >
                <ChevronLeftIcon
                    color={colors.white}
                    size={15}
                />
            </div>
        </div>;
    };

    const parts = window.location.pathname.split(/\/+/);
    const paths = parts.filter(e => e !== "");
    if(paths[0] !== "app"){
        return null;
    }

    return globalState.user?.type === "employer" ?
        <div
            className={classes.container}
            style={{
                paddingLeft: isSmall ? 0 : spaces.content * 2,
                minWidth: isSmall ? "4em" : "16em",
                width: isSmall ? "4em" : "16em",
                borderRightColor: colors.stroke,
                backgroundColor: colors.layer1,
                borderWidth: borders.line
            }}
        >
            {renderSizeButton()}
            <div
                className={classes.topContainer}
            >   
                <Button
                    title={isSmall ? undefined : localize("home")}
                    icon={() => <HomeIcon/>}
                    spreadBehaviour="free"
                    textColor= "primary"
                    color= "lightBlue"
                    size='large'
                    style={{
                        marginBottom: spaces.container,
                        marginTop: spaces.container
                    }}
                    onClick={() => {
                        navigate("/app/home");
                    }}
                />
                {renderMenuContainer(localize("management-panel"), navPathsData)}
            </div>
            <div
                className={classes.back}
                style={{
                    padding: spaces.container
                }}
                onClick={() => {
                    navigate("/");
                }}
            >
                <Button
                    title={isSmall ? undefined : "Çıkış yap"}
                    loading={isLoadingLogout}
                    spreadBehaviour="free"
                    textColor= "textWhite"
                    color= "warning"
                    size='large'
                    style={{
                        marginTop: spaces.container
                    }}
                    icon={() => {
                        return <ExitIcon
                            color={colors.textWhite}
                        />;
                    }}
                    onClick={() => {
                        logout();
                    }}
                />
            </div>
        </div>
        :
        null;
};
export default SidebarMenu;