import {
    useState 
} from "react";
import {
    IOCoreTheme 
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    CareuselTrackDataType 
} from "./types";

const CareuselTrackData = ({
    data 
}: CareuselTrackDataType) => {
    const classes = useStyles();

    const {
        radiuses,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const [slideIndex, setSlideIndex] = useState(0);

    const currentSlide = (index: number) => {
        setSlideIndex(index);
    };
    
    return (
        <div>
            <div 
                style={{
                    paddingRight: spaces.item,
                    paddingLeft: spaces.item
                }}
                className={classes.carousel}
            >
                <div
                    className={classes.carouselTrack}
                    style={{
                        transform: `translateX(-${slideIndex * 100}%)` 
                    }}
                >
                    {data.map(({
                        additionalProps,
                        Component
                    }) => (
                        <div className={classes.card} key={additionalProps.id}>
                            {<Component {...additionalProps}/> }
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.dots}>
                {data.map(({
                    title
                }, index) => (
                    <span
                        key={title}
                        className={`${classes.dot}`}
                        onClick={() => currentSlide(index)}
                        style={{
                            backgroundColor: slideIndex === index ? colors.primary : colors.gray80,
                            borderRadius: radiuses.half,
                            marginRight: spaces.inline,
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default CareuselTrackData;