import {
    useEffect,
    useRef 
} from "react";
import {
    AccordionCard,
    IOCoreTheme, 
    Text 
} from "isinolacak-web-cl";
import {
    ChevronRightIcon 
} from "../../../../../assets/svgr";
import {
    useStyles 
} from "./styles";
import {
    RowCardAccordionItemProps 
} from "./types";

const RowCardAccordionItem = ({
    setClickedChipId,
    isClickedChip,
    answerData, 
    question, 
    onClick,
    isOpen
}: RowCardAccordionItemProps) => {
    const classes = useStyles();
    
    const {
        borders,
        colors, 
        spaces 
    } = IOCoreTheme.useContext();

    const contentRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(contentRef.current && wrapperRef.current && isClickedChip) {
            wrapperRef.current.scrollIntoView({
                behavior: "smooth" 
            });
        }
        setClickedChipId(undefined);
    }, [isClickedChip]);

    useEffect(() => {
        if(contentRef.current) {
            if (isOpen) {
                contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
            } else {
                contentRef.current.style.height = "0px";
            }
        }
    }, [isOpen]);

    return <div 
        id={answerData[0].categoryID}
        className={classes.wrapper}
        ref={wrapperRef}
    >
        <div 
            className={`${isOpen ? classes.questionContainerActive : classes.questionContainer}`} 
            onClick={onClick}
            style={{
                padding: `${spaces.containerLarge}px ${spaces.inline}px`
            }}
        >
            <Text 
                variant="header3-medium"
            >
                {question}
            </Text>
            <div 
                style={{
                    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)", 
                    marginRight: spaces.container 
                }}
            >
                <ChevronRightIcon 
                    color={isOpen ? colors.greyBase : colors.stroke} 
                />
            </div>
        </div>

        <div
            className={classes.answerContainer} 
            ref={contentRef}
            style={{
                borderWidth: borders.indicator,
                borderColor: colors.stroke              
            }}
        >
            <div 
                className={classes.accordionCardContainer} 
                style={{
                    paddingBottom: spaces.containerLarge,
                    paddingTop: spaces.containerLarge,
                    gap: spaces.container
                }}
            >
                {answerData.map((item) => (
                    <AccordionCard  
                        content={item.content}
                        title={`${item.index}-) ${item.title}`}
                        key={item.key}
                    />
                ))}
            </div>
        </div>
    </div>;
};

export default RowCardAccordionItem;
