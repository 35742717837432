import {
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    FilledNotoficationIcon,
    CloseIcon
} from "../../../../../assets/svgr";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    IAlarmFiltersTypes
} from "./types";
import {
    SubscribeAlarmMatchParametersTypes
} from "../../../../../services/restAPI/actions/subscribeAlarm/types";

const AlarmContainer= ({
    foreignLanguages,
    isDisabledPeople,
    educationLevel,
    occupation,
    capability,
    city
}: IAlarmFiltersTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();


    const [isViewSetAlarm, setIsViewSetAlarm] = useState<boolean>(false); 
    const [alarmName, setAlarmName] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const subscribeAlarm = () => {
        if(!alarmName){
            setErrorText(localize("alarm-title-cannot-be-left-blank"));
            return;
        }

        if(!occupation?.length){
            setErrorText(localize("occupation-selection-is-required"));
            return;
        }

        setErrorText("");

        let _matchParameters : SubscribeAlarmMatchParametersTypes ={
            occupationIDs: occupation,
        };

        if(city?.length){
            _matchParameters.cityIDs = city;
        }
        if(educationLevel?.length){
            _matchParameters.educationLevelIDs = educationLevel;
        }

        if(foreignLanguages?.length){
            _matchParameters.foreignLanguages = foreignLanguages;
        }

        if(isDisabledPeople){
            _matchParameters.isDisabledPeople = true;
        }

        if(!isDisabledPeople){
            _matchParameters.isDisabledPeople = false;
        }

        if(capability?.length){
            _matchParameters.capabilityIDs = capability;
        }
        
        RESTService.action("SubscribeAlarm",{
            title: alarmName,
            matchParameters:_matchParameters
        })
            .then((res) => {
                setIsViewSetAlarm(false);
            })
            .catch((err) => {
                setErrorText(localize(err.message));
                console.error("subscribeAlarm Err:", err);
            });
    };

    const renderAlarmInfo = () =>{
        return <div
            className={classes.alarmInfoContainer}
        >
            <div 
                className={classes.alarmInfoContent}
                style={{
                    margin: `${0}px ${spaces.inline/6}px ${spaces.content}px ${spaces.inline/6}px`,
                    borderRadius: radiuses.half * 1.5,
                    padding:`${spaces.container}px `,
                    backgroundColor: colors.primary
                }}
            >
                <div
                    className={classes.textField}
                    style={{
                        gap: spaces.inline
                    }}
                >
                    <Text
                        variant="body-regular"
                        color="textWhite"
                    >
                        {localize("find-the-suitable-candidate")}
                    </Text>
                    <Text
                        variant="body3-regular"
                        color="textWhite"
                    >
                        {localize("let-us-notify-you")}
                    </Text>
                </div>
                <Button
                    onClick={() => {
                        setIsViewSetAlarm(true);
                    }}
                    title={localize("create") }
                    variant="filled"
                    color="white"
                    textColor="primary"
                    icon={() => <FilledNotoficationIcon
                        color={colors.primary}
                        size={16}
                    />
                    }
                    spreadBehaviour="free"
                />
            </div>
        </div>;
    };
    
    const renderPageModal = () =>{
        return <PageModal
            onOverlayPress={() => {
                setIsViewSetAlarm(false);
                setErrorText("");
            }}
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.content
                    }}
                >
                    <div
                        className={classes.closeButtonField}
                    >
                        <Button
                            spreadBehaviour="free"
                            variant="ghost"
                            icon={()=>{
                                return <CloseIcon
                                    size={16}
                                    color={colors.textGrey}
                                />;
                            }}
                            onClick={() =>{
                                setIsViewSetAlarm(false);
                                setErrorText("");
                            }}
                            style={{
                                justifyContent: "end",
                                flexDirection: "row",
                                display: "flex",
                                margin: 0,
                                padding: 0
                            }}
                        />
                    </div>
                    <div
                        className={classes.dialogHeader}
                        style={{
                            marginBottom: spaces.container,
                            gap: spaces.content
                        }}
                    >
                        <Text
                            variant="header4-medium"
                            color="backgroundDark"
                        >
                            {localize("set-worker-alarm")}
                        </Text>
                        <div
                            className={classes.line}
                            style={{
                                backgroundColor: colors.stroke
                            }}
                        >
                        </div>
                        <Text
                            variant="body2-regular"
                            color="backgroundDark"
                        >
                            {localize("set-worker-alarm-info")}
                        </Text>
                        <Text
                            variant="body-medium"
                            color="backgroundDark"
                            style={{
                                marginTop: spaces.container *2
                            }}
                        >
                            {localize("alarm-title")}
                        </Text>
                        <TextInput
                            placeholder={localize("create-alarm-name")}
                            onChangeText={(text) => setAlarmName(text)}
                            spreadBehaviour="free"
                            style={{
                                width:"100%"
                            }}
                        />
                    </div>
                    <Text
                        variant="body-medium"
                        color="danger"
                        style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            display: "flex",
                            width: "100%"
                        }}
                    >
                        {errorText}
                    </Text>
                    <div
                        className={classes.dialogBottom}
                        style={{
                            gap: spaces.content,
                            marginTop: spaces.content
                        }}
                    >
                        <Button
                            title={localize("give-up")}
                            spreadBehaviour="free"
                            variant="outline"
                            onClick={() => {
                                setIsViewSetAlarm(false);
                                setErrorText("");
                            }}
                        />
                        <Button
                            title={localize("save")}
                            spreadBehaviour="free"
                            onClick={() => {
                                subscribeAlarm();
                            }}
                        />
                    </div>
                </div>
            }
            isVisible={isViewSetAlarm}
        />;
    };

    return <div>
        {renderAlarmInfo()}
        {renderPageModal()}
    </div>;
};

export default AlarmContainer;
