import React, {
    useEffect, 
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextArea,
    Text
} from "isinolacak-web-cl";
import {
    SummaryType
} from "./types";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    GetCitiesPayloadObjectType
} from "../../../../../../../../services/restAPI/actions/getCities/types";
import {
    getErrorText 
} from "../../../../../../../../utils";

const CoverLetter = ({
    setSummary,
    setErrors,
    setCity,
    summary,
    errors,
    city
}: SummaryType) =>  {
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const [cities, setCities] = useState<Array<GetCitiesPayloadObjectType>>([]);

    useEffect(() => {
        getCities();
    }, []);

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCities(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderCity = () => {
        return <div>
            <div
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <SelectBox
                    infoText={errors.includes("city-cityID-length-error") ?  localize("needs-to-be-filled") : undefined}
                    initialSelectedItems={city && city._id ? [city] : []}
                    isError={errors.includes("city-cityID-length-error")}
                    titleExtractor={(item) => item.localizedText}
                    keyExtractor={(item) => item._id}
                    title={localize("city")}
                    isNeedConfirm={true}
                    multiSelect={false}
                    disabled={false}
                    onOk={({
                        selectedItems,
                        closeSheet,
                        onSuccess
                    }) => {
                        if(selectedItems[0]) {
                            setCity({
                            //@ts-ignore // TODO: Will fix from component library.
                                localizedText: selectedItems[0].localizedText,
                                //@ts-ignore
                                _id: selectedItems[0]._id
                            });
                            let _errors = JSON.parse(JSON.stringify(errors));
                            let nameError = "city-cityID-length-error";
                            let index = _errors.indexOf(nameError);
                            _errors.splice(index, 1);
                            setErrors(_errors);
                        }
                        closeSheet();
                        onSuccess();
                    } }
                    inputTitle={""}
                    data={cities}
                />
            </div>
        </div>;
    };

    const renderCoverLetter = () => {
        return <div>
            <div
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant='body-regular'
                    color='textDark'
                >
                    {localize("description")}
                </Text>
                <TextArea
                    infoText={errors.includes("summary-length-error") ?  localize("ıt-must-be-at-least-3-characters") : undefined}
                    isError={errors.includes("summary-length-error")}
                    onFocus={() => {
                        let _errors = JSON.parse(JSON.stringify(errors));
                        let nameError = "summary-length-error";
                        let index = _errors.indexOf(nameError);
                        _errors.splice(index, 1);

                        setErrors(_errors);
                    }}
                    onChangeText={(text) => setSummary(text)}
                    placeholder={localize("write-a-description")}
                    initialValue={summary}
                    style={{
                        marginTop: spaces.content
                    }}
                />
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderCity()}
            {renderCoverLetter()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default CoverLetter;
