import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const StickerDocument = ({
    size = 30,
    color = "#16A34A",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M1 12.2v5.6c0 3.96 0 5.94 1.23 7.17.304.305.655.534 1.07.706-.01-.062-.02-.125-.027-.187C3.1 24.204 3.1 22.6 3.1 20.739V9.262c0-1.861 0-3.465.172-4.75l.027-.187a3.232 3.232 0 0 0-1.069.706C1 6.26 1 8.24 1 12.2ZM29 12.2v5.6c0 3.96 0 5.94-1.23 7.17a3.233 3.233 0 0 1-1.07.706c.01-.062.02-.125.027-.187.173-1.285.173-2.889.173-4.75V9.262c0-1.861 0-3.465-.172-4.75a11.592 11.592 0 0 0-.027-.187c.414.172.765.401 1.069.706C29 6.26 29 8.24 29 12.2Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M6.43 2.23C5.2 3.46 5.2 5.44 5.2 9.4v11.2c0 3.96 0 5.94 1.23 7.17C7.66 29 9.64 29 13.6 29h2.8c3.96 0 5.94 0 7.17-1.23 1.23-1.23 1.23-3.21 1.23-7.17V9.4c0-3.96 0-5.94-1.23-7.17C22.34 1 20.36 1 16.4 1h-2.8C9.64 1 7.66 1 6.43 2.23ZM9.75 22c0-.58.47-1.05 1.05-1.05H15a1.05 1.05 0 1 1 0 2.1h-4.2c-.58 0-1.05-.47-1.05-1.05Zm1.05-6.65a1.05 1.05 0 1 0 0 2.1h8.4a1.05 1.05 0 1 0 0-2.1h-8.4ZM9.75 10.8c0-.58.47-1.05 1.05-1.05h8.4a1.05 1.05 0 1 1 0 2.1h-8.4c-.58 0-1.05-.47-1.05-1.05Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default StickerDocument;