import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        alignItems: "center",
    }
});
export default useStyles;