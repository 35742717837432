import React, {
    Fragment,
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreTranslationType,
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    StateCard,
    Loading,
    Sticker,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    AnnouncementIcon,
    CelebrationIcon,
    LogoutIcon,
    RadarIcon,
    ShieldIcon,
    FireIcon,
    ShareIcon,
    VerifiedCheckIcon
} from "../../../assets/svgr";
import {
    AdvertCard 
} from "../../../components";
import {
    RESTService
} from "../../../services/restAPI";
import {
    StickerObjectType,
    StickerDataType 
} from "./types";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import emptyProfileImage from "../../../assets/img/isinolacakMiniWithPadding.png";
import {
    GetAdvertDetailWithoutAuthResponseType 
} from "../../../services/restAPI/actions/getAdvertDetailWithoutAuth/types";
import Info from "../../../assets/svgr/infoIcon";
import {
    getBrowserLanguageFull 
} from "../../../utils";

/* const STICKER_DATA: StickerDataType = {
    "advert-sticker-featuredAds" : {
        titleKey: "advert-sticker-featuredAds",
        icon: () => <FireIcon size={12}/>,
        frontColor: "error",
        backColor: "error50",
    },
    "advert-sticker-newAd": {
        titleKey: "advert-sticker-newAd",
        icon: () => <CelebrationIcon size={12}/>,
        frontColor: "success",
        backColor: "success50"
    },
    "advert-sticker-announcement": {
        titleKey: "advert-sticker-announcement",
        icon: () => <AnnouncementIcon size={12}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
    },
    "advert-sticker-trusted": {
        titleKey: "advert-sticker-trusted",
        icon: () => <ShieldIcon size={12}/>,
        frontColor: "announcementFrontColor",
        backColor: "announcementBackColor",
    },
}; */

const WorkerJobDetails = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        state
    } = useLocation();

    const urlParams = useParams();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [advertData, setAdvertData] = useState<GetAdvertDetailWithoutAuthResponseType>();
    const [isShareVisible,setIsShareVisible] = useState(false);
    const [isVisible,setIsVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shareURL, setShareURL] = useState("");
    const [, setID] = useState("");

    useEffect(() => {
        if(window.localStorage.user && advertData) {
            navigate("/app/jobDetails",{
                state: {
                    jobID: advertData?._id
                }
            });
        }
    }, [advertData?._id]);

    useEffect(() => {
        if(state) {
            setID(state.jobID);
            setShareURL(`${window.location.origin}/share/jobDetails/${state.jobID}`);
            handleGetAdvertWiltDetail(state.jobID);
        } else if (urlParams.id) {
            setID(urlParams.id);
            setShareURL(`${window.location.origin}/share/jobDetails/${urlParams.id}`);
            handleGetAdvertWiltDetail(urlParams.id);
        } else {
            navigate("/notFound");
        }
    }, []);

    const handleGetAdvertWiltDetail = (_jobID:string) => {
        setLoading(true);
        RESTService.action("GetAdvertDetailWithoutAuth", {
            language: getBrowserLanguageFull(),
            advertID: _jobID
        })
            .then((res) => {
                setAdvertData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetAdvertDetailWithoutAuth Err:", err);
                setLoading(false);
            });
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    /* const renderItem = ({
        item
    }: {
        item: StickerObjectType | undefined,
        index: number;
    }) => {

        return <div style={{
            borderRadius: radiuses.hard,
            height: "fit-content",
        }}>
            <Sticker
                title={localize(item?.titleKey as keyof IOCoreTranslationType)}
                titleColor={item?.frontColor}
                color={item?.backColor}
                icon={item?.icon}
                spreadBehaviour="free"
                style={{
                    paddingBottom: spaces.content / 4.3,
                    paddingTop: spaces.content / 4.3,
                    marginRight: spaces.content,
                    borderRadius: radiuses.hard,
                }}
            />
        </div>;
    }; */

    /* const renderTags = () => {
        const stickers = advertData?.boosted; 

        if (!stickers || !stickers.length) {
            return null;
        }

        const newStickersData: Array<StickerObjectType | undefined> = stickers.map(propsStickerData => {
            let curSticker;
            if(propsStickerData.color === "featuredAdFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-featuredAds"];
            }

            if(propsStickerData.color === "newAdFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-newAd"];
            }

            if(propsStickerData.color === "announcementFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-announcement"];
            }

            if(propsStickerData.color === "trustedFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-trusted"];
            }

            return curSticker;
        });

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 1.4,
                paddingTop: spaces.content / 2
            }}
        >
            {
                newStickersData.map((item: StickerObjectType | undefined, index: number) => {
                    return <Fragment key={String(item?.titleKey) + index}>
                        {renderItem({
                            item,
                            index
                        })}
                    </Fragment>;
                })
            }
        </div>;
    }; */

    const renderBoosts = () => {
        if(!advertData?.boosted.length) {
            return null;
        }

        return <div
            className={classes.sticker}
            style={{
                paddingBottom: spaces.content / 1.4,
                gap: spaces.container
            }}
        >
            {
                advertData.boosted.map((item, index) => {
                    return  <Sticker
                        key={`boosted-${index}`}
                        title={item.localizedText}
                        color={item.backColor}
                        titleColor={item.color}
                        icon={() => <div
                            dangerouslySetInnerHTML={{
                                __html: item.icon
                            }}
                        ></div>}
                    />;
                })
            }
        </div>;
    };
    
    const renderHeaderJobDetailCard = () => {
        return <div
            style={{
                paddingRight: spaces.content,
                paddingLeft: spaces.content,
                paddingTop: spaces.content
            }}
        >
            <div
                className={classes.headerContainer}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {advertData?.occupation.localizedText}
                </Text>
                <div
                    onClick={() => {
                        navigator.clipboard.writeText(shareURL);
                        setIsShareVisible(true);
                    }}
                    className={classes.shareIcon}
                    style={{
                        borderRadius: spaces.containerXXLarge,
                        backgroundColor: colors.white,
                        borderColor: colors.stroke,
                        padding: spaces.inline / 2,
                        borderWidth: borders.line
                    }}
                >
                    <ShareIcon 
                        size={18}
                    />
                </div>
            </div>
            <div
                className={classes.headerContainerIcon}
            >
                <div 
                    style={{
                        marginBottom: spaces.inline * 2,
                        marginRight: spaces.inline * 2,
                        marginTop: spaces.inline * 2,
                    }}
                    className={classes.imageContainer}
                >
                    <img
                        className={classes.image}
                        style={{
                            borderRadius: radiuses.half,
                            borderColor: colors.stroke,
                            borderWidth: borders.line
                        }}
                        src={advertData?.companyInfo?.photoURL ? advertData?.companyInfo.photoURL : emptyProfileImage}
                        alt="Profile"
                    />
                </div>
                <div
                    className={classes.textContent}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                        style={{
                            marginBottom: spaces.content / 2,
                        }}
                    >
                        {advertData?.companyInfo.title}
                    </Text>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {`${advertData?.experienceLevel.localizedText} • ${advertData?.contractType.localizedText} • ${advertData?.city.localizedText} (${advertData?.workingType.localizedText})`}
                    </Text>
                </div>
            </div>
            {advertData?.boosted && <div
                className={classes.sticker}
                style={{
                    gap: spaces.container
                }}
            >
                {renderBoosts()}
            </div>}
        </div>;
    };
    
    const renderJobDetailAbout = () => {
        return <div>
            <div>
                <div
                    style={{
                        marginTop: spaces.containerLarge
                    }}
                >
                    <Text
                        variant="header5-medium"
                        color="textDark"
                    >
                        {localize("about-the-job-posting")}
                    </Text>
                </div>
                <div
                    style={{
                        marginTop: spaces.content,
                        marginBottom: spaces.content 
                    }}>
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {advertData?.description}
                    </Text>
                </div>
            </div>
        </div>;
    };
    
    const renderJobDetailApplicationProcess = () => {
        return <div>
            <div
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("application-process")}
                </Text>
            </div>
            <div
                style={{
                    marginTop: spaces.content,
                    marginBottom: spaces.content 
                }}>
                {/* //TODO: WHAT TO DO WITH THIS PART? */}
                <Text
                    variant="body4-regular"
                    color="textGrey"
                > 
                    {localize("application-process-text")}
                </Text>
            </div>
        </div>;
    };

    const renderApplicationButton = () => {
        return <div
            style={{
                marginTop: spaces.contentXLarge
            }}>
            <Button
                title={advertData?.isApplied ? localize("manage-application") : localize("apply")}
                disabled={advertData?.isApplied}
                color="secondary"
                onClick={() => {
                    navigate("/auth/login");
                }}
                spreadBehaviour="stretch"
            />
        </div>;
    };

    const renderEmptyCompanyInfo = () => {
        return <div
            className={classes.emptyCompanyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.inline
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body3-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };

    const renderEmptyOTheAdvertsInfo = () => {
        return <div
            className={classes.emptyCompanyInfo}
            style={{
                padding: `${spaces.inline}px ${spaces.content}px`,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.inline
            }}
        >
            <Info
                color={colors.primary}
                size={24}
            />
            <Text
                variant="body-regular"
            >
                {localize("empty-company-about")}
            </Text>
        </div>;
    };
    
    const renderSimilarJobPostings = () => {
        return <div
            style={{
                marginTop: spaces.contentXLarge
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("similar-job-postings")}
                </Text>
            </div>
            {advertData?.recommendedAdverts.length ? advertData?.recommendedAdverts.map((item) => {
                let stickers: Array<StickerObjectType> = [];
                item.boosted.forEach((boost) => {
                    stickers.push({
                        localizedText: boost.localizedText,
                        backColor: boost.backColor,
                        color: boost.color,
                        icon: () => <div
                            dangerouslySetInnerHTML={{
                                __html: boost.icon
                            }}
                        ></div>,
                        key: boost.key
                    });
                });

                return <Fragment key={item._id}>
                    <AdvertCard
                        experienceLevel={item.experienceLevel.localizedText}
                        contractType={item.contractType.localizedText}
                        workingType={item.workingType.localizedText}
                        companyName={item.companyInfo.title}
                        title={item.occupation.localizedText}
                        city={item.city.localizedText}
                        stickers={stickers}
                        jobImage={emptyProfileImage}
                        onClick={() => {
                            navigate("/auth/login");
                        }}
                        isNoAuth={true}
                        style={{
                            marginBottom: spaces.content,
                            marginRight: spaces.content,
                            cursor: "pointer",
                            maxWidth: "100%"
                        }}
                    />
                </Fragment>;
            }) : renderEmptyOTheAdvertsInfo()}
        </div>;
    };
    
    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                backgroundColor: colors.layer1,
                padding: spaces.containerLarge,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                gap: spaces.content / 1.2
            }}
        >
            <div className={classes.textStart}>
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("company-about")}
                </Text>
            </div>
            <div 
                className={classes.imageContainer}
            >
                <img
                    src={advertData?.companyInfo?.photoURL ? advertData?.companyInfo.photoURL : emptyProfileImage}
                    className={classes.image}
                    style={{
                        borderRadius: radiuses.hard * 10,
                        borderColor: colors.stroke,
                        borderWidth: borders.line
                    }}
                    alt="Profile"
                />
            </div>
            <div
                className={classes.textCenter}
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {advertData?.companyInfo.title}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.content
                }}>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                >
                    {`${advertData?.companyInfo.city.localizedText} •  ${advertData?.companyInfo.sector.localizedText}`}
                </Text>
            </div>
            {renderLine()}
            <div
                className={classes.textStart}
                style={{
                    marginTop: spaces.content
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("company-ınformation")}
                </Text>
            </div>
            <div
                className={classes.companyInformation}
                style={{
                    marginBottom: spaces.inline / 2, 
                    marginTop: spaces.inline / 2
                }}>
                {advertData?.companyInfo?.about ? 
                    <Text
                        variant="body4-regular"
                        color="textGrey"
                    >
                        {advertData?.companyInfo?.about} 
                    </Text> 
                    : 
                    renderEmptyCompanyInfo()
                }
            </div>
        </div>;
    };
    
    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: spaces.containerLarge,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {<div>
                {renderHeaderJobDetailCard()}
                {renderLine()}
                {renderJobDetailAbout()}
                {renderJobDetailApplicationProcess()} 
                {renderApplicationButton()}
                {renderSimilarJobPostings()}
            </div>}
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/search");
                }}
            />
        </div>;
    };

    const renderPageModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.content
                    }}
                >
                    <StateCard
                        content={localize("ıf-you-return-this-transaction-will-be-canceled")}
                        title={localize("you-are-about-to-cancel-the-transaction")}
                        icon={() =>
                            <LogoutIcon
                                size={68}
                            />
                        }
                        buttonProps={{
                            onClick: () => {setIsVisible(false);},
                            title: localize("post-an-ad"),
                            spreadBehaviour: "stretch",
                            iconDirection: "left",
                            color: "primary",
                            size: "large"
                        }}
                    />
                </div>
            }
            isVisible={isVisible}
        />;
    };

    const renderSuccesShareModal = () => {
        return <PageModal
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            onOverlayPress={() => {
                setIsShareVisible(false);
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.contentLarge
                    }}
                >
                    <div
                        style={{
                            marginBottom: spaces.content
                        }}
                    >
                        <VerifiedCheckIcon
                            color={colors.success}
                            size={68}
                        />
                    </div>
                    <div
                        className={classes.pageModalContainer}
                        style={{
                            marginBottom: spaces.content * 3
                        }}
                    >
                        <Text
                            variant="header5-medium"
                        >
                            {localize("advert-link-copied")}
                        </Text>
                        <Text
                            variant="body-regular"
                            color="textSecondary"
                        >
                            {localize("advert-link-copied-content")}
                        </Text>
                    </div>
                    <Button
                        title={localize("IOCoreDefaultDialogPrimaryButtonTitle")}
                        onClick ={
                            () => {
                                setIsShareVisible(false);
                            }
                        }
                        spreadBehaviour="stretch"
                        color="primary"
                        size="large"
                    />
                </div>
            }
            isVisible={isShareVisible}
        />;
    };

    if(loading) {
        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <div
            className={classes.outerContainer}
            style={{
                gap: spaces.content,
            }}
        >
            <Text
                className={classes.profileText}
                variant='header5-medium'
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                {localize("job-details")}
            </Text>
            <div 
                className={classes.container}
                style={{
                    gap: spaces.content * 3
                }}
            >
                {renderLeftContainer()}
                {renderContentContainer()}
                {renderRightContainer()}
            </div>
        </div>
        {renderPageModal()}
        {renderSuccesShareModal()}
    </div>;
};

export default WorkerJobDetails;
