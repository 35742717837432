import {
    useEffect 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text 
} from "isinolacak-web-cl";
import useStyles from "./style";
import {
    useNavigate 
} from "react-router-dom";
import notFoundVector from "../../../assets/img/404.svg";

const NotFound = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    useEffect(() => {
        let userType = window.localStorage.getItem("userType");
        
        setTimeout(() => {
            if(userType && userType === "employer") {
                navigate("/app/home");
            }
            else{
                navigate("/home");
            }
        }, 7000);
    }, []);

    return <div
        className={classes.container}
        style={{
            padding: spaces.containerXXLarge
        }}
    >
        <img 
            className={classes.image} 
            alt="404 - Not Found" 
            src={notFoundVector}
            style={{
                marginBottom: spaces.contentLarge * 4
            }} 
        />
        <Text 
            variant="header3-medium"
            style={{
                marginBottom: spaces.content
            }}
        >
            {localize("something-went-wrong")}
        </Text>
        <Text 
            variant="body-regular"
            style={{
                marginBottom: spaces.containerLarge * 2
            }}
        >
            {localize("sorry-we-couldnt-reach-the-page-you-were-looking-for-please-try-again")}
        </Text>
        <Button
            title={localize("go-back")}
            spreadBehaviour="free"
            size="large"
            onClick={() => {
                navigate(-2);
            }}
        />
    </div>;
};
export default NotFound;
