import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    datePickerInputContainer: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    removeDiv: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
    dateTimePicker: {
        userSelect: "none",
        width: "100%"
    }
});
export default useStyles;