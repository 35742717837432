import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        borderStyle: "solid",
        width: "100%",
        minWidth: "330px",
        maxWidth: "24%",
        display: "flex",
        "@media screen and (max-width: 1760px)": {
            maxWidth: "32% !important"
        },
        "@media screen and (max-width: 1430px)": {
            maxWidth: "48% !important"
        },
        "@media screen and (max-width: 908px)": {
            maxWidth: "100% !important"
        }
    },
    informationTextStyle: {
        width: "100%"
    },
    renderInformationsStyle: {
        flexDirection: "row",
        alignItems: "center",
        display:"flex",
        flexGrow: 1
    },
    imageStyle: {
        objectFit: "cover",
        borderStyle: "solid",
        borderWidth: 1,
        height: 55,
        width: 55
    },
    renderTagsStyle: {
        boxSizing: "border-box",
        borderTopStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    renderTags: {
        flexWrap: "wrap",
        display: "flex",
    },
    renderTimeSticker: {
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
        maxHeight: 36
    },
    tagsContainer: {
        alignItems: "center",
        flexWrap: "wrap", 
        display: "flex"
    }
});

