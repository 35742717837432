import {
    useEffect, 
    Fragment,
    useState,
    useRef
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    Loading,
    Button,
    Text,
    Chip,
} from "isinolacak-web-cl";
import {
    FilledSquareIcon,
    MagnifierIcon
} from "../../../assets/svgr";
import {
    useNavigate 
} from "react-router-dom";
import {
    RESTService 
} from "../../../services/restAPI";
import {
    GetFeaturedAdvertsRequestType 
} from "../../../services/restAPI/actions/getFeaturedAdverts/types";
import {
    getAdvertsWithoutAuthObjectTypes,
    getPopularAdvertsObjectTypes,
    GetLandingDataRequestType,
    getLandingBlogObjectTypes,
    LocalizedObjectTypes
} from "../../../services/restAPI/actions/getLandingData/types";
import {
    CitiesResponseObjectType 
} from "../../../services/restAPI/actions/getCities/types";
import {
    StickerObjectType 
} from "./components/featuredAdvertCard/types";
import {
    SelectedCitiesType 
} from "../search/types";
import {
    Footer
} from "../../../components";
import FeaturedAdvertCard from "./components/featuredAdvertCard";
import ImageWithTextCard from "./components/imageWithTextCard";
import PopularAdvertCard from "./components/popularAdvertCard";
import CareuselTrackData from "./components/careuselTrack";
import EmptyContainer from "./components/emptyContainer";
import IsinOlacakMiniImage from "../../../assets/img/isinolacakMini.png";
import IsinOlacakProfile from "../../../assets/img/isinolacakMiniWithPadding.png";
import BlogCoverPhoto from "../../../assets/img/blogCoverPhoto.png";
import DiscoverImage from "../../../assets/img/gradientImage.png";
import HeaderImage from "../../../assets/img/headerImage.png";
import PhoneMac from "../../../assets/img/phoneMac.png";
import {
    getBrowserLanguageFull 
} from "../../../utils";
import {
    allFilters 
} from "./constants";
import {
    useGlobalState 
} from "../../../context/globalState";

const Home = () => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize,
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [globalState, setGlobalState] = useGlobalState();

    const [popularOccupationsData, setPopularOccupationsData] = useState<Array<LocalizedObjectTypes>>([]);
    const [popularAdvertData, setPopularAdvertData] = useState<Array<getPopularAdvertsObjectTypes>>([]);
    const [advertData, setAdvertData] = useState<Array<getAdvertsWithoutAuthObjectTypes>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);
    const [isFeaturedAdvertsLoading, setIsFeaturedAdvertsLoading] = useState(false);
    const [blogData, setBlogData] = useState<Array<getLandingBlogObjectTypes>>([]);
    const [filter, setFilter] = useState<typeof allFilters[number]>("todays-ad");
    const [searchPosition, setSearchPosition] = useState<string | undefined>();
    const [isSmallest, setIsSmallest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const firstRender = useRef(true);
    
    useEffect(() => {
        if(window.innerWidth < 1100) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        if(window.innerWidth < 650) {
            setIsSmallest(true);
        } else {
            setIsSmallest(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    
    useEffect(() => {
        getLandingData();
    },[]);

    useEffect(() => {
        if(!firstRender.current) {
            getFeaturedAdverts();
        }
        firstRender.current = false;
    }, [filter]);

    const getFeaturedAdverts = () => {
        let params: GetFeaturedAdvertsRequestType = {
            language: getBrowserLanguageFull(),
            filter: "today"
        };

        switch(filter) {
        case "todays-ad":
            params.filter = "today";
            break;
        case "remote-work-ads":
            params.filter = "remote";
            break;
        case "part-time-work-ads":
            params.filter = "part-time";
            break;
        case "intern-work-ads":
            params.filter = "intern";
            break;
        default: 
            params.filter = "today";
        }

        if(!isFeaturedAdvertsLoading) {
            setIsFeaturedAdvertsLoading(true);
        } 
        
        RESTService.action("GetFeaturedAdverts", params)
            .then((res) => {
                setAdvertData(res);
            })
            .catch((err) => {
                console.error("GetFeaturedAdverts Err:", err);
            }).finally(() => {
                setIsFeaturedAdvertsLoading(false);
            });  
    };

    const getLandingData = () => {
        let params: GetLandingDataRequestType = {
            language: getBrowserLanguageFull(),
        };
        
        if(!isLoading || !isFeaturedAdvertsLoading) {
            setIsFeaturedAdvertsLoading(true);
            setIsLoading(true);
        }
        
        RESTService.action("GetLandingData", params)
            .then((res) => {
                const {
                    popularOccupations,
                    featuredAdverts, 
                    popularAdverts, 
                    landingBlogs
                } = res;

                if(popularOccupationsData.length > 0 && 
                   popularAdvertData.length > 0 && 
                   blogData.length > 0) 
                {
                    setAdvertData(featuredAdverts);
                } else {
                    setPopularOccupationsData(popularOccupations);
                    setPopularAdvertData(popularAdverts);
                    setAdvertData(featuredAdverts);
                    setBlogData(landingBlogs);
                }
            })
            .catch((err) => {
                console.error("GetAdvertsWithoutAuth Err:", err);
            }).finally(() => {
                setIsFeaturedAdvertsLoading(false);
                setIsLoading(false);
            });  
    };

    const onResize = () => {
        if(window.innerWidth < 1100) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }

        if(window.innerWidth < 650) {
            setIsSmallest(true);
        } else {
            setIsSmallest(false);
        }
    };

    const renderHeader = () => {
        return <div 
            className={classes.headerContainer} 
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingBottom: spaces.containerXXLarge,
                paddingTop: spaces.containerXXLarge,
                gap: spaces.containerXLarge
            }}>
            <div 
                className={classes.headerLeftContainer}
                style={{
                    gap: spaces.containerLarge
                }}
            >
                <div style={{
                    paddingRight: spaces.container
                }}>
                    <Text 
                        variant={!isSmall ? "header1-medium" : "header3-medium"}
                        className={classes.lineHeightStyle} 
                    >
                        {localize("hundreds-of-employers-thousands-of-new-job-listings-every-day-platform")}
                    </Text>
                </div>
                <div>
                    <div 
                        className={classes.headerTextInputContainer}
                        style={{
                            gap: spaces.content
                        }}>
                        <TextInput
                            placeholder={localize("search-occupation")}
                            icon={() => <div 
                                style={{
                                    paddingRight: spaces.item * 0.5,
                                    paddingBottom: spaces.inline / 3
                                }}>
                                <MagnifierIcon 
                                    size={!isSmall ? 15 : 12} 
                                    color={colors.primary}
                                />
                            </div>}
                            onChangeText={(e) => {
                                setSearchPosition(e);
                            }}
                        />
                        {!isSmall && <SelectBox
                            style={
                                {
                                    paddingBottom: spaces.inline,
                                    paddingTop: spaces.inline
                                }
                            }
                            titleExtractor={(e) => e.localizedText}
                            inputTitle={localize("location")}
                            keyExtractor={(e) => e._id}
                            title={localize("location")}
                            spreadBehaviour='stretch'
                            isNeedConfirm={true}
                            isSearchable={true}
                            emptyContent={() => {
                                return <Text>
                                    {localize("no-result-found")}
                                </Text>;
                            }}
                            multiSelect={true}
                            disabled={false}
                            onOk={({
                                selectedItems,
                                closeSheet,
                                onSuccess
                            }) => {
                                let _cities: Array<SelectedCitiesType> = selectedItems.map((item) => {
                                    const city = item as unknown as CitiesResponseObjectType;
                                    return {
                                        localizedText: city.localizedText,
                                        cityID: city._id,
                                    };
                                });
                                setSelectedCities(_cities);
                                closeSheet();
                                onSuccess();
                            }}
                            data={citiesData}
                        />}
                        <Button
                            className={classes.headerTextInputButton}
                            spreadBehaviour="free"
                            color="primary"
                            title="Ara"
                            onClick={() => {
                                const userType = globalState?.user?.type;
                                if(userType === "worker") {
                                    if(searchPosition) {
                                        navigate(`/search?s=${searchPosition}`);
                                    }
                                    if(selectedCities && selectedCities.length) {
                                        navigate(`/search?l=${JSON.stringify(selectedCities)}`);
                                    }
                                    if(selectedCities && selectedCities.length && searchPosition) {
                                        navigate(`/search/s=${searchPosition}&l=${JSON.stringify(selectedCities)}`);
                                    }
                                } else if(userType === "employer") {
                                    navigate("/app/findCandidate");
                                } else {
                                    if(searchPosition) {
                                        navigate("/search", {
                                            state: {
                                                s: searchPosition
                                            }
                                        });
                                    }
                                    if(selectedCities && selectedCities.length) {
                                        navigate("/search", {
                                            state: {
                                                l: JSON.stringify(selectedCities)
                                            }
                                        });
                                    }
                                    if(selectedCities && selectedCities.length && searchPosition) {
                                        navigate("/search", {
                                            state: {
                                                l: JSON.stringify(selectedCities),
                                                s: searchPosition
                                            }
                                        });
                                    }
                                }
                            }}
                            style={{
                                paddingRight: spaces.inline
                            }}
                        />
                    
                    </div>
                    <div 
                        className={classes.headerRegisterContainer}
                        style={{
                            marginTop: spaces.content / 2
                        }}>
                        {!globalState?.user && <Text 
                            variant="body3-medium" 
                            color="greyBase"
                        >
                            Üye değil misiniz? Hemen {" "}
                            <span onClick={() => navigate("/auth/register")}>
                                <Text 
                                    className={classes.linkElement}
                                    variant="body3-bold" color="greyBase" 
                                > 
                                    {localize("sign-up")}
                                </Text>
                            </span>
                        </Text>}
                    </div>
                </div>
              
                <div 
                    className={classes.checkBoxContainer}
                    style={{
                        gap: spaces.content * 2
                    }}
                >
                    <div 
                        className={classes.checkBoxElement}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <FilledSquareIcon 
                            color={colors.primary}
                            size={20} 
                        />
                        <Text 
                            variant={!isSmall ? "header5-regular" : "body-regular"}
                        >
                            {localize("hundreds-of-position-details")}
                        </Text>
                    </div>
                    <div 
                        className={classes.checkBoxElement}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <FilledSquareIcon 
                            color={colors.primary}
                            size={20} 
                        />
                        <Text 
                            variant={!isSmall ? "header5-regular" : "body-regular"}
                        >
                            {localize("daily-updated-listings")}
                        </Text>
                    </div>
                </div>
            </div>
            <div 
                className={classes.headerRightContainer}
            >
                <img 
                    src={HeaderImage}
                />
            </div>
        </div>;
    };

    const renderAlternativeDiscover = () => {
        return <div 
            className={classes.discoverMainContainer}
            style={{
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2
            }}>
            <div 
                className={classes.alternativeDiscoverContainer}
                style={{
                    marginBottom: spaces.containerXXLarge * 1.2,
                    marginTop: spaces.containerXXLarge * 1.2,
                    padding: spaces.container * 1.2,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                <div>
                    <img 
                        className={classes.alternativeDiscoverImage}
                        src={PhoneMac} 
                    />
                </div>
                <div
                    className={classes.alternativeDiscoverTextContainer}
                    style={{
                        gap: spaces.content
                    }}>
                    <Text 
                        variant="header5-medium"
                    >
                        {localize("thousands-of-free-listings")}
                    </Text>
                    <Text 
                        variant="body2-regular"
                    >
                        {localize("easy-access-from-both-web-and-mobile")}
                    </Text>
                </div>
            </div>
        </div>;
    };

    const renderDiscover = () => {
        return <div
            className={classes.discoverMainContainer}
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2
                
            }}>
            <div 
                className={classes.discoverContainer} 
                style={{
                    marginBottom: spaces.containerXXLarge * 1.2,
                    borderRadius: radiuses.half,
                    gap: spaces.content
                }}>
                <img 
                    src={DiscoverImage} 
                    style={{
                        borderRadius: radiuses.half
                    }}
                />
                <div 
                    className={classes.discoverRightContainer} 
                    style={{
                        padding: spaces.content,
                        gap: spaces.content
                    }}
                >
                    <div 
                        className={classes.discoverRightTextContainer}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <img 
                            className={classes.discoverLogoImageStyle}
                            src={IsinOlacakMiniImage} 
                            style={{
                                padding: spaces.content / 1.5,
                                backgroundColor: colors.white,
                                borderRadius: radiuses.half
                            }}/>
                        <Text 
                            variant="header4-medium"
                        >
                            {localize("which-work-model-suits-you-better")}
                        </Text>
                        <Text 
                            variant="body2-regular"
                        >
                            {localize("remote-work-model-or-shift-work-part-time-or-full-time-discover-the-work-model-that-suits-you-with-isinolacak-privilege")}
                        </Text>
                    </div>
                    <Button
                        className={classes.headerTextInputButton}
                        title={localize("discover-now")}
                        spreadBehaviour="free"
                        textColor="black"
                        color="white"
                        onClick={() => {
                            navigate("/search");
                        }}
                    />
                </div>
            </div>
        </div>;
    };

    const renderPopularAdverts = () => {
        return <div
            className={classes.popularAdvertsContainer}
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                marginBottom: spaces.containerXXLarge * 1.2,
                gap: spaces.containerLarge
            }}>
            <Text 
                className={classes.popularAdvertsTitle}
                variant="header2-medium" 
            >
                {localize("popular-listings")}   
            </Text>
            {(!isLoading && popularAdvertData.length === 0) ? <EmptyContainer variant="popularAdverts"/> : <div
                className={classes.popularAdvertsSubContainer}
                style={{
                    gap: spaces.item
                }}>
                {isLoading && !isSmallest ? <Loading/> : !isSmallest && popularAdvertData && popularAdvertData?.map((elm, i) => {
                    return <Fragment key={elm._id}>
                        <PopularAdvertCard 
                            city={`${elm.city.localizedText} (${elm.workingType.localizedText})`}
                            jobImage={elm.companyInfo.companyPhotoURL ?? IsinOlacakProfile}
                            experienceLevel={elm.experienceLevel.localizedText} 
                            contractType={elm.contractType.localizedText}
                            occupation={elm.occupation.localizedText}
                            description={elm.description}
                            id={elm._id}
                        />
                    </Fragment>; 
                })}
                {isLoading && isSmallest ? <Loading/> : isSmallest && popularAdvertData && (
                    <CareuselTrackData
                        data={popularAdvertData.map(elm => {
                            return {
                                Component: PopularAdvertCard,
                                title: elm._id,
                                additionalProps: {
                                    city: `${elm.city.localizedText} (${elm.workingType.localizedText})`,
                                    jobImage: elm.companyInfo.companyPhotoURL ?? IsinOlacakProfile,
                                    experienceLevel: elm.experienceLevel.localizedText, 
                                    contractType: elm.contractType.localizedText,
                                    occupation: elm.occupation.localizedText,
                                    description: elm.description,
                                    id: elm._id
                                }
                            };
                        })}
                    />
                )}
            </div>}
        </div>;
    };

    const renderHeaderChipContainer = () => {
        return <div
            className={classes.chipContainer}
            style={{
                paddingRight: spaces.item * 1.5,
                paddingBottom: spaces.item,
                paddingTop: spaces.item,
                gap: spaces.content
            }}
        >
            {allFilters.map(curFilter => {
                return (
                    <Fragment key={curFilter}>
                        <Chip
                            selected={filter === curFilter}
                            title={localize(curFilter)}
                            size="large"
                            disabled={isLoading}
                            onClick={() => {
                                if(filter === curFilter) {
                                    return;
                                }
                                setFilter(curFilter);
                            }}
                        />
                    </Fragment>
                );
            })}
            <div 
                className={classes.allListingsText}
                onClick={() => {
                    navigate("/search");
                }}
            >
                <Text
                    className={classes.link} 
                    variant="body-medium"
                    color="primary"
                >
                    {localize("all-listings")}
                </Text>
            </div>
        </div>;
    };

    const renderCareerGuide = () => {
        return <div 
            className={classes.carrerGuideContainer} 
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                marginBottom: spaces.containerXXLarge * 1.2
            }}>
            <div 
                style={{
                    marginBottom: spaces.containerLarge,
                }}
            >
                <Text
                    variant="header2-medium"
                >
                    {localize("career-guide")}
                </Text>
            </div>
            <div className={classes.carrerGuideCards}>
                {isLoading && isSmallest ? <Loading/> : !isSmallest && blogData && 
                blogData.map(({
                    coverPhotoURL,              
                    title,
                    _id, 
                }) => (
                    <Fragment key={_id}>
                        <ImageWithTextCard
                            image={coverPhotoURL ?? BlogCoverPhoto}
                            text={title}
                            id={_id}
                        />
                    </Fragment>
                ))}
                {isLoading && !isSmallest  ? <Loading/> : isSmallest && blogData && <CareuselTrackData data={blogData.map(elm => {
                    return {
                        Component: ImageWithTextCard,
                        title: elm._id,
                        additionalProps: {
                            image: elm.coverPhotoURL ?? BlogCoverPhoto,
                            text: elm.title,
                            id: elm._id
                        }
                    };
                })}/>}
            </div>
        </div>;
    };

    const renderFeaturedAdverts = () => {
        return <div
            className={classes.featuredAdvertsContainer}
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                marginBottom: spaces.containerXXLarge * 1.2,
                gap: spaces.container
            }}
        >
            <Text
                className={classes.popularAdvertsTitle}
                variant="header2-medium"
            >
                {localize("featured-listings")}
            </Text>
            {renderHeaderChipContainer()}
            {(!isFeaturedAdvertsLoading && advertData.length === 0) ? <EmptyContainer variant="featuredAdverts"/> :
                <div
                    className={classes.featuredAdvertsSubContainer}
                    style={{
                        gap: spaces.item
                    }}>
                    {isFeaturedAdvertsLoading ? <Loading/> : advertData.length > 0 && advertData.map(elm => {
                        let stickers: Array<StickerObjectType> = [];
                        elm.boosts.forEach((boost) => {
                            stickers.push({
                                localizedText: boost.localizedText,
                                backColor: boost.backColor,
                                color: boost.color,
                                icon: () => <div
                                    dangerouslySetInnerHTML={{
                                        __html: boost.icon
                                    }}
                                ></div>,
                                key: boost.key
                            });
                        });
                        return (
                            <Fragment key={elm._id}>
                                <FeaturedAdvertCard
                                    title={elm.occupation.localizedText}
                                    jobImage={elm.companyInfo.companyPhotoURL}
                                    companyName={elm.companyInfo.title}
                                    city={elm.city.localizedText}
                                    stickers={stickers}
                                    id={elm._id}
                                    onClick={() => {
                                        if(globalState?.user?.type === "worker") {
                                            navigate("/app/jobDetails", {
                                                state: {
                                                    jobID: elm._id
                                                }
                                            });
                                        } else if(globalState?.user?.type === "employer") {
                                            navigate("/app/home");
                                        } else {
                                            navigate("/jobDetails", {
                                                state: {
                                                    jobID: elm._id
                                                }
                                            });
                                        }
                                    }}
                                />
                            </Fragment>
                        );
                    })}
                </div>
            }
        </div>;
    };

    const renderPopularPositions = () => {
        return <div 
            className={classes.popularPositionsContainer}
            style={{
                paddingRight: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                paddingLeft: !isSmall ? spaces.containerXLarge : spaces.container * 2,
                marginBottom: spaces.containerXXLarge * 1.2,
                gap: spaces.container * 1.5
            }}
        >
            <Text
                className={classes.popularPositionsTitle}
                variant="header2-medium" 
            >
                {localize("popular-positions")}
            </Text>
            <hr 
                style={{
                    color: colors.greyBase
                }}
            />
            <div 
                className={classes.popularPositionsGrid}
                style={{
                    columnGap: spaces.containerXLarge * 1.25
                }}
            >
                {isLoading ? <Loading/> : popularOccupationsData && popularOccupationsData?.map((position, index) => (
                    <div 
                        className={classes.popularPositionsItem}
                        key={position._id}
                        style={{
                            padding: spaces.content
                        }}
                        onClick={() => {
                            if(globalState?.user?.type === "worker") {
                                navigate(`/app/search?s=${position.localizedText}`);
                            } else if(globalState?.user?.type === "employer") {
                                navigate(`/app/findCandidate?s=${position.localizedText}`);
                            } else {
                                navigate("/search", {
                                    state: {
                                        s: position.localizedText
                                    }
                                });
                            }
                        }}
                    >
                        <Text
                            variant="body-medium"
                            color="greyBase"
                        >
                            {position.localizedText}
                        </Text>
                    </div>
                ))}
            </div>
        </div>;
    };

    return <div className={classes.topContainer}>
        <div 
            className={classes.mainContainer}>
            {renderHeader()}
            {isSmall ? renderAlternativeDiscover() : renderDiscover()}
            {renderPopularAdverts()}
            {renderFeaturedAdverts()}
            {renderCareerGuide()}
            {renderPopularPositions()}
        </div>
        <Footer setCities={setCitiesData}/>
    </div>;
};
export default Home;