import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    IPopularAdvertCardProps,
} from "./types";
import {
    useNavigate 
} from "react-router-dom";
import {
    useGlobalState 
} from "../../../../../context/globalState";

const PopularAdvertCard = ({
    experienceLevel,
    contractType,
    description,
    occupation,
    jobImage,
    onPress,
    style,
    city,
    id
}: IPopularAdvertCardProps) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [globalState, setGlobalState] = useGlobalState();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {occupation}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {`${experienceLevel} • ${contractType} • ${city}`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            onClick={onPress}
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
        >
            <div >
                <img
                    src={jobImage}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderWidth: borders.indicator / 2,
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container,
                        borderColor: colors.stroke
                    }}
                />
            </div>
            {informationsContainer()}
        </div>;
    };

    const renderExtraInformation = () => {
        return<div
            style={{
                paddingRight: spaces.container * 0.75,
                paddingLeft: spaces.container * 0.75,
                paddingBottom: spaces.container,
                borderBottomColor: colors.stroke,
                borderBottomWidth: borders.line,
            }}
            className={
                classes.renderExtraInformationsStyle
            }
        >
            <div>
                <Text
                    variant="body3-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {localize("about-job-advert")}
                </Text>
            </div>
            <div className={classes.descriptionContainer}>
                <Text
                    className={classes.informationTextStyle}
                    variant="body4-regular"
                    color="textGrey"
                >
                    {description}
                </Text>
            </div>
        </div>;
    };

    const renderTags = () => {
        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content,
                paddingRight: spaces.content,
                paddingTop: spaces.content
            }}
        >
            <div 
                className={classes.buttonContainer}
                style={{
                    paddingBottom: spaces.containerXXLarge * 0.2
                }} 
            >
                <Button
                    onClick={() => {
                        if(globalState?.user?.type === "worker") {
                            navigate("/app/jobDetails", {
                                state: {
                                    jobID: id
                                }
                            });
                        } else if(globalState?.user?.type === "employer") {
                            navigate("/app/home");
                        } else {
                            navigate("/jobDetails", {
                                state: {
                                    jobID: id
                                }
                            });
                        }
                    }}
                    spreadBehaviour="stretch"
                    title={localize("apply")}
                    color="primary"
                />
            </div>
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            marginBottom: spaces.containerXXLarge * 0.2,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderInformations()}
        {renderExtraInformation()}
        {renderTags()}
    </div>;
};

export default PopularAdvertCard;