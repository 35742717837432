import useStyles from "./styles";
import {
    useEffect,
    useState 
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Text,
    Dialog
} from "isinolacak-web-cl";
import {
    PaymentPageProps 
} from "./types";
import {
    GetPackagesRequestType, GetPackagesResponseType 
} from "../../../../../../../../../../services/restAPI/actions/getPackages/types";
import {
    GetBanksResponseType 
} from "../../../../../../../../../../services/restAPI/actions/getBanks/types";
import {
    RESTService 
} from "../../../../../../../../../../services/restAPI";
import PackageCardForPaymentPage from "../../../../../../../../../../components/packageCardForPaymentPage";
import {
    CircleIcon,
    InfoCircleIcon 
} from "../../../../../../../../../../assets/svgr";
import Banknote from "../../../../../../../../../../assets/svgr/banknoteIcon";
import OutlineCreditCard from "../../../../../../../../../../assets/svgr/outlineCreditCardIcon";
import BankInfoCard from "../../../../../../../../../../components/bankInfoCard";
import {
    MakePaymentStatementRequestType 
} from "../../../../../../../../../../services/restAPI/actions/makePaymentStatement/types";
import {
    getErrorText 
} from "../../../../../../../../../../utils";

const Payment = ({
    setHeaderTitle,
    currentPackage,
    setLastOrder,
    isPremium,
    setViews
}: PaymentPageProps) =>  {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    
    const [getPackagesParam, setGetPackagesParam] = useState<GetPackagesRequestType>({
    });
    const [successPaymentModalOpen, setSuccessPaymentModalOpen] = useState(false);
    const [packageData, setPackageData] = useState<GetPackagesResponseType>();
    const [bankData, setBankData] = useState<GetBanksResponseType>();
    const [isLoadingPackage, setIsLoadingPackage] = useState(true);
    const [selectedPackage, setSelectedPackage] = useState("");
    const [isLoadingBank, setIsLoadingBank] = useState(true);
    const [selectedBank, setSelectedBank] = useState("");

    const _company = window.localStorage.getItem("company");
    let company: any;

    if(_company) {
        company = JSON.parse(_company);
    }

    useEffect(() => {
        handlePackageData();
        handleBankData();
    }, []);

    useEffect(() => {
        setHeaderTitle("buy-subscription");
    }, []);

    const handlePackageData = () => {
        RESTService.action("GetPackages", getPackagesParam)
            .then((res) => {
                setPackageData(res);
                if(isPremium && currentPackage?.length) {
                    setSelectedPackage(currentPackage);
                } else {
                    setSelectedPackage(res[0]._id);
                }
                setIsLoadingPackage(false);
            })
            .catch((err) => {
                console.error("GetPackages Err:", err);
            });
    };

    const handleBankData = () => {
        RESTService.action("GetBanks", {
        })
            .then((res) => {
                setBankData(res);
                setSelectedBank(res[0]._id);
                setIsLoadingBank(false);
            })
            .catch((err) => {
                console.error("GetBanks Err:", err);
            });
    };

    const handlePaymentStatement = () => {
        RESTService.action("MakePaymentStatement", {
            packageID: selectedPackage,
            bankID: selectedBank
        })
            .then((res) => {
                setSuccessPaymentModalOpen(true);
                setLastOrder(res);
            })
            .catch((err) => {
                console.error("MakePaymentStatement Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                marginBottom: spaces.contentLarge,
                gap: spaces.inline / 2
            }}
        >
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {company.title}
            </Text>
            <Text
                variant='body3-regular'
                color='textSecondary'
            >
                {isPremium ? localize("premium-membership") : localize("free-use")}
            </Text>
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderPackages = () => {
        if(!packageData) {
            return null;
        }

        return <div
            style={{
                marginTop: spaces.contentLarge
            }}
        >
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("packages")}
            </Text>
            <div
                className={classes.packagesContainer}
                style={{
                    marginTop: spaces.content
                }}
            >
                {
                    packageData.map((item) => {
                        return <PackageCardForPaymentPage
                            style={{
                                marginBottom: spaces.content
                            }}
                            title={item.subType ? `${localize(item.subType)} ${item.title}` : item.title}
                            isSelected={item._id === selectedPackage}
                            onChange={() => {
                                setSelectedPackage(item._id);
                                setSelectedPackage(item._id);
                            }}
                            payment={item.price}
                            type={item.subType}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderPaymentMethods = () => {
        return <div
            style={{
                marginTop: spaces.contentLarge
            }}
        >
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("choose-payment-method")}
            </Text>
            <div
                className={classes.paymentMethodsContainer}
                style={{
                    marginTop: spaces.content,
                    gap: spaces.content
                }}
            >
                <div>
                    <Button
                        icon={() => <OutlineCreditCard size={20} />}
                        style={{
                            marginBottom: spaces.inline / 2,
                            marginRight: spaces.inline
                        }}
                        title={localize("pay-via-card")}
                        onClick={() => {}}
                        size="large"
                        disabled
                    />
                    <div
                        className={classes.paymentInfo}
                        style={{
                            gap: spaces.inline / 4
                        }}
                    >
                        <InfoCircleIcon size={10} />
                        <Text
                            variant='caption-regular'
                            color='textSecondary'
                        >
                            {localize("payment-method-via-card-info")}
                        </Text>
                    </div>
                </div>
                <div>
                    <Button
                        icon={() => <Banknote size={20} />}
                        style={{
                            marginBottom: spaces.inline / 2
                        }}
                        title={localize("pay-via-iban")}
                        onClick={() => {}}
                        size="large"
                    />
                    <div
                        className={classes.paymentInfo}
                        style={{
                            gap: spaces.inline / 4
                        }}
                    >
                        <InfoCircleIcon size={10} />
                        <Text
                            variant='caption-regular'
                            color='textSecondary'
                        >
                            {localize("payment-method-via-bank-info")}
                        </Text>
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderBanks = () => {
        if(!bankData) {
            return null;
        }

        return <div
            style={{
                marginTop: spaces.contentLarge
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant='header5-medium'
                    color='textDark'
                >
                    {localize("choose-bank")}
                </Text>
            </div>
            {
                bankData.map((item) => {
                    return <BankInfoCard
                        isSelected={selectedBank === item._id}
                        onChange={() => {
                            setSelectedBank(item._id);
                        }}
                        iban={item.iban}
                        icon={item.icon}
                        title={item.title}
                    />;
                })
            }
        </div>;
    };

    const renderButton = () => {
        return <Button
            title={localize("send-payment-statement")}
            onClick={() => {
                handlePaymentStatement();
            }}
            spreadBehaviour="stretch"
            style={{
                marginTop: spaces.contentLarge
            }}
        />;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={successPaymentModalOpen}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.primary}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("your-payment-statement-received")}
                    </Text>
                    
                    <Text
                        variant="body-regular"
                    >
                        {localize("your-payment-statement-received-info")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {
                        setSuccessPaymentModalOpen(false);
                        setViews("purchaseHistory");
                    }}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };
    
    return <div>
        {
            isLoadingPackage || isLoadingBank ?
                <Loading /> :
                <div>
                    {renderHeader()}
                    {renderLine()}
                    {renderPackages()}
                    {renderPaymentMethods()}
                    {renderBanks()}
                    {renderLine()}
                    {renderButton()}
                    {successPaymentModalOpen && renderSuccessDialog()}
                </div>
        }
    </div>;
};

export default Payment;
