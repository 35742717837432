import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    topContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display:"flex",
        width:"100%",
        height: 400
    },
    topContainerText: {
        textAlign: "center",
        marginTop: "auto",
    },
    logoStyle: {
        boxSizing: "border-box",
        marginTop: "auto",
        height: "auto",
        width: 100
    },
    loadingContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    }
});