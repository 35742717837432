import React from "react";
import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Document = ({
    color = "#000",
    size = 18,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 18 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M8.208 1.688h1.584c1.379 0 2.47 0 3.325.114.88.119 1.59.368 2.152.929.561.561.81 1.273.929 2.152.114.855.114 1.946.114 3.325v3.084c0 1.379 0 2.47-.114 3.325-.119.88-.368 1.59-.929 2.152-.561.561-1.273.81-2.152.929-.855.114-1.946.114-3.325.114H8.208c-1.379 0-2.47 0-3.325-.114-.88-.119-1.59-.368-2.152-.929-.561-.561-.81-1.273-.929-2.152-.115-.855-.115-1.946-.115-3.325V8.208c0-1.379 0-2.47.115-3.325.119-.88.368-1.59.929-2.152.561-.561 1.273-.81 2.152-.929.855-.115 1.946-.115 3.325-.115Zm-3.175 1.23c-.754.1-1.19.291-1.507.608-.317.318-.507.753-.609 1.507-.103.77-.104 1.787-.104 3.217v3c0 1.43 0 2.446.104 3.217.102.754.292 1.19.61 1.507.317.317.752.507 1.506.609.77.103 1.787.105 3.217.105h1.5c1.43 0 2.446-.002 3.217-.105.754-.102 1.19-.292 1.507-.61.317-.317.507-.752.609-1.506.103-.77.104-1.787.104-3.217v-3c0-1.43 0-2.446-.104-3.217-.102-.754-.292-1.19-.61-1.507-.317-.317-.752-.507-1.506-.609-.77-.103-1.787-.104-3.217-.104h-1.5c-1.43 0-2.446 0-3.217.104Zm.404 5.332c0-.31.252-.563.563-.563h6a.562.562 0 1 1 0 1.125H6a.563.563 0 0 1-.563-.562Zm0 3c0-.31.252-.563.563-.563h3.75a.562.562 0 1 1 0 1.126H6a.562.562 0 0 1-.563-.563Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Document;
