import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const MyActions = ({
    color = "#9AA6B8",
    size = 75,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 75 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M60.168 23.485 47.962 12.499c-3.478-3.13-5.217-4.695-7.35-5.513l-.028 8.431c0 7.267 0 10.901 2.257 13.159 2.258 2.258 5.892 2.258 13.16 2.258h11.038c-1.118-2.172-3.12-3.973-6.87-7.35Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M43.667 67.833H31.334c-11.628 0-17.442 0-21.055-3.612-3.612-3.612-3.612-9.426-3.612-21.054V30.833c0-11.628 0-17.442 3.612-21.054 3.613-3.612 9.457-3.612 21.146-3.612 1.869 0 3.366 0 4.626.051a4.627 4.627 0 0 0-.064.752l-.028 8.74c0 3.382 0 6.372.323 8.781.351 2.612 1.158 5.224 3.289 7.355 2.131 2.132 4.743 2.938 7.355 3.29 2.41.323 5.4.323 8.782.323h12.494c.132 1.647.132 3.669.132 6.36v1.348c0 11.628 0 17.442-3.613 21.054-3.612 3.612-9.426 3.612-21.054 3.612ZM32.87 44.522c.955.848 1.04 2.31.192 3.264l-8.222 9.25a2.312 2.312 0 0 1-3.457 0l-4.111-4.625a2.312 2.312 0 1 1 3.457-3.072l2.382 2.68 6.494-7.305a2.313 2.313 0 0 1 3.265-.192Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default MyActions;