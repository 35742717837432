import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        display: "flex",
        width: "100%",
        height: "9%",
        "@media screen and (min-height: 1440px)": {
            height: "5%",
        }
    },
    leftContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        width: "min-content",
        cursor: "pointer",
        display: "flex",
    },
    hamburgerIcon: {
        display: "none",
        "@media screen and (max-width: 600px)": {
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
        }
    },
    logoContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    middleContainer: {
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 600px)": {
            display: "none"
        }
    },
    rightContainer: {
    },
    buttonContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    responsiveContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    profilePhotoContainer: {
        justifyContent: "center",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        overflow: "hidden",
        display: "flex",
        minHeight: 50,
        minWidth: 50,
        height: 50,
        width: 50,
    },
    userInfo: {
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    userInfoContent: {
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    userInfoContentUserInfo: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
    },
    line: {
        width: "100%",
        height: 1
    },
    profilePhotoField:{
        objectFit: "cover",
        height: "50px",
        width: "50px"
    }
});
export default useStyles;
