import {
    IOCoreLocale,
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import useStyles from "./style";
import {
    NotificationCardProps 
} from "./type";

const NotificationCard = ({
    profilePhotoURL,
    isUnreaded,
    createdAt,
    content,
    title,
}: NotificationCardProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const calculateTimeDifference = (time: string) => {
        const currentDate = new Date();
        const pastDate = new Date(time);
        
        const timeDifference = currentDate.getTime() - pastDate.getTime();
        const secondsDifference = Math.round(timeDifference / 1000);
      
        if (secondsDifference < 60) {
            return localize("seconds-ago", [secondsDifference]);
        } else if (secondsDifference < 60 * 60) {
            const minutes = Math.floor(secondsDifference / 60);
            return localize("minute-ago", [minutes]);
        } else if (secondsDifference < 60 * 60 * 24) {
            const hours = Math.floor(secondsDifference / (60 * 60));
            return localize("hour-ago", [hours]);
        } else {
            const days = Math.floor(secondsDifference / (60 * 60 * 24));
            return localize("day-ago", [days]);
        }
    };

    const renderBadge = () => {
        if(!isUnreaded){
            return null;
        }
        return <div
            className={classes.badge}
            style={{
                backgroundColor: colors.primary
            }}
        >
        </div>;
    };

    const renderProfilePhoto = () => {
        return <div
            className={classes.profilePhotoContainer}
            style={{
                borderRadius: spaces.item * 5,
                padding: spaces.container / 2,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                marginRight: spaces.content / 2
            }}
        >
            <img
                src={profilePhotoURL}
                className={classes.profilePhotoContent}
            />
        </div>;
    };

    const renderContent = () => {
        return <div
            className={classes.contentContainer}
        >
            <Text variant="body2-semiBold">{title}</Text>
            <Text variant="body3-medium">{content}</Text>
        </div>;
    };

    const renderTimeContainer = () => {
        return <div
            className={classes.timeContainer}
            style={{
                gap: spaces.inline
            }}
        >
            <Text
                variant="body4-regular"
                color="textSecondary"
            >
                {calculateTimeDifference(createdAt)}
            </Text>
            {renderBadge()}
        </div>;
    }; 

    return <div
        className={classes.container}
        style={{
            padding: spaces.container * .75,
        }}
    >
        {renderProfilePhoto()}
        {renderContent()}
        {renderTimeContainer()}
    </div>;
};

export default NotificationCard;
