import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    IGraphChipProps 
} from "./types";
import useStyles from "./styles";

const GraphChip = ({
    selected = false,
    disabled = false,
    onClick,
    title
}: IGraphChipProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div 
        className={`${classes.graphChipContainer} ${disabled ? classes.graphDisabled : ""}`}
        onClick={onClick}
        style={{
            borderColor: selected ? colors.primary : colors.stroke,
            borderWidth: selected ? borders.line : 0,
            paddingBottom: spaces.content / 2,
            borderRadius: radiuses.half / 2,
            paddingTop: spaces.content / 2,
            paddingRight: spaces.content,
            paddingLeft: spaces.content
        }}
    >
        <Text 
            variant="body3-regular" 
            color={selected ? "primary" : "textGrey"}
        >
            {title}
        </Text>
    </div>;
};

export default GraphChip;