import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const EyeOpen = ({
    color= "#16A34A",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return   <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M11.85 15.2a3.15 3.15 0 1 1 6.3 0 3.15 3.15 0 0 1-6.3 0Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M1 15.2c0 2.295.595 3.068 1.785 4.614C5.16 22.9 9.145 26.4 15 26.4c5.855 0 9.84-3.5 12.215-6.586C28.405 18.268 29 17.495 29 15.2s-.595-3.068-1.785-4.614C24.84 7.5 20.855 4 15 4 9.145 4 5.16 7.5 2.785 10.586 1.595 12.132 1 12.905 1 15.2Zm14-5.25a5.25 5.25 0 1 0 0 10.5 5.25 5.25 0 0 0 0-10.5Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default EyeOpen;
