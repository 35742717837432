import {
    AnswerChildObjectType
} from "../../types";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox
} from "isinolacak-web-cl";

const TwoOptions = ({
    attributes,
    setData,
    index,
    data
}: AnswerChildObjectType) => {
    const {
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    return <div>
        <SelectBox
            titleExtractor={(item) => item.localizedValue ? localize(item.localizedValue) : ""}
            inputTitle={localize("expectation")}
            keyExtractor={(e) => e.localizedValue || ""}
            title={localize("expectation")}
            data={attributes.inputs}
            isNeedConfirm={true}
            multiSelect={false}
            disabled={false}
            style={{
                marginBottom: spaces.content * 1.5
            }}
            onOk={({
                selectedItems,
                closeSheet,
                onSuccess
            }) => {
                let _data = JSON.parse(JSON.stringify(data));
                _data.questions[index].answerPayload.attributes.idealInput = selectedItems[0].__key;
                setData(_data);
                closeSheet();
                onSuccess();
            }}
        />
    </div>;
};
export default TwoOptions;
