import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const AddCircle = ({
    color = "#FFF",
    size = 20,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 20 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M10.625 8a.625.625 0 1 0-1.25 0v1.875H7.5a.625.625 0 0 0 0 1.25h1.875V13a.625.625 0 0 0 1.25 0v-1.875H12.5a.625.625 0 1 0 0-1.25h-1.875V8Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M10 1.542a8.958 8.958 0 1 0 0 17.916 8.958 8.958 0 0 0 0-17.916ZM2.292 10.5a7.708 7.708 0 1 1 15.417 0 7.708 7.708 0 0 1-15.417 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default AddCircle;
