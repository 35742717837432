import {
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CompanyMailTypes
} from "./types";
import {
    RoundArrowIcon 
} from "../../../../../../../../assets/svgr";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    getErrorText 
} from "../../../../../../../../utils";

const CompanyMail = ({
    setErrors,
    setToken,
    setViews,
    errors
}: CompanyMailTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [loadingButton, setLoadingButton] = useState(false);
    const [mail, setMail] = useState("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleChangeCompanyMail = () => {
        let _errors = JSON.parse(JSON.stringify(errors));

        if (mail.length < 8) {
            _errors.push("mail-length-error");
        }
        
        if (!emailRegex.test(mail)) {
            _errors.push("mail-format-error");
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        setLoadingButton(true);
        RESTService.action("ChangeCompanyMail", {
            mail: mail
        })
            .then((res) => {
                setToken ? setToken(res.token): "";
                setLoadingButton(false);
                setViews ? setViews("company-mail-sms"): "";
            })
            .catch((err) => {
                console.error("ChangeCompanyMail Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSaveButton = () => {
        return <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                title={localize("save")}
                spreadBehaviour="stretch"
                loading={loadingButton}
                onClick={() => {
                    handleChangeCompanyMail();
                }}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderTitle = () => {
        const mailLengthError = errors.includes("mail-length-error");
        const mailFormatError = errors.includes("mail-format-error");
        const errorText = mailLengthError
            ? localize("ıt-must-be-at-least-3-characters")
            : mailFormatError
                ? localize("invalid-email-format")
                : undefined;

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("please-enter-your-new-e-mail")}
            </Text>
            <TextInput
                placeholder={localize("enter-your-information.")}
                isError={mailLengthError || mailFormatError}
                infoText={errorText}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    _errors = _errors.filter((error: string) => error !== "mail-length-error" && error !== "mail-format-error");
                    setErrors(_errors);
                }}
                onChangeText={(e) => {
                    setMail(e);
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderTitle()}
        </div>;
    };


    return <div
        style={{
            padding: spaces.content * 2
        }}
    >
        <div
            className={classes.container}
            style={{
                gap: spaces.content
            }}
        >
            <div
                onClick={() => setViews ? setViews("default-open"): ""}
            >
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant='header5-medium'
                color='textDark'
            >
                {localize("corporate-email-update")}
            </Text>
        </div>
        {renderContent()}
        {renderSaveButton()}
    </div>;
};

export default CompanyMail;
