import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Facebook = ({
    color = "#fff",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M11.794 6.422v3.855H9v4.714h2.794V29h5.735V14.992h3.85s.36-2.26.536-4.732h-4.362V7.037c0-.482.624-1.13 1.243-1.13h3.127V1h-4.25c-6.022 0-5.879 4.718-5.879 5.422Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default Facebook;
