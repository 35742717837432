import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        borderStyle: "solid",
        width: "100%",
        minWidth: "330px",
        maxWidth: "23%",
        display: "flex",
        flexGrow: 1,
        cursor: "pointer",
        "&:hover": {
            opacity: 0.8
        },
        "@media screen and (max-width: 1760px)": {
            maxWidth: "32% !important"
        },
        "@media screen and (max-width: 1430px)": {
            maxWidth: "48% !important"
        },
        "@media screen and (max-width: 908px)": {
            maxWidth: "100% !important"
        }
    },
    informationTextStyle: {
        width: "100%"
    },
    renderInformationsStyle: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex",
        height: "100%"
    },
    imageStyle: {
        objectFit: "cover",
        height: 56,
        width: 56
    },
    bookmarkStyle: {
        justifyContent: "center",
        alignContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 36,
        width: 36
    },
    renderTagsStyle: {
        boxSizing: "border-box",
        borderTopStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    tagContainer:{
    },
    informationsContent:{ 
        alignItems: "center",
        flexDirection: "row",
        display:"flex"
    }
});
