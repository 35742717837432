import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media (max-width: 1040px)": {
            padding: "0 !important",
            flexDirection: "column",
            display:"flex",
            width: "100%"
        },
    },
    outerContainer: {
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex"
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            padding:"10px 20px !important",
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        position: "sticky",
        maxWidth: "18%",
        display: "flex",
        height: "100%",
        minWidth: 220,
        width: "100%",
        "@media (max-width: 1040px)": {
            position: "relative",
            maxWidth: "unset",
            display: "flex",
            width: "100%"
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        minHeight: "500px",
        height: "100%",
        width: "73%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        width: "100%",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%",
        height: "1px"
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    renderTagsStyle: {
        display: "flex",
        width: "97%"
    },
    pageModalContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display:"flex"
    },
    pageModalContent: {
        flexDirection: "row",
        display: "flex"
    },
    headerContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
    headerContainerIcon: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    icon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 25,
        width: 25
    },
    textContent: {
        flexDirection: "column",
        display: "flex"
    },
    sticker: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    loadingContainer: {
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center",
        display: "flex"
    },
    imageContainer: {
        boxSizing: "border-box",
        objectFit: "cover",
        flexShrink: 0,
        height: 125,
        width: 125
    },
    image: {
        borderStyle: "solid",
        objectFit: "cover",
        height: "100%",
        width: "100%"
    },
    textStart: {
        alignSelf: "start",
        textAlign: "start"
    },
    companyInformation: {
        alignSelf: "start",
        textAlign: "start",
        width: "100%",
        flexGrow: 1
    },
    textCenter: {
        textAlign: "center"
    },
    emptyCompanyInfo: {
        borderStyle: "solid",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    shareIcon: {
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 25,
        width: 25
    }
});
export default useStyles;