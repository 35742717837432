import {
    ShareDirectionsType 
} from "./types";

export const shareDirections: ShareDirectionsType = {
    employer: {
        "jobDetails": {
            redirectTo: "/app/jobDetails",
            dataKey: "advertID"
        },
        "profileUser": {
            redirectTo: "/app/profileUser/",
            dataKey: "workerID"
        },
    },
    worker: {
        "jobDetails": {
            redirectTo: "/app/jobDetails",
            dataKey: "jobID"
        },
        "profileUser": {
            redirectTo: "/app/profileUser",
            dataKey: "companyID"
        },
        "cv": {
            redirectTo: "/app/cv/",
        },
        "applicationUpdate": {
            redirectTo: "/app/applicationManagement/",
            dataKey: "applicationID"
        },
        "search": {
            redirectTo: "/app/search"
        },
        "conversation": {
            redirectTo: "/app/chat",
            dataKey: "conversationID"
        },
        "myActions": {
            redirectTo: "/app/myActions"
        }
    },
    noAuth: {
        "jobDetails": {
            redirectTo: "/jobDetails",
            dataKey: "jobID"
        }
    }
};