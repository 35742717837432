import useStyles from "./styles";
import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    QualificationCardPropsType
} from "./types";

const QualificationCard = ({
    category,
    title,
    level,
    logo: Logo
}: QualificationCardPropsType) => {
    const classes = useStyles();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div
        className={classes.cardHeader}
        style={{
            marginBottom: spaces.content,
            marginLeft: spaces.content,
            marginTop: spaces.content
        }}
    >
        <div
            className={classes.logo}
            style={{
                borderRadius: spaces.contentXLarge * 2,
                borderColor: colors.primary,
                borderWidth: borders.line,
                padding: spaces.inline
            }}
        >
            <Logo/>
        </div>
        <div
            style={{
                marginLeft: spaces.content
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                >
                    {level}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textSecondary"
                >
                    {category}
                </Text>
            </div>
        </div>
    </div>;
};

export default QualificationCard;
