import {
    useEffect,
    useState
} from "react";
import useStyles from "./style";
import {
    AssingPerformanceTestStatusType,
    PerformanceTestProps,
    AssignedPTType
} from "./types";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import CreatePerformanceTest from "./createPerformanceTest";
import PerformanceTestList from "./performanceTestList";
import EditPerformanceTest from "./editPerformanceTest";
import PTCard from "./ptCard";
import {
    TEST_DATA
} from "./createPerformanceTest/constants";
import {
    TestObjectType
} from "./createPerformanceTest/types";
import {
    GetPTListObjectType
} from "../../services/restAPI/actions/getPerformanceTestList/types";
import {
    RESTService
} from "../../services/restAPI";
import {
    RoundArrowIcon,
    TrashIcon,
    PlusIcon
} from "../../assets/svgr";
import {
    getErrorText
} from "../../utils";

const PerformanceTest = ({
    setCreateAttachmentStatus,
    createAttachmentStatus,
    setSelectedPT,
    selectedPT
}: PerformanceTestProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [ptAssignStatus, setPtAssignStatus] = useState<AssingPerformanceTestStatusType>("assignPT");
    const [ptListData, setPtListData] = useState<Array<GetPTListObjectType>>([]);
    const [data, setData] = useState<TestObjectType>(TEST_DATA);
    const [loading, setLoading] = useState<boolean>(true);
    const [editPTID, setEditPTID] = useState("");
    const [tempSelectedPT, setTempSelectedPT] = useState<AssignedPTType>({
        ptID: "",
        title: ""
    });
    const [deletePTAlert, setDeletePTAlert] = useState<{showDialog:boolean, id: string}>({
        showDialog: false,
        id: ""
    });

    useEffect(() => {
        getPerformanceTests();
    }, []);

    const getPerformanceTests = () => {
        RESTService.action("GetPTList", {
        })
            .then((res) => {
                setPtListData(res.payload);
                if(res.totalDataCount > 0) {
                    setPtAssignStatus("assignPT");
                } else {
                    setPtAssignStatus("createPT");
                }
                setLoading(false);
            })
            .catch((err) => {
                console.error("GetPerformanceTest Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const deletePT = () => {
        RESTService.action("DeletePT", {
            performanceTestID: deletePTAlert.id
        })
            .then((res) => {
                let _ptListData = JSON.parse(JSON.stringify(ptListData));
                const indexOfPT = ptListData.findIndex(e => e._id === deletePTAlert.id);
                if(indexOfPT !== -1) {
                    _ptListData.splice(indexOfPT,1);
                    setPtListData(_ptListData);
                    setDeletePTAlert({
                        id: "",
                        showDialog: false
                    });
                }                
            })
            .catch((err) => {
                console.error("DeletePerformanceTest Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const renderPerformansTestHeader = () => {
        return <div
            className={classes.header}
            style={{
                marginBottom: spaces.contentLarge,
                gap: spaces.content
            }}
        >
            <div
                className={classes.headerIcon}
                onClick={() => {
                    if(ptAssignStatus !== "assignPT") {
                        setPtAssignStatus("assignPT");
                        setData(TEST_DATA);
                    } else {
                        setCreateAttachmentStatus("advertInfo");
                    }
                }}
            >
                <RoundArrowIcon
                    color={colors.primary}
                    size={20}
                />
            </div>
            <Text
                variant="header5-medium"
            >
                {localize("create-performance-test-title")}
            </Text>
        </div>;
    };

    const renderCreatePT = () => {
        if(loading) {
            return null;
        }

        if(ptAssignStatus !== "createPT") {
            return null;
        }

        return <CreatePerformanceTest
            setPtAssignStatus={setPtAssignStatus}
            setTempSelectedPT={setTempSelectedPT}
            ptAssignStatus={ptAssignStatus}
            setSelectedPT={setSelectedPT}
            setData={setData}
            data={data}
        />; 
    };

    const renderEditPT = () => {
        if(loading) {
            return null;
        }

        if(ptAssignStatus !== "editPT") {
            return null;
        }

        return <EditPerformanceTest
            setPtAssignStatus={setPtAssignStatus}
            setTempSelectedPT={setTempSelectedPT}
            ptAssignStatus={ptAssignStatus}
            setSelectedPT={setSelectedPT}
            setEditPTID={setEditPTID}
            editPTID={editPTID}
            setData={setData}
            data={data}
        />; 
    };

    const renderTempPT = () => {
        if(loading) {
            return null;
        }

        if(ptAssignStatus !== "assignPT") {
            return null;
        }

        if(!tempSelectedPT.ptID) {
            return null;
        }

        if(tempSelectedPT.ptID && !tempSelectedPT.ptID.length) {
            return null;
        }

        return <PTCard
            isSelected={tempSelectedPT.ptID === selectedPT.ptID}
            title={tempSelectedPT.title}
            onEdit={() => {
                setEditPTID(tempSelectedPT.ptID);
                setPtAssignStatus("editPT");
            }}
            onClick={() => {
                setSelectedPT({
                    ptID: tempSelectedPT.ptID,
                    title: tempSelectedPT.title
                });
            }}
            onDelete={() => {
                setDeletePTAlert({
                    id: tempSelectedPT.ptID,
                    showDialog: true
                });
            }}
        />;
    };

    const renderPTList = () => {
        if(loading) {
            return null;
        }

        if(ptAssignStatus !== "assignPT") {
            return null;
        }

        return <div
            className={classes.ptListContainer}
        >
            <Button
                icon={() => <PlusIcon color={colors.primary} size={20}/>}
                title={localize("create-new-pt")}
                spreadBehaviour="stretch"
                variant="ghost"
                size="large"
                onClick={() => {
                    setPtAssignStatus("createPT");
                }}
            />
            <PerformanceTestList
                setPtAssignStatus={setPtAssignStatus}
                setPtListData={setPtListData}
                setSelectedPT={setSelectedPT}
                setEditPTID={setEditPTID}
                ptListData={ptListData}
                selectedPT={selectedPT}
            />
            <Button
                disabled={selectedPT.ptID ? false : true}
                spreadBehaviour="stretch"
                title={localize("use")}
                onClick={() =>{
                    setCreateAttachmentStatus("advertInfo");
                }}
            />
        </div>; 
    };

    const renderLoading = () => {
        if(!loading) {
            return null;
        }

        return <div
            className={classes.loadingcontainer}
        >
            <Loading/>
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            padding: `${spaces.content}px ${spaces.containerXLarge / 2}px`,
            backgroundColor: colors.layer1,
            borderRadius: radiuses.half,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderPerformansTestHeader()}
        {renderLoading()}
        {renderCreatePT()}
        {renderEditPT()}
        {renderTempPT()}
        {renderPTList()}
        <Dialog
            isVisible={deletePTAlert.showDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <TrashIcon
                        color={colors.error}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("delete-pt-warn-header")}
                    </Text>
                    <Text
                        variant="body-regular"
                        color="textGrey"
                    >
                        {localize("delete-pt-warn-content")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div
                className={classes.dialogBottom}
                style={{
                    gap: spaces.containerXLarge
                }}
            >
                <Button
                    title={localize("give-up")}
                    variant="outline"
                    spreadBehaviour="stretch"
                    onClick={() => {
                        setDeletePTAlert({
                            id: "",
                            showDialog: false
                        });
                    }}
                />
                <Button
                    spreadBehaviour="stretch"
                    title={localize("remove")}
                    onClick={() => {
                        deletePT();
                    }}
                />
            </div>}
        />
    </div>;
};

export default PerformanceTest;