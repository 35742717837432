import axios from "axios";
import {
    SERVER_ADDRESS,
    SERVER_ADDRESS_FRONT
} from "../../constants";
import {
    GetAdvertDetailWithoutAuthResponseType,
    GetAdvertDetailWithoutAuthRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const GetAdvertDetailWithoutAuth = async (params: GetAdvertDetailWithoutAuthRequestType): Promise<GetAdvertDetailWithoutAuthResponseType> => {
    return await axios(`${SERVER_ADDRESS_FRONT}/getAdvertDetailWithoutAuth`, {
        method: "GET",
        headers: getHeaders(),
        params: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default GetAdvertDetailWithoutAuth;
