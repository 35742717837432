import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        justifyContent: "start",
        flexDirection: "column",
        boxSizing: "border-box",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media screen and (max-width: 700px)": {
            paddingBottom: "30px !important",
            paddingRight: "10px !important",
            paddingLeft: "10px !important",
            paddingTop: "30px !important",
        }
    },
    mainContent: {
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "1200px"
    },
    mainContainer: {
        justifyContent: "start",
        alignItems: "start",
        display: "flex"
    },
    leftContainer: {
        flexBasis: "30%",
        "@media screen and (max-width: 1100px)": {
            display: "none"
        }
    },
    shareContainer: {
        flexDirection: "column",
        justifyContent: "start",
        borderStyle: "solid",
        alignItems: "start",
        maxWidth: "230px",
        display: "flex"
    },
    iconContainer: {
        justifyContent: "start",
        alignItems: "center",
        display: "flex"
    },
    icon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8
        }
    },
    contents: {
        justifyContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    rightContainer: {
        overflowWrap: "anywhere",
        flexDirection: "column",
        boxSizing: "border-box",
        flexBasis: "70%",
        display:"flex",
        //! SINCE DATA ARRIVE IN CUSTOM THIS PART IS NOT FOLLOWING THE RULES OF THE CODING STANDARDS
        
        "& img": {
            objectFit: "cover",
            borderRadius: "2%",
            marginBottom: 10,
            maxHeight: 350,
            width: "100%"
        },
        "@media screen and (max-width: 1100px)": {
            flexBasis: "100%",
            "& img": {
                maxHeight: 300,
            },
        },
        "@media screen and (max-width: 750px)": {
            flexBasis: "100%",
            "& img": {
                maxHeight: 180,
            }
        }
    },
    blogContent: {
        "& *": {
            marginBottom: 10
        },
    },
    smallShareContainer: {
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        display: "flex",
        "@media screen and (min-width: 1100px)": {
            display: "none"
        }
    }
});