import {
    IOCoreTranslationType 
} from "isinolacak-web-cl";
import {
    ApplicationDateTypes 
} from "./types";

export const CANDIDATE_HISTORY: Array<{
    title: keyof IOCoreTranslationType;
    value: ApplicationDateTypes;
    id: string;
}> = [
    {
        title: "in-the-last-24-hours",
        value: "today",
        id: "5"
    },
    {
        title: "added-yesterday",
        value: "yesterday",
        id: "4"
    },
    {
        title: "added-one-week-ago",
        value: "thisWeek",
        id: "3"
    },
    {
        title: "added-one-month-ago",
        value: "thisMonth",
        id: "2"
    },
    {
        title: "added-one-years-ago",
        value: "thisYear",
        id: "1"
    }
];

export const TWO_OPTIONS: Array<{
    value: boolean,
    localizedValue: keyof IOCoreTranslationType
}> = [
    {
        value: true,
        localizedValue: "yes"
    },
    {
        value: false,
        localizedValue: "no"
    }
];