import axios from "axios";
import {
    REST_SERVER_ADDRESS
} from "../../constants";
import {
    SendMessageResponseType,
    SendMessageRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const SendMessage = async (params: SendMessageRequestType): Promise<SendMessageResponseType> => {
    return await axios(`${REST_SERVER_ADDRESS}/chat/sendMessage`, {
        method: "POST",
        headers: getHeaders(true),
        data: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default SendMessage;
