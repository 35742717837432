import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import JobExperience from "./views/jobExperience";
import {
    LicenceOrCertificatesType,
    ForeingLanguagesType,
    WorkExperienceType,
    CapabilitiesType,
    AssociationType,
    EducationType,
    CityTypes
} from "./types";
import {
    CircleIcon,
    RadarIcon
} from "../../../../../../assets/svgr";
import {
    PersonalInfoTypes 
} from "../../types";
import {
    RESTService
} from "../../../../../../services/restAPI";
import {
    EditCVRequestType
} from "../../../../../../services/restAPI/actions/editCV/types";
import {
    GetForeignLanguagesPayloadObjectType, 
    GetForeignLanguagesRequestType,
    GetForeignLanguagesResponseType
} from "../../../../../../services/restAPI/actions/getForeignLanguages/types";
import {
    LicencesAndCertificatesObjectType,
    ForeignLanguageObjectType,
    WorkExperienceObjectType,
    CapabiltiesObjectType,
    EducationObjectType,
    CreateCVRequestType
} from "../../../../../../services/restAPI/actions/createCV/types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import ResumeApproval from "./views/resumeApproval";
import Hobbies from "./views/other/views/hobbies";
import CoverLetter from "./views/coverLetter";
import Education from "./views/education";
import Other from "./views/other";
import {
    GetMyCVResponseType 
} from "../../../../../../services/restAPI/actions/getMyCV/types";
import {
    getErrorText 
} from "../../../../../../utils";
import {
    WorkerProfileLeftContainer 
} from "../../../../../../components";

const CV = () =>  {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [isSuitableForDisabledPeopleAdvert, setIsSuitableForDisabledPeopleAdvert] = useState(false);
    const [associations, setAssociations] = useState<AssociationType>([]);
    const [activePage, setActivePage] = useState(location.pathname);
    const [city, setCity] = useState<CityTypes>({
        localizedText: "",
        _id: ""
    });
    const localStorageData: string | null = window.localStorage.getItem("user");

    let user: PersonalInfoTypes;
    if (localStorageData) {
        user = JSON.parse(localStorageData);
    } else {
        user = {
            areaCode: {
                countryTranslationKey: "",
                flagUnicode: "",
                country: "",
                value: "",
                _id: "",
            },
            occupation: {
                localizedText: "",
                _id: ""
            },
            phoneNumber: "",
            firstName: "",
            lastName: "",
            fullName: "",
            photoURL: "",
            about: "",
            mail: "",
        };
    }

    const [licenceOrCertificates, setLicenceOrCertificates] = useState<LicenceOrCertificatesType>([]);
    const [foreignLanguages, setForeignLanguages] = useState<ForeingLanguagesType>([]);
    const [workExperience, setWorkExperience] = useState<WorkExperienceType>([]);
    const [capabilities, setCapabilities] = useState<CapabilitiesType>([{
        localizedText: "",
        capabilityID: "",
        point: 0
    }]);
    const [getForeignLanguages, setGetForeignLanguages] = useState<Array<GetForeignLanguagesPayloadObjectType>>([]);
    const [foreignLanguagesTotalData, setForeignLanguagesTotalData] = useState<GetForeignLanguagesResponseType>();
    const [searchForeignLanguagesText, setSearchForeignLanguagesText] = useState("");
    const [getMyCVPhotoURL, setGetMyCVPhotoURL] = useState<GetMyCVResponseType>();
    const [foreignLanguagesPage, setForeignLanguagesPage] = useState<number>(1);
    const [education, setEducation] =  useState<EducationType>([]);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [getMyCV, setGetMyCV] = useState<string | null>();
    const [errors, setErrors] = useState<string[]>([]);
    const [searchPage, setSearchPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState("");
    const [hobbies, setHobbies] = useState("");

    const userLanguage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        handleGetMyCV();
    }, []);

    useEffect(() => {
        getForeignLanguagesData();
    },[foreignLanguagesPage,searchPage]);

    const getForeignLanguagesData = (searchParams?: GetForeignLanguagesRequestType) => {;
        let params: GetForeignLanguagesRequestType = {
            page:  foreignLanguagesPage,
            language: userLanguage ?? "tr-TR"
        };

        if(searchForeignLanguagesText && searchForeignLanguagesText.length) {
            params.language= userLanguage ?? "tr-TR";
            params.search = searchForeignLanguagesText;
            params.page = searchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = userLanguage ?? "tr-TR";

        RESTService.action("GetForeignLanguages",params)
            .then((res) => {
                setGetForeignLanguages(res.payload);
                setForeignLanguagesTotalData(res);

                if(searchParams || searchForeignLanguagesText.length){
                    setForeignLanguagesPage(1);
                } else {
                    setForeignLanguagesPage(params.page ?? 1);
                    setSearchPage(1);
                }
            })
            .catch((err) => {
                console.error("GetForeignLanguages Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleGetMyCV = () => {
        setLoading(true);
        RESTService.action("GetMyCV", {
        })
            .then((res) => {
                setLoading(false);
                setGetMyCV(res._id);
                setGetMyCVPhotoURL(res);
                setLoading(false);
                
                if(res.city) {
                    setCity({
                        localizedText: res.city.localizedText,
                        _id: res.city._id
                    });
                }

                if(res.education) {
                    let _education: EducationType = res.education.map((item) => {
                        return {
                            educationLevel: {
                                localizedText: item.educationLevel.localizedText,
                                _id: item.educationLevel._id,
                                key: ""
                            },
                            startDate: item.startDate,
                            endDate: item.endDate,
                            school: item.school,
                            major: item.major
                        };
                    });
                    setEducation(_education);
                }

                if(res.workExperience) {
                    let _workExperience: WorkExperienceType = res.workExperience.map((item) => {
                        return {
                            jobTitle: {
                                localizedText: item.jobTitle.localizedText,
                                _id: item.jobTitle._id,
                                key: ""
                            },
                            isStillWorkHere: item.isStillWorkHere,
                            companyName: item.companyName,
                            startDate: item.startDate,
                            endDate: item.endDate,
                        };
                    });
                    setWorkExperience(_workExperience);
                }

                if(res.capabilities) {
                    let _capabilities: CapabilitiesType = res.capabilities.map((item) => {
                        return {
                            localizedText: item.localizedText,
                            capabilityID: item.capabilityID,
                            point: item.point
                        };
                    });
                    setCapabilities(_capabilities);
                }

                if (res.foreignLanguages) {
                    let _foreignLanguages: ForeingLanguagesType = res.foreignLanguages.map((item) => {
                        return {
                            language: {
                                localizedText: item.language.localizedText,
                                _id: item.language._id
                            },
                            level: {
                                localizedText: item.level.localizedText,
                                levelID: item.level._id
                            }
                        };
                    });
                    setForeignLanguages(_foreignLanguages);
                }

                if(res.hobbies) {
                    setHobbies(res.hobbies);
                }

                if(res.summary) {
                    setSummary(res.summary);
                }

                if(res.isSuitableForDisabledPeopleAdverts) {
                    setIsSuitableForDisabledPeopleAdvert(res.isSuitableForDisabledPeopleAdverts);
                }

                if(res.licencesAndCertificates) {
                    let _licencesAndCertificates = res.licencesAndCertificates.map((item) => {
                        return {
                            givenByOrganization: item.givenByOrganization,
                            takenAtMonth: item.takenAtMonth,
                            takenAtYear: item.takenAtYear,
                            name: item.name
                        };
                    });
                    setLicenceOrCertificates(_licencesAndCertificates);
                }

                if (res.associations) {
                    let _associations = res.associations.map((item: string) => item);
                    setAssociations(_associations);
                }

            })
            .catch((err) => {
                console.error("GetMyCV Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleCreateCV = () => {
        let _errors: Array<string> = JSON.parse(JSON.stringify(errors));

        const CapabilitiesData = capabilities.map((item) => ({
            capabilityID: item.capabilityID,
            capability: item.localizedText,
            point: item.point
        }));
        
        let params: CreateCVRequestType = {
            capabilities: CapabilitiesData,
            summary: summary,
            cityID: city._id
        };

        if(city._id) {
            params.cityID = city._id;
        } else {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("city-cityID-length-error");
            setErrors(_errors);
            return;
        }

        if (summary.length >= 3) {
            params.summary = summary;
        } else {
            let _errors = JSON.parse(JSON.stringify(errors));
            _errors.push("summary-length-error");
            setErrors(_errors);
            return;
        }

        if (capabilities && capabilities.length) {
            let _capabilities: Array<CapabiltiesObjectType> = [];

            capabilities.forEach((item, index) => {
                if(item.capabilityID.length || item.point) {
                    let hasError = false;
                    let baseErrorKey = `capabilities-${index}`;

                    if (!item.capabilityID.length) {
                        if (!_errors.includes(`${baseErrorKey}-capabilityID-length-error`)) {
                            _errors.push(`${baseErrorKey}-capabilityID-length-error`);
                        }
                        hasError = true;
                    }
        
                    if (!item.point) {
                        if (!_errors.includes(`${baseErrorKey}-point-length-error`)) {
                            _errors.push(`${baseErrorKey}-point-length-error`);
                        }
                        hasError = true;
                    }

                    if (hasError) {
                        setErrors(_errors);
                        return null;
                    }

                    let __capabilities = {
                        capabilityID: item.capabilityID,
                        point: item.point
                    };
                    _capabilities.push(__capabilities);

                    if (_capabilities.length) {
                        params.capabilities = _capabilities;
                    }
                } else {
                    let hasError = false;
                    let baseErrorKey = `capabilities-${index}`;

                    if (!item.capabilityID.length) {
                        if (!_errors.includes(`${baseErrorKey}-capabilityID-length-error`)) {
                            _errors.push(`${baseErrorKey}-capabilityID-length-error`);
                        }
                        hasError = true;
                    }
        
                    if (!item.point) {
                        if (!_errors.includes(`${baseErrorKey}-point-length-error`)) {
                            _errors.push(`${baseErrorKey}-point-length-error`);
                        }
                        hasError = true;
                    }

                    if (hasError) {
                        setErrors(_errors);
                        return null;
                    }
                }
            });
        }

        if (education && education.length) {
            let _educationDetails: Array<EducationObjectType> = [];
        
            education.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `education-${index}`;
        
                if (!item.educationLevel._id.length) {
                    if (!_errors.includes(`${baseErrorKey}-educationLevelID-length-error`)) {
                        _errors.push(`${baseErrorKey}-educationLevelID-length-error`);
                    }
                    hasError = true;
                }
        
                if (!item.startDate) {
                    if (!_errors.includes(`${baseErrorKey}-startDate-length-error`)) {
                        _errors.push(`${baseErrorKey}-startDate-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.endDate) {
                    if (!_errors.includes(`${baseErrorKey}-endDate-length-error`)) {
                        _errors.push(`${baseErrorKey}-endDate-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.school || item.school.length < 3 || item.school.length > 55) {
                    if (!_errors.includes(`${baseErrorKey}-school-length-error`)) {
                        _errors.push(`${baseErrorKey}-school-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.major || (item.major.length < 3 || item.major.length > 55)) {
                    if (!_errors.includes(`${baseErrorKey}-major-length-error`)) {
                        _errors.push(`${baseErrorKey}-major-length-error`);
                    }
                    hasError = true;
                }
        
                if (hasError) {
                    setErrors(_errors);
                    return;
                }
        
                let __educationDetails = {
                    educationLevelID: item.educationLevel._id,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    school: item.school,
                    major: item.major
                };
                _educationDetails.push(__educationDetails);
            });
        
            if (_educationDetails.length) {
                params.education = _educationDetails;
            }
        }

        if(foreignLanguages && foreignLanguages.length) {
            let _foreignLanguageDetail:Array<ForeignLanguageObjectType> = [];

            foreignLanguages.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `foreignLanguages-${index}`;
        
                if (!item.language._id.length) {
                    if (!_errors.includes(`${baseErrorKey}-languageID-length-error`)) {
                        _errors.push(`${baseErrorKey}-languageID-length-error`);
                    }
                    hasError = true;
                }
        
                if (!item.level.levelID) {
                    if (!_errors.includes(`${baseErrorKey}-levelID-length-error`)) {
                        _errors.push(`${baseErrorKey}-levelID-length-error`);
                    }
                    hasError = true;
                }

                if (hasError) {
                    setErrors(_errors);
                    return;
                }

                let __foreignLanguage = {
                    languageID: item.language._id,
                    levelID: item.level.levelID
                };
                _foreignLanguageDetail.push(__foreignLanguage);
            });
            
            if(_foreignLanguageDetail.length) {
                params.foreignLanguages = _foreignLanguageDetail;
            }
        }

        if(workExperience && workExperience.length) {
            let _jobExpreinceDetails:Array<WorkExperienceObjectType> = [];

            workExperience.forEach((item, index) => {                    
                let hasError = false;
                let baseErrorKey = `workExperience-${index}`;
        
                if (!item.jobTitle._id.length) {
                    if (!_errors.includes(`${baseErrorKey}-jobTitleID-length-error`)) {
                        _errors.push(`${baseErrorKey}-jobTitleID-length-error`);
                    }
                    hasError = true;
                }
        
                if (!item.startDate) {
                    if (!_errors.includes(`${baseErrorKey}-startDate-length-error`)) {
                        _errors.push(`${baseErrorKey}-startDate-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.endDate && !item.isStillWorkHere) {
                    _errors.push(`${baseErrorKey}-endDate-length-error`);
                    hasError = true;
                }
        
                if (item.endDate && item.isStillWorkHere) {
                    _errors.push(`${baseErrorKey}-endDate-isStillWorkHere-conflict-error`);
                    hasError = true;
                }
                
                if (!item.companyName || item.companyName.length < 3 || item.companyName.length > 55) {
                    if (!_errors.includes(`${baseErrorKey}-companyName-length-error`)) {
                        _errors.push(`${baseErrorKey}-companyName-length-error`);
                    }
                    hasError = true;
                }
        
                if (hasError) {
                    setErrors(_errors);
                    return;
                }

                let __jobExpreinceDetails = {
                    //@ts-ignore
                    endDate: item.isStillWorkHere ? null : item.endDate,
                    isStillWorkHere: item.isStillWorkHere,
                    companyName: item.companyName,
                    jobTitle: item.jobTitle._id,
                    startDate: item.startDate,
                };
                //@ts-ignore
                _jobExpreinceDetails.push(__jobExpreinceDetails);
            });

            if(_jobExpreinceDetails.length) {
                params.workExperience = _jobExpreinceDetails;
            }
        }
        
        if (associations && associations.length) {
            let _associations: string[] = [];

            associations.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `associations-${index}`;

                if (!item || item.length < 3 || item.length > 55) {
                    if (!_errors.includes(`${baseErrorKey}-associations-length-error`)) {
                        _errors.push(`${baseErrorKey}-associations-length-error`);
                    }
                    hasError = true;
                }

                if (hasError) {
                    setErrors(_errors);
                    return;
                }

                let __associations = item;
                _associations.push(__associations);
            });
            if (_associations.length) {
                params.associations = _associations;
            }
        }

        if (hobbies) {
            if (hobbies.length >= 3 && hobbies.length <= 55) {
                params.hobbies = hobbies;
            } else {
                let _errors = [...errors, "hobbies-length-error"];
                setErrors(_errors);
                return;
            }
        }

        if(licenceOrCertificates && licenceOrCertificates.length) {
            let _licenceOrCertificatesDetail: Array<LicencesAndCertificatesObjectType> = [];

            licenceOrCertificates.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `licenceOrCertificates-${index}`;
        
                if (!item.name || item.name.length < 3 || item.name.length > 55) {
                    if (!_errors.includes(`${baseErrorKey}-name-length-error`)) {
                        _errors.push(`${baseErrorKey}-name-length-error`);
                    }
                    hasError = true;
                }
        
                if (!item.takenAtMonth) {
                    if (!_errors.includes(`${baseErrorKey}-takenAtMonth-length-error`)) {
                        _errors.push(`${baseErrorKey}-takenAtMonth-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.takenAtYear) {
                    if (!_errors.includes(`${baseErrorKey}-takenAtYear-length-error`)) {
                        _errors.push(`${baseErrorKey}-takenAtYear-length-error`);
                    }
                    hasError = true;
                }
                
                if (!item.givenByOrganization || item.givenByOrganization.length < 3 || item.givenByOrganization.length > 55) {
                    if (!_errors.includes(`${baseErrorKey}-givenByOrganization-length-error`)) {
                        _errors.push(`${baseErrorKey}-givenByOrganization-length-error`);
                    }
                    hasError = true;
                }
                    
                if (hasError) {
                    setErrors(_errors);
                    return;
                }
                    
                let __licenceOrCertificatesDetail = {
                    givenByOrganization: item.givenByOrganization,
                    takenAtMonth: item.takenAtMonth,
                    takenAtYear: item.takenAtYear,
                    name: item.name,
                };
                _licenceOrCertificatesDetail.push(__licenceOrCertificatesDetail);
            });

            if(_licenceOrCertificatesDetail.length) {
                params.licencesAndCertificates = _licenceOrCertificatesDetail;
            }
        }
        
        if(isSuitableForDisabledPeopleAdvert) {
            params.isSuitableForDisabledPeopleAdverts = isSuitableForDisabledPeopleAdvert;
        }
        
        if(_errors.length) {
            setErrors(_errors);
            return null;
        }

        setLoadingButton(true);
        RESTService.action("CreateCV", params)
            .then((res) => {
                setIsVisibleDialog(true);
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("CreateCV Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };
    
    const handleEditCV = () => {
        let params: EditCVRequestType = {
        };
        let _errors: Array<string> = JSON.parse(JSON.stringify(errors));
    
        if (city._id) {
            params.cityID = city._id;
        } else {
            _errors.push("city-cityID-length-error");
        }
    
        if (summary.length) {
            params.summary = summary;
        } else {
            _errors.push("summary-length-error");
        }
    
        if (capabilities && capabilities.length) {
            let _capabilities: Array<CapabiltiesObjectType> = [];

            capabilities.forEach((item, index) => {
                if(item.capabilityID.length || item.point) {
                    let hasError = false;
                    let baseErrorKey = `capabilities-${index}`;

                    if (!item.capabilityID.length) {
                        if (!_errors.includes(`${baseErrorKey}-capabilityID-length-error`)) {
                            _errors.push(`${baseErrorKey}-capabilityID-length-error`);
                        }
                        hasError = true;
                    }
        
                    if (!item.point) {
                        if (!_errors.includes(`${baseErrorKey}-point-length-error`)) {
                            _errors.push(`${baseErrorKey}-point-length-error`);
                        }
                        hasError = true;
                    }

                    if (hasError) {
                        setErrors(_errors);
                        return null;
                    }

                    let __capabilities = {
                        capabilityID: item.capabilityID,
                        point: item.point
                    };
                    _capabilities.push(__capabilities);

                    if (_capabilities.length) {
                        params.capabilities = _capabilities;
                    }
                } else {
                    let hasError = false;
                    let baseErrorKey = `capabilities-${index}`;

                    if (!item.capabilityID.length) {
                        if (!_errors.includes(`${baseErrorKey}-capabilityID-length-error`)) {
                            _errors.push(`${baseErrorKey}-capabilityID-length-error`);
                        }
                        hasError = true;
                    }
        
                    if (!item.point) {
                        if (!_errors.includes(`${baseErrorKey}-point-length-error`)) {
                            _errors.push(`${baseErrorKey}-point-length-error`);
                        }
                        hasError = true;
                    }

                    if (hasError) {
                        setErrors(_errors);
                        return null;
                    }
                }
            });
        }

        if (education && education.length) {
            let _educationDetails: Array<EducationObjectType> = [];
    
            education.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `education-${index}`;
    
                if (!item.educationLevel._id.length) {
                    _errors.push(`${baseErrorKey}-educationLevelID-length-error`);
                    hasError = true;
                }
    
                if (!item.startDate) {
                    _errors.push(`${baseErrorKey}-startDate-length-error`);
                    hasError = true;
                }
    
                if (!item.endDate) {
                    _errors.push(`${baseErrorKey}-endDate-length-error`);
                    hasError = true;
                }
    
                if (!item.school || item.school.length < 3 || item.school.length > 55) {
                    _errors.push(`${baseErrorKey}-school-length-error`);
                    hasError = true;
                }
    
                if (!item.major || item.major.length < 3 || item.major.length > 55) {
                    _errors.push(`${baseErrorKey}-major-length-error`);
                    hasError = true;
                }
    
                if (!hasError) {
                    _educationDetails.push({
                        educationLevelID: item.educationLevel._id,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        school: item.school,
                        major: item.major
                    });
                }
            });
    
            if (_educationDetails.length) {
                params.education = _educationDetails;
            }
        } else {
            params.education = [];
        }
    
        if (foreignLanguages && foreignLanguages.length) {
            let _foreignLanguageDetail: Array<ForeignLanguageObjectType> = [];
    
            foreignLanguages.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `foreignLanguages-${index}`;
    
                if (!item.language._id.length) {
                    _errors.push(`${baseErrorKey}-languageID-length-error`);
                    hasError = true;
                }
    
                if (!item.level.levelID) {
                    _errors.push(`${baseErrorKey}-levelID-length-error`);
                    hasError = true;
                }
    
                if (!hasError) {
                    _foreignLanguageDetail.push({
                        languageID: item.language._id,
                        levelID: item.level.levelID
                    });
                }
            });
    
            if (_foreignLanguageDetail.length) {
                //@ts-ignore
                params.foreignLanguages = _foreignLanguageDetail;
            }
        } else {
            params.foreignLanguages = [];
        }
    
        if (workExperience && workExperience.length) {
            let _jobExperienceDetails: Array<WorkExperienceObjectType> = [];
        
            workExperience.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `workExperience-${index}`;
        
                if (!item.jobTitle._id.length) {
                    _errors.push(`${baseErrorKey}-jobTitleID-length-error`);
                    hasError = true;
                }
        
                if (!item.startDate) {
                    _errors.push(`${baseErrorKey}-startDate-length-error`);
                    hasError = true;
                }

                if (!item.endDate && !item.isStillWorkHere) {
                    _errors.push(`${baseErrorKey}-endDate-length-error`);
                    hasError = true;
                }
        
                if (item.endDate && item.isStillWorkHere) {
                    _errors.push(`${baseErrorKey}-endDate-isStillWorkHere-conflict-error`);
                    hasError = true;
                }
        
                if (!item.companyName || item.companyName.length < 3 || item.companyName.length > 55) {
                    _errors.push(`${baseErrorKey}-companyName-length-error`);
                    hasError = true;
                }
        
                if (!hasError) {
                    _jobExperienceDetails.push({
                        //@ts-ignore
                        endDate: item.isStillWorkHere ? null : item.endDate,
                        isStillWorkHere: item.isStillWorkHere,
                        companyName: item.companyName,
                        jobTitle: item.jobTitle._id,
                        startDate: item.startDate
                    });
                }
            });
        
            if (_jobExperienceDetails.length) {
                params.workExperience = _jobExperienceDetails;
            }
        } else {
            params.workExperience = [];
        }
        
    
        if (associations && associations.length) {
            let _associations: string[] = [];
    
            associations.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `associations-${index}`;
    
                if (!item || item.length < 3 || item.length > 55) {
                    _errors.push(`${baseErrorKey}-associations-length-error`);
                    hasError = true;
                }
    
                if (!hasError) {
                    _associations.push(item);
                }
            });
    
            if (_associations.length) {
                params.associations = _associations;
            }
        } else {
            params.associations = [];
        }
    
        if (hobbies) {
            if (hobbies.length >= 3 && hobbies.length <= 55) {
                params.hobbies = hobbies;
            } else {
                _errors.push("hobbies-length-error");
            }
        }
    
        if (licenceOrCertificates && licenceOrCertificates.length) {
            let _licenceOrCertificatesDetail: Array<LicencesAndCertificatesObjectType> = [];
    
            licenceOrCertificates.forEach((item, index) => {
                let hasError = false;
                let baseErrorKey = `licenceOrCertificates-${index}`;
    
                if (!item.name || item.name.length < 3 || item.name.length > 55) {
                    _errors.push(`${baseErrorKey}-name-length-error`);
                    hasError = true;
                }
    
                if (!item.takenAtMonth) {
                    _errors.push(`${baseErrorKey}-takenAtMonth-length-error`);
                    hasError = true;
                }
    
                if (!item.takenAtYear) {
                    _errors.push(`${baseErrorKey}-takenAtYear-length-error`);
                    hasError = true;
                }
    
                if (!item.givenByOrganization || item.givenByOrganization.length < 3 || item.givenByOrganization.length > 55) {
                    _errors.push(`${baseErrorKey}-givenByOrganization-length-error`);
                    hasError = true;
                }
    
                if (!hasError) {
                    _licenceOrCertificatesDetail.push({
                        givenByOrganization: item.givenByOrganization,
                        takenAtMonth: item.takenAtMonth,
                        takenAtYear: item.takenAtYear,
                        name: item.name,
                    });
                }
            });
    
            if (_licenceOrCertificatesDetail.length) {
                params.licencesAndCertificates = _licenceOrCertificatesDetail;
            }
        } else {
            params.licencesAndCertificates = [];
        }
    
        if (isSuitableForDisabledPeopleAdvert) {
            params.isSuitableForDisabledPeopleAdverts = isSuitableForDisabledPeopleAdvert;
        }

        if (typeof isSuitableForDisabledPeopleAdvert === "boolean") {
            params.isSuitableForDisabledPeopleAdverts = isSuitableForDisabledPeopleAdvert;
        }
    
        if (_errors.length) {
            setErrors(_errors);
            return null;
        }

        setLoadingButton(true);
        RESTService.action("EditCV", params)
            .then((res) => {
                setIsVisibleDialog(true);
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("EditCV Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };
    
    const renderSaveButton = () => {
        return <div
            style={{
                borderTopWidth: borders.line,
                paddingTop: spaces.content,
                borderColor: colors.stroke
            }}
        >
            <Button
                disabled={!(getMyCV && getMyCV.length) ? errors.length ? true : false : false}
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                onClick={() => {if(getMyCV && getMyCV.length) {
                    handleEditCV();
                } else {
                    handleCreateCV();
                }}}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.renderLine}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke
            }}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content * 2,
                    marginTop: spaces.container
                }}
            >
                <Text
                    variant='header5-medium'
                    color="textDark"
                >
                    {localize("cv")}
                </Text>
            </div>
            <div>
                <CoverLetter
                    setSummary={setSummary}
                    setErrors={setErrors}
                    setCity={setCity}
                    summary={summary}
                    errors={errors}
                    city={city}
                />
                <JobExperience
                    setWorkExperience={setWorkExperience}
                    workExperience={workExperience}
                    setErrors={setErrors}
                    errors={errors}
                />
                {renderLine()}
                <Education
                    setEducation={setEducation}
                    setErrors={setErrors}
                    education={education}
                    errors={errors}
                />
                {renderLine()}
                <Hobbies
                    setHobbies={setHobbies}
                    setErrors={setErrors}
                    hobbies={hobbies}
                    errors={errors}
                />
                <Other
                    setSearchForeignLanguagesText={setSearchForeignLanguagesText}
                    setForeignLanguagesTotalData={setForeignLanguagesTotalData}
                    searchForeignLanguagesText={searchForeignLanguagesText}
                    foreignLanguagesTotalData={foreignLanguagesTotalData}
                    setLicenceOrCertificates={setLicenceOrCertificates}
                    setForeignLanguagesPage={setForeignLanguagesPage}
                    setGetForeignLanguages={setGetForeignLanguages}
                    licenceOrCertificates={licenceOrCertificates}
                    foreignLanguagesPage={foreignLanguagesPage}
                    getForeignLanguages={getForeignLanguages}
                    setForeignLanguages={setForeignLanguages}
                    foreignLanguages={foreignLanguages}
                    setAssociations={setAssociations}
                    setCapabilities={setCapabilities}
                    associations={associations}
                    capabilities={capabilities}
                    setErrors={setErrors}
                    errors={errors}
                />
                <ResumeApproval
                    setIsSuitableForDisabledPeopleAdvert={setIsSuitableForDisabledPeopleAdvert}
                    isSuitableForDisabledPeopleAdvert={isSuitableForDisabledPeopleAdvert}
                />
                {renderSaveButton()}
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ıt-was-added")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("person-profile")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <WorkerProfileLeftContainer/>
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
        {renderSuccessDialog()}
    </div>;
};

export default CV;
