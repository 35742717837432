import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    footerBorder: {
        borderTopStyle: "solid"
    },
    footer: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        marginRight: "auto",
        marginLeft: "auto",
        userSelect: "none",
        maxWidth: "1440px",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    footerResponsive: {
        justifyContent: "center",
        boxSizing: "border-box",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "auto",
        textAlign: "center",
        marginLeft: "auto",
        userSelect: "none",
        flexWrap: "wrap",
        display: "flex",
        "@media screen and (min-width: 1200px)": {
            display: "none"
        }
    },
    footerContainer: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "column",
        display: "flex",
        "@media screen and (max-width: 600px)": {
            justifyContent: "center",
            boxSizing: "border-box",
            flexDirection: "column",
            alignItems: "center",
            display: "flex"
        }
    },
    logo: {
        height: "auto",
        width: 100
    },
    logoContainer: {
        flexDirection: "column",
        maxWidth: "242px",
        display: "flex",
        "@media screen and (max-width: 1200px)": {
            alignItems: "center"
        }
    },
    section: {
        flexDirection: "column",
        display: "flex"
    },
    link: {
        textDecoration: "none",
        display: "block",
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    socialIcons: {
        display: "flex",
        "@media screen and (max-width: 1200px)": {
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
        }
    },
    socialIcon: {
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8
        }
    },
    rights: {
        borderTopStyle: "solid",
        textAlign: "center",
        width: "100%"
    },
    renderFooterRight: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        display: "flex",
        "@media screen and (max-width: 600px)": {
            display: "none"
        }
    },
    renderFooterRightResponsive: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        "@media screen and (min-width: 600px)": {
            display: "none"
        }
    }
});
export default useStyles;