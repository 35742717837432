import React, {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    StateCard,
    TextInput,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    useNavigate
} from "react-router-dom";
import {
    ResetPasswordStagesType,
    IsErrorType
} from "./types";
import {
    RESTService
} from "../../../services/restAPI";
import {
    md5
} from "js-md5";
import validator from "validator";
import {
    InstagramIcon,
    FacebookIcon,
    YoutubeIcon,
    MailIcon,
    XIcon,
    Logo
} from "../../../assets/svgr";
import RegisterLoginImage from "../../../assets/img/registerLoginImage.svg";
import {
    getErrorText
} from "../../../utils";

// TODO: All texts will be localized.
const ResetPassword = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces,
    } = IOCoreTheme.useContext();

    const [resetPasswordStage, setResetPasswordStage] = useState<ResetPasswordStagesType>("send-email");
    const [showResendButton, setShowResendButton] = useState(false);
    const [secondCode, setSecondCode] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [time, setTime] = useState(300000);
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [isError, setIsError] = useState<IsErrorType>({
        rePasswordError: null,
        passwordError: null,
        customError: null,
        mailError: null
    });

    const timerContainer = useRef<NodeJS.Timeout | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if(resetPasswordStage === "enter-pw-code") {
            startTimer();
            return () => {
                if (timerContainer.current) {
                    clearInterval(timerContainer.current);
                }
            };
        }
    }, [resetPasswordStage]);

    useEffect(() => {
        if (timerContainer.current) {
            clearInterval(timerContainer.current);
            startTimer();
        }
    }, [time]);

    const startResetPassword = () => {
        if(!validator.isEmail(email)) {
            setIsError({
                mailError: "Hatalı e-posta formatı.",
                customError: null,
                passwordError: null,
                rePasswordError: null
            });
            return;
        }

        RESTService.action("StartResetPassword",{
            mail: email
        })
            .then((res) => {
                setResetPasswordStage("enter-pw-code");
                setIsError({
                    rePasswordError: null,
                    passwordError: null,
                    customError: null,
                    mailError: null
                });
            })
            .catch((err) => {
                console.error("StartResetPassword Err:", err);
                setIsError({
                    customError: err.message,
                    rePasswordError: null,
                    passwordError: null,
                    mailError: null,
                });
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const reSendCode = () => {
        RESTService.action("StartResetPassword",{
            mail: email
        })
            .then((res) => {
                setIsError({
                    rePasswordError: null,
                    passwordError: null,
                    customError: null,
                    mailError: null
                });
            })
            .catch((err) => {
                console.error("StartResetPassword Err:", err);
                setIsError({
                    rePasswordError: null,
                    passwordError: null,
                    customError: err.message,
                    mailError: null
                });
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const sendCode = () => {
        if(!(code && code.length === 6)) {
            setIsError({
                customError: "Hatalı kod",
                rePasswordError: null,
                passwordError: null,
                mailError: null
            });
        }

        RESTService.action("ResetPasswordCheckCode",{
            code: code,
            mail: email
        })
            .then((res) => {
                setSecondCode(res.payload.code);
                setResetPasswordStage("new-password");
                setIsError({
                    rePasswordError: null,
                    passwordError: null,
                    customError: null,
                    mailError: null
                });
            })
            .catch((err) => {
                console.error("ResetPasswordCheckCode Err:", err);
                setIsError({
                    customError: err.message,
                    rePasswordError: null,
                    passwordError: null,
                    mailError: null
                });
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const resetPassword = () => {
        if(password.length < 8 || password.length > 16) {
            setIsError({
                passwordError: localize("password-length-limit"),
                customError: null,
                mailError: null,
                rePasswordError: null,
            });
            return;
        }
        
        if (password !== rePassword) {
            setIsError({
                passwordError: null,
                customError: null,
                mailError: null,
                rePasswordError: localize("register-repeat-password-error"),
            });
            return;
        }
        
        RESTService.action("ResetNewPassword",{
            newPassword: md5(password),
            code: secondCode,
            mail: email,
        })
            .then((res) => {
                setIsError({
                    rePasswordError: null,
                    passwordError: null,
                    customError: null,
                    mailError: null
                });
                navigate("/auth/login");
            })
            .catch((err) => {
                console.error("ResetNewPassword Err:", err);
                setIsError({
                    customError: err.message,
                    rePasswordError: null,
                    passwordError: null,
                    mailError: null
                });
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const startTimer = () => {
        setShowResendButton(false);
        timerContainer.current = setInterval(() => {
            if (time) {
                if (time > 1000) {
                    setTime(prevTime => prevTime - 1000);
                } else {
                    setShowResendButton(true);
                    if (timerContainer.current !== null) {
                        clearInterval(timerContainer.current);
                    }
                    timerContainer.current = null;
                }
            }
        }, 1000);
    };

    const timeConverter = () => {
        const totalSeconds = time / 1000;
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        const second = totalSeconds % 60;

        const resMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const resSecond = second < 10 ? `0${second}` : `${second}`;

        if (hour === 0) {
            return ` ${resMinute}:${resSecond}`;
        }
        return `${hour}:${resMinute}:${resSecond}`;
    };

    const handleResendCode = () => {
        setTime(300000);
        setShowResendButton(false);
        startTimer();
        reSendCode();
    };

    const renderLeftContainer = () => {
        return <div
            className={classes.leftContainer}
            style={{
                borderRightColor: colors.stroke,
                borderRightWidth: borders.line,
                padding: spaces.container
            }}
        >
            <div
                className={classes.leftTopContainer}
            >
                <div className={classes.visualImageContainer}>
                    <img
                        className={classes.visualImage}
                        src={RegisterLoginImage}
                        alt="sideVisualImage"
                    />
                </div>
                <div 
                    className={classes.visualImageContainerText}
                    style={{
                        marginTop: spaces.containerLarge
                    }}
                >
                    <Text 
                        variant="body2-regular"
                    >
                        {localize("with-isinolacak-you-will-reach-your-career-goals")}
                    </Text>
                </div>
            </div>
            <div 
                className={classes.footerTopContainer}
            >
                {renderFooter()}
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
        >
            {
                resetPasswordStage === "send-email" ? 
                    renderMailContent()
                    : resetPasswordStage === "enter-pw-code" ?
                        renderOTPContent()
                        :
                        renderPasswordContent()
            }
        </div>;
    };

    const renderMailContent = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: spaces.container
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline * 1.5
                }}
            >
                <Logo size={120} />
            </div>
            <StateCard
                icon={() => {
                    return <MailIcon size={65} color={colors.textSecondary} />;
                }}
                title={localize("forgot-password")}
                content={localize("please-enter-your-email")}
            />
            {renderMailInput()}
            <div
                className={classes.buttonContainer}
                style={{
                    marginBottom: spaces.inline * 2.5,
                    gap: spaces.inline * 1.5
                }}
            >
                <Button
                    spreadBehaviour="stretch"
                    color="secondary"
                    title={localize("send")}
                    size="large"
                    disabled={!email.length}
                    onClick={() => {
                        startResetPassword();
                    }}
                />
            </div>
        </div>;
    };

    const renderOTPContent = () => {    
        return <div
            className={classes.contentContainer}
            style={{
                padding: spaces.container
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline * 1.5
                }}
            >
                <Logo size={120} />
            </div>
            {renderOTPInput()}
            <div
                className={classes.buttonContainer}
                style={{
                    marginBottom: spaces.inline * 2.5,
                    gap: spaces.inline * 1.5
                }}
            >
                <Button
                    spreadBehaviour="stretch"
                    color="secondary"
                    title={localize("send")}
                    size="large"
                    disabled={code.length !== 6}
                    onClick={() => {
                        sendCode();
                    }}
                />
            </div>
        </div>;
    };

    const renderPasswordContent = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: spaces.container
            }}
        >
            <div
                style={{
                    marginBottom: spaces.inline * 1.5
                }}
            >
                <Logo size={120} />
            </div>
            <StateCard
                icon={() => {
                    return <MailIcon size={65} color={colors.textSecondary} />;
                }}
                title={localize("forgot-password")}
                content={localize("enter-new-password")}
            />
            {renderPasswordInput()}
            <div
                className={classes.buttonContainer}
                style={{
                    marginBottom: spaces.inline * 2.5,
                    gap: spaces.inline * 1.5
                }}
            >
                <Button
                    spreadBehaviour="stretch"
                    color="secondary"
                    title={localize("send")}
                    size="large"
                    disabled={!(password.length && rePassword.length)}
                    onClick={() => {
                        resetPassword();
                    }}
                />
            </div>
        </div>;
    };

    const renderMailInput = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <TextInput
                infoText={isError.mailError ? isError.mailError : ""}
                isError={isError.mailError ? true : false}
                spreadBehaviour="stretch"
                placeholder={localize("e-mail")}
                id="mail-input"
                onChangeText={(value) => {
                    setEmail(value);
                }}
            />
            {isError.customError ?
                <Text
                    variant="body2-regular"
                    color="accent"
                >
                    {isError.customError}
                </Text>
                :
                null
            }
        </div>;
    };

    const renderOTPInput = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline
            }}
        >
            <Text
                variant='body2-semiBold'
                color='textDark'
            >
                {localize("code-input")}
            </Text>
            <div
                className={classes.codeContainer}
                style={{
                    marginBottom: spaces.inline * 4
                }}
                onClick={() => {
                    inputRef.current?.focus();
                } }
            >
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[0]}</Text>
                </div>
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[1]}</Text>
                </div>
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[2]}</Text>
                </div>
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[3]}</Text>
                </div>
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[4]}</Text>
                </div>
                <div
                    className={classes.codeBox}
                    style={{
                        borderRadius: radiuses.half,
                        borderColor: colors.stroke,
                        borderWidth: borders.line,
                        padding: spaces.container
                    }}
                >
                    <Text variant='body-medium'>{code[5]}</Text>
                </div>
            </div>
            <input
                className={classes.input}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    //handleSMSOTP;
                    }
                } }
                ref={inputRef}
                maxLength={6}
                onChange={(e) => {
                    setCode(e.target.value);
                } }
                type="text" />
            {
                showResendButton ?
                    <Button
                        onClick={() => {
                            handleResendCode();
                            // handleSMSOTP
                        }}
                        title='Şifreyi Yeniden Gönder'
                        variant='ghost'
                    />
                    :
                    <Text
                        variant='body2-medium'
                        color='textGrey'
                    >
                        {localize("remaining-time")}
                        <Text
                            variant='body2-medium'
                            color='primary'
                        >
                            {timeConverter()}
                        </Text>
                    </Text>
            }
            {isError.customError ?
                <Text
                    variant="body2-regular"
                    color="accent"
                >
                    {isError.customError}
                </Text>
                :
                null
            }
        </div>;
    };

    const renderPasswordInput = () => {
        return <div
            className={classes.inputContainer}
            style={{
                marginBottom: spaces.inline * 2.5,
                gap: spaces.inline * 1.5
            }}
        >
            <TextInput
                infoText={isError.passwordError ? isError.passwordError : ""}
                isError={isError.passwordError ? true : false}
                spreadBehaviour="stretch"
                placeholder={localize("password")}
                id="password-input"
                password
                onChangeText={(value) => {
                    setPassword(value);
                }}
            />
            <TextInput
                infoText={isError.rePasswordError ? isError.rePasswordError : ""}
                isError={isError.rePasswordError ? true : false}
                spreadBehaviour="stretch"
                placeholder={localize("password-verification")}
                id="re-password-input"
                password
                onChangeText={(value) => {
                    setRePassword(value);
                }}
            />
            {isError.customError ?
                <Text
                    variant="body2-regular"
                    color="accent"
                >
                    {isError.customError}
                </Text>
                :
                null
            }
        </div>;
    };

    const renderFooter = () => {
        return <div
            className={classes.footerContainer}
            style={{
                gap: spaces.contentLarge
            }}
        >
            <Text 
                variant='body4-regular'
                color='secondary'
            >
                {localize("about-us")}
            </Text>
            <Text 
                variant='body4-regular'
                color='secondary'>
                {localize("help")}
            </Text>
            <Text 
                variant='body4-regular'
                color='secondary'
            >
                {localize("data-policy")}
            </Text>
            <div
                className={classes.footerButtonsContainer}
                style={{
                    gap: spaces.item
                }}
            >
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                ></div>
                <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <InstagramIcon 
                        size={15}
                        color="#fff"
                    />
                </div> <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <YoutubeIcon 
                        size={15}
                        color="#fff"
                    />
                </div> <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <FacebookIcon 
                        size={15}
                        color="#fff"
                    />
                </div> <div
                    className={classes.footerButton}
                    style={{
                        backgroundColor: colors.secondary,
                        borderRadius: radiuses.quarter
                    }}
                    onClick={() => {
                        
                    }}
                >
                    <XIcon 
                        size={15}
                        color="#fff"
                    />
                </div>
                <div
                    className={classes.verticalLine}
                    style={{
                        backgroundColor: colors.secondary
                    }}
                ></div>
            </div>
            <Text 
                variant='body4-regular'
                color='secondary'>isinolacak.
            com
            </Text>
        </div>;
    };

    return <div
        className={classes.container}
    >
        {renderLeftContainer()}
        {renderRightContainer()}
    </div>;
};
export default ResetPassword;
