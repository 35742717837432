import {
    ReceiveMessageResponseType,
    ReceiveMessageRequestType
} from "./types";
import {
    Socket
} from "socket.io-client";

const ReceiveMessage = async (
    params: ReceiveMessageRequestType,
    socket: Socket
): Promise<ReceiveMessageResponseType> => {
    socket.emit("receive-message", {
        messageIDs: [params.messageID]
    });

    return {
        message: "message-sended",
        code: 201
    };
};
export default ReceiveMessage;
