import React, {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    CircleIcon,
    InfoIcon
} from "../../../../../../../../assets/svgr";
import {
    CompanyNumberTypes
} from "./types";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    getErrorText 
} from "../../../../../../../../utils";

const CompanyPhoneNumberSmsOTP = ({
    token
}: CompanyNumberTypes) =>  {
    const classes = useStyles();

    const {
        localize 
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [showResendButton, setShowResendButton] = useState(false);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [isCodeActive, setCodeActive] = useState(true);
    const [error, setError] = useState(false);
    const [time, setTime] = useState(300000);
    const [code, setCode] = useState("");

    const timerContainer = useRef<NodeJS.Timeout | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        startTimer();
        return () => {
            if (timerContainer.current) {
                clearInterval(timerContainer.current);
            }
        };
    }, []);

    useEffect(() => {
        if (timerContainer.current) {
            clearInterval(timerContainer.current);
            startTimer();
        }
    }, [time]);

    const startTimer = () => {
        setShowResendButton(false);
        timerContainer.current = setInterval(() => {
            if (time) {
                if (time > 1000) {
                    setTime(prevTime => prevTime - 1000);
                } else {
                    setCodeActive(false);
                    if (timerContainer.current !== null) {
                        clearInterval(timerContainer.current);
                    }
                    timerContainer.current = null;
                    setShowResendButton(true);
                }
            }
        }, 1000);
    };

    const timeConverter = () => {
        const totalSeconds = time / 1000;
        const totalMinutes = Math.floor(totalSeconds / 60);
        const hour = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        const second = totalSeconds % 60;

        const resMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const resSecond = second < 10 ? `0${second}` : `${second}`;

        if (hour === 0) {
            return <Text
                variant='body2-medium'
                color='textGrey'
            >
                {localize("your-remaining-time-for-your-password")}: 
                <Text
                    variant='body2-medium'
                    color='primary'
                >
                    {`${resMinute}:${resSecond}`}
                </Text>
            </Text>;
        }
        return `${hour}:${resMinute}:${resSecond}`;
    };

    const focusInput = () => {
        inputRef.current?.focus();
    };
    
    const handleResendCode = () => {
        setTime(300000);
        setCodeActive(true);
        startTimer();
        setError(false);
    };

    const handleChangePhoneNumber = () => {
        setLoadingButton(true);
        RESTService.action("CheckChangeCompanyPhoneNumberCode", {
            token: token,
            code: code
        })
            .then((res) => {
                setIsVisibleDialog(true);
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("CheckChangeCompanyPhoneNumberCode Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSaveButton = () => {
        return <div
            className={classes.saveButton}
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                onClick={() => handleChangePhoneNumber()}
                title={localize("verify")}
                spreadBehaviour="stretch"
                loading={loadingButton}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderInputs = () => {
        return <div
            className={classes.inputContainer}
        >
            <Text
                style={{
                    marginRight: spaces.content * 3.5,
                    marginBottom: spaces.content
                }}
                variant='body3-semiBold'
                color='textDark'
            >
                {localize("enter-the-six-digit-password-sent-via-sms...")}
            </Text>
            <div
                className={classes.codeContainer}
                onClick={focusInput}
                style={{
                    marginBottom: spaces.content * 2,
                    gap: spaces.inline / 3
                }}
            >
                {[...Array(6)].map((_, index) => (
                    <div
                        className={classes.codeBox}
                        key={index}
                        style={{
                            borderColor: error ? colors.error : colors.textGrey,
                            borderWidth: spaces.inline / 6,
                            borderRadius: spaces.inline
                        }}
                    >
                        {code[index]}
                    </div>
                ))}
            </div>
            <input
                className={classes.input}
                ref={inputRef}
                minLength={6}
                maxLength={6}
                onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 6) {
                        setCode(inputValue);
                    }
                }}
                type="text"
                style={error ? {
                    borderColor: colors.error 
                } : {
                }}
            />
            {error && (
                <div
                    className={classes.errorText}
                    style={{
                        marginBottom: spaces.content
                    }}
                >
                    <InfoIcon />
                    <Text
                        variant='body4-regular'
                        color='error'
                        style={{
                            marginLeft: spaces.content / 3,
                        }}
                    >
                        {localize("you-have-logged-in-incorrectly-please-try-again")}
                    </Text>
                </div>
            )}
            {!showResendButton && <div>{timeConverter()}</div>}
            {showResendButton && (
                <div className={classes.refleshButton}>
                    <Button
                        onClick={() => {
                            handleResendCode();
                        }}
                        title={localize("resend-password")}
                        variant='ghost'
                    />
                </div>
            )}
        </div>;
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ıt-was-edited-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };

    return <div>
        <Text
            variant='header5-medium'
            color='textDark'
        >
            {localize("phone-number-update")}
        </Text>
        <div
            className={classes.container}
            style={{
                marginTop: spaces.container
            }}
        >
            {renderInputs()}
        </div>
        {renderSaveButton()}
        {renderSuccessDialog()}
    </div>;
};

export default CompanyPhoneNumberSmsOTP;
