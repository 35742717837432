import React, {
    useEffect,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme, 
    StateCard,
    Loading,
    Dialog,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../services/restAPI";
import moment from "moment";
import {
    AdvertsForEmployerFilterType,
    SubPageType
} from "./types";
import {
    StickerNoUserIcon,
    StickerUserIcon,
    FilledClockIcon,
    AddCircleIcon,
    MyActionsIcon,
    CalenderIcon,
    StopTimeIcon,
    EyeOpenIcon
} from "../../../../assets/svgr";
import {
    AdvertCardForEmployer
} from "../../../../components";
import {
    useNavigate
} from "react-router-dom";
import emptyProfile from "../../../../assets/img/isinolacakMiniWithPadding.png";
import {
    GetMyActionsEmployerPayloadTypes,
    GetMyActionsEmployerRequestType
} from "../../../../services/restAPI/actions/getMyActionsEmployer/types";
import {
    ActionButtonTypes, 
    StickerObjectType
} from "../../../../components/advertCardForEmployer/types";

let STATUS_STICKERS: Array<StickerObjectType> = [
    {
        key: "noApplication",
        translationKey: "no-aplicant-header",
        translationKeyParam: false,
        color: "applicationNumberStickerFrontColor",
        backColor: "applicationNumberStickerBackColor"
    },
    {
        key: "numberOfApplicants",
        translationKey: "aplicant-card-for-employer-sticker-number-of-aplicants",
        translationKeyParam: true,
        color: "applicationNumberStickerFrontColor",
        backColor: "applicationNumberStickerBackColor"
    },
    {
        key: "activeAdvert",
        translationKey: "active-advert",
        translationKeyParam: false,
        color: "activeAdvertStickerFrontColor",
        backColor: "activeAdvertStickerBackColor"
    },
    {
        key: "hiddenAdvert",
        translationKey: "hidden-advert",
        translationKeyParam: false,
        color: "hiddenAdvertStickerFrontColor",
        backColor: "hiddenAdvertStickerBackColor"
    },
    {
        key: "lastUpdate",
        translationKey: "advert-last-update-date",
        translationKeyParam: true,
        color: "advertDateStickerFrontColor",
        backColor: "advertDateStickerBackColor"
    },
    {
        key: "removedFromPublicationAt",
        translationKey: "advert-removed-from-publication-date",
        translationKeyParam: true,
        color: "negativeAdvertDateStickerFrontColor",
        backColor: "negativeAdvertDateStickerBackColor"
    },
    {
        key: "endDate",
        translationKey: "advert-end-date",
        translationKeyParam: true,
        color: "negativeAdvertDateStickerFrontColor",
        backColor: "negativeAdvertDateStickerBackColor"
    },
    {
        key: "publicationDate",
        translationKey: "advert-publication-date",
        translationKeyParam: true,
        color: "advertDateStickerFrontColor",
        backColor: "advertDateStickerBackColor"
    },
    {
        key: "creationDate",
        translationKey: "draft-advert-creation-date",
        translationKeyParam: true,
        color: "advertDateStickerFrontColor",
        backColor: "advertDateStickerBackColor"
    }
];

const EmployerMyActions = () => {
    const navigate = useNavigate();

    const classes = useStyles();
    
    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [filter, setFilter] = useState<AdvertsForEmployerFilterType>(undefined);
    const [data, setData] = useState<Array<GetMyActionsEmployerPayloadTypes>>([]);
    const [advertIsEndOfData, setAdvertIsEndOfData] = useState(false);
    const [advertIsLoadMore, setAdvertIsLoadMore] = useState(false);
    const [subPage, setSubPage] = useState<SubPageType>("my-current-ads");
    const [searchPage, setSearchPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [transactionAlert, setTransactionAlert] = useState({
        showDialog: false,
        transactions: "",
        advertId:  ""
    });

    useEffect(() => {
        getAdverts();
    },[]);
    
    const getAdverts = (selectedFilter?: string, isLoadMore?: boolean) => {
        if(selectedFilter && selectedFilter.length) {
            setAdvertIsEndOfData(true);
        }

        let params: GetMyActionsEmployerRequestType = {
            page: searchPage,
        };

        if(selectedFilter && selectedFilter.length) {
            params.filter = selectedFilter;
        }

        if(isLoadMore) {
            params.page = searchPage + 1;
        }

        if(filter) {
            params.filter = filter;
        }

        if(!isLoadMore) {
            setLoading(true);
        }

        RESTService.action("GetMyActionsEmployer", params)
            .then((res) => {
                let _getAdverts = JSON.parse(JSON.stringify(data));
                let newAdverts = res.payload.filter(newItem => !_getAdverts.some((oldItem: { _id: string; }) => oldItem._id === newItem._id));
                if(isLoadMore) {
                    setData([..._getAdverts, ...newAdverts]);
                    setSearchPage(params.page);
                } else {
                    setData(res.payload);
                }
                setLoading(false);
                setAdvertIsLoadMore(false);

                if(res.payload.length < 10) {
                    setAdvertIsEndOfData(true);
                }

            })
            .catch((err) => {
                console.error("RemoveAdvert Err:", err);
            });
    };

    const addToFavoriteFunction = (id: string) => {
        RESTService.action("AddAdvertToFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data = JSON.parse(JSON.stringify(data));
                const currentIndex = data.findIndex((item: { _id: string; }) => {
                    return item._id === id;
                });
                _data[currentIndex].isFavorite=true;
                setData(_data);
            })
            .catch((err) => {
                console.error("AddAdvertToFavorite Err:", err);
            });
    };

    const removeAdvertFromFavoriteFunction = (id: string) => {
        RESTService.action("RemoveAdvertFromFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data = JSON.parse(JSON.stringify(data));
                const currentIndex = data.findIndex((item: { _id: string; }) => {
                    return item._id === id;
                });
                _data[currentIndex].isFavorite = false;
                if (currentIndex !== -1 && subPage === "saved") {
                    _data.splice(currentIndex, 1);
                }
                setData(_data);
            })
            .catch((err) => {
                console.error("RemoveAdvertFromFavorite Err:", err);
            });
    };

    const createDraftAdvertFunction = (id: string) => {
        RESTService.action("CreateDraftAdvert",{
            advertID : id
        })
            .then((res) => {
                setTransactionAlert({
                    transactions: "draft-created",
                    advertId: id,
                    showDialog: true
                });
            })
            .catch((err) => {
                console.error("CreateDraftAdvert Err:", err);
            });
    };

    const removeAdvertFromPublicationFunction = (id: string) => {
        RESTService.action("RemoveAdvert",{
            advertID: id
        })
            .then((res) =>{
                let _data = JSON.parse(JSON.stringify(data));
                let currentAdvertIndex = _data.findIndex((e: GetMyActionsEmployerPayloadTypes) => e._id === id);
                if (currentAdvertIndex !== -1) {
                    _data.splice(currentAdvertIndex, 1);
                }
                setData(_data); 
            })
            .catch((err) =>{
                console.error("RemoveAdvertFromPublication Err:", err);
            });
    };

    const deleteAdvertFunction = (id: string) => {
        RESTService.action("DeleteAdvert",{
            advertID: id
        })
            .then((res) =>{
                let _data = JSON.parse(JSON.stringify(data));
                let currentAdvertIndex = _data.findIndex((e: GetMyActionsEmployerPayloadTypes) => e._id === id);
                if (currentAdvertIndex !== -1) {
                    _data.splice(currentAdvertIndex, 1);
                }
                setData(_data); 
            })
            .catch((err) =>{
                console.error("DeleteAdvert Err:", err);
            });
    };

    const republishAdvertFunction = (id: string) => {
        RESTService.action("RepublishAdvert",{
            advertID: id
        })
            .then((res) =>{
                let _data = JSON.parse(JSON.stringify(data));
                let currentAdvertIndex = _data.findIndex((e: GetMyActionsEmployerPayloadTypes) => e._id === id);
                if (currentAdvertIndex !== -1) {
                    _data.splice(currentAdvertIndex, 1);
                }
                setData(_data); 
            })
            .catch((err) =>{
                console.error("RepublishAdvert Err:", err);
            });
    };

    const setAdvertStickers = (item: any, arr: Array<StickerObjectType>) => {
        if(subPage === "my-current-ads") {
            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "activeAdvert");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.icon = () => <EyeOpenIcon size={12}/>;
            arr.push(newSticker);

            if(item.applicationCount > 0) {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "numberOfApplicants");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.param = item.applicationCount;
                newSticker.icon = () => <StickerUserIcon size={12}/>;
                arr.push(newSticker);
            } else {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "noApplication");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.icon = () => <StickerNoUserIcon size={12}/>;
                arr.push(newSticker);
            }
        }

        if(subPage === "my-draft-ads") {
            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "creationDate");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.param = moment(item.createdAt).format("DD.MM.YYYY").toString();
            newSticker.icon = () => <CalenderIcon size={12}/>;
            arr.push(newSticker);

            let stickerIndex2 = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "lastUpdate");
            let newSticker2 = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex2]));
            newSticker2.param = moment(item.updatedAt).format("DD.MM.YYYY").toString();
            newSticker2.icon = () => <FilledClockIcon size={12}/>;
            arr.push(newSticker2);
        }

        if(subPage === "my-past-ads") {
            if(item.applicationCount > 0) {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "numberOfApplicants");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.param = item.applicationCount;
                newSticker.icon = () => <StickerUserIcon size={12}/>;
                arr.push(newSticker);
            } else {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "noApplication");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.icon = () => <StickerNoUserIcon size={12}/>;
                arr.push(newSticker);
            }

            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "endDate");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.param = moment(item.endDate).format("DD.MM.YYYY").toString();
            newSticker.icon = () => <StopTimeIcon size={12}/>;
            arr.push(newSticker);
        }

        if(subPage === "takedowns") {
            if(item.applicationCount > 0) {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "numberOfApplicants");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.param = item.applicationCount;
                newSticker.icon = () => <StickerUserIcon size={12}/>;
                arr.push(newSticker);
            } else {
                let _stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "noApplication");
                let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[_stickerIndex]));
                newSticker.icon = () => <StickerNoUserIcon size={12}/>;
                arr.push(newSticker);
            }

            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "removedFromPublicationAt");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.param = moment(item.unpublishDate).format("DD.MM.YYYY").toString();
            newSticker.icon = () => <StopTimeIcon size={12}/>;
            arr.push(newSticker);
        }

        if(subPage === "saved") {
            let stickerIndex = STATUS_STICKERS.findIndex((e: StickerObjectType) => e.key === "lastUpdate");
            let newSticker = JSON.parse(JSON.stringify(STATUS_STICKERS[stickerIndex]));
            newSticker.param = moment(item.updatedAt).format("DD.MM.YYYY").toString();
            newSticker.icon = () => <CalenderIcon size={12}/>;
            arr.push(newSticker);
        }
    };

    const renderHeaderChipContainer = () => {
        return <div
            className={classes.renderHeaderChipContainer}
            style={{
                marginTop: spaces.content * 2,
            }}
        >
            <div
                className={classes.chipContainer}
                style={{
                    marginTop: spaces.content * 2,
                    padding: spaces.content,
                    gap: spaces.content * 1,
                }}
            >
                <Chip
                    title={localize("my-current-ads")}
                    selected={subPage === "my-current-ads"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if(subPage === "my-current-ads") {
                            setSubPage(null);
                            getAdverts("current");
                            return;
                        }
                        getAdverts("current");
                        setSubPage("my-current-ads");
                    }}
                />
                <Chip
                    title={localize("my-past-ads")}
                    selected={subPage === "my-past-ads"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if(subPage === "my-past-ads") {
                            setSubPage(null);
                            getAdverts("past");
                            return;
                        }
                        getAdverts("past");
                        setSubPage("my-past-ads");
                    }}
                />
                <Chip
                    title={localize("takedowns")}
                    selected={subPage === "takedowns"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if(subPage === "takedowns") {
                            setSubPage(null);
                            getAdverts("removed");
                            return;
                        }
                        getAdverts("removed");
                        setSubPage("takedowns");
                    }}
                />
                <Chip
                    title={localize("my-draft-ads")}
                    selected={subPage === "my-draft-ads"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if(subPage === "my-draft-ads") {
                            setSubPage(null);
                            getAdverts("templates");
                            return;
                        }
                        getAdverts("templates");
                        setSubPage("my-draft-ads");
                    }}
                />
                <Chip
                    title={localize("saved")}
                    selected={subPage === "saved"}
                    disabled={false}
                    size="large"
                    onClick={() => {
                        if(subPage === "saved") {
                            setSubPage(null);
                            getAdverts("favs");
                            return;
                        }
                        getAdverts("favs");
                        setSubPage("saved");
                    }}
                />
            </div>
            <div
                className={classes.addButton}
                style={{
                    marginTop: spaces.container * 1.5,
                }}
            >
                <Button
                    icon={() => <AddCircleIcon />}             
                    onClick={() => {
                        navigate("/app/newAdvert");
                    }}
                    size="large"
                />
            </div>
        </div>;
    };

    const advertCard = () => {
        if(loading) {
            return <div
                className={classes.loadingContainer}
                style={{
                    marginTop: spaces.container
                }}
            >
                <Loading/>
            </div>;
        }
        
        if (!data?.length) {
            return <div
                className={classes.stateCard}
                style={{
                    marginTop: spaces.content * 5,
                    color: colors.textDark
                }}
            >
                <StateCard
                    buttonProps={{
                        onClick: () => {navigate("/app/newAdvert");},
                        icon: () => <AddCircleIcon />,
                        title: localize("post-an-ad"),
                        spreadBehaviour: "stretch",
                        iconDirection: "left",
                        color: "primary",
                        size: "large"
                    }}
                    content={localize("you-will-view-your-activities-for-your-live-advertisements-on-this-page")}
                    title={localize("this-place-is-empty-right-now")}
                    icon={() => <MyActionsIcon />}
                    isAction={true}
                />
            </div>;
        }

        let _actionType:ActionButtonTypes = subPage === "my-current-ads" ?
            "manage-advert":
            subPage=== "my-past-ads" ?
                "manage-advert" :
                subPage ==="takedowns" ?
                    "republish" :
                    subPage==="saved" ?
                        "manage-advert"
                        :
                        "create-advert-from-draft";

        return <div
            className={classes.cardContainer}
            style={{
                marginTop: spaces.content*3,
                gap: spaces.content * 2
            }}
        >
            {data.map((item, index) => {
                let advertStickers: Array<StickerObjectType> = [];

                setAdvertStickers(item, advertStickers);
                
                return  <AdvertCardForEmployer
                    key={"advert-card-employer-" + index}
                    jobImage={emptyProfile}
                    experience={item.experienceLevel.localizedText}
                    contractType={item.contractType.localizedText}
                    workingType={item.workingType.localizedText}
                    title={item.occupation.localizedText}
                    companyName={item.companyInfo.title}
                    city={item.city.localizedText}
                    stickers={advertStickers}
                    actionType={_actionType}
                    actionOnClick={() => {
                        if(subPage === "my-current-ads" || subPage === "my-past-ads" || subPage === "saved"){
                            navigate("/app/candidateManagement", {
                                state: {
                                    advertID: item._id
                                }
                            });
                        }
                        if(subPage==="takedowns"){
                            setTransactionAlert({
                                transactions: "republish",
                                advertId: item._id,
                                showDialog: true
                            });
                        }
                        if(subPage === "my-draft-ads"){
                            navigate("/app/editAdvert", {
                                state: {
                                    advertID: item._id
                                }
                            });
                        }
                    }}
                    onClick={() => {
                        navigate("/app/jobDetails",{
                            state: {
                                advertID: item._id
                            }
                        });
                    }}
                    transactions={
                        subPage === "my-current-ads" ?
                            [
                                {
                                    title: item.isFavorite ? localize("remaove-from-saved") : localize("save"), 
                                    onClick: () =>{
                                        if(item.isFavorite){
                                            removeAdvertFromFavoriteFunction(item._id);
                                        }else{
                                            addToFavoriteFunction(item._id);
                                        }
                                    }
                                },
                                {
                                    title: localize("take-down"), 
                                    onClick: () =>{
                                        setTransactionAlert({
                                            transactions: "take-down",
                                            advertId: item._id,
                                            showDialog: true
                                        });
                                    }
                                },
                                {
                                    title: localize("create-draft"), 
                                    onClick: () =>{
                                        createDraftAdvertFunction(item._id);
                                    }
                                },
                                {
                                    title: localize("delete-advert"), 
                                    onClick: () =>{
                                        setTransactionAlert({
                                            transactions: "delete-advert",
                                            advertId: item._id,
                                            showDialog: true
                                        });
                                    }
                                }
                            ]
                            :
                            subPage === "takedowns" ?
                                [
                                    {
                                        title: localize("save"), 
                                        onClick: () =>{
                                            addToFavoriteFunction(item._id);
                                        }
                                    },
                                    {
                                        title: localize("republish"), 
                                        onClick: () =>{
                                            setTransactionAlert({
                                                transactions: "republish",
                                                advertId: item._id,
                                                showDialog: true
                                            });
                                        }
                                    },
                                    {
                                        title: localize("create-draft"), 
                                        onClick: () =>{
                                            createDraftAdvertFunction(item._id);
                                        }
                                    },
                                    {
                                        title: localize("delete-advert"), 
                                        onClick: () =>{
                                            setTransactionAlert({
                                                transactions: "delete-advert",
                                                advertId: item._id,
                                                showDialog: true
                                            });
                                        }
                                    }
                                ]
                                : 
                                subPage === "saved" ?
                                    [
                                        {
                                            title: localize("remaove-from-saved"), 
                                            onClick: () =>{
                                                removeAdvertFromFavoriteFunction(item._id);
                                            }
                                        },
                                    ]
                                    :
                                    []
                                
                    }
                />;
            }
            )}
        </div>;
    };

    const renderMyActions = () => {
        return <div
            style={{
                marginTop: spaces.content * 2
            }}
        >
            <Text
                variant="header5-medium"
                color="textDark"
                style={{
                    marginLeft: spaces.content * 1,
                }}
            >
                {localize("overview")}
            </Text>
            {renderHeaderChipContainer()}
            {advertCard()}
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.grey25,
            padding: spaces.content * 3
        }}
    >
        {renderMyActions()}
        <Dialog
            isVisible={transactionAlert.showDialog}
            headerComponent={
                transactionAlert.transactions === "take-down" ?
                    <Text
                        variant="header5-medium"
                    >
                        {localize("are-you-sure-you-want-to-unpublish")}
                    </Text>
                    : 
                    transactionAlert.transactions === "delete-advert" ?
                        <Text
                            variant="header5-medium"
                        >
                            {localize("are-you-sure-you-want-to-delete-the-advert")}
                        </Text>
                        :
                        transactionAlert.transactions === "republish" ?
                            <Text
                                variant="header5-medium"
                            >
                                {localize("are-you-sure-you-want-to-republish")}
                            </Text>
                            :
                            <Text
                                variant="header5-medium"
                            >
                                {localize("draft-created")}
                            </Text>
            }
            variant="yes-no"
            bottomComponent={<div
                className={classes.dialogBottom}
                style={{
                    gap: spaces.containerXLarge
                }}
            >
                {
                    transactionAlert.transactions !== "draft-created" ?
                        <Button
                            title={localize("give-up")}
                            variant="outline"
                            spreadBehaviour="stretch"
                            onClick={() => {
                                setTransactionAlert({
                                    showDialog: false,
                                    transactions: "",
                                    advertId: ""
                                });
                            }}
                        />
                        :
                        null
                }
                <Button
                    spreadBehaviour="stretch"
                    title={
                        transactionAlert.transactions === "take-down" ?
                            localize("remove")
                            :
                            transactionAlert.transactions ==="delete-advert" ?
                                localize("delete")
                                :
                                transactionAlert.transactions ==="republish" ?
                                    localize("publish") 
                                    :
                                    localize("ok")
                    }
                    onClick={() => {
                        if(transactionAlert.transactions === "take-down"){
                            removeAdvertFromPublicationFunction(transactionAlert.advertId);
                        }
                        if(transactionAlert.transactions === "delete-advert"){
                            deleteAdvertFunction(transactionAlert.advertId);
                        }
                        if(transactionAlert.transactions === "republish"){
                            republishAdvertFunction(transactionAlert.advertId);
                        }
                        setTransactionAlert({
                            showDialog: false,
                            transactions: "",
                            advertId: ""
                        });
                    }}
                />
            </div>}
        />
    </div>;
};

export default EmployerMyActions;
