import {
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    useStyles 
} from "./styles";
import {
    StickerObjectType, 
    IAdvertCardProps,
} from "./types";
import IsinOlacakAdvertLogo from "../../../../../assets/img/isinolacakMiniWithPadding.png";
import BoostsTypeStickers from "../../../../../components/boostTypeStickers";

const FeaturedAdvertCard = ({
    companyName,
    jobImage,
    stickers,
    onClick,
    style,
    title,
    city
}: IAdvertCardProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const informationsContainer = () => {
        return <div
            className={classes.informationTextContainer}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                className={classes.informationTitleStyle}
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {title}
            </Text>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {companyName}
                </Text>
            </div>
            <div>
                <Text
                    className={classes.informationTextStyle}
                    variant="body4-regular"
                    color="textGrey"
                >
                    {`${city}`}
                </Text>
            </div>
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                paddingBottom: spaces.container / 3,
                paddingRight: spaces.container * 0.75,
                paddingLeft: spaces.container * 0.75,
                paddingTop: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
        >
            <div>
                <img
                    src={jobImage ?? IsinOlacakAdvertLogo}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container,
                        borderColor: colors.stroke
                    }}
                />
            </div>
            {informationsContainer()}
        </div>;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 3,
                paddingTop: spaces.content / 3,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                paddingLeft: spaces.content,
                gap: spaces.inline / 2
            }}
        >
            {
                stickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <div
                        key={item.key}
                    >
                        <BoostsTypeStickers
                            title={localizedText}
                            titleColor={item.color}
                            color={item.backColor}
                            icon={item.icon}
                            itemKey={item.key}
                        />
                    </div>;
                })
            }
        </div>;
        /* if (!stickers || !stickers.length) {
            return null;
        }

        const newStickersData: Array<StickerObjectType | undefined> = stickers.map(propsStickerData => {
            let curSticker;
            if(propsStickerData.color === "featuredAdFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-featuredAds"];
            }

            if(propsStickerData.color === "newAdFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-newAd"];
            }

            if(propsStickerData.color === "announcementFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-announcement"];
            }

            if(propsStickerData.color === "trustedFrontColor") {
                curSticker = STICKER_DATA["advert-sticker-trusted"];
            }

            return curSticker;
        });

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 1.4,
                paddingTop: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                paddingLeft: spaces.content,
                gap: spaces.inline / 2
            }}
        >
            {
                newStickersData.map((item: StickerObjectType | undefined, index: number) => {
                    return <Fragment key={item?.titleKey + id}>
                        {renderItem({
                            item,
                            index
                        })}
                    </Fragment>;
                })
            }
        </div>; */
    };

    return <div
        className={classes.container}
        onClick={onClick}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line
        }}
    >
        {renderInformations()}
        {stickers && renderTags()}
    </div>;
};

export default FeaturedAdvertCard;