import CheckChangeCompanyPhoneNumberCode from "./checkChangeCompanyPhoneNumberCode";
import GetAdvertWithDetailForEmployer from "./getAdvertWithDetailForEmployer";
import GetAdvertWithPAQForEmployer from "./getAdvertWithPAQForEmployer";
import GetWorkerProfileForEmployer from "./getWorkerProfileForEmployer";
import CheckChangePhoneNumberCode from "./checkChangePhoneNumberCode";
import GetAdvertsDataForDashboard from "./getAdvertsDataForDashboard";
import CheckChangeCompanyMailCode from "./checkChangeCompanyMailCode";
import GetCompanyProfileForWorker from "./getCompanyProfileForWorker";
import GetAdvertDetailWithoutAuth from "./getAdvertDetailWithoutAuth";
import RemoveArchiveConversation from "./removeArchiveConversation";
import GetAplicationsForEmployer from "./getAplicationsForEmployer";
import EmployerHandleApplication from "./employerHandleApplication";
import RemoveAdvertFromFavorite from "./removeAdvertFromFavorite";
import ExportApplicantDocumentInBulk from "./exportDocumentInBulk";
import GetStaticDataWithoutAuth from "./getStaticDataWithoutAuth";
import ChangeCompanyPhoneNumber from "./changeCompanyPhoneNumber";
import GetConversationUserInfo from "./getConversationUserInfo";
import HandleSubmittedDocument from "./handleSubmittedDocument";
import ExportApplicantDocument from "./exportApplicantDocument";
import GetPerformanceTestForEmployer from "./getPTForEmployer";
import GetBlogsForLandingPage from "./getBlogsForLandingPage";
import DeleteDocumentTemplate from "./deleteDocumentTemplate";
import ResetPasswordCheckCode from "./resetPasswordCheckCode";
import GetMySubscriptionData from "./getMySubscriptionData";
import GetAdvertsWithoutAuth from "./getAdvertsWithoutAuth";
import GetPopularOccupations from "./getPopularOccupations";
import GetMyUserPreferences from "./getMyUserPreferences";
import GetDocumentTemplates from "./getDocumentTemplates";
import GetCurrentJobSeekers from "./getCurrentJobSeekers";
import EditDocumentTemplate from "./editDocumentTemplate";
import GetMyActionsEmployer from "./getMyActionsEmployer";
import CancelDocumentUpload from "./cancelDocumentUpload";
import MakePaymentStatement from "./makePaymentStatement";
import GetMyPurchaseHistory from "./getMyPurchaseHistory";
import GetPushNotifications from "./getPushNotifications";
import GetAdvertWithDetail from "./getAdvertsWithDetail";
import RemoveAdvert from "./removeAdvertFromPublication";
import AddAdvertToFavorite from "./addAdvertToFavorite";
import CheckChangeMailCode from "./checkChangeMailCode";
import GetExperienceLevels from "./getExperienceLevels";
import GetForeignLanguages from "./getForeignLanguages";
import NewDocumentTemplate from "./newDocumentTemplate";
import ArchiveConversation from "./archiveConversation";
import GetUserBasedAdvert from "./getUserBasedAdverts";
import GetDocumentTemplate from "./getDocumentTempate";
import GetFeaturedAdverts from "./getFeaturedAdverts";
import SendMailVerifyMail from "./sendMailVerifyMail";
import UpdateProfilePhoto from "./updateProfilePhoto";
import GetMyActionsWorker from "./getMyActionsWorker";
import StartResetPassword from "./startResetPassword";
import GetPerformanceTest from "./getPerformanceTest";
import GetEducationLevels from "./getEducationLevels";
import EditCompanyProfile from "./editCompanyProfile";
import GetPopularSearches from "./getPopularSearches";
import UpdateCompanyPhoto from "./updateCompanyPhoto";
import GetMyPendingOrders from "./getMyPendingOrders";
import CreateDraftAdvert from "./createDraftAdvert";
import GetPopularAdverts from "./getPopularAdverts";
import ChangePhoneNumber from "./changePhoneNumber";
import ChangeCompanyMail from "./changeCompanyMail";
import ConversationStart from "./conversationStart";
import GetPTAnswerTypes from "./getPTAnswerTypes";
import GetConversations from "./getConversations";
import GetMyApplication from "./getMyApplication";
import ResetNewPassword from "./resetNewPassword";
import GetContractTypes from "./getContractTypes";
import AssignPTtoAdvert from "./assignPTtoAdvert";
import GetDocumentTypes from "./getDocumentTypes";
import RevokeMailChange from "./revokeMailChange";
import GetPTList from "./getPerformanceTestList";
import ConversationEnd from "./conversationEnd";
import DeleteMyAccount from "./deleteMyAccount";
import EditUserProfile from "./editUserProfile";
import GetCapabilities from "./getCapabilities";
import GetFAQsForAbout from "./getFAQsForAbout";
import GetWorkingTypes from "./getWorkingTypes";
import RepublishAdvert from "./republishAdvert";
import CreatePT from "./createPerformanceTest";
import SubmitPT from "./submitPerformanceTest";
import DeletePT from "./deletePerformanceTest";
import InitialConfigs from "./initialConfigs";
import GetCompanySize from "./getCompanySize";
import UserPreference from "./userPreference";
import GetLandingData from "./getLandingData";
import UploadDocument from "./uploadDocument";
import GetAccessToken from "./getAccessToken";
import SubscribeAlarm from "./subscribeAlarm";
import ApplyToAdvert from "./applyToAdvert";
import GetOccupation from "./getOccupation";
import SearchAdverts from "./searchAdverts";
import GetAdvertsPAQ from "./getAdvertPAQ";
import EditPT from "./editPerformanceTest";
import ReadMessages from "./readMessages";
import WorkerSearch from "./workerSearch";
import DeleteAdvert from "./deleteAdvert";
import GetMyProfile from "./getMyProfile";
import RefreshToken from "./refreshToken";
import CheckVersion from "./checkVersion";
import ReSendSMSOTP from "./resendSMSOTP";
import GetAreaCodes from "./getAreaCodes";
import GetAgreement from "./getAgreement";
import CreateAdvert from "./createAdvert";
import GetMyActions from "./getMyActions";
import GetDistricts from "./getDistricts";
import SendMessage from "./sendMessage";
import DeleteAlarm from "./deleteAlarm";
import GetMessages from "./getMessages";
import SetLanguage from "./setLanguage";
import GetPackages from "./getPackages";
import GetSectors from "./getSectors";
import GetAdverts from "./getAdverts";
import EditAdvert from "./editAdvert";
import ChangeMail from "./changeMail";
import GetCities from "./getCities";
import ContactUs from "./contactUs";
import EditAlarm from "./editAlarm";
import GetAlarms from "./getAlarms";
import Register from "./register";
import CreateCV from "./createCV";
import GetBlogs from "./getBlogs";
import GetBanks from "./getBanks";
import GetBlog from "./getBlog";
import GetMyCV from "./getMyCV";
import GetFAQs from "./getFAQs";
import EditCV from "./editCV";
import Logout from "./logout";
import SMSOTP from "./smsOTP";
import Login from "./login";
import Me from "./me";

const actions = {
    CheckChangeCompanyPhoneNumberCode,
    GetAdvertWithDetailForEmployer,
    ExportApplicantDocumentInBulk,
    GetPerformanceTestForEmployer,
    GetAdvertWithPAQForEmployer,
    GetWorkerProfileForEmployer,
    GetAdvertsDataForDashboard,
    GetCompanyProfileForWorker,
    GetAdvertDetailWithoutAuth,
    CheckChangeCompanyMailCode,
    CheckChangePhoneNumberCode,
    EmployerHandleApplication,
    GetAplicationsForEmployer,
    RemoveArchiveConversation,
    RemoveAdvertFromFavorite,
    ChangeCompanyPhoneNumber,
    GetStaticDataWithoutAuth,
    HandleSubmittedDocument,
    ExportApplicantDocument,
    GetConversationUserInfo,
    GetBlogsForLandingPage,
    ResetPasswordCheckCode,
    DeleteDocumentTemplate,
    GetPopularOccupations,
    GetAdvertsWithoutAuth,
    GetMySubscriptionData,
    GetPushNotifications,
    GetDocumentTemplates,
    GetMyPurchaseHistory,
    GetCurrentJobSeekers,
    EditDocumentTemplate,
    GetMyActionsEmployer,
    GetMyUserPreferences,
    CancelDocumentUpload,
    MakePaymentStatement,
    AddAdvertToFavorite,
    GetExperienceLevels,
    GetForeignLanguages,
    GetAdvertWithDetail,
    GetDocumentTemplate,
    NewDocumentTemplate,
    ArchiveConversation,
    CheckChangeMailCode,
    GetUserBasedAdvert,
    UpdateProfilePhoto,
    GetMyPendingOrders,
    GetMyActionsWorker,
    GetPopularSearches,
    StartResetPassword,
    EditCompanyProfile,
    GetPerformanceTest,
    GetFeaturedAdverts,
    GetEducationLevels,
    UpdateCompanyPhoto,
    SendMailVerifyMail,
    ConversationStart,
    ChangeCompanyMail,
    GetPopularAdverts,
    ChangePhoneNumber,
    CreateDraftAdvert,
    GetMyApplication,
    GetPTAnswerTypes,
    GetDocumentTypes,
    GetContractTypes,
    AssignPTtoAdvert,
    ResetNewPassword,
    GetConversations,
    RevokeMailChange,
    GetFAQsForAbout,
    ConversationEnd,
    DeleteMyAccount,
    EditUserProfile,
    GetCapabilities,
    GetWorkingTypes,
    RepublishAdvert,
    InitialConfigs,
    GetLandingData,
    GetCompanySize,
    UploadDocument,
    UserPreference,
    GetAccessToken,
    SubscribeAlarm,
    ApplyToAdvert,
    GetOccupation,
    GetAdvertsPAQ,
    WorkerSearch,
    DeleteAdvert,
    SearchAdverts,
    ReadMessages,
    CreateAdvert,
    GetMyProfile,
    ReSendSMSOTP,
    RefreshToken,
    CheckVersion,
    RemoveAdvert,
    GetAreaCodes,
    GetMyActions,
    GetDistricts,
    GetAgreement,
    GetPackages,
    SendMessage,
    SetLanguage,
    GetMessages,
    DeleteAlarm,
    EditAdvert,
    GetSectors,
    GetAdverts,
    ChangeMail,
    GetAlarms,
    GetPTList,
    ContactUs,
    GetCities,
    EditAlarm,
    CreateCV,
    Register,
    CreatePT,
    GetBlogs,
    GetBanks,
    DeletePT,
    SubmitPT,
    GetMyCV,
    GetBlog,
    GetFAQs,
    SMSOTP,
    EditPT,
    EditCV,
    Logout,
    Login,
    Me
};
export default actions;