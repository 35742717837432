import React from "react";
import useStyles from "./styles";
import {
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import InfoIcon from "../../../../assets/svgr/infoIcon";

const InfoCard = ({
    text 
}: {
  text: string;
}) => {
    const classes = useStyles();

    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();
  
    return <div
        className={classes.cardContainer}
        style={{
            borderRadius: radiuses.quarter * 3,
            backgroundColor: colors.white,
            padding: spaces.content / 2,
            borderColor: colors.stroke,
            marginTop: spaces.content,
        }}
    >
        <div
            className={classes.iconContainer}
            style={{
                borderRadius: radiuses.quarter,
                marginRight: spaces.content,
                padding: spaces.content
            }}
        >
            <InfoIcon
                color={colors.primary}
                size={25}
            />
        </div>
        <Text
            variant="body2-regular"
            color="textDark"
        >
            {text}
        </Text>
    </div>;
};

export default InfoCard;
