import {
    useEffect, 
    useState
} from "react";
import useStyles from "./style";
import {
    FilledTriangleBottomIcon,
    NotificationIcon,
    HamburgerIcon,
    MagnifierIcon,
    PlusIcon,
    Logo
} from "../../assets/svgr";
import {
    IOCoreLocale,
    IOCoreTheme,
    ToolBox,
    Button,
    Text
} from "isinolacak-web-cl";
import emptyProfile from "../../assets/img/isinolacakMiniWithPadding.png";
import NavLink from "./views/navLink";
import {
    useGlobalState
} from "../../context/globalState";
import {
    RESTService
} from "../../services/restAPI";
import {
    ChatService 
} from "../../services/chat";
import {
    NAV_LINKS_WITHOUT_LOGIN,
    NAV_LINKS_FOR_EMPLOYER,
    NAV_LINKS_FOR_WORKER
} from "../../constants";
import {
    useNavigate
} from "react-router-dom";
import {
    NavbarContext
} from "../../context";
import NavigationBox from "../navigationBox";
import IGlobalState from "../../context/globalState/type";

const Header = ({
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        activePath,
        activeHref
    } = NavbarContext.NavigationContext.useContext();

    const [globalState, setGlobalState] = useGlobalState();
    const [variant, SetVariant] = useState<"auth" | "profile" | "creteAdvert">("auth");
    const [isLoadingLogout, setIsLoadingLogout] = useState<boolean>(false);
    const [navLinks, setNavLinks] = useState(NAV_LINKS_WITHOUT_LOGIN);
    const [userData, setUserData] = useState<{
        photoURL?: string | null;
    }>({
        photoURL: undefined
    });
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        const localStorageData: any = localStorage.getItem("user");
        const user: IGlobalState["user"] = JSON.parse(localStorageData);
        setUserData({
            photoURL: user?.photoURL
        });
    }, [userData?.photoURL]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    useEffect(() => {
        if(globalState.user?.type === "worker"){
            setNavLinks(NAV_LINKS_FOR_WORKER);
            SetVariant("profile");
        } else if(globalState.user?.type === "employer"){
            setNavLinks(NAV_LINKS_FOR_EMPLOYER);
            SetVariant("creteAdvert");
        } else {
            setNavLinks(NAV_LINKS_WITHOUT_LOGIN);
            SetVariant("auth");
        }
    }, [globalState]);

    const onResize = () => {
        if(window.innerWidth < 1000) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const logout = () => {
        setIsLoadingLogout(true);
        RESTService.action("Logout", {
        })
            .then(() => {
                window.localStorage.clear();
                setGlobalState({
                    ...globalState,
                    company: null,
                    user: null
                });
                setIsLoadingLogout(false);
                navigate("/auth/login");
                ChatService.disconnect();
            })
            .catch((err) => {
                console.error("Logout Err:", err);
            });
    };

    const renderLeft = () => {
        return <div
            className={classes.leftContainer}
            style={{
                marginRight: isSmall ? spaces.content : spaces.content * 7,
                gap: spaces.content,
                alignItems: "center"
            }}
        >
            <div
                className={classes.hamburgerIcon}
                onClick={() => {
                    NavbarContext.openMenu();
                }}
            >
                <HamburgerIcon />
            </div>
            <div
                className={classes.logoContainer}
                onClick={() => {
                    if(globalState.user?.type === "employer"){
                        navigate("/app/home");
                    }else{
                        navigate("/");
                    }
                }}
            >
                <Logo size={80}/>
            </div>
        </div>;
    };

    const renderMiddle = () => {
        return <div
            className={classes.middleContainer}
        >
            {navLinks.map((item, index) => {
                if(item.subLinks?.length){
                    return <ToolBox
                        key={"ToolBox-"+ index}
                        content={
                            <div>
                                {item.subLinks.map((c_item, c_index) => {
                                    return <NavLink
                                        isActive={activePath === c_item.path && activeHref === c_item.href}
                                        key={"child-nav-link-" + c_index}
                                        titleKey={c_item.titleKey}
                                        path={c_item.path}
                                        href={c_item.href}
                                        isChild={true}
                                    />;
                                })}
                            </div>
                        }
                    >
                        <NavLink
                            isActive={activePath === item.path}
                            key={"child-nav-link-" + index}
                            titleKey={item.titleKey}
                            isDropDownTitle={true}
                            path={item.path}
                            href={item.href}
                            isChild={false}
                        />
                    </ToolBox>;
                }
                return <NavLink
                    isActive={activePath === item.path}
                    key={"child-nav-link-" + index}
                    titleKey={item.titleKey}
                    path={item.path}
                    href={item.href}
                    isChild={false}
                />;
            })}
        </div>;
    };

    const renderRight = () => {
        return <div
            className={classes.rightContainer}
            style={{
                marginLeft: isSmall ? spaces.content : spaces.content * 7
            }}
        >   
            {renderAuthButtons()}
            {renderAppButtons()}
        </div>;
    };

    const renderAuthButtons = () => {
        if(variant !== "auth"){
            return;
        }

        return <div
            className={classes.buttonContainer}
            style={{
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                title={localize("register")}
                variant='outline'
                size='large'
                color='secondary'
                style={{
                    whiteSpace: "nowrap"
                }}
                onClick={() => {
                    navigate("/auth/register");
                }}
            />
            <Button
                title={localize("login")}
                variant='filled'
                size='large'
                color='secondary'
                style={{
                    whiteSpace: "nowrap"
                }}
                onClick={() => {
                    navigate("/auth/login");
                }}
            />
        </div>;
    };

    const renderCreateAdvertButton = () => {
        if(variant !== "creteAdvert"){
            return;
        }

        return <div
            className={classes.buttonContainer}
            style={{
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                title={!isSmall ? localize("create-ad") : ""}
                color='secondary'
                variant='filled'
                size='large'
                icon={() => <PlusIcon
                    color={colors.black100}
                    size={16}
                />}
                style={{
                    whiteSpace: "nowrap"
                }}
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderAppButtons = () => {
        if(variant === "auth"){
            return;
        }
        
        return <div
            className={classes.buttonContainer}
            style={{
                gap: spaces.inline * 1.5
            }}
        >
            <Button
                spreadBehaviour='free'
                variant='filled'
                size='large'
                onClick={() => {
                    if(globalState.user?.type === "employer"){
                        navigate("/app/findCandidate");
                    }else{
                        navigate("/app/search");
                    }
                }}
                icon={({
                    size
                }) => {
                    return <MagnifierIcon
                        color={colors.constrastBody}
                        size={size}
                    />;
                }}
                style={{
                    whiteSpace: "nowrap"
                }}
            />
            <NavigationBox/>
            {renderProfileField()}
            {renderCreateAdvertButton()}
        </div>;
    };

    const renderProfileField = () =>{
        if(variant !== "profile"){
            return;
        }

        return <ToolBox
            content={
                <div
                    className={classes.userInfoContent}
                >
                    <div
                        className={classes.userInfoContentUserInfo}
                        style={{
                            borderBottomWidth: borders.line,
                            marginBottom: spaces.content,
                            borderColor: colors.stroke,
                            gap: spaces.inline,
                        }}
                    >
                        {renderProfilePhoto()}
                        <Text variant='body2-medium'>{globalState.user?.fullName}</Text>
                    </div>
                    {renderLine()}
                    <Button
                        title={localize("profile")}
                        spreadBehaviour='free'
                        color='textDark'
                        variant='ghost'
                        onClick={() => {
                            navigate("/app/profile");
                        }}
                    />
                    <Button
                        title={localize("messages")}
                        spreadBehaviour='free'
                        variant='ghost'
                        color='textDark'
                        onClick={() => {
                            navigate("/app/chat");
                        }}
                    />
                    <Button
                        title={localize("settings")}
                        spreadBehaviour='free'
                        color='textDark'
                        variant='ghost'
                        onClick={() => {
                            navigate("/app/settings");
                        }}
                    />
                    <Button
                        title={localize("log-out")}
                        loading={isLoadingLogout}
                        spreadBehaviour='free'
                        color='yellowButton'
                        variant='filled'
                        onClick={() => {
                            logout();
                        }}
                    />
                </div>
            }
        >
            <div
                className={classes.userInfo}
                style={{
                    gap: spaces.inline
                }}
            >
                {renderProfilePhoto()}
                {
                    !isSmall &&
                        <Text
                            variant='body2-medium'
                            style={{
                                whiteSpace: "nowrap"
                            }}
                        >
                            {globalState.user?.fullName}
                        </Text>
                }
                <FilledTriangleBottomIcon
                    color={colors.secondary}
                    size={15}
                />
            </div>
        </ToolBox>;
    };

    const renderProfilePhoto = () => {
        return <div
            className={classes.profilePhotoContainer}
            style={{
                borderRadius: radiuses.hard * 100,
                borderWidth: borders.indicator,
                borderColor: colors.primary
            }}
        >
            <img 
                src={
                    userData?.photoURL ? 
                        userData.photoURL
                        :
                        emptyProfile
                } 
                alt="profile"
                className={classes.profilePhotoField}
            />
        </div>;
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    return  <div
        className={classes.container}
        style={{
            padding: isSmall ? spaces.container : `${spaces.container * 1.5}px ${spaces.container * 8}px`,
            borderBottomWidth: borders.line,
            borderColor: colors.stroke
        }}
    >
        {renderLeft()}
        {renderMiddle()}
        {renderRight()}
    </div>;
};
export default Header;
