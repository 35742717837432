import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        boxSizing: "content-box",
        flexDirection: "row",
        userSelect: "none",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    leftContainer: {
        borderRightStyle: "solid",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "50%",
        "@media (max-width: 1040px)": {
            display: "none"
        },
        "@media (max-height: 600px)": {
            display: "none"
        },
    },
    leftTopContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        marginTop: "auto",
        maxHeight: "100%",
        display: "flex",
        maxWidth: 500,
        width: "90%",
    },
    visualImageContainer: {
        justifyContent: "center",
        maxHeight: "60%",
        display: "flex",
        flexGrow: 1,
    },
    visualImage: {
        objectFit: "scale-down",
        width: "100%"
    },
    visualImageContainerText: {
        textAlign: "center"
    },
    footerTopContainer: {
        marginTop: "auto"
    },
    footerContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    footerButtonsContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    footerButton: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 23,
        width: 23,
        "&:hover": {
            opacity: 0.8
        }
    },
    footerLink: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
            opacity: 0.8
        }
    },
    verticalLine: {
        height: 20,
        width: 1
    }
});

export default useStyles;