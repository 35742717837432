import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const OpenFolder = ({
    size = 22,
    color="#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.757 8.952c1.4 0 2.592 0 3.544.102.162.017.323.038.483.064.56.09 1.105.235 1.61.488v-.85c0-.91 0-1.663-.09-2.264-.095-.635-.301-1.197-.795-1.66a3.203 3.203 0 0 0-.255-.214c-.539-.408-1.184-.575-1.915-.652-.705-.074-1.592-.074-2.68-.074h-.37c-1.035 0-1.405-.006-1.741-.087a2.957 2.957 0 0 1-.564-.196c-.3-.14-.56-.351-1.292-.968l-.5-.42c-.209-.176-.352-.296-.505-.403a4.663 4.663 0 0 0-2.296-.803A8.947 8.947 0 0 0 6.698 1h-.123c-.674 0-1.119 0-1.505.061-1.69.268-3.056 1.39-3.389 2.875-.075.337-.075.724-.075 1.283v4.387c.505-.253 1.05-.399 1.61-.488.16-.026.321-.047.483-.064.952-.102 2.144-.102 3.544-.102h7.514Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M1.377 11.779c-.643.941-.377 2.25.153 4.868.381 1.885.572 2.827 1.233 3.452.172.163.364.306.572.429C4.14 21 5.205 21 7.34 21h7.322c2.133 0 3.2 0 4.004-.472.208-.123.4-.266.572-.429.66-.625.852-1.567 1.233-3.452.53-2.618.796-3.927.153-4.868a2.988 2.988 0 0 0-.6-.646c-.917-.735-2.398-.735-5.362-.735H7.339c-2.964 0-4.445 0-5.362.735a2.99 2.99 0 0 0-.6.646Zm6.67 4.402c0-.4.361-.723.806-.723h4.294c.445 0 .805.323.805.723 0 .399-.36.723-.805.723H8.853c-.445 0-.805-.324-.805-.723Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default OpenFolder;
