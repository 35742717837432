import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Question = ({
    color = "#45BBD4",
    size = 19,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 19 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M9.5 1.178a7.323 7.323 0 1 0 0 14.646 7.323 7.323 0 0 0 0-14.646ZM.99 8.5a8.51 8.51 0 1 1 17.02 0 8.51 8.51 0 0 1-17.02 0ZM9.5 5.136a.89.89 0 0 0-.89.89.594.594 0 1 1-1.188 0 2.078 2.078 0 1 1 3.135 1.79 1.475 1.475 0 0 0-.368.292c-.081.095-.095.158-.095.195v.99a.594.594 0 1 1-1.188 0v-.99c0-.4.176-.726.378-.964.2-.235.448-.414.669-.545A.89.89 0 0 0 9.5 5.136Zm0 7.323a.792.792 0 1 0 0-1.583.792.792 0 0 0 0 1.583Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Question;
