import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme
} from "isinolacak-web-cl";
import {
    PersonalInfoEditingTypes,
    PersonalInfoType
} from "./types";
import {
    GetAreaCodesResponseType
} from "../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    RESTService
} from "../../../../../../services/restAPI";
import ChangeWorkerPhoneNumber from "./views/changeWorkerPhoneNumber";
import PhoneNumberSmsOtp from "./views/phoneNumberSmsOTP";
import ChangeWorkerMail from "./views/changeWorkerMail";
import PersonalInfoInput from "./views/defaultOpen";
import EmailSmsOtp from "./views/emailSmsOTP";
import {
    getErrorText 
} from "../../../../../../utils";

const PersonalInfo = ({
    setPersonalInfo,
    getMyProfile,
    personalInfo,
}: PersonalInfoType) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        spaces
    } = IOCoreTheme.useContext();

    const [views, setViews] = useState<PersonalInfoEditingTypes>("default-open");
    const [areaCodes, setAreaCodes] = useState<GetAreaCodesResponseType>();
    const [errors, setErrors] = useState<string[]>([]);
    const [token, setToken] = useState("");

    useEffect(() => {
        getAreaCodes();
    }, []);

    const getAreaCodes = () => {
        RESTService.action("GetAreaCodes",{
        })
            .then((res) => {
                setAreaCodes(res);
            })
            .catch((err) => {
                console.error("GetAreaCodes Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderContent = () => {
        return <div>
            {views === "company-mail-change" && <ChangeWorkerMail 
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                setErrors={setErrors}
                setViews={setViews}
                setToken={setToken}
                errors={errors}
                token={token}
            />}
            {views === "company-phone-number-change" && <ChangeWorkerPhoneNumber
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                areaCodes={areaCodes}
                setErrors={setErrors}
                setViews={setViews}
                setToken={setToken}
                errors={errors}
                token={token}
            />}
            {views === "default-open" && <PersonalInfoInput
                setPersonalInfo={setPersonalInfo}
                getMyProfile={getMyProfile}
                personalInfo={personalInfo}
                setErrors={setErrors}
                setViews={setViews}
                errors={errors}
            />
            }
            {views === "sms-mail" && <EmailSmsOtp
                setViews={setViews}
                setToken={setToken}
                token={token}
            />
            }
            {views === "sms-phone-number" && <PhoneNumberSmsOtp
                setPersonalInfo={setPersonalInfo}
                personalInfo={personalInfo}
                setViews={setViews}
                setToken={setToken}
                token={token}
            />
            }
        </div>;
    };


    return <div
        className={classes.container}
        style={{
            padding: spaces.content * 2
        }}
    >
        {renderContent()}
    </div>;
};

export default PersonalInfo;
