import React, {
    useState
} from "react";
import {
    AnswerChildObjectType
} from "../../types";
import useStyles from "./style";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    FilledCheckIcon,
    PencilEditIcon,
    PlusIcon
} from "../../../../../assets/svgr";

const MultiSelect = ({
    index: t_index,
    setData,
    data
}: AnswerChildObjectType) => {
    const classes = useStyles();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [saveQuestions, setSaveQuestions] = useState(false);
    const [disableAdd, setDisableAdd] = useState(false);

    const updateInputValue = (text: string, c_index: number) => {
        let _data = JSON.parse(JSON.stringify(data));
        _data.questions[t_index].answerPayload.attributes.inputs[c_index].value = text;
        setData(_data);
    };

    const confirmValue = (c_index: number) => {
        let _data = JSON.parse(JSON.stringify(data));
        _data.questions[t_index].answerPayload.attributes.inputs[c_index].isValueConfirmed = true;
        setData(_data);
    };

    const editValue = (c_index: number) => {
        let _data = JSON.parse(JSON.stringify(data));
        _data.questions[t_index].answerPayload.attributes.inputs[c_index].isValueConfirmed = false;
        setData(_data);
    };

    const addInput = () => {
        if(data.questions[t_index].answerPayload.attributes.inputs.length > 4) {
            setDisableAdd(true);
            return null;
        }

        let _data = JSON.parse(JSON.stringify(data));
        _data.questions[t_index].answerPayload.attributes.inputs.push({
            val: ""
        });
        setData(_data);
    };

    const renderQuesitonInput = (q_index: number) => {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        return <div
            className={classes.quesitonContainer}
            style={{
                marginBottom: spaces.content * 1.5,
                gap: spaces.content
            }}
        >
            <div
                className={classes.questionIndex}
                style={{
                    backgroundColor: colors.primary,
                    padding: spaces.inline
                }}
            >
                <Text
                    color="textWhite"
                >
                    {alphabet[q_index]}
                </Text>
            </div>
            <TextInput
                disabled={data.questions[t_index].answerPayload.attributes.inputs[q_index].isValueConfirmed}
                initialValue={data.questions[t_index].answerPayload.attributes.inputs[q_index].value}
                placeholder={localize("create-answer")}
                onChangeText={(text) => {
                    updateInputValue(text, q_index);
                }}
                spreadBehaviour="stretch"
                iconDirection="right"
                icon={({
                    size
                }) => {
                    if(data.questions[t_index].answerPayload.attributes.inputs[q_index].isValueConfirmed) {
                        return <PencilEditIcon
                            color={colors.primary}
                            size={size}
                        />;
                    } else {
                        return <FilledCheckIcon
                            color={colors.primary}
                            size={size}
                        />;
                    }
                }}
                iconOnClick={() => {
                    if(data.questions[t_index].answerPayload.attributes.inputs[q_index].isValueConfirmed){
                        editValue(q_index);
                    } else {
                        if(data.questions[t_index].answerPayload.attributes.inputs[q_index].value?.length) {
                            confirmValue(q_index); 
                        }
                    }
                }}
            />
        </div>;
    };

    const renderPreferedInput = (i_index: number) => {
        if(!saveQuestions) {
            return null;
        } 

        return <SelectBox
            titleExtractor={e => e.value ? e.value : ""}
            keyExtractor={(e) => e.value ? e.value : ""}
            title={localize("ideal-responses")}
            isNeedConfirm={true}
            multiSelect={true}
            data={data.questions[t_index].answerPayload.attributes.inputs}
            customIcon={() => {
                return <FilledCheckIcon
                    color={colors.primary}
                    size={24} 
                />;
            }}
            inputTitle=""
            style={{
                marginBottom: spaces.content * 1.5
            }}
            onOk={({
                selectedItems,
                onSuccess,
                closeSheet
            }) => {
                const selectedIndexes = selectedItems.map(e => e.__title);
                let _data = JSON.parse(JSON.stringify(data));
                _data.questions[t_index].answerPayload.attributes.idealInput = selectedIndexes;
                setData(_data);
                onSuccess();
                closeSheet();
            }}
        />;
    };

    const renderButtons = () => {
        let _isAllConfirmed = true;
        data.questions[t_index].answerPayload.attributes.inputs.forEach((e) => {
            if(!e.isValueConfirmed) {
                _isAllConfirmed= true;
            } else {
                _isAllConfirmed= false;
            }
        });

        return <div
            className={classes.buttonsContainer}
            style={{
                marginBottom: spaces.content
            }}
        >
            <Button
                icon={() => <PlusIcon
                    color={colors.primary}
                    size={20}
                />}
                title={localize("create-answer")}
                spreadBehaviour="baseline"
                variant="ghost"
                size="large"
                onClick={() => {
                    addInput();
                }}
            />
            <Button
                disabled={data.questions[t_index].answerPayload.attributes.inputs.length < 3 || _isAllConfirmed}
                title={localize("save-questions")}
                spreadBehaviour="baseline"
                size="large"
                onClick={() => {
                    setSaveQuestions(true);
                }}
            />
        </div>;
    };

    return <div>
        {
            data.questions[t_index].answerPayload.attributes.inputs.map((item, index) => {
                return  renderQuesitonInput(index);
            })
        }
        {renderPreferedInput(t_index)}
        {renderButtons()}
    </div>;
};
export default MultiSelect;
