import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const InstagramCircle = ({
    size = 30,
    color = "black",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M19.14 14.5a4.64 4.64 0 1 1-4.64-4.64 4.655 4.655 0 0 1 4.64 4.64ZM29 8.12v12.76A8.12 8.12 0 0 1 20.88 29H8.12A8.12 8.12 0 0 1 0 20.88V8.12A8.12 8.12 0 0 1 8.12 0h12.76A8.12 8.12 0 0 1 29 8.12Zm-7.54 6.38a6.96 6.96 0 1 0-13.92 0 6.96 6.96 0 0 0 13.92 0Zm2.32-7.54a1.74 1.74 0 1 0-3.481 0 1.74 1.74 0 0 0 3.481 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default InstagramCircle;
