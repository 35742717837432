import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const Radar = ({
    size = 30,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15 29c7.732 0 14-6.268 14-14S22.732 1 15 1 1 7.268 1 15s6.268 14 14 14Zm1.902-21.098a7.317 7.317 0 0 0-3.126-.15 1.05 1.05 0 1 1-.352-2.07 9.448 9.448 0 1 1-6.327 4.135c.635-.967 1.9-1.026 2.687-.39l5.877 4.757a1.05 1.05 0 0 1-1.322 1.632l-2.992-2.422A3.989 3.989 0 1 0 15 11.011a1.05 1.05 0 0 1 0-2.1 6.089 6.089 0 1 1-5.323 3.131l-.992-.803a7.348 7.348 0 1 0 8.217-3.337Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Radar;
