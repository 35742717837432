import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        flexBasis: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1
    },
    mainContent: {
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        width: "100%",
        flexGrow: 1
    },
    renderLeftContainer: {
        borderRightStyle: "solid",
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        flexGrow: 1,
        width: "50%",
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    leftContainerTextWrapper: {
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        display: "flex"
    },
    renderRightContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        width: "50%",
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    renderRightResponsiveContainer: {
        justifyContent: "center",
        flexDirection: "column",
        boxSizing: "border-box",
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        width: "50%",
        "@media screen and (min-width: 1200px)": {
            display: "none"
        }
    },
    rightContainerTitle: {
        marginRight: "auto",
        marginLeft: "auto",
        display: "block",
        maxWidth: 300
    },
    formContainer: {
        flexDirection: "column",
        display: "flex"
    },
    iconContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    icon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 23,
        width: 23,
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8
        }
    },
    imageContainer: {
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: 500
    }, 
    image: {
        height: "100%",
        width: "100%"
    },
    dialogHeaderContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        maxWidth: 410
    },
    alignRight: {
        textAlign: "start"
    }
});