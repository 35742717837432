import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    ResetPasswordCheckCodeResponseType,
    ResetPasswordCheckCodeRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const ResetPasswordCheckCode = async (params:ResetPasswordCheckCodeRequestType ): Promise<ResetPasswordCheckCodeResponseType> => {
    return await axios(`${SERVER_ADDRESS}/authentication/resetPasswordCheckCode`, {
        method: "POST",
        headers: getHeaders(),
        data: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }

            throw err;
        });
};
export default ResetPasswordCheckCode;
