import React, {
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    Loading
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import locales from "../../../../../../../../locales";
import {
    useGlobalState
} from "../../../../../../../../context/globalState";
import {
    getErrorText 
} from "../../../../../../../../utils";

const ChangeLanguage = () =>  {
    const classes = useStyles();

    const [ globalState, setGlobalState ] = useGlobalState();

    const [loading, setLoading] = useState(false);

    const {
        activeLocale,
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const handleSetLanguage = (language: string) => {
        setLoading(true);
        RESTService.action("SetLanguage", {
            language: language
        }).then((res) => {
            window.localStorage.setItem("userLanguage",language);
            let _globalState = JSON.parse(JSON.stringify(globalState));
            _globalState.user.language = language;
            setGlobalState(_globalState);
            IOCoreLocale.switchLocale(language);
            setLoading(false);
        }).catch((err) => {
            console.error(err);
            setLoading(false);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const renderLine = () => {
        return <div
            className={classes.renderLine}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke
            }}
        />;
    };

    const CheckBoxLanguage = () => {
        return <div>
            {locales.map((item) => {
                return <div key={item.code}>
                    <RadioButton
                        isSelected={activeLocale === item.code}
                        //@ts-ignore
                        title={localize(item.code)}
                        onChange={() => {
                            if (activeLocale !== item.code) {
                                handleSetLanguage(item.code);
                            }
                        }}
                    />
                    {renderLine()}
                </div>;
            })}
        </div>;
    };

    if(loading) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div>
        {CheckBoxLanguage()}
    </div>;
};

export default ChangeLanguage;