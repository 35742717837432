import {
    useState
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    Loading,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    useGlobalState 
} from "../../context/globalState";
import {
    EmailVerification,
    VerifiedCheckIcon,
    CloseIcon
} from "../../assets/svgr";
import{
    StatusType
} from "./types";
import {
    RESTService
} from "../../services/restAPI";

const EmailVerificationContainer = () => {
    const [globalState, setGlobalState] = useGlobalState();

    const classes = useStyles();
    const {
        radiuses,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [status, setStatus] = useState<StatusType>("unsent");
    const [isView, setIsView] = useState<boolean>(true);

    const sendMailVerifyMail = () => {
        setStatus("loading");
        RESTService.action("SendMailVerifyMail", {
        })
            .then((res) =>{
                let _globalState = JSON.parse(JSON.stringify(globalState));
                _globalState.user.isMailVerified = true;
                setGlobalState(_globalState);
                setStatus("successful");
                setTimeout(() => {
                    setIsView(false);
                }, 5000);
            })
            .catch(err => {
                setStatus("unsuccessful");
                console.error("SendMailVerifyMail err:", err);
            });
    };

    const closeButtonField = () => {
        return <div
            className={classes.closeButtonField}
        >
            <Button
                spreadBehaviour='free'
                variant="ghost"
                onClick={() => {
                    setIsView(false);
                }}
                icon={({
                    size
                }) => {
                    return <CloseIcon
                        color={colors.textSecondary}
                        size={10}
                    />;
                }}
                style={{
                    padding: 0
                }}
            />
        </div>;
    };

    const renderContent = () => {
        if(status === "successful"){
            return;
        }
        return <div
            className={classes.contentContainer}
        >
            <div 
                className={classes.rightContentContainer}
                style={{
                    marginRight: spaces.inline,
                    gap: spaces.container
                }}
            >
                <EmailVerification/>
                <div 
                    className={classes.textField}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <Text
                        variant="body2-semiBold"
                        color="textDark"
                    >
                        {localize("verify-email-address")}
                    </Text>
                    <Text
                        variant="body3-medium"
                        color="textGrey"
                    >
                        {
                            globalState.user?.type === "employer" ?
                                localize("publish-your-first-advert-by-verifying")
                                :
                                localize("review-job-posting-by-verifying")}
                    </Text>
                </div>
            </div>
            <Button
                spreadBehaviour="free"
                title={localize("verify")}
                onClick={() => {
                    sendMailVerifyMail();
                }}
            />
        </div>;
    };

    const renderLoading = () => {
        if(status !== "loading"){
            return;
        }
        return <Loading/>;
    };

    const renderSuccesfullStatusContent = () => {
        if(status !== "successful"){
            return;
        }
        return <div
            className={classes.succesfullStatusContentContainer}
            style={{
                gap: spaces.container
            }}
        >
            <VerifiedCheckIcon
                color={colors.success}
                size={50}
            />
            <Text
                variant="body2-semiBold"
                color="textDark"
            >
                {localize("email-sent")}
            </Text>
        </div>;
    };

    const renderUnsuccessfulContent = () => {
        return <Dialog
            isVisible={status === "unsuccessful"}
            headerComponent={
                <Text
                    variant="header5-medium"
                >
                    {localize("email-could-not-be-sent")}
                </Text>
            }
            variant="yes-no"
            bottomComponent={
                <Button
                    onClick={() => {
                        setStatus("unsent");
                    }}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />}
        />;
    };

    if(!isView){
        return <div>
        </div>;
    }

    return <div
        className={classes.container}
        style={{
            padding:`${spaces.container}px ${spaces.container}px`,
            backgroundColor: colors.layer2,
            borderRadius: radiuses.half,
            gap: spaces.container * 4
        }}
    >
        {/*closeButtonField()*/}
        {renderContent()}
        {renderLoading()}
        {renderSuccesfullStatusContent()}
        {renderUnsuccessfulContent()}
    </div>;
};
export default EmailVerificationContainer;
