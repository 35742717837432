import {
    useEffect,
    useState,
    useRef
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    RadioButton,
    IOCoreTheme,
    TextInput,
    CheckBox,
    Loading,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    SelectedExperienceLevelsType,
    SelectedOccupationType,
    SelectedContractTypes,
    SelectedWorkingTypes,
    SelectedCitiesType,
    IEditAlarmType,
    ViewDataType
} from "./types";
import {
    FilledArrowDownIcon, 
    FilledArrowUpIcon, 
    MagnifierIcon,
    CloseIcon
} from "../../../../../../../../assets/svgr";
import { 
    RESTService 
} from "../../../../../../../../services/restAPI";
import {
    ExperienceLevelObjectType
} from "../../../../../../../../services/restAPI/actions/getExperienceLevels/types";
import {
    CitiesResponseObjectType,
} from "../../../../../../../../services/restAPI/actions/getCities/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../../../../services/restAPI/actions/getOccupation/types";
import {
    DISABLED_ADS_FILTERS
} from "../constants";
import {
    WorkingTypeObjectType 
} from "../../../../../../../../services/restAPI/actions/getWorkingTypes/types";
import {
    GetContractTypesResponseObjectType
} from "../../../../../../../../services/restAPI/actions/getContractTypes/types";
import {
    EditAlarmMatchParametersTypes 
} from "../../../../../../../../services/restAPI/actions/editAlarm/types";


const EditAlarm = ({
    setSelectedExperienceLevels,
    setSelectedContractTypes,
    selectedExperienceLevels,
    setIsAdvertsForDisabled,
    setSelectedWorkingTypes,
    selectedContractTypes,
    setSelectedOccupation,
    selectedWorkingTypes,
    isAdvertsForDisabled,
    onClickCloseButton,
    selectedOccupation,
    setSelectedCities,
    selectedCities,
    setAlarmTitle,
    setAlarmData,
    alarmTitle,
    alarmData,
    alarmID
}: IEditAlarmType) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [contractTypesData, setContractTypesData] = useState<Array<GetContractTypesResponseObjectType>>([]);
    const [experienceLevelData, setExperienceLevelsData] = useState<Array<ExperienceLevelObjectType>>([]);
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [workingTypesData, setWorkingTypesData] = useState<Array<WorkingTypeObjectType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);
    const [isSeeAll, setIsSeeAll] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    //Occupation Pagination States
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(false);
    const [occupationPage, setOccupationPage] = useState<number>(1);

    const [viewData,setViewData] = useState<ViewDataType>();

    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    
    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getExperienceLevels();
        getContractTypes();
        getWorkingTypes();
        getOccupations();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);

    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    const onSearchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const onChangeCities = (item: CitiesResponseObjectType) => {
        if(selectedCities.length) {
            let _selectedCities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));

            const isExistsInSelectedData = selectedCities.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCities.splice(isExistsInSelectedData, 1);
                setSelectedCities(_selectedCities);
            } else {
                _selectedCities.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedCities(_selectedCities);
            }
        } else {
            setSelectedCities([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const OnChangeWorkingTypes = (item: WorkingTypeObjectType) => {
        if(selectedWorkingTypes.length) {
            let _selectedWorkingTypes:  Array<SelectedWorkingTypes> = JSON.parse(JSON.stringify(selectedWorkingTypes));

            const isExistsInSelectedData = selectedWorkingTypes.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedWorkingTypes.splice(isExistsInSelectedData, 1);
                setSelectedWorkingTypes(_selectedWorkingTypes);
            } else {
                _selectedWorkingTypes.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedWorkingTypes(_selectedWorkingTypes);
            }
        } else {
            setSelectedWorkingTypes([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeContractTypes = (item: GetContractTypesResponseObjectType) => {
        if(selectedContractTypes.length) {
            let _selectedContractTypes:  Array<SelectedContractTypes> = JSON.parse(JSON.stringify(selectedContractTypes));

            const isExistsInSelectedData = selectedContractTypes.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedContractTypes.splice(isExistsInSelectedData, 1);
                setSelectedContractTypes(_selectedContractTypes);
            } else {
                _selectedContractTypes.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedContractTypes(_selectedContractTypes);
            }
        } else {
            setSelectedContractTypes([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeExperienceLevels = (item: ExperienceLevelObjectType) => {
        if(selectedExperienceLevels.length) {
            let _selectedExperienceLevels:  Array<SelectedExperienceLevelsType> = JSON.parse(JSON.stringify(selectedExperienceLevels));

            const isExistsInSelectedData = selectedExperienceLevels.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedExperienceLevels.splice(isExistsInSelectedData, 1);
                setSelectedExperienceLevels(_selectedExperienceLevels);
            } else {
                _selectedExperienceLevels.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedExperienceLevels(_selectedExperienceLevels);
            }
        } else {
            setSelectedExperienceLevels([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e._id === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    localizedText: item.localizedText,
                    _id: item._id
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    _id: item._id
                }
            ]);
        }
    };

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getWorkingTypes = () => {
        RESTService.action("GetWorkingTypes",{
        })
            .then((res) => {
                setWorkingTypesData(res.payload);
            })
            .catch((err) => {
                console.error("GetWorkingTypes Err:", err);
            });
    };

    const getContractTypes = () => {
        RESTService.action("GetContractTypes", {
        })
            .then((res) => {
                setContractTypesData(res.payload);
            })
            .catch((err) => {
                console.error("GetContractTypes Err:", err);
            });
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        params.language = _langugage ?? "tr-TR";

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const getExperienceLevels = () => {
        RESTService.action("GetExperienceLevels", {
        })
            .then((res) => {
                setExperienceLevelsData(res.payload);
            })
            .catch((err) => {
                console.error("GetExperienceLevels Err:", err);
            });
    };

    const editAlarm = () => {
        if(!alarmTitle){
            setErrorText(localize("alarm-title-cannot-be-left-blank"));
            return;
        }

        if(!selectedOccupation?.length){
            setErrorText(localize("occupation-selection-is-required"));
            return;
        }

        setErrorText("");

        let selectedOccupationIDs: Array<string> = [

        ];
        
        selectedOccupation.map((item) =>{
            selectedOccupationIDs.push(item._id);
        });
        let _matchParameters : EditAlarmMatchParametersTypes ={
            occupationIDs: selectedOccupationIDs
        };

        if(selectedCities?.length){
            let selectedCityIDs: Array<string> = [];
            selectedCities.map((item) =>{
                selectedCityIDs.push(item._id);
            });
            _matchParameters.cityIDs = selectedCityIDs;
        }
        
        if(selectedWorkingTypes?.length){
            let selecedWorkingTypesIDs: Array<string> = [];
            selectedWorkingTypes.map((item) =>{
                selecedWorkingTypesIDs.push(item._id);
            });
            _matchParameters.workingTypeIDs = selecedWorkingTypesIDs;
        }

        if(selectedContractTypes?.length){
            let selectedContractTypesIDs: Array<string> = [];
            selectedContractTypes.map((item) =>{
                selectedContractTypesIDs.push(item._id);
            });
            _matchParameters.contractTypeIDs = selectedContractTypesIDs;
        }

        if(isAdvertsForDisabled === "true"){
            _matchParameters.isDisabledPeople = true;
        }

        if(isAdvertsForDisabled === "false"){
            _matchParameters.isDisabledPeople = false;
        }

        if(selectedExperienceLevels?.length){
            let selectedExperienceLevelsIDs: Array<string> = [];
            selectedExperienceLevels.map((item) =>{
                selectedExperienceLevelsIDs.push(item._id);
            });
            _matchParameters.experienceLevelIDs = selectedExperienceLevelsIDs;
        }
        
        RESTService.action("EditAlarm",{
            alarmID: alarmID,
            title: alarmTitle,
            matchParameters:_matchParameters
        })
            .then((res) => {
                let _alarmData = JSON.parse(JSON.stringify(alarmData));
                const currentIndex = alarmData?.findIndex(item => item._id === alarmID);
                if(currentIndex !== -1) {
                    _alarmData[currentIndex].matchParameters = res.matchParameters;
                    _alarmData[currentIndex].title = res.title;
                    _alarmData[currentIndex]._id = res._id;
                }
                setAlarmData(_alarmData);
                onClickCloseButton();
            })
            .catch((err) => {
                setErrorText(localize(err.message));
                console.error("editAlarm Err:", err);
            });
    };

    const renderModalHeader = () =>{
        return <div
            className={classes.modalHeader}
            style={{
                gap: spaces.content
            }}
        >
            <div
                className={classes.closeButtonField}
            >
                <Button
                    onClick={()=>{
                        onClickCloseButton();
                    }}
                    icon={() => <CloseIcon
                        color={colors.textGrey}
                        size={14}
                    />
                    }
                    variant="ghost"
                />
            </div>
            <Text
                variant="header3-medium"
                color="textDark"
            >
                {localize("edit-advert-alarm")}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {localize("edit-advert-alarm-info")}
            </Text>
        </div>;
    };

    const renderAlarmTitleField = () =>{
        return <div
            className={classes.alarmTitleField}
            style={{
                paddingRight:spaces.container,
                paddingLeft:spaces.container,
                gap: spaces.content
            }}
        >
            <Text
                variant="body-medium"
                color="backgroundDark"
                style={{
                    marginTop: spaces.container *2
                }}
            >
                {localize("alarm-title")}
            </Text>
            <TextInput
                placeholder={alarmTitle}
                onChangeText={(text) => setAlarmTitle(text)}
                spreadBehaviour="free"
                style={{
                    width:"100%"
                }}
            />
        </div>;
    };

    const renderCities = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "city"){
                        setViewData(undefined);
                    }else {
                        setViewData("city");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("city")}
                </Text>
                {
                    viewData === "city" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedCities.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeCities(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "city" &&
                    <div
                        className={classes.checkBoxContainer}
                        style={{
                            padding: spaces.content
                        }}
                    >
                        {
                            citiesData.map((item) => {
                                let isSelected = false;

                                if(selectedCities.length) {
                                    let _isSelected =  selectedCities.findIndex((e:CitiesResponseObjectType) => e._id === item._id);
                                    if(_isSelected !== -1){
                                        isSelected = true;
                                    }
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        onChangeCities(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                    </div>
            }
        </div>;
    };

    const renderWorkingType = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "workingType"){
                        setViewData(undefined);
                    }else {
                        setViewData("workingType");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("working-type")}
                </Text>
                {
                    viewData === "workingType" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedWorkingTypes.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                OnChangeWorkingTypes(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "workingType" &&
                    <div
                        className={classes.checkBoxContainer}
                        style={{
                            padding: spaces.content
                        }}
                    >
                        {
                            workingTypesData.map((item) => {
                                let isSelected = false;

                                if(selectedWorkingTypes.length) {
                                    let _isSelected =  selectedWorkingTypes.findIndex((e:SelectedWorkingTypes) => e._id === item._id);
                                    if(_isSelected !== -1){
                                        isSelected = true;
                                    }
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        OnChangeWorkingTypes(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                    </div>
            }
        </div>;
    };

    const renderContractType = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "contractType"){
                        setViewData(undefined);
                    }else {
                        setViewData("contractType");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("work-preference")}
                </Text>
                {
                    viewData === "contractType" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedContractTypes.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeContractTypes(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "contractType" &&
                    <div
                        className={classes.checkBoxContainer}
                        style={{
                            padding: spaces.content
                        }}
                    >
                        {
                            contractTypesData.map((item) => {
                                let isSelected = false;

                                if(selectedContractTypes.length) {
                                    let _isSelected =  selectedContractTypes.findIndex((e:SelectedContractTypes) => e._id === item._id);
                                    if(_isSelected !== -1){
                                        isSelected = true;
                                    }
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        onChangeContractTypes(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                    </div>
            }
        </div>;
    };

    const renderOccupations = () => {
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "occupation"){
                        setViewData(undefined);
                    }else {
                        setViewData("occupation");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("job")}
                </Text>
                {
                    viewData === "occupation" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedOccupation.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            color="primary"
                            size="medium"
                            onClick={() => {
                                onChangeOccupation(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "occupation" &&
                <div>
                    <TextInput
                        icon={() => {
                            return <div
                                style={{
                                    marginRight: spaces.content / 2
                                }}
                            >
                                <MagnifierIcon
                                    color={colors.textGrey}
                                    size={20}
                                />
                            </div>;
                        }}
                        spreadBehaviour="stretch"
                        placeholder={localize("search-job")}
                        onChangeText={(e) => {
                            setOccupationLoading(true);
                            setSearchOccupationPage(1);
                            setSearhOccupation(e);
                            onSearchOccupation(e);
                        }}
                    />
                    <div
                        className={classes.occupationContent}
                        onScroll={(e) => {
                            if(occupationLoading) {
                                return;
                            }
                            if(occupationEndOfData) {
                                return;
                            }
                            if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                                if(searchOccupation.length) {
                                    onSearchOccupation(searchOccupation);
                                } else {
                                    getOccupations();
                                }
                            }
                        }}
                    >
                        {
                            occupationRenderData.map((item) => {
                                let isSelected = false;

                                if(selectedOccupation.length) {
                                    isSelected =  selectedOccupation.findIndex((e) => e._id === item._id) !== -1;
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        onChangeOccupation(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                        {
                            occupationLoading ? <Loading/> : null
                        }
                    </div>
                </div>
            }
        </div>;
    };

    const renderExperienceLevels = () => {
        if(!isSeeAll){
            return;
        }

        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <div
                className={classes.filterHeader}
                style={{
                    padding: spaces.content
                }}
                onClick={()=>{
                    if(viewData === "experienceLevel"){
                        setViewData(undefined);
                    }else {
                        setViewData("experienceLevel");
                    }
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                    style={{
                        marginBottom: spaces.container * 1.25,
                        textAlign: "center"
                    }}
                >
                    {localize("position-level")}
                </Text>
                {
                    viewData === "experienceLevel" ?
                        <FilledArrowUpIcon
                            size={20}
                        />
                        :
                        <FilledArrowDownIcon
                            size={20}
                        />
                }
            </div>
            <div
                className={classes.selectedDataContainer}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    selectedExperienceLevels.map((item) =>{
                        return <Chip
                            title={item.localizedText}
                            color="primary"
                            isCancelable= {true}
                            size="medium"
                            onClick={() => {
                                onChangeExperienceLevels(item);
                            }}
                        />; 
                    })
                }
            </div>
            {
                viewData === "experienceLevel" &&
                    <div
                        className={classes.checkBoxContainer}
                        style={{
                            padding: spaces.content
                        }}
                    >
                        {
                            experienceLevelData.map((item) => {
                                let isSelected = false;

                                if(selectedExperienceLevels.length) {
                                    let _isSelected =  selectedExperienceLevels.findIndex((e:SelectedExperienceLevelsType) => e._id === item._id);
                                    if(_isSelected !== -1){
                                        isSelected = true;
                                    }
                                }

                                return <CheckBox
                                    title={item.localizedText}
                                    isSelected={isSelected}
                                    onChange={() => {
                                        onChangeExperienceLevels(item);
                                    }}
                                    key={item._id}
                                />;
                            })
                        }
                    </div>
            }
        </div>;
    };

    const renderDisabledAdverts = () => {
        if(!isSeeAll){
            return;
        }
        
        return <div
            className={classes.citiesContianer}
            style={{
                paddingRight: spaces.container,
                paddingLeft: spaces.container,
                gap: spaces.inline
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.container * 1.25
                }}
            >
                {localize("disable-candidate")}
            </Text>
            {
                DISABLED_ADS_FILTERS.map((item:any) => {
                    let isSelected = false;

                    if(isAdvertsForDisabled) {
                        isSelected =  isAdvertsForDisabled === item.value ? true : false;
                    }

                    return <RadioButton
                        title={localize(item.localizedText)}
                        isSelected={isSelected}
                        key={item.id}
                        onChange={() => {
                            setIsAdvertsForDisabled(item.value);
                            window.localStorage.setItem("setIsAdvertsForDisabled",item.value);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderSeeAll = () =>{
        return <Button
            title={isSeeAll ? localize("reduce") : localize("see-all")}
            iconDirection="right"
            variant="ghost"
            onClick={() =>{
                setIsSeeAll(!isSeeAll);
            }}
            icon={() =>{
                return isSeeAll ?
                    <FilledArrowUpIcon 
                        size={20}
                    />
                    :
                    <FilledArrowDownIcon 
                        size={20}
                    />;
            }}
        />;
    };

    const renderModalContent = () =>{
        return <div
            className={classes.content}
            style={{
                gap: spaces.content
            }}
        >
            {renderAlarmTitleField()}
            {renderOccupations()}
            {renderCities()}
            {renderWorkingType()}
            {renderContractType()}
            {renderExperienceLevels()}
            {renderDisabledAdverts()}
            {renderSeeAll()}
        </div>;
    };

    const renderErrorTextField = () =>{
        return <Text
            variant="body-medium"
        
            color="danger"
            style={{
                justifyContent: "center",
                flexDirection: "row",
                display: "flex",
                width: "100%"
            }}
        >
            {errorText}
        </Text>;
    };

    const renderButtonField = () =>{
        return <div
            className={classes.saveButtonField}
        >
            <Button
                title = {localize("create")}
                onClick = {() => {
                    editAlarm();
                }}
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer3,
            padding: spaces.container,
            gap: spaces.container
        }}
    >
        {renderModalHeader()}
        {renderModalContent()}
        {renderErrorTextField()}
        {renderButtonField()}
    </div>;
};

export default EditAlarm;
