import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    TextInput,
    CheckBox,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    IResponsiveFiltersContainerProps,
    FilterSelectType
} from "./types";
import {
    MagnifierIcon,
    CloseIcon
} from "../../../../../assets/svgr";
import {
    DISABLED_ADS_FILTERS,
    DATES_FILTER,
    SORT_FILTER,
    FILTERS
} from "../../constants";
import {
    RowCard
} from "../../../../../components";
import {
    GetContractTypesResponseObjectType
} from "../../../../../services/restAPI/actions/getContractTypes/types";
import {
    WorkingTypeObjectType
} from "../../../../../services/restAPI/actions/getWorkingTypes/types";
import {
    CitiesResponseObjectType
} from "../../../../../services/restAPI/actions/getCities/types";
import {
    ExperienceLevelObjectType
} from "../../../../../services/restAPI/actions/getExperienceLevels/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../services/restAPI/actions/getOccupation/types";
import {
    SelectedExperienceLevelType,
    SelectedOccupationType,
    SelectedContractType,
    SelectedWorkingType,
    SelectedCitiesType
} from "../../types";
import {
    getStaticDataWithoutAuthPayloadTypes, 
    GetStaticDataWithoutAuthRequestType
} from "../../../../../services/restAPI/actions/getStaticDataWithoutAuth/types";
import {
    getBrowserLanguageFull
} from "../../../../../utils";

const ResponsiveFilters= ({
    setSelecetedExperienceLevel,
    setSelectedContractType,
    setShowResponsiveFilter,
    selectedExperienceLevel,
    setIsAdvertForDisabled,
    setSelectedWorkingType,
    setSelectedOccupation,
    setSelectedAdvertDate,
    selectedContractType,
    showResponsiveFilter,
    selectedWorkingType,
    isAdvertForDisabled,
    selectedAdvertDate,
    selectedOccupation,
    setSelectedCities,
    selectedCities,
    setSortMethod,
    sortMethod,
    onSearch
}: IResponsiveFiltersContainerProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [contractTypesData, setContractTypesData] = useState<Array<GetContractTypesResponseObjectType>>([]);
    const [positionLevelsData, setPositionLevelsData] = useState<Array<ExperienceLevelObjectType>>([]);
    const [filterSelectStatus, setFilterSelectStatus] = useState<FilterSelectType>("filterSelect");
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [workingTypesData, setWorkingTypesData] = useState<Array<WorkingTypeObjectType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);

    //Occupation Pagination States
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(false);
    const [occupationPage, setOccupationPage] = useState<number>(1);

    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        getOccupations();
        getStaticData();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);

    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getStaticData = () => {
        let variables: GetStaticDataWithoutAuthRequestType = {
            language: getBrowserLanguageFull(),
        };

        RESTService.action("GetStaticDataWithoutAuth", variables)
            .then((res) => {
                const {
                    experienceLevels, workingTypes, contractTypes
                } =  res as unknown as getStaticDataWithoutAuthPayloadTypes;
                setPositionLevelsData(experienceLevels);
                setContractTypesData(contractTypes);
                setWorkingTypesData(workingTypes);
            })
            .catch((err) => {
                console.error("GetStaticDataWithoutAuth Err:", err);
            });
    };

    const onSearchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: getBrowserLanguageFull(),
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            language: getBrowserLanguageFull(),
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
                /*  if(res.payload.length === 0 || res.payload.length < 20) {
                    setOccupationEndOfData(true);
                } */
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const onChangeCites = (item: CitiesResponseObjectType) => {
        if(selectedCities.length) {
            let _selectedCities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));

            const isExistsInSelectedData = selectedCities.findIndex(e => e.cityID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCities.splice(isExistsInSelectedData, 1);
                setSelectedCities(_selectedCities);
            } else {
                _selectedCities.push({
                    ...item,
                    cityID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCities(_selectedCities);
            }
        } else {
            setSelectedCities([
                {
                    localizedText: item.localizedText,
                    cityID: item._id
                }
            ]);
        }
    };

    const onChangeWorkingType = (item: WorkingTypeObjectType) => {
        if(selectedWorkingType.length) {
            let _selectedWorkingType: Array<SelectedWorkingType> = JSON.parse(JSON.stringify(selectedWorkingType));
            const isExistsInSelectedData = selectedWorkingType.findIndex(e => e.workingTypeID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedWorkingType.splice(isExistsInSelectedData, 1);
                setSelectedWorkingType(_selectedWorkingType);
            } else {
                _selectedWorkingType.push({
                    ...item,
                    workingTypeID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedWorkingType(_selectedWorkingType);
            }
        } else {
            setSelectedWorkingType([{
                localizedText: item.localizedText,
                workingTypeID: item._id
            }]);
        }
    };

    const onChangeContractType = (item: GetContractTypesResponseObjectType) => {
        if(selectedContractType.length) {
            let _selectedContractType: Array<SelectedContractType> = JSON.parse(JSON.stringify(selectedContractType));
            const isExistsInSelectedData =selectedContractType.findIndex(e => e.contractTypeID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedContractType.splice(isExistsInSelectedData, 1);
                setSelectedContractType(_selectedContractType);
            } else {
                _selectedContractType.push({
                    ...item,
                    contractTypeID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedContractType(_selectedContractType);
            }
        } else {
            setSelectedContractType([
                {
                    localizedText: item.localizedText,
                    contractTypeID: item._id
                }
            ]);
        }
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e.occupationID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    ...item,
                    occupationID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    occupationID: item._id
                }
            ]);
        }
    };

    const onChangePosition = (item: ExperienceLevelObjectType) => {
        if(selectedExperienceLevel.length) {
            let _selectedExperienceLevel: Array<SelectedExperienceLevelType>   = JSON.parse(JSON.stringify(selectedExperienceLevel));

            const isExistsInSelectedData = selectedExperienceLevel.findIndex(e => e.experienceLevelID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedExperienceLevel.splice(isExistsInSelectedData, 1);
                setSelecetedExperienceLevel(_selectedExperienceLevel);
            } else {
                _selectedExperienceLevel.push({
                    ...item,
                    experienceLevelID: item._id,
                    localizedText: item.localizedText
                });
                setSelecetedExperienceLevel(_selectedExperienceLevel);
            }
        } else {
            setSelecetedExperienceLevel([
                {
                    localizedText: item.localizedText,
                    experienceLevelID: item._id
                }
            ]);
        }
    };

    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                borderBottomColor: colors.stroke,
                borderBottomWidth: borders.line,
                marginBottom: spaces.container,
                backgroundColor: colors.white
            }}
        >
            <div
                className={classes.headerContent}
                style={{
                    paddingBottom: spaces.container * 1.25,
                    paddingTop: spaces.container * 1.25,
                    paddingRight: spaces.container * 2,
                    paddingLeft: spaces.container * 2,
                }}
            > 
                {
                    filterSelectStatus === "filterSelect" ? 
                        <div
                            className={classes.hoverableElement}
                            onClick={() => {
                                setShowResponsiveFilter(false);
                            }}
                        >
                            <CloseIcon
                                color={colors.textGrey}
                                size={14}
                            />
                        </div> :
                        <div
                            className={classes.hoverableElement}
                            onClick={() => {
                                setFilterSelectStatus("filterSelect");
                            }}
                        >
                            <Text
                                variant="body3-regular"
                                color="textDark"
                            >
                                {localize("filters")}
                            </Text>
                        </div>
                }
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("filter")}
                </Text>
                <div
                    className={classes.hoverableElement}
                    onClick={() => {
                        setSelecetedExperienceLevel([]);
                        setIsAdvertForDisabled("all");
                        setSelectedAdvertDate("all");
                        setSelectedContractType([]);
                        setSortMethod("recommended");
                        setSelectedWorkingType([]);
                        setSelectedOccupation([]);
                    }}
                >
                    <Text
                        variant="body3-regular"
                        color="textGrey"
                    >
                        {localize("iocore-select-sheet-clear-button")}
                    </Text>
                </div>
            </div>
            {renderOccupationSearch()}
        </div>;
    };

    const renderOccupationSearch = () => {
        if(filterSelectStatus !== "occupationSelect") {
            return null;
        }

        return <div
            style={{
                paddingBottom: spaces.container * 1.25,
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2
            }}
        >
            <TextInput
                icon={() => <MagnifierIcon
                    color={colors.textGrey}
                    size={20}
                />}
                placeholder={localize("search-job")}
                onChangeText={(e) => {
                    setSearhOccupation(e);
                    onSearchOccupation(e);
                }}
            />
        </div>;
    };

    const renderSortOptions = () => {
        if(filterSelectStatus !== "filterSelect") {
            return null;
        }

        return <div
            className={classes.sortContainer}
            style={{
                paddingRight: spaces.container * 1.75,
                paddingLeft: spaces.container * 1.75,
                borderBottomColor: colors.stroke,
                paddingTop: spaces.contentLarge,
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                marginBottom: spaces.container,
                borderWidth: borders.line,
                gap: spaces.contentLarge
            }}
        >
            <Text
                variant="body-medium"
                color="textDark"
            >
                {localize("sorting")}
            </Text>
            <div
                className={classes.sortContent}
                style={{
                    gap: spaces.inline
                }}
            >
                {
                    SORT_FILTER.map((item) => {
                        let isSelected = false;

                        if(sortMethod) {
                            isSelected = sortMethod === item.value ? true : false;
                        }

                        return  <div
                            className={classes.sortItem}
                        >
                            <Text
                                variant="body2-regular"
                                color="textDark"
                            >
                                {localize(item.localizedText)}
                            </Text>
                            <RadioButton
                                isSelected={isSelected}
                                onChange={() => {
                                    setSortMethod(item.value);
                                }}
                            />
                        </div>;
                    })
                }
            </div>
        </div>;
    };

    const renderFiltersContainer = () => {
        if(filterSelectStatus !== "filterSelect") {
            return null;
        }

        return <div
            className={classes.filtersContainer}
            style={{
                marginBottom: spaces.container * 2,
                paddingTop: spaces.container,
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1
            }}
        >
            {
                FILTERS.map((item) => {
                    let _selectedFilters: string;

                    if(item.value === "advertDateSelect") {
                        _selectedFilters = selectedAdvertDate === "today" ? localize("todays-ad") : selectedAdvertDate === "thisWeek" ? localize("this-weeks-ads") : selectedAdvertDate === "last12Hours" ? localize("last-12-hours") : selectedAdvertDate === "last3Hours" ? localize("last-3-hours") : localize("all"); 
                    }
                    if(item.value === "citySelect") {
                        _selectedFilters = selectedCities.length === 1 ? selectedCities[0].localizedText : selectedCities.length > 1 ? `${selectedCities.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "workPreferenceSelect") {
                        _selectedFilters = selectedWorkingType.length === 1 ? selectedWorkingType[0].localizedText : selectedWorkingType.length > 1 ? `${selectedWorkingType.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "workTypeSelect") {
                        _selectedFilters = selectedContractType.length === 1 ? selectedContractType[0].localizedText : selectedContractType.length > 1 ? `${selectedContractType.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "occupationSelect") {
                        _selectedFilters = selectedOccupation.length === 1 ? selectedOccupation[0].localizedText : selectedOccupation.length > 1 ? `${selectedOccupation.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "positionLevelSelect") {
                        _selectedFilters = selectedExperienceLevel.length === 1 ? selectedExperienceLevel[0].localizedText : selectedExperienceLevel.length > 1 ? `${selectedExperienceLevel.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "disabledAdsSelect") {
                        _selectedFilters = isAdvertForDisabled === "all" ? localize("all") : isAdvertForDisabled === "true" ? localize("only-disabled-ads"): localize("no-disabled-ads");
                    }

                    return <RowCard
                        title={localize("location-city")}
                        onClick={() => {
                            setFilterSelectStatus(item.value);
                        }}
                        renderTitle={(title) => {
                            return <div
                                style={{
                                    flexDirection: "column",
                                    display: "flex"
                                }}
                            >
                                <Text
                                    variant="body-medium"
                                    color="textDark"
                                >
                                    {localize(item.localizedText)}
                                </Text>
                                <Text
                                    variant="body3-regular"
                                    color="textGrey"
                                >
                                    {_selectedFilters}
                                </Text>
                            </div>;
                        }}
                        style={{
                            borderBottom: "solid",
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderFilterButton = () => {
        return <div
            className={classes.filterButtonContainer}
            style={{
                paddingBottom: spaces.contentLarge,
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2
            }}
        >
            <Button
                spreadBehaviour="stretch"
                onClick={() => {
                    setFilterSelectStatus("filterSelect");
                    if(filterSelectStatus === "filterSelect") {
                        setShowResponsiveFilter(false);
                        onSearch();
                    }
                }}
                size="large"
                title={localize("filter-it")}
                color="secondary"
            />
        </div>;
    };

    const renderCitiesSelect = () => {
        if(filterSelectStatus !== "citySelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                citiesData.map((item) => {
                    let isSelected = false;

                    if(selectedCities.length) {
                        isSelected =  selectedCities.findIndex((e) => e.cityID === item._id) !== -1;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={item._id}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeCites(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderWorkPreferenceSelect = () => {
        if(filterSelectStatus !== "workPreferenceSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                workingTypesData.map((item) => {
                    let isSelected = false;

                    if(selectedWorkingType.length) {
                        isSelected =  selectedWorkingType.findIndex((e) => e.workingTypeID === item._id) !== -1;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={item._id}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            key={item._id}
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeWorkingType(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderWorkTypeSelect = () => {
        if(filterSelectStatus !== "workTypeSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                contractTypesData.map((item) => {
                    let isSelected = false;

                    if(selectedContractType.length) {
                        isSelected =  selectedContractType.findIndex((e) => e.contractTypeID === item._id) !== -1;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={item._id}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeContractType(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderDateFilterSelecet = () => {
        if(filterSelectStatus !== "advertDateSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                DATES_FILTER.map((item) => {
                    let isSelected = false;

                    if(selectedAdvertDate) {
                        isSelected =  selectedAdvertDate === item.value ? true : false;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize(item.localizedText)}
                        </Text>
                        <RadioButton
                            key={item.id}
                            isSelected= {isSelected}
                            onChange={() => {
                                setSelectedAdvertDate(item.value);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderOccupationSelect = () =>  {
        if(filterSelectStatus !== "occupationSelect") {
            return null;
        }

        return  <div
            className={classes.occupationSelectContainer}
            onScroll={(e) => {
                if(occupationLoading) {
                    return;
                }
                if(occupationEndOfData) {
                    return;
                }
                setOccupationLoading(true);
                if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                    if(searchOccupation.length) {
                        onSearchOccupation(searchOccupation);
                    } else {
                        getOccupations();
                    }
                }
            }}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                occupationRenderData.map((item,index) => {
                    let isSelected = false;

                    if(selectedOccupation.length) {
                        isSelected =  selectedOccupation.findIndex((e) => e.occupationID === item._id) !== -1;
                    }

                    return <div
                        className={classes.citiesSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            borderColor: colors.stroke,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeOccupation(item);
                            }}
                        />
                    </div>;
                })
            }
            {
                occupationLoading ? <Loading/> : null
            }
        </div>;
    };

    const renderPositionLevelSelect = () => {
        if(filterSelectStatus !== "positionLevelSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                positionLevelsData.map((item,index) => {
                    let isSelected = false;

                    if(selectedExperienceLevel.length) {
                        isSelected =  selectedExperienceLevel.findIndex((e) => e.experienceLevelID === item._id) !== -1;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={index}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangePosition(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderDisabledAdsSelect = () => {
        if(filterSelectStatus !== "disabledAdsSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                DISABLED_ADS_FILTERS.map((item,index) => {
                    let isSelected = false;

                    if(isAdvertForDisabled) {
                        isSelected =  isAdvertForDisabled === item.value ? true : false;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={index}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize(item.localizedText)}
                        </Text>
                        <RadioButton
                            isSelected={isSelected}
                            key={item.id}
                            onChange={() => {
                                setIsAdvertForDisabled(item.value);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };


    if(!showResponsiveFilter) {
        return null;
    }

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer3
        }}
    >
        {renderHeader()}
        {renderSortOptions()}
        {renderFiltersContainer()}
        {renderCitiesSelect()}
        {renderWorkPreferenceSelect()}
        {renderWorkTypeSelect()}
        {renderDateFilterSelecet()}
        {renderOccupationSelect()}
        {renderPositionLevelSelect()}
        {renderDisabledAdsSelect()}
        {renderFilterButton()}
    </div>;
};

export default ResponsiveFilters;
