import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const DocumentVariant = ({
    color = "#45BBD4",
    size = 22,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M1 3.736v14.217C1 19.636 2.679 21 4.75 21h12.5c2.071 0 3.75-1.364 3.75-3.047V7.798c0-1.682-1.679-3.046-3.75-3.046h-15C1.56 4.752 1 4.297 1 3.736Zm4.063 7.109c0-.42.42-.762.937-.762h10c.518 0 .938.341.938.762 0 .42-.42.761-.938.761H6c-.518 0-.938-.34-.938-.761Zm0 3.554c0-.42.42-.761.937-.761h6.875c.518 0 .938.34.938.761 0 .42-.42.762-.938.762H6c-.518 0-.938-.341-.938-.762Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
        <path
            d="M1.511 2.81c.436.158.739.513.739.926h15c.432 0 .85.045 1.25.128v-.833c0-1.236-1.348-2.185-2.854-2.01L2.15 2.587c-.24.028-.458.107-.638.222Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default DocumentVariant;
