import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    alarmInfoContainer:{
        flexDirection: "row",
        display: "flex",
        width: "100%",
    },
    alarmInfoContent:{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        width: "100%",
        "@media (max-width: 600px)": {
            justifyContent: "start",
            flexDirection: "column",
            alignItems: "start",
            gap: 20
        }
    },
    textField:{
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    dialogHeader: {
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "start",
        display: "flex",
        width: "100%"
    },
    dialogBottom : {
        justifyContent: "end",
        flexDirection: "row",
        display: "flex"
    },
    line: {
        width: "100%",
        height: 1
    },
    pageModalContainer: {
        justifyContent: "center",
        alignItems: "center",
        width: "500px",
        "@media (max-width: 600px)": {
            width: "400px",
        },
        "@media (max-width: 450px)": {
            width: "300px",
        }
    },
    pageModalContent: {
        flexDirection: "row",
        display: "flex"
    },
    closeButtonField:{
        justifyContent: "end",
        flexDirection: "row",
        display: "flex",
        margin: 0,
        padding: 0
    }
});
