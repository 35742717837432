import IGlobalState from "../context/globalState/type";
import {
    MyActionsMenuIcon,
    FourRectangleIcon,
    MagnifierIcon,
    ProfileIcon,
    LetterIcon
} from "../assets/svgr";
import {
    NavPathDataType,
    NavLinkType
} from "./types";

export const STORAGE_SERVER_ADDRESS = "https://storage.isinolacak.com/upload";

export const GLOBAL_STATE_INITIAL_STORE: IGlobalState = {
    user: null
};

export const NAV_LINKS_WITHOUT_LOGIN: Array<NavLinkType> = [
    {
        path: "/search",
        titleKey: "jobAdverts",
    },
];

export const NAV_LINKS_FOR_WORKER: Array<NavLinkType> = [
    {
        titleKey: "jobAdverts",
        path: "/app/myActions",
        subLinks: [
            {
                titleKey: "myFavorites",
                path: "/app/myActions",
                href: "favs"
            },
            {
                titleKey: "myApplications",
                path: "/app/myActions",
                href: "applied"
            }
        ]
    }
];

export const NAV_LINKS_FOR_EMPLOYER: Array<NavLinkType> = [
    {
        titleKey: "find-candidate",
        path: "/app/findCandidate"
    }
    /* {
        titleKey: "findEmployee",
        path: "/",
    } */
];

export const SIDEBAR_NAV_LINKS_WITHOUTH_LOGIN: Array<NavPathDataType> = [
    {
        titleKey: "profile",
        path: "/app/profile",
        icon: () => {
            return <ProfileIcon size={18} />;
        }
    },
    {
        titleKey: "staff-search",
        path: "/app/findCandidate",
        icon: () => {
            return <MagnifierIcon size={18} />;
        }
    },
    {
        titleKey: "myActions",
        path: "/app/myActions",
        icon: () => {
            return <MyActionsMenuIcon size={18} />;
        }
    },
    {
        titleKey: "my-messages",
        path: "/app/chat",
        icon: () => {
            return <LetterIcon size={18} />;
        }
    },
    {
        titleKey: "settings",
        path: "/app/settings",
        icon: () => {
            return <FourRectangleIcon size={18} />;
        }
    }
];

export const ISIN_OLACAK_LINKEDIN = "https://www.linkedin.com/company/isin-olacak";
export const ISIN_OLACAK_INSTAGRAM = "https://www.instagram.com/isinolacak";
export const ISIN_OLACAK_FACEBOOK = "https://www.facebook.com/isin-olacak";
export const ISIN_OLACAK_YOUTUBE = "https://www.youtube.com/@IsinOlacak";
export const ISIN_OLACAK_TWITTER = "https://x.com/isinolacak";

export const WORKER_HEADER_MENU_FOR_HAMBURGER_MENU: Array<NavLinkType> = [
    {
        titleKey: "profile",
        path: "/app/profile"
    },
    {
        titleKey: "messages",
        path: "/app/chat"
    },
    {
        titleKey: "settings",
        path: "/app/settings"
    },
];
