import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    headerContainer: {
        display: "flex",
        borderStyle: "solid",
        justifyContent: "space-between",
        alignItems: "center",
        "@media screen and (max-width: 1239px)": {
            flexDirection: "column",
            alignItems: "flex-start"
        },
        "@media screen and (max-width: 1040px)": {
            flexDirection: "row",
            alignItems: "center"
        },
        "@media screen and (max-width: 600px)": {
            flexDirection: "column",
            alignItems: "flex-start"
        }
    },
    headerTextsContainer: {
        display: "flex",
        flexDirection: "column"
    },
    rowLeftIconStyle: {
        boxSizing: "border-box",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    rowStyle: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative"
    },
    toolboxContainer: {
        borderStyle: "solid",
        position: "absolute",
        right: 0,
    },
    toolboxItemContainer: {
        display: "flex",
        flexDirection: "column"
    },
    toolboxItem: {
        display: "flex",
        alignItems: "center"
    },
    historyContainer: {
        width: "100%"
    },
    historyHeadersContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopStyle: "solid",
        borderLeftStyle: "solid"
    },
    purchaseHistoryRow: {
        display: "flex",
        borderLeftStyle: "solid"
    },
    purchaseHistoryItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderBottomStyle: "solid",
        borderRightStyle: "solid"
    },
    purchaseHistoryContentItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderBottomStyle: "solid",
        borderRightStyle: "solid"
    },
    noOrderHistory: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    }
});
export default useStyles;