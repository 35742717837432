import {
    useEffect,
    useRef,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    RadioButton,
    TextInput,
    CheckBox,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    IResponsiveFiltersContainerProps,
    FilterSelectType
} from "./types";
import {
    MagnifierIcon,
    CloseIcon
} from "../../../../../assets/svgr";
import {
    DISABLED_CANDIDATES_FILTERS,
    //SORT_FILTER,
    FILTERS
} from "../../constants";
import {
    RowCard
} from "../../../../../components";
import {
    GetForeignLanguagesPayloadObjectType
} from "../../../../../services/restAPI/actions/getForeignLanguages/types";
import {
    CitiesResponseObjectType
} from "../../../../../services/restAPI/actions/getCities/types";
import {
    EducationLevelsObjectType    
} from "../../../../../services/restAPI/actions/getEducationLevels/types";
import {
    GetCapabilitiesRequestType,
    GetCapabilitiesObjectType
} from "../../../../../services/restAPI/actions/getCapabilities/types";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../../../../services/restAPI/actions/getOccupation/types";
import {
    SelectedForeignLanguageType,
    SelectedEducationLevelType,
    SelectedCapabilitiesType,
    SelectedOccupationType,
    SelectedCitiesType
} from "../../types";

const ResponsiveFilters= ({
    setSelectedForeignLanguages,
    setIsCandidatesForDisabled,
    setSelectedEducationLevel,
    selectedForeignLanguages,
    isCandidatesForDisabled,
    setShowResponsiveFilter,
    setSelectedCapabilities,
    selectedEducationLevel,
    setSelectedOccupation,
    selectedCapabilities,
    showResponsiveFilter,
    selectedOccupation,
    setSelectedCities,
    selectedCities,
    //setSortMethod,
    //sortMethod,
    onSearch
}: IResponsiveFiltersContainerProps) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [foreignLanguagesData, setForeignLanguagesData] = useState<Array<GetForeignLanguagesPayloadObjectType>>([]);
    const [educationLevelData, setEducationLevelData] = useState<Array<EducationLevelsObjectType>>([]);
    const [occupationData, setOccupationData] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [filterSelectStatus, setFilterSelectStatus] = useState<FilterSelectType>("filterSelect");
    const [capabilitiesData, setCapabilitiesData] = useState<Array<GetCapabilitiesObjectType>>([]);
    const [citiesData, setCitiesData] = useState<Array<CitiesResponseObjectType>>([]);

    //Occupation Pagination States
    const [capabilitiesRenderData, setCapabilitiesRenderData] = useState(capabilitiesData);
    const [foreignLanguagesEndOfData, setForeignLanguagesEndOfData] = useState(false);
    const [occupationRenderData, setOccupationRenderData] = useState(occupationData);
    const [searchCapabilitiesPage, setSearchCapabilitiesPage] = useState<number>(1);
    const [educationLevelEndOfData, setEducationLevelEndOfData] = useState(false);
    const [searchOccupationPage, setSearchOccupationPage] = useState<number>(1);
    const [capabilitiesEndOfData, setCapabilitiesEndOfData] = useState(false);
    const [searchCapabilities, setSearhCapabilities] = useState<string>("");
    const [occupationEndOfData, setOccupationEndOfData] = useState(false);
    const [capabilitiesLoading, setCapabilitiesLoading] = useState(true);
    const [capabilitiesPage, setCapabilitiesPage] = useState<number>(1);
    const [searchOccupation, setSearhOccupation] = useState<string>("");
    const [occupationLoading, setOccupationLoading] = useState(true);
    const [occupationPage, setOccupationPage] = useState<number>(1);
    
    const searchOccupationDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getForeignLanguages();
        getEducationLevel();
        getCapabilities();
        getOccupations();
        getCities();
    }, []);

    useEffect(() => {
        if(searchOccupation && searchOccupation.length) {
            let newData = JSON.parse(JSON.stringify(occupationData));
            newData = newData.filter((item: GetOccupationResponseObjectType) => item.localizedText.match(new RegExp(searchOccupation, "gi")));
            setOccupationRenderData(newData);
        } else {
            setOccupationRenderData(occupationData);
        }
    }, [searchOccupation, occupationData]);

    useEffect(() => {
        onSearchOccupation(searchOccupation);
    }, [searchOccupation]);

    useEffect(() => {
        if(searchCapabilities && searchCapabilities.length) {
            let newData = JSON.parse(JSON.stringify(capabilitiesData));
            newData = newData.filter((item: GetCapabilitiesObjectType) => item.localizedText.match(new RegExp(searchCapabilities, "gi")));
            setCapabilitiesRenderData(newData);
        } else {
            setCapabilitiesRenderData(capabilitiesData);
        }
    }, [searchCapabilities, capabilitiesData]);

    // Helper Functions
    const onChangeCities = (item: CitiesResponseObjectType) => {
        if(selectedCities.length) {
            let _selectedCities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));

            const isExistsInSelectedData = selectedCities.findIndex(e => e.cityID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCities.splice(isExistsInSelectedData, 1);
                setSelectedCities(_selectedCities);
                window.localStorage.setItem("setSelectedCities",JSON.stringify(_selectedCities));
            } else {
                _selectedCities.push({
                    ...item,
                    cityID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCities(_selectedCities);
                window.localStorage.setItem("setSelectedCities",JSON.stringify(_selectedCities));
            }
        } else {
            setSelectedCities([
                {
                    localizedText: item.localizedText,
                    cityID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedCities",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    cityID: item._id
                }
            ]));
        }
    };

    const onChangeOccupation = (item: GetOccupationResponseObjectType) => {
        if(selectedOccupation.length) {
            let _selectedOccupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));

            const isExistsInSelectedData = selectedOccupation.findIndex(e => e.occupationID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedOccupation.splice(isExistsInSelectedData, 1);
                setSelectedOccupation(_selectedOccupation);
            } else {
                _selectedOccupation.push({
                    ...item,
                    occupationID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedOccupation(_selectedOccupation);
            }
        } else {
            setSelectedOccupation([
                {
                    localizedText: item.localizedText,
                    occupationID: item._id
                }
            ]);
        }
    };

    const onChangeCapabilities = (item: GetCapabilitiesObjectType) => {
        if(selectedCapabilities.length) {
            let _selectedCapabilities: Array<SelectedCapabilitiesType> = JSON.parse(JSON.stringify(selectedCapabilities));

            const isExistsInSelectedData = selectedCapabilities.findIndex((e: SelectedCapabilitiesType) => e.capabilitiesID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedCapabilities.splice(isExistsInSelectedData, 1);
                setSelectedCapabilities(_selectedCapabilities);
                window.localStorage.setItem("setSelectedCapabilities",JSON.stringify(_selectedCapabilities));
            } else {
                _selectedCapabilities.push({
                    ...item,
                    capabilitiesID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedCapabilities(_selectedCapabilities);
                window.localStorage.setItem("setSelectedCapabilities",JSON.stringify(_selectedCapabilities));
            }
        } else {
            setSelectedCapabilities([
                {
                    localizedText: item.localizedText,
                    capabilitiesID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedCapabilities",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    capabilitiesID: item._id
                }
            ]));
        }
    };

    const onChangeEducationLevel = (item: EducationLevelsObjectType) => {
        if(selectedEducationLevel.length) {
            let _selectedEducationLevel: Array<SelectedEducationLevelType> = JSON.parse(JSON.stringify(selectedEducationLevel));

            const isExistsInSelectedData = selectedEducationLevel.findIndex((e: SelectedEducationLevelType) => e.educationLevelID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedEducationLevel.splice(isExistsInSelectedData, 1);
                setSelectedEducationLevel(_selectedEducationLevel);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedEducationLevel));
            } else {
                _selectedEducationLevel.push({
                    ...item,
                    educationLevelID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedEducationLevel(_selectedEducationLevel);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedEducationLevel));
            }
        } else {
            setSelectedEducationLevel([
                {
                    localizedText: item.localizedText,
                    educationLevelID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedEducation",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    educationLevelData: item._id
                }
            ]));
        }
    };

    const onChangeForeignLanguage = (item: GetForeignLanguagesPayloadObjectType) => {
        if(selectedForeignLanguages.length) {
            let _selectedForeignLanguages: Array<SelectedForeignLanguageType> = JSON.parse(JSON.stringify(selectedForeignLanguages));

            const isExistsInSelectedData = selectedForeignLanguages.findIndex((e: SelectedForeignLanguageType) => e.foreignLanguageID === item._id);

            if(isExistsInSelectedData !== -1) {
                _selectedForeignLanguages.splice(isExistsInSelectedData, 1);
                setSelectedForeignLanguages(_selectedForeignLanguages);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedForeignLanguages));
            } else {
                _selectedForeignLanguages.push({
                    ...item,
                    foreignLanguageID: item._id,
                    localizedText: item.localizedText
                });
                setSelectedForeignLanguages(_selectedForeignLanguages);
                window.localStorage.setItem("setSelectedEducation",JSON.stringify(_selectedForeignLanguages));
            }
        } else {
            setSelectedForeignLanguages([
                {
                    localizedText: item.localizedText,
                    foreignLanguageID: item._id
                }
            ]);
            window.localStorage.setItem("setSelectedEducation",JSON.stringify([
                {
                    localizedText: item.localizedText,
                    foreignLanguageData: item._id
                }
            ]));
        }
    };

    const onSearchOccupation = (searchText: string) => {
        if(searchOccupationDebouncer.current) {
            clearTimeout(searchOccupationDebouncer.current);
        }
        searchOccupationDebouncer.current = null;

        if(searchText && searchText.length) {
            searchOccupationDebouncer.current = setTimeout(() => {
                getOccupations({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchOccupationDebouncer.current);
                searchOccupationDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupations();
        }
    };

    const onSearchCapabilities = (e: string) => {
        const timer = setTimeout(() => {
            if (e && e.length) {
                let params: GetCapabilitiesRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: searchCapabilitiesPage,
                    search: e
                };
                getCapabilities(params);
                setCapabilitiesLoading(true);
            }
            if(!e.length) {
                let params: GetCapabilitiesRequestType = {
                    language: _langugage ?? "tr-TR",
                    page: occupationPage
                };
                getCapabilities(params);
                setCapabilitiesLoading(true);
            }
        }, 750);
        return () => clearTimeout(timer);
    };


    // Request Functions
    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                setCitiesData(res.payload);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const getEducationLevel = () => {
        RESTService.action("GetEducationLevels",{
            language: _langugage ?? "tr-TR",
        }).then((res) => {
            setEducationLevelData(res.payload);
            if(res.payload.length === 0 || res.payload.length < 20) {
                setEducationLevelEndOfData(true);
            }
        }).catch((err) => {
            console.error("GetEducationLevels Err:", err);
        });
    };

    const getForeignLanguages = () => {
        RESTService.action("GetForeignLanguages",{
            language: _langugage ?? "tr-TR",
        }).then((res) => {
            setForeignLanguagesData(res.payload);
            if(res.payload.length === 0 || res.payload.length < 20) {
                setForeignLanguagesEndOfData(true);
            }
        }).catch((err) => {
            console.error("GetForeignLanguages Err:", err);
        });
    };

    const getOccupations = (occupationParams ?:GetOccupationRequestType) => {
        if(!occupationLoading) setOccupationLoading(true);

        let params: GetOccupationRequestType = {
            language: _langugage ?? "tr-TR",
            page:  occupationPage
        };

        if(searchOccupation && searchOccupation.length) {
            params.search = searchOccupation;
            params.page = searchOccupationPage;
        }

        if(occupationParams) {
            params = occupationParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetOccupation",params)
            .then((res) => {
                let _getOccupations = JSON.parse(JSON.stringify(occupationData));
                let newOccupations = res.payload.filter(newItem => !_getOccupations.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setOccupationData([..._getOccupations, ...newOccupations]);

                if(occupationParams?.search || searchOccupation.length){
                    setOccupationPage(1);
                } else {
                    setOccupationPage(e => e +1);
                    setSearchOccupationPage(1);
                }

                setOccupationLoading(false);
                /*  if(res.payload.length === 0 || res.payload.length < 20) {
                    setOccupationEndOfData(true);
                } */
            })
            .catch((err) => {
                console.error("GetOccupation Err:", err);
            });
    };

    const getCapabilities = (capabilitiesParams ?:GetCapabilitiesRequestType) => {
        let params: GetCapabilitiesRequestType = {
            language: _langugage ?? "tr-TR",
            page:  capabilitiesPage,
        };

        if(capabilitiesParams) {
            params = capabilitiesParams;
        }

        RESTService.action("GetCapabilities",params)
            .then((res) => {
                let _getCapabilities = JSON.parse(JSON.stringify(capabilitiesData));
                let newCapabilities = res.payload.filter(newItem => !_getCapabilities.some((oldItem:{ _id: string}) => oldItem._id === newItem._id));
                setCapabilitiesData([..._getCapabilities, ...newCapabilities]);

                if(capabilitiesParams?.search && capabilitiesParams.search.length){
                    setSearchCapabilitiesPage(e => e + 1);
                    setCapabilitiesPage(1);
                } else {
                    setCapabilitiesPage(e => e + 1);
                    setSearchCapabilitiesPage(1);
                }

                if(res.payload.length === 0 || res.payload.length < 20) {
                    setCapabilitiesEndOfData(true);
                }
                setCapabilitiesLoading(false);
            })
            .catch((err) => {
                console.error("GetCapabilities Err:", err);
            });
    };

    // Render Functions
    const renderHeader = () => {
        return <div
            className={classes.headerContainer}
            style={{
                borderBottomColor: colors.stroke,
                borderBottomWidth: borders.line,
                marginBottom: spaces.container,
                backgroundColor: colors.white
            }}
        >
            <div
                className={classes.headerContent}
                style={{
                    paddingBottom: spaces.container * 1.25,
                    paddingTop: spaces.container * 1.25,
                    paddingRight: spaces.container * 2,
                    paddingLeft: spaces.container * 2,
                    marginBottom: spaces.container
                }}
            > 
                {
                    filterSelectStatus === "filterSelect" ? 
                        <div
                            className={classes.linkClickable}
                            onClick={() => {
                                setShowResponsiveFilter(false);
                            }}
                        >
                            <CloseIcon
                                color={colors.textGrey}
                                size={14}
                            />
                        </div> :
                        <div
                            className={classes.linkClickable}
                            onClick={() => {
                                setFilterSelectStatus("filterSelect");
                            }}
                        >
                            <Text
                                variant="body3-regular"
                                color="textDark"
                            >
                                {localize("filters")}
                            </Text>
                        </div>
                }
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("filter")}
                </Text>
                <div
                    onClick={() => {
                        window.localStorage.removeItem("setSelectedForeignLanguageType");
                        window.localStorage.setItem("setIsCandidatesForDisabled","all");
                        window.localStorage.removeItem("setSelectedCapabilitiesType");
                        window.localStorage.removeItem("setSelectedEducationLevel");
                        window.localStorage.setItem("recommended","recommended");
                        window.localStorage.removeItem("setSelectedOccupation");
                        setIsCandidatesForDisabled("all");
                        setSelectedForeignLanguages([]);
                        setSelectedEducationLevel([]);
                        //setSortMethod("recommended");
                        setSelectedCapabilities([]);
                        setSelectedOccupation([]);
                        setSelectedCities([]);
                    }}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    <Text
                        variant="body3-regular"
                        color="textGrey"
                    >
                        {localize("iocore-select-sheet-clear-button")}
                    </Text>
                </div>
            </div>
            {renderOccupationSearch()}
            {renderCapabilitiesSearch()}
        </div>;
    };

    const renderOccupationSearch = () => {
        if(filterSelectStatus !== "occupationSelect") {
            return null;
        }

        return <div
            style={{
                paddingBottom: spaces.container * 1.25,
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2
            }}
        >
            <TextInput
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                placeholder={localize("search-job")}
                onChangeText={(e) => {
                    setSearhOccupation(e);
                    onSearchOccupation(e);
                }}
            />
        </div>;
    };

    const renderCapabilitiesSearch = () => {
        if(filterSelectStatus !== "capabilitiesSelect") {
            return null;
        }

        return <div
            style={{
                paddingBottom: spaces.container * 1.25,
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2
            }}
        >
            <TextInput
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                placeholder={localize("search-skill")}
                onChangeText={(e) => {
                    setSearhCapabilities(e);
                    onSearchCapabilities(e);
                }}
            />
        </div>;
    };

    /*const renderSortOptions = () => {
        if(filterSelectStatus !== "filterSelect") {
            return null;
        }

        return <div
            className={classes.sortContainer}
            style={{
                paddingRight: spaces.container * 1.75,
                paddingLeft: spaces.container * 1.75,
                borderBottomColor: colors.stroke,
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                marginBottom: spaces.container,
                paddingTop: spaces.contentLarge,
                borderWidth: borders.line,
                gap: spaces.contentLarge
            }}
        >
            <Text
                variant="body-medium"
                color="textDark"
            >
                {localize("sorting")}
            </Text>
            <div
                className={classes.sortContent}
                style={{
                    gap: spaces.inline
                }}
            >
                {
                    SORT_FILTER.map((item) => {
                        let isSelected = false;

                        if(sortMethod) {
                            isSelected = sortMethod === item.value ? true : false;
                        }

                        return  <div
                            className={classes.sortItem}
                        >
                            <Text
                                variant="body2-regular"
                                color="textDark"
                            >
                                {localize(item.localizedText)}
                            </Text>
                            <RadioButton
                                isSelected={isSelected}
                                onChange={() => {
                                    setSortMethod(item.value);
                                    window.localStorage.setItem("recommended",item.value);
                                }}
                            />
                        </div>;
                    })
                }
            </div>
        </div>;
    };*/

    const renderFiltersContainer = () => {
        if(filterSelectStatus !== "filterSelect") {
            return null;
        }

        return <div
            className={classes.filtersContainer}
            style={{
                marginBottom: spaces.container * 2,
                paddingTop: spaces.container,
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1
            }}
        >
            {
                FILTERS.map((item) => {
                    let _selectedFilters: string;

                    if(item.value === "citySelect") {
                        _selectedFilters = selectedCities.length === 1 ? selectedCities[0].localizedText : selectedCities.length > 1 ? `${selectedCities.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "educationLevelSelect") {
                        _selectedFilters = selectedEducationLevel.length === 1 ? selectedEducationLevel[0].localizedText : selectedEducationLevel.length > 1 ? `${selectedEducationLevel.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "occupationSelect") {
                        _selectedFilters = selectedOccupation.length === 1 ? selectedOccupation[0].localizedText : selectedOccupation.length > 1 ? `${selectedOccupation.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "capabilitiesSelect") {
                        _selectedFilters = selectedCapabilities.length === 1 ? selectedCapabilities[0].localizedText : selectedCapabilities.length > 1 ? `${selectedCapabilities.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    if(item.value === "foreignLanguageSelect") {
                        _selectedFilters = selectedForeignLanguages.length === 1 ? selectedForeignLanguages[0].localizedText : selectedForeignLanguages.length > 1 ? `${selectedForeignLanguages.length} ${localize("IOCoreSelectBoxSelectedText")}` : localize("all");
                    }

                    return <RowCard
                        title={localize("location-city")}
                        onClick={() => {
                            setFilterSelectStatus(item.value);
                        }}
                        renderTitle={(title) => {
                            return <div
                                style={{
                                    flexDirection: "column",
                                    display: "flex"
                                }}
                            >
                                <Text
                                    variant="body-medium"
                                    color="textDark"
                                >
                                    {localize(item.localizedText)}
                                </Text>
                                <Text
                                    variant="body3-regular"
                                    color="textGrey"
                                >
                                    {_selectedFilters}
                                </Text>
                            </div>;
                        }}
                        style={{
                            borderBottom: "solid",
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderFilterButton = () => {
        return <div
            className={classes.filterButtonContainer}
            style={{
                paddingRight: spaces.container * 2,
                paddingLeft: spaces.container * 2,
                paddingBottom: spaces.contentLarge
            }}
        >
            <Button
                spreadBehaviour="stretch"
                onClick={() => {
                    setFilterSelectStatus("filterSelect");
                    if(filterSelectStatus === "filterSelect") {
                        setShowResponsiveFilter(false);
                        onSearch();
                    }
                }}
                size="large"
                title={localize("filter-it")}
                color="secondary"
            />
        </div>;
    };

    const renderCitiesSelect = () => {
        if(filterSelectStatus !== "citySelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                citiesData.map((item) => {
                    let isSelected = false;

                    if(selectedCities.length) {
                        isSelected =  selectedCities.findIndex((e) => e.cityID === item._id) !== -1;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={item._id}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeCities(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderOccupationSelect = () =>  {
        if(filterSelectStatus !== "occupationSelect") {
            return null;
        }

        return  <div
            className={classes.occupationSelectContainer}
            onScroll={(e) => {
                if(occupationLoading) {
                    return;
                }
                if(occupationEndOfData) {
                    return;
                }
                if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                    if(searchOccupation.length) {
                        onSearchOccupation(searchOccupation);
                    } else {
                        getOccupations();
                    }
                }
            }}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                occupationRenderData.map((item,index) => {
                    let isSelected = false;

                    if(selectedOccupation.length) {
                        isSelected =  selectedOccupation.findIndex((e) => e.occupationID === item._id) !== -1;
                    }

                    return <div
                        className={classes.citiesSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            borderColor: colors.stroke,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeOccupation(item);
                            }}
                        />
                    </div>;
                })
            }
            {
                occupationLoading ? <Loading/> : null
            }
        </div>;
    };

    const renderCapabilitiesSelect = () =>  {
        if(filterSelectStatus !== "capabilitiesSelect") {
            return null;
        }
        
        return  <div
            className={classes.occupationSelectContainer}
            onScroll={(e) => {
                if(capabilitiesLoading) {
                    return;
                }
                if(capabilitiesEndOfData) {
                    return;
                }
                setCapabilitiesLoading(true);
                if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                    if(searchCapabilities.length) {
                        onSearchCapabilities(searchCapabilities);
                    } else {
                        getCapabilities();
                    }
                }
            }}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                capabilitiesRenderData.map((item,index) => {
                    let isSelected = false;

                    if(selectedCapabilities.length) {
                        isSelected =  selectedCapabilities.findIndex((e) => e.capabilitiesID === item._id) !== -1;
                    }
                    
                    return <div
                        className={classes.citiesSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            borderColor: colors.stroke,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            onChange={() => {
                                onChangeCapabilities(item);
                            }}
                            key={item._id}
                        />
                    </div>;
                })
            }
            {
                capabilitiesLoading ? <Loading/> : null
            }
        </div>;
    };

    const renderEducationLevelsSelect = () =>  {
        if(filterSelectStatus !== "educationLevelSelect") {
            return null;
        }

        return  <div
            className={classes.educationLevelSelectContainer}
            onScroll={(e) => {
                if(educationLevelEndOfData) {
                    return;
                }
                getEducationLevel();
            }}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                educationLevelData.map((item,index) => {
                    let isSelected = false;

                    if(selectedEducationLevel.length) {
                        isSelected =  selectedEducationLevel.findIndex((e) => e.educationLevelID === item._id) !== -1;
                    }

                    return <div
                        className={classes.educationLevelSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            borderColor: colors.stroke,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeEducationLevel(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderForeignLanguagesSelect = () =>  {
        if(filterSelectStatus !== "foreignLanguageSelect") {
            return null;
        }

        return  <div
            className={classes.educationLevelSelectContainer}
            onScroll={(e) => {
                if(foreignLanguagesEndOfData) {
                    return;
                }
                getForeignLanguages();
            }}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                foreignLanguagesData.map((item,index) => {
                    let isSelected = false;

                    if(selectedForeignLanguages.length) {
                        isSelected =  selectedForeignLanguages.findIndex((e) => e.foreignLanguageID === item._id) !== -1;
                    }

                    return <div
                        className={classes.educationLevelSelectContent}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            borderColor: colors.stroke,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {item.localizedText}
                        </Text>
                        <CheckBox
                            isSelected={isSelected}
                            key={item._id}
                            onChange={() => {
                                onChangeForeignLanguage(item);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };
     
    const renderDisabledCandidatesSelect = () => {
        if(filterSelectStatus !== "disabledCandidatesSelect") {
            return null;
        }

        return <div
            className={classes.citiesSelectContainer}
            style={{
                marginBottom: spaces.container * 2,
                backgroundColor: colors.layer1
            }}
        >
            {
                DISABLED_CANDIDATES_FILTERS.map((item,index) => {
                    let isSelected = false;

                    if(isCandidatesForDisabled) {
                        isSelected =  isCandidatesForDisabled === item.value ? true : false;
                    }

                    return  <div
                        className={classes.citiesSelectContent}
                        key={index}
                        style={{
                            paddingLeft: spaces.contentLarge * 1.5,
                            borderBottomColor: colors.stroke,
                            borderBottomWidth: borders.line,
                            paddingBottom: spaces.item,
                            paddingRight: spaces.item,
                            paddingTop: spaces.item
                        }}
                    >
                        <Text
                            variant="body-regular"
                            color="textDark"
                        >
                            {localize(item.localizedText)}
                        </Text>
                        <RadioButton
                            isSelected={isSelected}
                            key={item.id}
                            onChange={() => {
                                setIsCandidatesForDisabled(item.value);
                                window.localStorage.setItem("setIsCandidatesForDisabled",item.value);
                            }}
                        />
                    </div>;
                })
            }
        </div>;
    };

    if(!showResponsiveFilter) {
        return null;
    }

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.layer3,
            padding: spaces.container
        }}
    >
        {renderHeader()}
        {/*renderSortOptions()*/}
        {renderFiltersContainer()}
        {renderCitiesSelect()}
        {renderEducationLevelsSelect()}
        {renderOccupationSelect()}
        {renderCapabilitiesSelect()}
        {renderForeignLanguagesSelect()}
        {renderDisabledCandidatesSelect()}
        {renderFilterButton()}
    </div>;
};

export default ResponsiveFilters;
