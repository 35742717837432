import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    menuItem: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
    },
    verticalLine: {
        borderRadius: 10,
        height: 20,
        width: 6
    }
});
export default useStyles;