import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
    },
    mainContent: {
        boxSizing: "border-box",
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        maxWidth: 1440,
        width: "100%",
        "@media screen and (max-width: 1200px)": {
            display: "none"
        }
    },
    responsiveMainContent: {
        boxSizing: "border-box",
        flexDirection: "column",
        marginRight: "auto",
        marginLeft: "auto",
        display: "flex",
        maxWidth: 1440,
        width: "100%",
        "@media screen and (min-width: 1200px)": {
            display: "none"
        }
    },
    renderAboutUs: {
        flexDirection: "column",
        textAlign: "center",
        display: "flex"
    },
    chipContainer: {
        justifyContent: "center",
        display: "flex",
        flexGrow: 1
    },
    companyGoalsContainer: {
        justifyContent: "center",
        flexWrap: "wrap",
        display: "flex",
        flexGrow: 1
    },
    renderWhyChooseUs: {
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        width: "100%",
        "@media screen and (max-width: 1200px)": {
            flexDirection: "column"
        }
    },
    whyChooseUsTextContainer: {
        justifyContent: "space-around",
        flexDirection: "column",
        display: "flex"
    },
    imageContainer: {
        objectFit: "cover",
        maxWidth: 550,
        minWidth: 400,
        width: "100%",
        "@media screen and (max-width: 450px)": {
            maxWidth: "300px !important",
            minWidth: "300px !important",
            height: "auto"
        }
    }, 
    image: {
        objectFit: "cover",
        height: "100%",
        width: "100%"
    }
});