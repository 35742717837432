import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Clock = ({
    color= "#2563EB",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M29 15c0 7.732-6.268 14-14 14S1 22.732 1 15 7.268 1 15 1s14 6.268 14 14Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill="#fff"
            fillRule="evenodd"
            d="M15 8.35c.58 0 1.05.47 1.05 1.05v5.165l3.192 3.193a1.05 1.05 0 0 1-1.485 1.485l-3.5-3.5A1.05 1.05 0 0 1 13.95 15V9.4c0-.58.47-1.05 1.05-1.05Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Clock;
