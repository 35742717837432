import {
    useEffect,
    useState,
    useRef
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    Pagination,
    TextInput,
    Loading,
    Button,
    Chip,
    Text
} from "isinolacak-web-cl";
import {
    SelectedExperienceLevelType,
    SelectedFiltersObjectType,
    SelectedOccupationType,
    SelectedContractType,
    SelectedWorkingType,
    SelectedFiltersType,
    SelectedCitiesType,
    SearchStorageType,
    DisabledAdsType,
    StickerObjectType
} from "./types";
import {
    ArrowDownIcon,
    MagnifierIcon,
    RadarIcon
} from "../../../assets/svgr";
import {
    ResponsiveFilters,
    FiltersContainer,
    AlarmContainer,
    ResultCard
} from "./views";
import {
    SORT_FILTER,
    NO_RESULT
} from "./constants";
import {
    RESTService
} from "../../../services/restAPI";
import {
    SearchAdvertsRequestType,
    SearchAdvertsObjectType,
    AdvertDateSearchType,
    SearchSortType
} from "../../../services/restAPI/actions/searchAdverts/types";
import {
    GetPopularSearchesResponseType
} from "../../../services/restAPI/actions/getPopularSearches/types";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import {
    FilterSelectType
} from "./views/responsiveFilter/types";
import {
    EmailVerificationContainer,
    Footer
} from "../../../components";
import {
    getErrorText
} from "../../../utils";

const SearchPage = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [selectedExperienceLevel, setSelecetedExperienceLevel] =  useState<Array<SelectedExperienceLevelType>>([]);
    const [selectedContractType, setSelectedContractType] =  useState<Array<SelectedContractType>>([]);
    const [selectedOccupation, setSelectedOccupation] =  useState<Array<SelectedOccupationType>>([]);
    const [selectedWorkingType, setSelectedWorkingType] =  useState<Array<SelectedWorkingType>>([]);
    const [selectedAdvertDate, setSelectedAdvertDate] = useState<AdvertDateSearchType>("all");
    const [isAdvertForDisabled, setIsAdvertForDisabled] = useState<DisabledAdsType>("all");
    const [selectedCities, setSelectedCities] = useState<Array<SelectedCitiesType>>([]);
    const [hideAppliedAdverts, setHideAppliedAdverts] = useState<boolean>(false);

    const [filterSelectStatus, setFilterSelectStatus] = useState<FilterSelectType>("filterSelect");
    const [searchResultData, setSearchResultData] = useState<Array<SearchAdvertsObjectType>>([]);
    const [popularAds, setPopularAds] = useState<GetPopularSearchesResponseType>([]);
    const [showResponsiveFilter, setShowResposiveFilter] = useState<boolean>(false);
    const [bookmarkLoading, setBookmarkLoading] = useState<string | undefined>();
    const [sortMethod, setSortMethod] = useState<SearchSortType>("recommended");
    const [totalSearchResult, setTotalSearchResult] = useState<number>(0);
    const [isSortDropdown, setIsSortDropdown] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isİnitial, setIsInitial] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState<string>("");
    const [isSmall, setIsSmall] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    
    const firstRender = useRef(true);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsSortDropdown(false);
        }
    };

    let _storedFilter: SearchStorageType = {
    };

    useEffect(() => {
        let filters: SearchAdvertsRequestType = {
            page: page
        };

        let storedFiters = window.localStorage.getItem("storedSearchFilters");

        /* 
        //useSearchParams keys for designated states
        s searchText
        e selectedExperienceLevel
        c selectedContractType
        o selectedOccupation
        w selectedWorkingType
        l selectedCities
        d selectedAdvertDate
        x isAdvertForDisabled
        h hideAppliedAdverts
        r sortMethod
        */

        if(searchParams.size > 0) {
            if(searchParams.get("s")) { 
                let _searchText = searchParams.get("s");
    
                if(_searchText?.length) {
                    setSearchText(_searchText);

                    filters.searchText = _searchText;
                }
            }

            if(searchParams.get("l")) { 
                let _setSelectedCities = searchParams.get("l");

                if(_setSelectedCities?.length) {
                    let __setSelectedCities = JSON.parse(_setSelectedCities);
                    setSelectedCities(__setSelectedCities);

                    //@ts-ignore
                    let _selectedCityID: Array<string> = __setSelectedCities.map((item) => {
                        return item.cityID;
                    });
        
                    filters.cityID = _selectedCityID;
                }
            }

            if(searchParams.get("e")) { 
                let _selectedExperienceLevel = searchParams.get("e");
    
                if(_selectedExperienceLevel?.length) {
                    let __selectedExperienceLevel = JSON.parse(_selectedExperienceLevel);
                    setSelecetedExperienceLevel(__selectedExperienceLevel);
                    //@ts-ignore
                    let _selectedExperienceID: Array<string> = __selectedExperienceLevel.map((item) => {
                        return item.experienceLevelID;
                    });
        
                    filters.experienceLevelID = _selectedExperienceID;
                }
            }

            if(searchParams.get("c")) { 
                let _selectedContractType = searchParams.get("c");
    
                if(_selectedContractType?.length) {
                    let __selectedContractType = JSON.parse(_selectedContractType);
                    setSelectedContractType(__selectedContractType);
                    //@ts-ignore
                    let _selectedContractTypeID: Array<string> = __selectedContractType.map((item) => {
                        return item.contractTypeID;
                    });
        
                    filters.contractTypeID = _selectedContractTypeID;
                }
            }

            if(searchParams.get("o")) { 
                let _selectedOccupation = searchParams.get("o");
    
                if(_selectedOccupation?.length) {
                    let __selectedOccupation = JSON.parse(_selectedOccupation);
                    setSelectedOccupation(__selectedOccupation);
                    //@ts-ignore
                    let _selectedOccupationTypeID: Array<string> = __selectedOccupation.map((item) => {
                        return item.occupationID;
                    });
        
                    filters.occupationID = _selectedOccupationTypeID;
                }
            }

            if(searchParams.get("w")) { 
                let _selectedWorkingType = searchParams.get("w");
    
                if(_selectedWorkingType?.length) {
                    let __selectedWorkingType = JSON.parse(_selectedWorkingType);
                    setSelectedWorkingType(__selectedWorkingType);
                    //@ts-ignore
                    let __selectedWorkingTypeID: Array<string> = __selectedWorkingType.map((item) => {
                        return item.workingTypeID;
                    });
        
                    filters.workingTypeID = __selectedWorkingTypeID;
                }
            }

            if(searchParams.get("d")) { 
                let _selectedAdvertDate = searchParams.get("d");
    
                if(_selectedAdvertDate?.length) {
                    //@ts-ignore
                    setSelectedAdvertDate(_selectedAdvertDate);
                    //@ts-ignore
                    filters.advertDate = _selectedAdvertDate;
                }
            }

            if(searchParams.get("x")) { 
                let _isAdvertForDisabled = searchParams.get("x");
    
                if(_isAdvertForDisabled?.length) {
                    //@ts-ignore
                    setIsAdvertForDisabled(true);
                    
                    //@ts-ignore
                    filters.isShowDisabledPeopleAdverts = "true";
                }
            }

            if(searchParams.get("h")) { 
                let _hideAppliedAdverts = searchParams.get("h");
                if(hideAppliedAdverts) {
                    setHideAppliedAdverts(true);
                    //@ts-ignore
                    filters.isHideAppliedAdverts = "true";
                }
            }

            if(searchParams.get("r")) { 
                let _sortMethod = searchParams.get("r");
    
                if(_sortMethod?.length) {
                    //@ts-ignore
                    setSortMethod(_sortMethod);
                    //@ts-ignore
                    filters.sortMethod = _sortMethod;
                }
            }
        } else if(storedFiters) {
            let parsedStoredFilters:SearchStorageType  = JSON.parse(storedFiters);

            if(parsedStoredFilters.selectedExperienceLevel) {
                setSelecetedExperienceLevel(parsedStoredFilters.selectedExperienceLevel);
                let _selectedCityID: Array<string> = parsedStoredFilters.selectedExperienceLevel.map((item) => {
                    return item.experienceLevelID;
                });
        
                filters.experienceLevelID = _selectedCityID;
            }

            if(parsedStoredFilters.selectedCities) {
                setSelectedCities(parsedStoredFilters.selectedCities);
                let _selectedCityID: Array<string> = parsedStoredFilters.selectedCities.map((item) => {
                    return item.cityID;
                });
        
                filters.cityID = _selectedCityID;
            }

            if(parsedStoredFilters.hideAppliedAdverts) {
                setHideAppliedAdverts(parsedStoredFilters.hideAppliedAdverts);
                filters.isHideAppliedAdverts = "true";
            }

            if(parsedStoredFilters.isAdvertForDisabled) {
                setIsAdvertForDisabled(parsedStoredFilters.isAdvertForDisabled);
                if(parsedStoredFilters.isAdvertForDisabled !== "all") {
                    filters.isShowDisabledPeopleAdverts = parsedStoredFilters.isAdvertForDisabled;
                }
            }

            if(parsedStoredFilters.selectedAdvertDate) {
                setSelectedAdvertDate(parsedStoredFilters.selectedAdvertDate);
                if(parsedStoredFilters.selectedAdvertDate !== "all") {
                    filters.advertDate = parsedStoredFilters.selectedAdvertDate;
                }
            }

            if(parsedStoredFilters.selectedContractType) {
                setSelectedContractType(parsedStoredFilters.selectedContractType);
                let _contractTypeID: Array<string> = parsedStoredFilters.selectedContractType.map((item) => {
                    return item.contractTypeID;
                });
        
                filters.contractTypeID = _contractTypeID;
            }

            if(parsedStoredFilters.sortMethod) {
                setSortMethod(parsedStoredFilters.sortMethod);
                if(parsedStoredFilters.sortMethod !== "recommended") {
                    filters.sortMethod = parsedStoredFilters.sortMethod;
                }
            }

            if(parsedStoredFilters.selectedWorkingType) {
                setSelectedWorkingType(parsedStoredFilters.selectedWorkingType);
                let _workingTypeID: Array<string> = parsedStoredFilters.selectedWorkingType.map((item) => {
                    return item.workingTypeID;
                });
        
                filters.workingTypeID = _workingTypeID;
            }

            if(parsedStoredFilters.selectedOccupation) {
                setSelectedOccupation(parsedStoredFilters.selectedOccupation);
                let _occupationID: Array<string> = parsedStoredFilters.selectedOccupation.map((item) => {
                    return item.occupationID;
                });
        
                filters.occupationID = _occupationID;
            }
        }

        onSearch(true,filters);
        getPopularAds();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(!firstRender.current) {
            onSearch();
        }
        firstRender.current = false;
    }, [page]);

    useEffect(() => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
        
        window.addEventListener("resize", onResize);   
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    

    const onResize = () => {
        if(window.innerWidth < 650) {
            setIsSmall(true);
        } else {
            setIsSmall(false);
        }
    };

    const onSearch = (initial?: boolean, __filters?:SearchAdvertsRequestType) => {
        setLoading(true);
        let filters: SearchAdvertsRequestType = {
            page: page
        };

        if(!initial) {
            setButtonLoading(true);

            if(searchText.length) {
                filters.searchText = searchText;
                setSearchParams(e => {
                    e.set("s",searchText);
                    return e;
                }, {
                    replace: true
                });
            } else {
                searchParams.delete("s");
                setSearchParams(searchParams);
            }

            if(selectedExperienceLevel.length) {
                _storedFilter.selectedExperienceLevel = selectedExperienceLevel;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _experienceLevelID: Array<string> = selectedExperienceLevel.map((item) => {
                    return item.experienceLevelID;
                });

                setSearchParams(e => {
                    e.set("e",JSON.stringify(selectedExperienceLevel));
                    return e;
                }, {
                    replace: true
                });

                filters.experienceLevelID = _experienceLevelID;
            } else {
                _storedFilter.selectedExperienceLevel = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("e");
                setSearchParams(searchParams);
            }

            if(selectedContractType.length) {
                _storedFilter.selectedContractType = selectedContractType;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _contractTypeID: Array<string> = selectedContractType.map((item) => {
                    return item.contractTypeID;
                });

                setSearchParams(e => {
                    e.set("c",JSON.stringify(selectedContractType));
                    return e;
                }, {
                    replace: true
                });

                filters.contractTypeID = _contractTypeID;
            } else {
                _storedFilter.selectedContractType = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("c");
                setSearchParams(searchParams);
            }

            if(selectedOccupation.length) {
                _storedFilter.selectedOccupation = selectedOccupation;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _occupationID: Array<string> = selectedOccupation.map((item) => {
                    return item.occupationID;
                });

                setSearchParams(e => {
                    e.set("o",JSON.stringify(selectedOccupation));
                    return e;
                }, {
                    replace: true
                });

                filters.occupationID = _occupationID;
            } else {
                _storedFilter.selectedOccupation = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
                searchParams.delete("o");
                setSearchParams(searchParams);
            }

            if(selectedWorkingType.length) {
                _storedFilter.selectedWorkingType = selectedWorkingType;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _workingTypeID: Array<string> = selectedWorkingType.map((item) => {
                    return item.workingTypeID;
                });

                setSearchParams(e => {
                    e.set("w",JSON.stringify(selectedWorkingType));
                    return e;
                }, {
                    replace: true
                });

                filters.workingTypeID = _workingTypeID;
            } else {
                _storedFilter.selectedWorkingType = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("w");
                setSearchParams(searchParams);
            }

            if(selectedCities.length) { 
                _storedFilter.selectedCities = selectedCities;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                let _cityID: Array<string> = selectedCities.map((item) => {
                    return item.cityID;
                });

                setSearchParams(e => {
                    e.set("l",JSON.stringify(selectedCities));
                    return e;
                }, {
                    replace: true
                });

                filters.cityID = _cityID;
            } else {
                _storedFilter.selectedCities = selectedCities;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("l");
                setSearchParams(searchParams);
            }

            if(selectedAdvertDate !== "all") {
                _storedFilter.selectedAdvertDate = selectedAdvertDate;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("d",selectedAdvertDate);
                    return e;
                }, {
                    replace: true
                });

                filters.advertDate = selectedAdvertDate;
            } else {
                _storedFilter.selectedAdvertDate = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("d");
                setSearchParams(searchParams);
            }

            if(isAdvertForDisabled !== "all") {
                _storedFilter.isAdvertForDisabled = isAdvertForDisabled;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("x",isAdvertForDisabled);
                    return e;
                }, {
                    replace: true
                });

                filters.isShowDisabledPeopleAdverts = isAdvertForDisabled;
            } else {
                _storedFilter.isAdvertForDisabled = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("x");
                setSearchParams(searchParams);
            }

            if(hideAppliedAdverts) {
                _storedFilter.hideAppliedAdverts = hideAppliedAdverts;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("h","true");
                    return e;
                },{
                    replace: true
                });

                filters.isHideAppliedAdverts = "true";
            } else {
                _storedFilter.hideAppliedAdverts = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("h");
                setSearchParams(searchParams);
            }

            if(sortMethod !== "recommended") {
                _storedFilter.sortMethod = sortMethod;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                setSearchParams(e => {
                    e.set("r",sortMethod);
                    return e;
                }, {
                    replace: true
                });

                filters.sortMethod = sortMethod;
            } else {
                _storedFilter.sortMethod = undefined;
                window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));

                searchParams.delete("r");
                setSearchParams(searchParams);
            }
        }

        RESTService.action("SearchAdverts",initial && __filters ? __filters : filters)
            .then((res) => {
                setTotalSearchResult(res.totalDataCount);
                setSearchResultData(res.payload);
                setButtonLoading(false);
                setLoading(false);
            })
            .catch((err) => {
                setButtonLoading(false);
                setLoading(false);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
                console.error("SearchAdverts Err:", err);
            });
    };

    const onChangeSelectedFilters = (_filters: SelectedFiltersType, item: SelectedFiltersObjectType) => {
        if(item.type === "advertDate") {
            setSelectedAdvertDate("all");
            _storedFilter.selectedAdvertDate = "all";
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "cities") {
            let  _cities: Array<SelectedCitiesType> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData =selectedCities.findIndex(e => e.cityID === item.id);
            _cities.splice(isExistsInSelectedData,1);
            setSelectedCities(_cities);
            _storedFilter.selectedCities = _cities;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "contractType") {
            let  _contractType: Array<SelectedContractType> = JSON.parse(JSON.stringify(selectedContractType));
            const isExistsInSelectedData =selectedContractType.findIndex(e => e.contractTypeID === item.id);
            _contractType.splice(isExistsInSelectedData,1);
            setSelectedContractType(_contractType);

            _storedFilter.selectedContractType = _contractType;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "disabledAdverts") {
            setIsAdvertForDisabled("all");
            _storedFilter.isAdvertForDisabled = "all";
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "experienceLevel") {
            let  _experienceLevel: Array<SelectedExperienceLevelType> = JSON.parse(JSON.stringify(selectedExperienceLevel));
            const isExistsInSelectedData =selectedExperienceLevel.findIndex(e => e.experienceLevelID === item.id);
            _experienceLevel.splice(isExistsInSelectedData,1);
            setSelecetedExperienceLevel(_experienceLevel);

            _storedFilter.selectedExperienceLevel = _experienceLevel;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "sort") {
            setSortMethod("recommended");
            _storedFilter.sortMethod = "recommended";
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "occupation") {
            let  _occupation: Array<SelectedOccupationType> = JSON.parse(JSON.stringify(selectedOccupation));
            const isExistsInSelectedData =selectedOccupation.findIndex(e => e.occupationID === item.id);
            _occupation.splice(isExistsInSelectedData,1);
            setSelectedOccupation(_occupation);

            _storedFilter.selectedOccupation = _occupation;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "workingType") {
            let  _workingType: Array<SelectedWorkingType> = JSON.parse(JSON.stringify(selectedWorkingType));
            const isExistsInSelectedData =selectedWorkingType.findIndex(e => e.workingTypeID === item.id);
            _workingType.splice(isExistsInSelectedData,1);
            setSelectedWorkingType(_workingType);
            _storedFilter.selectedWorkingType = _workingType;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }

        if(item.type === "hideAdvert") {
            setHideAppliedAdverts(false);
            _storedFilter.hideAppliedAdverts = false;
            window.localStorage.setItem("storedSearchFilters",JSON.stringify(_storedFilter));
            return;
        }
    }; 

    const getPopularAds = () => {
        RESTService.action("GetPopularSearches",{
        })
            .then((res) => {
                setPopularAds(res);
            })
            .catch((err) => {
                console.error("GetPopularSearches Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const addToFavorite = (id: string) => {
        setBookmarkLoading(id);
        RESTService.action("AddAdvertToFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data = JSON.parse(JSON.stringify(searchResultData));
                const currentIndex = searchResultData.findIndex(item => {
                    return item._id === id;
                });

                _data[currentIndex].isFavorite = true;
                setSearchResultData(_data);
            })
            .catch((err) => {
                console.error("AddAdvertToFavorite Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            }).finally(() => {
                setBookmarkLoading(undefined);
            });
    };

    const removeFromFavorite = (id: string) => {
        setBookmarkLoading(id);

        RESTService.action("RemoveAdvertFromFavorite",{
            advertID : id
        })
            .then((res) => {
                let _data = JSON.parse(JSON.stringify(searchResultData));
                const currentIndex = searchResultData.findIndex(item => {
                    return item._id === id;
                });

                _data[currentIndex].isFavorite = false;
                setSearchResultData(_data);
            })
            .catch((err) => {
                console.error("RemoveAdvertFromFavorite Err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            }).finally(() => {
                setBookmarkLoading(undefined);
            });
    };

    const renderSearchContainer = () => {
        return <div
            className={classes.searchContianer}
            style={{   
                borderBottomColor: colors.stroke,
                backgroundColor: colors.white,
                borderTopColor: colors.stroke,
            }}
        >
            <div 
                className={classes.searchContainerInner} 
                style={{
                    paddingRight: spaces.containerXXLarge,
                    paddingLeft: spaces.containerXXLarge,
                    paddingBottom: spaces.content,
                    paddingTop: spaces.content,
                    gap: spaces.content
                }}>
                <TextInput
                    initialValue={searchParams.get("s") ? searchParams.get("s") as string : searchText}
                    placeholder={localize("search-ads-for-you")}
                    className={classes.searchTextInput}
                    spreadBehaviour="free"
                    clearEnabled= {true}
                    iconOnClick={() => {
                        onSearch();
                    }}
                    onKeyDown={(e) => {
                        if(e.key === "Enter"){
                            onSearch();               
                        }
                    }}
                    icon={() => {
                        return <div
                            style={{
                                marginRight: spaces.content / 2
                            }}
                        >
                            <MagnifierIcon
                                color={colors.textGrey}
                                size={20}
                            />
                        </div>;
                    }}
                    onChangeText={(e) => {
                        setSearchText(e);
                        setIsInitial(false);
                    }}
                />
                <Button
                    className={classes.searchTextButton}
                    title={localize("search-ads")}
                    onClick={() => {
                        onSearch();
                        setIsInitial(false);
                    }}
                    spreadBehaviour="free"
                    loading={buttonLoading}
                />
            </div>
        </div>;
    };

    const renderSearchResponsiveContainer = () => {
        return <div
            className={classes.searchContianerResponsive}
            style={{
                paddingRight: spaces.container * 2.3,
                paddingLeft: spaces.content * 2.3,
                borderBottomColor: colors.stroke,
                paddingTop: spaces.content * 3,
                backgroundColor: colors.white,
                paddingBottom: spaces.content,
                borderTopColor: colors.stroke,
                gap: spaces.container
            }}
        >
            <TextInput
                placeholder={localize("search-ads-for-you")}
                clearEnabled= {true}
                iconOnClick={() => {
                    setIsInitial(false);
                    onSearch();
                }}
                icon={() => {
                    return <div
                        style={{
                            marginRight: spaces.content / 2
                        }}
                    >
                        <MagnifierIcon
                            color={colors.textGrey}
                            size={20}
                        />
                    </div>;
                }}
                onChangeText={(e) => {
                    setSearchText(e);
                }}
            />
            <Button
                onClick={() => {
                    setShowResposiveFilter(!showResponsiveFilter);
                }}
                title={localize("filter-and-sort")}
                spreadBehaviour="baseline"
                variant="outline"
                color="textGrey"
            />
        </div>;
    };

    const renderNoResult = () => {
        if(searchResultData.length) {
            return null;
        }

        if(loading) {
            return null;
        }

        return <div
            className={classes.noResultContainer}
            style={{
                padding: spaces.content * 2,
                borderRadius: radiuses.hard,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.noResultContent}
                style={{
                    marginBottom: spaces.content * 2.33,
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {localize("no-result-short")}
                </Text>
                <Text
                    variant="body3-regular"
                    color="textDark"
                >
                    {localize("no-result-long")}
                </Text>
            </div>
            <div
                className={classes.noResultContent}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    NO_RESULT.map((item) => {
                        return <Text
                            variant="body4-regular"
                            color="textDark"
                        >
                        &#x2022; {localize(item.localizeText)}
                        </Text>;
                    })
                }
            </div>
        </div>;
    };

    const renderIndicatorsContainer = () => {
        return <div
            className={classes.indicatorContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.container
            }}
        >
            <div
                className={classes.indicatorInfoContent}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                >
                    {localize("advert-count-length",[totalSearchResult ? totalSearchResult : 0])} 
                </Text>
                <div
                    style={{
                        position: "relative",
                        zIndex: 99
                    }}
                >
                    <Button
                        onClick={() => {
                            if(isSortDropdown) {
                                setIsSortDropdown(false);
                            } else {
                                setIsSortDropdown(true);
                            }
                        }}
                        title={sortMethod === "recommended" ? localize("recomended") : sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new")}
                        textColor="textSecondary"
                        color="textSecondary"
                        variant="outline"
                        iconDirection="right"
                        icon={() => <ArrowDownIcon
                            color={colors.textGrey}
                        />
                        }
                    />
                    <div
                        
                    >
                        {renderSortDropdown()}
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderSortDropdown = () => {
        if(!isSortDropdown) {
            return null;
        }

        return <div
            className={classes.sortContainer}
            ref={dropdownRef}
            style={{
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                padding: spaces.content
            }}
        >
            {
                SORT_FILTER.map((item, index) => {
                    return <Button
                        key={`menu-item${index}`}
                        title={localize(item.localizedText)}
                        textVariant='body3-regular'
                        textColor='textGrey'
                        variant='ghost'
                        onClick={() => {
                            setSortMethod(item.value);
                            setIsSortDropdown(false);
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderChoosenFilters = (_filters :SelectedFiltersType) => {
        return <div
            className={classes.choosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersTitle}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {`${localize("choosen-filters")} (${_filters.length})`}
                </Text>
                <div
                    style={{
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        window.localStorage.removeItem("storedSearchFilters");
                        setSelecetedExperienceLevel([]);
                        setIsAdvertForDisabled("all");
                        setSelectedAdvertDate("all");
                        setHideAppliedAdverts(false);
                        setSelectedContractType([]);
                        setSortMethod("recommended");
                        setSelectedWorkingType([]);
                        setSelectedOccupation([]);
                        setSelectedCities([]);
                    }}
                >
                    <Text
                        variant="body4-regular"
                        color="primary"
                    >
                        {localize("clear-filters")}
                    </Text>
                </div>
            </div>
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderFilters = () => {
        return <div
            className={classes.filterMainContainer}
        >
            <FiltersContainer
                setSelecetedExperienceLevel={setSelecetedExperienceLevel}
                selectedExperienceLevel={selectedExperienceLevel}
                setSelectedContractType={setSelectedContractType}
                setSelectedWorkingType={setSelectedWorkingType}
                setIsAdvertForDisabled={setIsAdvertForDisabled}
                setSelectedOccupation={setSelectedOccupation}
                setSelectedAdvertDate={setSelectedAdvertDate}
                setHideAppliedAdverts={setHideAppliedAdverts}
                selectedContractType={selectedContractType}
                isAdvertForDisabled={isAdvertForDisabled}
                selectedWorkingType={selectedWorkingType}
                hideAppliedAdverts={hideAppliedAdverts}
                selectedAdvertDate={selectedAdvertDate}
                selectedOccupation={selectedOccupation}
                setSelectedCities={setSelectedCities}
                selectedCities={selectedCities}
                setSortMethod={setSortMethod}
                sortMethod={sortMethod}
                onSearch={onSearch}
            />
            <ResponsiveFilters
                setSelecetedExperienceLevel={setSelecetedExperienceLevel}
                selectedExperienceLevel={selectedExperienceLevel}
                setSelectedContractType={setSelectedContractType}
                setShowResponsiveFilter={setShowResposiveFilter}
                setSelectedWorkingType={setSelectedWorkingType}
                setIsAdvertForDisabled={setIsAdvertForDisabled}
                setSelectedOccupation={setSelectedOccupation}
                setSelectedAdvertDate={setSelectedAdvertDate}
                setFilterSelectStatus={setFilterSelectStatus}
                setHideAppliedAdverts={setHideAppliedAdverts}
                showResponsiveFilter={showResponsiveFilter}
                selectedContractType={selectedContractType}
                isAdvertForDisabled={isAdvertForDisabled}
                selectedWorkingType={selectedWorkingType}
                filterSelectStatus={filterSelectStatus}
                hideAppliedAdverts={hideAppliedAdverts}
                selectedAdvertDate={selectedAdvertDate}
                selectedOccupation={selectedOccupation}
                setSelectedCities={setSelectedCities}
                selectedCities={selectedCities}
                setSortMethod={setSortMethod}
                sortMethod={sortMethod}
                onSearch={onSearch}
            />
        </div>;
    };

    const renderLoading = () => {
        if(!loading) {
            return null;
        }

        return <div
            className={classes.loadingContainer}
            style={{
                marginTop: spaces.container
            }}
        >
            <Loading/>
        </div>;
    };

    const renderSearchResults = () => {
        if(!searchResultData.length) {
            return null;
        }

        if(loading) {
            return null;
        }

        return <div
            className={classes.searchResultsContainer}
            style={{
                gap: spaces.content
            }}
        >
            {
                searchResultData.map((item,index) => {
                    let stickers: Array<StickerObjectType> = [];

                    item.boosted.forEach((boost) => {
                        stickers.push({
                            localizedText: boost.localizedText,
                            backColor: boost.backColor,
                            color: boost.color,
                            icon: () => <div
                                dangerouslySetInnerHTML={{
                                    __html: boost.icon
                                }}
                            ></div>,
                            key: boost.key
                        });
                    });

                    return <ResultCard
                        key={index}
                        experienceLevel={item.experienceLevel.localizedText}
                        contractType={item.contractType.localizedText}
                        workingType={item.workingType.localizedText}
                        companyName={item.companyInfo.title}
                        jobImage={item.companyInfo.photoURL}
                        city={item.city.localizedText}
                        stickers={stickers}
                        isSaved={item.isFavorite}
                        title={item.occupation.localizedText}
                        id={item._id}
                        onPressBookMark={(e) => {
                            e.stopPropagation();
                            if(item.isFavorite) {
                                removeFromFavorite(item._id);
                            } else {
                                addToFavorite(item._id);
                            }
                        }}
                        onPress={() => {
                            navigate("/app/jobDetails",{
                                state: {
                                    jobID: item._id
                                }
                            });
                        }}
                        bookmarkLoading={bookmarkLoading}
                    />;
                })
            }
        </div>;
    };

    const renderMobilSearchResults = () => {
        if(!searchResultData.length) {
            return null;
        }

        if(showResponsiveFilter) {
            return null;
        }

        return <div
            className={classes.searchMobilResultsContainer}
            style={{
                gap: spaces.content
            }}
        >
            {
                searchResultData.map((item,index) => {
                    let stickers: Array<StickerObjectType> = [];

                    item.boosted.forEach((boost) => {
                        stickers.push({
                            localizedText: boost.localizedText,
                            backColor: boost.backColor,
                            color: boost.color,
                            icon: () => <div
                                dangerouslySetInnerHTML={{
                                    __html: boost.icon
                                }}
                            ></div>,
                            key: boost.key
                        });
                    });

                    return <ResultCard
                        key={index}
                        experienceLevel={item.experienceLevel.localizedText}
                        contractType={item.contractType.localizedText}
                        workingType={item.workingType.localizedText}
                        companyName={item.companyInfo.title}
                        jobImage={item.companyInfo.photoURL}
                        city={item.city.localizedText}
                        stickers={stickers}
                        isSaved={item.isFavorite}
                        title={item.occupation.localizedText}
                        onPressBookMark={(e) => {
                            e.stopPropagation();
                            if(item.isFavorite) {
                                removeFromFavorite(item._id);
                            } else {
                                addToFavorite(item._id);
                            }
                        }}
                        id={item._id}
                        onPress={() => {
                            navigate("/app/jobDetails",{
                                state: {
                                    jobID: item._id
                                }
                            });
                        }}
                    />;
                })
            }
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                marginTop: isİnitial ? spaces.containerXLarge * 3 : spaces.containerLarge * 1.9,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <RadarIcon 
                    color={colors.primary}
                    size={45}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("see-today's-ads")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
            </Text>
            <Button
                title={localize("discover")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/search?d=today");
                }}
            />
        </div>;
    };

    const renderInitialContainer = () => {
        if(!isİnitial) {
            return null;
        }

        return <div
            className={classes.mostSearchedContainer}
            style={{
                gap: spaces.content,
                marginBottom: spaces.content
            }}
        >
            <Text
                variant="body2-medium"
                color="textDark"
            >
                {localize("popular-searches")}
            </Text>
            <div
                className={classes.popularAdsChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    popularAds.map((item) => {
                        return <Chip
                            title={item.localizedText}
                            onClick={() => {
                                setSelectedOccupation([
                                    {
                                        localizedText: item.localizedText,
                                        occupationID: item._id
                                    }
                                ]);
                                onSearch();
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderPagination = () => {
        if(totalSearchResult < 20) {
            return null;
        }
        return <div
            className={classes.paginationContainer}
            style={{
                marginBottom: spaces.containerXLarge,
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <Pagination
                totalDataCount= {totalSearchResult}
                currentPage={page}
                itemPerPage= {20}
                onSelect={(item, index) => {
                    setPage(item.pageNumber);
                }}
            />
        </div>;
    };

    const renderResposivePagination = () => {
        if(totalSearchResult < 20) {
            return null;
        }

        if(showResponsiveFilter) {
            return null;
        }

        return <div
            className={classes.responsivePaginationContainer}
            style={{
                marginBottom: spaces.content,
                gap: spaces.content
            }}
        >
            <Text
                variant="body4-medium"
            >
                {localize("current-search-page",[Math.ceil((totalSearchResult / 20)),page])}
            </Text>
            <div
                className={classes.responsivePaginationContent}
                style={{
                    gap: spaces.content
                }}
            >
                <Button
                    title={localize("previus-page")}
                    textColor="textWhite"
                    disabled={page === 1}
                    color="textSecondary"
                    onClick={() => {
                        setPage(page - 1);
                    }}
                />
                <Button
                    disabled={searchResultData?.length / 20 < 1}
                    title={localize("next-page")}
                    textColor="textWhite"
                    onClick={() => {
                        setPage(page + 1);
                    }}
                
                />   
            </div>
        </div>;
    };

    const renderHeader =()=> {
        let _filters: SelectedFiltersType = [];

        if(selectedExperienceLevel.length) {
            let _experienceLevels: SelectedFiltersType = selectedExperienceLevel.map((item) => {
                return {
                    id: item.experienceLevelID,
                    localizedText: item.localizedText,
                    type: "experienceLevel"
                };
            });

            _filters = [..._filters, ..._experienceLevels];
        }

        if(selectedContractType.length) {
            let _contractType: SelectedFiltersType = selectedContractType.map((item) => {
                return {
                    id: item.contractTypeID,
                    localizedText: item.localizedText,
                    type: "contractType"
                };
            });

            _filters = [..._filters, ..._contractType];
        }

        if(selectedOccupation.length) {
            let _occupations: SelectedFiltersType = selectedOccupation.map((item) => {
                return {
                    id: item.occupationID,
                    localizedText: item.localizedText,
                    type: "occupation"
                };
            });

            _filters = [..._filters, ..._occupations];
        }

        if(selectedWorkingType.length) {
            let _workingTypes: SelectedFiltersType = selectedWorkingType.map((item) => {
                return {
                    id: item.workingTypeID,
                    localizedText: item.localizedText,
                    type: "workingType"
                };
            });

            _filters = [..._filters, ..._workingTypes];
        }

        if(selectedCities.length) { 
            let _cities: SelectedFiltersType = selectedCities.map((item) => {
                return {
                    id: item.cityID,
                    localizedText: item.localizedText,
                    type: "cities"
                };
            });

            _filters = [..._filters, ..._cities];
        }

        if(selectedAdvertDate !== "all") {
            let _selectedAdvertDate: SelectedFiltersObjectType = {
                id: selectedAdvertDate,
                localizedText: selectedAdvertDate === "today" ? localize("todays-ad") : selectedAdvertDate === "thisWeek" ? localize("this-weeks-ads") : selectedAdvertDate === "last12Hours" ? localize("last-12-hours") : localize("last-3-hours"),
                type: "advertDate"
            };

            _filters.push(_selectedAdvertDate);
        }

        if(isAdvertForDisabled !== "all") {
            let _isAdvertForDisabled: SelectedFiltersObjectType = {
                id: isAdvertForDisabled,
                localizedText: isAdvertForDisabled === "true" ? localize("only-disabled-ads") : localize("no-disabled-ads"),
                type: "disabledAdverts"
            };

            _filters.push(_isAdvertForDisabled);
        }

        if(hideAppliedAdverts) {
            let _isAdvertForDisabled: SelectedFiltersObjectType = {
                id: hideAppliedAdverts ? "true" : "false",
                localizedText: hideAppliedAdverts ? localize("hide-aplied-ads") : "",
                type: "hideAdvert"
            };

            _filters.push(_isAdvertForDisabled);
        }

        if(sortMethod !== "recommended") {
            let _sortMethod: SelectedFiltersObjectType = {
                id: sortMethod,
                localizedText: sortMethod === "newToOld" ? localize("new-to-old") : localize("old-to-new"),
                type: "sort"
            };

            _filters.push(_sortMethod);
        }

        if(!_filters.length) {
            return null;
        }

        let _experienceLevel: Array<string> = selectedExperienceLevel.map((item) => {
            return item.experienceLevelID.toString();
        });

        let _contractType: Array<string> = selectedContractType.map((item) => {
            return item.contractTypeID.toString();
        });

        let _workingType: Array<string> = selectedWorkingType.map((item) => {
            return item.workingTypeID.toString();
        });

        let _occupation: Array<string> = selectedOccupation.map((item) => {
            return item.occupationID.toString();
        });

        let _cities : Array<string> = selectedCities.map((item) =>{
            return item.cityID.toString();
        });

        return <div
            className={classes.headerContainer}
        >
            {
                _filters.length &&
                    renderChoosenFilters(_filters)
            }
            {
                _occupation.length ?
                    <AlarmContainer
                        isDisabledPeople= {isAdvertForDisabled === "true" ? true : isAdvertForDisabled === "false" && false}
                        experienceLevel = {_experienceLevel}
                        contractType = {_contractType}
                        workingType = {_workingType}
                        occupation = {_occupation}
                        city = {_cities}
                    />
                    :null
            }
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            backgroundColor: colors.grey25,
        }}
    >
        {renderSearchContainer()}
        {renderSearchResponsiveContainer()}
        <div
            className={classes.contentContianer}
            style={{
                paddingRight: !isSmall ? spaces.containerXXLarge : spaces.content * 2,
                paddingLeft: !isSmall ? spaces.containerXXLarge : spaces.content * 2,
                paddingBottom: spaces.containerXXLarge,
                paddingTop: spaces.content * 1.5,
                marginBottom: spaces.container,
                backgroundColor: colors.grey25,
                gap: spaces.container * 3
            }}
        >
            <div
                className={classes.leftContainer}
            >
                {renderInitialContainer()}
                {renderIndicatorsContainer()}
                <div
                    className={classes.filterContainer}
                    style={{
                        gap: spaces.containerXLarge
                    }}
                >
                    {renderFilters()}
                    {isSmall && renderHeader()}
                    {renderMobilSearchResults()}
                    <div
                        className={classes.innerContent}
                    >
                        {!isSmall && renderHeader()}
                        {renderSearchResults()}
                        {renderLoading()}
                        {renderNoResult()}
                    </div>
                </div>
            </div>
            {renderRightContainer()}
        </div>
        {renderPagination()}
        {renderResposivePagination()}
        <Footer/>
    </div>;
};
export default SearchPage;
