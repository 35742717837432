import React, {
    useEffect,
    useState,
    Fragment,
    useRef
} from "react";
import useStyles from "./styles";
import {
    DateTimePicker,
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    CheckBox,
    Text
} from "isinolacak-web-cl";
import {
    NewWorkExperience
} from "./types";
import {
    CircleExitIcon
} from "../../assets/svgr";
import {
    RESTService
} from "../../services/restAPI";
import {
    GetOccupationResponseObjectType,
    GetOccupationRequestType
} from "../../services/restAPI/actions/getOccupation/types";
import moment from "moment";
import {
    getErrorText 
} from "../../utils";

const NewJobExperience = ({
    onChangeExperienceLevel,
    removeWorkExperience,
    onChangeCompany,
    onChangeIsWork,
    workExperience,
    handleChange,
    showExitIcon,
    setErrors,
    errors,
    index
}: NewWorkExperience) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();
    
    const [getOccupation, setGetOccupation] = useState<Array<GetOccupationResponseObjectType>>([]);
    const [occupationsSearchPage, setOccupationsSearchPage] = useState(1);
    const [searchOccupationText, setSearchOccupationText] = useState("");
    const [occupationsLoading, setOccupationsLoading] = useState(false);
    const [occupationsPage, setOccupationsPage] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);
    
    const searchDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    
    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getOccupationData();
    }, []);

    useEffect(() => {
        search(searchOccupationText);
    }, [searchOccupationText]);

    const search = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if(searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getOccupationData({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupationData();
        }
    };

    const getOccupationData = (searchParams?: GetOccupationRequestType) => {
        if(!occupationsLoading) setOccupationsLoading(true);

        let params: GetOccupationRequestType = {
            page: occupationsPage,
        };

        if(searchOccupationText && searchOccupationText.length) {
            params.search = searchOccupationText;
            params.page = occupationsSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetOccupation", params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setGetOccupation(res.payload);

                if(searchParams?.search || searchOccupationText.length){
                    setOccupationsPage(1);
                } else {
                    setOccupationsPage(params.page ?? 1);
                    setOccupationsSearchPage(1);
                }

                setOccupationsLoading(false);
            })
            .catch((err) => {
                setOccupationsLoading(false);
                console.error("GetOccupation Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderJobExperience = () => {
        let initialItem: Array<GetOccupationResponseObjectType> | [] = workExperience[index].jobTitle && workExperience[index].jobTitle._id.length ? [workExperience[index].jobTitle] : [];

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.removeDiv}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {localize("work-experience")}  
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeWorkExperience(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <TextInput
                infoText={errors.includes(`workExperience-${index}-companyName-length-error`) ? localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.some(err => err === `workExperience-${index}-companyName-length-error`)}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = `workExperience-${index}-companyName-length-error`;
                    let _index = _errors.indexOf(nameError);
                    _errors.splice(_index, 1);
                        
                    setErrors(_errors);
                }}
                onChangeText={(text) => onChangeCompany(text, index)}
                initialValue={workExperience[index].companyName}
                placeholder={localize("company")}
                style={{
                    marginBottom: spaces.content,
                    marginTop: spaces.content
                }}

            />
            <SelectBox
                infoText={errors.includes(`workExperience-${index}-jobTitleID-length-error`) ? localize("needs-to-be-filled") : undefined}
                isError={errors.some(err => err === `workExperience-${index}-jobTitleID-length-error`)}
                titleExtractor={(e) => e.localizedText}
                initialSelectedItems={initialItem}
                inputTitle={localize("job")}
                keyExtractor={(e) => e._id}
                spreadBehaviour='stretch'
                onSearch={(e) => {
                    setSearchOccupationText(e);
                }}
                style={{
                    marginBottom: spaces.content
                }}
                title={localize("job")}
                isNeedConfirm={true}
                isSearchable={true}
                emptyContent={() => {
                    if(occupationsLoading) {
                        return <Fragment></Fragment>;
                    }

                    return <Text>
                        {localize("no-result-found")}
                    </Text>;
                }}
                multiSelect={false}
                disabled={false}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    const selectedItem = selectedItems[0];
                    if (selectedItem) {
                        //@ts-ignore
                        onChangeExperienceLevel(selectedItem, index);
                        let updatedErrors = errors.filter(err => err !== `workExperience-${index}-jobTitleID-length-error`);
                        setErrors(updatedErrors);
                    }
                    closeSheet();
                    onSuccess();
                }}
                data={getOccupation}
            />
            <div
                className={classes.datePickerInputContainer}
            >
                <DateTimePicker
                    infoText={errors.includes(`workExperience-${index}-startDate-length-error`) ? localize("needs-to-be-filled") : undefined}
                    onFocus={() => {
                        let updatedErrors = errors.filter(err => err !== `workExperience-${index}-startDate-length-error`);
                        setErrors(updatedErrors);
                    }}
                    isError={errors.some(err => err === `workExperience-${index}-startDate-length-error`)}
                    initialValue={moment(workExperience[index].startDate).format("YYYY-MM-DD")}
                    onChangeText={(date) => handleChange("startDate", date, index)}
                    defaultValue={workExperience[index].startDate}
                    //@ts-ignore
                    max={moment(workExperience[index].endDate).format("YYYY-MM-DD")}
                    className={classes.dateTimePicker}
                    spreadBehaviour="stretch"
                    inputType={"date"}
                    style={{
                        marginRight: spaces.content
                    }}
                />
                <DateTimePicker
                    infoText={(() => {
                        if (errors.includes(`workExperience-${index}-endDate-length-error`)) {
                            return localize("needs-to-be-filled");
                        } else if (errors.includes(`workExperience-${index}-endDate-isStillWorkHere-conflict-error`)) {
                            return localize("ı'm-currently-working-here-and-the-end-date-cannot-be-filled-at-the-same-time");
                        } else {
                            return undefined;
                        }
                    })()}
                    onFocus={() => {
                        let updatedErrors = errors.filter(err =>
                            err !== `workExperience-${index}-endDate-length-error` &&
                            err !== `workExperience-${index}-endDate-isStillWorkHere-conflict-error`
                        );
                        setErrors(updatedErrors);
                    }}
                    isError={errors.some(err =>
                        err === `workExperience-${index}-endDate-length-error` ||
                        err === `workExperience-${index}-endDate-isStillWorkHere-conflict-error`
                    )}
                    initialValue={moment(workExperience[index].endDate).format("YYYY-MM-DD")}
                    //@ts-ignore
                    min={moment(workExperience[index].startDate).format("YYYY-MM-DD")}
                    onChangeText={(date) => handleChange("endDate", date, index)}
                    disabled={workExperience[index].isStillWorkHere === true}
                    defaultValue={workExperience[index].endDate}
                    className={classes.dateTimePicker}
                    placeholder={localize("end-date")}
                    spreadBehaviour="stretch"
                    inputType={"date"}
                />
            </div>
            <CheckBox
                isSelected={workExperience[index].isStillWorkHere}
                title={localize("ı'm-still-working-here")}
                onChange={() => {
                    let updatedErrors = JSON.parse(JSON.stringify(errors));
                    const nameErrorEndDate = `workExperience-${index}-endDate-length-error`;
                    const nameErrorIsWorkHere = `workExperience-${index}-endDate-isStillWorkHere-conflict-error`;
                
                    updatedErrors = updatedErrors.filter((error: string) =>
                        error !== nameErrorEndDate &&
                        error !== nameErrorIsWorkHere
                    );
                    setErrors(updatedErrors);
                    onChangeIsWork(index);}
                }
                style={{
                    marginTop: spaces.content,
                    width: "100%"
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderJobExperience()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default NewJobExperience;
