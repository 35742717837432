import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    LoginResponseType,
    LoginRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const Login = async (params: LoginRequestType): Promise<LoginResponseType> => {
    return await axios(`${SERVER_ADDRESS}/authentication/login`, {
        method: "POST",
        headers: getHeaders(),
        data: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                if(!res.data.isSMSOTPRequired) {
                    window.localStorage.setItem("accessToken", res.data.accessToken);
                    window.localStorage.setItem("refreshToken", res.data.refreshToken);
                    window.localStorage.setItem("chatAccessToken",res.data.chatAccessToken);
                }
                return res.data;
            }
            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default Login;
