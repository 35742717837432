import React, {
    useEffect,
    useState,
    useRef
} from "react";
import useStyles from "./styles";
import {
    SelectDialog,
    IOCoreLocale,
    IOCoreTheme,
    Pagination,
    Loading,
    Button,
    Text,
    Chip,
    StateCard
} from "isinolacak-web-cl";
import {
    SelectedCandidateFiltersType,
    CandidateManageViewsType
} from "./types";
import {
    ArrowDownIcon,
    OpenFolderIcon,
    ProfileIcon,
    RadarIcon,
    WidgetAddIcon
} from "../../../assets/svgr";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import CurrentCandidate from "./views/currentCandidate";
import CandidateCard from "./views/candidateCard";
import PTResults from "./views/ptResults";
import DropDown from "./views/dropDown";
import {
    CANDIDATE_STATUS,
    CANDIDATE_POINT,
    CANDIDATE_EXP
} from "./constants";
import {
    SelectedItem
} from "./views/dropDown/types";
import {
    RESTService
} from "../../../services/restAPI";
import {
    GetAplicationsForEmployerResponseType,
    GetAplicationsForEmployerRequestType,
    PTAnswerForEmployerObjectType,
    ApplicationsObjectType
} from "../../../services/restAPI/actions/getAplicationsForEmployer/types";
import {
    HandleApplicationStatusType
} from "../../../services/restAPI/actions/employerHandleApplication/types";
import moment from "moment";
import SendMesage from "./views/sentMessage";
import {
    getErrorText,
    uuid
} from "../../../utils";

const CandidateManagement = () => {
    const classes = useStyles();

    const navigate = useNavigate();

    const {
        state
    } = useLocation();

    const urlParams = useParams();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [aplianceData, setAplianceData] = useState<GetAplicationsForEmployerResponseType>();
    const [selectedCapabilities, setSelectedCapabilities] = useState<Array<SelectedItem>>([]);
    const [selectedExperience, setSelectedExperience] = useState<Array<SelectedItem>>([]);
    const [ptResults, setPTResults] = useState<Array<PTAnswerForEmployerObjectType>>();
    const [currentCandidate, setCurrentCandidate] = useState<ApplicationsObjectType>();
    const [rCapabilitySelecetVisible, setRCapabilitySelectVisible] = useState(false);
    const [rExperienceSelecetVisible, setRExperienceSelectVisible] = useState(false);
    const [capabilitySelecetVisible, setCapabilitySelectVisible] = useState(false);
    const [experienceSelecetVisible, setExperienceSelectVisible] = useState(false);
    const [selectedPoints, setSelectedPoints] = useState<Array<SelectedItem>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<SelectedItem>>([]);
    const [selectedStatus, setSelectedStatus] = useState<Array<SelectedItem>>([]);
    const [aplicants, setAplicants] = useState<Array<ApplicationsObjectType>>([]);
    const [views, setViews] = useState<CandidateManageViewsType>("candidateInfo");
    const [rLocationSelecetVisible, setRLocationSelectVisible] = useState(false);
    const [locationSelecetVisible, setLocationSelectVisible] = useState(false);
    const [showAllWorkExperience, setShowAllWorkExperience] = useState(false);
    const [showCandidateContact, setShowCandidateContact] = useState(false);
    const [rPointSelecetVisible, setRPointSelectVisible] = useState(false);
    const [isShowMoreDocuments, setIsShowMoreDocuments] = useState(false);
    const [showAllCapabilities, setShowAllCapabilities] = useState(false);
    const [pointSelecetVisible, setPointSelectVisible] = useState(false);
    const [showAllEducation, setShowAllEducation] = useState(false);
    const [rIsStatus, setRIsStatus] = useState(false);
    const [isStatus, setIsStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [advertID, setAdvertID] = useState("");
    const [page, setPage] = useState(1);

    const candidateContactDropdownRef = useRef<HTMLDivElement>(null);
    const capabilityDropdownRef = useRef<HTMLDivElement>(null);
    const experineceDropdownRef = useRef<HTMLDivElement>(null);
    const locationDropdownRef = useRef<HTMLDivElement>(null);
    const statusDropdownRef = useRef<HTMLDivElement>(null);
    const pointDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(state) {
            setAdvertID(state.advertID);
            getApplications({
                advertID: state.advertID
            },true);
        } else if (urlParams.id) {
            setAdvertID(urlParams.id);
            getApplications({
                advertID: urlParams.id
            },true);
        } else {
            navigate("/notFound");
        }
    },[]);

    useEffect(() => {
        let _params: GetAplicationsForEmployerRequestType = {
            advertID: advertID,
            page: page
        };

        if(selectedCapabilities.length) {
            _params.capabilityID = selectedCapabilities.map((e) => e.__key);
        }

        if(selectedCities.length) {
            _params.cityID = selectedCities.map((e) => e.__key);
        }

        if(selectedPoints.length) {
            //@ts-ignore
            _params.score = selectedPoints.map((e) => e.__key);
        }

        if(selectedExperience.length) {
            //@ts-ignore
            _params.experience = selectedExperience.map((e) => e.__key);
        }

        getApplications(_params);
    },[selectedCapabilities,selectedCities,selectedExperience,selectedPoints,page]);

    const onChangeSelectedFilters = (_filters: Array<SelectedCandidateFiltersType>, item: SelectedCandidateFiltersType) => {

        if(item.type === "point") {
            let  _points: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedPoints));
            const isExistsInSelectedData = selectedPoints.findIndex(e => e.__key === item.id);
            _points.splice(isExistsInSelectedData,1);
            setSelectedPoints(_points);
            return;
        }

        if(item.type == "experience") {
            let  _experience: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedExperience));
            const isExistsInSelectedData = selectedExperience.findIndex(e => e.__key === item.id);
            _experience.splice(isExistsInSelectedData,1);
            setSelectedExperience(_experience);
            return;
        }

        if(item.type == "location") {
            let  _location: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedCities));
            const isExistsInSelectedData = selectedCities.findIndex(e => e.__key === item.id);
            _location.splice(isExistsInSelectedData,1);
            setSelectedCities(_location);
            return;
        }

        if(item.type == "skill") {
            let  _capabilities: Array<SelectedItem> = JSON.parse(JSON.stringify(selectedCapabilities));
            const isExistsInSelectedData = selectedCapabilities.findIndex(e => e.__key === item.id);
            _capabilities.splice(isExistsInSelectedData,1);
            setSelectedCapabilities(_capabilities);
            return;
        }
    };

    const getApplications = (params?: GetAplicationsForEmployerRequestType,isInitial?: boolean) => {
        let __params: GetAplicationsForEmployerRequestType = {
            advertID: advertID
        };

        if(params) {
            __params = params;
        } else {
            setLoading(true);
        }
        if(isInitial) {
            setLoading(true);
        }
        
        RESTService.action("GetAplicationsForEmployer", __params)
            .then((res) => {
                setAplicants(res.applications);

                setAplianceData(res);

                if(!params) {
                    if(res.applications.length) {
                        setCurrentCandidate(res.applications[0]);
                        if(res.isHavePT) {
                            setPTResults(res.applications[0].performanceTest.questions);
                        }
                    }
                }

                setLoading(false);
            }).catch((err) => {
                console.error("GetAdvertWithDetailForEmployer Err:", JSON.stringify(err,null,4));
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
    
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            });
    };

    const handleDownload = (url: string) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = uuid();
                document.body.appendChild(link);
    
                link.click();
    
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const downloadDocument = (documentID: string) => {
        RESTService.action("ExportApplicantDocument", {
            documentID: documentID
        }).then((res) => {
            handleDownload(res.downloadURL);
        }).catch((err) => {
            console.error("ExportDocumentr Err:", JSON.stringify(err,null,4));
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const downloadAllDocument = (documentID: string) => {
        if(!currentCandidate?.documents.length) {
            return alert(localize("docs-not-sended"));
        }

        RESTService.action("ExportApplicantDocumentInBulk", {
            applicationID: documentID
        }).then((res) => {
            let _links:Array<string> = [];
            if(res.downloadURLs && res.downloadURLs.length) {
                _links = res.downloadURLs;

                _links.forEach(async(e) => {
                    handleDownload(e);
                });
            } else {
                alert(localize("docs-not-sended"));
            }

        }).catch((err) => {
            console.error("ExportDocumentr Err:", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const handleCandidateStatus = (status: HandleApplicationStatusType) => {
        if(!currentCandidate?._id.length) {
            return null;
        }

        if(status === "document" && !aplianceData?.isHaveDT) {
            alert(localize("advert-no-doc"));
        }

        if(status === "performanceTest" && !aplianceData?.isHavePT) {
            alert(localize("advert-no-pt"));
        }

        setLoading(true);
        RESTService.action("EmployerHandleApplication", {
            applicationID: currentCandidate?._id,
            status: status
        }).then((res) => {
            let _currentCandidate:ApplicationsObjectType = JSON.parse(JSON.stringify(currentCandidate));

            if(status === "performanceTest") {
                _currentCandidate.status = "performanceTestWaiting";
                setCurrentCandidate(_currentCandidate);
            }

            if(status === "document") {
                _currentCandidate.status = "documentWaiting";
                setCurrentCandidate(_currentCandidate);
            }

            if(status === "finished") {
                _currentCandidate.status = "finished";
                setCurrentCandidate(_currentCandidate);
            }

            if(status === "rejected") {
                _currentCandidate.status = "rejected";
                setCurrentCandidate(_currentCandidate);
            }
            setLoading(false);
        }).catch((err) => {
            console.error("EmployerHandleApplication Err:", JSON.stringify(err,null,4));
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const handleDocumentStatus = (id: string, status: boolean) => {
        RESTService.action("HandleSubmittedDocument", {
            approved: status,
            documentID: id
        }).then((res) => {
            let _currentCandidate:ApplicationsObjectType = JSON.parse(JSON.stringify(currentCandidate));

            let  _index =  _currentCandidate.documents.findIndex((e) => e._id === id);

            if(_index !== -1) {
                if(status) {
                    _currentCandidate.documents[_index].status = "approved";
                    setCurrentCandidate(_currentCandidate);
                } else {
                    _currentCandidate.documents[_index].status = "rejected";
                    setCurrentCandidate(_currentCandidate);
                }
            }
            setLoading(false);
        }).catch((err) => {
            console.error("EmployerHandleDocument Err:", JSON.stringify(err,null,4));
            setLoading(false);
            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);

                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(errorData.errorText));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.message));
                return;
            }
        });
    };

    const renderLeftContainer = () => {
        if(loading) {
            return <div
                className={classes.leftLoading}
            >
                <Loading/>
            </div>;
        }

        if(aplianceData?.totalApplicationCount === 0) {
            return renderNoApplicant();
        }

        return <div
            className={classes.leftMainContainer}
        >
            <div
                className={classes.leftContainer}
                style={{
                    backgroundColor: colors.layer1,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    marginBottom: spaces.content
                }}
            >
                <div
                    style={{
                        padding: spaces.container
                    }}
                >
                    <div>
                        <Text
                            variant="body-medium"
                            color="textDark"
                        >
                            {`${aplicants.length} ${localize("application")}`}
                        </Text>
                    </div>
                    <div
                        style={{
                            marginBottom: spaces.content
                        }}
                    >
                        <Text
                            variant="body4-regular"
                            color="textDark"
                        >
                            {localize("all-candidates")} {/* TODO: change for filter */}
                        </Text>
                    </div>
                </div>
                {aplicants.map((item) => (
                    <CandidateCard
                        occupation={item.occupation.localizedText}
                        location={item.city.localizedText}
                        profilePhoto={item.photoURL}
                        whenAplied={item.appliedAt}
                        fullName={item.fullName}
                        matchPoint={item.score}
                        status={item.status}
                        id={item._id}
                        onClick={() => {
                            const isExistsInSelectedData = aplicants.findIndex(e => e._id === item._id);
                            if(isExistsInSelectedData !== -1) {
                                setCurrentCandidate(aplicants[isExistsInSelectedData]);
                            }
                        }}
                    />
                ))}
            </div>
            {renderPagination()}
        </div>;
    };

    const renderNoApplicant = () => {
        return <div
            className={classes.noApplicant}
            style={{
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                paddingTop: spaces.container,
                paddingRight: spaces.content,
                gap: spaces.containerXXLarge,
                borderRadius: radiuses.hard,
                paddingLeft: spaces.content,
                borderColor: colors.stroke,
                borderWidth:borders.line
            }}
        >
            <div
                className={classes.noApplicantHeader}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                >
                    {localize("no-aplicant-header")}
                </Text>
                <Text
                    variant="body4-regular"
                >
                    {localize("no-aplicant-content")}
                </Text>
            </div>
            <div>
                <StateCard
                    title={localize("no-aplicant-statecard-header")}
                    content={localize("no-aplicant-statecard-content")}
                    icon={() => <OpenFolderIcon
                        size={50}
                    />}
                />
            </div>
        </div>;
    };

    const renderResponsiveNoApplicant = () => {
        return <div
            className={classes.responsiveNoApplicant}
            style={{
                paddingBottom: spaces.container,
                backgroundColor: colors.layer1,
                paddingTop: spaces.container,
                paddingRight: spaces.content,
                gap: spaces.containerXXLarge,
                borderRadius: radiuses.hard,
                paddingLeft: spaces.content,
                borderColor: colors.stroke,
                borderWidth:borders.line
            }}
        >
            <div
                className={classes.noApplicantHeader}
                style={{
                    gap: spaces.content
                }}
            >
                <Text
                    variant="body-medium"
                >
                    {localize("no-aplicant-header")}
                </Text>
                <Text
                    variant="body4-regular"
                >
                    {localize("no-aplicant-content")}
                </Text>
            </div>
            <div>
                <StateCard
                    title={localize("no-aplicant-statecard-header")}
                    content={localize("no-aplicant-statecard-content")}
                    icon={() => <OpenFolderIcon
                        size={50}
                    />}
                />
            </div>
        </div>;
    };

    const renderPagination = () => {
        if(aplicants.length < 10) {
            return null;
        } 
        return <div
            className={classes.paginationContainer}
            style={{
                marginBottom: spaces.containerXLarge,
                backgroundColor: colors.white,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <Pagination
                totalDataCount= {aplianceData?.totalApplicationCount ?? aplicants.length}
                currentPage={page}
                itemPerPage= {10}
                onSelect={(item, index) => {
                    setPage(item.pageNumber);
                }}
            />
        </div>;
    };

    const renderResponsiveCandidateList = () => {
        if(views !== "candidateInfo") {
            return null;
        }

        if(loading) {
            return <div
                className={classes.leftLoading}
            >
                <Loading/>
            </div>;
        }

        if(aplianceData?.totalApplicationCount === 0) {
            return renderResponsiveNoApplicant();
        }

        return <div
            className={classes.mainResponsiveCandidateContainer}
        >
            <div
                className={classes.responsiveCandidateContainer}
                style={{
                    backgroundColor: colors.layer1,
                    marginBottom: spaces.content,
                    borderRadius: radiuses.half,
                    borderColor: colors.stroke,
                    borderWidth: borders.line
                }}
            >
                {aplicants.map((item) => (
                    <CandidateCard
                        occupation={item.occupation.localizedText}
                        location={item.city.localizedText}
                        profilePhoto={item.photoURL}
                        whenAplied={item.appliedAt}
                        fullName={item.fullName}
                        matchPoint={item.score}
                        status={item.status}
                        id={item._id}
                        onClick={() => {
                            const isExistsInSelectedData = aplicants.findIndex(e => e._id === item._id);
                            if(isExistsInSelectedData !== -1) {
                                setCurrentCandidate(aplicants[isExistsInSelectedData]);
                                setViews("responsiveLook");
                            }
                        }}
                    />
                ))}
            </div>
            {renderPagination()}
        </div>;
    };

    const renderResponsiveHeader = () => {
        if(loading) {
            return <div
                className={classes.leftLoading}
            >
                <Loading/>
            </div>;
        }

        return <div
            className={classes.responsiveHeader}
            style={{
                backgroundColor: colors.layer1,
                marginBottom: spaces.container,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderResponsiveAdvertInfo()}
            {responsiveCandidateInfo()}
            {renderResponsiveCandidateOperations()}
            {renderResponsiveFilterContainer()}
            {renderResponsiveChoosenFilters()}
        </div>;
    };

    const responsiveCandidateInfo = () => {
        if(views === "candidateInfo") {
            return null;
        }

        if(!currentCandidate) {
            return null;
        }

        return <div
            className={classes.advertDetailsContent}
            style={{
                paddingLeft: spaces.content,
                paddingRight: spaces.content,
                paddingTop: spaces.content
            }}
        >
            <Text
                variant="header4-medium"
                color="textDark"
                style={{
                    marginBottom: spaces.content
                }}
            > 
                {localize("application-of",[currentCandidate.fullName])}
            </Text>
            <div
                className={classes.generalInfo}
                style={{
                    marginBottom: spaces.inline,
                    gap: spaces.inline / 2
                }}
            >
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {currentCandidate.occupation.localizedText}
                </Text>
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {currentCandidate.city.localizedText}
                </Text>
            </div>
            <Text
                variant="body4-regular"
                color="textSecondary"
            >
                {moment(currentCandidate.appliedAt).fromNow()}
            </Text>
        </div>;
    };

    const renderResponsiveCandidateOperations = () => {
        if(views === "candidateInfo") {
            return null;
        }

        if(!currentCandidate) {
            return null;
        }

        return <div
            className={classes.buttonContent}
            style={{
                padding: `0px ${spaces.containerXLarge / 4}px`,
                marginTop: spaces.content,
                marginBottom: spaces.content,
                gap: spaces.content
            }}
        >
            <Button
                title={localize("choose-status")}
                variant="outline"
                size="small"
                onClick={() => {
                    setRIsStatus(true);
                }}
                iconDirection="right"
                icon={() => <ArrowDownIcon
                    size={15}
                    color={colors.primary}
                />}
            />
            <SelectDialog
                data={CANDIDATE_STATUS.map((item, index) => {
                    return {
                        __key: item.value,
                        __title: localize(item.title),
                        __originalIndex: index
                    };
                })}
                initialData={[{
                }]}
                onClose={() => {
                    setRIsStatus(false);
                }}
                isVisible={rIsStatus}
                setSelectedItems={(item) => {
                    setSelectedStatus(item.map((c_item) => {
                        return {
                            __key: c_item.__key,
                            __title: c_item.__title,
                            __originalIndex: c_item.__originalIndex
                        };
                    }));
                }}
                multiSelect={false}
                selectedItems={selectedStatus}
                isNeedConfirm={true}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess,
                }) => {
                //@ts-ignore
                    handleCandidateStatus(selectedItems[0].__key);
                    closeSheet();
                    onSuccess();
                }}
            />
            <SendMesage
                setDropdownVisible={setShowCandidateContact}
                onClickSendMessage={() => {
                    handleChatStart();
                }}
                dropdownref={candidateContactDropdownRef}
                isOptionsVisible={showCandidateContact}
                phoneNumber={currentCandidate.phone}
            />
            <Button
                title={localize("see-profile")}
                onClick={() => navigate("/app/profileUser", {
                    state: {
                        workerID: currentCandidate?.userID
                    }
                })}
                variant="outline"
                iconDirection="right"
                size="small"
                icon={() => <ProfileIcon
                    size={15}
                    color={colors.primary}
                />}
            />
        </div>;
    };

    const handleChatStart = () => {
        if(!currentCandidate?._id) {
            return;
        }
        RESTService.action("ConversationStart", {
            applicationID: currentCandidate?._id,
        })
            .then((res) => {
                navigate("/app/chat", {
                    state: {
                        conversationID: res.conversationID
                    }
                });
            })
            .catch((err) => {
                console.error("ConversationStart err:", err);
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);

                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(errorData.errorText));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.message));
                    return;
                }
            })
        ;
    };

    const renderResponsiveAdvertInfo = () => {
        if(views !== "candidateInfo") {
            return null;
        }

        return <div
            style={{
                padding: spaces.container
            }}
        >
            <div
                style={{
                    marginBottom: spaces.content * 2
                }}
            >
                <Text
                    variant='header5-medium'
                
                >
                    {localize("advert-management")}
                </Text>
            </div>
            <div>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {`${aplicants.length} ${localize("application")}`}
                </Text>
            </div>
            <div
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant="body4-regular"
                    color="textDark"
                >
                    {localize("all-candidates")} {/* TODO: change for filter */}
                </Text>
            </div>
        </div>;
    };

    const renderResponsiveChoosenFilters = () => {
        let _filters: Array<SelectedCandidateFiltersType> = [];

        if(selectedPoints.length) { 
            let _points: Array<SelectedCandidateFiltersType> = selectedPoints.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "point"
                };
            });

            _filters = [..._filters, ..._points];
        }

        if(selectedCapabilities.length) { 
            let _capabilities: Array<SelectedCandidateFiltersType> = selectedCapabilities.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "skill"
                };
            });

            _filters = [..._filters, ..._capabilities];
        }

        if(selectedCities.length) { 
            let _location: Array<SelectedCandidateFiltersType> = selectedCities.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "location"
                };
            });

            _filters = [..._filters, ..._location];
        }

        if(selectedExperience.length) { 
            let _experience: Array<SelectedCandidateFiltersType> = selectedExperience.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "experience"
                };
            });

            _filters = [..._filters, ..._experience];
        }

        if(!_filters.length) {
            return null;
        }

        return <div
            className={classes.responsiveChoosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            key={index}
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderChoosenFilters = () => {
        let _filters: Array<SelectedCandidateFiltersType> = [];

        if(selectedPoints.length) { 
            let _points: Array<SelectedCandidateFiltersType> = selectedPoints.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "point"
                };
            });

            _filters = [..._filters, ..._points];
        }

        if(selectedCapabilities.length) { 
            let _capabilities: Array<SelectedCandidateFiltersType> = selectedCapabilities.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "skill"
                };
            });

            _filters = [..._filters, ..._capabilities];
        }

        if(selectedCities.length) { 
            let _location: Array<SelectedCandidateFiltersType> = selectedCities.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "location"
                };
            });

            _filters = [..._filters, ..._location];
        }

        if(selectedExperience.length) { 
            let _experience: Array<SelectedCandidateFiltersType> = selectedExperience.map((item) => {
                return {
                    id: item.__key,
                    localizedText: item.__title,
                    type: "experience"
                };
            });

            _filters = [..._filters, ..._experience];
        }

        if(!_filters.length) {
            return null;
        }

        return <div
            className={classes.choosenFiltersContainer}
            style={{
                marginBottom: spaces.container,
                gap: spaces.content
            }}
        >
            <div
                className={classes.choosenFiltersChip}
                style={{
                    gap: spaces.content
                }}
            >
                {
                    _filters.map((item, index) => {
                        return <Chip
                            title={item.localizedText}
                            isCancelable= {true}
                            size="medium"
                            key={index}
                            onClick={() => {
                                onChangeSelectedFilters(_filters,item);
                            }}
                        />;
                    })
                }
            </div>
        </div>;
    };

    const renderFilterContainer = () => {
        if(loading) {
            return null;
        }

        if(views !== "candidateInfo") {
            return null;
        }

        return <div
            className={classes.filterContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                marginBottom: spaces.container,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content,
                    marginBottom: spaces.content,
                    gap: spaces.content
                }}
            >
                <DropDown
                    dropdownref={pointDropdownRef}
                    selectedItems={selectedPoints}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={CANDIDATE_POINT.map((item, index) => {
                        return {
                            __key: item.id,
                            __title: localize(item.title),
                            __originalIndex: index
                        };
                    })}
                    buttonTitle={localize("filter-score")}
                    initialData={[{
                    }]}
                    onClickOpen={() => {
                        setPointSelectVisible(true);
                    }}
                    onClose={() => {
                        setPointSelectVisible(false);
                    }}
                    isVisible={pointSelecetVisible}
                    setSelectedItems={(item) => {
                        setSelectedPoints(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <DropDown
                    buttonTitle={localize("filter-location")}
                    isVisible={locationSelecetVisible}
                    dropdownref={locationDropdownRef}
                    selectedItems={selectedCities}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={aplianceData ? aplianceData.cities.map((item, index) => {
                        return {
                            __title: item.localizedText,
                            __originalIndex: index,
                            __key: item._id
                        };
                    }): []}
                    initialData={[{
                    }]}
                    onClickOpen={() => {
                        setLocationSelectVisible(!locationSelecetVisible);
                    }}
                    onClose={() => {
                        setLocationSelectVisible(false);
                    }}
                    setSelectedItems={(item) => {
                        setSelectedCities(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <DropDown
                    buttonTitle={localize("filter-capability")}
                    isVisible={capabilitySelecetVisible}
                    dropdownref={capabilityDropdownRef}
                    selectedItems={selectedCapabilities}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={aplianceData ? aplianceData?.capabilities.map((item, index) => {
                        return {
                            __key: item._id,
                            __title: item.localizedText,
                            __originalIndex: index
                        };
                    }): []}
                    initialData={[{
                    }]}
                    onClickOpen={() => {
                        setCapabilitySelectVisible(!capabilitySelecetVisible);
                    }}
                    onClose={() => {
                        setCapabilitySelectVisible(false);
                    }}
                    setSelectedItems={(item) => {
                        setSelectedCapabilities(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <DropDown
                    buttonTitle={localize("filter-experience")}
                    dropdownref={experineceDropdownRef}
                    selectedItems={selectedExperience}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={CANDIDATE_EXP.map((item, index) => {
                        return {
                            __key: item.id,
                            __title: localize(item.title),
                            __originalIndex: index
                        };
                    })}
                    initialData={[{
                    }]}
                    onClickOpen={() => {
                        setExperienceSelectVisible(!experienceSelecetVisible);
                    }}
                    onClose={() => {
                        setExperienceSelectVisible(false);
                    }}
                    isVisible={experienceSelecetVisible}
                    setSelectedItems={(item) => {
                        setSelectedExperience(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
        </div>;
    };

    const renderResponsiveFilterContainer = () => {
        if(views !== "candidateInfo") {
            return null;
        }

        if(loading) {
            return null;
        }

        return <div
            className={classes.responsiveFilterContainer}
            style={{
                padding: `0px ${spaces.containerLarge / 2}px`,
                marginBottom: spaces.container,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.languageContainer}
                style={{
                    marginTop: spaces.content,
                    marginBottom: spaces.content,
                    gap: spaces.content
                }}
            >
                <Button
                    title={localize("filter-score")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setRPointSelectVisible(true);
                    }}
                />
                <SelectDialog
                    selectedItems={selectedPoints}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={CANDIDATE_POINT.map((item, index) => {
                        return {
                            __key: item.id,
                            __title: localize(item.title), //TODO: check
                            __originalIndex: index
                        };
                    })}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setRPointSelectVisible(false);
                    }}
                    isVisible={rPointSelecetVisible}
                    setSelectedItems={(item) => {
                        setSelectedPoints(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <Button
                    title={localize("filter-location")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setRLocationSelectVisible(true);
                    }}
                />
                <SelectDialog
                    isVisible={rLocationSelecetVisible}
                    selectedItems={selectedCities}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={aplianceData ? aplianceData.cities.map((item, index) => {
                        return {
                            __title: item.localizedText,
                            __originalIndex: index,
                            __key: item._id
                        };
                    }): []}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setRLocationSelectVisible(false);
                    }}
                    setSelectedItems={(item) => {
                        setSelectedCities(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <Button
                    title={localize("filter-capability")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setRCapabilitySelectVisible(true);
                    }}
                />
                <SelectDialog
                    isVisible={rCapabilitySelecetVisible}
                    selectedItems={selectedCapabilities}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={aplianceData ? aplianceData?.capabilities.map((item, index) => {
                        return {
                            __key: item._id,
                            __title: item.localizedText,
                            __originalIndex: index
                        };
                    }): []}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setRCapabilitySelectVisible(false);
                    }}
                    setSelectedItems={(item) => {
                        setSelectedCapabilities(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
                <Button
                    title={localize("filter-experience")}
                    variant="outline"
                    size="small"
                    onClick={() => {
                        setRExperienceSelectVisible(true);
                    }}
                />
                <SelectDialog
                    selectedItems={selectedExperience}
                    isNeedConfirm={true}
                    multiSelect={true}
                    data={CANDIDATE_EXP.map((item, index) => {
                        return {
                            __key: item.id,
                            __title: localize(item.title),
                            __originalIndex: index
                        };
                    })}
                    initialData={[{
                    }]}
                    onClose={() => {
                        setRExperienceSelectVisible(false);
                    }}
                    isVisible={rExperienceSelecetVisible}
                    setSelectedItems={(item) => {
                        setSelectedExperience(item.map((c_item) => {
                            return {
                                __key: c_item.__key,
                                __title: c_item.__title,
                                __originalIndex: c_item.__originalIndex
                            };
                        }));
                    }}
                    onOk={({
                        closeSheet,
                        onSuccess,
                    }) => {
                        closeSheet();
                        onSuccess();
                    }}
                />
            </div>
        </div>;
    };

    const renderContentContainer = () => {
        if(aplianceData?.totalApplicationCount === 0) {
            return renderNoApplicantContent();
        }

        return <div
            className={classes.contentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderLoading()}
            {renderCandidateInfoView()}
            {renderPTResult()}
        </div>;
    };

    const renderNoApplicantContent = () => {
        return <div
            className={classes.noApplicantcontentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <img
                src={require("../../../assets/img/noApplicant.png")}
                className={classes.noApplicanting}
            />
            <StateCard
                title={localize("no-aplicant-content-content")}
                content={localize("no-aplicant-content-header")}
                isAction={true}
                buttonProps={{
                    onClick: () => {
                        navigate("/app/myActions");
                    },
                    title: localize("no-aplicant-content-buton"),
                    spreadBehaviour: "stretch"
                }}
            />
        </div>;
    };

    const renderResponsiveContentContainer = () => {
        if(views === "candidateInfo") {
            return null;
        }

        return <div
            className={classes.ResponsiveContentContainer}
            style={{
                padding: `0px ${spaces.containerXLarge / 2}px`,
                backgroundColor: colors.layer1,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            {renderLoading()}
            {renderResponsiveCandidateInfoView()}
            {renderPTResult()}
        </div>;
    };

    const renderPTResult = () => {
        if(loading) {
            return null;
        }

        if(views !== "ptReview") {
            return null;
        }

        if(!currentCandidate) {
            return null;
        }

        if(!ptResults) {
            return null;
        }

        return <PTResults
            currentAplicant={currentCandidate}
            ptResults={ptResults}
            setViews={setViews}
        />;
    }; 

    const renderCandidateInfoView = () => {
        if(loading) {
            return null;
        }

        if(views === "ptReview") {
            return null;
        }

        return <CurrentCandidate
            candidateContactDropdownRef={candidateContactDropdownRef}
            setShowAllWorkExperience={setShowAllWorkExperience}
            setShowCandidateContact={setShowCandidateContact}
            setIsShowMoreDocuments={setIsShowMoreDocuments}
            setShowAllCapabilities={setShowAllCapabilities}
            handleCandidateStatus={handleCandidateStatus}
            showAllWorkExperience={showAllWorkExperience}
            handleDocumentStatus={handleDocumentStatus}
            showCandidateContact={showCandidateContact}
            isShowMoreDocuments={isShowMoreDocuments}
            setCurrentCandidate={setCurrentCandidate}
            setShowAllEducation={setShowAllEducation}
            showAllCapabilities={showAllCapabilities}
            downloadAllDocument={downloadAllDocument}
            setSelectedStatus={setSelectedStatus}
            statusDropdownRef={statusDropdownRef}
            currentCandidate={currentCandidate}
            downloadDocument={downloadDocument}
            showAllEducation={showAllEducation}
            selectedStatus={selectedStatus}
            setIsStatus={setIsStatus}
            isStatus={isStatus}
            setViews={setViews}
        />;
    };

    const renderResponsiveCandidateInfoView = () => {
        if(loading) {
            return null;
        }

        if(views !== "responsiveLook") {
            return null;
        }

        return <CurrentCandidate
            candidateContactDropdownRef={candidateContactDropdownRef}
            setShowAllWorkExperience={setShowAllWorkExperience}
            setShowCandidateContact={setShowCandidateContact}
            setIsShowMoreDocuments={setIsShowMoreDocuments}
            setShowAllCapabilities={setShowAllCapabilities}
            handleCandidateStatus={handleCandidateStatus}
            showAllWorkExperience={showAllWorkExperience}
            handleDocumentStatus={handleDocumentStatus}
            showCandidateContact={showCandidateContact}
            isShowMoreDocuments={isShowMoreDocuments}
            setCurrentCandidate={setCurrentCandidate}
            setShowAllEducation={setShowAllEducation}
            downloadAllDocument={downloadAllDocument}
            showAllCapabilities={showAllCapabilities}
            setSelectedStatus={setSelectedStatus}
            statusDropdownRef={statusDropdownRef}
            currentCandidate={currentCandidate}
            downloadDocument={downloadDocument}
            showAllEducation={showAllEducation}
            selectedStatus={selectedStatus}
            setIsStatus={setIsStatus}
            isStatus={isStatus}
            isResponsive={true}
            setViews={setViews}
        />;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 2
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    const renderLoading = () => {
        if(!loading) {
            return null;
        }

        return <div
            className={classes.loadingcontainer}
        >
            <Loading/>
        </div>;
    };

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 2}px ${spaces.container * 4}px`,
            backgroundColor: colors.layer3
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("advert-management")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 2
            }}
        >
            
            {renderLeftContainer()}
            {renderResponsiveHeader()}
            {renderResponsiveCandidateList()}
            <div
                className={classes.contentContainerStyle}
            >
                {renderFilterContainer()}
                {renderChoosenFilters()}
                {renderContentContainer()}
                {renderResponsiveContentContainer()}
            </div>
            {renderRightContainer()}
        </div>
    </div>;
};

export default CandidateManagement;
