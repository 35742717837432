import React, {
    useEffect,
    useState
} from "react";
import {
    useStyles
} from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    TextArea,
    Button,
    Text
} from "isinolacak-web-cl";
import {
    CompletePTTypes
} from "./types";
import {
    RESTService
} from "../../../../../services/restAPI";
import {
    RoundArrowIcon,
    InfoIcon
} from "../../../../../assets/svgr";
import {
    GetPerformanceTestResponseType
} from "../../../../../services/restAPI/actions/getPerformanceTest/types";
import {
    PTAnswerType
} from "../../../../../services/restAPI/actions/submitPerformanceTest/types";
import moment from "moment";
import {
    TWO_OPTIONS
} from "../../constants.";
import {
    getErrorText 
} from "../../../../../utils";

const CompletePT = ({
    getMyApplication,
    setViews
}: CompletePTTypes) => {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();
    
    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [ptQuestions, setPTQuestions] = useState<GetPerformanceTestResponseType>();
    const [ptAnswers, setPTAnswers] = useState<Array<PTAnswerType>>([]);

    useEffect(() => {
        getPTQuestions();
    }, []);

    const onChangePoints = (answer: string, id: string) => {
        let _ptAnswers: Array<PTAnswerType> = JSON.parse(JSON.stringify(ptAnswers));

        let _index = ptAnswers.findIndex(e => e.questionID === id);
        if(_index === -1) {
            _ptAnswers.push({
                answer: Number(answer),
                questionID: id
            });
            setPTAnswers(_ptAnswers);
        } else {
            setPTAnswers(_ptAnswers);
            _ptAnswers[_index] = {
                answer: Number(answer),
                questionID: id
            };
        }
    };

    const onChangeYesNo = (answer: string, id: string) => {
        let _ptAnswers: Array<PTAnswerType> = JSON.parse(JSON.stringify(ptAnswers));

        let _index = ptAnswers.findIndex(e => e.questionID === id);
        if(_index === -1) {
            _ptAnswers.push({
                answer: answer === "yes" ? true : false,
                questionID: id
            });
            setPTAnswers(_ptAnswers);
        } else {
            setPTAnswers(_ptAnswers);
            _ptAnswers[_index] = {
                answer: answer === "yes" ? true : false,
                questionID: id
            };
        }
    };

    const onChangeMultiSelecet = (answer: Array<string>, id: string) => {
        let _ptAnswers: Array<PTAnswerType> = JSON.parse(JSON.stringify(ptAnswers));

        let _index = ptAnswers.findIndex(e => e.questionID === id);
        if(_index === -1) {
            _ptAnswers.push({
                answer: answer,
                questionID: id
            });
            setPTAnswers(_ptAnswers);
        } else {
            setPTAnswers(_ptAnswers);
            _ptAnswers[_index] = {
                answer: answer,
                questionID: id
            };
        }
    };

    const onChangeText = (answer: string, id: string)=> {
        let _ptAnswers: Array<PTAnswerType> = JSON.parse(JSON.stringify(ptAnswers));

        let _index = ptAnswers.findIndex(e => e.questionID === id);
    
        if(_index === -1) {
            _ptAnswers.push({
                answer: answer,
                questionID: id
            });
            setPTAnswers(_ptAnswers);
        } else {
            _ptAnswers[_index] = {
                answer: answer,
                questionID: id
            };
            setPTAnswers(_ptAnswers);
        }
    };

    const getPTQuestions = () => {
        if(!getMyApplication) {
            return;
        }

        RESTService.action("GetPerformanceTest", {
            performanceTestID: getMyApplication?.performanceTestID
        })
            .then((res) => {
                setPTQuestions(res);
            })
            .catch((err) => {
                console.error("GetPerformanceTest Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const submitPT = () => {
        if(!getMyApplication) {
            return;
        }

        RESTService.action("SubmitPT", {
            advertApplicationID: getMyApplication?._id,
            answers: ptAnswers
        })
            .then((res) => {
                setViews("default");
                alert(localize("performance-test-was-sent-successfully"));
            })
            .catch((err) => {
                console.error("submitPT Err:", err);
                
                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderTextAnswer = (_id: string) => {
        return <div>
            <TextArea
                onChangeText={(e) => {
                    onChangeText(e,_id);
                }}
            />
        </div>;
    };

    const renderTenPoints = (id: string, index: number) => {
        return <SelectBox
            titleExtractor={(item) => item.value ? item.value : ""}
            inputTitle=""
            keyExtractor={(e) => e.key}
            data={[
                {
                    value: "1",
                    key: "1"
                },
                {
                    value: "2",
                    key: "2"
                },
                {
                    value: "3",
                    key: "3"
                },
                {
                    value: "4",
                    key: "4"
                },
                {
                    value: "5",
                    key: "5"
                },
                {
                    value: "6",
                    key: "6"
                },
                {
                    value: "7",
                    key: "7"
                },
                {
                    value: "8",
                    key: "8"
                },
                {
                    value: "9",
                    key: "9"
                },
                {
                    value: "10",
                    key: "10"
                }
            ]}
            isNeedConfirm={true}
            multiSelect={false}
            disabled={false}
            style={{
                marginBottom: spaces.content * 1.5
            }}
            onOk={({
                selectedItems,
                closeSheet,
                onSuccess
            }) => {
                onChangePoints(selectedItems[0].__key,id);
                closeSheet();
                onSuccess();
            }}
        />;
    };

    const renderFivePoints = (id: string, index: number) => {
        return <SelectBox
            titleExtractor={(item) => item.value ? item.value : ""}
            inputTitle=""
            keyExtractor={(e) => e.key}
            data={[
                {
                    value: "1",
                    key: "1"
                },
                {
                    value: "2",
                    key: "2"
                },
                {
                    value: "3",
                    key: "3"
                },
                {
                    value: "4",
                    key: "4"
                },
                {
                    value: "5",
                    key: "5"
                }
            ]}
            isNeedConfirm={true}
            multiSelect={false}
            disabled={false}
            style={{
                marginBottom: spaces.content * 1.5
            }}
            onOk={({
                selectedItems,
                closeSheet,
                onSuccess
            }) => {
                onChangePoints(selectedItems[0].__key,id);
                closeSheet();
                onSuccess();
            }}
        />;
    };

    const renderTwoOptions = (id: string, index: number) => {
        return <div>
            <SelectBox
                titleExtractor={(item) => item.localizedValue ? localize(item.localizedValue) : ""}
                keyExtractor={(e) => e.localizedValue || ""}
                inputTitle=""
                data={TWO_OPTIONS}
                isNeedConfirm={true}
                multiSelect={false}
                disabled={false}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    onChangeYesNo(selectedItems[0].__key,id);
                    closeSheet();
                    onSuccess();
                }}
            />
        </div>;
    };

    const renderMultiChoise = (id: string, index: number, options:Array<any>) => {
        return <div>
            <SelectBox
                titleExtractor={(item) => item ? item : ""}
                keyExtractor={(e) => e || ""}
                inputTitle=""
                data={options}
                isNeedConfirm={true}
                multiSelect={true}
                disabled={false}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
                onOk={({
                    selectedItems,
                    closeSheet,
                    onSuccess
                }) => {
                    let _seleceteds = selectedItems.map(e => e.__title);
                    onChangeMultiSelecet(_seleceteds,id);
                    closeSheet();
                    onSuccess();
                }}
            />
        </div>;
    };

    const renderApplicationButton = () => {
        if(!ptQuestions) {
            return null;
        }

        let _isAllConfirmed = true;
        ptAnswers.forEach((e) => {
            if(!e.answer) {
                _isAllConfirmed= true;
            }
            if(ptAnswers.length < ptQuestions?.questions.length) {
                _isAllConfirmed= true;
            }
            else {
                _isAllConfirmed= false;
            }
        });

        return <div
            style={{
                marginBottom: spaces.containerLarge,
                marginTop: spaces.contentXLarge
            }}
        >
            <Button
                title={localize("send-reply")}
                disabled={_isAllConfirmed}
                spreadBehaviour="stretch"
                onClick={() => {
                    submitPT();
                }}
            />
        </div>;
    };

    const renderQuesitionList = () => {
        if(!ptQuestions) {
            return null;
        }

        return ptQuestions.questions.map((item, index) => {
            return <div
                className={classes.content}
                style={{
                    gap: spaces.content / 2,
                    marginBottom: spaces.content,
                }}
            >
                <Text
                    variant="header5-medium"
                    color="textDark"
                    style={{
                        marginTop: spaces.content
                    }}
                >
                    {item.question}
                </Text>
                <Text
                    variant="body2-regular"
                    color="textDark"
                >
                    {item.description}
                </Text>
                {
                    item.answerType.key === "2-option-answer" ? renderTwoOptions(item._id,index) :
                        item.answerType.key === "10-point-answer" ? renderTenPoints(item._id,index) :
                            item.answerType.key === "5-point-answer" ? renderFivePoints(item._id,index) :
                                item.answerType.key === "multi-select-answer" ? renderMultiChoise(item._id,index,item.answerOptions) :
                                    item.answerType.key === "open-ended-answer" ? renderTextAnswer(item._id) : null
                }
            </div>;
        });
    };

    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke,
                marginTop: spaces.content,
                height: 1
            }}
        >
        </div>;
    };
    
    return <div
        style={{
            marginTop: spaces.contentXLarge
        }}
    >
        <div
            className={classes.header}
            style={{
                marginBottom: spaces.content,
                gap: spaces.inline / 2
            }}
            onClick={() => {
                setViews("default");
            }}
        >
            <div>
                <RoundArrowIcon
                    color={colors.primary}
                />
            </div>
            <Text
                variant="header4-medium"
                color="textDark"
            >
                {localize("pre-assessment-questions")}
            </Text>
        </div>
        <div
            className={classes.container}
            style={{
                gap: spaces.content / 2
            }}>
            <Text
                variant="body-medium"
                color="textDark"
            >
                {getMyApplication?.occupation.localizedText}
            </Text>
            <Text
                variant="body-regular"
                color="textDark"
            >
                {getMyApplication?.companyInfo.title}
            </Text>
            <div
                className={classes.workTypeContainer}
                style={{
                    gap: spaces.inline / 2
                }}
            >
                <InfoIcon
                    color={colors.primary}
                />
                <Text
                    variant="body-regular"
                    color="textDark"
                >
                    {getMyApplication?.workingType.localizedText} - {getMyApplication?.contractType.localizedText}
                </Text>
                
            </div>
            <Text
                variant="body3-medium"
                color="textSecondary"
            >
                {moment(getMyApplication?.updatedAt).fromNow()}
            </Text>
        </div>
        {renderLine()}
        <div
            style={{
                paddingTop: spaces.content
            }}
        >
            <Text
                variant="header5-medium"
            >
                {localize("myActions-worker-performance-testing")}
            </Text>
            {renderQuesitionList()}
        </div>
        {renderApplicationButton()}
    </div>;
};
export default CompletePT;
