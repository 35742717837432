import React, {
    useEffect,
    useState,
    Fragment,
    useRef
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    SelectBox,
    Text
} from "isinolacak-web-cl";
import {
    CapabilitiesTypes
} from "./types";
import {
    RESTService
} from "../../../../../../../../../../services/restAPI";
import {
    GetCapabilitiesRequestType,
    GetCapabilitiesObjectType
} from "../../../../../../../../../../services/restAPI/actions/getCapabilities/types";
import {
    CircleExitIcon 
} from "../../../../../../../../../../assets/svgr";
import {
    POINT 
} from "../../../../constants";
import {
    getErrorText 
} from "../../../../../../../../../../utils";

const Capabilities = ({
    removeCapabilities,
    onChangeLevel,
    onChangeSkill,
    capabilities,
    showExitIcon,
    setErrors,
    errors,
    index
}: CapabilitiesTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [getCapabilities, setGetCapabilities] = useState<Array<GetCapabilitiesObjectType>>([]);
    const [capabilitiesSearchPage, setCapabilitiesSearchPage] = useState(1);
    const [searchCapabilitiesText, setSearchCapabilitiesText] = useState("");
    const [capabilitiesLoading, setCapabilitiesLoading] = useState(false);
    const [capabilitiesPage, setCapabilitiesPage] = useState(1);
    const [totalDataCount, setTotalDataCount] = useState(1);
    
    const searchDebouncer = useRef<ReturnType<typeof setTimeout> | null>(null);
    
    const _langugage = window.localStorage.getItem("userLanguage");

    useEffect(() => {
        getOccupationData();
    }, []);

    useEffect(() => {
        search(searchCapabilitiesText);
    }, [searchCapabilitiesText]);

    const search = (searchText: string) => {
        if(searchDebouncer.current) {
            clearTimeout(searchDebouncer.current);
        }
        searchDebouncer.current = null;

        if(searchText && searchText.length) {
            searchDebouncer.current = setTimeout(() => {
                getOccupationData({
                    language: _langugage ?? "tr-TR",
                    search: searchText,
                    page: 1
                });

                // @ts-ignore
                clearTimeout(searchDebouncer.current);
                searchDebouncer.current = null;
            }, 750);
        } else if(!searchText || !searchText.length) {
            getOccupationData();
        }
    };

    const getOccupationData = (searchParams?: GetCapabilitiesRequestType) => {
        if(!capabilitiesLoading) setCapabilitiesLoading(true);

        let params: GetCapabilitiesRequestType = {
            page: capabilitiesPage,
        };

        if(searchCapabilitiesText && searchCapabilitiesText.length) {
            params.search = searchCapabilitiesText;
            params.page = capabilitiesSearchPage;
        }

        if(searchParams) {
            params = searchParams;
        }

        params.language = navigator.language ?? "tr-TR";

        RESTService.action("GetCapabilities", params)
            .then((res) => {
                setTotalDataCount(res.totalDataCount);
                setGetCapabilities(res.payload);

                if(searchParams?.search || searchCapabilitiesText.length){
                    setCapabilitiesPage(1);
                } else {
                    setCapabilitiesPage(params.page ?? 1);
                    setCapabilitiesSearchPage(1);
                }

                setCapabilitiesLoading(false);
            })
            .catch((err) => {
                setCapabilitiesLoading(false);
                console.error("GetCapabilities Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderCapabilities = () => {
        //@ts-ignore
        let initialItem: Array<GetCapabilitiesObjectType> | [] = capabilities[index].capabilityID && capabilities[index].capabilityID.length ? [capabilities[index]] : [];

        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <div
                className={classes.remove}
                style={{
                    marginBottom: spaces.content
                }}
            >
                <Text
                    variant='body-regular'
                    color='textDark'
                >
                    {localize("capabilities")}
                </Text>
                {showExitIcon && (
                    <div
                        onClick={() => removeCapabilities(index)}
                    >
                        <CircleExitIcon
                            color={colors.primary}
                        />
                    </div>
                )}
            </div>
            <div
                className={
                    classes.selectBoxContainer
                }
                style={{
                    marginTop: spaces.content
                }}
            >
                <div
                    className={classes.selectBox}
                >
                    <SelectBox
                        infoText={errors.includes(`capabilities-${index}-capabilityID-length-error`) ? localize("needs-to-be-filled") : undefined}
                        //@ts-ignore
                        isError={errors.some(err => err === `capabilities-${index}-capabilityID-length-error`)}
                        titleExtractor={(e) => e.localizedText}
                        inputTitle={localize("capabilities")}
                        initialSelectedItems={initialItem}
                        title={localize("capabilities")}
                        keyExtractor={(e) => e._id}
                        spreadBehaviour='stretch'
                        onSearch={(e) => {
                            setSearchCapabilitiesText(e);
                        }}
                        style={{
                            marginRight: spaces.content
                        }}
                        isNeedConfirm={true}
                        isSearchable={true}
                        emptyContent={() => {
                            if(capabilitiesLoading) {
                                return <Fragment></Fragment>;
                            }
        
                            return <Text>
                                {localize("no-result-found")}
                            </Text>;
                        }}
                        multiSelect={false}
                        disabled={false}
                        paginationProps={
                            totalDataCount > 20 ? {
                                currentPage: searchCapabilitiesText && searchCapabilitiesText.length ? capabilitiesSearchPage : capabilitiesPage,
                                totalDataCount: totalDataCount,
                                itemPerPage: 20,
                                onSelect(item, index) {
                                    if (searchCapabilitiesText && searchCapabilitiesText.length) {
                                        setCapabilitiesSearchPage(item.pageNumber);
                                    } else {
                                        setCapabilitiesPage(item.pageNumber);
                                    }
                    
                                    let params: {
                                        search?: string;
                                        page: number;
                                    } = {
                                        page: item.pageNumber
                                    };
                    
                                    if (searchCapabilitiesText && searchCapabilitiesText.length) {
                                        params.search = searchCapabilitiesText;
                                    }
                    
                                    getOccupationData(params);
                                }
                            } : undefined
                        }
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            const selectedItem = selectedItems[0];
                            if (selectedItem) {
                                //@ts-ignore
                                onChangeSkill(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `capabilities-${index}-capabilityID-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        data={getCapabilities}
                    />
                </div>
                <div
                    className={classes.selectBox}
                >
                    <SelectBox
                        infoText={errors.includes(`capabilities-${index}-point-length-error`) ? localize("needs-to-be-filled") : undefined}
                        initialSelectedItems={capabilities && capabilities[index].point ? [capabilities[index]] : []}
                        isError={errors.some(err => err === `capabilities-${index}-point-length-error`)}
                        titleExtractor={(item: { point: number }) => String(item.point)}
                        keyExtractor={(item: { point: number }) => String(item.point)}
                        disabled={!(capabilities[index].capabilityID.length)}
                        inputTitle={localize("level")}
                        title={localize("level")}
                        isNeedConfirm={true}
                        multiSelect={false}
                        onOk={({
                            selectedItems,
                            closeSheet,
                            onSuccess
                        }) => {
                            //@ts-ignore
                            const selectedItem = selectedItems[0].point;
                            if (selectedItem) {
                                //@ts-ignore
                                onChangeLevel(selectedItem, index);
                                let updatedErrors = errors.filter(err => err !== `capabilities-${index}-point-length-error`);
                                setErrors(updatedErrors);
                            }
                            closeSheet();
                            onSuccess();
                        }}
                        data={POINT}
                    />
                </div>
            </div>
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderCapabilities()}
        </div>;
    };

    return <div>
        {renderContent()}
    </div>;
};

export default Capabilities;
