import {
    createUseStyles
} from "react-jss";
import {
    CSSProperties 
} from "react";
import {
    ToolBoxStylerParams, 
    ToolBoxStylerResult
} from "./type";

const useStyles = createUseStyles({
    container: {
        display: "inline-block",
        position: "relative",
        userSelect: "none",
        transition: "none",
        cursor: "pointer",
    },
    toolBoxContainer: {
        boxShadow: "0px 4px 4px 0px #00000040",
        transition: "all .03s ease",
        position: "absolute",
        width: "max-content",
        textAlign: "center",
        overflowY: "scroll",
        maxWidth: "500px",
        minWidth: "350px",
        maxHeight: "600px",
        top: "100%",
        zIndex: 1
    },
    line: {
        borderStyle: "solid",
        display: "flex",
        maxWidth: "80%",
        margin: "auto"
    },
    navigationHeader: {
        display: "flex",
        flexDirection: "column",
        borderStyle: "solid",
    },
    navigationHeaderTitle: {
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    tabBar: {
        display: "flex",
        flexDirection: "row"
    },
    stateCardContainer: {
        justifyContent: "center",
        boxSizing: "border-box",
        alignItems: "center",
        display: "flex",
        width: "100%",
    }
}, {
    name: "IOCore-ToolBox"
});

export const toolBoxStyler = ({
    radiuses,
    colors,
    spaces,
    tInfo
}: ToolBoxStylerParams): ToolBoxStylerResult => {
    let toolBoxContainer: CSSProperties = {
        borderRadius: radiuses.quarter * 1.5,
        backgroundColor: colors.layer2,
        right: 0
    };
    if(tInfo.x < 0) {
        toolBoxContainer = {
            ...toolBoxContainer,
            right: toolBoxContainer.right ? toolBoxContainer.right : 0  + tInfo.x,
        };
    }

    if(tInfo.windowWidth < 500){
        toolBoxContainer = {
            ...toolBoxContainer,
            maxWidth: tInfo.windowWidth - 50
        };
    }

    if(tInfo.windowHeight < 700){
        toolBoxContainer = {
            ...toolBoxContainer,
            maxHeight: tInfo.windowHeight - 80
        };
    }

    return {
        toolBoxContainer
    };
};
export default useStyles;
