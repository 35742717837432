import {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreTranslationType,
    IOCoreLocale,
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import {
    InstagramCircleIcon,
    LinkedInCircleIcon, 
    FacebookCircleIcon 
} from "../../assets/svgr";
import {
    ISIN_OLACAK_INSTAGRAM,
    ISIN_OLACAK_FACEBOOK, 
    ISIN_OLACAK_LINKEDIN
} from "../../constants";
import {
    CONNECTIONS, 
    JOB_ADVERTS, 
    SERVICES
} from "./constants";
import {
    useNavigate 
} from "react-router-dom";
import {
    DropdownMenu 
} from "..";
import IsinOlacakLogo from "../../assets/img/isinolacakLogo.png";
import {
    RESTService 
} from "../../services/restAPI";
import {
    FooterProps 
} from "./types";
import {
    getBrowserLanguageFull 
} from "../../utils";

const Footer = ({
    setCities
}: FooterProps) => {
    const navigate = useNavigate();

    const classes = useStyles();

    const {
        localize,
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [modifiedJobAdverts, setModifiedJobAdverts] = useState<typeof JOB_ADVERTS>([]);
    const [dropdownActive, setDropdownActive] = useState("");

    useEffect(() => {
        getCities();
    },[]);

    const getCities = () => {
        RESTService.action("GetCities",{
        })
            .then((res) => {
                if(setCities) {
                    setCities(res.payload);
                }

                const jobAdvertCities = JOB_ADVERTS.reduce((acc: Array<string>, cur) => {
                    if(cur?.href) {
                        return [...acc, cur?.href];
                    }
                    return acc;
                }, []);
            
                const _modifiedAdverts = res.payload.reduce((acc: typeof JOB_ADVERTS, cur) => {
                    if(jobAdvertCities.includes(cur.localizedText)) {
                        const currentCity = {
                            localizedText: cur.localizedText,
                            cityID: cur._id
                        };
                        const currentJobAdvert = {
                            titleKey: `${cur.localizedText.toLocaleLowerCase(getBrowserLanguageFull() || "tr-TR")}-job-listings` as keyof IOCoreTranslationType, 
                            path: `/search?l=${JSON.stringify([currentCity])}`, 
                            href: cur.localizedText
                        };
                        return [...acc, currentJobAdvert]; 
                    }
                    return acc;
                }, []);

                setModifiedJobAdverts(_modifiedAdverts);
            })
            .catch((err) => {
                console.error("GetCities Err:", err);
            });
    };

    const renderFooterLeftContainer = () => {
        return <div className={classes.footerContainer}>
            <div className={classes.logoContainer}>
                <img
                    alt={`${localize("isinolacak-logo")}`}
                    className={classes.logo}
                    src={IsinOlacakLogo}
                />
                <Text 
                    variant="body3-medium"
                    style={{
                        marginTop: spaces.container
                    }}
                >
                    {localize("your-career-will-be-with-you")} <br/> {localize("take-a-new-step")}
                </Text>
            </div>
            <div 
                className={classes.socialIcons} 
                style={{
                    marginTop: spaces.containerLarge,
                    paddingBottom: spaces.content,
                    paddingRight: spaces.content,
                    gap: spaces.item 
                }}>
                <div
                    className={classes.socialIcon}
                    onClick={() => {
                        window.open(ISIN_OLACAK_FACEBOOK);
                    }}
                >
                    <FacebookCircleIcon 
                        color={colors.backgroundDark}
                        size={25} 
                    />
                </div>
                <div
                    className={classes.socialIcon}
                    onClick={() => {
                        window.open(ISIN_OLACAK_INSTAGRAM);
                    }}
                >
                    <InstagramCircleIcon 
                        color={colors.backgroundDark}
                        size={25} 
                    />
                </div>
                <div 
                    onClick={() => {
                        window.open(ISIN_OLACAK_LINKEDIN);
                    }} 
                    className={classes.socialIcon}
                >
                    <LinkedInCircleIcon 
                        color={colors.backgroundDark}
                        size={25} 
                    />
                </div>
            </div>
        </div>;
    };

    const renderFooterRightResponsive = () => {
        return <div className={classes.renderFooterRightResponsive}>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}>
                <DropdownMenu 
                    onClick={() => setDropdownActive((prev) => prev !== "connections" ? "connections" : "")} 
                    isActive={dropdownActive === "connections"} 
                    title={localize("connections")} 
                    data={CONNECTIONS}
                    style={{
                        paddingLeft: 0
                    }}
                    isHrefAdd={false}  
                />
            </div>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}>
                <DropdownMenu 
                    onClick={() => setDropdownActive((prev) => prev !== "services" ? "services" : "")} 
                    isActive={dropdownActive === "services"} 
                    title={localize("services")} 
                    data={SERVICES}
                    isHrefAdd={false}  
                />
            </div>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}>
                <DropdownMenu 
                    onClick={() => setDropdownActive((prev) => prev !== "jobAdverts" ? "jobAdverts" : "")} 
                    isActive={dropdownActive === "jobAdverts"} 
                    title={localize("jobAdverts")} 
                    data={modifiedJobAdverts?.length && modifiedJobAdverts || JOB_ADVERTS}
                    isHrefAdd={false}  
                />
            </div>
        </div>;
    };
    
    const renderFooterRight = () => {
        return <div 
            className={classes.renderFooterRight}
            style={{
                gap: spaces.containerLarge
            }}>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}>
                <Text
                    variant="body-bold"
                    style={{
                        marginBottom: spaces.content * 2
                    }}>
                    {localize("connections")}
                </Text>
                {CONNECTIONS.map(elm => (
                    <div
                        onClick={() => {
                            navigate(elm.path);
                        }} 
                        key={elm.titleKey}>
                        <Text
                    
                            className={classes.link}
                            variant="body2-regular"
                            style={{
                                paddingBottom: spaces.container,
                                color: colors.black
                            }}>
                            {localize(elm.titleKey)}
                        </Text>
                    </div>
                ))}
            </div>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}>
                <Text
                    variant="body-bold"
                    style={{
                        marginBottom: spaces.content * 2
                    }}>
                    {localize("services")}
                </Text>
                {SERVICES.map(elm => (
                    <div
                        key={elm.titleKey}
                        onClick={() => {
                            navigate(elm.path);
                        }} 
                    >
                        <Text
                            className={classes.link}
                            variant="body2-regular"
                            style={{
                                paddingBottom: spaces.container,
                                color: colors.black
                            }}>
                            {localize(elm.titleKey)}
                        </Text>
                    </div>
                ))}
            </div>
            <div 
                className={classes.section} 
                style={{
                    marginTop: spaces.content * 1.2,
                    marginRight: spaces.container
                }}
            >
                <Text
                    variant="body-bold"
                    style={{
                        marginBottom: spaces.content * 2
                    }}
                >
                    {localize("jobAdverts")}
                </Text>
                {(modifiedJobAdverts?.length && modifiedJobAdverts || JOB_ADVERTS).map(elm => (
                    <div
                        key={elm.titleKey}
                        onClick={() => {
                            navigate(elm.path);
                        }} 
                    >
                        <Text
                            key={elm.titleKey}
                            className={classes.link}
                            variant="body2-regular"
                            style={{
                                paddingBottom: spaces.container,
                                color: colors.black
                            }}
                        >
                            {localize(elm.titleKey)}
                        </Text>
                    </div>
                ))}
            </div>
        </div>;
    };

    const renderFooterBottomContainer = () => {
        return <div 
            style={{
                borderTopWidth: borders.indicator / 3,
                paddingTop: spaces.content * 2,
                borderTopColor: colors.grey50,
                marginTop: spaces.content
            }} 
            className={classes.rights}
        >
            <Text 
                variant="body2-regular"
            >
                {localize("all-rights-reserved")}
            </Text>
        </div>;
    };

    const renderFooter = () => {
        return <div 
            className={classes.footer} 
            style={{
                paddingRight: spaces.containerXXLarge,
                paddingLeft: spaces.containerXXLarge,
                paddingBottom: spaces.containerLarge,
                paddingTop: spaces.containerXLarge
            }}>
            {renderFooterLeftContainer()}
            {renderFooterRight()}
            {renderFooterRightResponsive()}
            {renderFooterBottomContainer()}
        </div>;
    };

    const renderFooterResponsive = () => {
        return <div 
            className={classes.footerResponsive} 
            style={{
                paddingBottom: spaces.containerLarge,
                paddingRight: spaces.containerLarge,
                paddingLeft: spaces.containerLarge,
                paddingTop: spaces.containerXLarge
            }}>
            {renderFooterLeftContainer()}
            {renderFooterRight()}
            {renderFooterRightResponsive()}
            {renderFooterBottomContainer()}
        </div>;
    };

    return <footer 
        className={classes.footerBorder}
        style={{
            borderTopWidth: borders.indicator,
            backgroundColor: colors.white,
            borderTopColor: colors.stroke
        }}
    >
        {renderFooterResponsive()}
        {renderFooter()}
    </footer>;
};

export default Footer;