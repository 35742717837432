import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles(
    {
        graphChipContainer: {
            overflowWrap: "break-word",
            boxSizing: "border-box",
            minWidth: "min-content",
            borderStyle: "solid",
            textAlign: "center",
            cursor: "pointer",
            "&:hover": {
                opacity: 0.6
            },
            "&:active": {
                transform: "scale(0.95)"
            }
        }
    }
);
export default useStyles;