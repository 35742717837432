import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    userInfoContainer: {
        flexDirection: "column",
        textAlign: "center",
        display: "flex"
    },
    profilePhotoContianer: {
        justifyContent: "center",
        boxSizing: "border-box",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        overflow: "hidden",
        display: "flex",
        minHeight: 60,
        minWidth: 60,
        height: 60,
        width: 60,
    },
    profilePhotoField:{
        objectFit: "cover",
        height: "60px",
        width: "60px"
    }
});
export default useStyles;