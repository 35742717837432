import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    inputContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    areaCodesContainer: {
        justifyContent:"space-between",
        flexDirection:"row",
        display: "flex"
    },
    textInput: {
        display: "block",
        width: "100%"
    },
    dialogHeader: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    }
});
export default useStyles;