import {
    useEffect,
    useState,
    useRef
} from "react";
import {
    IOCoreLocale,
    IOCoreTheme,
    StateCard,
    TextArea,
    ToolBox,
    Loading,
    Button,
    Text
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    ConversationFilterType,
    GetMessagesRequestType,
    ConversationType,
    UserInfoType,
    MessageType
} from "./type";
import {
    RESTService
} from "../../../services/restAPI";
import {
    ChatService
} from "../../../services/chat";
import {
    ITextAreaRef
} from "isinolacak-web-cl/lib/components/textArea/textArea.props";
import emptyProfile from "../../../assets/img/isinolacakMiniWithPadding.png";
import {
    useGlobalState
} from "../../../context/globalState";
import {
    MessageBubble,
    Conversation
} from "./../../../components";
import {
    EmptyConversationIcon,
    EmptyMessageIcon,
    ChevronLeftIcon,
    WidgetAddIcon,
    DownArrow,
    SendIcon,
    RadarIcon
} from "../../../assets/svgr";
import moment from "moment";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import {
    uuid
} from "../../../utils";

let selectedConversationID: string = "";

const Chat = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const classes = useStyles();

    const {
        state
    } = location;

    const navigatedConversationID = state?.conversationID;

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const contextConversations = ChatService.ConversationsContext.useContext().conversations;

    const [globalState] = useGlobalState();

    const [conversations, setConversations] = useState<Array<ConversationType>>(contextConversations);
    const [selectedConversation, setSelectedConversation] = useState<ConversationType>();
    const [isConversationLoading, setIsConversationLoading] = useState(true);
    const [isMessageLoading, setIsMessageLoading] = useState<boolean>(true);
    const [isViewRedirectButton, setIsViewRedirectButton] = useState(false);
    const [isMessagesEndOfData, setIsMessagesEndOfData] = useState(false);
    const [filter, setFilter] = useState<ConversationFilterType>("all");
    const [messages, setMessages] = useState<Array<MessageType>>([]);
    const [isResponsive, setIsResponsive] = useState<boolean>(false);
    const [unReadMessageCount, setUnReadMessageCount] = useState(0);
    const [userInfo, setUserInfo] = useState<UserInfoType>();
    const [value, setValue] = useState<string>("");

    const messageBoxRef = useRef<HTMLDivElement | null>();
    const textAreaRef = useRef<ITextAreaRef | null>();

    useEffect(() => {
        setIsConversationLoading(true);
        let _contextConversations = contextConversations;
        _contextConversations.sort((b, a) => {
            // @ts-ignore
            return new Date(a.lastMessageDate) - new Date(b.lastMessageDate);
        });
        setConversations(filterConversations(_contextConversations));
        setIsConversationLoading(false);
    }, [contextConversations, filter]);

    useEffect(() => {
        let unReadMessageIDs: Array<string> = [];
        messages.forEach((message: MessageType) => {
            let isMe = message.senderID === globalState.user?.userID;
            if(message.status !== "readed" && !isMe) {
                unReadMessageIDs.push(message.id);
            }
        });

        if(unReadMessageIDs.length) {
            readMessages(unReadMessageIDs);
        }

        ChatService.addEventListener("receive-message", receiveMessage);
        ChatService.addEventListener("read-message", readMessage);

        return () => {
            ChatService.removeEventListener("receive-message");
            ChatService.removeEventListener("read-message");
        };
    }, [messages]);

    useEffect(() => {
        setIsViewRedirectButton(false);
        ChatService.addEventListener("new-message", newMessage);
        return () => {
            ChatService.removeEventListener("new-message");
        };
    }, [conversations, messages]);

    useEffect(() => {
        selectedConversationID = "";
        resize();
        ChatService.addEventListener("end-conversation", endConversation);
        ChatService.addEventListener("new-conversation", newConversation);
        window.addEventListener("resize", resize);

        if(navigatedConversationID) {
            selectedConversationID = navigatedConversationID;
            let _selectedConversation = contextConversations.find((e: ConversationType) =>
                e.conversationID === navigatedConversationID
            );
            setSelectedConversation(_selectedConversation);
            getUserInfo(selectedConversationID);
        }

        return () => {
            ChatService.removeEventListener("end-conversation");
            ChatService.removeEventListener("new-conversation");
            window.removeEventListener("resize", resize);
        };
    }, []);

    // listener functions
    const resize = () => {
        if(window.innerWidth < 750) {
            setIsResponsive(true);
        }
        if(window.innerWidth >= 750) {
            setIsResponsive(false);
        }
    };

    const newMessage = (message: MessageType) => {
        if(message.senderID !== globalState.user?.userID) {
            let unReadMessageIDs: Array<string> = [];
            messages.forEach((message: MessageType) => {
                let isMe = message.senderID === globalState.user?.userID;
                if(message.status !== "readed" && !isMe) {
                    unReadMessageIDs.push(message.id);
                }
            });

            let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
            let currentIndex = _contextConversations.findIndex((e: any) => {
                return e.conversationID === message.conversationID;
            });
            _contextConversations[currentIndex].lastMessage = message.message;
            _contextConversations[currentIndex].unReadMessageCount += 1;
            ChatService.ConversationsContext.setState({
                conversations: _contextConversations
            });
        }

        if(selectedConversationID !== message.conversationID) {
            return;
        }

        if(messageBoxRef.current && (messageBoxRef.current.scrollHeight - messageBoxRef.current.scrollTop) > 30) {
            setUnReadMessageCount(prev => prev + 1);
        }

        let _messages = JSON.parse(JSON.stringify(messages));
        _messages.push(message);
        _messages = sortMessages(_messages);
        setMessages(_messages);
    };

    const newConversation = (newConversation: ConversationType) => {
        let _conversations = JSON.parse(JSON.stringify(conversations));
        conversations.push(newConversation);
        _conversations.sort((b: ConversationType, a: ConversationType) => {
            // @ts-ignore
            return new Date(a.lastMessageDate) - new Date(b.lastMessageDate);
        });
        setConversations(_conversations);
    };

    const endConversation = (newConversation: ConversationType) => {
        let _conversations = JSON.parse(JSON.stringify(conversations));
        let currentIndex = _conversations.findIndex((e: ConversationType) => e.conversationID === newConversation.conversationID);
        _conversations[currentIndex] = newConversation;
        _conversations.sort((b: ConversationType, a: ConversationType) => {
            // @ts-ignore
            return new Date(a.lastMessageDate) - new Date(b.lastMessageDate);
        });
        setConversations(_conversations);
    };

    const receiveMessage = (message: MessageType) => {
        let _messages = JSON.parse(JSON.stringify(messages));
        let currentIndex = _messages.findIndex((e: MessageType) => e.id === message.id);
        if(currentIndex !== -1 && selectedConversationID === message.conversationID) {
            message.status = "received";
            _messages[currentIndex] = message;
            setMessages(_messages);
        }
    };

    const readMessage = (message: MessageType) => {
        if(selectedConversationID !== message.conversationID) {
            return;
        }

        let _messages = JSON.parse(JSON.stringify(messages));
        let currentIndex = _messages.findIndex((e: MessageType) => e.id === message.id);

        if(currentIndex !== -1) {
            _messages[currentIndex] = message;
            setMessages(_messages);
        }
    };

    // helper functions
    const filterConversations = (conversations: Array<ConversationType>) => {
        switch(filter) {
        case "all": {
            return conversations;
        }
        case "unRead": {
            return conversations.filter(e => e.unReadMessageCount > 0);
        }
        case "archived": {
            return conversations.filter(e => e.archivedByIDs?.findIndex(userID => userID === globalState.user?.userID) !== -1);
        }
        case "ended": {
            return conversations.filter(e => e.isEnded);
        }
        default: return conversations;
        }
    };

    const sortMessages = (messages: Array<MessageType>) => {
        messages.sort((a, b) => {
            // @ts-ignore //TODO: this will check
            return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return messages;
    };

    // Request functions
    const getMessages = (conversationID: string, topOfListMessageID?: string) => {
        let _variables: GetMessagesRequestType = {
            conversationID: conversationID
        };

        if(topOfListMessageID) {
            _variables.topOfListMessageID = topOfListMessageID;
        }
        RESTService.action("GetMessages", _variables)
            .then((res) => {
                let newMessages: Array<MessageType> = [];
                if(selectedConversation?.conversationID === res[0].conversationID) {
                    newMessages = [
                        ...res,
                        ...messages
                    ];
                } else {
                    newMessages = res;
                    setTimeout(() => {
                        if(messageBoxRef && messageBoxRef.current) {
                            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
                        }
                    }, 1);
                }
                setMessages(sortMessages(newMessages));
                if(res.length < 20) {
                    setIsMessagesEndOfData(true);
                }
                setIsMessageLoading(false);
            }).catch((err) => {
                setIsMessageLoading(false);
                console.error(err);
            });
    };

    const getUserInfo = (conversationID: string) => {
        RESTService.action("GetConversationUserInfo", {
            conversationID: conversationID
        }).then((res) => {
            setUserInfo(res);
        }).catch((err) => {
            console.error(err);
        });
    };

    const send = () => {
        if(!globalState.user) {
            return;
        }
        if(value && value.length && selectedConversationID.length) {
            // new message create
            const _messageID = uuid();
            let message: MessageType = {
                conversationID: selectedConversationID,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                senderID: globalState.user.userID,
                temproraryID: _messageID,
                status: "sending",
                message: value,
                id: _messageID,
                emojis: []
            };

            let _messages = JSON.parse(JSON.stringify(messages));
            _messages.push(message);
            _messages = sortMessages(_messages);
            setMessages(_messages);

            // conversation update
            let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
            let currentIndex = _contextConversations.findIndex((e: any) => e.conversationID === selectedConversationID);
            _contextConversations[currentIndex].unReadMessageCount = 0;
            ChatService.ConversationsContext.setState({
                conversations: _contextConversations
            });

            // selectedConversation update
            if(selectedConversation) {
                setSelectedConversation({
                    ...selectedConversation,
                    unReadMessageCount: 0
                });
            }

            setValue("");
            textAreaRef.current?.cleanText();
            // Request
            RESTService.action("SendMessage", {
                conversationID: selectedConversationID,
                temproraryID: _messageID,
                message: value.trim()
            }).then((res) => {
                // conversation configuration
                let isArchived = selectedConversation?.archivedByIDs?.findIndex(e => e === globalState.user?.userID);
                if(isArchived !== -1) {
                    let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
                    let currentIndex = _contextConversations.findIndex((e: any) => e.conversationID === selectedConversationID);
                    let archivedIndex = _contextConversations[currentIndex].archivedByIDs.findIndex((e: string) => e === globalState.user?.userID);
                    _contextConversations[currentIndex].archivedByIDs.splice(archivedIndex, 1);
                    _contextConversations[currentIndex].lastMessage = value.trim();
                    ChatService.ConversationsContext.setState({
                        conversations: _contextConversations
                    });

                    setSelectedConversation(_contextConversations[currentIndex]);
                    selectedConversationID = _contextConversations[currentIndex].conversationID;
                }
                // messageBox configuration
                if(messageBoxRef && messageBoxRef.current) {
                    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
                }

                // message configuration
                _messages = JSON.parse(JSON.stringify(_messages));
                let currentMessageIndex = _messages.findIndex((e: MessageType) => e.id === _messageID);
                if(currentMessageIndex !== -1 && _messages[currentMessageIndex].status === "sending") {
                    _messages[currentMessageIndex].status = "sended";
                    _messages[currentMessageIndex].id = res.messageID;
                }
                setMessages(_messages);
            }).catch(err => {
                if(messageBoxRef && messageBoxRef.current) {
                    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
                }

                _messages = JSON.parse(JSON.stringify(_messages));
                let currentMessageIndex = _messages.findIndex((e: MessageType) => e.id === _messageID);
                if(currentMessageIndex !== -1 && _messages[currentMessageIndex].status === "sending") {
                    _messages[currentMessageIndex].status = "error";
                }
                setMessages(_messages);
                console.error("Send message err:", err);
            });
        }
    };

    const errorMessageSend = (errorMessage: MessageType) => {
        if(!globalState.user) {
            return;
        }

        if(errorMessage && errorMessage.message.length && selectedConversationID.length) {
            let _messages = JSON.parse(JSON.stringify(messages));
            let currentMessageIndex = _messages.findIndex((e: MessageType) => e.id === errorMessage.id);
            _messages[currentMessageIndex].status = "sending";
            setMessages(_messages);

            // conversation update
            let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
            let currentIndex = _contextConversations.findIndex((e: any) => e.conversationID === selectedConversationID);
            _contextConversations[currentIndex].unReadMessageCount = 0;
            ChatService.ConversationsContext.setState({
                conversations: _contextConversations
            });

            // selectedConversation update
            if(selectedConversation) {
                setSelectedConversation({
                    ...selectedConversation,
                    unReadMessageCount: 0
                });
            }

            // Request
            RESTService.action("SendMessage", {
                conversationID: selectedConversationID,
                message: errorMessage.message,
                temproraryID: errorMessage.temproraryID ? errorMessage.temproraryID : errorMessage.id
            }).then((res) => {
                // messageBox configuration
                if(messageBoxRef && messageBoxRef.current) {
                    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
                }

                // message configuration
                _messages = JSON.parse(JSON.stringify(_messages));
                let currentMessageIndex = _messages.findIndex((e: MessageType) => e.id === errorMessage.id);
                if(currentMessageIndex !== -1 && _messages[currentMessageIndex].status === "sending") {
                    _messages[currentMessageIndex].status = "sended";
                    setMessages(_messages);
                }

                _messages[currentMessageIndex].id = res.messageID;
                setMessages(_messages);
            }).catch(err => {
                _messages = JSON.parse(JSON.stringify(_messages));
                let currentMessageIndex = _messages.findIndex((e: MessageType) => e.id === errorMessage.id);
                if(currentMessageIndex !== -1) {
                    _messages[currentMessageIndex].status = "error";
                }
                setMessages(_messages);
                console.error("Send message err (send error message):", err);
            });
        }
    };
    const readMessages = (messageIDs: Array<string>) => {
        RESTService.action("ReadMessages", {
            messageIDs: messageIDs
        })
            .then(res => {
                let _messages = JSON.parse(JSON.stringify(messages));
                res.forEach(message => {
                    if(selectedConversationID !== message.conversationID) {
                        return;
                    }
                    let currentIndex = _messages.findIndex((e: MessageType) => e.id === message.id);
                    if(currentIndex !== -1) {
                        _messages[currentIndex] = message;
                    }
                    setMessages(_messages);
                });
            })
            .catch(err => {
                console.error("readMessages err:", err);
            });
    };

    const ConversationEnd = (conversationID: string) => {
        RESTService.action("ConversationEnd", {
            conversationID: conversationID,
        })
            .catch(err => {
                console.error("Conversation end err:", err);
            });
    };

    const ArchiveConversation = (conversationID: string) => {
        RESTService.action("ArchiveConversation", {
            conversationID: conversationID,
        }).then((res) => {
            let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
            let currentIndex = _contextConversations.findIndex((e: any) => e.conversationID === conversationID);
            _contextConversations[currentIndex].archivedByIDs = [
                ..._contextConversations[currentIndex].archivedByIDs,
                globalState.user?.userID
            ];
            ChatService.ConversationsContext.setState({
                conversations: _contextConversations
            });

            if(contextConversations[currentIndex].conversationID === selectedConversation?.conversationID) {
                setSelectedConversation(_contextConversations[currentIndex]);
                selectedConversationID = _contextConversations[currentIndex].conversationID;
            }
        }).catch(err => {
            console.error("Archive conversation err:", err);
        });
    };

    const RemoveArchiveConversation = (conversationID: string) => {
        RESTService.action("RemoveArchiveConversation", {
            conversationID: conversationID,
        }).then((res) => {
            let _contextConversations = JSON.parse(JSON.stringify(contextConversations));
            let currentIndex = _contextConversations.findIndex((e: any) => e.conversationID === conversationID);
            let archivedIndex = _contextConversations[currentIndex].archivedByIDs.findIndex((e: any) => e === globalState.user?.userID);
            _contextConversations[currentIndex].archivedByIDs.splice(archivedIndex, 1);
            ChatService.ConversationsContext.setState({
                conversations: _contextConversations
            });

            if(contextConversations[currentIndex].conversationID === selectedConversation?.conversationID) {
                setSelectedConversation(_contextConversations[currentIndex]);
                selectedConversationID = _contextConversations[currentIndex].conversationID;
            }
        }).catch(err => {
            console.error("Remove Archive conversation err:", err);
        });
    };

    // render functions
    const renderLine = () => {
        return <div
            className={classes.line}
            style={{
                backgroundColor: colors.stroke
            }}
        >
        </div>;
    };

    const renderProfilePhoto = (profilePhotoURL: string | null) => {
        return <div
            className={classes.profilePhotoContainer}
            style={{
                borderRadius: radiuses.hard * 100,
                borderWidth: borders.indicator,
                marginBottom: spaces.content,
                borderColor: colors.primary
            }}
        >
            <img
                src={profilePhotoURL ? profilePhotoURL : emptyProfile}
                className={classes.profilePhotoField}
                alt="profile"
            />
        </div>;
    };

    const renderFilterButtons = () => {
        return <div
            className={classes.filterButtons}
            style={{
                gap: spaces.content
            }}
        >
            <Button
                color={filter === "all" ? "primary" : "textSecondary"}
                variant="outline"
                title={localize("all")}
                size="large"
                onClick={() => {
                    setFilter("all");
                }}
            />
            <Button
                color={filter === "unRead" ? "primary" : "textSecondary"}
                variant="outline"
                title={localize("unread")}
                size="large"
                onClick={() => {
                    setFilter("unRead");
                }}
            />
            <Button
                color={filter === "archived" ? "primary" : "textSecondary"}
                title={localize("archived")}
                variant="outline"
                size="large"
                onClick={() => {
                    setFilter("archived");
                }}
            />
            <Button
                color={filter === "ended" ? "primary" : "textSecondary"}
                title={localize("terminated")}
                variant="outline"
                size="large"
                onClick={() => {
                    setFilter("ended");
                }}
            />
        </div>;
    };

    const renderConversations = () => {
        return <div
            className={classes.conversationContainer}
            style={{
                display: selectedConversation && isResponsive ? "none" : "flex",
                backgroundColor: colors.layer2,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.conversationHeader}
                style={{
                    padding: `${spaces.containerXLarge / 2}px ${spaces.container * .75}px`,
                    gap: spaces.inline * 2.5
                }}
            >
                <Text>
                    {
                        globalState.user?.type === "employer"
                            ?
                            localize("applicants")
                            :
                            localize("contacted-companies")
                    }
                </Text>
                <Text
                    variant="body3-regular"
                >
                    {localize("incoming-messages")}
                </Text>
            </div>
            {
                isConversationLoading ?
                    <Loading />
                    :
                    conversations.length ?
                        conversations.map((item, index) => {
                            const userData = item.users.find(user => user.userID !== globalState.user?.userID);
                            if(!userData) return;
                            const isArchived = item.archivedByIDs ?
                                item.archivedByIDs.findIndex(userID => userID === globalState.user?.userID)
                                :
                                -1;
                            return <Conversation
                                updatedAt={item.createdAt ?? item.lastMessageDate}
                                unReadMessageCount={item.unReadMessageCount}
                                profilePhotoURL={userData?.profilePhotoURL}
                                isArchived={isArchived !== -1}
                                fullName={userData?.fullName}
                                key={"conversation-" + index}
                                message={item.lastMessage}
                                onClick={() => {
                                    setIsMessageLoading(true);
                                    setSelectedConversation(item);
                                    selectedConversationID = item.conversationID;
                                    getUserInfo(item.conversationID);
                                    getMessages(item.conversationID);
                                    textAreaRef.current?.cleanText();
                                    setValue("");
                                }}
                                onEndConversation={() => {
                                    ConversationEnd(item.conversationID);
                                }}
                                onArchiveConversation={() => {
                                    ArchiveConversation(item.conversationID);
                                }}
                                onRemoveArchiveConversation={() => {
                                    RemoveArchiveConversation(item.conversationID);
                                }}
                            />;
                        })
                        :
                        renderEmptyConversationCard()
            }
        </div>;
    };

    const renderOptionsContent = () => {
        let isArchived = selectedConversation?.archivedByIDs?.findIndex(e => e === globalState.user?.userID) !== -1;
        return <div
            className={classes.optionsContent}
            style={{
                backgroundColor: colors.layer1,
                gap: spaces.content
            }}
        >
            <div
                onClick={() => {
                    ConversationEnd(selectedConversationID);
                }}
            >
                <Text
                    variant='body3-regular'
                    color='textSecondary'
                >
                    {localize("terminate-chat")}
                </Text>
            </div>
            <div
                onClick={() => {
                    if(isArchived) {
                        RemoveArchiveConversation(selectedConversationID);
                    } else {
                        ArchiveConversation(selectedConversationID);
                    }
                }}
            >
                <Text
                    variant='body3-regular'
                    color='textSecondary'
                >
                    {isArchived ? localize("unarchive") : localize("archive")}
                </Text>
            </div>
        </div>;
    };

    const renderOptions = () => {
        return <ToolBox
            content={renderOptionsContent()}
        >
            <div
                className={classes.optionDots}
                style={{
                    padding: spaces.container / 4
                }}
            >
                <div
                    className={classes.optionDot}
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
                <div
                    className={classes.optionDot}
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
                <div
                    className={classes.optionDot}
                    style={{
                        backgroundColor: colors.gray80
                    }}
                >
                </div>
            </div>
        </ToolBox>;
    };

    const renderMessageBoxNavbar = () => {
        if(!(selectedConversation && isResponsive)) {
            return;
        }
        return <div
            className={classes.messageBoxNavbar}
            style={{
                marginTop: spaces.contentLarge,
                borderColor: colors.seperator,
                borderWidth: borders.line,
                padding: spaces.container
            }}
        >
            <div
                className={classes.messageBoxNavbarContent}
            >
                <div
                    style={{
                        alignItems: "center",
                        display: "flex"
                    }}
                    onClick={() => {
                        setSelectedConversation(undefined);
                    }}
                >
                    <ChevronLeftIcon
                        color={colors.gray70}
                        size={20}
                    />
                </div>
                <Text variant="body-medium" >
                    {userInfo?.user.fullName}
                </Text>
            </div>
            {renderOptions()}
        </div>;
    };

    const renderMessageContainer = () => {
        return <div
            className={classes.messagesContainer}
            style={{
                display: isResponsive ? selectedConversation ? "flex" : "none" : "flex",
                backgroundColor: colors.layer2,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line,
                padding: spaces.container
            }}
        >
            {
                selectedConversation && selectedConversation?.isEnded ?
                    <div
                        className={classes.messageBoxHeaderTitle}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <Text>
                            {localize("terminated")}
                        </Text>
                        {renderUserInfo()}
                        {renderLine()}
                    </div>
                    :
                    selectedConversation && selectedConversation?.archivedByIDs?.findIndex(e => e === globalState.user?.userID) !== -1 ?
                        <div
                            className={classes.messageBoxHeaderTitle}
                            style={{
                                gap: spaces.content
                            }}
                        >
                            <Text>
                                {localize("archived")}
                            </Text>
                            {renderUserInfo()}
                            {renderLine()}
                        </div>
                        :
                        renderUserInfo()
            }
            {renderEmptyMessageCard()}
            {renderMessageBox()}
            {
                selectedConversation &&
                <div
                    className={classes.messageValueContainer}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <TextArea
                        ref={(e) => {
                            textAreaRef.current = e;
                        }}
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                                send();
                            }
                        }}
                        placeholder={localize("send-message")}
                        spreadBehaviour="stretch"
                        initialValue={value}
                        style={{
                            marginBottom: spaces.inline
                        }}
                        onChangeText={(value) => {
                            setValue(value);
                        }}
                    />
                    {
                        isResponsive ?
                            <div
                                onClick={() => {
                                    send();
                                }}
                            >
                                <SendIcon
                                    color={colors.primary}
                                    size={20}
                                />
                            </div>
                            :
                            <Button
                                spreadBehaviour="free"
                                title={localize("send")}
                                color="primary"
                                onClick={() => {
                                    send();
                                }}
                            />
                    }
                </div>
            }
        </div>;
    };

    const renderUserInfo = () => {
        if(!userInfo) {
            return;
        }

        return <div
            className={classes.userInfoContainer}
        >
            <Text
                variant="body3-medium"
                color="primary"
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                {localize("advert-name", [userInfo.advertName])}
            </Text>
            {renderProfilePhoto(userInfo.user.profilePhotoURL)}
            <Text
                variant="body2-regular"
                style={{
                    marginBottom: spaces.content / 3
                }}
            >
                {userInfo.user.fullName}
            </Text>
            <Text
                variant="body4-regular"
                color="textSecondary"
            >
                {userInfo.user.content}
            </Text>
        </div>;
    };

    const renderMessageBox = () => {
        return <div
            className={classes.messageBoxContainer}
            style={{
                paddingRight: spaces.inline,
                gap: spaces.inline
            }}
            ref={(e) => {
                messageBoxRef.current = e;
            }}
            onScroll={(e) => {
                if(isMessageLoading) {
                    return;
                }
                if(Math.abs(e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)) <= 20) {
                    setUnReadMessageCount(0);
                    setIsViewRedirectButton(false);
                } else {
                    setIsViewRedirectButton(true);
                }
                if(isMessagesEndOfData) {
                    return;
                }
                if(e.currentTarget.scrollTop < 20) {
                    setIsMessageLoading(true);
                    getMessages(selectedConversationID, messages[0].id);
                }
            }}
        >
            {renderUnreadMessageBox()}
            {
                isMessageLoading && selectedConversationID.length ?
                    <div
                        className={classes.messageLoadingContainer}
                    >
                        <Loading />
                    </div>
                    :
                    messages.map((item, index) => {
                        let isMe = item.senderID === globalState.user?.userID;
                        return <MessageBubble
                            date={moment(item.createdAt).format("HH.mm")}
                            direction={isMe === true ? "right" : "left"}
                            key={"messageBubble-" + item.id}
                            message={item.message}
                            status={item.status}
                            onClickRefreshIcon={() => {
                                errorMessageSend(item);
                            }}
                        />;
                    })
            }
            {renderRedirectButton()}
        </div>;
    };

    const renderRedirectButton = () => {
        if(!messages.length) {
            return;
        }
        if(!selectedConversation) {
            return;
        };

        if(!isViewRedirectButton) {
            return;
        }
        return <div
            className={classes.redirectButtonField}
            style={{
                bottom: spaces.item,
                right: spaces.item
            }}
        >
            <Button
                title={unReadMessageCount > 0 ? unReadMessageCount.toString() : ""}
                spreadBehaviour="free"
                iconDirection="right"
                color="warning"
                onClick={() => {
                    if(messageBoxRef && messageBoxRef.current) {
                        messageBoxRef.current.scrollTo({
                            top: messageBoxRef.current.scrollHeight,
                            behavior: "smooth"
                        });
                    }
                }}
                icon={() => <DownArrow
                    color={colors.black100}
                    size={16}
                />}
            />
        </div>;
    };

    const renderUnreadMessageBox = () => {
        if(!(selectedConversation && selectedConversation.unReadMessageCount && selectedConversation.unReadMessageCount !== 0)) {
            return;
        }
        return <div
            className={classes.unReadMessageBoxContainer}
            style={{
                top: spaces.item
            }}
        >
            <div
                className={classes.unReadMessageBox}
                style={{
                    backgroundColor: colors.layer1,
                    padding: spaces.content * 0.6,
                    borderColor: colors.warning,
                    borderRadius: radiuses.half,
                    borderWidth: borders.line,
                }}
            >
                <Text
                    variant="body3-regular"
                    color="warning"
                >
                    {localize(
                        "unread-message",
                        [selectedConversation?.unReadMessageCount]
                    )}
                </Text>
            </div>
        </div>;
    };

    const renderEmptyConversationCard = () => {
        if(messages.length) {
            return;
        }
        return <div
            className={classes.stateCardContainer}
        >
            <StateCard
                icon={() => {
                    return <EmptyConversationIcon size={150} />;
                }}
                title={localize("no-messages-yet")}
                content={localize("view-actions-for-active-ads")}
            />
        </div>;
    };

    const renderEmptyMessageCard = () => {
        if(messages.length) {
            return;
        }
        return <div
            className={classes.stateCardContainer}
        >
            <StateCard
                icon={() => {
                    return <EmptyMessageIcon size={200} />;
                }}
                title={localize("no-messages-yet")}
                content={localize("view-actions-for-active-ads")}
            />
        </div>;
    };

    const renderNewAdvertCardContainer = () => {
        return <div
            className={classes.newAdvertCardContainer}
            style={{
                backgroundColor: colors.layer2,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <StateCard
                title={localize("create-new-ad")}
                content={localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
                isAction={true}
                icon={() => {
                    return <WidgetAddIcon size={50} color={colors.primary} />;
                }}
                buttonProps={{
                    spreadBehaviour: "stretch",
                    title: localize("create-ad"),
                    onClick: () => {
                        navigate("/app/newAdvert");
                    }
                }}
            />
        </div>;
    };

    const renderViewAdvertContainer = () => {
        return <div
            className={classes.newAdvertCardContainer}
            style={{
                backgroundColor: colors.layer2,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <StateCard
                title={localize("see-today's-ads")}
                content={localize("we-have-compiled-the-popular-ads-of-the-day-for-you.")}
                isAction={true}
                icon={() => {
                    return <RadarIcon size={50} color={colors.primary} />;
                }}
                buttonProps={{
                    spreadBehaviour: "stretch",
                    title: localize("discover"),
                    onClick: () => {
                        navigate("/app/search");
                    }
                }}
            />
        </div>;
    };

    const renderRightContainer = () => {
        if(globalState.user?.type === "employer") {
            return <div>
                {renderNewAdvertCardContainer()}
            </div>;
        } else {
            return <div>
                {renderViewAdvertContainer()}
            </div>;
        }
    };

    return <div
        className={classes.container}
        style={{
            padding: isResponsive ? 0 : spaces.containerXLarge / 2,
            backgroundColor: colors.layer3,
            gap: spaces.containerXLarge
        }}
    >
        <div
            className={classes.chatHeader}
            style={{
                padding: isResponsive ? spaces.container : 0,
                display: selectedConversation && isResponsive ? "none" : "flex"
            }}
        >
            <Text
                style={{
                    marginBottom: spaces.contentLarge
                }}
            >
                {localize("messaging")}
            </Text>
            {renderFilterButtons()}
        </div>
        {renderMessageBoxNavbar()}
        <div
            className={classes.contentContainer}
            style={{
                gap: spaces.inline * 3
            }}
        >
            {renderConversations()}
            {renderMessageContainer()}
            {renderRightContainer()}
        </div>
    </div>;
};
export default Chat;
