import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "space-between",
        borderRightStyle: "solid",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        userSelect: "none",
    },
    topContainer: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        width: "100%"
    },
    menuContainer: {
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "center",
        display: "flex",
        width: "100%",
        zIndex: 1
    },
    categoryTitle: {
        justifyContent: "center",
        display: "flex",
        width: "100%"
    },
    sizeButtonContainer: {
        boxSizing: "border-box",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        borderRadius: 200,
        cursor: "pointer",
        display: "flex"
    },
    sizeButtonContent: {
        transitionDuration: ".9s",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    back: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    "@media screen and (max-width: 600px)": {
        container: {
            display: "none"
        }
    }
});
export default useStyles;