import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Banknote = ({
    size = 30,
    color="#FFFFFF",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M11.85 14.8a3.15 3.15 0 1 0 6.3 0 3.15 3.15 0 0 0-6.3 0Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M3.489 23.656c1.412.944 3.379.944 7.311.944h8.4c3.933 0 5.899 0 7.311-.944a5.597 5.597 0 0 0 1.545-1.545C29 20.7 29 18.733 29 14.8s0-5.899-.944-7.311a5.598 5.598 0 0 0-1.545-1.545C25.1 5 23.133 5 19.2 5h-8.4c-3.932 0-5.899 0-7.311.944a5.6 5.6 0 0 0-1.545 1.545C1 8.9 1 10.867 1 14.8s0 5.899.944 7.311a5.598 5.598 0 0 0 1.545 1.545ZM15 20.05a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 0 1 0 10.5Zm-9.1 0c-.58 0-1.05-.47-1.05-1.05v-8.4a1.05 1.05 0 1 1 2.1 0V19c0 .58-.47 1.05-1.05 1.05ZM23.05 19a1.05 1.05 0 1 0 2.1 0v-8.4a1.05 1.05 0 1 0-2.1 0V19Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default Banknote;
