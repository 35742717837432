import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        display: "flex",
        width: "100%",
        "@media (max-width: 650px)": {
            paddingBottom: "0px !important",
            paddingRight: "0px !important",
            paddingLeft: "0px !important"
        }
    },
    titleContainer: {
        "@media (max-width: 950px)": {
            display: "none"
        },
        marginBottom: 20
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "flex-start",
        position: "sticky",
        display: "flex",
        height: "100%",
        maxWidth: "200px",
        width: "100%",
        "@media (max-width: 950px)": {
            display: "none"
        }
    },
    stepContainer: {
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    responsiveStepsContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        display: "none",
        width: "100%",
        "@media (max-width: 950px)": {
            display: "flex"
        }
    },
    responsiveSteps: {
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    stepLine: {
        width: "100%",
        height: 2
    },
    numberContainer: {
        justifyContent: "center",
        flexDirection: "row",
        borderStyle: "solid",
        alignItems: "center",
        userSelect: "none",
        display: "flex",
        height: 23,
        width: 23
    },
    contentContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        height: "100%",
        width: "100%",
    },
    sectionContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        display: "flex",
        width: "100%"
    },
    questionGroup: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    questionContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    capabilityContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap"
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        width: "100%",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%"
    },
    verticalLine: {
        height: 16
    },
    newPaqButtonContainer: {
        justifyContent: "flex-end",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    }
});
export default useStyles;