import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        flexGrow: 1,
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display:"flex",
            width: "100%"
        },
    },
    container: {
        justifyContent: "space-between",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        position: "sticky",
        maxWidth: "15%",
        display: "flex",
        height: "100%",
        minWidth: 230,
        width: "100%",
        "@media (max-width: 1040px)": {
            paddingRight: "0px !important",
            paddingLeft: "0px !important",
            position: "relative",
            maxWidth: "unset",
            display: "flex"
        }
    },
    contentContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        minHeight: 600,
        height: "100%",
        width: "73%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        height: "100%",
        minWidth: 150,
        width: "8%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    line: {
        width: "100%",
        height: 1
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    loadingContiner: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    },
    companyInfoHeaderContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    comapnyInfoHeaderContent: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    companyInfoHeaderımage: {
        objectPosition: "center",
        borderStyle: "solid",
        objectFit: "cover",
        height: 130,
        width: 130
    },
    companyInfoHeaderText: {
        flexDirection: "column",
        alignItems: "center",
        display: "flex"
    },
    advertCardContainer : {
        justifyContent: "space-between",
        flexDirection: "row",
        display: "flex"
    },
    advertCardContent: {
        justifyContent: "center",
        boxSizing: "border-box",
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap",
        display: "flex",
        width: "calc(100% - 330)",
        "@media screen and (max-width: 1040px)": {
            justifyContent: "center",
        }
    },
    advertCard: {
        flexBasis: "min-content",
        flexGrow: 0,
    },
});
export default useStyles;