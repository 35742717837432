const currentYear = new Date().getFullYear();

const yearCount = currentYear - 1900;

const INPUT_YEARS: {year: number}[] = [];
for (let i = 0; i <= yearCount; i++) {
    const year = 1900 + i;
    INPUT_YEARS.unshift({
        year: year
    });
}

const MONTH_DATA = [
    {
        name: "Ocak",
        number: "01",
        id: 1 
    },
    {
        name: "Şubat",
        number: "02",
        id: 2 
    },
    {
        name: "Mart",
        number: "03",
        id: 3
    },
    {
        name: "Nisan",
        number: "04",
        id: 4
    },
    {
        name: "Mayıs",
        number: "05",
        id: 5
    },
    {
        name: "Haziran",
        number: "06",
        id: 6
    },
    {
        name: "Temmuz",
        number: "07",
        id: 7
    },
    {
        name: "Ağustos",
        number: "08",
        id: 8
    },
    {
        name: "Eylül",
        number: "09",
        id: 9 
    },
    {
        name: "Ekim",
        number: "10",
        id: 10
    },
    {
        name: "Kasım",
        number: "11",
        id: 11
    },
    {
        name: "Aralık",
        number: "12",
        id: 12
    }
];

const POINT = [
    {
        point: 1
    },
    {
        point: 2
    },
    {
        point: 3
    },
    {
        point: 4
    },
    {
        point: 5
    },
    {
        point: 6
    },
    {
        point: 7
    },
    {
        point: 8
    },
    {
        point: 9
    },
    {
        point: 10
    }
];


export {
    INPUT_YEARS,
    MONTH_DATA,
    POINT
};
