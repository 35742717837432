import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const LetterOpen = ({
    size = 20,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 20 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M8 20h4c3.771 0 5.657 0 6.828-1.171C20 17.657 20 15.77 20 12s0-5.657-1.172-6.828c-.362-.363-.794-.613-1.328-.786v2.773c.003.34.009.911-.236 1.433-.244.522-.686.884-.95 1.1-.026.02-.051.041-.073.06l-1.507 1.255c-.86.718-1.61 1.342-2.284 1.776-.725.466-1.51.812-2.45.812-.94 0-1.724-.346-2.45-.813-.674-.433-1.423-1.057-2.284-1.774L3.759 9.752l-.073-.06c-.264-.216-.705-.578-.95-1.1-.244-.522-.24-1.093-.237-1.433l.001-.096V4.385c-.534.173-.966.424-1.328.787C0 6.343 0 8.229 0 12c0 3.771 0 5.657 1.172 6.829C2.343 20 4.229 20 8 20Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
            d="m4.72 8.6 1.439 1.2c1.837 1.53 2.755 2.295 3.841 2.295 1.086 0 2.005-.765 3.841-2.296l1.44-1.2c.353-.294.53-.442.625-.643.094-.202.094-.432.094-.893V5c0-.32 0-.62-.002-.898-.011-1.771-.098-2.737-.73-3.37C14.536 0 13.358 0 11 0H9C6.643 0 5.465 0 4.732.732c-.632.633-.72 1.599-.732 3.37-.002.279 0 .577 0 .898v2.063c0 .46 0 .691.095.893.094.201.27.349.625.644ZM7.25 4A.75.75 0 0 1 8 3.25h4a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 4Zm1 3A.75.75 0 0 1 9 6.25h2a.75.75 0 0 1 0 1.5H9A.75.75 0 0 1 8.25 7Z"
        />
    </svg>;
};
export default LetterOpen;
