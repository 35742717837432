import React, {
    useEffect,
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    PageModal,
    StateCard,
    Loading,
    Button,
    Text,
    IOCoreTranslationType
} from "isinolacak-web-cl";
import {
    RoundArrowIcon,
    WidgetAddIcon,
    FreeInfoIcon,
    QuestionIcom,
    WalletIcon,
    TrashIcon,
    MapIcon
} from "../../../../../../assets/svgr";
import {
    SettingsTypes
} from "./types";
import {
    RESTService 
} from "../../../../../../services/restAPI";
import {
    PersonalInfoTypes
} from "../../types";
import {
    EmployerProfileLeftContainer,
    RowCard 
} from "../../../../../../components";
import {
    GetMyProfileResponseTypes
} from "../../../../../../services/restAPI/actions/getMyProfile/types";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import ChangeLanguage from "./views/changeLanguage";
import AskedQuestion from "./views/askedQuestions";
import PrivacyPolicy from "./views/privacyPolicy";
import {
    getErrorText 
} from "../../../../../../utils";
import Subscriptions from "./views/subscriptions";
import Payment from "./views/subscriptions/views/payment";

const EmployerSettings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const [headerTitle, setHeaderTitle] = useState<keyof IOCoreTranslationType>("settings");
    const [getMyProfile, setGetMyProfile] = useState<GetMyProfileResponseTypes>();
    const [loadingProfilePhoto, setLoadingProfilePhoto] = useState(true);
    const [views, setViews] = useState<SettingsTypes>("open");
    const [currentPackage, setCurrentPackage] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [lastOrder, setLastOrder] = useState("");

    const localStorageData: string | null = window.localStorage.getItem("company");

    let company: PersonalInfoTypes;
    if (localStorageData) {
        company = JSON.parse(localStorageData);
    } else {
        company = {
            areaCode: {
                countryTranslationKey: "",
                flagUnicode: "",
                country: "",
                value: "",
                _id: "",
            },
            companyOccupation: {
                localizedText: "",
                _id: ""
            },
            occupation: {
                localizedText: "",
                _id: ""
            },
            district: {
                localizedText: "",
                _id: ""
            },
            sector: {
                localizedText: "",
                _id: ""
            },
            city: {
                localizedText: "",
                _id: ""
            },
            companyTitle: "",
            profileURL: "",
            address: "",
            website: "",
            title: "", 
            about: "",
            mail: ""
        };
    }

    useEffect(() => {
        handleGetMyProfile();
    },[]);

    useEffect(() => {
        if(views === "open") {
            setHeaderTitle("settings");
            setLastOrder("");
        }
    }, [views]);

    const handleGetMyProfile = () => {
        setLoadingProfilePhoto(true);
        RESTService.action("GetMyProfile",{
        }).then((res) => {
            setGetMyProfile(res);
            setLoadingProfilePhoto(false);
        }).catch((err) => {
            console.error("GetMyProfile Err:", err);

            if(err && err.payload) {
                const errorData = getErrorText(err, [
                    "answer"
                ]);
                if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                    alert(localize(err.message));
                    return;
                }
            } else if(err.message) {
                alert(localize(err.mesaage));
                return;
            }

            alert(localize("invalid-request"));
        });
    };

    const handleDeleteMyAccount = () => {
        RESTService.action("DeleteMyAccount", {
        })
            .then((res) => {
                setTimeout(() => {
                    navigate("/auth/login");
                }, 300);
            })
            .catch((err) => {
                console.error("DeleteMyAccount Err:", err);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const handleGoBackButton = () => {
        switch (views) {
        case "changeLanguage":
            setViews("open");
            break;
        case "asked-questions":
            setViews("open");
            break;
        case "purchaseHistory":
            setViews("subscriptions");
            break;
        case "privacyPolicy":
            setViews("open");
            break;
        case "subscriptions":
            setViews("open");
            break;
        case "payment":
            setViews("subscriptions");
            break;
        
        default:
            break;
        }
    };

    const renderLine = () => {
        return <div
            className={classes.renderLine}
            style={{
                borderWidth: spaces.inline / 10,
                borderColor: colors.stroke
            }}
        />;
    };

    const renderRowCard = () => {
        return <div>
            <RowCard
                onClick={() => {
                    setViews("changeLanguage");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <MapIcon />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("change-language")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                onClick={() => {
                    setViews("asked-questions");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <QuestionIcom />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("asked-questions")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                onClick={() => {
                    setViews("privacyPolicy");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <FreeInfoIcon />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("privacy-policy")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                onClick={() => {
                    setViews("subscriptions");
                }}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <WalletIcon size={19} />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="textDark"
                    className={classes.rowStyle}
                >
                    {localize("subscriptions")}
                </Text>}
                title=""
            />
            {renderLine()}
            <RowCard
                onClick={() => setIsVisible(false)}
                renderLeft={() =>
                    <div
                        style={{
                            borderRadius: spaces.content,
                            marginRight: spaces.content
                        }}
                    >
                        <TrashIcon
                            color={colors.error}
                            size={19}
                        />
                    </div>
                }
                renderTitle={() => <Text
                    variant="body2-regular"
                    color="error"
                    className={classes.rowStyle}
                >
                    {localize("delete-the-account")}
                </Text>}
                title=""
            />
            {renderLine()}
        </div>;
    };

    const renderContent = () => {
        switch (views) {
        case "changeLanguage":
            return <ChangeLanguage setHeaderTitle={setHeaderTitle} />;
        case "asked-questions":
            return <AskedQuestion setHeaderTitle={setHeaderTitle} />;
        case "privacyPolicy":
            return <PrivacyPolicy setHeaderTitle={setHeaderTitle} />;
        case "subscriptions":
            return <Subscriptions setHeaderTitle={setHeaderTitle} setLastOrder={setLastOrder} lastOrder={lastOrder} views={views} setViews={setViews} setPremium={setIsPremium} setCurrentPackage={setCurrentPackage} />;
        case "purchaseHistory":
            return <Subscriptions setHeaderTitle={setHeaderTitle} setLastOrder={setLastOrder} lastOrder={lastOrder} views={views} setViews={setViews} setPremium={setIsPremium} setCurrentPackage={setCurrentPackage} />;
        case "payment":
            return <Payment setLastOrder={setLastOrder} setHeaderTitle={setHeaderTitle} views={views} setViews={setViews} isPremium={isPremium} currentPackage={currentPackage} />;
        case "open":
        default:
            return renderRowCard();
        }
    };

    const renderPageModal = () => {
        return <PageModal
            onOverlayPress={() => setIsVisible(false)}
            contentContainerStyle={{
                backgroundColor: colors.white
            }}
            content={
                <div
                    className={classes.pageModalContainer}
                    style={{
                        padding: spaces.content
                    }}
                >
                    <StateCard
                        content={localize("you-are-about-to-delete-your-account-desc")}
                        title={localize("you-are-about-to-delete-your-account")}
                        icon={() =>
                            <TrashIcon
                                color={colors.error}
                                size={68}
                            />
                        }
                    />
                    <div
                        className={classes.pageModalContent}
                        style={{
                            gap: spaces.content
                        }}
                    >
                        <Button
                            onClick={() => {setIsVisible(false);}}
                            title={localize("give-up")}
                            spreadBehaviour='stretch'
                            variant='outline'
                        />
                        <Button
                            onClick={() => {handleDeleteMyAccount();}}
                            title={localize("delete")}
                            spreadBehaviour='stretch'
                            color="error"
                        />
                    </div>
                </div>
            }
            isVisible={isVisible}
        />;
    };

    const renderContentContainer = () => {
        return <div
            className={classes.contentContainer}
            style={{
                padding: `50px ${spaces.containerXLarge}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div>   
                <div
                    className={classes.content}
                    style={{
                        marginBottom: spaces.content * 2
                    }}
                >
                    {(
                        views === "purchaseHistory" ||
                        views === "asked-questions" ||
                        views === "changeLanguage" ||
                        views === "subscriptions" ||
                        views === "payment" ||
                        views === "privacyPolicy"
                    ) && (
                        <div
                            className={classes.iconStyles}
                            style={{
                                marginRight: spaces.content
                            }}
                            onClick={() => {
                                handleGoBackButton();
                            }}
                        >
                            <RoundArrowIcon />
                        </div>
                    )}
                    <Text
                        variant="header5-medium"
                        color="textDark"
                    >
                        {localize(headerTitle)}
                    </Text>
                </div>
                <div>
                    {renderContent()}
                </div>
            </div>
        </div>;
    };

    const renderRightContainer = () => {
        return <div
            className={classes.rightContainer}
            style={{
                padding: `${spaces.container * 1.75}px ${spaces.container * 2.5}px`,
                backgroundColor: colors.layer1,
                borderRadius: radiuses.half,
                borderColor: colors.stroke,
                borderWidth: borders.line
            }}
        >
            <div
                className={classes.iconContainer}
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                <WidgetAddIcon 
                    color={colors.primary}
                />  
            </div>
            <Text 
                variant='body2-medium'
                style={{
                    marginBottom: spaces.inline / 3
                }}
            >
                {localize("create-new-ad")}
            </Text>
            <Text
                variant='body4-regular'
                color='textGrey'
                style={{
                    marginBottom: spaces.content * 1.6
                }}
            >
                {localize("find-the-employee-you-are-looking-for-immediately-by-creating-a-new-ad.")}
            </Text>
            <Button
                title={localize("create-new-ad")}
                spreadBehaviour='stretch'
                onClick={() => {
                    navigate("/app/newAdvert");
                }}
            />
        </div>;
    };

    if(loadingProfilePhoto) {
        return <div
            className={classes.loadingContiner}
        >
            <Loading/>
        </div>;
    }

    return <div
        className={classes.mainContainer}
        style={{
            padding: `${spaces.container * 3}px ${spaces.container * 8}px`,
            backgroundColor: colors.layer3,
        }}
    >
        <Text
            className={classes.profileText}
            variant='header5-medium'
            style={{
                marginBottom: spaces.content * 2
            }}
        >
            {localize("company-profile-screen")}
        </Text>
        <div
            className={classes.container}
            style={{
                gap: spaces.content * 3
            }}
        >
            <EmployerProfileLeftContainer/>
            {renderContentContainer()}
            {renderRightContainer()}
        </div>
        {renderPageModal()}
    </div>;
};

export default EmployerSettings;