import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const OutlineTrash = ({
    size = 30,
    color="#8C8C8C",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M14.634 3.049a3.075 3.075 0 0 0-2.898 2.048 1.024 1.024 0 1 1-1.932-.682 5.124 5.124 0 0 1 9.66 0 1.024 1.024 0 1 1-1.931.682 3.075 3.075 0 0 0-2.899-2.048ZM2 7.488c0-.566.459-1.025 1.024-1.025h23.22a1.024 1.024 0 1 1 0 2.05H3.024A1.024 1.024 0 0 1 2 7.487ZM6.323 10.834a1.024 1.024 0 1 0-2.044.137l.633 9.495c.117 1.752.21 3.167.432 4.277.23 1.155.621 2.12 1.43 2.875.807.756 1.796 1.083 2.963 1.235C10.86 29 12.278 29 14.034 29h1.2c1.756 0 3.175 0 4.298-.147 1.167-.152 2.155-.479 2.963-1.235.808-.756 1.2-1.72 1.43-2.875.22-1.11.315-2.525.432-4.277l.633-9.495a1.024 1.024 0 0 0-2.044-.137l-.629 9.424c-.122 1.84-.21 3.121-.402 4.085-.186.935-.446 1.43-.82 1.78-.373.349-.884.575-1.83.699-.974.127-2.258.13-4.103.13h-1.056c-1.845 0-3.129-.003-4.103-.13-.945-.124-1.456-.35-1.83-.7-.373-.35-.633-.844-.82-1.779-.192-.964-.279-2.245-.402-4.085l-.628-9.424Z"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            d="M11.118 13.298a1.024 1.024 0 0 1 1.12.917l.684 6.83a1.024 1.024 0 1 1-2.039.203L10.2 14.42a1.024 1.024 0 0 1 .918-1.121ZM19.068 14.419a1.024 1.024 0 0 0-2.038-.204l-.683 6.83a1.024 1.024 0 1 0 2.038.203l.683-6.829Z"
        />
    </svg>;
};
export default OutlineTrash;
