import React, {
    useState
} from "react";
import useStyles from "./styles";
import {
    IOCoreLocale,
    IOCoreTheme,
    TextInput,
    SelectBox,
    TextArea,
    Button,
    Dialog,
    Text
} from "isinolacak-web-cl";
import {
    PersonalInfoInputTypes
} from "./types";
import {
    GetAreaCodesObjectType
} from "../../../../../../../../services/restAPI/actions/getAreaCodes/types";
import {
    RESTService
} from "../../../../../../../../services/restAPI";
import {
    EditUserProfileRequestTypes
} from "../../../../../../../../services/restAPI/actions/editUserProfile/types";
import {
    CircleIcon
} from "../../../../../../../../assets/svgr";
import {
    getErrorText 
} from "../../../../../../../../utils";

const PersonalInfoInput = ({
    setPersonalInfo,
    getMyProfile,
    personalInfo,
    setErrors,
    setViews,
    errors
}: PersonalInfoInputTypes) =>  {
    const classes = useStyles();

    const {
        localize
    } = IOCoreLocale.useContext();

    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleEditUserProfile = () => {
        let params: EditUserProfileRequestTypes = {
        };

        if (personalInfo.firstName.length >= 3) {
            params.firstName = personalInfo.firstName;
        } else {
            let _errors = JSON.parse(JSON.stringify(errors));
            if (personalInfo.firstName.length < 3) {
                _errors.push("firstName-length-error");
            }
            setErrors(_errors);
            return;
        }

        if (personalInfo.lastName.length >= 3) {
            params.lastName = personalInfo.lastName;
        } else {
            let _errors = JSON.parse(JSON.stringify(errors));
            if (personalInfo.lastName.length < 3) {
                _errors.push("lastName-length-error");
            }
            setErrors(_errors);
            return;
        }

        if (personalInfo.about) {
            if (personalInfo.about.length >= 3) {
                params.about = personalInfo.about;
            } else {
                let _errors = JSON.parse(JSON.stringify(errors));
                if (personalInfo.about.length < 3) {
                    _errors.push("about-length-error");
                }
                setErrors(_errors);
                return;
            }
        }
        
        setLoadingButton(true);
        RESTService.action("EditUserProfile", params)
            .then((res) => {
                setIsVisibleDialog(true);
                setPersonalInfo({
                    ...personalInfo,
                    fullName: `${params.firstName} ${params.lastName}`
                });
                setLoadingButton(false);
            })
            .catch((err) => {
                console.error("EditUserProfile Err:", err);
                setLoadingButton(false);

                if(err && err.payload) {
                    const errorData = getErrorText(err, [
                        "answer"
                    ]);
                    if(errorData.isErrorHandled && errorData.errorText && errorData.errorText.length) {
                        alert(localize(err.message));
                        return;
                    }
                } else if(err.message) {
                    alert(localize(err.mesaage));
                    return;
                }
    
                alert(localize("invalid-request"));
            });
    };

    const renderSuccessDialog = () => {
        return <Dialog
            isVisible={isVisibleDialog}
            headerComponent={
                <div
                    className={classes.dialogHeader}
                    style={{
                        gap: spaces.content
                    }}
                >
                    <CircleIcon
                        color={colors.success}
                        size={60}
                    />
                    <Text
                        variant="header5-medium"
                    >
                        {localize("ıt-was-edited-successfully")}
                    </Text>
                </div>
            }
            variant="yes-no"
            bottomComponent={<div>
                <Button
                    onClick={() => {setIsVisibleDialog(false);}}
                    spreadBehaviour="stretch"
                    title={localize("ok")}
                    variant="filled"
                />
            </div>}
        />;
    };
    
    const renderSaveButton = () => {
        return <div
            style={{
                paddingTop: spaces.content * 2,
                borderTopWidth: borders.line,
                borderColor: colors.stroke
            }}
        >
            <Button
                onClick={() => {handleEditUserProfile();}}
                spreadBehaviour="stretch"
                title={localize("save")}
                loading={loadingButton}
                style={{
                    marginBottom: spaces.content * 1.5
                }}
            />
        </div>;
    };

    const renderFirstName = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("name")}
            </Text>
            <TextInput
                infoText={errors.includes("firstName-length-error") ?  localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.includes("firstName-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "firstName-length-error";
                    let index = _errors.indexOf(nameError);
                    if (index > -1) {
                        _errors.splice(index, 1);
                    }
                    setErrors(_errors);
                }}
                initialValue={personalInfo.firstName}
                placeholder={localize("enter-your-information.")}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        firstName: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderLastName = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("surname")}
            </Text>
            <TextInput
                infoText={errors.includes("lastName-length-error") ?  localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.includes("lastName-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "lastName-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                                                
                    setErrors(_errors);
                }}
                placeholder={localize("enter-your-information.")}
                initialValue={personalInfo.lastName}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        lastName: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderPhoneNumber = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("phone-number")}
            </Text>
            <div
                onClick={() => setViews("company-phone-number-change")}
            >
                <div
                    className={classes.inputContainer}
                >
                    <div>
                        <SelectBox
                            initialSelectedItems={
                                getMyProfile ? [getMyProfile.profilePayload.phoneAreaCode] : []
                            }
                            keyExtractor={(e) => e._id}
                            spreadBehaviour='stretch'
                            //@ts-ignore
                            titleExtractor={(
                                item: GetAreaCodesObjectType
                            ) => {
                                return <div 
                                    className={classes.areaCodesContainer}>
                                    <Text>
                                        {item.flagUnicode}
                                    </Text>
                                    <Text>
                                        {item.value}
                                    </Text>
                                </div>;
                            }}
                            isNeedConfirm={true}
                            multiSelect={false}
                            disabled={false}
                            onOk={({
                                closeSheet,
                                onSuccess
                            }) => {
                                closeSheet();
                                onSuccess();
                            }}
                            style={{
                                marginTop: spaces.content / 2,
                                marginRight: spaces.content
                            }}
                            data={getMyProfile ? [getMyProfile.profilePayload.phoneAreaCode] : []}
                        />
                    </div>
                    <div
                        className={classes.textInput}
                    >
                        <TextInput
                            placeholder={localize("enter-your-information.")}
                            initialValue={personalInfo.phoneNumber}
                            onChangeText={(e) => {
                                setPersonalInfo({
                                    ...personalInfo,
                                    phoneNumber: e
                                });
                            }}
                            style={{
                                marginTop: spaces.content / 2,
                                width: "100%"
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>;
    };

    const renderMail = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("e-mail")}
            </Text>
            <div
                onClick={() => setViews("company-mail-change")}
            >
                <TextInput
                    placeholder={localize("enter-your-information.")}
                    initialValue={personalInfo.mail}
                    onChangeText={(e) => {
                        setPersonalInfo({
                            ...personalInfo,
                            mail: e
                        });
                    }}
                    style={{
                        marginTop: spaces.content / 2
                    }}
                />
            </div>
        </div>;
    };

    const renderAbout = () => {
        return <div
            style={{
                marginBottom: spaces.content,
                marginTop: spaces.content
            }}
        >
            <Text
                variant='body-regular'
                color='textDark'
            >
                {localize("about")}
            </Text>
            <TextArea
                infoText={errors.includes("about-length-error") ?  localize("ıt-must-be-at-least-3-characters") : undefined}
                isError={errors.includes("about-length-error")}
                onFocus={() => {
                    let _errors = JSON.parse(JSON.stringify(errors));
                    let nameError = "about-length-error";
                    let index = _errors.indexOf(nameError);
                    _errors.splice(index, 1);
                                                            
                    setErrors(_errors);
                }}
                initialValue={personalInfo.about}
                placeholder={localize("enter-your-information.")}
                onChangeText={(e) => {
                    setPersonalInfo({
                        ...personalInfo,
                        about: e
                    });
                }}
                style={{
                    marginTop: spaces.content / 2
                }}
            />
        </div>;
    };

    const renderContent = () => {
        return <div>
            {renderFirstName()}
            {renderLastName()}
            {renderPhoneNumber()}
            {renderMail()}
            {renderAbout()}
        </div>;
    };


    return <div
        style={{
            padding: spaces.content * 2,
        }}
    >
        <Text
            variant='header5-medium'
            color='textDark'
        >
            {localize("personal-information")}
        </Text>
        {renderContent()}
        {renderSaveButton()}
        {renderSuccessDialog()}
    </div>;
};

export default PersonalInfoInput;
