import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    EditAlarmResponseType,
    EditAlarmRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const EditAlarm = async (params: EditAlarmRequestType): Promise<EditAlarmResponseType> => {
    return await axios(`${SERVER_ADDRESS}/notification/editAlarm`, {
        method: "PUT",
        headers: getHeaders(true),
        data: JSON.stringify(params)
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default EditAlarm;
