import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const ArrowDown = ({
    size = 16,
    color,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 16 / size;

    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="m8.37 11.835 6.43-6.63C15.201 4.79 14.958 4 14.43 4H1.57c-.528 0-.771.79-.37 1.205l6.43 6.63c.213.22.527.22.74 0Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
    </svg>;
};
export default ArrowDown;
