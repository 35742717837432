import {
    IIOCoreIconPropsType
} from "isinolacak-web-cl";

const Clock = ({
    color= "#45BBD4",
    size = 30,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;

    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M15 2.953C8.347 2.953 2.953 8.347 2.953 15c0 6.653 5.394 12.047 12.047 12.047 6.653 0 12.047-5.394 12.047-12.047 0-6.653-5.394-12.047-12.047-12.047ZM1 15C1 7.268 7.268 1 15 1s14 6.268 14 14-6.268 14-14 14S1 22.732 1 15Zm14-6.186c.54 0 .977.437.977.977v4.804l2.97 2.97a.977.977 0 0 1-1.382 1.381l-3.256-3.255a.977.977 0 0 1-.286-.691V9.79c0-.539.438-.976.977-.976Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default Clock;
