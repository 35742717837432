import {
    IOCoreLocale,
    IOCoreTheme,
    Button,
    Text,
    Loading
} from "isinolacak-web-cl";
import {
    useStyles
} from "./styles";
import {
    IResultsCardProps,
    StickerObjectType
} from "./types";
import {
    BookMarkFullIcon,
    BookMarkIcon 
} from "../../../../../assets/svgr";
import emptyProfile from "../../../../../assets/img/isinolacakMiniWithPadding.png";
import BoostsTypeStickers from "../../../../../components/boostTypeStickers";

const ResultCard = ({
    onPressBookMark,
    isSaved = false,
    experienceLevel,
    bookmarkLoading,
    actionOnPress,
    contractType,
    companyName,
    workingType,
    actionType,
    jobImage,
    stickers,
    onPress,
    style,
    title,
    city,
    id
}: IResultsCardProps) => {
    const classes = useStyles();
    const {
        radiuses,
        borders,
        spaces,
        colors
    } = IOCoreTheme.useContext();

    const {
        localize
    } = IOCoreLocale.useContext();

    const informationsContainer = () => {
        return <div
            className={
                classes.informationTextStyle
            }
        >
            <div>
                <Text
                    variant="body2-medium"
                    color="textDark"
                    className={
                        classes.informationTextStyle
                    }
                    style={{
                        marginBottom: spaces.inline
                    }}
                >
                    {title}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {companyName}
                </Text>
            </div>
            <div>
                <Text
                    variant="body4-regular"
                    color="textGrey"
                    className={
                        classes.informationTextStyle
                    }
                >
                    {`${experienceLevel} • ${contractType} • ${city} (${workingType})`}
                </Text>
            </div>
        </div>;
    };

    const bookmarkContianer = () => {
        return <div
            className={classes.bookmarkStyle}
            style={
                {
                    borderRadius: radiuses.hard,
                    borderColor: colors.stroke,
                    borderWidth: borders.line,
                    borderStyle: "solid"
                }
            }
            onClick={(e) => onPressBookMark(e)}
        >
            
            {bookmarkLoading === id ? <Loading/> : 
                isSaved ? <BookMarkFullIcon /> :  <BookMarkIcon />
                
            }
        </div>;
    };

    const renderInformations = () => {
        return <div
            className={
                classes.renderInformationsStyle
            }
            style={{
                padding: spaces.container * 0.75,
                borderColor: colors.stroke
            }}
            onClick={onPress}
        >
            <div
                style={{
                    display: "flex"
                }}
            >
                <img
                    src={jobImage && jobImage.length ? jobImage : emptyProfile}
                    className={
                        classes.imageStyle
                    }
                    style={{
                        borderRadius: radiuses.half * 1.5,
                        marginRight: spaces.container
                    }}
                />
                {informationsContainer()}
            </div>
            {bookmarkContianer()}
        </div>;
    };

    const renderTags = () => {
        if (!stickers || !stickers.length) {
            return null;
        }

        return <div
            className={
                classes.renderTagsStyle
            }
            style={{
                paddingBottom: spaces.content / 2,
                paddingTop: spaces.content / 2,
                paddingRight: spaces.content / 2,
                paddingLeft: spaces.content / 2,
                borderTopColor: colors.stroke,
                borderTopWidth: borders.line,
                gap: spaces.content / 2,
                borderTopStyle: "solid"
            }}
        >
            {
                stickers.map((item: StickerObjectType, index: number) => {
                    let localizedText = "";
                    if(item.translationKey) {
                        if(item.translationKeyParam) {
                            localizedText = localize(item.translationKey, [item.param]);
                        } else {
                            localizedText = localize(item.translationKey);
                        }
                    } else {
                        localizedText = item.localizedText ? item.localizedText : "";
                    }

                    return <div
                        key={item.key}
                    >
                        <BoostsTypeStickers
                            title={localizedText}
                            titleColor={item.color}
                            color={item.backColor}
                            icon={item.icon}
                            itemKey={item.key}
                        />
                    </div>;
                })
            }
        </div>;
    };

    const renderActionButton = () => {
        if(!actionOnPress) {
            return null;
        }

        if(!actionType) {
            return null;
        }

        let currentActionTitle;

        if(actionType === "uploadDocuments") {
            currentActionTitle = "Upload Documents";
        }

        if(actionType === "completePerformanceTest") {
            currentActionTitle = "CompletePerformanceTest";
        }

        if(actionType === "joinInterview") {
            currentActionTitle = "Join Interview";
        }

        return <div
            style={{
                padding: spaces.container * 0.75,
                borderTopColor: colors.stroke,
                borderWidth: borders.line,
                borderTopStyle: "solid"
            }}
        >
            <Button
                title={currentActionTitle}
                spreadBehaviour="stretch"
                onClick={actionOnPress}
                variant="filled"
            />
        </div>;
    };

    return <div
        className={classes.container}
        style={{
            ...style,
            borderRadius: radiuses.half * 1.5,
            backgroundColor: colors.white,
            borderColor: colors.stroke,
            borderWidth: borders.line,
            borderStyle: "solid"
        }}
    >
        {renderInformations()}
        {renderTags()}
        {renderActionButton()}
    </div>;
};

export default ResultCard;