import {
    createUseStyles 
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    bottomLine: {
        width: "80%"
    }
});
export default useStyles;