import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    mainContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        userSelect: "none",
        minHeight: "100%",
        display: "flex",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display:"flex",
            width: "100%"
        },
    },
    container: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxSizing: "border-box",
        flexDirection: "row",
        display: "flex",
        height: "100%",
        width: "100%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            width: "100%"
        }
    },
    leftContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        alignItems: "center",
        position: "sticky",
        maxWidth: "15%",
        display: "flex",
        width: "100%",
        "@media (max-width: 1040px)": {
            position: "relative",
            display: "flex",
            minWidth: "100%"
        }
    },
    contentContainer: {
        flexDirection: "column",
        boxSizing: "border-box",
        borderStyle: "solid",
        width: "73%",
        "@media (max-width: 1040px)": {
            flexDirection: "column",
            display: "flex",
            height: "100%",
            width: "100%"
        }
    },
    rightContainer: {
        justifyContent: "flex-start",
        flexDirection: "column",
        borderStyle: "solid",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        maxHeight: 160,
        flexShrink: 0,
        maxWidth: 150,
        width: "100%",
        "@media (max-width: 1200px)": {
            display: "none"
        }
    },
    iconContainer: {
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        textAlign: "center",
        display: "flex"
    },
    companyAboutHeader: {
        alignSelf: "flex-start",
        display: "flex"
    },
    companyHeaderLogo: {
        justifyContent: "center",
        alignItems: "center",
        objectFit: "cover",
        borderStyle: "solid",
        display: "flex",
        height: 80,
        width: 80
    },
    companyLogo: {
        justifyContent: "center",
        alignItems: "center",
        objectFit: "cover",
        borderStyle: "solid",
        display: "flex",
        height: 100,
        width: 100
    },
    line: {
        width: "100%",
        height: "1px"
    },
    buttonContainer: {
        alignItems: "flex-start",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1040px)": {
            flexDirection: "row",
            display: "flex"
        }
    },
    button: {
        alignItems: "center",
        cursor: "pointer",
        display: "flex"
    },
    buttonText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    profileText: {
        "@media (max-width: 1040px)": {
            display: "none"
        }
    },
    renderTagsStyle: {
        width: "97%",
        height: 37
    },
    pageModalContainer: {
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        userSelect: "none",
        display:"flex"
    },
    pageModalContent: {
        flexDirection: "row",
        display: "flex"
    },
    searchContianer: {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        "@media (max-width: 500px)": {
            display: "none"
        }
    },
    searhStateCard: {
        justifyContent: "center",
        display: "flex"
    },
    tabBar: {
        display: "flex",
        borderBottom: "1px solid #ccc",
    },
    tabItem: {
        padding: "10px 20px",
        cursor: "pointer",
        position: "relative",
        color: "#000",
        fontSize: "16px",
        "&.active": {
            color: "#00bfff",
            "&::after": {
                content: "\"\"",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "2px",
                backgroundColor: "#00bfff",
            },
        },
    },
    sticker: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
    },
    tabContent: {
        padding: "20px",
    },
    headerContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex"
    },
    headerContainerIcon: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex"
    },
    icon:{
        justifyContent: "center",
        borderStyle: "solid",
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 25,
        width: 25
    },
    cardText: {
        flexDirection: "column",
        display: "flex"
    },
    renderLine: {
        width: "99%"
    },
    loadingContainer: {
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%"
    }
});
export default useStyles;