import {
    createUseStyles
} from "react-jss";

const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        display: "flex",
        width: "100%"
    },
    headerContainer: {
        flexDirection: "row",
        display: "flex"
    },
    pointer: {
        cursor: "pointer"
    },
    ptAnswersContainer: {
        flexDirection: "column",
        display: "flex"
    },
    question: {
        alignItems: "center",
        flexDirection: "row",
        display: "flex"
    },
    line: {
        borderStyle: "solid",
        width: "20%"
    }
});
export default useStyles;