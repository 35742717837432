import {
    createUseStyles 
} from "react-jss";

export const useStyles = createUseStyles({
    container: {
        flexDirection: "column",
        borderStyle: "solid",
        display: "flex",
        width: "100%",
        flexGrow: 1
    },
    informationTextStyle: {
        width: "100%"
    },
    renderInformationsStyle: {
        justifyContent: "space-between",
        flexDirection: "row",
        display:"flex",
        height: "100%"
    },
    imageStyle: {
        objectFit: "cover",
        height: 56,
        width: 56
    },
    renderTagsStyle: {
        boxSizing: "border-box",
        borderTopStyle: "solid",
        flexDirection: "row",
        display: "flex",
        width: "100%"
    },
    informationsContent:{ 
        flexDirection: "row",
        display:"flex"
    }
});
