import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const LinkedInCircle = ({
    size = 30,
    color = "#black",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M14.5 0C6.492 0 0 6.492 0 14.5S6.492 29 14.5 29 29 22.508 29 14.5 22.508 0 14.5 0Zm-3.55 20.51H8.014v-9.45h2.937v9.45ZM9.464 9.9c-.927 0-1.527-.656-1.527-1.469 0-.83.618-1.466 1.565-1.466s1.527.637 1.545 1.466c0 .813-.598 1.47-1.583 1.47Zm12.21 10.61h-2.936v-5.237c0-1.219-.426-2.046-1.488-2.046-.81 0-1.292.56-1.505 1.1-.079.191-.099.463-.099.733v5.448H12.71v-6.434c0-1.18-.038-2.166-.077-3.015h2.55l.135 1.313h.059c.387-.616 1.334-1.526 2.918-1.526 1.932 0 3.38 1.295 3.38 4.077v5.587Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default LinkedInCircle;
