import {
    IOCoreTheme,
    Text
} from "isinolacak-web-cl";
import useStyles from "./styles";
import {
    IFAQChipProps 
} from "./types";

const FAQChip = ({
    selected = false, 
    onClick,
    title 
}: IFAQChipProps) => {
    const classes = useStyles();

    const {
        radiuses,
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div 
        className={classes.graphChipContainer}
        onClick={onClick}
        style={{
            borderColor: !selected ? colors.secondary : colors.primary,
            paddingRight: spaces.content * 2,
            paddingLeft: spaces.content * 2,
            borderWidth: borders.line * 1.5,
            paddingBottom: spaces.content,
            borderRadius: radiuses.half,
            paddingTop: spaces.content
        }}
    >
        <Text 
            color={!selected ? "secondary" : "primary"}
            variant="body2-medium" 
        >
            {title}
        </Text>
    </div>;
};

export default FAQChip;