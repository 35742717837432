import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledCheck = ({
    size = 22,
    color = "#fff",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 22 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            d="M11 1C5.48 1 1 5.48 1 11s4.48 10 10 10 10-4.48 10-10S16.52 1 11 1ZM8.29 15.29 4.7 11.7a.996.996 0 1 1 1.41-1.41L9 13.17l6.88-6.88a.996.996 0 1 1 1.41 1.41L9.7 15.29a.996.996 0 0 1-1.41 0Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default FilledCheck;
