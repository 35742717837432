import axios from "axios";
import {
    SERVER_ADDRESS
} from "../../constants";
import {
    ExportApplicantDocumentInBulkResponseType,
    ExportApplicantDocumentInBulkRequestType
} from "./types";
import {
    getHeaders
} from "../../utils";

const ExportApplicantDocumentInBulk = async (params: ExportApplicantDocumentInBulkRequestType): Promise<ExportApplicantDocumentInBulkResponseType> => {
    return await axios(`${SERVER_ADDRESS}/document/exportApplicantDocumentsInBulk`, {
        headers: getHeaders(true),
        method: "GET",
        params: params
    })
        .then((res) => {
            if(
                res.status === 200 ||
                res.status === 201 ||
                res.status === 202
            ) {
                return res.data;
            }

            throw res;
        })
        .catch((err) => {
            if(err && err.response && err.response.data) {
                throw err.response.data;
            }
            
            throw err;
        });
};
export default ExportApplicantDocumentInBulk;
