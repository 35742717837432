import React from "react";
import useStyles from "./styles";
import {
    IOCoreTheme,
    Text 
} from "isinolacak-web-cl";
import {
    QualificationCardPropsType
} from "./types";
import {
    DocumentsIcon 
} from "../../../../../assets/svgr";

const QualificationCard = ({
    title
}: QualificationCardPropsType) => {
    const classes = useStyles();
    
    const {
        borders,
        colors,
        spaces
    } = IOCoreTheme.useContext();

    return <div
        className={classes.cardHeader}
        style={{
            marginBottom: spaces.content / 2,
            marginLeft: spaces.content,
            marginTop: spaces.content,
        }}
    >
        <div
            className={classes.icon}
            style={{
                borderRadius: spaces.contentXLarge * 2,
                padding: spaces.content / 2,
                borderColor: colors.primary,
                borderWidth: borders.line
            }}  
        >
            <DocumentsIcon
                color={colors.primary}
                size={30}
            />
        </div>
        <div
            style={{
                marginLeft: spaces.content,
            }}
        >
            <div>
                <Text
                    variant="body-medium"
                    color="textDark"
                >
                    {title}
                </Text>
            </div>
        </div>
    </div>;
};

export default QualificationCard;
