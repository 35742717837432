import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const FilledWallet = ({
    size = 30,
    color = "#45BBD4",
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 30 / size;
    return <svg
        width={size}
        height={size}
        fill="none"
        {...props}
    >
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M26.775 9.752c-.077-.005-.16-.005-.246-.005H23.122c-2.79 0-5.177 2.196-5.177 5.06 0 2.864 2.387 5.06 5.177 5.06H26.53c.086 0 .17 0 .246-.004 1.133-.072 2.135-.959 2.22-2.197.005-.082.005-.17.005-.25V12.199c0-.081 0-.169-.005-.25-.085-1.239-1.087-2.126-2.22-2.197Zm-3.953 6.405c.718 0 1.3-.604 1.3-1.35 0-.745-.582-1.35-1.3-1.35-.718 0-1.3.605-1.3 1.35 0 .745.582 1.35 1.3 1.35Z"
            clipRule="evenodd"
        />
        <path
            transform={`scale(${1 / pathScale})`}
            fill={color}
            fillRule="evenodd"
            d="M26.528 21.892a.297.297 0 0 1 .299.374c-.271.962-.701 1.781-1.391 2.471-1.01 1.01-2.29 1.458-3.872 1.67-1.538.207-3.502.207-5.982.207h-2.85c-2.48 0-4.445 0-5.982-.206-1.582-.213-2.863-.661-3.873-1.67-1.01-1.01-1.458-2.291-1.67-3.873C1 19.328 1 17.363 1 14.883v-.152c0-2.48 0-4.444.207-5.981.212-1.582.66-2.863 1.67-3.873 1.01-1.01 2.29-1.458 3.873-1.67C8.287 3 10.25 3 12.73 3h2.851c2.48 0 4.444 0 5.982.207 1.582.212 2.862.66 3.872 1.67.69.69 1.12 1.51 1.39 2.471a.297.297 0 0 1-.298.375h-3.406c-3.828 0-7.201 3.024-7.201 7.084s3.373 7.085 7.201 7.085h3.406ZM6.06 8.398a1.012 1.012 0 1 0 0 2.024h5.398a1.012 1.012 0 1 0 0-2.024H6.06Z"
            clipRule="evenodd"
        />
    </svg>;
};
export default FilledWallet;