import {
    IIOCoreIconPropsType 
} from "isinolacak-web-cl";

const BookMark = ({
    color = "#9AA6B8",
    size = 12,
    ...props
}: IIOCoreIconPropsType) => {
    const pathScale = 12 / size;
    return <svg
        height={size}
        width={size}
        fill="none"
        {...props}
    >
        <path
            d="M4.5 2.625a.375.375 0 1 0 0 .75h3a.375.375 0 1 0 0-.75h-3Z"
            transform={`scale(${1 / pathScale})`}
            fill={color}
        />
        <path
            d="M5.971.625c-1.036 0-1.852 0-2.49.087-.655.089-1.178.275-1.589.69-.41.415-.594.942-.681 1.602-.086.644-.086 1.468-.086 2.517V8.07c0 .753 0 1.35.048 1.8.047.443.15.844.44 1.112.233.215.528.35.843.386.394.045.761-.143 1.123-.4.367-.259.811-.652 1.372-1.148l.019-.016c.26-.23.436-.385.583-.493.141-.104.228-.141.301-.156a.738.738 0 0 1 .292 0c.073.015.16.052.301.156.147.108.323.263.583.493l.019.016c.56.496 1.005.89 1.372 1.149.362.256.73.444 1.123.4.315-.037.61-.172.843-.387.29-.268.393-.669.44-1.113.048-.449.048-1.046.048-1.8V5.522c0-1.049 0-1.873-.086-2.517-.088-.66-.271-1.187-.681-1.602-.411-.415-.934-.601-1.589-.69C7.881.625 7.065.625 6.03.625H5.97ZM2.426 1.93c.248-.251.585-.398 1.156-.475.581-.08 1.347-.08 2.418-.08 1.071 0 1.837 0 2.418.08.57.077.908.224 1.156.475.25.252.395.594.472 1.173.078.588.079 1.363.079 2.446v2.496c0 .784 0 1.34-.044 1.745-.044.414-.126.57-.202.64a.744.744 0 0 1-.42.193c-.1.012-.267-.027-.605-.266-.33-.233-.743-.6-1.327-1.115l-.013-.012a9.5 9.5 0 0 0-.624-.524 1.507 1.507 0 0 0-.596-.286 1.488 1.488 0 0 0-.588 0c-.222.045-.41.15-.596.286-.179.13-.38.309-.624.524l-.013.012c-.584.516-.998.882-1.327 1.115-.338.239-.505.278-.605.266a.744.744 0 0 1-.42-.193c-.076-.07-.158-.226-.202-.64-.043-.405-.044-.961-.044-1.745V5.55c0-1.083 0-1.858.08-2.446.076-.579.222-.921.47-1.173Z"
            transform={`scale(${1 / pathScale})`}
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
        />
    </svg>;
};
export default BookMark;
